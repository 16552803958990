/* eslint-disable import/prefer-default-export */

import dayjs from 'dayjs';
import { Merchant } from '../types';
import { addressDetails } from './address-service';

const zplEncode = (text: string) => {
  return text.replace(/_/g, '_5F').replace(/ /g, '_20');
};

const zplTemplate = `
  ^XA
  ^FO20,60

  ^CF0,40
  ^FO40,61^FH^FDSTOP_20#STOPNUMBER#^FS


  ^CF0,40
  ^FO40,125^FH^FDROUTE^FS

  ^CF0,90
  ^FO40,170^FH^FD##ROUTENUMBER#^FS

  ^CFA,30
  ^FO40,255^FH^FD#ROUTEID#^FS
  
  ^FO420,70#ZPLLOGO#

  ^FO20,290^GB770,3,3,B^FS
  
  ^CF0,30
  ^FO40,320^FH^FD#DATE#^FS
  ^FO770,320,1^FH^FD#ITEMS#^FS
  ^FO40,350^FH^FD#ID#^FS
  
  ^FO330,400,1^BQN,2,9^FDQA,#BARCODE#^FS
  ^FO350,410^FH^FD#NAME#^FS
  
  ^CFF,30
  ^FO350,470^FH^FD#ADDRESS1#^FS
  ^FO350,510^FH^FD#CITY#^FS 
  ^FO350,550^FH^FD#POSTALPROVINCE#^FS
  ^FO350,600^FH^FD#ADDRESS2#^FS
  ^FO350,640^FH^FD#PHONE#^FS
  
  ^FO20,685^GB770,3,3,B^FS
  
  ^CF0,30
  ^FO40,710^FH^FD#MIDDLEMILEGROUP#^FS
  
  ^CFF,30
  ^FO40,755^FH^FD#MIDDLEMILE_ADDRESS1#^FS
  ^FO40,795^FH^FD#MIDDLEMILE_CITY#^FS 
  ^FO40,835^FH^FD#MIDDLEMILE_POSTALPROVINCE#^FS
  
  ^FO20,880^GB#DIVIDERWIDTH#,3,3,B^FS
  
  ^CF0,35
  ^FO40,905^FH^FD#ROUTINGGROUPLABEL#^FS
  
  ^CF0,90
  ^FO40,970^FH^FD#ROUTINGGROUP#^FS
  
  ^FO20,1075^GB770,3,3,B^FS
  
  ^FO150,1110
  ^GFA,5103,5103,63,:Z64:eJzV2E2O2zYUAGAKDMJNEF6gJa+RoqrUI83sFMCIaHjhXeYCRXKSAhx4Mcu5QIFS8CK7RMFsBETV63vUH+WxZDkBCpQwZI2tT5bIx8enYWxVS5t1x820/H/N6/+c54Ct+jHeOljg3DLX7SoZfA7jr89zDUcblXgS+EHuFni1yB3x8ns5vXM6RIXfrOIs4y23Z3lJexHydu8cf8RrQL6JnOfdL0UTHs3zv+9dbrmNRaWk4rtG/OniKzjcO3DIdY1c7Bvxj3tzwssF/lQUX/HXlUqUfC3u7kRdXsPvi8IZ5LFSUgop9q7MnnO3xBlxiZwu35Q3PfcIY4Iv8nLKsxv6RvTDfZEDXXwKSor3GpCXLS/XcQfYdUnA3Ql3yzylgYsx5sUHzYSJr+RRz/kfCvlvUy7ACbALnCHfeP4X8Z/9sRI59p6AByhwEHCHRj+azh/P75FX8SvkteTC6I67CBOF7LmGe+QSmlNePOHNVTrRd1ElHwPOQp7jW8lSAHPKseeh1I1ELkAYaQKOQ4E/ixzgK36Sw6QX24EzHKyoifPmGRdwQI5h+I1uB56Hv5/oUXtW7JmRY4ZPO859EPMhBc23NuVp4g2+JPFGeC6G5H+Z25BLz+UKnoYcagk75Npz2l5ab98NHOsKqCT2fcjhAt8EPOp5ChWATVdwNnIKVAkmpZdtXys5HomnoHgCoynocGtzv7OOG89dzzESLPZleg3HqapputAgIMdu1+u5hLueA3Hjo/gqTleum3byXc0/ep4OvFrL847jeQa+vcC5CTilOWiu4vYMbzquO94fQefRMOVDUu14HXJKlOkJNye8HDnvec38XZzjdpFXftJ7ngOl3nzglKQUd1NuOk7zmlNm6zhlO7qFcb57Hp3yaoZ/It7wgEc1cRBbe5OaI6/4E/K3VNu0PII2X9IL2mz9OCYrJQB5AfudK8F+wboG87W5neWN357wIxx2rgAHd5gP6d6pthm49bYK+JColXyJXXc8HA8OKxSg2oZ6nmqbljNakqi7aLzaCAyWCSpiNTxsj4fyaMtSSe3DhmobFvmLPMfdM/5wi9zFQhF3WchZx9sUTdshTpSgj/fb4uH20JQu+fLBh42vbVpOMUIlbYXzBVLKGHg9US1KXI+R4/kasS0+vT1A6VIgbjJf23S86bmABvtQ+EKhkbXOU6PwIQLLiW3x+ZeWK8/fBLwmTmUFVgD4h58EEnStITcK1yPi7vOvB3ykwfLEc1/b8Pbe66EiBufPRRWGTBL98dFQIdPxXcgtY10RQ5uhpCXu8++rWGm5N+plxxu9s7879YLG3WR66NtpIUSc00ML26hECpNIifv8YGu9dc5pKffY89XI+aSU6M+VsEwlWhpMw8TBbvQWHC7eFLS4KpmRuxkeY8zAvkEegdloBtaJRnyjsJEDFwPHYBzuJGZZ4vm2Zq9pPc8kS42jCUtFUsjtAp9p4xcy5ENHII83a/iYR31h23IV/5RtMgzaFbzf8+t82xGJSjZVFs/yd8NeGvBhqia6TonPLTYBH9Ki521HJLJKywW+GfbGf3r4R6MWxKKUNotnu25sY1oM+PoWhPzqimxs0ZRf+++b8Nkzv1xKn7ZwwqVnCvmLfJxwEuaeplae6tqem7b82bX/CxDm+Ak=:3ab4
  ^XZ`;

interface ZplOptions {
  name: string;
  address1: string;
  address2: string;
  city: string;
  postalProvince: string;
  id: string;
  routeNumber: string;
  routeId: string;
  pickupTime: string;
  phone: string;
  stopNumber: number | string;
  date: string;
  barcode: string;
  itemNumber?: number;
  totalItems?: number;
  isLineHaul?: boolean;
  routingGroup?: string;
  pickupAddress: string;
  isMiddleMile?: boolean;
  middleMileGroup?: string;
  middleMileAddress?: string;
  zplLogo?: Merchant['settings']['zplLogo'];
}

export const zplLabel = (options: ZplOptions) => {
  let label = zplTemplate;
  label = label.split(' ').join('');
  label = label.replace(/#PHONE#/g, zplEncode(options.phone));
  label = label.replace(/#NAME#/g, zplEncode(options.name));
  label = label.replace(/#ADDRESS1#/g, zplEncode(options.address1));
  label = label.replace(/#ADDRESS2#/g, zplEncode(options.address2) || '');
  label = label.replace(/#CITY#/g, zplEncode(options.city));
  label = label.replace(/#POSTALPROVINCE#/g, zplEncode(options.postalProvince));
  label = label.replace(/#ID#/g, zplEncode(options.id));
  if (options.routeNumber) {
    label = label.replace(/#ROUTENUMBER#/g, `${zplEncode(options.routeNumber)}`);
  } else {
    label = label.replace(/#ROUTENUMBER#/g, ``);
  }
  if (options.routeId) {
    label = label.replace(/#ROUTEID#/g, `${zplEncode(options.routeId)}`);
  } else {
    label = label.replace(/#ROUTEID#/g, ``);
  }
  label = label.replace(/#PICKUPTIME#/g, zplEncode(options.pickupTime));
  if (options.stopNumber) {
    label = label.replace(/#STOPNUMBER#/g, zplEncode(`${options.stopNumber}`));
  } else {
    label = label.replace(/#STOPNUMBER#/g, ``);
  }

  if (options.zplLogo?.z64) {
    const logoZplString = `^GFA,${options.zplLogo.length},${options.zplLogo.length},${options.zplLogo.rowlen},${options.zplLogo.z64}`;
    label = label.replace(/#ZPLLOGO#/g, logoZplString);
  } else {
    label = label.replace(/#ZPLLOGO#/g, ``);
  }

  const showItems = options.itemNumber && options.totalItems && options.totalItems > 1;

  if (showItems) {
    label = label.replace(/#ITEMS#/g, `${options.itemNumber} OF ${options.totalItems} ITEMS`);
  } else {
    label = label.replace(/#ITEMS#/g, ``);
  }
  label = label.replace(
    /#DATE#/g,
    `${zplEncode(options.date)} (${dayjs(options.date).format('ddd').toUpperCase()})`
  );

  if (options.isLineHaul) {
    label = label.replace(/#ROUTINGGROUP#/g, zplEncode(`${options.routingGroup?.toUpperCase()}`));
    label = label.replace(/#ROUTINGGROUPLABEL#/g, zplEncode(`LINEHAUL`));
    // const address = addressDetails(options.pickupAddress);
    // label = label.replace(/#LINEHAUL_ADDRESS1#/g, zplEncode(address.street));
    // label = label.replace(/#LINEHAUL_CITY#/g, zplEncode(address.city));
    // label = label.replace(
    //   /#LINEHAUL_POSTALPROVINCE#/g,
    //   zplEncode(`${address.postalCode} ${address.province}`)
    // );
  } else {
    label = label.replace(/#ROUTINGGROUP#/g, ``);
    label = label.replace(/#ROUTINGGROUPLABEL#/g, ``);
    label = label.replace(/#LINEHAUL_ADDRESS1#/g, ``);
    label = label.replace(/#LINEHAUL_CITY#/g, ``);
    label = label.replace(/#LINEHAUL_POSTALPROVINCE#/g, ``);
  }

  if (options.isMiddleMile && options.middleMileAddress) {
    label = label.replace(
      /#MIDDLEMILEGROUP#/g,
      zplEncode(`MIDDLEMILE TO ${options.middleMileGroup?.toUpperCase()}`)
    );
    const address = addressDetails(options.middleMileAddress);
    label = label.replace(/#MIDDLEMILE_ADDRESS1#/g, zplEncode(address.street));
    label = label.replace(/#MIDDLEMILE_CITY#/g, zplEncode(address.city));
    label = label.replace(
      /#MIDDLEMILE_POSTALPROVINCE#/g,
      zplEncode(`${address.postalCode} ${address.province}`)
    );
  } else {
    label = label.replace(/#MIDDLEMILEGROUP#/g, ``);
    label = label.replace(/#MIDDLEMILE_ADDRESS1#/g, ``);
    label = label.replace(/#MIDDLEMILE_CITY#/g, ``);
    label = label.replace(/#MIDDLEMILE_POSTALPROVINCE#/g, ``);
  }

  const showDivider = options.isLineHaul && options.isMiddleMile;

  if (showDivider) {
    label = label.replace(/#DIVIDERWIDTH/g, `770`);
  } else {
    label = label.replace(/#DIVIDERWIDTH/g, `0`);
  }

  // We purposely don't want to zplEncode the barcode
  const barcode = showItems ? `${options.barcode} ${options.itemNumber}` : options.barcode;
  label = label.replace(/#BARCODE#/g, barcode);

  return label;
};
