<template>
  <main class="flex-1 focus:outline-none" tabindex="0">
    <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
      <div class="pt-10 pb-16">
        <div class="px-4 sm:px-6 md:px-0">
          <GoHeader :level="1">Credits</GoHeader>
        </div>
        <div class="px-4 sm:px-6 md:px-0 pt-10">
          <div class="flex flex-col space-y-8">
            <div class="-my-2 sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <Form @submit="createCredit" :validation-schema="schema">
                  <div class="w-full md:w-1/4 space-y-4 grid-cols-4">
                    <GoSelect
                      name="reason"
                      :options="
                        [
                          'Cancelled Order',
                          'Damaged Order',
                          'Mis-Delivered Order',
                          'Undelivered Order',
                          'Late Delivery',
                          'Billing Correction',
                          'Referral Bonus',
                          'Other',
                        ].map(x => ({ value: x, label: x }))
                      "
                    />
                    <GoSelect
                      name="province"
                      :options="{
                        ON: 'Ontario',
                        BC: 'British Columbia',
                      }"
                    />
                    <GoTextField label="Order ID (optional)" name="orderId" />
                    <GoTextField label="Credit Amount" name="amount" type="number" />
                    <GoButton class="text-xs" :disabled="!currentUser.canCreateCredits">
                      Save Changes
                    </GoButton>
                  </div>
                </Form>
              </div>
            </div>
            <GoTable
              v-if="credits?.length"
              :tableData="{
                headers: [
                  { title: 'ID', key: 'id', wrap: false },
                  { title: 'Merchant', key: 'email' },
                  { title: 'Date', valueFunction: row => getDate(row.created), wrap: false },
                  { title: 'Reason', key: 'reason' },
                  { title: 'Province', key: 'province' },
                  { title: 'Order Id', key: 'orderId' },
                  { title: 'Amount', valueFunction: row => `$${row.amount?.toFixed(2)}` },
                  { title: 'Applied', key: 'creditApplied' },
                ],
                data: credits,
              }"
            ></GoTable>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { apiService } from '@tyltgo/shared';
import * as yup from 'yup';
import { Form } from 'vee-validate';
import sortBy from 'lodash/sortBy';
import dayjs from 'dayjs';
import { currentUser } from '../services/auth-service';
import { addNotification, aroundLoadingMessage } from '../services/ui-message-service';

const schema = yup.object().shape({
  reason: yup.string().required().label('Reason'),
  province: yup.string().required().label('Province'),
  orderId: yup.string().label('Order Id'),
  amount: yup.number().required().label('Amount'),
});

export default defineComponent({
  emits: ['loadMerchant'],
  setup() {
    return {
      schema,
      currentUser,
    };
  },
  components: {
    Form,
  },
  props: {
    merchant: {
      type: Object,
      required: true,
    },
  },
  async mounted() {
    await this.loadCredits();
  },
  data() {
    return {
      loading: false,
      credits: null,
    };
  },
  methods: {
    async loadCredits() {
      this.loading = true;
      const { credits } = await apiService.admin.merchants.credits(this.merchant.id);
      this.credits = sortBy(credits, 'created').reverse();
      this.loading = false;
    },
    getDate(unix: number) {
      return dayjs.unix(unix).format('YYYY-MM-DD');
    },
    async createCredit(values, { resetForm }) {
      aroundLoadingMessage('Creating...', async () => {
        try {
          await apiService.admin.merchants.createCredit(
            this.merchant.id,
            await schema.validate(values)
          );
          resetForm({ values: {} });
          await this.loadCredits();
        } catch (e) {
          addNotification({
            color: 'danger',
            message: `Error sending offer: ${e.response?.data?.message || e.message}`,
          });
        }
      });
    },
  },
});
</script>
