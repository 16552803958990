<template>
  <Form
    v-bind="restOfAttrs"
    @submit="(values, options) => submitTask.perform(values, options)"
    v-slot="params"
  >
    <slot
      v-bind="{ ...params, handleSubmit: (event: Event, f) => handleSubmitTask.perform(event, params.handleSubmit, f) }"
      :task="submitTask"
    ></slot>
  </Form>
</template>

<script lang="ts">
import { defineComponent, useAttrs, provide, computed } from 'vue';
import { useTask } from 'vue-concurrency';
import { Form, SubmissionContext } from 'vee-validate';

export default defineComponent({
  inheritAttrs: false,
  components: {
    Form,
  },
  setup() {
    const attrs = useAttrs();
    const { onSubmit, ...restOfAttrs } = attrs;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const submitTask = useTask(function* submit(_signal, values: any, options: SubmissionContext) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      yield (onSubmit as (values: any, options: SubmissionContext) => void)(values, options);
    }).drop();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleSubmitTask = useTask(function* submit(
      _signal,
      event: Event,
      handleSubmit,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      submitFunction: (values: any, options: SubmissionContext) => void
    ) {
      yield handleSubmit(event, submitFunction);
    }).drop();

    const isRunning = computed(() => {
      return submitTask.isRunning || handleSubmitTask.isRunning;
    });

    provide('formIsRunning', isRunning);
    return {
      submitTask,
      restOfAttrs,
      handleSubmitTask,
    };
  },
});
</script>
