export interface GenerateBatchOptions {
  batchCapacity: number;
  carType: string;
  businessAddress: string;
  day: string;
  orders: GenerateOrderOptions[];
}

export interface CreateBatchOptions {
  carType: string;
  businessAddress: string;
  day: string;
  orders: GenerateOrderOptions[];
  startWindow: string;
  endWindow: string;
  totalPrice: number;
  routeInformation: any;
}

export interface OrderLocation {
  lat: number;
  lng: number;
}

export interface GenerateOrderOptions {
  id?: string;
  trackingKey?: string;
  trackingSecret?: string;
  associatedOrder?: string;
  associatedEmail?: string;
  merchantId?: string;
  associatedBusiness: string;
  address: string;
  addressLine2: string;
  location: OrderLocation;
  name: string;
  email?: string;
  notes: string;
  rawAddress?: unknown;
  phoneNumber: string;
  priority: string;
  relativePriorityTime?: number;
  isPriority?: boolean;
  quantity: number;
  dropoffMessage?: string;
  outsidePriceList?: boolean;
  bagReturnDropoff?: boolean;
  integration?: {
    name: string;
  };
  tags?: string[];
  senderName?: string;
  senderEmail?: string;
  senderPhoneNumber?: string;
}

export interface Location {
  // degrees
  latitude: number;

  // degrees
  longitude: number;

  // meters
  altitude: number;

  // meters
  horizontalAccuracy: number;

  // meters
  verticalAccuracy: number;

  // meters/second
  speed: number;

  // in degrees
  direction: number;

  timestamp: Date;
}

export interface WaypointStatusOptions {
  status: string;
  note: string;
  time?: string;
  image?: string;
  imageFileName?: string;
  location?: Location;
  unscannableAtDropoff?: string[];
  scannedAtDropoff?: string[];
}

export interface DropoffWaypoint extends CoreWaypoint {
  stopNumber?: number;
  courierNote: string;
  phoneNumber: string;
  email?: string;
  status: string;
  quantity: number;
  chargeQuantity?: number;

  unpicked: boolean;
  unpickedReason?: string;
  scanned: 'pickup' | 'dropoff' | 'both' | null;
  unscannable: 'pickup' | 'dropoff' | 'both' | null;
  unscannableAtDropoff?: string[];
  scannedAtDropoff?: string[];
  scannedForRelabel?: string[];
  quantityScannedAtPickup?: number;
  quantityUnscannableAtPickup?: number;
  outsidePriceList: boolean;

  delivered?: boolean;

  deliveryOptions?: Array<{
    name: string;
    note: string;
    photo: string;
    photoHint: string;
    photoOf: string;
  }>;

  bagReturnDropoff?: boolean;

  senderName?: string;
  senderEmail?: string;
  senderPhoneNumber?: string;
  tags?: string[];

  reverseLogisticsItem?: string;
  reverseLogisticsItemsCollected?: number;
}

export interface PickupWaypoint extends CoreWaypoint {
  price: number;

  cutoffTimeDisplay: string | null;
  startedEta?: string;

  dropoffCount?: number;
  quantityCount?: number;
}

export interface CoreWaypoint {
  id: string;
  type: 'pickup' | 'dropoff';

  merchantId: string;
  routeId: string;
  routeNumber?: number;
  assignedDriver?: string;
  courierId?: string;
  routeDate: string;
  timeZoneId?: string | undefined;
  trackingSecret?: string | null | undefined;

  name: string;
  address: string;
  addressLine2: string;
  lat: number;
  lng: number;
  note?: string;

  pickupMessage?: string;
  dropoffMessage?: string;
  integration?: {
    name: string;
    shopify?: {
      id: string;
      fulfillment_id?: string;
      deliveryType: string;
      storeName: string;
    };
  };

  createdAt: string;
  cutoffTime?: string | null;
  arrivedAt: string | null;
  arrivedLocation?: Location;
  arrivedAtDisplay: string | null;
  statusUpdatedAt: string | null;
  statusUpdatedAtDisplay: string | null;
  statusUpdatedLocation?: Location;

  travelDuration?: number;
  travelDurationDisplay?: string;
  travelDistance?: number;
  travelDistanceDisplay?: string;

  state: string;
  subState: string;

  api?: string;
  deleted: boolean;

  relativePriorityTime?: number;

  associatedBusiness: string;
  associatedOrder: string;
  associatedEmail: string;

  waypointNumber?: number;
  order?: number;

  estimatedStatusUpdateTime?: string | null;
  estimatedArrivalTime?: string | null;

  alerts?: Array<{
    type: string;
    display: string;
    message: string;
  }>;

  noCharge: boolean;
}

export type Waypoint = DropoffWaypoint | PickupWaypoint;

export interface WaypointLocation {
  type: string;
  lat: number;
  lng: number;
}

export interface AmountItem {
  title?: string;
  description?: string;
  amount?: number;
  displayOnly?: boolean;
}

export interface OfferTravel {
  duration: number;
  distance: number;
}

export interface RawPayDetails {
  basePay: number;
  bumpupPay: number;
  personalPay: number;
  payLimitReduction: number;
  surgeLimitReduction: number;
  courierTips: number;
  pay: number;
  details: PriceDetail[];
  toPickup?: OfferTravel;
  afterRoute?: OfferTravel;
}

export interface PayDetails {
  display?: AmountItem[];
  totalPay?: number;
  basePay?: number;
  details?: PriceDetail[];
  toPickup?: OfferTravel;
  afterRoute?: OfferTravel;
  pay?: number;
  personalPay?: number;
  payLimitReduction?: number;
  surgeLimitReduction?: number;
  courierTips?: number;
  bumpupPay?: number;
}

export interface PriceDetails {
  price: number;
  details?: PriceDetail[];
}

export interface RouteOffer {
  id: string;
  routeNumber?: number;
  assignedDriver?: string;
  courierId?: string;
  merchantId: string;
  businessName: string;
  duration: number;
  distance: number;
  distanceKm: number;
  pay: number;
  price: number;
  pickupName: string;
  pickupAddress: string;
  routeDate: string;
  polyline: string;
  dropoffCount: number;
  quantityCount: number;
  pickupCount: number;
  deleted: boolean;
  waypointLocations?: WaypointLocation[];
  cutoffTime?: string | null;

  createdAt: string;
  offeredAt?: string;
  willAutoDeclineAt?: string | null;
  timeZoneId?: string;

  scanner?: boolean;
  dropoffScanner?: boolean;
  acceptRouteFlow?: boolean;

  toPickup?: OfferTravel;
  afterRoute?: OfferTravel;
  payDetails?: PayDetails;
  priceDetails?: PriceDetails;
  activeOfferCount: number;
  state: string;
  numberOfIncidents?: number;
  capabilitiesNeeded?: string[];
}

export interface RouteErrorParameter {
  name: string;
  value: any;
  displayName: string;
  displayValue: string;
}

export interface RouteError {
  routeId: string;
  createdAt: string;
  reportedBy: string;
  resolvedBy: string;
  description: string;
  type: string;
  id: string;
  isResolved: boolean;
  state: string;
  resolution: {
    bumpUp: number;
    isInternal: boolean;
    notes: string;
  };
  parameters?: RouteErrorParameter[];
}

export interface Conditions {
  title: string;
  information: string;
}

export interface Note {
  ownerId?: string;
  ownerName?: string;
  content?: string;
}

export interface Route extends RouteOffer {
  deviceType?: 'android' | 'ios' | '' | null;
  endAtAddress?: string;
  mergedOrderIds: string[];
  mergedMerchantIds: string[];
  mergedParent: boolean;
  mergedToId: string;
  isComponent: boolean;
  willAutoSchedule: boolean;
  declinedCouriers: string[];

  autoScheduleOptions?: SchedulingOptions & { lastAutoScheduledAt: string };

  createdAtDisplay: string;
  acceptedAt?: string;
  startedAt?: string;
  startedEta?: {
    waypointId: string;
    timeToWaypoint: number;
  };

  waypoints: Waypoint[];
  removedWaypoints: Waypoint[];

  errors: RouteError[];
  hasErrors: boolean;
  hasUnresolvedErrors: boolean;
  deletedErrors: RouteError[];

  split?: boolean;

  originalPay?: number | null;
  isLineHaul?: boolean;
  routingGroup?: string;
  isMiddleMile?: boolean;
  middleMileGroup?: string;
  middleMileAddress?: string;
  middleMileAddressLat?: number;
  middleMileAddressLng?: number;

  declinedAt?: string;

  activeOffers: OfferModel[];
  declinedOffers: OfferModel[];

  pickUpconditions?: Conditions[];
  dropOffConditions?: Conditions[];

  startedLocation?: Location;
  acceptedLocation?: Location;

  pickupTimeChanges?: PickupTimeChange[] | null | undefined;
}

export interface Batch extends Omit<Route, 'pay'> {
  pay: undefined;
}

export interface PickupTimeChange {
  updatedAt?: string;
  updatedBy?: string;
  oldPickupTime?: string | null;
  newPickupTime: string;
  waypointId?: string | null;
}

export interface PriceDetail {
  change: number;
  details: string;
  category?: string | null | undefined;
  custom?: Record<string, unknown>;
}

export interface OfferModel {
  id: number;
  routeId: string;
  courierId: string;
  offerGroupId: string;
  state: string;
  pay: number;
  payDetails: PayDetails;
  toPickup: OfferTravel;
  afterRoute: OfferTravel;
  declineNote?: string;
  declineReason?: string;
  declinedAt?: string;
  declinedBy?: string;
  acceptedAt?: string;
  acceptedThrough?: string;
  willAutoDeclineAt?: string;
  createdAt: string;
  updatedAt: string;
}

export enum CourierDocumentStates {
  incomplete = 'Incomplete',
  awaitingApproval = 'Awaiting Approval',
  approved = 'Approved',
  expired = 'Expired',
}
export enum TypeOfCar {
  suv = 'SUV',
  pick = 'Pick-Up Truck',
  hatchback = 'Hatchback',
  sedan = 'Sedan',
  coupe = 'Coupe',
  minivan = 'Minivan',
  cargo = 'Cargo Van',
}
export enum SchedulingGroups {
  sameDay = 'Same Day',
  nextDay = 'Next Day',
}
export enum AvailabilityStatuses {
  paused = 'Paused',
  active = 'Active',
}
export enum CourierAgreementStates {
  incomplete = 'Incomplete',
  approved = 'Approved',
}

export enum CourierSignupStates {
  incomplete = 'Incomplete',
  completed = 'Completed',
}

export enum CourierStates {
  incomplete = 'Incomplete',
  awaitingApproval = 'Awaiting Approval',
  awaitingReapproval = 'Awaiting Reapproval',
  pendingTraining = 'Pending Training',
  onHold = 'On Hold',
  approved = 'Approved',
  expired = 'Expired',
  suspended = 'Suspended',
  rejected = 'Rejected',
}

export interface AvailabilityBlock {
  startTime: string;
  endTime: string;
  startNumber: number;
  endNumber: number;
}

export interface Authentication {
  iat: number;
  exp: number;
}

export interface Courier {
  id: string;
  email: string;
  fcmToken: string;
  userType: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  address: string;
  lat: number;
  lng: number;
  unitNumber: string;
  location: [number, number] | null;
  city: string;
  status: string;
  availabilityStatus: 'paused' | 'active';
  schedulingGroup: 'sameDay' | 'nextDay';
  state?: string;
  birthDate?: string | null;
  availabilityUpdatedAt?: string | null;
  signedUpAt?: string | null;
  completedAt?: string | null;
  loginStamps?: Authentication[] | null;
  documentUpdatedAt?: string | null;
  typeOfCar: keyof typeof TypeOfCar;
  totalDislikes: number;
  availabilityNote: string;
  availability: {
    [k in DayOfWeek]?: AvailabilityBlock[];
  };
  totalLikes: number;
  totalOffers: number;
  documents: CourierDocuments;
  documentsState?: keyof typeof CourierDocumentStates;
  agreements: CourierAgreements;
  agreementsState?: keyof typeof CourierAgreementStates;
  training: CourierTrainings;
  settingsSteps: CourierSettingsSteps;
  lastSteps: CourierLastSteps;
  approved: boolean;
  rejected: boolean;
  featureFlags: Record<string, boolean>;
  settings: {
    // disableAddressChange: boolean;
    goat: boolean;
    sameDay: boolean;
    surge: boolean;
    capabilities: string[];
  };
  tier: number;
  suspended?: {
    at: string;
    by: string;
    state: boolean;
  };
  approvedInformation?: {
    approvedAt: string;
    approvedBy: string;
  };
  quizReadTypeform: {
    formId: string;
    responseId: string;
  };
  signupState?: keyof typeof CourierSignupStates;
  bagReturnExperience: boolean;
  totalRoutes: number;
  lastRouteDate: string;
  checkForUpdates?: boolean;
  payoutInformation?: CourierPayoutInformation;
  referredBy: string;
  referredSource: string;
}

export interface CourierPayoutInformation {
  totalPayouts?: number;
  totalPayoutsAmount?: number;
  lastPayoutDate?: number | null;
  lastPayoutAmount?: number;
}

export interface CourierTrainings {
  [key: string]: {
    completed: boolean;
    state?: string;
  };
}

export interface CourierLastSteps {
  [key: string]: {
    completed: boolean;
    state?: string;
  };
}

export interface CourierSettingsSteps {
  [key: string]: {
    completed: boolean;
    state?: string;
  };
}

export interface CourierAgreements {
  [key: string]: CourierAgreement;
}

export interface CourierAgreement {
  completed: boolean;
  state?: string;
}

export interface CourierDocuments {
  [key: string]: CourierDocument;
}

export interface CourierDocument {
  approved: boolean;
  completed: boolean;
  uploaded: boolean;
  documentType?: string;
  expired?: boolean;
  expiringSoon?: boolean;
  expiryDate?: string | null;
  state?: keyof typeof CourierDocumentStates | null;
}

export interface Merchant {
  firstName: string;
  lastName: string;
  email: string;
  parentId?: string;
  parentIds: string[];
  fulfillmentParentIds: string[];
  fcmToken: string;
  userType: string;
  id: string;
  stripeId?: string;
  businessName: string;
  name: string;
  trackingKey: string;
  trackingSlug: string;
  leftAtDoorEmails: string[];
  bringBackEmails: string[];
  invoiceEmails: string[];
  excludedCouriers: string[];
  shopifyShopDomain: string;
  phoneNumber: string;
  priceList?: Record<string, number>;
  payList?: Record<string, number>;
  isFlatRatePay?: boolean;
  testAccount?: boolean;
  costPerOrder?: number;
  createdAt: string | null;
  address: string | null;
  processingFees?: {
    percentageRate?: number;
    flatRate?: number;
  };
  setupIntentId?: string;
  businessAddresses: Array<{
    locationDescription: string | undefined;
    businessAddress: string;
    businessLat: number;
    businessLng: number;
    imageFileName: string | undefined | null;
  }>;
  objectType: string;
  pricingType: string;
  subPricingType: 'fsa' | 'city' | undefined | null;
  deleted: boolean;
  type: string;
  settings: {
    parent?: boolean;
    zplLogo?: {
      length: number;
      rowlen: number;
      z64?: string;
      url: string;
    };
    batchCapacity: number;
    volumeCapacity: number;
    timeConstraint: number;
    removeDropoffNumberLabel: boolean;
    showUnroutedLabels: boolean;
    labelLogoImageUrl?: string;
    removePricelistDisplay: boolean;
    canChangeQuantity: boolean;
    additionalQuantityPrice: number;
    chargeFuelSurcharge: boolean;
    fuelSurchargeAnchor: { [key: string]: number };
    trackingPage?: {
      logoImageUrl: string;
      largeImageUrl: string;
      title: string;
      blurb: string;
      disableTip: boolean;
      supportEmail?: string;
      brandColor: string;
      background: 'light' | 'dark';
    };
    pickupMessage: string;
    dropoffMessage: string;
    preRouteMessage: string;
    merchantSlackChannel: string;
    deliveryOptions: string[];
    deliveryCutoffTime: string;
    reverseLogisticsEnabled: boolean;
    reverseLogisticsItem: string;
    reverseLogisticsDropoffMessage: string;
    reverseLogisticsAutomateReturn: boolean;
    reverseLogisticsReturnAddress?: {
      address: string;
      lat: number;
      lng: number;
    };
    schedulingNotes?: string;
    billingCadence?: 'weekly' | 'monthly' | 'biweekly' | 'none';
    routingGroups: RoutingGroupConfig[];
    routingInfoEmail?: string;
    recipientFromPhoneNumber?: string;
    sendRecipientNotificationEmails?: boolean;
    routingWebhook?: string;
    doNotScheduleUntil?: string;
    capabilitiesNeeded?: string[];
    excludeFromMassScheduling?: boolean;
    excludeFromAutoScheduling?: boolean;
    includeInAutoScheduling?: boolean;
    invoiceNotes?: string;
  };
  routingDays: string[];
  deliveryDays?: DeliveryDayConfig[];
  deliveryCutoffSettings?: {
    time: string;
  };
  featureFlags: Record<string, boolean>;
  chargeMethod?: 'credit-card' | 'quickbooks' | 'pad' | '';
  receiptType?: 'csv' | 'pdf' | '';
  quickbooksCustomerId?: string;
  invoiceAddressFull?: string;
  invoiceAddress?: {
    address1?: string;
    city?: string;
    state?: string;
    zip?: string;
  };
  signupAccessKey: string;
  totalBatches: number | null;
  lastRouteDate: string | null;
}

export interface DeliveryDayConfig {
  day: DayOfWeek;
  routingDay: DayOfWeek;
  routingTime: string;
  integrationPullTime: string;
}

export interface VehicleTypeGroup {
  name: string;
  type: 'Sprinter Van' | 'Cargo Van' | 'Minivan';
  capability?: boolean;
  endLocation?: {
    address: string;
    lat: number;
    lng: number;
  };
  count: number;
  maxDistance: number;
  costFactor: number;
}

export interface RoutingGroupConfig {
  name: string;
  pickUpLocation: {
    address: string;
    lat: number;
    lng: number;
  };
  pickupTime: string;
  pickupTimeStagger: number;
  pickupTimeStaggerGroup: number;
  timeConstraint?: number;
  isLineHaul: boolean;
  postalCodes: string;
  postalCodeMatch: string[];
  deliveryDays: DeliveryDayConfig[];
  isMiddleMile: boolean;
  middleMileGroup?: string;
  middleMileAddress?: {
    address: string;
    lat: number;
    lng: number;
  };
  vehicleTypes?: VehicleTypeGroup[];
}

export enum RoutingEngineNames {
  routific = 'Routific',
  logisticsos = 'LogisticsOS',
  vrpcli = 'VRP CLI',
}
export type RoutingEngineName = keyof typeof RoutingEngineNames;

export interface GlobalSettings {
  routingEngine: RoutingEngineName;
  gasPricePerLitre: { [key: string]: number };
  minimumHourlyWage: number;
  minimumHourlyPersonalWage: number;
  downtownPay: number;
  apartmentsPay: number;
  minutesAtPickup: number;
  fuelEfficiency: number;
  fuelSurcharge: number;
  fuelIncrement: number;
  hourlyPayLimit?: number;
  autoScheduleSettings: {
    enable: boolean;
    startTime: string;
    endTime: string;
    openToAllTime: string;

    offerIncrement?: number;
    forRouteRadius?: number;
    maxOffersPerCourier?: number;
    maxOffersPerRoute?: number;
    payThresholdPercentage?: number;
    filterByPickupTime?: boolean;
    hideDeclined?: boolean;
    lockCleaner?: boolean;
    lockCleanerMinutes?: number;
  };
  autoSurgeSettings: {
    enable: boolean;
    startTimeNight: string;
    endTimeNight: string;
    startTimeMorning: string;
    endTimeMorning: string;
    doubleDownStartTime: string;
    enableDoubleDown: boolean;
  };
}

export interface RawOrder {
  id: string;
  merchantEmail: string;
  orderId: string;
  packageId: string;
  createdAt: number;
  date: string;
  name: string;
  phoneNumber: string;
  email?: string;
  addressType?: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  province: string;
  postalCode: string;
  notes?: string;
  quantity?: number;
  geolocation: {
    formattedAddress: string;
    latitude: number;
    longitude: number;
  };
  status?: string;
  integration: string;
  custom?: Record<string, unknown>;
  relativePriorityTime?: number | null;
  tags?: string;
  senderName?: string;
  senderEmail?: string;
  senderPhoneNumber?: string;
  trackingSecret?: string;
}

export type DayOfWeek =
  | 'sunday'
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday';

export enum DayOfWeekList {
  sunday = 'Sunday',
  monday = 'Monday',
  tuesday = 'Tuesday',
  wednesday = 'Wednesday',
  thursday = 'Thursday',
  friday = 'Friday',
  saturday = 'Saturday',
}

export interface Credit {
  amount: number;
  reason: string;
  created: number;
  createdBy: string;
  email: string;
  orderId?: string;
  creditApplied: boolean;
  id: string;
  province?: string;
}

export interface Debit {
  amount: number;
  reason: string;
  created: number;
  createdBy: string;
  email: string;
  description: string;
  debitApplied: boolean;
  id: string;
  province?: string;
}

export interface InvoiceOrder {
  adjustments?: Adjustment[];
  day: string;
  dropOffs: number;
  orderId: string;
  price: number;
  priceDetails?: PriceDetails;
}

export interface Adjustment {
  isInternal: boolean;
  amount: number;
  reason: string;
}

export enum InvoiceState {
  NotPaid = 'NOT_PAID',
  Error = 'ERROR',
  Paid = 'PAID',
  Pending = 'PENDING',
}

export interface Invoice {
  note: string;
  state: InvoiceState;
  padId: string;
  orders: InvoiceOrder[];
  credits: Credit[];
  debits?: Debit[];
  price: number;
  tax: number;
  priceWithTax: number;
  processingFee: number;
  creditAmount: number;
  debitAmount?: number;
  numberOfOrders: number;
  numberOfBatches: number;
  billingPeriod: string;
  periodStart: number;
  periodEnd: number;
  created: number;
  chargeId: string;
  chargeMethod: 'credit-card' | 'quickbooks' | 'pad';
  attachmentUrls: string[];
  email: string;
  qbInvoiceNumber: string;
  id: string;
  priceByProvince: Record<string, number>;
  taxByProvince: Record<string, number>;
}

export interface Incident {
  id: number;
  routeId?: string;
  merchantId?: string;
  courierId?: string;
  waypointId?: string;
  source: string;
  causedBy: string;
  status: string;
  type: string;
  subType: string;
  category: string;
  description?: string;
  routeDate?: string;
  closedReason?: string;
  pendingAction?: string;
  createdAt: string;
  updatedAt: string;
  reportedBy?: string;
}

export interface TextTemplate {
  template?: string;
  priority?: number;
  tag?: string;
  time?: string;
}

export interface TextMessageConfig {
  dayBefore?: TextTemplate[];
  nightBefore?: TextTemplate[];
  completed?: TextTemplate[];
  initial?: TextTemplate[];
}

export enum TagDateFormats {
  'YYYY-MM-DD' = 'YYYY-MM-DD',
  'DD-MM-YYYY' = 'DD-MM-YYYY',
  'DD/MM/YYYY' = 'DD/MM/YYYY',
  'MM/DD/YY' = 'MM/DD/YY',
  'dddd' = 'dddd',
}

export enum ShopifyPullTypes {
  'pull' = 'Pull',
  'refresh' = 'Refresh',
  'pause' = 'Pause',
}

export enum ShopifyQuantityTypes {
  'onePerOrder' = 'One Per Order',
  'lineItemQuantity' = 'Line Item Quantity',
}

export enum ShopifyMarkFulfilledTypes {
  '' = 'No',
  'onPulling' = 'On Pulling',
  'onRouting' = 'On Routing',
}

export type Awaited<T> = T extends PromiseLike<infer U> ? U : T;

export interface SchedulingOptions {
  forRouteRadius?: number;
  maxOffersPerCourier?: number;
  maxOffersPerRoute?: number;
  payThresholdPercentage?: number;
  filterByPickupTime?: boolean;
  hideDeclined?: boolean;
}

export interface Tip {
  id: string;
  amount: number;
  courierAmount: number;
  tyltgoAmount: number;
  createdAt: string;
  merchantId: string;
  routeId: string;
  waypointId: string;
  stripeTransactionId: string;
}
