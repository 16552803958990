<template>
  <div class="rounded-md p-4 dark:text-gray-800" :class="colors.bg">
    <div class="flex">
      <div class="flex-shrink-0">
        <div class="h-5 w-5" :class="colors.icon">
          <CheckCircleIcon v-if="color === 'success'" aria-hidden="true" />
          <XCircleIcon v-else-if="color === 'danger'" aria-hidden="true" />
          <ExclamationIcon v-else-if="color === 'warning'" aria-hidden="true" />
          <InformationCircleIcon v-else aria-hidden="true" />
        </div>
      </div>
      <div class="ml-3 space-y-2">
        <h3 v-if="title" class="text-sm font-bold" :class="colors.title">{{ title }}</h3>
        <div v-if="message" class="text-sm" :class="colors.message">
          <p>
            {{ message }}
          </p>
        </div>
        <div v-if="$slots.default">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import {
  CheckCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
  ExclamationIcon,
} from '@heroicons/vue/solid';

const styles = {
  primary: {
    bg: 'bg-primary-50',
    icon: 'text-primary-400',
    title: 'text-primary-800',
    message: 'text-primary-700',
  },
  success: {
    bg: 'bg-green-50',
    icon: 'text-green-400',
    title: 'text-green-800',
    message: 'text-green-700',
  },
  information: {
    bg: 'bg-blue-50',
    icon: 'text-blue-400',
    title: 'text-blue-800',
    message: 'text-blue-700',
  },
  danger: {
    bg: 'bg-red-50',
    icon: 'text-red-400',
    title: 'text-red-800',
    message: 'text-red-700',
  },
  warning: {
    bg: 'bg-yellow-50',
    icon: 'text-yellow-400',
    title: 'text-yellow-800',
    message: 'text-yellow-700',
  },
};

export default defineComponent({
  components: {
    CheckCircleIcon,
    InformationCircleIcon,
    XCircleIcon,
    ExclamationIcon,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    message: {
      type: String,
      required: false,
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
  computed: {
    colors() {
      return styles[this.color];
    },
  },
});
</script>
