/* eslint-disable import/prefer-default-export */
import { parse, ParseConfig, ParseResult } from 'papaparse';
import { DropoffWaypoint, Merchant, RawOrder, Route, Waypoint } from '@tyltgo/shared';
import dayjs from 'dayjs';
import router from '@/router';

export const parseCsv = <T>(data: string, options: ParseConfig<T>): ParseResult<T> => {
  // Remove any non-ASCII characters from the start of the file
  // eslint-disable-next-line no-param-reassign, no-control-regex
  data = data.replace(/^[^\x00-\x7F]*/, '');

  return parse(data, options);
};

function trackingLinkUrl(waypoint: Waypoint, merchant: Merchant | undefined) {
  if (!merchant) return undefined;
  const params = {
    name: merchant.trackingSlug ? 'track.id.trackingsecret' : 'tracking.id.trackingsecret',
    params: {
      trackingSlug: merchant.trackingSlug,
      id: waypoint.id,
      trackingSecret: waypoint.trackingSecret || 't',
    },
  };

  const resolved = router.resolve(params);
  const { protocol, host } = window.location;
  return `${protocol}//${host}${resolved.fullPath}`;
}

export const routeToCsvHash = (
  route: Route,
  type: 'admin' | 'merchant' = 'merchant',
  merchant: Merchant | undefined = undefined
) => {
  return route.waypoints
    .filter(point => point.type === 'dropoff')
    .map((point: DropoffWaypoint) => {
      const trackingLink = trackingLinkUrl(point, merchant);
      const pickup = route.waypoints
        .filter(p => p.type === 'pickup')
        .find(p => p.associatedOrder === point.associatedOrder);
      const additionalColumns =
        type === 'admin'
          ? {
              State: point.state,
              'Sub State': point.subState,
              Status: point.status,
              Unpicked: point.unpicked,
              'Arrived Time': point.arrivedAt ? dayjs(point.arrivedAt).format('h:mm A') : undefined,
              'Status Updated Time': point.statusUpdatedAt
                ? dayjs(point.statusUpdatedAt).format('h:mm A')
                : undefined,
              Merchant: point.merchantId,
            }
          : {};
      return {
        'Order ID': point.id,
        'Route ID': route.id,
        'Route Number': route.routeNumber,
        'Stop Number': point.stopNumber || point.waypointNumber,
        'Route Date': point.routeDate,
        Name: point.name,
        Address: point.address,
        'Address Line 2': point.addressLine2,
        'Phone Number': point.phoneNumber,
        Email: point.email,
        Note: point.note,
        Quantity: point.quantity,
        'Pickup Time': route.cutoffTime ? dayjs(route.cutoffTime).format('h:mm A') : undefined,
        'Pickup Address': pickup?.address,
        Linehaul: route.isLineHaul ? route.routingGroup : '',
        'Tracking Link': trackingLink,
        ...additionalColumns,
      };
    });
};

export const rawOrderToCsvHash = (rawOrder: RawOrder, type: 'admin' | 'merchant' = 'merchant') => {
  const additionalColumns =
    type === 'admin'
      ? {
          Merchant: rawOrder.merchantEmail,
        }
      : {};
  return {
    'Order ID': rawOrder.packageId,
    'Route ID': 'UNROUTED',
    'Route Number': 'UNROUTED',
    'Stop Number': 'UNROUTED',
    'Route Date': rawOrder.date,
    Name: rawOrder.name,
    Address: rawOrder.geolocation.formattedAddress,
    'Address Line 2': rawOrder.addressLine2,
    'Phone Number': rawOrder.phoneNumber,
    Email: rawOrder.email,
    Note: rawOrder.notes,
    Quantity: rawOrder.quantity,
    'Pickup Time': '',
    'Pickup Address': '',
    Linehaul: '',
    ...additionalColumns,
  };
};
