<template>
  <div class="bg-white dark:bg-gray-800 p-4 shadow rounded-sm mb-4 flex border-l-4 flex-col">
    <div v-if="edit">
      <Form @submit="updateOrder" :initial-values="{ order }" v-slot="{ values }">
        <div class="mt-2 pt-2 border-t-2">
          <div class="flex justify-between items-center w-full">
            <div class="grid grid-cols-4 gap-y-1 gap-x-4 w-full">
              <GoHiddenField :name="`order.custom`" :value="order.custom" />
              <GoHiddenField :name="`order.integration`" :value="order.integration" />
              <GoTextField
                class="col-span-4 sm:col-span-1"
                label="Name"
                type="text"
                :name="`order.name`"
                :value="order.name"
              />
              <GoTextField
                class="col-span-4 sm:col-span-2"
                label="Address"
                type="search"
                :name="`order.geolocation.formattedAddress`"
                v-model="address"
                @place-selected="placeSelected"
                google-places
                placeholder="Search for address"
              />
              <GoHiddenField :name="`order.geolocation.latitude`" v-model="lat" />
              <GoHiddenField :name="`order.geolocation.longitude`" v-model="lng" />

              <GoTextField
                class="col-span-4 sm:col-span-1"
                label="Address Line 2"
                type="text"
                :name="`order.addressLine2`"
                :value="order.addressLine2"
              />
              <GoTextField
                class="col-span-4 sm:col-span-1"
                label="Phone Number"
                type="text"
                :name="`order.phoneNumber`"
                :value="order.phoneNumber"
              />
              <GoTextField
                class="col-span-4 sm:col-span-2"
                label="Note"
                type="text"
                :name="`order.notes`"
                :value="order.notes"
              />
              <GoTextField
                class="col-span-4 sm:col-span-1"
                label="Quantity"
                type="number"
                :name="`order.quantity`"
                :value="order.quantity"
              />
              <GoTextField
                class="col-span-4 sm:col-span-2"
                label="Note"
                type="text"
                :name="`order.email`"
                :value="order.email"
              />
            </div>
          </div>
          <div class="border-t mt-4 pt-2 flex justify-between">
            <GoButton variant="link" class="text-xs" @click="edit = false" type="button">
              Cancel
            </GoButton>
            <GoButton class="text-xs">Save</GoButton>
          </div>
        </div>
      </Form>
    </div>
    <div v-else class="flex flex-col sm:flex-row justify-between">
      <div class="flex flex-col w-full sm:w-2/5 mb-1">
        <div class="flex items-center">
          <span
            class="font-header font-bold text-xs text-gray-500 dark:text-gray-400 ml-2"
            v-if="order.type === 'dropoff'"
          >
            {{ order.id }}
          </span>
        </div>
        <div class="flex flex-col">
          <span class="text-sm text-gray-400">
            {{ order.orderId }}
          </span>
          <span>
            {{ order.name }}
          </span>
          <GoTooltip
            text="Phone Number"
            class="flex space-x-2 items-center"
            v-if="order.phoneNumber"
          >
            <IconPhone class="flex-none w-3 mt-px text-gray-600 dark:text-gray-300" />
            <span class="text-sm text-gray-500 dark:text-gray-400">
              {{ order.phoneNumber }}
            </span>
          </GoTooltip>
          <GoTooltip text="Email" class="flex space-x-2 items-center" v-if="order.email">
            <IconEnvelope class="flex-none w-3 mt-px text-gray-600 dark:text-gray-300" />
            <span class="text-sm text-gray-500 dark:text-gray-400">
              {{ order.email }}
            </span>
          </GoTooltip>
          <div class="flex space-x-2 items-start" v-if="order.quantity && !order.bagReturnDropoff">
            <GoTooltip text="Quantity" class="flex space-x-2">
              <IconBoxOpen class="w-4 text-gray-600 dark:text-gray-300" />
              <div class="text-sm font-body align-middle">{{ order.quantity }}</div>
            </GoTooltip>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-full sm:w-3/5">
        <div class="flex sm:justify-end mb-2 space-x-2 items-center">
          <GoBadge class="bg-pink-200 text-pink-700">
            <span>Unrouted</span>
          </GoBadge>
          <GoDropdown>
            <template v-slot:button>
              <IconEllipsis class="flex-none w-3 text-gray-600 dark:text-gray-300" />
            </template>
            <template v-slot:items>
              <GoDropdownItem v-if="!edit" @click="edit = true">Edit</GoDropdownItem>
              <GoDropdownItem @click="remove">Remove</GoDropdownItem>
              <GoDropdownItem @click="downloadLabels('zpl')">ZPL Labels</GoDropdownItem>
              <GoDropdownItem @click="downloadLabels('pdf')">PDF Labels</GoDropdownItem>
            </template>
          </GoDropdown>
        </div>
        <div class="flex flex-col space-y-1 sm:justify-end mb-1">
          <GoTooltip text="Address" class="flex space-x-2 items-start">
            <IconMapMarker class="flex-none w-3 mt-px text-gray-600 dark:text-gray-300" />
            <div class="flex flex-col">
              <p class="text-sm font-body align-middle">
                {{ order.geolocation?.formattedAddress }}
              </p>
              <p class="text-sm font-body align-middle">{{ order.addressLine2 }}</p>
              <span class="text-xs to-gray-400">{{ order.addressType }}</span>
            </div>
          </GoTooltip>
          <GoTooltip text="Order Note" class="flex space-x-2 items-start" v-if="order.notes">
            <IconClipboard class="flex-none w-3 mt-px text-gray-600 dark:text-gray-300" />
            <p class="text-sm font-body align-middle">{{ order.notes }}</p>
          </GoTooltip>
        </div>
        <div>
          <GoTooltip
            text="Courier Note"
            class="flex space-x-2 items-start"
            v-if="order.courierNote"
          >
            <IconCar class="flex-none w-3 mt-1 text-gray-600 dark:text-gray-300" />
            <p class="text-sm font-body align-middle">{{ order.courierNote }}</p>
          </GoTooltip>
          <div
            class="flex space-x-2 items-start border-2 bg-pink-100 border-pink-400 text-pink-700 px-2 py-1 rounded-md"
            v-if="order.unpicked"
          >
            <IconCar class="flex-none w-3 mt-1 text-gray-600 dark:text-gray-300" />
            <p class="text-sm font-body align-middle">{{ order.unpickedReason }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { apiService, convertZplToPdf, rawOrderZpl, Merchant } from '@tyltgo/shared';
import * as yup from 'yup';
import { Form } from 'vee-validate';
import IconCar from './IconCar.vue';
import IconEllipsis from './IconEllipsis.vue';
import GoBadge from './GoBadge.vue';
import { time, timezoneConversionRouteInput } from '../services/date-service';
import IconClipboard from './IconClipboard.vue';
import GoTooltip from './GoTooltip.vue';
import GoDropdown from './GoDropdown.vue';
import GoDropdownItem from './GoDropdownItem.vue';
import { showConfirmationModal } from '../services/ui-message-service';
import { currentUser } from '../services/auth-service';
import { downloadBlob } from '../services/browser-service';
import IconEnvelope from './IconEnvelope.vue';

const quantitySchema = yup.object().shape({
  quantity: yup.number().required().label('Quantity'),
});

export default defineComponent({
  emits: ['updateUnroutedOrders'],
  setup() {
    return {
      time,
      timezoneConversionRouteInput,
      currentUser,
      quantitySchema,
    };
  },
  components: {
    Form,
    IconCar,
    IconEllipsis,
    GoBadge,
    IconClipboard,
    GoTooltip,
    GoDropdown,
    GoDropdownItem,
    IconEnvelope,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { edit: false, lat: null, lng: null, address: null };
  },
  computed: {},
  created() {
    this.lat = this.order.lat;
    this.lng = this.order.lng;
    this.address = this.order.address;
  },
  methods: {
    placeSelected(place, _name) {
      this.address = place.formatted_address;
      this.lat = place.geometry.location.lat();
      this.lng = place.geometry.location.lng();
    },
    async updateOrder(values) {
      console.log(values);
      await apiService.merchants.batches.updateRawOrder(this.order.id, values);
      this.edit = false;
      this.$emit('updateUnroutedOrders');
    },
    async remove() {
      const confirmed = await showConfirmationModal({
        color: 'danger',
        title: 'Confirm Order Removal',
        message: `Are you sure you want to remove order ${this.order.orderId}?`,
        confirmButtonText: 'Remove Order',
      });
      if (confirmed) {
        await apiService.merchants.batches.removeRawOrder(this.order.id);
        this.$emit('updateUnroutedOrders');
      }
    },
    createZplLabel(merchant: Merchant) {
      return rawOrderZpl({
        rawOrder: this.order,
        merchant,
      });
    },
    async downloadLabels(fileType: 'pdf' | 'zpl') {
      const merchant = this.currentUser;
      let blob = new Blob([this.createZplLabel(merchant)], { type: 'x-application/zpl' });
      if (fileType === 'pdf') {
        const pdfData = await convertZplToPdf(blob);
        blob = new Blob([pdfData], { type: 'application/pdf' });
      }
      downloadBlob(blob, `${this.order.date}-${this.order.id}.${fileType}`);
    },
  },
});
</script>
