<template>
  <div class="">
    <div
      v-for="incident of incidents"
      :key="incident.id"
      class="w-full py-2 px-4 border-t-2 bg-white dark:bg-gray-800"
    >
      <IncidentLine :incident="incident" @reloadIncident="loadIncidents" />
    </div>
  </div>
</template>

<script lang="ts">
import { apiService, Route } from '@tyltgo/shared';
import { defineComponent, PropType } from 'vue';
import IncidentLine from './IncidentLine.vue';

export default defineComponent({
  components: {
    IncidentLine,
  },
  props: {
    route: {
      type: Object as PropType<Route>,
      required: true,
    },
  },
  async mounted() {
    await this.loadIncidents();
  },
  data() {
    return {
      incidents: [],
    };
  },
  methods: {
    async loadIncidents() {
      const results = await apiService.admin.incidents.getRouteIncidents(this.route.id);
      this.incidents = results.incidents;
    },
  },
});
</script>
