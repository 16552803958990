<template>
  <div class="flex flex-col md:flex-row relative">
    <div class="p-2 sm:p-4 md:w-1/4 relative self-start md:sticky flex-grow-0 top-0">
      <div class="">
        <div
          class="flex flex-col md:block mb-1 md:mb-4"
          v-for="[section, steps] of Object.entries(groupedSteps)"
          :key="section"
        >
          <p class="md:mb-2 text-xs font-semibold text-gray-500 uppercase tracking-wider pl-2">
            {{ startCase(section) }}
          </p>

          <div class="flex flex-wrap md:block">
            <div v-for="step of steps" :key="step.name" class="flex items-center group">
              <router-link
                :to="{
                  name: stepRouteName,
                  params: { name: step.name, section: step.section },
                }"
                class="block flex items-center md:mb-1 w-full group-hover:bg-gray-200 pl-2 py-1 px-2"
                :class="
                  $route.params.name == step.name ? 'bg-gray-300 group-hover:bg-gray-300' : ''
                "
              >
                <span
                  class="inline-block mr-2 h-3 w-3 rounded-full flex items-center justify-center"
                >
                  <span
                    class="inline-block h-2 w-2 rounded-full flex-shrink-0"
                    :class="
                      {
                        expired: 'bg-red-500 group-hover:bg-red-800',
                        incomplete: 'bg-gray-500 group-hover:bg-gray-800',
                      }[stepState(section, step.name)]
                    "
                    v-if="['expired', 'incomplete'].includes(stepState(section, step.name))"
                  ></span>
                  <IconCheckCircle
                    class="w-3 flex-shrink-0 text-green-500"
                    v-if="['awaitingApproval', 'approved'].includes(stepState(section, step.name))"
                  />
                </span>
                <span class="text-gray-600 group-hover:text-gray-800">
                  {{ step.title || startCase(step.name) }}
                </span>
                <span
                  class="ml-2 text-xs font-semibold text-gray-500 uppercase tracking-wider"
                  v-if="stepState(section, step.name) === 'expired'"
                  :class="courierStepTextStyle(stepState(section, step.name))"
                >
                  {{ stepState(section, step.name) }}
                </span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="p-2 md:p-4 bg-white w-full">
      <slot v-if="$route.params.section === 'completed' && $slots.default"></slot>
      <component
        v-else-if="step"
        :is="stepComponents[step.section]"
        :options="step.options"
        :courier="currentUser"
        :name="step.name"
        :key="$route.path"
        @completed="stepCompleted"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  courierDisplayState,
  courierStyle,
  courierStepTextStyle,
  courierDocumentDisplayState,
  courierDocumentState,
  findCourierStep,
  courierStepsGrouped,
  courierProfileMessage,
  findRemainingSteps,
} from '@tyltgo/shared';
import startCase from 'lodash/startCase';
import { defineComponent, markRaw } from 'vue';
import IconCheckCircle from './IconCheckCircle.vue';
import CourierAgreementStep from './CourierAgreementStep.vue';
import CourierDocumentStep from './CourierDocumentStep.vue';
import CourierTrainingStep from './CourierTrainingStep.vue';
import CourierLastStep from './CourierLastStep.vue';
import CourierSettingsStep from './CourierSettingsStep.vue';
import GoAlert from './GoAlert.vue';
import { currentUser, loadCurrentUser } from '../services/auth-service';

const stepComponents = {
  agreements: markRaw(CourierAgreementStep),
  documents: markRaw(CourierDocumentStep),
  training: markRaw(CourierTrainingStep),
  settingsSteps: markRaw(CourierSettingsStep),
  lastSteps: markRaw(CourierLastStep),
};

export default defineComponent({
  emits: ['stepsCompleted'],
  setup() {
    return {
      startCase,
      currentUser,
      courier: currentUser,
      courierDisplayState,
      courierStyle,
      courierStepTextStyle,
      courierDocumentDisplayState,
      courierDocumentState,
      stepComponents,
      courierProfileMessage,
    };
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    stepRouteName: {
      type: String,
      required: true,
    },
  },
  components: {
    IconCheckCircle,
    GoAlert,
  },
  watch: {
    $route(to, _from) {
      if (to.name === this.stepRouteName) {
        const { name, section } = this.$route.params;
        this.step = findCourierStep(name, section);
      }
    },
  },
  mounted() {
    if (this.$route.params.name) {
      const { name, section } = this.$route.params;
      this.step = findCourierStep(name, section);
    }
  },
  data() {
    return {
      step: null,
    };
  },
  computed: {
    groupedSteps() {
      return courierStepsGrouped(this.steps);
    },
  },
  methods: {
    stepState(section: string, stepName: string) {
      return this.courier[section]?.[stepName]?.state || 'incomplete';
    },
    async stepCompleted(name: string) {
      await loadCurrentUser('courier');
      const stepsList = this.steps.map(step => step.name);
      const index = stepsList.indexOf(name);

      const remainingSteps = findRemainingSteps(this.currentUser, this.steps);
      const remainingStepNames = remainingSteps.reduce(
        (hash, step) => ({ ...hash, [step.name]: true }),
        {}
      );

      const nextStep = this.steps.slice(index).find(step => remainingStepNames[step.name]);
      if (nextStep) {
        this.$router.push({
          name: this.stepRouteName,
          params: { name: nextStep.name, section: nextStep.section },
        });
        return;
      }

      const previousStep = this.steps.slice(0, index).find(step => remainingStepNames[step.name]);
      if (previousStep) {
        this.$router.push({
          name: this.stepRouteName,
          params: { name: previousStep.name, section: previousStep.section },
        });
        return;
      }

      this.$emit('stepsCompleted');
      this.$router.push({
        name: this.stepRouteName,
        params: { section: 'completed', name: 'done' },
      });
    },
  },
});
</script>
