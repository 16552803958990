<template>
  <main class="flex-1 overflow-y-auto focus:outline-none" tabindex="0">
    <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
      <div class="pt-10 pb-16">
        <div class="px-4 sm:px-6 md:px-0 mb-8">
          <GoHeader :level="1">Admin Search</GoHeader>
        </div>
        <div class="px-4 sm:px-6 md:px-0">{{ q }}</div>
        <div class="px-4 sm:px-6 md:px-0">
          <CourierCard
            v-for="courier of couriers"
            :key="courier.id"
            :courier="courier"
            :forSearch="true"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import debounce from 'lodash/debounce';
import { defineComponent } from 'vue';
import { apiService } from '@tyltgo/shared';
import GoTextField from '../../components/GoTextField.vue';
import SearchPanel from '../../components/SearchPanel.vue';
import WaypointCard from '../../components/WaypointCard.vue';
import CourierCard from '../../components/CourierCard.vue';
import RouteCard from '../../components/RouteCard.vue';
import MerchantCard from '../../components/MerchantCard.vue';

export default defineComponent({
  components: {
    GoTextField,
    SearchPanel,
    WaypointCard,
    CourierCard,
    RouteCard,
    MerchantCard,
  },
  data() {
    return {
      couriers: [],
      q: '',
      searchResults: {},
      searchLoading: false,
      searchSet: false,
    };
  },
  async beforeMount() {
    this.setFilters();
    this.search();
  },
  methods: {
    setFilters() {
      this.q = this.$route.query.q;
    },
    search: async function search() {
      const api = {
        couriers: apiService.admin.couriers.search,
      };

      this.searchLoading = true;
      const results: any = {};
      for (const [name, call] of Object.entries(api)) {
        // eslint-disable-next-line no-await-in-loop
        results[name] = await call(this.q);
      }
      const couriers = results.couriers?.data;
      if (couriers.length === 1) {
        this.$router.push({ name: 'admin.courier', params: { id: couriers[0].id } });
      } else if (!couriers.length) {
        this.$router.push({ name: 'admin.search', query: { searchText: this.q } });
      }
      this.couriers = couriers || [];
      this.searchLoading = false;
      this.searchSet = true;
      this.searchResults = results;
    },
  },
});
</script>
