/* eslint-disable import/prefer-default-export */
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc'; // dependent on utc plugin
import { sortBy } from 'lodash';
import * as yup from 'yup';
import { AvailabilityBlock, Courier } from '../types';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);

// Time is in the 24 hour format
export const timeToNumber = (timeString: string): number => {
  const [hours, minutes] = timeString.split(':');
  const number = Number(hours) + Number(minutes) / 60;
  return Number(number.toFixed(2));
};

const addNumbersToBlock = (
  block: Omit<AvailabilityBlock, 'startNumber' | 'endNumber'>
): AvailabilityBlock => {
  return {
    ...block,
    startNumber: timeToNumber(block.startTime),
    endNumber: timeToNumber(block.endTime),
  };
};

const addMinuteToTimeString = (time: string): string => {
  let [hour, minutes] = time.split(':');
  if (minutes === '59') {
    minutes = '00';
    hour = `0${Number(hour) + 1}`.slice(-2);
  } else {
    minutes = `0${Number(minutes) + 1}`.slice(-2);
  }
  const result = `${hour}:${minutes}`;
  return result;
};

const subtractMinuteFromTimeString = (time: string): string => {
  let [hour, minutes] = time.split(':');
  if (minutes === '00') {
    minutes = '59';
    hour = `0${Number(hour) - 1}`.slice(-2);
  } else {
    minutes = `0${Number(minutes) - 1}`.slice(-2);
  }
  const result = `${hour}:${minutes}`;
  return result;
};

export const collapseAvailabilityBlocks = (
  availabilityBlocks: Omit<AvailabilityBlock, 'startNumber' | 'endNumber'>[]
): AvailabilityBlock[] => {
  if (!availabilityBlocks) return [];

  const blocks: AvailabilityBlock[] = sortBy(availabilityBlocks.map(addNumbersToBlock), [
    'startNumber',
  ]);

  // taken and modified from https://stackoverflow.com/questions/26390938/merge-arrays-with-overlapping-values
  function merge(ranges: AvailabilityBlock[]) {
    const result: AvailabilityBlock[] = [];
    let last: AvailabilityBlock;

    ranges.forEach(r => {
      if (!last || r.startNumber > last.endNumber) {
        result.push((last = r));
      } else if (r.endNumber > last.endNumber) {
        last.endNumber = r.endNumber;
        last.endTime = r.endTime;
      }
    });

    return result;
  }

  return merge(blocks) as AvailabilityBlock[];
};

export const collapseAvailability = (
  availability: Courier['availability']
): Courier['availability'] => {
  const collapsedAvailability = Object.entries(availability || {}).reduce((acc, [day, blocks]) => {
    return {
      ...acc,
      [day]: collapseAvailabilityBlocks(blocks),
    };
  }, {} as Courier['availability']);
  return collapsedAvailability;
};

export const fullDayAvailabilityBlocks = (
  availabilityBlocks: Omit<AvailabilityBlock, 'startNumber' | 'endNumber'>[]
) => {
  const results: (AvailabilityBlock & {
    available: boolean;
    length?: number;
    percentage?: number;
  })[] = [];

  const blocks = collapseAvailabilityBlocks(availabilityBlocks);

  // add last block if needed
  const first = blocks[0];
  if (first && first.startNumber > 8) {
    results.push({
      ...addNumbersToBlock({
        startTime: '08:00',
        endTime: subtractMinuteFromTimeString(first.startTime),
      }),
      available: false,
    });
  }

  blocks.forEach((block, index) => {
    results.push({
      ...block,
      available: true,
    });

    const next = blocks[index + 1];
    if (next && next.startNumber !== block.endNumber + 0.02) {
      results.push({
        ...addNumbersToBlock({
          startTime: addMinuteToTimeString(block.endTime),
          endTime: subtractMinuteFromTimeString(next.startTime),
        }),
        available: false,
      });
    }
  });

  // add last block if needed
  const last = results[results.length - 1];
  if (!last) {
    results.push({
      ...addNumbersToBlock({
        startTime: '08:00',
        endTime: '21:00',
      }),
      available: false,
    });
  } else if (last.endNumber < 21) {
    results.push({
      ...addNumbersToBlock({
        startTime: addMinuteToTimeString(last.endTime),
        endTime: '21:00',
      }),
      available: false,
    });
  }

  const blocksWithLength = results.map(block => {
    return {
      ...block,
      length: Number((block.endNumber - block.startNumber).toFixed(2)),
    };
  });
  const totalLength = blocksWithLength.reduce((total, block) => total + block.length, 0);
  const blocksWithPercentages = blocksWithLength.map(block => {
    const length = block.length || 0;
    return {
      ...block,
      percentage: Number(Math.round((length / totalLength) * 100)),
    };
  });

  return blocksWithPercentages;
};

const availabilityBlockSchema = yup.array().of(
  yup.object().shape({
    startTime: yup.string().required().label('Start Time'),
    endTime: yup
      .string()
      .required()
      .label('End Time')
      .when('startTime', (startTime: string, schema: yup.StringSchema) => {
        if (!startTime) return schema.test('no-op', '', () => true);
        return schema.test('is-greater', 'End time must come after start time', (value: any) => {
          return value > startTime;
        });
      }),
  })
);

export const availabilityDays = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

export const availabilitySchema = yup.object().shape({
  availability: yup.object().shape(
    availabilityDays.reduce((acc, day) => {
      return {
        ...acc,
        [day]: availabilityBlockSchema,
      };
    }, {})
  ),
});

export const isOverlapping = (
  a: { startNumber: number; endNumber: number },
  b: { startNumber: number; endNumber: number }
) => {
  const earlierRoute = a.startNumber <= b.startNumber ? a : b;
  const laterRoute = a.startNumber <= b.startNumber ? b : a;

  earlierRoute.endNumber += 0.75;
  return earlierRoute.endNumber >= laterRoute.startNumber;
};
