<template>
  <main class="flex-1 overflow-y-auto focus:outline-none" tabindex="0">
    <div class="relative max-w-7xl mx-auto md:px-8 xl:px-0">
      <div class="pt-10 pb-16">
        <div class="px-4 sm:px-6 md:px-0">
          <GoHeader :level="1">Courier Payouts</GoHeader>
        </div>
        <div class="px-4 sm:px-6 md:px-0">
          <div class="py-6">Last 200 Payouts</div>
          <div v-if="payouts.length">
            <div class="flex mb-4 space-x-4">
              <div class="shadow-md rounded-md py-4 px-8 bg-white text-center">
                <span class="font-bold block mb-2">Couriers</span>
                <span>{{ payouts.length }}</span>
              </div>
              <div class="shadow-md rounded-md py-4 px-8 bg-white text-center">
                <span class="font-bold block mb-2">Routes</span>
                <span>{{ payouts.reduce((total, p) => p.orders.length + total, 0) }}</span>
              </div>
              <div class="shadow-md rounded-md py-4 px-8 bg-white text-center">
                <span class="font-bold block mb-2">Total Pay</span>
                <span>
                  {{ parseFloat(payouts.reduce((total, p) => p.totalPay + total, 0)).toFixed(2) }}
                </span>
              </div>
            </div>
            <GoTable
              :tableData="{
                headers: [
                  { title: 'Payout ID', key: 'payoutId' },
                  { title: 'Courier', key: 'email' },
                  { title: 'Pay Period', key: 'payPeriod' },
                  { title: '# Routes', key: 'numberOfRoutes' },
                  { title: 'Total Pay', key: 'totalPay' },
                  { title: 'Bumpup Pay', key: 'bumpUpPay', tooltip: status => status },
                  {
                    title: 'Status',
                    key: 'veemStatus',
                    valueFunction: row => paymentStatus(row.veemStatus).status,
                    tooltip: status => paymentStatus(status).message,
                  },
                  { title: 'Veem ID', key: 'veemId' },
                  { title: 'Created', key: 'veemCreated' },
                ],
                data: payouts.map(p => ({
                  ...p,
                  numberOfRoutes: p.orders.length,
                  totalPay: parseFloat(p.currentBalance || p.totalPay).toFixed(2),
                  bumpUpPay: parseFloat(p.bumpUpPay).toFixed(2),
                  veemStatus: p.veem?.status,
                  veemId: p.transferId,
                  veemCreated: veemDateTime(p.veem?.timeCreated),
                })),
              }"
            ></GoTable>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { apiService, veemDateTime, paymentStatus } from '@tyltgo/shared';
import sortBy from 'lodash/sortBy';
import GoTable from '../../components/GoTable.vue';
import { aroundLoadingMessage } from '../../services/ui-message-service';

export default defineComponent({
  components: {
    GoTable,
  },
  setup() {
    return {
      veemDateTime,
      paymentStatus,
    };
  },
  data() {
    return {
      payouts: [],
    };
  },
  mounted() {
    this.loadPayouts();
  },
  methods: {
    async loadPayouts() {
      await aroundLoadingMessage('Loading...', async () => {
        const { payouts } = await apiService.admin.couriers.allPayouts();
        this.payouts = sortBy(payouts, 'created').reverse();
      });
    },
  },
});
</script>
