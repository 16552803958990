<template>
  <div class="">
    <div
      v-for="offer of offers"
      :key="offer.id"
      class="w-full py-2 px-4 border-t-2 dark:border-gray-500 bg-white dark:bg-gray-800"
    >
      <RouteOfferLine :offer="offer" :route="route" :resend="true" />
    </div>
    <div
      v-if="
        $route.name === 'admin.scheduling' &&
        !route.courierId &&
        Object.keys(schedulingCourierBundle).length
      "
      class="p-4 rounded-md bg-white flex justify-between mt-4"
    >
      <div>Send {{ Object.keys(schedulingCourierBundle).length }} offers for route</div>
      <GoButton class="text-xs" variant="outline" @click="sendOfferBundle">Send offers</GoButton>
    </div>
    <div v-if="route.state !== 'accepted' && offers.find(o => o.state === 'accepted')" class="py-4">
      <GoButton class="text-xs" variant="outline" @click="fixAcceptedState">
        Fix accepted state
      </GoButton>
    </div>
  </div>
</template>

<script lang="ts">
import { apiService, Route } from '@tyltgo/shared';
import { defineComponent, PropType } from 'vue';
import { addNotification, aroundLoadingMessage } from '../services/ui-message-service';
import { clearSchedulingCourierBundle, schedulingCourierBundle } from '../store/scheduling-store';
import RouteOfferLine from './RouteOfferLine.vue';
import GoButton from './GoButton.vue';

export default defineComponent({
  emits: ['loadRoute', 'offerSent'],
  setup() {
    return {
      schedulingCourierBundle,
    };
  },
  components: {
    RouteOfferLine,
    GoButton,
  },
  props: {
    route: {
      type: Object as PropType<Route>,
      required: true,
    },
  },
  async mounted() {
    await this.loadOfferHistory();
  },
  data() {
    return {
      offers: [],
    };
  },
  methods: {
    async fixAcceptedState() {
      await apiService.admin.routes.fixAcceptedState(this.route.id);
      this.$emit('loadRoute');
    },
    async loadOfferHistory() {
      const results = await apiService.admin.routes.offerHistory(this.route.id);
      this.offers = results.offers;
    },
    async sendOfferBundle() {
      try {
        await aroundLoadingMessage('Sending...', async () => {
          await apiService.admin.routes.sendOfferCourierBundle(
            this.route.id,
            schedulingCourierBundle.value
          );
          clearSchedulingCourierBundle();
          await this.loadOfferHistory();
          this.$emit('offerSent');
        });
      } catch (e) {
        addNotification({
          color: 'danger',
          message: `Error sending offer: ${e.response?.data?.message || e.message}`,
        });
      }
    },
  },
});
</script>
