<template>
  <div>
    <GoButton v-if="!edit" variant="outline" class="text-xs mb-4" @click="edit = true">
      Edit
    </GoButton>
    <GoButton v-if="edit" variant="outline" class="text-xs mb-4" @click="cancel">Cancel</GoButton>
    <Form
      @submit="submit"
      :initial-values="{
        businessAddresses: merchant.businessAddresses.map(a => ({
          ...a,
          formattedAddress: a.businessAddress,
        })),
      }"
      :key="refreshKey"
    >
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50 dark:bg-gray-700">
          <tr>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
            >
              Address
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
            >
              Description
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
            >
              Photo
            </th>
            <th
              v-if="edit"
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
            ></th>
          </tr>
        </thead>
        <tbody class="bg-white dark:bg-gray-800 divide-y divide-gray-200">
          <FieldArray name="businessAddresses" v-slot="{ fields, push, remove }">
            <tr v-for="(entry, index) of fields || []" :key="entry.key">
              <td
                class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-50"
              >
                <div v-if="!edit">
                  <div>
                    {{ entry.value?.businessAddress }}
                  </div>
                  <div>{{ entry.value?.businessLat }}, {{ entry.value?.businessLng }}</div>
                </div>
                <div v-show="edit">
                  <GoAddressField
                    label="false"
                    :name="`businessAddresses[${index}]`"
                    placeholder="Address"
                    addressFieldName="businessAddress"
                    latFieldName="businessLat"
                    lngFieldName="businessLng"
                    class="col-span-4 sm:col-span-2"
                  />
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                <span v-if="!edit">
                  {{ entry.value?.locationDescription || 'None' }}
                </span>
                <div v-show="edit">
                  <GoTextField
                    label="false"
                    placeholder="Description"
                    :name="`businessAddresses[${index}].locationDescription`"
                  />
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                <span v-if="!edit && entry.value?.imageFileName">
                  <GoButton variant="badge" @click="setShowImageModal(index, true)">
                    <span class="flex space-x-2 items-center">
                      <IconFileImage class="w-3" />
                      <span>Photo</span>
                    </span>
                  </GoButton>
                  <GoModal v-if="showImageModal(index)" @close="setShowImageModal(index, false)">
                    <div class="flex justify-center">
                      <img :src="getImageDisplayInfo(index).image" />
                    </div>
                  </GoModal>
                </span>
                <div v-show="edit">
                  <GoUploadButton
                    variant="link"
                    class="text-xs"
                    :id="`upload_photo_${index}`"
                    accept=".pdf,.png,.jpeg,.jpg"
                    @filesSelected="getImageDisplayInfo(index).uploadFilesSelected"
                  >
                    Upload Photo
                  </GoUploadButton>

                  <div v-if="imagesDisplayInfo[index]?.filename">
                    {{ imagesDisplayInfo[index].filename }}
                  </div>
                </div>
              </td>
              <td v-if="edit" class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <GoButton @click="remove(index)" class="text-xs px-3" variant="link" type="button">
                  <IconTimes class="w-3" />
                </GoButton>
              </td>
            </tr>
            <tr v-if="edit">
              <td colspan="4" class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                <GoButton class="text-xs" variant="outline" @click="push()" type="button">
                  <div class="flex">
                    <IconPlus class="w-2 mr-2" />
                    Add Address
                  </div>
                </GoButton>
              </td>
            </tr>
          </FieldArray>
        </tbody>
      </table>
      <div v-if="edit">
        <div class="flex justify-center py-2">
          <GoButton class="text-base">Save</GoButton>
        </div>
      </div>
    </Form>
    <div v-if="merchant.parentBusinessAddresses?.length" class="py-16">
      <div class="px-4 sm:px-6 md:px-0">
        <GoHeader :level="3">Parent Business Addresses</GoHeader>
      </div>
      <GoTable
        class="my-4 overflow-x-auto"
        :tableData="{
          headers: [
            { title: 'Merchant', key: 'merchantEmail' },
            { title: 'Address', key: 'businessAddress' },
            { title: 'Description', key: 'locationDescription' },
          ],
          data: merchant.parentBusinessAddresses,
        }"
      ></GoTable>
    </div>
  </div>
</template>

<script lang="ts">
import { Form, FieldArray } from 'vee-validate';
import { defineComponent } from 'vue';
import { apiService } from '@tyltgo/shared/services';
import GoButton from './GoButton.vue';
import GoTextField from './GoTextField.vue';
import GoAddressField from './GoAddressField.vue';
import IconPlus from './IconPlus.vue';
import IconTimes from './IconTimes.vue';
import IconFileImage from './IconFileImage.vue';
import GoUploadButton from './GoUploadButton.vue';
import { aroundLoadingMessage } from '../services/ui-message-service';

export default defineComponent({
  emits: ['loadMerchant'],
  components: {
    Form,
    FieldArray,
    GoButton,
    IconPlus,
    IconTimes,
    GoTextField,
    GoAddressField,
    GoUploadButton,
    IconFileImage,
  },
  props: {
    merchant: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      edit: false,
      imagesDisplayInfo: {},
      refreshKey: 0,
    };
  },
  async created() {
    let index = 0;
    for (const address of this.merchant.businessAddresses) {
      // eslint-disable-next-line no-await-in-loop
      const image = await this.getImage(address.imageFileName);
      const imageDisplayInfo = this.getImageDisplayInfo(index);
      imageDisplayInfo.image = image;
      index += 1;
    }
  },
  watch: {
    merchant() {
      this.updateDisplayImages();
      this.refreshKey += 1;
    },
  },
  methods: {
    createImageDisplayInfo(index) {
      const data = {
        image: '',
        showImageModal: false,
        uploadPhotoImage: '',
        uploadFilesSelected: null,
        filename: null,
      };
      this.imagesDisplayInfo[index] = data;
      data.uploadFilesSelected = async (files, name) => {
        const file = files[0];
        console.log(file);
        const reader = new FileReader();

        reader.addEventListener(
          'load',
          () => {
            this.imagesDisplayInfo[index].uploadPhotoImage = reader.result.toString();
            this.imagesDisplayInfo[index].filename = file.name;
          },
          false
        );

        if (file) {
          reader.readAsDataURL(file);
        }
      };
    },
    getImageDisplayInfo(index) {
      if (!(index in this.imagesDisplayInfo)) {
        this.createImageDisplayInfo(index);
      }
      return this.imagesDisplayInfo[index];
    },
    async updateDisplayImages() {
      let index = 0;
      for (const address of this.merchant.businessAddresses) {
        const displayInfo = this.getImageDisplayInfo(index);
        // eslint-disable-next-line no-await-in-loop
        const image = await this.getImage(address.imageFileName);
        displayInfo.image = image;
        index += 1;
      }
    },
    showImageModal(index) {
      return this.getImageDisplayInfo(index).showImageModal;
    },
    setShowImageModal(index, val) {
      this.getImageDisplayInfo(index).showImageModal = val;
    },
    async getImage(imageFileName) {
      if (imageFileName) {
        try {
          const image = await apiService.admin.merchants.getImage(
            this.merchant.email,
            'merchantAddress',
            imageFileName
          );
          return image;
        } catch (e) {
          return null;
        }
      }
      return null;
    },
    async submit(values, { setFieldError }) {
      const addresses = values.businessAddresses || [];
      let errors = false;
      for (const [index, address] of (values.businessAddresses || []).entries()) {
        if (!address.businessAddress || address.businessAddress !== address.formattedAddress) {
          errors = true;
          setFieldError(
            `businessAddresses[${index}].businessAddress`,
            'Please select the address from the autocomplete dropdown.'
          );
        }
      }

      if (errors) return;

      for (let index = 0; index < addresses.length; index += 1) {
        const displayInfo = this.getImageDisplayInfo(index);
        addresses[index].image = displayInfo.uploadPhotoImage?.split('base64,')[1];
      }
      await aroundLoadingMessage('Saving...', async () => {
        await apiService.admin.merchants.updateLocations(
          this.merchant.email,
          addresses.map(address => {
            // eslint-disable-next-line no-param-reassign
            delete address.formattedAddress;
            return address;
          })
        );
        this.$emit('loadMerchant');
        this.edit = false;
      });
    },
    cancel() {
      this.edit = false;
    },
  },
});
</script>
