/* eslint-disable import/prefer-default-export */
import { computed, Ref, ref, watch, watchEffect } from 'vue';
import { couriers } from './couriers-store';
import { routes } from './routes-store';

export const schedulingRouteId: Ref<string> = ref('');
export const schedulingCourierId: Ref<string> = ref('');
const schedulingRouteBundleRef: Ref<Record<string, any>> = ref({});
const schedulingCourierBundleRef: Ref<Record<string, any>> = ref({});

// If selected route id or courier id changes we want to clear any bundles
watch([schedulingRouteId, schedulingCourierId], () => {
  schedulingRouteBundleRef.value = {};
  schedulingCourierBundleRef.value = {};
});

export const schedulingRoute = computed(() => {
  if (!schedulingRouteId.value) return undefined;
  if (!routes.value) return undefined;
  return routes.value.find(
    route => route.id.toLowerCase() === schedulingRouteId.value.toLowerCase()
  );
});

export const schedulingCourier = computed(() => {
  if (!schedulingCourierId.value) return undefined;
  if (!couriers.value) return undefined;
  return couriers.value.find(
    courier => courier.id.toLowerCase() === schedulingCourierId.value.toLowerCase()
  );
});

export const schedulingRouteBundle = computed(() => {
  return schedulingRouteBundleRef.value;
});

export const schedulingAddRouteToBundle = (routeId: string, pay: any) => {
  schedulingRouteBundleRef.value[routeId] = pay;
};

export const schedulingRemoveRouteFromBundle = (routeId: string) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
  const { [routeId]: _routeId, ...rest } = schedulingRouteBundleRef.value;
  schedulingRouteBundleRef.value = rest;
};

export const clearSchedulingRouteBundle = () => {
  schedulingRouteBundleRef.value = {};
};

export const schedulingCourierBundle = computed(() => {
  return schedulingCourierBundleRef.value;
});

export const schedulingAddCourierToBundle = (courierId: string, pay: any) => {
  schedulingCourierBundleRef.value[courierId] = pay;
};

export const schedulingRemoveCourierFromBundle = (courierId: string) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
  const { [courierId]: _courierId, ...rest } = schedulingCourierBundleRef.value;
  schedulingCourierBundleRef.value = rest;
};

export const clearSchedulingCourierBundle = () => {
  schedulingCourierBundleRef.value = {};
};
