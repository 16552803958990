<template>
  <main class="flex-1 focus:outline-none" tabindex="0">
    <div class="relative max-w-4xl mx-auto md:px-8 pb-4 xl:px-0">
      <div class="pt-10 pb-16">
        <GoHeader :level="1">Settings</GoHeader>
      </div>
      <div class="px-4 sm:px-6 md:px-0" v-if="settings">
        <GoForm
          v-if="currentUser.canEditSettings"
          class="space-y-12 pb-6"
          @submit="updateSettings"
          :initial-values="{ settings, agreements }"
        >
          <div class="flex flex-col w-1/4 space-y-4">
            <GoSelect
              name="settings.routingEngine"
              label="Routing Engine"
              :options="RoutingEngineNames"
            />
            <GoTextField
              name="settings.fuelEfficiency"
              label="Fuel efficiency per L/100km"
              type="number"
            />
            <GoLabel class="text-gray-600 text-sm">Gas Price per Litre</GoLabel>
            <div class="flex flex-row space-x-4">
              <div v-for="key in ['ON', 'BC']" :key="key">
                <GoTextField
                  type="number"
                  placeholder="1.4"
                  :label="key"
                  :name="`settings.gasPricePerLitre.${key}`"
                  class="w-full"
                />
              </div>
            </div>

            <GoTextField
              name="settings.minimumHourlyWage"
              label="Hourly Route Wage"
              type="number"
            />
            <GoTextField
              name="settings.minimumHourlyPersonalWage"
              label="Hourly Commute Wage"
              type="number"
            />
            <GoTextField name="settings.downtownPay" label="Downtown Pay" type="number" />
            <GoTextField name="settings.apartmentsPay" label="Apartments Pay" type="number" />
            <GoTextField name="settings.minutesAtPickup" label="Minutes at pickup" type="number" />
          </div>

          <div class="flex flex-col w-1/4 space-y-4">
            <GoTextField name="settings.fuelSurcharge" label="Fuel Surcharge %" type="number" />
            <GoTextField name="settings.fuelIncrement" label="Fuel Increment" type="number" />
          </div>

          <div class="flex flex-col w-2/4 space-y-4">
            <GoTextArea name="agreements.contract" label="Contract" />
            <GoTextArea name="agreements.courierGuide" label="Courier Guide" />
            <GoTextArea name="agreements.socialCode" label="Social Code" />
          </div>

          <div class="flex flex-col w-1/4 space-y-4">
            <GoCheckbox
              name="settings.autoSurgeSettings.enable"
              label="Enable Auto-Surge"
              :value="true"
            />
            <GoTextField
              name="settings.autoSurgeSettings.startTimeNight"
              label="Auto Surge Night Start Time"
              type="time"
            />
            <GoTextField
              name="settings.autoSurgeSettings.endTimeNight"
              label="Auto Surge Night End Time"
              type="time"
            />
            <GoTextField
              name="settings.autoSurgeSettings.startTimeMorning"
              label="Auto Surge Morning Start Time"
              type="time"
            />
            <GoTextField
              name="settings.autoSurgeSettings.endTimeMorning"
              label="Auto Surge Morning End Time"
              type="time"
            />
            <GoCheckbox
              name="settings.autoSurgeSettings.enableDoubleDown"
              label="Auto Surge Enable Doubling Down"
              :value="true"
            />
            <GoTextField
              name="settings.autoSurgeSettings.doubleDownStartTime"
              label="Auto Surge Double Down Start Time"
              type="time"
            />
          </div>

          <div class="flex flex-col w-1/4 space-y-4">
            <GoCheckbox
              name="settings.autoScheduleSettings.enable"
              label="Enable Auto-Schedule"
              :value="true"
            />
            <GoTextField
              name="settings.autoScheduleSettings.startTime"
              label="Auto Schedule Start Time"
              type="time"
            />
            <GoTextField
              name="settings.autoScheduleSettings.endTime"
              label="Auto Schedule End Time"
              type="time"
            />
            <GoTextField
              name="settings.autoScheduleSettings.openToAllTime"
              label="Auto Schedule Open to All Time"
              type="time"
            />
            <GoTextField
              name="settings.autoScheduleSettings.offerIncrement"
              label="Offer Increment"
              type="number"
            />
            <GoTextField
              name="settings.autoScheduleSettings.maxOffersPerRoute"
              label="Max Offers Per Route"
              type="number"
            />
            <GoTextField
              name="settings.autoScheduleSettings.maxOffersPerCourier"
              label="Max Offers Per Courier"
              type="number"
            />
            <GoCheckbox
              name="settings.autoScheduleSettings.lockCleaner"
              label="Enable Lock Cleaner"
              :value="true"
            />
            <GoTextField
              name="settings.autoScheduleSettings.lockCleanerMinutes"
              label="Lock Cleaner Check Minutes"
              type="number"
            />
          </div>
          <div class="flex items-center space-x-6">
            <GoButton>Update</GoButton>
          </div>
        </GoForm>
        <div v-else>
          <div>Can only be edited by certain folks.</div>
          <pre>{{ settings }}</pre>
        </div>
        <div>
          <GoCheckbox name="darkMode" label="Dark Mode" v-model="darkMode" :value="true" />
        </div>
        <div class="text-lg py-4">Lock Viewer:</div>
        <pre>{{ allDocuments }}</pre>
        <div v-if="currentUser.canEditSettings" class="py-4 space-y-4">
          <GoTextField name="nameOfLockToDelete" label="Lock Name" type="text" v-model="lockName" />
          <GoButton class="text-xs" color="danger" @click="deleteLock()">Delete Lock</GoButton>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { apiService } from '@tyltgo/shared/services/api-service';
import { GlobalSettings, RoutingEngineNames } from '@tyltgo/shared';
import GoSelect from '../../components/GoSelect.vue';
import GoTextField from '../../components/GoTextField.vue';
import GoTextArea from '../../components/GoTextArea.vue';
import GoButton from '../../components/GoButton.vue';
import { currentUser } from '../../services/auth-service';
import { darkMode } from '../../services/browser-service';
import {
  addNotification,
  aroundLoadingMessage,
  showConfirmationModal,
} from '../../services/ui-message-service';

export default defineComponent({
  setup() {
    return {
      RoutingEngineNames,
      currentUser,
      darkMode,
    };
  },
  components: {
    GoButton,
    GoSelect,
    GoTextField,
    GoTextArea,
  },
  data() {
    return {
      settings: null,
      agreements: null,
      allDocument: null,
      lockName: null,
    };
  },
  async mounted() {
    const { settings, agreements } = await apiService.admin.settings.get();
    const { allDocuments } = await apiService.admin.settings.lockViewer();
    this.settings = settings;
    this.agreements = agreements;
    this.allDocuments = allDocuments;
  },
  methods: {
    async updateSettings(
      values: { settings: GlobalSettings; agreements: Record<string, string> },
      { resetForm }: unknown
    ) {
      try {
        await aroundLoadingMessage('Saving...', async () => {
          const { settings, agreements } = await apiService.admin.settings.updateSetting(
            values.settings,
            values.agreements
          );
          resetForm({ values: { settings, agreements } });
          addNotification({ color: 'success', message: 'Settings Updated.' });
        });
      } catch (e) {
        addNotification({ color: 'danger', message: 'Error while updating.' });
      }
    },

    async deleteLock() {
      if (!this.lockName) {
        showConfirmationModal({
          title: 'No Lock Added',
          message: 'Please enter a lock name.',
          color: 'danger',
        });
        return;
      }
      const trimmedString = this.lockName.trim();
      const confirmed = await showConfirmationModal({
        title: `Delete ${trimmedString}`,
        message: 'Click OK to confirm.',
        color: 'warning',
      });
      if (confirmed) {
        let result = null;
        await aroundLoadingMessage('Deleting ...', async () => {
          result = await apiService.admin.settings.deleteLock(trimmedString);
        });
        await showConfirmationModal({
          title: 'Status Report',
          message: `${result}`,
        });
        this.lockName = null;
      }
    },
  },
});
</script>
