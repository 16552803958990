<template>
  <GoField :name="name" v-bind="$attrs" v-slot="{ field, id, errors }">
    <select
      v-bind="field"
      class="form-element shadow-sm block w-full sm:text-sm rounded-md dark:bg-gray-700"
      :class="elementClasses(errors)"
      :id="id"
    >
      <option v-if="placeholder" value="">
        {{ placeholder }}
      </option>
      <option v-for="item of items" :key="valueValue(item)" :value="valueValue(item)">
        {{ labelValue(item) }}
      </option>
    </select>
    <div
      class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
      v-if="errors.length"
    >
      <IconExclamationCircle class="h-5 w-5 text-red-500" />
    </div>
  </GoField>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import GoField from './GoField.vue';
import IconExclamationCircle from './IconExclamationCircle.vue';

interface Options {
  [key: string]: string;
}

export default defineComponent({
  inheritAttrs: false,
  components: {
    GoField,
    IconExclamationCircle,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    options: {
      type: [Array, Object],
      required: true,
    },
    valueField: {
      type: String,
      default: 'value',
    },
    labelField: {
      type: String,
      default: 'label',
    },
    helpField: {
      type: String,
      default: 'help',
    },
  },
  computed: {
    isArray(): boolean {
      return Array.isArray(this.options);
    },
    items(): any[] {
      if (this.isArray) {
        return this.options;
      }
      return this.objectToArray(this.options as Options);
    },
  },
  methods: {
    elementClasses(errors: string[]) {
      if (errors.length) {
        return 'border-2 border-red-400 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 pr-10';
      }
      return 'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 dark:border-gray-500';
    },
    labelValue(item: Options) {
      return item[this.labelField];
    },
    valueValue(item: Options) {
      return item[this.valueField];
    },
    helpValue(item: Options) {
      return item[this.helpField];
    },
    objectToArray(object: Options): any[] {
      const result = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const value in object) {
        if (value) {
          const label = object[value];
          result.push({
            value,
            label,
          });
        }
      }
      return result;
    },
  },
});
</script>
