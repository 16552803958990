<template>
  <main class="flex-1 overflow-y-auto focus:outline-none" tabindex="0">
    <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
      <div class="pt-10 pb-16" v-if="courier">
        <CourierCard :courier="courier" @loadCourier="loadCourier" />

        <div>
          <div class="">
            <div class="border-b border-gray-200 mb-4">
              <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                <template v-for="key in tabsToShow" :key="key">
                  <router-link
                    :to="{
                      name: 'admin.courier.tab',
                      params: { id: courier.email, tab: key },
                    }"
                    class="whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                    :class="
                      currentTab && currentTab.name === tabs[key].name
                        ? 'border-indigo-500 dark:border-indigo-400 text-indigo-600'
                        : 'border-transparent text-gray-700 hover:text-gray-500 hover:border-gray-300'
                    "
                    aria-current="page"
                  >
                    {{ tabs[key].name }}
                  </router-link>
                </template>
              </nav>
            </div>
            <div>
              <component
                v-if="currentTab"
                :is="currentTab.component"
                :courier="courier"
                @loadCourier="loadCourier"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent, markRaw } from 'vue';
import { apiService } from '@tyltgo/shared';
import Card from '../../components/CourierCard.vue';
import AdminCourierRoutes from '../../components/AdminCourierRoutes.vue';
import AdminCourierOffers from '../../components/AdminCourierOffers.vue';
import AdminCourierOfferHistory from '../../components/AdminCourierOfferHistory.vue';
import AdminCourierDocuments from '../../components/AdminCourierDocuments.vue';
import AdminCourierSettings from '../../components/AdminCourierSettings.vue';
import AdminCourierAvailability from '../../components/AdminCourierAvailability.vue';
import AdminCourierQuiz from '../../components/AdminCourierQuiz.vue';
import AdminCourierPayouts from '../../components/AdminCourierPayouts.vue';
import AdminCourierReferrals from '../../components/AdminCourierReferrals.vue';
import AdminCourierIncidents from '../../components/AdminCourierIncidents.vue';

const tabs = {
  routes: {
    name: 'Routes',
    component: markRaw(AdminCourierRoutes),
  },
  offers: {
    name: 'Offers',
    component: markRaw(AdminCourierOffers),
  },
  offerHistory: {
    name: 'Offer History',
    component: markRaw(AdminCourierOfferHistory),
  },
  incidents: {
    name: 'Incidents',
    component: markRaw(AdminCourierIncidents),
  },
  documents: {
    name: 'Documents',
    component: markRaw(AdminCourierDocuments),
  },
  settings: {
    name: 'Settings',
    component: markRaw(AdminCourierSettings),
  },
  availability: {
    name: 'Availability',
    component: markRaw(AdminCourierAvailability),
  },
  quiz: {
    if: courier => courier.quizReadTypeform,
    name: 'Quiz',
    component: markRaw(AdminCourierQuiz),
  },
  payouts: {
    name: 'Payouts',
    component: markRaw(AdminCourierPayouts),
  },
  referrals: {
    name: 'Referrals',
    component: markRaw(AdminCourierReferrals),
  },
};

export default defineComponent({
  components: {
    CourierCard: Card,
  },
  data() {
    return {
      courier: null,
      loading: false,
      tabs,
      currentTab: null,
    };
  },
  async mounted() {
    const { tab } = this.$route.params;
    this.currentTab = tabs[tab] || tabs.routes;
    this.loadCourier();
  },
  watch: {
    async $route(to, from) {
      const { tab } = this.$route.params;
      if (to.name === 'admin.courier.tab') {
        this.currentTab = tabs[tab] || tabs.routes;
      }
      if (to.name.startsWith('admin.courier') && to.params.id !== from.params.id) {
        await this.loadCourier();
        this.currentTab = null;
        setTimeout(() => {
          this.currentTab = tabs[tab] || tabs.routes;
        }, 10);
      }
    },
  },
  computed: {
    tabsToShow() {
      return Object.keys(tabs).filter(key => {
        const tab = tabs[key];
        if (!tab.if) return true;
        return tab.if(this.courier);
      });
    },
  },
  methods: {
    async loadCourier() {
      const { id } = this.$route.params;
      if (!id) return;
      this.loading = true;
      const { courier } = await apiService.admin.couriers.get(id);
      this.courier = courier;
      this.loading = false;
    },
  },
});
</script>
