<template>
  <div class="flex justify-end">
    <GoButton class="bg-white" variant="badge" @click="showTemplateVariables = true">
      <div class="flex">
        <IconInfo class="w-4 mr-2" />
        Template Variables
      </div>
    </GoButton>
  </div>

  <GoButton v-if="!edit" variant="outline" class="text-xs mb-4" @click="edit = true">Edit</GoButton>

  <GoModal v-if="showTemplateVariables" @close="showTemplateVariables = false">
    <div class="flex justify-center">
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <tr>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
            >
              Variable
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
            >
              Test Output
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr>
            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              ${firstName}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-700">Jessica</td>
          </tr>
          <tr>
            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              ${fullName}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-700">Jessica Smith</td>
          </tr>
          <tr>
            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              ${trackingLink}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
              www.tyltgo.com/fakeTrackingLink
            </td>
          </tr>
          <tr>
            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              ${deliveryStatus}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-700">Delivered</td>
          </tr>
          <tr>
            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              ${businessName}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
              {{ merchant.businessName }}
            </td>
          </tr>
          <tr>
            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              ${customerAddress}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
              123 Anywhere Street, Toronto, ON L0R 1H2, Canada
            </td>
          </tr>
          <tr>
            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              ${etaWindow}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-700">10:45 A.M- 11:45 A.M.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </GoModal>

  <GoButton v-if="edit" variant="outline" class="text-xs mb-4" @click="cancel">Cancel</GoButton>
  <div class="text-3xl text-brand-1" v-if="!edit && textMessageTemplates.textData?.length === 0">
    No Texts Configured.
  </div>

  <Form @submit="submit" class="pt-4">
    <div class="text-2xl" v-for="textBlock of sortedTextMessageTemplates" :key="textBlock">
      <div class="flex justify-between">
        <GoHeader :level="1" class="py-4">{{ convertCase(textBlock[0]) }}</GoHeader>
        <GoCheckbox
          v-if="edit"
          :name="`remove.${textBlock[0]}`"
          label="Remove"
          :value="true"
          class="col-span-4 sm:col-span-1"
        />
      </div>

      <div v-for="text of textBlock.slice(1)" :key="text" :id="merchant.id">
        <div
          class="bg-white dark:bg-gray-800 py-2 shadow rounded-sm mb-4 flex border-l-4 flex-col"
          v-for="(information, index) of text"
          :key="information"
        >
          <div class="px-4 pb-2">
            <div
              class="flex flex-col sm:flex-row justify-between text-base text-gray-500 dark:text-gray-400 uppercase"
              v-if="!edit && information.time"
            >
              Time: {{ formattedTime(information.time) }}
            </div>
            <div
              v-if="edit && information.time"
              class="flex flex-col sm:flex-row text-sm text-gray-500 dark:text-gray-400 uppercase"
            >
              <GoTextField
                class="pt-4"
                label="Time to Send"
                type="time"
                placeholder="Time to Send"
                :name="`${textBlock[0]}[${index}].time`"
                :value="information.time"
              />
            </div>
            <div
              class="flex flex-col sm:flex-row justify-between text-base text-gray-500 dark:text-gray-400 uppercase"
              v-if="!edit"
            >
              Tag: {{ information.tag || 'None' }}
            </div>
            <div
              v-if="edit"
              class="flex flex-col sm:flex-row text-sm text-gray-500 dark:text-gray-400 uppercase"
            >
              <GoTextField
                class="pt-4"
                label="Tag"
                placeholder="Tag"
                :name="`${textBlock[0]}[${index}].tag`"
                :value="information.tag"
              />
            </div>

            <div
              class="flex flex-col sm:flex-row justify-between text-sm text-gray-500 dark:text-gray-400 uppercase"
              v-if="!edit"
            >
              Priority: {{ information.priority || 'None' }}
            </div>
            <div
              v-if="edit"
              class="flex flex-col sm:flex-row text-sm text-gray-500 dark:text-gray-400 uppercase"
            >
              <GoTextField
                class="pt-4"
                label="Priority"
                placeholder="Priority"
                :name="`${textBlock[0]}[${index}].priority`"
                :value="information.priority"
              />
            </div>
            <div class="flex flex-col sm:flex-row text-base pt-4" v-if="!edit">
              Template: {{ information.template || 'None' }}
            </div>
            <div v-if="edit">
              <GoTextArea
                class="pt-4"
                label="TEMPLATE"
                placeholder="Template"
                :name="`${textBlock[0]}[${index}].template`"
                v-model="information.template"
              />
            </div>
            <GoAlert class="my-4" color="information" title="Preview">
              <pre class="text-sm whitespace-pre-line">{{ preview(textBlock[0], information) }}</pre>
            </GoAlert>
          </div>
        </div>
      </div>
    </div>

    <div v-if="edit && emptyTemplate.length !== 0">
      <div class="text-3xl text-brand-1 py-6">Add Template</div>

      <div class="text-2xl text-brand-1 my-4" v-for="(empty, index) of emptyTemplate" :key="empty">
        <div class="flex justify-between">
          {{ convertCase(empty.name) }}

          <GoButton
            :name="`${empty}[${index}].add`"
            v-if="!add[empty.name]"
            type="button"
            class="bg-white"
            variant="badge"
            @click="add[empty.name] = true"
          >
            <div class="flex">
              <IconPlus class="w-2 mr-2" />
              Add Text
            </div>
          </GoButton>
          <GoButton
            :name="`${empty}[${index}].remove`"
            v-if="add[empty.name]"
            type="button"
            class="bg-white"
            variant="badge"
            @click="add[empty.name] = false"
          >
            <div class="flex">
              <IconTimes class="w-2 mr-2" />
              Cancel
            </div>
          </GoButton>
        </div>
        <div
          v-if="add[empty.name]"
          :name="`empty.${empty}[${index}].div`"
          class="bg-white dark:bg-gray-800 py-2 shadow rounded-sm mb-4 flex border-l-4 flex-col px-4 my-6"
        >
          <div
            v-if="baseTemplates[empty.name].time"
            class="flex flex-col sm:flex-row text-sm text-gray-500 dark:text-gray-400 uppercase"
          >
            <GoTextField
              class="pt-4"
              type="time"
              label="Time to send"
              placeholder="Time to send"
              :name="`empty.${empty.name}.time`"
              :value="empty.time"
            />
          </div>
          <div class="flex flex-col sm:flex-row text-sm text-gray-500 dark:text-gray-400 uppercase">
            <GoTextField
              class="pt-4"
              label="Tag"
              placeholder="Tag"
              :name="`empty.${empty.name}.tag`"
              :value="empty.tag"
            />
          </div>
          <div class="flex flex-col sm:flex-row text-sm text-gray-500 dark:text-gray-400 uppercase">
            <GoTextField
              class="pt-4"
              label="Priority"
              placeholder="Priority"
              :name="`empty.${empty.name}.priority`"
              :value="empty.priority"
            />
          </div>
          <div>
            <GoTextArea
              class="pt-4"
              label="TEMPLATE"
              placeholder="Template"
              :name="`empty.${empty.name}.template`"
              v-model="empty.template"
            />
          </div>
          <GoAlert
            class="my-4"
            color="information"
            title="Preview"
            :message="preview(empty.name, empty)"
          >
            <pre class="text-sm whitespace-pre-line">{{ preview(empty.name, empty) }}</pre>
          </GoAlert>
        </div>
      </div>
    </div>

    <div class="flex justify-center py-8" v-if="edit">
      <GoButton class="text-base">Save</GoButton>
    </div>
  </Form>
</template>

<script lang="ts">
import { Form } from 'vee-validate';
import { defineComponent } from 'vue';
import { apiService, modifyRecipientText } from '@tyltgo/shared/services';
import startCase from 'lodash/startCase';
import template from 'lodash/template';
import dayjs from 'dayjs';
import sortBy from 'lodash/sortBy';
import GoButton from './GoButton.vue';
import GoAlert from './GoAlert.vue';
import GoModal from './GoModal.vue';
import GoTextField from './GoTextField.vue';
import GoTextArea from './GoTextArea.vue';
import GoCheckbox from './GoCheckbox.vue';
import IconPlus from './IconPlus.vue';
import IconTimes from './IconTimes.vue';
import IconInfo from './IconInfo.vue';
import { aroundLoadingMessage } from '../services/ui-message-service';

const templateNames = ['dayBefore', 'nightBefore', 'initial', 'completed'];

export default defineComponent({
  emits: ['loadMerchant'],
  components: {
    Form,
    GoButton,
    GoTextField,
    GoTextArea,
    GoAlert,
    GoModal,
    GoCheckbox,
    IconPlus,
    IconTimes,
    IconInfo,
  },
  props: {
    merchant: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      edit: false,
      showTemplateVariables: false,
      textMessageTemplates: [],
      sortedTextMessageTemplates: [],
      completedTexts: [],
      initialTexts: [],
      nightBeforeTexts: [],
      dayBeforeTexts: [],
      emptyTemplate: [],
      addCompleted: false,
      add: {
        dayBefore: false,
        nightBefore: false,
        initial: false,
        completed: false,
      },
    };
  },
  mounted() {
    this.loadTextMessageTemplates();
  },
  computed: {
    baseTemplates() {
      const dayBefore =
        `Hey` +
        // eslint-disable-next-line no-template-curly-in-string
        ' ${firstName}! ' +
        `Your ${this.merchant.businessName} order will be delivered tomorrow! Look out for a tracking link tomorrow.`;
      const nightBefore = dayBefore;
      const initial =
        `Hey` +
        // eslint-disable-next-line no-template-curly-in-string
        ' ${firstName}! ' +
        `Your ${this.merchant.businessName} order is on the way. Track your order here` +
        // eslint-disable-next-line no-template-curly-in-string
        ' ${trackingLink}';

      const completed =
        `Your ${this.merchant.businessName} order has arrived. Enjoy!` +
        // eslint-disable-next-line no-template-curly-in-string
        ' Delivery details: ${trackingLink}';

      return {
        dayBefore: {
          name: 'dayBefore',
          priority: 10,
          tag: '',
          time: '20:00',
          template: dayBefore,
        },
        nightBefore: {
          name: 'nightBefore',
          priority: 10,
          tag: '',
          template: nightBefore,
        },
        initial: {
          name: 'initial',
          priority: 10,
          tag: '',
          template: initial,
        },
        completed: {
          name: 'completed',
          priority: 10,
          tag: '',
          template: completed,
        },
      };
    },
  },
  methods: {
    formattedTime(time: string) {
      const day = dayjs().format('YYYY-MM-DD');
      return dayjs(`${day} ${time}`).format('h:mm A');
    },
    convertCase(stage) {
      if (stage === 'nightBefore') {
        return 'Night Before (8 P.M.)';
      }

      if (stage === 'initial') {
        return 'Pickup';
      }

      if (stage === 'completed') {
        return 'Completed';
      }

      return startCase(stage);
    },

    async cancel() {
      this.edit = false;
      this.add.nightBefore = false;
      this.add.initial = false;
      this.add.completed = false;
      await this.loadTextMessageTemplates();
    },
    preview(type, templateText) {
      const textMessageBody = templateText.template.split('_b').join('\n');

      const compiled = template(modifyRecipientText(type, textMessageBody));
      const variables = {
        deliveryStatus: 'Delivered',
        firstName: 'Jessica',
        fullName: 'Jessica Smith',
        businessName: this.merchant.businessName,
        senderName: 'Tony',
        trackingLink: 'www.tyltgo.com/fakeTrackingLink',
        customerAddress: '123 Anywhere Street, Toronto, ON L0R 1H2, Canada',
        etaWindow: '10:45 AM - 11:45 AM',
      };

      return compiled(variables);
    },

    async loadTextMessageTemplates() {
      this.emptyTemplate = [];
      await aroundLoadingMessage('Please Wait...', async () => {
        this.textMessageTemplates = await apiService.admin.merchants.getTextMessages(
          this.merchant.email
        );

        this.sortedTextMessageTemplates = Object.entries(
          this.textMessageTemplates.textData
        ).map(([key, value]) => [key, value]);
      });

      this.sortedTextMessageTemplates = sortBy(this.sortedTextMessageTemplates, item => {
        return templateNames.indexOf(item[0]);
      });

      if (Object.keys(this.textMessageTemplates.textData).length === 0) {
        this.textMessageTemplates.textData = [];
      }

      if (!this.textMessageTemplates.textData.dayBefore) {
        this.emptyTemplate.push(this.baseTemplates.dayBefore);
      }

      if (!this.textMessageTemplates.textData.nightBefore) {
        this.emptyTemplate.push(this.baseTemplates.nightBefore);
      }

      if (!this.textMessageTemplates.textData.initial) {
        this.emptyTemplate.push(this.baseTemplates.initial);
      }

      if (!this.textMessageTemplates.textData.completed) {
        this.emptyTemplate.push(this.baseTemplates.completed);
      }
    },

    async submit(values) {
      if (values.remove?.dayBefore === true) {
        await apiService.admin.merchants.deleteTextMessage(this.merchant.email, 'dayBefore');
      }

      if (values.remove?.nightBefore === true) {
        await apiService.admin.merchants.deleteTextMessage(this.merchant.email, 'nightBefore');
      }

      if (values.remove?.initial === true) {
        await apiService.admin.merchants.deleteTextMessage(this.merchant.email, 'initial');
      }

      if (values.remove?.completed === true) {
        await apiService.admin.merchants.deleteTextMessage(this.merchant.email, 'completed');
      }

      if (values.empty?.dayBefore) {
        await this.dayBeforeTexts.push(values.empty.dayBefore);
      }
      if (values.empty?.nightBefore) {
        await this.nightBeforeTexts.push(values.empty.nightBefore);
      }
      if (values.empty?.initial) {
        await this.initialTexts.push(values.empty.initial);
      }
      if (values.empty?.completed) {
        await this.completedTexts.push(values.empty.completed);
      }

      if (!values.remove?.dayBefore) {
        values.dayBefore?.map(async element => {
          await this.dayBeforeTexts.push(element);
        });
      }

      if (!values.remove?.nightBefore) {
        values.nightBefore?.map(async element => {
          await this.nightBeforeTexts.push(element);
        });
      }

      if (!values.remove?.initial) {
        values.initial?.map(async element => {
          await this.initialTexts.push(element);
        });
      }

      if (!values.remove?.completed) {
        values.completed?.map(async element => {
          await this.completedTexts.push(element);
        });
      }

      await aroundLoadingMessage('Saving...', async () => {
        await apiService.admin.merchants.updateTextMessages(
          this.merchant.email,
          this.completedTexts,
          this.initialTexts,
          this.nightBeforeTexts,
          this.dayBeforeTexts
        );
        this.completedTexts = [];
        this.initialTexts = [];
        this.nightBeforeTexts = [];
        this.dayBeforeTexts = [];
        this.edit = false;
        this.add.dayBefore = false;
        this.add.nightBefore = false;
        this.add.initial = false;
        this.add.completed = false;
      });

      await aroundLoadingMessage('Saving...', async () => {
        await this.loadTextMessageTemplates();
        await this.loadTextMessageTemplates();
      });
    },
  },
});
</script>
