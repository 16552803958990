<template>
  <main class="flex-1 overflow-y-auto focus:outline-none" tabindex="0">
    <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
      <div class="pt-10 pb-16">
        <div class="px-4 sm:px-6 md:px-0">
          <GoHeader :level="1">Courier Availability</GoHeader>
        </div>
        <div
          class="py-5 px-4 sm:px-6 md:px-0 flex flex-col lg:flex-row lg:justify-between lg:items-center"
        >
          <h1 class="text-2xl font-extrabold text-gray-900 mb-4 lg:mb-0">
            {{ formatDay(this.date) }}
            <span v-if="isToday()">- Today</span>
          </h1>
          <div
            class="flex flex-row-reverse lg:flex-row justify-end items-center text-xs lg:space-x-2"
          >
            <IconLoader class="animate-spin mx-2 h-5 w-5 text-primary-800" v-show="loading" />
            <div class="space-x-4">
              <GoButton
                as="router-link"
                variant="outline"
                :to="{
                  name: 'admin.availability',
                  query: { ...$route.query, day: changeDay(-1) },
                }"
              >
                Previous
              </GoButton>

              <GoButton
                as="router-link"
                class="px-6"
                variant="outline"
                :to="{
                  name: 'admin.availability',
                  query: { ...$route.query, day: changeDay(1) },
                }"
              >
                Next
              </GoButton>
            </div>
          </div>
        </div>

        <div class="px-4 sm:px-6 md:px-0 pt-10">
          <div class="flex flex-col">
            <div class="-my-2 sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Courier
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Start Time
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          End Time
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Last Route
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Total Routes
                        </th>
                        <th
                          scope="col"
                          class="py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Avail Set On
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white dark:gray-800 divide-y divide-gray-200">
                      <tr
                        v-for="availability in availabilities"
                        :key="availability.courierAvailabilityInfo.id"
                        :class="{
                          'bg-green-100': isAvailableNow(
                            availability.courierAvailabilityInfo.startTime,
                            availability.courierAvailabilityInfo.endTime,
                            availability.courierAvailabilityInfo.timeZoneId
                          ),
                        }"
                      >
                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {{ availability.courierAvailabilityInfo.date }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                          <p>{{ availability.courierAvailabilityInfo.courierEmail }}</p>
                          <p class="text-gray-500 text-xs">{{ availability.address || 'N/A' }}</p>
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {{
                            formatTime(
                              availability.courierAvailabilityInfo.startTime,
                              availability.courierAvailabilityInfo.timeZoneId
                            )
                          }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {{
                            formatTime(
                              availability.courierAvailabilityInfo.endTime,
                              availability.courierAvailabilityInfo.timeZoneId
                            )
                          }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {{ availability.lastRouteDate || 'N/A' }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {{ availability.totalRoutes || 'N/A' }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {{
                            formatRequestDate(availability.courierAvailabilityInfo.timeOfRequest)
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { apiService } from '@tyltgo/shared';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import GoButton from '../../components/GoButton.vue';
import IconLoader from '../../components/IconLoader.vue';
import { time } from '../../services/date-service';

dayjs.extend(advancedFormat);

export default defineComponent({
  components: { GoButton, IconLoader },
  async mounted() {
    console.log(this.$route.query);
    const { day } = this.$route.query;
    this.date = day ?? dayjs().format('YYYY-MM-DD');
    this.loadAvailabilities();
  },
  data() {
    return {
      availabilities: [],
      date: '',
      loading: false,
    };
  },

  watch: {
    $route(to, _from) {
      if (to.name === 'admin.availability') {
        this.date = to.query.day ?? dayjs().format('YYYY-MM-DD');
      }
    },
    date() {
      this.loadAvailabilities();
    },
  },

  methods: {
    async loadAvailabilities() {
      this.loading = true;
      const { couriersAvailability } = await apiService.admin.couriers.allCourierAvailability(
        this.date
      );
      this.availabilities = couriersAvailability;
      this.loading = false;
    },

    changeDay(diff: number) {
      return dayjs(this.date).add(diff, 'days').format('YYYY-MM-DD');
    },

    formatTime(timestamp: number, timezone = 'America/Toronto') {
      const formattedTime = dayjs.unix(timestamp).tz(timezone).format();

      return time(formattedTime, timezone);
    },

    formatDay(day: number) {
      return dayjs(day).format('dddd, MMMM DD, YYYY');
    },

    formatRequestDate(timestamp: number) {
      return dayjs.unix(timestamp).format('YYYY-MM-DD');
    },

    isToday() {
      if (this.date === dayjs().format('YYYY-MM-DD')) {
        return true;
      }
      return false;
    },

    isAvailableNow(startTime: number, endTime: number, timezone = 'America/Toronto') {
      const timeNow = dayjs().unix();
      if (startTime <= timeNow && endTime > timeNow && this.isToday() && this.loading === false) {
        return true;
      }
      return false;
    },
  },
});
</script>
