<template>
  <main class="flex-1 overflow-y-auto focus:outline-none" tabindex="0">
    <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
      <div class="pt-10 pb-8">
        <div class="px-4 sm:px-6 md:px-0">
          <GoHeader :level="1">Shopify Integrations</GoHeader>
        </div>
        <div class="px-4 sm:px-6 md:px-0 pt-4 mt-8">
          <div
            v-for="store of currentUser.shopifyStores"
            :key="store.credentials.shopName"
            class="bg-white dark:bg-gray-800 shadow rounded-lg mb-4"
          >
            <router-link
              :to="{
                name: 'merchant.shopifyIntegration.home',
                params: {
                  storeName: store.credentials.shopName,
                },
              }"
              class="flex justify-between items-center p-4"
            >
              <span>
                {{ store.credentials.shopName }}
              </span>
              <svg
                class="h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { currentUser } from '../../services/auth-service';

export default defineComponent({
  setup() {
    return {
      currentUser,
    };
  },
  data() {
    return {};
  },
  mounted() {},
  watch: {},
});
</script>
