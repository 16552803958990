/* eslint-disable import/prefer-default-export */

import center from '@turf/center';
import distance from '@turf/distance';
import { lineString, Units } from '@turf/helpers';
import mapboxPolyline from '@mapbox/polyline';

const cleanCoord = (coord: PotentialCoord): Coord => {
  if (Array.isArray(coord)) return coord;

  if ('lat' in coord) {
    return [coord.lat, coord.lng];
  }
  return [coord.latitude, coord.longitude];
};

export const calculateDistance = (
  locationOne: PotentialCoord,
  locationTwo: PotentialCoord,
  units: Units = 'meters'
): number => {
  const one = cleanCoord(locationOne);
  const two = cleanCoord(locationTwo);
  return distance(one, two, { units });
};

export const centerOfPoints = (points: any[]) => {
  const line = lineString(points);
  const tCenter = center(line);
  return tCenter;
};

export const decodePolyline = (line: string) => {
  let points = mapboxPolyline.decode(line, 5);
  const lat = points[0][0];
  if (lat > 400 || lat < -400) {
    points = mapboxPolyline.decode(line, 6);
  }
  return points;
};

export const line = (points: any[]) => {
  return lineString(points);
};

type PotentialCoord =
  | number[]
  | { lat: number; lng: number }
  | { latitude: number; longitude: number };

type Coord = number[];
