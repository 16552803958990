<template>
  <GoField
    :name="name"
    v-bind="$attrs"
    v-model="selectedValue"
    v-slot="{ field, id, errors }"
    class="go-multi-select-wrapper"
  >
    <Multiselect
      v-if="showMultiselect"
      autocomplete="do-not-complete"
      class="p-0 text-xs rounded-md"
      :hideSelected="mode === 'tags'"
      :closeOnSelect="mode !== 'multiple'"
      :class="elementClasses(errors)"
      :placeholder="placeholder"
      :mode="mode"
      v-model="selectedValue"
      :options="optionsList"
      :searchable="true"
      :createOption="createOption"
      :appendNewOption="createOption"
    />
    <input type="hidden" v-bind="field" :id="id" />
    <div
      class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
      v-if="errors.length"
    >
      <IconExclamationCircle class="h-5 w-5 text-red-500" />
    </div>
  </GoField>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Multiselect from '@vueform/multiselect';
import GoField from './GoField.vue';
import IconExclamationCircle from './IconExclamationCircle.vue';

interface Options {
  [key: string]: string;
}

export default defineComponent({
  inheritAttrs: false,
  components: {
    GoField,
    Multiselect,
    IconExclamationCircle,
  },
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: [String, Object],
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    options: {
      type: [Array, Object],
      required: true,
    },
    valueField: {
      type: String,
      default: 'value',
    },
    labelField: {
      type: String,
      default: 'label',
    },
    helpField: {
      type: String,
      default: 'help',
    },
    mode: {
      type: String,
      default: 'single',
    },
    createOption: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedValue: null,
      showMultiselect: true,
    };
  },
  mounted() {
    if (this.mode === 'single') {
      this.selectedValue = this.modelValue || '';
    } else {
      this.selectedValue = this.modelValue || [];
    }
  },
  watch: {
    selectedValue() {
      this.$emit('update:modelValue', this.selectedValue);
    },
    modelValue() {
      this.selectedValue = this.modelValue;
    },
    mode() {
      this.showMultiselect = false;
      this.$nextTick(() => {
        let value = this.selectedValue;
        if (this.mode === 'single') {
          if (Array.isArray(value)) {
            // eslint-disable-next-line prefer-destructuring
            value = value[0];
          }
        } else if (!Array.isArray(value)) {
          value = [value];
        }
        this.$emit('update:modelValue', value);
        this.showMultiselect = true;
      });
    },
  },
  computed: {
    isArray(): boolean {
      return Array.isArray(this.options);
    },
    items(): any[] {
      if (this.isArray) {
        return this.options;
      }
      return this.objectToArray(this.options as Options);
    },
    optionsList() {
      const items = this.items.map(item => {
        return {
          label: this.labelValue(item),
          value: this.valueValue(item),
        };
      });
      if (this.placeholder && this.mode === 'single') {
        items.unshift({
          label: this.placeholder,
          value: '',
        });
      }
      return items;
    },
  },
  methods: {
    elementClasses(errors: string[]) {
      if (errors.length) {
        return 'border-2 border-red-400 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 pr-10';
      }
      return 'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300';
    },
    labelValue(item: Options) {
      return item[this.labelField];
    },
    valueValue(item: Options) {
      return item[this.valueField];
    },
    helpValue(item: Options) {
      return item[this.helpField];
    },
    objectToArray(object: Options): any[] {
      const result = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const value in object) {
        if (value) {
          const label = object[value];
          result.push({
            value,
            label,
          });
        }
      }
      return result;
    },
  },
});
</script>

<style>
.go-multi-select-wrapper {
  @apply w-full;
}

.form-element-wrapper .multiselect-search {
  @apply rounded-md font-body text-sm border-gray-300 text-gray-800 py-2 min-h-0 bg-white;
}

.form-element-wrapper .is-multiple .multiselect-option.is-selected,
.form-element-wrapper .is-tags .multiselect-option.is-selected {
  background: #41b883;
  color: #fff;
}

.form-element-wrapper .is-multiple .multiselect-option.is-selected.is-pointed,
.form-element-wrapper .is-tags .multiselect-option.is-selected.is-pointed {
  background: #41b883;
  color: #000;
}

.form-element-wrapper .is-single .multiselect-search input,
.form-element-wrapper .is-multiple .multiselect-search input {
  @apply font-body text-sm border-gray-300 text-gray-800 py-0;
}
</style>
