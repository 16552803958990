<template>
  <TrackingPageLayout v-slot="{ config }">
    <main>
      <div v-if="!initialLoading && matchedOrder" class="flex flex-col">
        <GoHeader :level="2" :color="config.grayColor" class="mb-8">Unsubscribe</GoHeader>
        <p class="text-base leading-7 font-normal mb-4" :class="config.grayColor">
          Order {{ matchedOrder.waypointId }}
        </p>
        <p class="text-xl leading-7 font-normal mb-4" :class="config.textColor">
          Your email has been unsubscribed. You will no longer recieve any delivery updates.
        </p>
        <p class="text-lg leading-7 font-normal mb-4" :class="config.textColor">
          You can re-subscribe at any time.
        </p>
        <div>
          <GoButton
            class="text-sm"
            variant="outline"
            rounded="rounded-md"
            color="secondary"
            :style="`background-color: ${config.brandColor}; color: ${config.buttonTextColor}`"
            @click="resubscribe"
          >
            Re-subscribe
          </GoButton>
        </div>
      </div>
      <div v-else class="mt-4 flex flex-col items-center justify-center py-8 w-full space-y-2">
        <IconLoader class="animate-spin mx-2 h-8 w-8 text-primary-800" />
      </div>
    </main>
  </TrackingPageLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { apiService } from '@tyltgo/shared';
import IconLoader from '../components/IconLoader.vue';

export default defineComponent({
  components: {
    IconLoader,
  },
  async beforeMount() {
    this.initialLoading = true;
    const { id } = this.$route.params;
    const { trackingSecret } = this.$route.params;
    this.trackingSecret = trackingSecret?.replace(/[^0-9_a-z]/, '');
    if (id) {
      this.orderId = id.replace(/[.$!]$/, '');
      await this.validateTrackingSecret();
      await this.track({ orderId: this.orderId });

      await apiService.tracking.unsubscribeEmail(
        this.matchedOrder.routeId,
        this.orderId,
        this.trackingSecret
      );
    }
    this.initialLoading = false;
    console.log(this.$router);
    console.log(this.$route);
  },
  data() {
    return {
      matchedOrder: null,
      notFound: null,
      initialLoading: null,
      loading: null,
      orderId: null,
      trackingSecret: '',
      trackingSlug: '',
    };
  },
  methods: {
    async validateTrackingSecret() {
      const { valid } = await apiService.tracking.validateTrackingSecret(
        this.orderId,
        this.trackingSecret,
        this.trackingSlug
      );
      this.moreFeatures = valid;
    },
    async track({ orderId }) {
      this.loading = true;
      if (orderId) {
        this.matchedOrder = await this.trackOrder(orderId);
      }
      this.loading = false;
      if (orderId) {
        this.notFound = !this.matchedOrder;
      }
    },
    async trackOrder(orderId: string) {
      const searchText = orderId?.trim();

      if (!searchText) return null;

      const { trackingSlug } = this.$route.params;

      const searchQuery = `id:${searchText}`;

      const results = await apiService.tracking.search(
        encodeURIComponent(searchQuery),
        this.trackingSecret,
        trackingSlug
      );

      let result = null;
      if (results.length > 1) {
        result = results.find((item: { merged: boolean }) => item.merged) || results[0];
      } else if (results.length === 1) {
        // eslint-disable-next-line prefer-destructuring
        result = results[0];
      }

      if (!result) return null;
      if (result.status === 'Cancelled') return null;

      return result;
    },
    resubscribe() {
      const name = this.$route.name.replace(/^unsubscribe/, 'resubscribe');
      this.$router.push({
        name,
      });
    },
  },
});
</script>
