<template>
  <div class="p-4">
    <div class="text-center py-2">
      <h2 class="text-2xl mb-1">{{ options.title }}</h2>
      <h4 class="text-lg text-gray-600">{{ options.subtitle }}</h4>
    </div>
    <div v-if="htmlText" v-html="htmlText" class="prose" />
    <div v-if="options.youtubeVideo" class="py-8 w-full">
      <youtube-vue3 :videoid="options.youtubeVideo" class="w-full" @ended="videoWatched = true" />
    </div>
    <div v-if="!completed" class="p-8 text-center">
      <GoAlert v-if="nextMessage" class="my-3" color="information" :message="nextMessage" />
      <GoButton :disabled="!canSubmit" @click="confirmLastStep">Confirm</GoButton>
    </div>
  </div>
</template>

<script lang="ts">
import { apiService } from '@tyltgo/shared/services';
import { defineComponent } from 'vue';
import { marked } from 'marked';
import startCase from 'lodash/startCase';
import { YoutubeVue3 } from 'youtube-vue3';
import GoButton from './GoButton.vue';
import GoAlert from './GoAlert.vue';
import { showLoadingMessage, stopLoadingMessage } from '../services/ui-message-service';
import { currentUser } from '../services/auth-service';

export default defineComponent({
  emits: ['completed'],
  setup() {
    return {
      currentUser,
      startCase,
    };
  },
  components: {
    GoButton,
    GoAlert,
    YoutubeVue3,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    courier: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      videoWatched: null,
    };
  },
  computed: {
    nextMessage() {
      if (this.options.youtubeVideo) {
        return 'You can proceed after watching the video.';
      }
      return null;
    },
    htmlText() {
      if (!this.options.markdownText) return null;

      return marked(this.options.markdownText);
    },
    completed() {
      return this.courier.lastSteps[this.name].completed;
    },
    canSubmit() {
      if (this.options.youtubeVideo) {
        return this.videoWatched;
      }
      return false;
    },
  },
  watch: {
    $route(to, from) {
      if (to.params.name !== from.params.name) {
        this.setupStep();
      }
    },
  },
  async mounted() {
    this.setupStep();
  },
  methods: {
    setupStep() {
      this.videoWatched = null;
    },
    async confirmLastStep() {
      showLoadingMessage('Submitting ...');
      await apiService.couriers.profile.confirmLastStep(this.name);
      stopLoadingMessage();
      this.$emit('completed', this.name);
    },
  },
});
</script>
