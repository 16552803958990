<template>
  <div>
    <div v-if="waypoints">
      <GoButton variant="link" class="text-xs py-6" @click="downloadCsv">Download CSV</GoButton>
      <div class="flex items-center justify-between">
        <div class="space-x-4 flex items-center text-xs">
          <GoButton
            as="router-link"
            variant="link"
            :to="{ name: 'admin.report', query: { ...$route.query, day: changeDay(-1) } }"
            class="text-blue-800"
          >
            Previous Day
          </GoButton>
          <div class="text-xl">{{ this.currentDay }}</div>
          <GoButton
            variant="link"
            as="router-link"
            :to="{ name: 'admin.report', query: { ...$route.query, day: changeDay(1) } }"
            class="text-blue-800"
          >
            Next Day
          </GoButton>
        </div>
        <div>{{ total }} Unable to Deliver{{ total === 1 ? '' : 's' }}</div>
      </div>
      <div class="py-4">
        <WaypointCard
          v-for="waypoint in waypoints"
          :key="`${waypoint.routeId}-${waypoint.id}`"
          :waypoint="waypoint"
          :routeId="waypoint.routeId"
          :ref="waypoint.id"
          :forSearch="true"
        />
        <div v-if="!waypoints.length">No waypoints where marked as unable to deliver.</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { addressDetails, apiService } from '@tyltgo/shared';
import dayjs from 'dayjs';
import { orderBy } from 'lodash';
import WaypointCard from './WaypointCard.vue';
import GoButton from './GoButton.vue';
import { showLoadingMessage, stopLoadingMessage } from '../services/ui-message-service';
import { downloadBlob } from '../services/browser-service';

export default defineComponent({
  components: {
    WaypointCard,
    GoButton,
  },
  data() {
    return {
      waypoints: null,
      currentDay: null,
      total: null,
    };
  },
  watch: {
    $route(to, _from) {
      if (to.name === 'admin.report') {
        this.currentDay = to.query.day ?? dayjs().format('YYYY-MM-DD');
        this.loadResults();
      }
    },
  },
  async mounted() {
    this.loadResults();
  },
  methods: {
    async loadResults() {
      showLoadingMessage('Loading ...');
      const { day } = this.$route.query;
      const date = dayjs(day || undefined).format('YYYY-MM-DD');
      this.currentDay = date;
      const { data, total } = await apiService.admin.routes.searchWaypoints(
        `elastic routeDate:${date} _exists_:assignedDriver subState:"bringBack"`,
        1000
      );
      this.waypoints = orderBy(data, ['merchantId', 'routeId']);
      this.total = total;
      stopLoadingMessage();
    },
    downloadCsv() {
      const header = `Date,Merchant,Associated Business,Route Id,Courier,Order Id,Name,Phone Number,Updated Time,Picked Status,Courier Note,Street,City,Province,Postal Code,Order Note\n`;
      const lines = this.waypoints
        .map(point => {
          const address = addressDetails(point.address);
          const updatedTime = dayjs(point.statusUpdatedAt).format('h:mm A');
          const line = [
            point.routeDate,
            point.merchantId,
            point.associatedEmail,
            point.routeId,
            point.assignedDriver,
            point.id,
            point.name,
            point.phoneNumber,
            updatedTime,
            point.unpicked ? 'Unpicked' : 'Picked',
            point.courierNote,
            address.street,
            address.city,
            address.province,
            address.postalCode,
            point.note,
          ]
            .map(item => (item === null || item === undefined ? '' : item))
            .map(item => `"${item.replace(/"/g, '')}"`)
            .join(',');
          return line;
        })
        .join('\n');
      const csv = header + lines;
      const blob = new Blob([csv], { type: 'text/csv' });
      downloadBlob(blob, `bringback-report-${this.currentDay}.csv`);
    },
    changeDay(diff: number) {
      return dayjs(this.currentDay).add(diff, 'days').format('YYYY-MM-DD');
    },
  },
});
</script>
