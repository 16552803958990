/* eslint-disable import/prefer-default-export */
import parsePhoneNumber from 'libphonenumber-js';

export const parsePhone = (phoneNumber: string | undefined, defaultReturn = '') => {
  if (!phoneNumber) return '';

  let number = phoneNumber.toString();

  number = number.trim();
  // this is regex to handle the case:
  // +6472985944
  number = number.replace(/^\+/, '');
  number = number.replace(/^0+/, '');
  number = parsePhoneNumber(number, 'US')?.formatNational() || defaultReturn;

  // this is repeated to handle the case:
  // +1 04161111111
  number = number.replace(/^0+/, '');
  number = parsePhoneNumber(number, 'US')?.formatNational() || defaultReturn;

  return number;
};
