/* eslint-disable import/prefer-default-export */
import { parsePhone } from '../helpers/phone-helper';
import { Merchant, RawOrder } from '../types/index';
import { addressDetails } from './address-service';
import { zplLabel } from './zpl-service';

export const rawOrderZpl = ({ rawOrder, merchant }: { rawOrder: RawOrder; merchant: Merchant }) => {
  const address = addressDetails(rawOrder.geolocation.formattedAddress);

  const quantity = Number(rawOrder.quantity || 1);
  let customerAddressline2 = rawOrder.addressLine2 || '';

  if (rawOrder.addressLine2 !== '' && !Number.isNaN(Number(rawOrder.addressLine2))) {
    customerAddressline2 = `Unit ${rawOrder.addressLine2}`;
  }

  const id = rawOrder.packageId || rawOrder.orderId;
  return [...Array(quantity).keys()]
    .map(itemNumber => {
      return zplLabel({
        name: rawOrder.name,
        phone: parsePhone(rawOrder.phoneNumber),
        address1: address.street,
        address2: customerAddressline2,
        city: address.city,
        postalProvince: `${address.postalCode} ${address.province}`,
        id,
        routeNumber: 'TBD',
        routeId: 'UNROUTED',
        pickupTime: '',
        stopNumber: 'UNROUTED',
        date: rawOrder.date,
        barcode: id,
        isLineHaul: false,
        routingGroup: '',
        pickupAddress: '',
        isMiddleMile: false,
        middleMileGroup: '',
        middleMileAddress: '',
        itemNumber: itemNumber + 1,
        totalItems: quantity,
        zplLogo: merchant.settings.labelLogoImageUrl ? merchant.settings.zplLogo : undefined,
      });
    })
    .join('\n');
};
