/* eslint-disable import/prefer-default-export */
import dayjs from 'dayjs';
import { customAlphabet } from 'nanoid';

export const shortId = (prefix = '', length = 13, postfix: string | undefined = undefined) => {
  const finalPostfix = postfix || dayjs().format('MMDD');
  const alphabet = '0123456789_abcdefghijklmnopqrstuvwxyz';
  const nanoid = customAlphabet(alphabet, length - finalPostfix.length);
  const idPrefix = prefix ? `${prefix}-` : '';
  return `${idPrefix}${nanoid()}${finalPostfix}`;
};
