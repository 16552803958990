<template>
  <label
    :for="$props.for"
    class="form-label block text-sm font-medium text-gray-700 dark:text-gray-300"
  >
    {{ label }}
    <slot></slot>
  </label>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    for: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
  },
});
</script>
