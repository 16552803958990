import { computed, Ref, ref, reactive } from 'vue';
import { shortId } from '@tyltgo/shared/helpers/id-helper';

const loadingMessageRef: Ref<string> = ref(null);

export const showLoadingMessage = (message: string): void => {
  loadingMessageRef.value = message;
};

export const stopLoadingMessage = (): void => {
  loadingMessageRef.value = null;
};

export const aroundLoadingMessage = async (
  message: string,
  method: () => Promise<void>
): Promise<void> => {
  showLoadingMessage(message);
  try {
    await method();
  } finally {
    stopLoadingMessage();
  }
};

export const loadingMessage = computed(() => {
  return loadingMessageRef.value;
});

const confirmationModalData = reactive({
  show: false,
  color: 'primary',
  title: '',
  message: '',
  matchText: '',
  matchTextMessage: '',
  confirmButtonText: 'Ok',
  closeButtonText: 'Close',
});
let confirmationModalResolve: (value: boolean) => void;

export const showConfirmationModal = ({
  title,
  message,
  confirmButtonText,
  closeButtonText,
  color,
  matchText,
  matchTextMessage,
}: {
  title?: string;
  color?: string;
  message: string;
  matchText?: string;
  matchTextMessage?: string;
  confirmButtonText?: string;
  closeButtonText?: string;
}): Promise<boolean> => {
  confirmationModalData.show = true;
  confirmationModalData.title = title || '';
  confirmationModalData.color = color || 'primary';
  confirmationModalData.message = message;
  confirmationModalData.matchText = matchText || '';
  confirmationModalData.matchTextMessage = matchTextMessage || '';
  confirmationModalData.confirmButtonText = confirmButtonText || 'Ok';
  confirmationModalData.closeButtonText = closeButtonText || 'Close';

  return new Promise<boolean>(resolve => {
    confirmationModalResolve = resolve;
  });
};

export const confirmationModal = computed(() => {
  return {
    ...confirmationModalData,
    close: (): void => {
      confirmationModalData.show = false;
      confirmationModalResolve(false);
    },
    confirm: (): void => {
      confirmationModalData.show = false;
      confirmationModalResolve(true);
    },
  };
});

interface Notification {
  id?: string;
  title?: string;
  message?: string;
  color?: string;
  ttl?: number;
  show?: boolean;
}

const notificationsRef: Ref<Notification[]> = ref([]);

export const notifications = computed(() => {
  return notificationsRef.value;
});

export const replaceNotification = (id: string, notification: Notification): void => {
  const index = notificationsRef.value.findIndex(n => n.id === id);
  notificationsRef.value[index] = notification;
};

export const removeNotification = (id: string): void => {
  const notification = notificationsRef.value.find(n => n.id === id);
  if (!notification) return;
  replaceNotification(id, { ...notification, show: false });
  setTimeout(() => {
    notificationsRef.value = notificationsRef.value.filter(n => n.id !== id);
  }, 1000);
};

export const addNotification = (notification: Notification): void => {
  const id = shortId();
  notificationsRef.value.push({
    id,
    show: false,
  });
  // We do this to animate the notification.
  setTimeout(() => {
    replaceNotification(id, { ...notification, id, show: true });
  }, 10);
  const ttl = notification.ttl === 0 ? 0 : notification.ttl || 5;
  if (ttl) {
    setTimeout(() => {
      removeNotification(id);
    }, ttl * 1000);
  }
};
