<template>
  <div class="min-h-screen flex flex-col justify-center py-12 px-2 sm:px-6 lg:px-8 bg-brand-1">
    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 space-y-2">
        <h1 class="mx-auto text-2xl h-12 text-center pt-5">Safari is currently not supported.</h1>
        <h1 class="mx-auto text-xl h-12 text-center pt-2">Supported browsers:</h1>
        <div class="flex justify-around">
          <div>
            <div class="pl-1"><IconChrome class="w-12 text-brand-1" /></div>
            <div class="pt-2">Chrome</div>
          </div>
          <div class="content-center">
            <div><IconEdge class="w-12 text-brand-1" /></div>
            <div class="pt-2 pl-2">Edge</div>
          </div>
          <div>
            <div><IconFirefox class="w-12 text-brand-1" /></div>
            <div class="pt-2">Firefox</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import IconChrome from '../components/IconChrome.vue';
import IconEdge from '../components/IconEdge.vue';
import IconFirefox from '../components/IconFirefox.vue';

export default defineComponent({
  components: {
    IconChrome,
    IconEdge,
    IconFirefox,
  },
});
</script>
