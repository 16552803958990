<template>
  <div class="relative h-full" @mouseover="showTooltip = true" @mouseout="showTooltip = false">
    <slot />
    <div
      class="text-sm -mb-3 bottom-0 absolute bg-gray-900 text-gray-100 px-1 rounded-sm left-1/2 transform -translate-x-1/2 translate-y-full whitespace-nowrap z-50"
      v-if="text && showTooltip"
    >
      {{ text }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    text: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      showTooltip: false,
    };
  },
});
</script>
