/* eslint-disable import/prefer-default-export */
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import startCase from 'lodash/startCase';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc'; // dependent on utc plugin
import distance from '@turf/distance';
import {
  Merchant,
  DropoffWaypoint,
  PickupWaypoint,
  Route,
  Waypoint,
  RawOrder,
} from '../types/index';

import { addressDetails } from './address-service';
import { zplLabel } from './zpl-service';
import { time } from './date-service';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);

export const waypointState = (waypoint: Waypoint): string => {
  const { type, state, subState } = waypoint;
  if (type === 'pickup' && state === 'completed') {
    return 'picked';
  }
  if (type === 'dropoff' && state === 'completed') {
    return subState;
  }
  if (state === 'arrived') {
    return 'inProgress';
  }
  if (state === 'notArrived') {
    return 'upcoming';
  }
  if (state === 'unpicked') {
    return 'unpicked';
  }
  return '';
};

interface ColorSet {
  border: string;
  background: string;
  text: string;
  marker: string;
}

const colors: Record<string, ColorSet> = {
  Delivered: {
    border: 'border-green-200',
    background: 'bg-green-200',
    text: 'text-green-800',
    marker: 'text-green-500',
  },
  Picked: {
    border: 'border-green-200',
    background: 'bg-green-200',
    text: 'text-green-800',
    marker: 'text-green-800',
  },
  Unpicked: {
    border: 'border-pink-200',
    background: 'bg-pink-200',
    text: 'text-pink-800',
    marker: 'text-pink-500',
  },
  'Unable To Deliver': {
    border: 'border-red-200',
    background: 'bg-red-200',
    text: 'text-red-800',
    marker: 'text-red-500',
  },
  'In Progress': {
    border: 'border-blue-200',
    background: 'bg-blue-200',
    text: 'text-blue-800',
    marker: 'text-blue-500',
  },
  Upcoming: {
    border: 'border-gray-200',
    background: 'bg-gray-200',
    text: 'text-gray-800',
    marker: 'text-gray-500',
  },
  default: {
    border: 'border-gray-200',
    background: 'bg-gray-200',
    text: 'text-gray-800',
    marker: 'text-gray-500',
  },
};

colors.Door = colors.Delivered;
colors.Customer = colors.Delivered;
colors.Neighbour = colors.Delivered;
colors.Concierge = colors.Delivered;
colors.Conceirge = colors.Delivered;
colors.Reception = colors.Delivered;
colors.Receptionist = colors.Delivered;
colors.Cancelled = colors['Unable To Deliver'];
colors.Missing = colors['Unable To Deliver'];
colors['Reception Concierge Neighbour'] = colors.Delivered;

const getColorSet = (state: string): ColorSet => {
  return colors[state] || colors.default;
};

export const waypointDisplayState = (waypoint: Waypoint): string => {
  if (waypointState(waypoint) === 'bringBack') {
    return startCase('Unable To Deliver');
  }
  return startCase(waypointState(waypoint));
};

export const waypointMarkerStyle = (waypoint: Waypoint, zoom: number): string => {
  const z = zoom > 12 ? 'w-5' : 'w-4';
  return `${getColorSet(waypointDisplayState(waypoint)).marker} ${z}`;
};

export const waypointTextStyle = (waypoint: Waypoint): string => {
  return getColorSet(waypointDisplayState(waypoint)).text;
};

export const waypointBackgroundStyle = (waypoint: Waypoint): string => {
  return getColorSet(waypointDisplayState(waypoint)).background;
};

export const waypointStyle = (waypoint: Waypoint): string => {
  return `${waypointBackgroundStyle(waypoint)} ${waypointTextStyle(waypoint)}`;
};

export const waypointBorder = (waypoint: Waypoint): string => {
  return getColorSet(waypointDisplayState(waypoint)).border;
};

// TODO: treat pickup differently than dropoff for time.
export const waypointsWithEstimates = (
  waypoints: Array<
    Waypoint & {
      estimatedStatusUpdateTimeDayjs?: dayjs.Dayjs;
      estimatedArrivalTimeDayjs?: dayjs.Dayjs;
    }
  >
): Waypoint[] => {
  const timeAtDropoff = 3;
  const result = waypoints
    .map(point => ({ ...point }))
    .map((waypoint, index, array) => {
      let waypointArrivedAt = waypoint.arrivedAt;
      if (index === 0) {
        waypointArrivedAt = waypoint.cutoffTime || null;
      }
      const estimatedStatusUpdateTimeDayjs = waypointArrivedAt
        ? dayjs(waypointArrivedAt).add(timeAtDropoff, 'minutes')
        : undefined;
      // eslint-disable-next-line no-param-reassign
      waypoint.estimatedStatusUpdateTimeDayjs = estimatedStatusUpdateTimeDayjs;
      if (
        waypoint.estimatedStatusUpdateTimeDayjs &&
        dayjs().isAfter(waypoint.estimatedStatusUpdateTimeDayjs)
      ) {
        // eslint-disable-next-line no-param-reassign
        waypoint.estimatedStatusUpdateTimeDayjs = dayjs();
      }

      if (index === 0) return waypoint;

      const previousWaypoint = array[index - 1];
      // eslint-disable-next-line no-nested-ternary
      const previousUpdatedAtDayjs = ['cancelled', 'missing'].includes(previousWaypoint.subState)
        ? previousWaypoint.estimatedStatusUpdateTimeDayjs
        : previousWaypoint.statusUpdatedAt
        ? dayjs(previousWaypoint.statusUpdatedAt)
        : previousWaypoint.estimatedStatusUpdateTimeDayjs;

      if (!previousUpdatedAtDayjs) return waypoint;

      // treat 0 travel time as 1 minute.
      // treat lack of travel time as 40 minutes
      const travelDuration =
        waypoint.travelDuration === 0 ? 60 : waypoint.travelDuration || 40 * 60;

      // eslint-disable-next-line no-param-reassign
      waypoint.estimatedArrivalTimeDayjs = previousUpdatedAtDayjs
        .add(travelDuration, 'seconds')
        .add(5, 'minutes');

      const arrivedAtDayjs = waypoint.arrivedAt
        ? dayjs(waypoint.arrivedAt)
        : waypoint.estimatedArrivalTimeDayjs;
      // eslint-disable-next-line no-param-reassign
      waypoint.estimatedStatusUpdateTimeDayjs = arrivedAtDayjs
        ? arrivedAtDayjs.add(timeAtDropoff, 'minutes')
        : undefined;

      if (
        waypoint.estimatedStatusUpdateTimeDayjs &&
        dayjs().isAfter(waypoint.estimatedStatusUpdateTimeDayjs)
      ) {
        // eslint-disable-next-line no-param-reassign
        waypoint.estimatedStatusUpdateTimeDayjs = dayjs();
      }

      return waypoint;
    })
    .map(waypoint => {
      return {
        ...waypoint,
        estimatedStatusUpdateTimeDayjs: undefined,
        estimatedArrivalTimeDayjs: undefined,
        estimatedStatusUpdateTime: waypoint.estimatedStatusUpdateTimeDayjs?.format(),
        estimatedArrivalTime: waypoint.estimatedArrivalTimeDayjs?.format(),
      };
    });
  return result as Waypoint[];
};

export const arrivalDelayed = (
  waypoint: Waypoint,
  { bufferMinutes }: { bufferMinutes?: number } = {}
) => {
  if (waypoint.state === 'completed') return null;
  if (waypoint.arrivedAt || waypoint.statusUpdatedAt) return null;

  const options = {
    bufferMinutes: bufferMinutes || 15,
  };

  const now = dayjs();
  const estimatedArrivalTime = dayjs(waypoint.estimatedArrivalTime || undefined);
  const bufferTime = estimatedArrivalTime.add(options.bufferMinutes, 'minute');
  if (!now.isSameOrAfter(bufferTime)) return null;

  const delayedBy = now.diff(estimatedArrivalTime, 'minutes');
  const message = `Arrival delayed over ${delayedBy} minutes. (ETA: ${estimatedArrivalTime.format(
    'h:mm A'
  )} Buffer: ${options.bufferMinutes} minutes)`;
  return message;
};

export const completionDelayed = (waypoint: Waypoint) => {
  if (waypoint.state === 'completed') return null;
  if (waypoint.statusUpdatedAt) return null;
  if (!waypoint.arrivedAt) return null;

  const bufferMinutes = waypoint.type === 'pickup' ? 30 : 10;

  const now = dayjs();
  const arrivedAt = dayjs(waypoint.arrivedAt);
  const bufferTime = arrivedAt.add(bufferMinutes, 'minute');
  if (!now.isSameOrAfter(bufferTime)) return null;

  const delayedBy = now.diff(arrivedAt, 'minutes');
  const message = `Completion delayed over ${delayedBy} minutes. (ETA: ${arrivedAt.format(
    'h:mm A'
  )} Buffer: ${bufferMinutes} minutes)`;
  return message;
};

export const pickupDelayed = (
  waypoint: Waypoint,
  { bufferMinutes }: { bufferMinutes?: number } = {}
) => {
  if (waypoint.type !== 'pickup') return null;
  if (waypoint.state === 'completed') return null;
  if (waypoint.arrivedAt) return null;

  const options = {
    bufferMinutes: bufferMinutes || 20,
  };

  const now = dayjs();
  const pickupTime = dayjs((waypoint as PickupWaypoint).cutoffTime || undefined);
  const bufferTime = pickupTime.add(options.bufferMinutes, 'minute');
  if (!now.isSameOrAfter(bufferTime)) return null;

  const delayedBy = now.diff(pickupTime, 'minutes');
  const message = `Pickup delayed over ${delayedBy} minutes. (Pickup: ${pickupTime.format(
    'h:mm A'
  )} Buffer: ${options.bufferMinutes} minutes)`;
  return message;
};

export const pickupTimePushBack = (
  waypoint: Waypoint,
  { bufferMinutes }: { bufferMinutes?: number } = {}
) => {
  if (waypoint.type !== 'pickup') return false;
  if (waypoint.state === 'completed') return false;
  if (waypoint.arrivedAt) return false;

  const options = {
    bufferMinutes: bufferMinutes || 15,
  };

  const now = dayjs();
  const pickupTime = dayjs((waypoint as PickupWaypoint).cutoffTime || undefined);
  const bufferTime = pickupTime.subtract(options.bufferMinutes, 'minute');
  return now.isSameOrAfter(bufferTime);
};

export const estimatedPastCutoff = (
  waypoint: Waypoint,
  {
    minutesBeforeCutoff,
    maxCutoffTime,
  }: { minutesBeforeCutoff?: number; maxCutoffTime?: dayjs.Dayjs } = {}
) => {
  if (!waypoint.estimatedArrivalTime) return null;
  if (waypoint.type === 'pickup') return null;
  if (waypoint.state === 'completed') return null;

  const timeZoneId = waypoint.timeZoneId || 'America/Toronto';
  const isDefaultCutoffTime = !waypoint.cutoffTime;
  const cutoffTime = waypoint.cutoffTime
    ? dayjs(waypoint.cutoffTime)
    : maxCutoffTime ||
      dayjs
        .tz(waypoint.routeDate, timeZoneId)
        .hour(12 + 9)
        .minute(0)
        .second(0);
  const estimatedArrivalTime = dayjs(waypoint.estimatedArrivalTime);

  if (minutesBeforeCutoff && !dayjs().add(minutesBeforeCutoff, 'minute').isAfter(cutoffTime))
    return null;
  if (!estimatedArrivalTime.isAfter(cutoffTime)) return null;

  const delayedBy = estimatedArrivalTime.diff(cutoffTime, 'minutes');
  const message = `ETA (${estimatedArrivalTime.format('h:mm A')}) is ${delayedBy} minutes past ${
    isDefaultCutoffTime ? 'our' : 'merchant'
  } cutoff time ${cutoffTime.format('h:mm A')}.`;
  return message;
};

export const geolocationDistance = (
  waypoint: Waypoint,
  {
    updateDistanceBuffer,
    arrivalDistanceBuffer,
  }: { arrivalDistanceBuffer?: number; updateDistanceBuffer?: number } = {}
) => {
  const hasLocation = waypoint.arrivedLocation || waypoint.statusUpdatedLocation;
  if (!hasLocation) return null;

  const options = {
    updateDistanceBuffer: updateDistanceBuffer || 225,
    arrivalDistanceBuffer: arrivalDistanceBuffer || 1000,
  };

  const arrivalDistance = waypoint.arrivedLocation
    ? distance(
        [waypoint.lat, waypoint.lng],
        [waypoint.arrivedLocation.latitude, waypoint.arrivedLocation.longitude],
        { units: 'meters' }
      )
    : 0;
  const statusUpdateDistance = waypoint.statusUpdatedLocation
    ? distance(
        [waypoint.lat, waypoint.lng],
        [waypoint.statusUpdatedLocation.latitude, waypoint.statusUpdatedLocation.longitude],
        { units: 'meters' }
      )
    : 0;

  const messages: string[] = [];

  if (arrivalDistance > options.arrivalDistanceBuffer) {
    messages.push(
      `Arrival distance is ${Math.ceil(arrivalDistance)}m (Arrival Buffer: ${
        options.arrivalDistanceBuffer
      }) `
    );
  }

  if (statusUpdateDistance > options.updateDistanceBuffer) {
    messages.push(
      `Status update distance is ${Math.ceil(statusUpdateDistance)}m (Update Buffer: ${
        options.updateDistanceBuffer
      })`
    );
  }

  const message = messages.join('');
  return message;
};

export const missingStreetNumber = (waypoint: Waypoint) => {
  let message = '';
  if (waypoint.address && !/^\d+/.test(waypoint.address.trim())) {
    message = waypoint.address;
  }

  return message;
};

export const questionableAddressLine = (waypoint: Waypoint) => {
  let message = '';
  if (waypoint.address) {
    const [firstPart] = waypoint.address.split(',');
    if (firstPart.length < 8) {
      message = `'${firstPart}' is less than 8 characters`;
    }
  }

  return message;
};

export const waypointsWithAlerts = (
  waypoints: Waypoint[],
  options: { estimate: boolean } = { estimate: true }
): Waypoint[] => {
  const points = options.estimate ? waypointsWithEstimates(waypoints) : waypoints;
  const timeZoneId = waypoints[0].timeZoneId || 'America/Toronto';
  const maxCutoffTime = dayjs
    .tz(waypoints[0].routeDate, timeZoneId)
    .hour(12 + 9)
    .minute(0)
    .second(0);
  const result = points.map(waypoint => {
    const alerts = [];

    // TODO: make this more generic.
    const pickupMessage = pickupDelayed(waypoint);
    if (pickupMessage) {
      alerts.push({
        type: 'pickupDelayed',
        display: 'Pickup Delayed',
        message: pickupMessage,
      });
    }

    const arrivalMessage = arrivalDelayed(waypoint);
    if (arrivalMessage) {
      alerts.push({
        type: 'arrivalDelayed',
        display: 'Arrival Delayed',
        message: arrivalMessage,
      });
    }

    const completionMessage = completionDelayed(waypoint);
    if (completionMessage) {
      alerts.push({
        type: 'completionDelayed',
        display: 'Completion Delayed',
        message: completionMessage,
      });
    }

    const cutoffMessage = estimatedPastCutoff(waypoint, { maxCutoffTime });
    if (cutoffMessage) {
      alerts.push({
        type: 'estimatedPastCutoff',
        display: 'Estimated Past Cutoff',
        message: cutoffMessage,
      });
    }

    const geolocationDistanceMessage = geolocationDistance(waypoint);
    if (geolocationDistanceMessage) {
      alerts.push({
        type: 'geolocationDistance',
        display: 'Geolocation Distance',
        message: geolocationDistanceMessage,
      });
    }

    const missingStreetNumberMessage = missingStreetNumber(waypoint);
    if (missingStreetNumberMessage) {
      alerts.push({
        type: 'missingStreetNumber',
        display: 'Missing Street Number',
        message: missingStreetNumberMessage,
      });
    }

    const questionableAddressLineMessage = questionableAddressLine(waypoint);
    if (questionableAddressLineMessage) {
      alerts.push({
        type: 'questionableAddressLine',
        display: 'Questionable Address Line',
        message: questionableAddressLineMessage,
      });
    }

    return {
      ...waypoint,
      alerts,
    };
  });
  return result;
};

export const waypointsWithDeliveryCutoffs = (
  waypoints: Waypoint[],
  merchants: Merchant[]
): Waypoint[] => {
  const { routeDate, timeZoneId } = waypoints[0];
  const cutoffTimesByMerchantId: Record<string, string | null> =
    merchants?.reduce(
      (byId, merchant) => ({
        ...byId,
        [merchant.email]: merchant?.settings.deliveryCutoffTime
          ? dayjs
              .tz(
                `${routeDate} ${merchant.settings.deliveryCutoffTime}`,
                timeZoneId || 'America/Toronto'
              )
              .format()
          : null,
      }),
      {} as Record<string, string | null>
    ) || {};
  return waypoints.map(point => {
    if (point.cutoffTime || point.type === 'pickup') return point;
    const cutoffTime = cutoffTimesByMerchantId[point.associatedEmail];
    return {
      ...point,
      cutoffTime,
    };
  });
};

export const waypointNumber = (route: Route, waypoint: Waypoint): number => {
  const { type } = waypoint;
  const points = route.waypoints.filter(point => point.type === type);
  return points.findIndex(point => point.id === waypoint.id) + 1;
};

export const pickupForDropoff = (route: Route, dropoff: Waypoint): PickupWaypoint | undefined => {
  return route.waypoints.find(
    x => x.associatedOrder === dropoff.associatedOrder && x.type === 'pickup'
  ) as PickupWaypoint;
};

export const pickupsForRoute = (route: Route): PickupWaypoint[] => {
  return (route.waypoints as PickupWaypoint[]).filter(x => x.type === 'pickup');
};

export const dropoffsForPickup = (route: Route, pickup: Waypoint): DropoffWaypoint[] => {
  return (route.waypoints.filter(
    x => x.associatedOrder === pickup.associatedOrder && x.type === 'dropoff'
  ) || []) as DropoffWaypoint[];
};

export const merchantIdsForRoute = (route: Route): string[] => {
  return pickupsForRoute(route).map(pickup => pickup.associatedEmail);
};

export const dropoffsForRoute = (route: Route): DropoffWaypoint[] => {
  return (route.waypoints as DropoffWaypoint[]).filter(x => x.type === 'dropoff');
};

export const waypointZpl = ({
  route,
  waypoint,
  merchant,
  toChangeTimezone,
}: {
  route: Route;
  waypoint: DropoffWaypoint;
  merchant: Merchant;
  toChangeTimezone?: string;
}) => {
  const number = merchant?.settings.removeDropoffNumberLabel
    ? ''
    : waypoint.stopNumber || waypointNumber(route, waypoint);
  const pickup = pickupForDropoff(route, waypoint);
  const address = addressDetails(waypoint.address);
  const formattedTime = pickup?.cutoffTime
    ? time(dayjs(pickup.cutoffTime).format(), toChangeTimezone)
    : '';

  const quantity = Number((waypoint as DropoffWaypoint).quantity);
  let customerAddressline2 = '';

  if (waypoint.addressLine2 !== '' && !Number.isNaN(Number(waypoint.addressLine2))) {
    customerAddressline2 = `Unit ${waypoint.addressLine2}`;
  }

  return [...Array(quantity).keys()]
    .map(itemNumber => {
      return zplLabel({
        name: waypoint.name,
        phone: waypoint.phoneNumber,
        address1: address.street,
        address2: customerAddressline2 || waypoint.addressLine2 || '',
        city: address.city,
        postalProvince: `${address.postalCode} ${address.province}`,
        id: waypoint.id,
        routeNumber: route.routeNumber?.toString() || '',
        routeId: route.id || '',
        pickupTime: formattedTime,
        stopNumber: number,
        date: waypoint.routeDate,
        barcode: waypoint.id,
        isLineHaul: route.isLineHaul || false,
        routingGroup: route.routingGroup || '',
        pickupAddress: route.pickupAddress,
        isMiddleMile: route.isMiddleMile || false,
        middleMileGroup: route.middleMileGroup || '',
        middleMileAddress: route.middleMileAddress || '',
        itemNumber: itemNumber + 1,
        totalItems: quantity,
        zplLogo: merchant.settings.labelLogoImageUrl ? merchant.settings.zplLogo : undefined,
      });
    })
    .join('\n');
};

export const waypointEtaWindow = (waypoint: Waypoint, picked: boolean) => {
  let estimatedArrivalTimeStart = '';
  let estimatedArrivalTimeEnd = '';
  let etaWindow = '';

  if (waypoint.estimatedArrivalTime) {
    let estimatedArrivalTime = dayjs(waypoint.estimatedArrivalTime).second(0);
    const minute = estimatedArrivalTime.minute();
    const roundTo = 15;
    const remain = minute % roundTo;

    if (remain < roundTo / 2) {
      estimatedArrivalTime = estimatedArrivalTime.subtract(remain, 'minute');
    } else {
      estimatedArrivalTime = estimatedArrivalTime.add(roundTo - remain, 'minute');
    }

    const etaWindowMinutes = picked ? 60 : 90;
    estimatedArrivalTimeStart = estimatedArrivalTime.subtract(etaWindowMinutes, 'minute').format();
    estimatedArrivalTimeEnd = estimatedArrivalTime.add(etaWindowMinutes, 'minute').format();

    const timeZoneId = waypoint.timeZoneId || 'America/Toronto';
    if (estimatedArrivalTimeStart) {
      etaWindow = `${dayjs(estimatedArrivalTimeStart).tz(timeZoneId).format('h:mm A')} - ${dayjs(
        estimatedArrivalTimeEnd
      )
        .tz(timeZoneId)
        .format('h:mm A')}`;
    }
  }

  return {
    estimatedArrivalTimeStart,
    estimatedArrivalTimeEnd,
    etaWindow,
  };
};

export const waypointTrackingLinkBackend = (waypoint: DropoffWaypoint, merchant?: Merchant) => {
  const parts = [];

  parts.push(process.env.FRONTEND_URL);

  if (merchant?.trackingSlug) {
    parts.push('track');
    parts.push(merchant.trackingSlug);
  } else {
    parts.push('tracking');
  }

  parts.push(waypoint.id);
  parts.push(waypoint.trackingSecret);

  return parts.filter(x => !!x).join('/');
};

export const rawOrderTrackingLinkBackend = (rawOrder: RawOrder, merchant?: Merchant) => {
  const parts = [];

  parts.push(process.env.FRONTEND_URL);

  if (merchant?.trackingSlug) {
    parts.push('track');
    parts.push(merchant.trackingSlug);
  } else {
    parts.push('tracking');
  }

  parts.push(rawOrder.packageId);
  parts.push(rawOrder.trackingSecret);

  return parts.filter(x => !!x).join('/');
};

export const waypointUnsubscribeLinkBackend = (waypoint: DropoffWaypoint, merchant?: Merchant) => {
  const parts = [];

  parts.push(process.env.FRONTEND_URL);

  if (merchant?.trackingSlug) {
    parts.push('unsubscribe');
    parts.push(merchant.trackingSlug);
  } else {
    parts.push('unsubscribed');
  }

  parts.push(waypoint.id);
  parts.push(waypoint.trackingSecret);

  return parts.filter(x => !!x).join('/');
};
