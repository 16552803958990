<template>
  <div v-if="hasItems()" class="relative">
    <button @click="showDropdown = !showDropdown">
      <slot name="button"></slot>
    </button>
    <div
      v-if="showDropdown"
      v-click-away="() => (showDropdown = !showDropdown)"
      class="origin-top-right absolute z-30 right-0 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black dark:ring-gray-600 ring-opacity-5 focus:outline-none"
      style="z-index: 1200"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-2"
    >
      <div class="py-1">
        <slot name="items"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      showDropdown: false,
    };
  },
  provide() {
    return {
      hideDropdown: this.hideDropdown,
    };
  },
  methods: {
    hasItems() {
      if (!this.$slots.items) return false;
      return this.$slots.items().filter(x => x.children && x.children !== 'v-if').length > 0;
    },
    hideDropdown() {
      this.showDropdown = false;
    },
  },
});
</script>
