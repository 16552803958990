<template>
  <l-polyline
    v-if="polyline"
    :lat-lngs="polylinePoints"
    :color="color === 'random' ? displayColor || randomColor() : '#4B5563'"
    :opacity="0.6"
    :weight="4"
    draggable
    @click="clicked"
  />

  <template v-if="showMarkers">
    <WaypointMapMarker
      v-for="waypoint in route.waypoints"
      :key="waypoint.id"
      :waypoint="waypoint"
      :waypointNumber="waypoint.stopNumber || waypointNumber(route, waypoint)"
      :route="route"
      :zoom="zoom"
      :link="link"
    />
  </template>
</template>

<script lang="ts">
import { LPolyline } from '@vue-leaflet/vue-leaflet';
import { defineComponent } from 'vue';
import { waypointNumber, decodePolyline } from '@tyltgo/shared';
import randomColor from 'randomcolor';
import WaypointMapMarker from './WaypointMapMarker.vue';

export default defineComponent({
  setup() {
    return { waypointNumber };
  },
  components: {
    LPolyline,
    WaypointMapMarker,
  },
  props: {
    route: {
      type: Object,
      required: true,
    },
    markers: {
      type: Boolean,
      default: true,
    },
    link: {
      type: String,
      required: true,
    },
    zoom: {
      type: Number,
      default: 6,
    },
    polyline: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: '#4B5563',
    },
  },
  mounted() {
    this.showMarkers = this.markers;
    this.displayColor = this.randomColor();
  },
  computed: {
    polylinePoints() {
      return decodePolyline(this.route.polyline);
    },
  },
  data() {
    return {
      showMarkers: null,
      displayColor: null,
    };
  },
  methods: {
    randomColor() {
      return randomColor({ luminosity: 'dark' });
    },
    clicked() {
      this.showMarkers = !this.showMarkers;
    },
  },
});
</script>
