/* eslint-disable import/prefer-default-export */
import { apiService, Courier, Route } from '@tyltgo/shared';
import { computed, Ref, ref } from 'vue';

const couriersRef: Ref<Courier[]> = ref(null);
const couriersTotalRef: Ref<number> = ref(null);

export const couriers = computed(() => {
  return couriersRef.value;
});

export const couriersTotal = computed(() => {
  return couriersTotalRef.value;
});

export const loadCouriers = async (
  searchText: string,
  options: {
    fixedSearchText?: string;
    size?: number;
    sortBy?: string;
    sortOrder?: string;
    currentDay?: string;
    radius?: number;
    geoPin?: string;
  }
): Promise<void> => {
  const results = await apiService.admin.couriers.search(searchText, options);
  couriersRef.value = results.data;
  couriersTotalRef.value = results.total;
};

export const declinedRoutesForCouriers = async (
  routeDate: string,
  couriersList?: Courier[]
): Promise<Route[]> => {
  if (couriersList && !couriersList.length) return [];

  const list = couriersList
    ? `(${couriersList.map(courier => `declinedCouriers:"${courier.id}"`).join(' OR ')})`
    : '';
  const { data: declined } = await apiService.admin.routes.searchRoutes(
    `elastic routeDate:${routeDate} ${list}`,
    1000
  );
  return declined;
};

export const assignedRoutesForCouriers = async (
  routeDate: string,
  couriersList?: Courier[]
): Promise<Route[]> => {
  if (couriersList && !couriersList.length) return [];

  const list = couriersList
    ? `(${couriersList.map(courier => `courierId:"${courier.id}"`).join(' OR ')})`
    : ``;

  const { data: assigned } = await apiService.admin.routes.searchRoutes(
    `elastic routeDate:${routeDate} _exists_:assignedDriver ${list}`,
    1000
  );
  return assigned;
};
