<template>
  <main class="flex-1 overflow-y-auto focus:outline-none" tabindex="0">
    <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0" v-if="showGeneratedBatches">
      <div class="pt-10 pb-16">
        <div class="px-4 sm:px-6 md:px-0 flex items-center">
          <IconArrowLeft @click="showGeneratedBatches = false" class="mr-4 w-6" />
          <GoHeader :level="1">Review Batches</GoHeader>
        </div>
      </div>
      <div class="px-4 sm:px-6 md:px-0 pt-10">
        <div
          class="bg-white px-4 pt-2 pb-4 shadow rounded-lg mb-4 flex border-l-4 border-red-300 flex-col"
          v-if="unassignedDropoffs.length"
        >
          <p class="text-lg mb-4 font-bold">Unassigned Orders</p>
          <div v-for="(point, pointIndex) in unassignedDropoffs" :key="pointIndex" class="flex">
            <p class="w-1/4 text-right pr-4">{{ point.name }}</p>
            <p class="w-3/4">{{ point.address }}</p>
          </div>
        </div>
        <NewBatchCard
          :batch="batch"
          :index="index"
          v-for="(batch, index) in generatedBatches"
          :key="index"
        />

        <GoButton
          variant="link"
          class="text-xs py-5"
          v-if="currentUser.featureFlags?.newBatchesToCsv"
          @click="downloadCsv"
        >
          Download CSV
        </GoButton>
      </div>
    </div>
    <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0" v-show="!showGeneratedBatches">
      <div class="pt-10 pb-16">
        <div class="px-4 sm:px-6 md:px-0 flex justify-between">
          <GoHeader :level="1">New Order Batch</GoHeader>
          <GoUploadButton
            variant="outline"
            class="text-xs"
            id="csv_upload"
            @filesSelected="fileSelected"
            accept=".csv"
          >
            Upload CSV
          </GoUploadButton>
          <GoModal v-if="showModal" v-slot="{ close }" @close="modalClosed">
            <MapFields @selected="close" :files="files" :merchant="currentUser" />
          </GoModal>
        </div>
        <div class="px-4 sm:px-6 md:px-0 pt-10">
          <section class="mb-2">
            <form @submit.prevent="onSubmitWrapper" ref="form" autocomplete="off">
              <div v-if="adminAuthToken && currentUser.parentIds?.length" class="py-3">
                <div class="bg-white p-4 rounded-md">
                  <GoCheckbox
                    name="showSameDay"
                    label="Same Day"
                    v-model="showSameDay"
                    value="true"
                  />
                  <p class="pt-2 text-sm">Visible to Tyltgo Admin's only.</p>
                </div>
              </div>
              <div class="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-3 mb-4">
                <GoTextField label="Delivery Day" name="day" type="date" class="w-full md:w-1/4" />
                <GoSelect
                  v-if="uploadType === 'sameDay'"
                  placeholder="Select Pickup Address"
                  class="w-full md:w-3/4"
                  label="Pickup Address"
                  name="businessAddress"
                  :options="currentUser.businessAddresses"
                  label-field="businessAddress"
                  value-field="businessAddress"
                />
              </div>

              <div
                v-if="validOrders.length"
                class="shadow sm:rounded-md sm:overflow-hidden mb-4 p-4 bg-white dark:bg-gray-800"
              >
                <div class="flex justify-between">
                  <div>{{ validOrders.length }} orders validated.</div>
                  <GoButton
                    class="text-sm"
                    variant="link"
                    @click="showValidOrders = !showValidOrders"
                    type="button"
                  >
                    <div class="flex">
                      <IconAngleRight v-if="showValidOrders" class="w-2 mr-2 transform rotate-90" />
                      <IconAngleRight v-else class="w-2 mr-2" />
                      <div v-if="showValidOrders">Hide</div>
                      <div v-else>Show</div>
                    </div>
                  </GoButton>
                </div>
                <template v-if="showValidOrders">
                  <GoTable
                    class="mt-4 overflow-x-auto"
                    :tableData="{
                      headers: [
                        { title: 'Name', key: 'name' },
                        { title: 'Address', key: 'address' },
                        { title: 'Phone', key: 'phoneNumber' },
                      ],
                      data: validOrders,
                    }"
                  ></GoTable>
                </template>
              </div>
              <div v-if="validOrders.length && formOrdersArray.length" class="mb-4">
                Please review the orders below:
              </div>

              <NewOrderForm
                v-for="(entry, index) of formOrdersArray"
                :key="entry.key"
                :droppedAddress="entry.value.droppedAddress"
                :merchant="currentUser"
                :outsidePriceList="entry.value.outsidePriceList"
                :prefix="`orders[${index}].`"
                :orderNumber="`# ${Number(index) + 1}`"
                @removeOrder="removeOrder(Number(index))"
                :entry="entry"
              />
              <div class="flex justify-start py-2">
                <GoButton class="text-xs" variant="outline" @click="pushOrder({})" type="button">
                  <div class="flex">
                    <IconPlus class="w-2 mr-2" />
                    Add Order
                  </div>
                </GoButton>
              </div>
              <div class="flex justify-center py-2">
                <GoButton class="text-base">
                  {{ uploadType === 'sameDay' ? 'Submit Batch' : 'Save Orders' }}
                </GoButton>
              </div>
            </form>
          </section>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import { useForm, useFormValues, useFieldArray } from 'vee-validate';
import * as yup from 'yup';
import { addressDetails, apiService, GenerateBatchOptions } from '@tyltgo/shared';
import { shortId } from '@tyltgo/shared/helpers/id-helper';
import dayjs from 'dayjs';
import pluralize from 'pluralize';
import sortBy from 'lodash/sortBy';
import groupBy from 'lodash/groupBy';
import { useRouter } from 'vue-router';
import GoTextField from '../../components/GoTextField.vue';
import GoButton from '../../components/GoButton.vue';
import GoUploadButton from '../../components/GoUploadButton.vue';
import GoSelect from '../../components/GoSelect.vue';
import GoModal from '../../components/GoModal.vue';
import GoTable from '../../components/GoTable.vue';
import IconPlus from '../../components/IconPlus.vue';
import IconArrowLeft from '../../components/IconArrowLeft.vue';
import IconAngleRight from '../../components/IconAngleRight.vue';
import MapFields from '../../components/MapFields.vue';
import NewBatchCard from '../../components/NewBatchCard.vue';
import NewOrderForm from '../../components/NewOrderForm.vue';
import { currentUser } from '../../services/auth-service';
import { downloadBlob } from '../../services/browser-service';
import {
  showLoadingMessage,
  stopLoadingMessage,
  showConfirmationModal,
} from '../../services/ui-message-service';

const currentDate = dayjs().startOf('day');
const customDateValidationMessage = 'Delivery Day must be today or later.';
const ordersCollapseThreshold = 50;

const showOutsideServiceRadiusModal = (numberOfOSA: number) => {
  showConfirmationModal({
    title: `${pluralize('Address', numberOfOSA)} Outside Service Radius`,
    message: `${numberOfOSA} ${pluralize('address', numberOfOSA)} ${pluralize(
      'is',
      numberOfOSA
    )} outside your Tyltgo Service Agreement delivery region. Please remove the ${pluralize(
      'address',
      numberOfOSA
    )} to continue or contact Tyltgo Support. `,
    color: 'danger',
  });
};

export default defineComponent({
  components: {
    GoTable,
    GoTextField,
    GoButton,
    GoUploadButton,
    GoSelect,
    GoModal,
    IconPlus,
    IconArrowLeft,
    MapFields,
    NewBatchCard,
    NewOrderForm,
    IconAngleRight,
  },
  setup() {
    const router = useRouter();

    const generatedBatches = ref([]);
    const unassignedDropoffs = ref([]);
    const geolocations = ref([]);
    const validOrders = ref([]);
    const showGeneratedBatches = ref(false);
    const showSameDay = ref(false);
    const { handleSubmit, setFieldValue, setFieldError, setFieldTouched, errors } = useForm();
    const formValues = useFormValues();
    const { remove: removeOrder, push: pushOrder, fields: formOrdersArray } = useFieldArray(
      'orders'
    );
    pushOrder({});

    const uploadType = computed(() => {
      if (showSameDay.value) return 'sameDay';
      if (currentUser.value.parentIds?.length) return 'nextDay';
      return 'sameDay';
    });

    const orderSchema = computed(() => {
      return yup.object().shape({
        name: yup.string().required().label('Name'),
        address: yup
          .string()
          .required()
          .label('Address')
          .test('length', 'is required', val => (val || '').replace(/ /g, '').length > 5),

        phoneNumber: yup.string().required().label('Phone Number'),
        email: yup.string().label('Email'),
        quantity: yup.number().required().label('Quantity'),
        orderNumber:
          uploadType.value === 'nextDay'
            ? yup.string().required().label('Order Number')
            : yup.string().label('Order Number'),
      });
    });
    const waitTime = (count: number) => {
      const options = [2000, 2000, 5000, 5000, 10000, 15000];
      return options[count % options.length];
    };

    const formErrorDialog = async () => {
      const errorCount = Object.keys(errors.value).length;
      if (errorCount) {
        console.log({ errors });
        showConfirmationModal({
          title: `Form ${pluralize('Error', errorCount)}`,
          message: `Please update highlighted ${pluralize('field', errorCount)}.`,
          color: 'danger',
        });
      }
    };

    const onSubmit = handleSubmit(async values => {
      const currentYear = dayjs().year();
      const dayValue = values.day;
      let day = dayjs(dayValue);
      if (Number.isNaN(day.year())) {
        // If we can't parse let's use today.
        day = dayjs();
      } else if (day.year() < currentYear) {
        // If the year is less than the current year let's add the current year.
        day = dayjs(`${dayValue} ${currentYear}`);
      }
      const formattedDay = day.format('YYYY-MM-DD');

      const loadingMessage =
        uploadType.value === 'nextDay' ? 'Saving Orders' : 'Generating Batches';
      showLoadingMessage(`${loadingMessage} ...`);
      const allOrders = [...(values.orders || []), ...validOrders.value];
      const data: GenerateBatchOptions = {
        ...(values as GenerateBatchOptions),
        day: formattedDay,
        orders: allOrders.map(order => ({
          ...order,
          id: order.orderNumber,
          outsidePriceList: order.outsidePriceList,
          isPriority: undefined,
          relativePriorityTime: order.isPriority ? 4 : undefined,
          location: geolocations.value[order.address] || order.geo[order.address],
        })),
      };

      if (uploadType.value === 'nextDay') {
        try {
          await apiService.merchants.batches.createRawOrders(data);
          const message = `Orders have been successfully uploaded.`;
          stopLoadingMessage();
          await showConfirmationModal({
            title: `Orders Successfully Uploaded`,
            message,
            color: 'success',
          });
          router.push({ name: 'merchant.batches', query: { day: formattedDay } });
        } finally {
          stopLoadingMessage();
        }
        return;
      }

      try {
        const {
          routingRequestId,
        }: { routingRequestId: string } = await apiService.merchants.batches.generate(data);
        console.log({ routingRequestId });

        // eslint-disable-next-line no-await-in-loop
        await new Promise(r => setTimeout(r, 2000));

        let state = '';
        let count = 0;
        while (state !== 'completed') {
          // eslint-disable-next-line no-await-in-loop
          const {
            state: updatedState,
          }: // eslint-disable-next-line no-await-in-loop
          { state: string } = await apiService.merchants.batches.routingRequestState(
            routingRequestId
          );
          if (state === 'error') {
            // eslint-disable-next-line @typescript-eslint/no-throw-literal
            throw new Error('Batch Generation Error');
          }

          state = updatedState;

          if (state !== 'completed') {
            const wait = waitTime(count);
            console.log({ wait });
            // eslint-disable-next-line no-await-in-loop
            await new Promise(r => setTimeout(r, wait));
          }

          count += 1;

          if (count > 90) {
            // eslint-disable-next-line @typescript-eslint/no-throw-literal
            throw new Error('Batch Generation Error');
          }
        }

        // eslint-disable-next-line no-await-in-loop
        const result = await apiService.merchants.batches.routingRequestResult(routingRequestId);

        unassignedDropoffs.value = result.unassignedDropoffs || [];
        generatedBatches.value = sortBy(
          result.batches.map(batch => ({
            ...batch,
            businessAddress: data.businessAddress,
            day: data.day,
          })),
          'routeInformation.duration'
        ).reverse();
        showGeneratedBatches.value = true;
      } catch (e) {
        showConfirmationModal({
          title: `Batch Generation Error`,
          message: `Please try again or contact support.`,
          color: 'danger',
        });
      }
      stopLoadingMessage();
    });

    const validateForm = async () => {
      const schema = yup.object().shape({
        day: yup
          .date()
          .required()
          .min(currentDate, customDateValidationMessage)
          .label('Delivery Day'),
        businessAddress:
          uploadType.value === 'nextDay'
            ? undefined
            : yup.string().required().label('Pickup Address'),
        orders: yup.array().of(orderSchema.value),
      });

      try {
        await schema.validate(formValues.value, { abortEarly: false });
      } catch (e) {
        e.inner.forEach(error => {
          setFieldError(error.path, error.message);
        });
      }
    };

    const clearErrors = async () => {
      Object.entries(errors.value).forEach(([key]) => {
        setFieldError(key, null);
      });
    };

    const adminAuthToken = localStorage.getItem('authToken-admin');
    const onSubmitWrapper = async (
      event: Event & {
        target: HTMLFormElement;
      }
    ) => {
      await clearErrors();
      await validateForm();

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const formOrders = (formValues.value as any).orders || [];
      const orderNumbersCount = formOrders.reduce((numbers, order) => {
        if (!order.orderNumber) return numbers;

        const count = (numbers[order.orderNumber] || 0) + 1;
        return {
          ...numbers,
          [order.orderNumber]: count,
        };
      }, {});
      let numberOfOSA = 0;
      for (const [index, order] of formOrders.entries()) {
        if (!geolocations.value[order.address] && !order.geo[order.address]) {
          setFieldError(
            `orders[${index}].address`,
            'Please select the address from the autocomplete dropdown.'
          );
        }

        if (orderNumbersCount[order.orderNumber] > 1) {
          setFieldError(`orders[${index}].orderNumber`, 'Order Number must be unique');
        }
        numberOfOSA += order.outsidePriceList ? 1 : 0;
      }

      const hasErrors = Object.keys(errors.value).length > 0;
      if (hasErrors) {
        formErrorDialog();
      } else if (numberOfOSA && !adminAuthToken) {
        showOutsideServiceRadiusModal(numberOfOSA);
      } else {
        if (numberOfOSA && adminAuthToken) {
          await showConfirmationModal({
            title: `${pluralize('Address', numberOfOSA)} Outside Service Radius`,
            message: `${numberOfOSA} ${pluralize('address', numberOfOSA)} ${pluralize(
              'is',
              numberOfOSA
            )} outside your Tyltgo Service Agreement delivery region. You can proceed since you are logged in as admin.`,
            color: 'warning',
          });
        }
        onSubmit(event);
      }
    };

    return {
      adminAuthToken,
      uploadType,
      showSameDay,
      showGeneratedBatches,
      generatedBatches,
      unassignedDropoffs,
      currentUser,
      formValues,
      clearErrors,
      onSubmitWrapper,
      setFieldValue,
      setFieldTouched,
      geolocations,
      formOrdersArray,
      removeOrder,
      pushOrder,
      validOrders,
      orderSchema,
    };
  },
  data() {
    return {
      showModal: false,
      files: null,
      isOutside: [],
      showValidOrders: false,
    };
  },
  methods: {
    async modalClosed(orders) {
      this.showModal = false;
      this.files = [];
      if (!orders) return;

      await new Promise(r => setTimeout(r, 100));
      showLoadingMessage('Loading Form...');
      await new Promise(r => setTimeout(r, 100));

      const orderNumbersCount = orders.reduce((numbers, order) => {
        if (!order.orderNumber) return numbers;

        const count = (numbers[order.orderNumber] || 0) + 1;
        return {
          ...numbers,
          [order.orderNumber]: count,
        };
      }, {});

      const validatedOrders = orders.map(order => {
        // we're doing this instead of setting the geo location
        // inside the object is because some addresses contain
        // dots, and that breaks up the hash.
        let outsidePriceList = false;
        if (order.latitude && order.longitude) {
          this.geolocations[order.addressLine1] = {
            lat: order.latitude,
            lng: order.longitude,
          };

          const { fsa } = addressDetails(order.addressLine1);

          if (this.currentUser.pricingType === 'fixed') {
            const priceListCheck = Object.keys(this.currentUser.priceList).find(key => key === fsa);
            if (!priceListCheck) {
              outsidePriceList = true;
              this.isOutside.push(fsa);
            }
          }
        }

        const notes = [order.addressLine2, order.notes].filter(x => !!x).join(' - ') || '';
        const mappedOrder = {
          rawAddress: order.rawAddress,
          name: order.name,
          address: order.addressLine1,
          addressLine2: order.addressLine2,
          phoneNumber: order.phoneNumber,
          quantity: order.quantity || 1,
          volume: order.volume,
          orderNumber: order.orderNumber,
          email: order.email,
          notes,
          outsidePriceList,
          droppedAddress: order.droppedAddress || false,
        };

        const duplicate =
          mappedOrder.orderNumber && (orderNumbersCount[mappedOrder.orderNumber] || 0) > 1;
        return {
          ...mappedOrder,
          valid: this.orderSchema.isValidSync(mappedOrder) && !duplicate,
        };
      });

      let ordersForForm;

      if (validatedOrders.length > ordersCollapseThreshold) {
        const hasIssues = order => !order.valid || order.droppedAddress || order.outsidePriceList;

        const groupedOrders = groupBy(validatedOrders, hasIssues);
        const validOrders = groupedOrders.false || [];
        this.validOrders.push(...validOrders);
        ordersForForm = groupedOrders.true || [];
      } else {
        ordersForForm = validatedOrders;
      }

      this.$nextTick(async () => {
        for (const order of ordersForForm) {
          this.pushOrder(order);
          // eslint-disable-next-line no-await-in-loop
          await new Promise(r => setTimeout(r, 5));
        }

        this.$nextTick(() => {
          if (this.formValues?.orders?.length) {
            const firstOrder = this.formValues.orders[0];
            if (firstOrder && !firstOrder.name && !firstOrder.address) {
              this.removeOrder(0);
            }
          }

          if (this.isOutside.length) {
            showOutsideServiceRadiusModal(this.isOutside.length);
          }
          this.$nextTick(() => {
            stopLoadingMessage();
          });
        });
      });
    },
    fileSelected(files) {
      this.files = files;
      this.showModal = true;
    },
    downloadCsv() {
      console.log({ generatedBatches: this.generatedBatches });
      const header = `Batch Number,Date,Address,Street,City,Province,Postal Code\n`;
      const lines = this.generatedBatches
        .map((batch, index) => {
          const uniqueId = `${shortId()}${index}`;
          return batch.dropOffPoints
            .map(point => {
              const address = addressDetails(point.address);
              const line = [
                uniqueId,
                batch.day,
                point.address,
                address.street,
                address.city,
                address.province,
                address.postalCode,
              ]
                .map(item => `"${item}"`)
                .join(',');
              return line;
            })
            .join('\n');
        })
        .join('\n');
      const csv = header + lines;
      const blob = new Blob([csv], { type: 'text/csv' });
      downloadBlob(blob, `batches-${new Date().toISOString()}.csv`);
    },
  },
});
</script>
