<template>
  <GoField v-bind="$attrs" v-slot="{ field, id, errors }">
    <textarea
      v-bind="field"
      class="form-element shadow-sm block w-full h-full sm:text-sm rounded-md dark:bg-gray-700"
      :class="elementClasses(errors)"
      :placeholder="placeholder"
      :id="id"
    ></textarea>
    <div
      class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
      v-if="errors.length"
    >
      <IconExclamationCircle class="h-5 w-5 text-red-500" />
    </div>
  </GoField>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import GoField from './GoField.vue';
import IconExclamationCircle from './IconExclamationCircle.vue';

export default defineComponent({
  inheritAttrs: false,
  components: {
    GoField,
    IconExclamationCircle,
  },
  props: {
    placeholder: {
      type: String,
      required: false,
    },
  },
  methods: {
    elementClasses(errors: string[]) {
      if (errors.length) {
        return 'border-2 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 pr-10';
      }
      return 'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 dark:text-gray-100 dark:border-gray-500';
    },
  },
});
</script>
