<template>
  <div class="p-4">
    <div class="text-center py-2">
      <h2 class="text-3xl mb-1">{{ options.title }}</h2>
      <h4 class="text-lg text-gray-600">{{ options.subtitle }}</h4>
    </div>
    <div v-if="htmlText" v-html="htmlText" class="prose" />
    <div v-if="options.youtubeVideo" class="py-8 w-full">
      <youtube-vue3 :videoid="options.youtubeVideo" class="w-full" @ended="videoWatched = true" />
    </div>
    <div v-if="options.typeform" class="py-8 w-full">
      <div v-if="!completed" id="typeform-widget" style="width: 100%; height: 1000px"></div>
      <div v-else class="flex justify-center text-xl">Step completed</div>
    </div>
    <div v-if="!completed && !options.typeform" class="md:p-8 text-center">
      <GoAlert v-if="nextMessage" class="my-3" color="information" :message="nextMessage" />
      <GoButton :disabled="!canSubmit" @click="confirmTraining">Next</GoButton>
    </div>
  </div>
</template>

<script lang="ts">
import { apiService } from '@tyltgo/shared/services';
import { defineComponent } from 'vue';
import { marked } from 'marked';
import startCase from 'lodash/startCase';
import { YoutubeVue3 } from 'youtube-vue3';
import { createWidget } from '@typeform/embed';
import GoButton from './GoButton.vue';
import GoAlert from './GoAlert.vue';
import { showLoadingMessage, stopLoadingMessage } from '../services/ui-message-service';
import { currentUser } from '../services/auth-service';

export default defineComponent({
  emits: ['completed'],
  setup() {
    return {
      currentUser,
      startCase,
    };
  },
  components: {
    GoButton,
    GoAlert,
    YoutubeVue3,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    courier: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      videoWatched: null,
    };
  },
  computed: {
    nextMessage() {
      if (this.options.youtubeVideo) {
        return 'You can proceed after watching the video.';
      }
      return null;
    },
    htmlText() {
      if (!this.options.markdownText) return null;

      return marked(this.options.markdownText);
    },
    completed() {
      return this.courier.training[this.name].completed;
    },
    canSubmit() {
      if (this.options.youtubeVideo) {
        return this.videoWatched;
      }
      return false;
    },
  },
  async mounted() {
    this.setupTraining();
  },
  methods: {
    setupTypeform() {
      this.$nextTick(() => {
        if (!this.options.typeform) return;
        const typeform = document.getElementById('typeform-widget');
        createWidget(this.options.typeform, {
          container: typeform,
          onSubmit: event => {
            const payload = {
              typeform: { formId: this.options.typeform, responseId: event.responseId },
            };
            this.confirmTraining(payload);
          },
          hidden: {
            email: this.currentUser.email,
          },
        });
      });
    },
    setupTraining() {
      this.videoWatched = null;
      this.setupTypeform();
    },
    async confirmTraining(payload = {}) {
      showLoadingMessage('Submitting ...');
      await apiService.couriers.profile.confirmTraining(this.name, payload);
      stopLoadingMessage();
      this.$emit('completed', this.name);
    },
  },
});
</script>

<style src="@typeform/embed/build/css/widget.css"></style>
