<template>
  <component
    :is="as"
    :class="styles"
    :disabled="disabledValue"
    :type="type"
    ref="buttonRef"
    :style="width ? `width: ${width}px` : ''"
  >
    <span v-if="isRunning" class="inline-flex items-center justify-center">
      <IconLoader class="inline-block h-3 w-3 animate-spin text-gray-400" />
    </span>
    <slot v-else></slot>
  </component>
</template>

<script lang="ts">
import { defineComponent, inject, Ref, ref, watch, computed } from 'vue';
import { buttonStyles } from '@tyltgo/shared';

export default defineComponent({
  setup(props) {
    const formIsRunning: Ref<boolean> = inject('formIsRunning', ref(false));

    const width = ref(0);
    const buttonRef = ref();

    const isSubmitType = computed(() => {
      return !props.type || props.type === 'submit';
    });

    const isRunning = computed(() => {
      return isSubmitType.value && formIsRunning?.value;
    });

    watch(isRunning, (newValue, oldValue) => {
      if (!oldValue && newValue) {
        width.value = buttonRef.value.offsetWidth;
      } else {
        width.value = 0;
      }
    });

    const disabledValue = computed(() => {
      return props.disabled || isRunning.value;
    });

    return {
      formIsRunning,
      width,
      disabledValue,
      buttonRef,
      isRunning,
    };
  },
  props: {
    variant: {
      type: String,
      default: 'default',
    },
    color: {
      type: String,
      default: 'primary',
    },
    rounded: {
      type: String,
      required: false,
    },
    padding: {
      type: String,
      default: 'py-2 px-3',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    as: {
      type: String,
      default: 'button',
    },
    type: {
      type: String,
      required: false,
    },
  },
  computed: {
    styles() {
      return buttonStyles(this);
    },
  },
});
</script>
