/* eslint-disable import/prefer-default-export */
import { apiService, authService } from '@tyltgo/shared';
import { Ref, ref } from 'vue';
import { setUser } from '@sentry/vue';

const isProduction = import.meta.env.MODE === 'production';
export const currentUser: Ref<any> = ref({});
export const shopifySession: Ref<{ token?: string; shop?: string; integration?: any }> = ref({});
export const redirectUrl: Ref<{ path: string; query: Record<string, string | string[]> }> = ref(
  null
);

type LoginType = 'merchant' | 'admin' | 'courier';

async function getCurrentUser(type: LoginType): Promise<any> {
  const functions = {
    merchant: apiService.merchants.auth.currentUser,
    admin: apiService.admin.auth.currentUser,
    courier: apiService.couriers.auth.currentUser,
  };
  const user = await functions[type]();
  return user;
}

export async function loadCurrentUser(type: LoginType): Promise<void> {
  const user = await getCurrentUser(type);
  currentUser.value = user || {};
}

export const authTokenKey = (type: LoginType): string => {
  return `authToken-${type}`;
};

export const startupAuth = async (type: LoginType): Promise<void> => {
  const key = authTokenKey(type);
  authService.storeToken = (token: string) => {
    localStorage.setItem(key, token);
  };

  authService.postJwt = async function postJwt() {
    try {
      if (authService.jwt) {
        const user = await getCurrentUser(type);
        currentUser.value = user;
        if (isProduction) {
          setUser({ email: user.email });
        }
        return;
      }
    } catch (e) {
      // do nothing
    }
    currentUser.value = {};
  };

  if (!authService.jwt) {
    const authToken = localStorage.getItem(key);
    await authService.setJwt(authToken);
  }
};
