<template>
  <Field
    :name="name"
    v-slot="{ field, errors }"
    v-bind="$attrs"
    ref="field"
    :validateOnBlur="false"
    :validateOnInput="true"
  >
    <div class="form-field-wrapper" :class="this.class" :style="style">
      <div
        :class="fieldWrapperClass"
        class="flex justify-between items-end"
        v-if="labelLocation === 'before'"
      >
        <GoLabel class="mb-1" :for="generatedId" v-if="showLabel" :label="labelValue" />
        <GoHintText :id="generatedId + '-hint'" :hint="hint" v-if="showHint" class="mb-1" />
      </div>
      <div class="form-element-wrapper relative" :class="elementWrapperClass">
        <slot name="default" :field="field" :errors="errors" :id="generatedId"></slot>
      </div>
      <div class="form-after-wrapper" :class="afterWrapperClass">
        <GoLabel
          :for="generatedId"
          v-if="showLabel && labelLocation === 'after'"
          :label="labelValue"
        >
          <slot v-if="$slots.label" name="label"></slot>
        </GoLabel>
        <GoHelpText v-if="showHelp" :id="generatedId + '-help'" :help="help" />
        <template v-if="showError && labelLocation === 'after'">
          <GoErrorMessage
            v-for="(error, index) in errors"
            :key="index"
            :id="generatedId + 'error-' + index"
            :message="error"
          />
        </template>
      </div>
      <div class="" v-if="showError && errors.length && labelLocation !== 'after'">
        <GoErrorMessage
          v-for="(error, index) in errors"
          :key="index"
          :id="generatedId + 'error-' + index"
          :message="error"
        />
      </div>
    </div>
  </Field>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Field } from 'vee-validate';
import cuid from 'cuid';
import startCase from 'lodash/startCase';
import GoHelpText from './GoHelpText.vue';
import GoHintText from './GoHintText.vue';
import GoLabel from './GoLabel.vue';
import GoErrorMessage from './GoErrorMessage.vue';

export default defineComponent({
  inheritAttrs: false,
  components: {
    Field,
    GoHelpText,
    GoHintText,
    GoLabel,
    GoErrorMessage,
  },
  props: {
    class: {
      type: String,
    },
    style: {
      type: String,
    },
    fieldWrapperClass: {
      type: String,
    },
    elementWrapperClass: {
      type: String,
    },
    afterWrapperClass: {
      type: String,
    },
    name: {
      type: String,
    },
    help: {
      type: String,
    },
    hint: {
      type: [String, Boolean],
    },
    label: {
      type: [String, Boolean],
      default: undefined,
    },
    labelLocation: {
      type: String,
      default: 'before',
    },
    showError: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    showHelp() {
      if (!this.help || this.help === 'false') return false;
      return true;
    },
    showHint() {
      if (!this.hint || this.hint === 'false') return false;
      return true;
    },
    showLabel() {
      if (this.label === false || this.label === 'false') return false;
      return true;
    },
    labelValue(): string | undefined {
      if (this.$slots.label) return null;
      if (!this.label) return startCase(this.name);
      return `${this.label}`;
    },
    generatedId(): string {
      return `${this.name}-${cuid.slug()}`;
    },
  },
  methods: {},
});
</script>
