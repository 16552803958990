<template>
  <div
    class="bg-white dark:bg-gray-800 pb-4 px-4 shadow rounded-sm mb-4 flex border-l-4 flex-col"
    :class="stateBorder"
    :id="waypoint.id"
  >
    <GoModal v-if="showUpdateStatusModal" @close="showUpdateStatusModal = false">
      <div class="p-4">
        <p class="text-lg">Update Status</p>
      </div>

      <div class="p-4">
        <Form class="space-y-4" @submit="updateStatus">
          <div class="pb-4">
            <GoMultiselect
              placeholder="Status"
              :options="statuses"
              :name="`status`"
              label="false"
              v-model="waypointStatus"
            />
          </div>
          <GoHiddenField
            name="time"
            :value="waypoint.statusUpdatedAt"
            v-if="waypoint.statusUpdatedAt"
          />
          <GoHiddenField
            name="image"
            :value="uploadPhotoImage.split('base64,')[1]"
            v-if="uploadPhotoImage"
          />
          <GoTextArea :name="`note`" label="Note" :value="waypoint.courierNote" />
          <GoUploadButton
            variant="link"
            class="text-xs"
            id="upload_photo"
            accept=".pdf,.png,.jpeg,.jpg"
            @filesSelected="uploadFilesSelected"
          >
            Upload Photo
          </GoUploadButton>
          <img class="w-1/2" :src="uploadPhotoImage" v-if="uploadPhotoImage" />
          <div class="flex justify-end">
            <GoButton class="text-xs" variant="outline">Update</GoButton>
          </div>
        </Form>
      </div>
    </GoModal>
    <GoModal v-if="showPickupTimeModel" @close="showPickupTimeModel = false">
      <div class="p-4">
        <p class="text-lg">Change Pickup Time</p>
      </div>
      <div class="p-4">
        <Form
          class="space-y-4"
          @submit="changePickupTime"
          :validation-schema="pickupTimeSchema"
          :initial-values="{ endOfTimeWindow: actualPickupTime }"
        >
          <div class="flex space-x-6 justify-center mb-4 pt-4">
            <div class="text-left space-y-1">
              <GoLabel label="Delivery Day" />
              <div class="pt-2 text-gray-700">{{ waypoint.routeDate }}</div>
            </div>
            <GoTextField label="Pickup Time" type="time" :name="`endOfTimeWindow`" />
            <div class="text-left space-y-1">
              <GoLabel label="Timezone" />
              <div class="pt-2 text-gray-700">{{ timeZoneId }}</div>
            </div>
          </div>
          <div class="flex space-x-6 justify-center mb-4 pt-4">
            <GoCheckbox
              :name="`applyToAllPickups`"
              label="Apply to all pickups"
              :value="true"
              class="col-span-4 sm:col-span-1"
            />
          </div>
          <div class="flex justify-end">
            <GoButton class="text-xs" variant="outline">Save</GoButton>
          </div>
        </Form>
      </div>
      <div v-if="pickupTimeChanges && pickupTimeChanges.length" class="p-4">
        <div class="text-lg">Previous Changes ({{ pickupTimeChanges.length }})</div>
        <GoTable
          class="my-4 overflow-x-auto"
          :tableData="{
            headers: [
              { title: 'From', key: 'oldPickupTime' },
              { title: 'To', key: 'newPickupTime' },
              { title: 'Updated By', key: 'updatedBy' },
            ],
            data: pickupTimeChanges,
          }"
        ></GoTable>
      </div>
    </GoModal>
    <GoModal v-if="showAddressModal" @close="showAddressModal = false">
      <div class="p-4">
        <p class="text-lg">Change Pickup Address</p>
      </div>
      <div class="p-4">
        <Form
          class="space-y-4"
          @submit="changePickupAddress"
          :validation-schema="changeAddressSchema"
          :initial-values="{
            address: {
              address: waypoint.address,
              formattedAddress: waypoint.address,
              lat: waypoint.lat,
              lng: waypoint.lng,
            },
          }"
        >
          <div class="mb-4 pt-4">
            <GoAddressField label="Pickup Address" name="address" />
          </div>
          <div class="flex justify-end">
            <GoButton class="text-xs" variant="outline">Save</GoButton>
          </div>
        </Form>
      </div>
    </GoModal>
    <div class="flex flex-col pt-4">
      <div v-if="removed" class="flex flex-row items-center pb-2 mb-2 border-b-2 border-red-200">
        <GoBadge class="bg-red-200 text-red-700">
          <span>Removed</span>
        </GoBadge>
      </div>
      <div
        class="flex flex-col sm:flex-row items-center pb-2 mb-2 border-b-2 border-red-200"
        v-for="(alert, index) of waypoint.alerts"
        :key="Number(index)"
      >
        <div class="font-bold text-sm mr-4">
          <GoBadge class="bg-red-200 text-red-700">
            <span>
              {{ alert.display }}
            </span>
          </GoBadge>
        </div>
        <div class="text-sm">
          {{ alert.message }}
        </div>
      </div>
    </div>
    <div class="flex flex-col sm:flex-row justify-between">
      <div class="flex flex-col w-full sm:w-3/5 mb-1">
        <div class="uppercase font-header font-bold text-xs text-gray-500 dark:text-gray-300">
          {{ waypoint.associatedBusiness }}
        </div>
        <div class="flex items-center">
          <router-link
            :to="`/admin/routes/${waypoint.routeId}`"
            class="uppercase font-header text-xs text-gray-500 dark:text-gray-300"
          >
            <span>Route {{ waypoint.routeId }}</span>
          </router-link>
          <span
            v-if="route?.routeNumber"
            class="font-header font-bold text-xs text-gray-500 dark:text-gray-300 ml-2"
          >
            #{{ route?.routeNumber }}
          </span>
        </div>
        <div class="flex items-center">
          <router-link
            :to="`/admin/routes/${waypoint.routeId}#${waypoint.id}`"
            class="uppercase font-header text-xs text-gray-500 dark:text-gray-300"
          >
            <span>
              {{ waypoint.type }}
            </span>
            <span v-if="waypoint.type === 'dropoff'">
              <span class="ml-1" v-if="waypointNumber">#{{ waypointNumber }}</span>
            </span>
          </router-link>
          <span class="font-header font-bold text-xs text-gray-500 dark:text-gray-300 ml-2">
            {{ waypoint.id }}
          </span>
        </div>
        <div class="flex flex-col space-y-1">
          <span>
            {{ waypoint.name }}
          </span>
          <div class="flex space-x-2 items-center">
            <span class="text-sm text-gray-500 dark:text-gray-300">
              {{ waypoint.address }}
            </span>
            <a
              :href="`https://www.google.com/maps/search/?api=1&query=${waypoint.address}`"
              target="__blank"
            >
              <IconMapMarker class="flex-none w-3 mt-px text-gray-600 dark:text-gray-400" />
            </a>
            <a
              :href="`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${waypoint.lat},${waypoint.lng}`"
              target="__blank"
            >
              <IconStreetView class="flex-none w-3 mt-px text-gray-600 dark:text-gray-400" />
            </a>
          </div>
          <span class="text-sm text-gray-500 dark:text-gray-300 flex space-x-2">
            <div class="flex space-x-2 items-center" v-if="waypoint.travelDistanceDisplay">
              <IconRoad class="flex-none w-3 mt-px text-gray-600 dark:text-gray-400" />
              <div class="text-sm font-body align-middle">{{ waypoint.travelDistanceDisplay }}</div>
            </div>
            <div class="flex space-x-2 items-center" v-if="waypoint.travelDurationDisplay">
              <IconHourglass class="flex-none w-3 mt-px text-gray-600 dark:text-gray-400" />
              <div class="text-sm font-body align-middle">{{ waypoint.travelDurationDisplay }}</div>
            </div>
          </span>
          <div class="flex space-x-2 text-sm" v-if="waypoint.phoneNumber">
            <span class="text-gray-500 dark:text-gray-300">
              {{ waypoint.phoneNumber }}
            </span>
            <template v-if="!forSearch">
              <GoTooltip text="Text Message Log" class="flex space-x-2">
                <GoButton
                  variant="link"
                  @click="
                    showTextMessageLogModal = true;
                    getTextMessageLogs();
                  "
                >
                  <IconMessage
                    class="w-4 text-sm"
                    :class="{
                      'text-red-500': !!problemNotification,
                    }"
                  />
                </GoButton>
              </GoTooltip>
              <GoTooltip
                v-if="problemPhoneNumber?.phoneNumber"
                :text="problemPhoneNumber.issue"
                class="flex space-x-2"
              >
                <IconPhoneSlash class="w-4 text-sm text-red-500" />
              </GoTooltip>
              <GoTooltip
                v-else-if="!waypoint.phoneNumber.startsWith('(')"
                text="Improper Phone Number"
                class="flex space-x-2"
              >
                <IconPhoneSlash class="w-4 text-sm text-red-500" />
              </GoTooltip>
              <GoModal
                v-if="showTextMessageLogModal"
                @close="showTextMessageLogModal = false"
                width="large"
              >
                <div class="flex text-lg py-2 text-gray-900">
                  Text Message Logs for {{ waypoint.id }} {{ waypoint.name }}
                  {{ waypoint.phoneNumber }}
                </div>
                <GoTable
                  v-if="textMessageLogs"
                  class="my-4 overflow-x-auto"
                  :tableData="{
                    headers: [
                      {
                        title: 'Time',
                        valueFunction: row => readableDateTime(row.notification.createdAt),
                        wrap: false,
                      },
                      {
                        title: 'Type',
                        valueFunction: row => startCase(row.notification.notificationType),
                      },
                      { title: 'Body', valueFunction: row => row.text?.body, pre: true },
                      {
                        title: 'Text Status',
                        valueFunction: row => textStatus(row.text),
                      },
                    ],
                    data: textMessageLogs,
                  }"
                ></GoTable>
                <div v-else class="flex justify-center">
                  <IconLoader class="animate-spin mx-2 h-8 w-8 text-primary-800" />
                </div>
              </GoModal>
            </template>
          </div>
          <div class="flex space-x-2 text-sm" v-if="waypoint.email">
            <span class="text-gray-500 dark:text-gray-300">
              {{ waypoint.email }}
            </span>
            <template v-if="!forSearch">
              <GoTooltip text="Email Log" class="flex space-x-2">
                <GoButton
                  variant="link"
                  @click="
                    showEmailLogModal = true;
                    getEmailLogs();
                  "
                >
                  <IconEnvelope class="w-4 text-sm" />
                </GoButton>
              </GoTooltip>
              <GoTooltip
                v-if="problemEmail?.email"
                :text="problemEmail.issue"
                class="flex space-x-2"
              >
                <IconSquareEnvelope class="w-4 text-sm text-red-500" />
              </GoTooltip>
              <GoModal v-if="showEmailLogModal" @close="showEmailLogModal = false" width="large">
                <div class="flex text-lg py-2 text-gray-900">
                  Email Logs for {{ waypoint.id }} {{ waypoint.name }}
                  {{ waypoint.email }}
                </div>
                <GoTable
                  v-if="emailLogs"
                  class="my-4 overflow-x-auto"
                  :tableData="{
                    headers: [
                      {
                        title: 'Time',
                        valueFunction: row => readableDateTime(row.notification.createdAt),
                        wrap: false,
                      },
                      {
                        title: 'Type',
                        valueFunction: row => startCase(row.notification.notificationType),
                      },
                    ],
                    data: emailLogs,
                  }"
                ></GoTable>
                <div v-else class="flex justify-center">
                  <IconLoader class="animate-spin mx-2 h-8 w-8 text-primary-800" />
                </div>
              </GoModal>
            </template>
          </div>
          <div class="flex space-x-2 items-start" v-if="waypoint.addressLine2">
            <GoTooltip text="Address Line 2" class="flex space-x-2">
              <IconBuilding class="w-4 text-gray-600 dark:text-gray-400" />
              <div class="text-sm font-body align-middle">{{ waypoint.addressLine2 }}</div>
            </GoTooltip>
          </div>
          <div
            class="flex space-x-2 items-start"
            v-if="waypoint.quantity && !waypoint.bagReturnDropoff"
          >
            <GoTooltip text="Quantity" class="flex space-x-2">
              <IconBoxOpen class="w-4 text-gray-600 dark:text-gray-400" />
              <div class="text-sm font-body align-middle">{{ waypoint.quantity }}</div>
            </GoTooltip>
          </div>
          <div class="flex space-x-2 items-start" v-if="waypoint.note">
            <IconClipboard class="flex-none w-3 mt-px text-gray-600 dark:text-gray-400" />
            <div class="text-sm font-body align-middle">{{ waypoint.note }}</div>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-full sm:w-2/5">
        <div class="flex sm:justify-end mb-2 space-x-2 items-center">
          <GoBadge v-if="waypoint.unpicked" class="bg-pink-200 text-pink-700">
            <span>Unpicked</span>
          </GoBadge>
          <GoBadge
            class="border border-gray-200"
            v-if="waypoint.scanned === 'pickup' || waypoint.scanned === 'both'"
          >
            <span class="flex space-x-2 items-center">
              <IconBarcode class="w-3" />
              <span>
                Pickup
                <span v-if="waypoint.quantity !== 1">
                  {{ waypoint.quantityScannedAtPickup }}/{{ waypoint.quantity }}
                </span>
              </span>
            </span>
          </GoBadge>

          <div v-if="waypoint.type === 'dropoff' && unscannableImage">
            <GoButton variant="badge" @click="showUnscannableImageModal = true">
              <span class="flex space-x-2 items-center">
                <IconCamera class="w-3" />
                <span>
                  Pickup
                  <span v-if="waypoint.quantity !== 1">
                    {{ waypoint.quantityUnscannableAtPickup }}/{{ waypoint.quantity }}
                  </span>
                </span>
              </span>
            </GoButton>
            <GoModal v-if="showUnscannableImageModal" @close="showUnscannableImageModal = false">
              <div
                class="flex justify-center py-2"
                v-for="link in unscannableImageLinks"
                :key="link"
              >
                <img :src="link" />
              </div>
            </GoModal>
          </div>

          <GoBadge
            class="border border-gray-200"
            v-if="waypoint.scanned === 'dropoff' || waypoint.scanned === 'both'"
          >
            <span class="flex space-x-2 items-center">
              <IconBarcode class="w-3" />
              <span>
                Dropoff
                <span v-if="waypoint.quantity !== 1">
                  {{ waypoint.scannedAtDropoff.length - waypoint.unscannableAtDropoff.length }}/{{
                    waypoint.quantity
                  }}
                </span>
              </span>
            </span>
          </GoBadge>

          <div v-if="waypoint.type === 'dropoff' && unscannableDropoffImage">
            <GoButton variant="badge" @click="showUnscannableDropoffImageModal = true">
              <span class="flex space-x-2 items-center">
                <IconCamera class="w-3" />
                <span>
                  Dropoff
                  <span v-if="waypoint.quantity !== 1">
                    {{ waypoint.unscannableAtDropoff.length }}/{{ waypoint.quantity }}
                  </span>
                </span>
              </span>
            </GoButton>
            <GoModal
              v-if="showUnscannableDropoffImageModal"
              @close="showUnscannableDropoffImageModal = false"
            >
              <div
                class="flex justify-center py-2"
                v-for="link in unscannableDropoffImageLinks"
                :key="link"
              >
                <img :src="link" />
              </div>
            </GoModal>
          </div>

          <div v-if="verifyStreetImage">
            <GoButton variant="badge" @click="showVerifyStreetImageModal = true">
              <span class="flex space-x-2 items-center">
                <IconCamera class="w-3" />
                <span>Street</span>
              </span>
            </GoButton>
            <GoModal v-if="showVerifyStreetImageModal" @close="showVerifyStreetImageModal = false">
              <div class="flex justify-center">
                <img :src="verifyStreetImage" />
              </div>
            </GoModal>
          </div>

          <div v-if="verifyUnitImage">
            <GoButton variant="badge" @click="showVerifyUnitImageModal = true">
              <span class="flex space-x-2 items-center">
                <IconCamera class="w-3" />
                <span>Unit</span>
              </span>
            </GoButton>
            <GoModal v-if="showVerifyUnitImageModal" @close="showVerifyUnitImageModal = false">
              <div class="flex justify-center">
                <img :src="verifyUnitImage" />
              </div>
            </GoModal>
          </div>

          <GoBadge
            class="border border-red-500 text-red-500"
            v-if="waypoint.outsidePriceList && waypoint.type === 'dropoff'"
          >
            <span class="flex space-x-2 items-center">
              <IconOutsideServiceArea class="w-3" />
              <span>O.S.A.</span>
            </span>
          </GoBadge>

          <GoButton v-if="image" variant="badge" @click="showImageModal = true">
            <span class="flex space-x-2 items-center">
              <IconFileImage class="w-3" />
              <span>Photo</span>
            </span>
          </GoButton>
          <GoModal v-if="showImageModal" @close="showImageModal = false">
            <div class="flex justify-center">
              <img :src="image" />
            </div>
          </GoModal>
          <GoBadge v-if="waypoint.relativePriorityTime" class="bg-yellow-200 text-yellow-700">
            <span>Priority {{ waypoint.relativePriorityTime }}</span>
          </GoBadge>
          <GoTooltip
            v-if="waypoint.noCharge"
            :text="`Merchant will not be charged for this dropoff`"
          >
            <GoBadge v-if="waypoint.noCharge" class="border border-gray-400">
              <span>Not Charged</span>
            </GoBadge>
          </GoTooltip>
          <GoBadge :class="stateStyle">
            <span>{{ waypointDisplayState(waypoint) }}</span>
          </GoBadge>
          <GoDropdown v-if="showActions">
            <template v-slot:button>
              <IconEllipsis class="flex-none w-3 text-gray-600 dark:text-gray-300" />
            </template>
            <template v-slot:items>
              <GoDropdownItem v-if="isPickupType" @click="showPickupTimeModel = true">
                Change Pickup Time
              </GoDropdownItem>
              <GoDropdownItem v-if="isPickupType" @click="showAddressModal = true">
                Change Pickup Address
              </GoDropdownItem>
              <GoDropdownItem v-if="isDevelopment" @click="reset()">Reset</GoDropdownItem>
              <GoDropdownItem v-if="!route?.assignedDriver && isPickupType" @click="resetPickup()">
                Reset Pickup
              </GoDropdownItem>
              <GoDropdownItem
                v-if="waypoint.type === 'dropoff'"
                @click="showUpdateStatusModal = true"
              >
                Update Status
              </GoDropdownItem>
              <GoDropdownItem
                v-if="trackingPageParams && waypoint.type === 'dropoff'"
                :to="trackingPageParams"
                target="_blank"
                as="router-link"
              >
                Tracking Link
              </GoDropdownItem>
              <GoDropdownItem @click="downloadLabels('zpl')">ZPL Labels</GoDropdownItem>
              <GoDropdownItem @click="downloadLabels('pdf')">PDF Labels</GoDropdownItem>
            </template>
          </GoDropdown>
        </div>
        <div class="flex space-x-4 sm:justify-end">
          <DataBit
            title="Pickup Time"
            :value="time(waypoint.cutoffTime, timeZoneId)"
            v-if="waypoint.cutoffTime && isPickupType"
          />
          <DataBit
            title="Est. Arrival"
            :value="time(waypoint.estimatedArrivalTime, timeZoneId)"
            v-if="waypoint.estimatedArrivalTime"
          />
          <GoTooltip :text="`${arrivalDistance?.toFixed(2)}m`" v-if="waypoint.arrivedAt">
            <DataBit title="Arrived At">
              <span>
                {{ time(waypoint.arrivedAt, timeZoneId) }}
              </span>
              <span v-if="arrivalDistance > 150" class="ml-1 text-xs text-red-600">
                {{ arrivalDistance.toFixed(2) }}m
              </span>
            </DataBit>
          </GoTooltip>
          <GoTooltip :text="`${statusUpdateDistance?.toFixed(2)}m`" v-if="waypoint.statusUpdatedAt">
            <DataBit title="Updated At">
              <span>
                {{ time(waypoint.statusUpdatedAt, timeZoneId) }}
              </span>
              <span v-if="statusUpdateDistance > 150" class="ml-1 text-xs text-red-600">
                {{ statusUpdateDistance.toFixed(2) }}m
              </span>
            </DataBit>
          </GoTooltip>
        </div>
        <div>
          <div class="flex space-x-2 items-start" v-if="waypoint.courierNote">
            <IconCar class="flex-none w-3 mt-1 text-gray-600 dark:text-gray-400" />
            <div class="text-sm font-body align-middle">{{ waypoint.courierNote }}</div>
          </div>
          <div
            class="flex space-x-2 items-start"
            v-if="
              waypoint.type === 'dropoff' &&
              waypoint.state === 'completed' &&
              merchant?.settings?.reverseLogisticsEnabled
            "
          >
            <DataBit
              :title="getReverseLogisticsItemsTitle"
              :value="getReverseLogisticsItemsCollected"
            />
          </div>
          <div
            class="flex space-x-2 items-start border-2 bg-pink-100 border-pink-400 text-pink-700 px-2 py-1 rounded-md"
            v-if="waypoint.unpicked"
          >
            <IconCar class="flex-none w-3 mt-1 text-gray-600 dark:text-gray-400" />
            <div class="text-sm font-body align-middle">{{ waypoint.unpickedReason }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2 pt-2 border-t-2" v-if="forSearch">
      <div class="flex justify-between items-center">
        <div class="text-lg flex items-center space-x-2">
          <router-link :to="`/admin/routes/${waypoint.routeId}#${waypoint.id}`">
            Route {{ waypoint.routeId }}
          </router-link>
          <GoBadge
            class="bg-indigo-200 text-indigo-600"
            v-if="waypoint.associatedOrder !== waypoint.routeId"
          >
            merged
          </GoBadge>
        </div>
        <div class="flex space-x-4">
          <DataBit title="Date" :value="waypoint.routeDate" />
        </div>
      </div>
    </div>
    <div
      class="mt-2 pt-2 border-t-2"
      v-if="(waypoint.dropoffMessage || waypoint.pickupMessage) && !waypoint.bagReturnDropoff"
    >
      <div class="uppercase font-header font-bold text-xs text-gray-500 dark:text-gray-300">
        In-App Guided Message
      </div>
      <div class="text-xs" v-if="waypoint.dropoffMessage">
        {{ waypoint.dropoffMessage }}
      </div>
      <div class="text-xs" v-if="waypoint.pickupMessage">
        {{ waypoint.pickupMessage }}
      </div>
    </div>
    <div class="mt-2 pt-2 border-t-2" v-if="waypoint.integration">
      <div class="uppercase font-header font-bold text-xs text-gray-500 dark:text-gray-300">
        {{ startCase(waypoint.integration.name) }} Integration
      </div>
      <div class="flex space-x-2">
        <template v-if="waypoint.integration[waypoint.integration.name]">
          <div
            v-for="[key, value] of Object.entries(
              waypoint.integration[waypoint.integration.name]
            ).sort()"
            :key="key"
          >
            <DataBit v-if="value" :title="startCase(key)" :value="value" />
          </div>
        </template>
        <template v-if="waypoint.integration['default']">
          <div
            v-for="[key, value] of Object.entries(waypoint.integration['default']).sort()"
            :key="key"
          >
            <DataBit v-if="value" :title="startCase(key)" :value="value" />
          </div>
        </template>
        <template v-if="waypoint.integration.address">
          <div
            v-for="[key, value] of Object.entries(waypoint.integration.address).sort()"
            :key="key"
          >
            <DataBit v-if="value" :title="startCase(key)" :value="value" />
          </div>
        </template>
      </div>
    </div>
    <div class="mt-2 pt-2 border-t-2" v-if="waypoint.tags?.length">
      <div class="uppercase font-header font-bold text-xs text-gray-500 dark:text-gray-300">
        Tags
      </div>
      <div class="flex space-x-2">
        <span class="text-sm">
          {{ waypoint.tags.join(', ') }}
        </span>
      </div>
    </div>
    <div class="mt-2 pt-2 border-t-2" v-if="edit && editable">
      <div class="flex justify-between items-center w-full">
        <div class="grid grid-cols-4 gap-y-1 gap-x-4 w-full">
          <GoTextField
            class="col-span-4 sm:col-span-1"
            label="Name"
            type="text"
            :name="`waypoints.${waypoint.id}.customerName`"
            :value="waypoint.name"
          />
          <GoTextField
            class="col-span-4 sm:col-span-2"
            label="Address"
            type="search"
            :name="`waypoints.${waypoint.id}.customerAddress`"
            v-model="customerAddress"
            @place-selected="placeSelected"
            google-places
            placeholder="Search for address"
          />
          <GoHiddenField :name="`waypoints.${waypoint.id}.customerLat`" v-model="customerLat" />
          <GoHiddenField :name="`waypoints.${waypoint.id}.customerLong`" v-model="customerLong" />

          <GoTextField
            class="col-span-4 sm:col-span-1"
            label="Address Line 2"
            type="text"
            :name="`waypoints.${waypoint.id}.customerAddressLine2`"
            :value="waypoint.addressLine2"
          />
          <GoTextField
            class="col-span-4 sm:col-span-1"
            label="Phone Number"
            type="text"
            :name="`waypoints.${waypoint.id}.customerPhoneNumber`"
            :value="waypoint.phoneNumber"
          />
          <GoTextField
            class="col-span-4 sm:col-span-1"
            label="Priority (hours)"
            type="number"
            :name="`waypoints.${waypoint.id}.relativePriorityTime`"
            :value="waypoint.relativePriorityTime"
            v-model="newPriority"
            :help="priorityHelpMessage"
          />
          <GoTextField
            class="col-span-4 sm:col-span-2"
            label="Note"
            type="text"
            :name="`waypoints.${waypoint.id}.customerOrderNote`"
            :value="waypoint.note"
          />
          <GoTextField
            class="col-span-4 sm:col-span-1"
            label="Quantity"
            type="number"
            :name="`waypoints.${waypoint.id}.orderQuantity`"
            :value="waypoint.quantity"
          />
          <GoCheckbox
            :name="`waypoints.${waypoint.id}.remove`"
            label="Remove"
            :value="true"
            class="col-span-4 sm:col-span-1"
          />
          <GoCheckbox
            :name="`waypoints.${waypoint.id}.bagReturnDropoff`"
            v-model="bagReturnDropoff"
            label="Bag Return"
            :value="true"
            :uncheckedValue="false"
            class="col-span-4 sm:col-span-1"
          />
        </div>
      </div>
    </div>
    <div class="mt-2 pt-2 border-t-2" v-if="edit && removable">
      <div class="flex justify-between items-center w-full">
        <div class="grid grid-cols-4 gap-y-1 gap-x-4 w-full">
          <GoCheckbox
            :name="`waypoints.${waypoint.id}.remove`"
            label="Remove"
            :value="true"
            class="col-span-4 sm:col-span-1"
          />
        </div>
      </div>
    </div>
    <div v-if="$route.name === 'admin.scheduling'" class="mt-2 pt-2 border-t-2">
      <GoButton
        v-if="schedulingRouteId === route.id"
        class="text-xs"
        @click="schedulingRouteId = ''"
      >
        Unselect
      </GoButton>
      <GoButton
        v-else-if="!schedulingCourierId"
        variant="link"
        class="text-xs"
        @click="schedulingRouteId = waypoint.routeId"
      >
        Find Couriers
      </GoButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {
  apiService,
  waypointBorder,
  waypointState,
  waypointStyle,
  waypointDisplayState,
  convertZplToPdf,
  waypointZpl,
  calculateDistance,
  readableDateTime,
  Merchant,
} from '@tyltgo/shared';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import dayjs from 'dayjs';
import startCase from 'lodash/startCase';
import GoMultiselect from './GoMultiselect.vue';
import GoHiddenField from './GoHiddenField.vue';
import IconCar from './IconCar.vue';
import IconEllipsis from './IconEllipsis.vue';
import GoBadge from './GoBadge.vue';
import GoModal from './GoModal.vue';
import GoTextField from './GoTextField.vue';
import GoAddressField from './GoAddressField.vue';
import GoTooltip from './GoTooltip.vue';
import GoDropdown from './GoDropdown.vue';
import GoDropdownItem from './GoDropdownItem.vue';
import GoCheckbox from './GoCheckbox.vue';
import DataBit from './DataBit.vue';
import { time, timezoneConversionRouteInput } from '../services/date-service';
import IconClipboard from './IconClipboard.vue';
import IconBoxOpen from './IconBoxOpen.vue';
import IconBuilding from './IconBuilding.vue';
import IconHourglass from './IconHourglass.vue';
import IconRoad from './IconRoad.vue';
import IconMapMarker from './IconMapMarker.vue';
import IconStreetView from './IconStreetView.vue';
import IconFileImage from './IconFileImage.vue';
import IconCamera from './IconCamera.vue';
import IconBarcode from './IconBarcode.vue';
import IconOutsideServiceArea from './IconOutsideServiceArea.vue';
import GoButton from './GoButton.vue';
import GoUploadButton from './GoUploadButton.vue';
import GoTextArea from './GoTextArea.vue';
import { downloadBlob } from '../services/browser-service';
import GoLabel from './GoLabel.vue';
import GoTable from './GoTable.vue';
import { schedulingCourierId, schedulingRouteId } from '../store/scheduling-store';
import { aroundLoadingMessage, showConfirmationModal } from '../services/ui-message-service';

const pickupTimeSchema = yup.object().shape({
  endOfTimeWindow: yup.string().required().label('Pickup Time'),
});

const changeAddressSchema = yup.object().shape({
  address: yup.object().shape({
    address: yup.string().required().label('Pickup Address'),
    formattedAddress: yup.string().required().label('Pickup Address'),
  }),
});

export default defineComponent({
  emits: ['reloadRoute'],
  setup() {
    return {
      readableDateTime,
      pickupTimeSchema,
      changeAddressSchema,
      time,
      waypointDisplayState,
      timezoneConversionRouteInput,
      startCase,
      schedulingRouteId,
      schedulingCourierId,
    };
  },
  components: {
    Form,
    IconCar,
    IconCamera,
    IconEllipsis,
    IconMapMarker,
    IconStreetView,
    GoBadge,
    GoModal,
    GoHiddenField,
    GoTooltip,
    GoDropdown,
    GoDropdownItem,
    GoButton,
    GoUploadButton,
    GoTextField,
    GoAddressField,
    GoTextArea,
    GoMultiselect,
    GoCheckbox,
    IconClipboard,
    IconBoxOpen,
    IconBuilding,
    IconHourglass,
    IconRoad,
    IconFileImage,
    IconBarcode,
    DataBit,
    IconOutsideServiceArea,
    GoLabel,
    GoTable,
  },
  props: {
    waypoint: {
      type: Object,
      required: true,
    },
    routeId: {
      type: String,
      required: true,
    },
    waypointNumber: {
      type: Number,
      required: false,
    },
    forSearch: {
      type: Boolean,
      required: false,
    },
    edit: {
      type: Boolean,
      required: false,
    },
    removed: {
      type: Boolean,
      required: false,
    },
    route: {
      type: Object,
      required: false,
    },
    merchant: {
      type: Object,
      required: false,
    },
    showActions: {
      type: Boolean,
      default: true,
    },
    problemPhoneNumber: {
      type: Object,
      required: false,
    },
    problemEmail: {
      type: Object,
      required: false,
    },
    problemNotification: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      uploadPhotoImage: '',
      waypointStatus: '',
      bagReturnDropoff: null,
      customerLat: null,
      customerLong: null,
      customerAddress: null,
      showImageModal: false,
      showUnscannableImageModal: false,
      showUnscannableDropoffImageModal: false,
      showTextMessageLogModal: false,
      showEmailLogModal: false,
      showVerifyStreetImageModal: false,
      showVerifyUnitImageModal: false,
      showAlertsModal: false,
      showUpdateStatusModal: false,
      textMessageLogs: null,
      emailLogs: null,
      image: null,
      unscannableImage: null,
      unscannableImageLinks: [],
      unscannableDropoffImage: null,
      verifyStreetImage: null,
      verifyStreetImageLinks: [],
      verifyUnitImage: null,
      verifyUnitImageLinks: [],
      unscannableDropoffImageLinks: [],
      newPriority: '',
      timeZoneId: this.route?.timeZoneId || this.waypoint?.timeZoneId,
      showPickupTimeModel: false,
      showAddressModal: false,
    };
  },
  async created() {
    this.bagReturnDropoff = this.waypoint.bagReturnDropoff;
    this.customerLat = this.waypoint.lat;
    this.customerLong = this.waypoint.lng;
    this.customerAddress = this.waypoint.address;
    this.newPriority = this.waypoint.relativePriorityTime;
    this.waypointStatus = this.waypoint.status;
    if (this.route && !this.route.timeZoneId) {
      const zone = await timezoneConversionRouteInput(this.route, 'admin');
      this.timeZoneId = zone.timeZoneId;
    }
    if (this.route && !this.removed) {
      if (this.hasUnscannablePickupPhoto) {
        this.unscannableImage = await this.getUnscannableImage();
      }
      if (this.waypoint.state === 'completed') {
        this.image = await this.getImage();
        if (this.hasUnscannableDropoffPhoto) {
          this.unscannableDropoffImage = await this.getUnscannableDropoffImage();
        }
        this.verifyStreetImage = await this.getVerifyStreetImage();
        this.verifyUnitImage = await this.getVerifyUnitImage();
      }
    }
  },
  computed: {
    hasUnscannablePickupPhoto() {
      return !!this.route.waypoints?.find(
        x => x.unscannable === 'pickup' || x.unscannable === 'both'
      );
    },
    hasUnscannableDropoffPhoto() {
      return !!this.route.waypoints?.find(
        x => x.unscannable === 'dropoff' || x.unscannable === 'both'
      );
    },
    trackingPageParams() {
      if (!this.merchant) return undefined;
      return {
        name: this.merchant.trackingSlug ? 'track.id.trackingsecret' : 'tracking.id.trackingsecret',
        params: {
          trackingSlug: this.merchant.trackingSlug,
          id: this.waypoint.id,
          trackingSecret: this.waypoint.trackingSecret || 't',
        },
      };
    },
    actualPickupTime() {
      return dayjs(this.waypoint.cutoffTime)
        .tz(this.timeZoneId || 'America/Toronto')
        .format('HH:mm');
    },
    isPickupType() {
      return this.waypoint?.type === 'pickup';
    },
    pickupTimeChanges() {
      if (!this.route.pickupTimeChanges || !this.route.pickupTimeChanges.length) return [];

      return this.route.pickupTimeChanges
        .filter(pt => pt.waypointId === this.waypoint.id)
        .map(pt => ({
          ...pt,
          newPickupTime: dayjs(pt.newPickupTime).format('hh:mm A'),
          oldPickupTime: dayjs(pt.oldPickupTime).format('hh:mm A'),
        }));
    },
    priorityHelpMessage() {
      if (!this.newPriority) return '';
      const priority = Number(this.newPriority);
      const message: string[] = [];
      const hours = Math.floor(priority);
      const minutes = Math.floor((priority - Math.floor(priority)) * 60);
      if (hours) message.push(`${hours} hour${hours === 1 ? '' : 's'}`);
      if (minutes) message.push(`${minutes} minute${minutes === 1 ? '' : 's'}`);
      message.push('from start of route.');

      const startTime = this.route.waypoints[0].statusUpdatedAt;
      if (startTime) {
        message.push('Before');
        const fromStart = dayjs(startTime).add(hours, 'hours').add(minutes, 'minutes');
        message.push(fromStart.format('h:mm A'));
      }
      return `Within ${message.join(' ')}`;
    },
    arrivalDistance() {
      return this.calculateDistance(this.waypoint.arrivedLocation);
    },
    statusUpdateDistance() {
      return this.calculateDistance(this.waypoint.statusUpdatedLocation);
    },
    editable() {
      return this.waypoint.type === 'dropoff' && this.waypoint.state !== 'completed';
    },
    removable() {
      return this.waypoint.type === 'dropoff' && !this.editable && !this.waypoint.delivered;
    },
    state() {
      return waypointState(this.waypoint);
    },
    stateStyle() {
      return waypointStyle(this.waypoint);
    },
    stateBorder() {
      return waypointBorder(this.waypoint);
    },
    isDevelopment() {
      return import.meta.env.MODE === 'development';
    },
    statuses() {
      const statuses: string[] = this.waypoint.deliveryOptions.map(x => x.name);
      statuses.unshift('Missing');
      statuses.unshift('Cancelled');
      statuses.push('Bring Back');
      return statuses.map(x => ({ label: x, value: x }));
    },
    getReverseLogisticsItemsTitle() {
      if (!this.route) {
        return '';
      }
      if (this.waypoint.bagReturnDropoff) {
        return `${startCase(this.merchant.settings.reverseLogisticsItem)} returned`;
      }
      return `${startCase(this.merchant.settings.reverseLogisticsItem)} collected`;
    },
    getReverseLogisticsItemsCollected() {
      if (!this.route) {
        return '';
      }
      if (this.waypoint.bagReturnDropoff) {
        return this.route.waypoints
          .filter(waypoint => !waypoint.bagReturnDropoff && waypoint.type === 'dropoff')
          .map(waypoint => waypoint.reverseLogisticsItemsCollected)
          .reduce((acc, itemCount) => acc + itemCount);
      }
      return this.waypoint.reverseLogisticsItemsCollected || '0';
    },
  },
  methods: {
    textStatus(text) {
      if (!text) return '';
      const s = startCase(text.status || 'Unknown');
      const statuses = [s];
      if (s === 'Undelivered') {
        statuses.push(text.errorMessage);
        statuses.push(`[${text.errorCode}]`);
      }
      return statuses.join(' - ');
    },
    async uploadFilesSelected(files, name) {
      const file = files[0];
      const reader = new FileReader();

      reader.addEventListener(
        'load',
        () => {
          this.uploadPhotoImage = reader.result.toString();
        },
        false
      );

      if (file) {
        reader.readAsDataURL(file);
      }
    },
    placeSelected(place, _name) {
      this.customerAddress = place.formatted_address;
      this.customerLat = place.geometry.location.lat();
      this.customerLong = place.geometry.location.lng();
    },
    calculateDistance(location: any) {
      if (!location) return null;
      return calculateDistance(this.waypoint, location);
    },
    async getImage() {
      if (this.waypoint.state !== 'completed') {
        return null;
      }
      try {
        return await apiService.admin.routes.waypointImage(this.routeId, this.waypoint.id);
      } catch (e) {
        return null;
      }
    },
    async getTextMessageLogs() {
      try {
        const { textMessageLogs } = await apiService.admin.routes.waypointTextMessageLogs(
          this.routeId,
          this.waypoint.id
        );
        this.textMessageLogs = textMessageLogs;
      } catch (e) {
        // do nothing
      }
    },
    async getEmailLogs() {
      try {
        const { emailLogs } = await apiService.admin.routes.waypointEmailLogs(
          this.routeId,
          this.waypoint.id
        );
        this.emailLogs = emailLogs;
      } catch (e) {
        // do nothing
      }
    },
    async getUnscannableImage() {
      try {
        const numberofImages = this.waypoint.quantity;
        // eslint-disable-next-line no-plusplus
        for (let step = 1; step <= numberofImages; step++) {
          this.unscannableImageLinks.push(
            // eslint-disable-next-line no-await-in-loop
            await apiService.admin.routes.waypointImage(
              this.routeId,
              this.waypoint.id,
              'unscannable',
              step
            )
          );
        }
        return !!(
          this.waypoint.quantityUnscannableAtPickup &&
          this.waypoint.quantityUnscannableAtPickup !== 0
        );
      } catch (e) {
        return null;
      }
    },
    async getUnscannableDropoffImage() {
      try {
        const numberofImages = this.waypoint.quantity;
        // eslint-disable-next-line no-plusplus
        for (let step = 1; step <= numberofImages; step++) {
          this.unscannableDropoffImageLinks.push(
            // eslint-disable-next-line no-await-in-loop
            await apiService.admin.routes.waypointImage(
              this.routeId,
              this.waypoint.id,
              'unscannableDropoff',
              step
            )
          );
        }
        return this.waypoint.unscannableAtDropoff.length;
      } catch (e) {
        return null;
      }
    },
    async getVerifyStreetImage() {
      try {
        return await apiService.admin.routes.waypointImage(
          this.routeId,
          this.waypoint.id,
          'streetVerification'
        );
      } catch (e) {
        return null;
      }
    },
    async getVerifyUnitImage() {
      try {
        return await apiService.admin.routes.waypointImage(
          this.routeId,
          this.waypoint.id,
          'unitVerification'
        );
      } catch (e) {
        return null;
      }
    },
    async changePickupTime(values) {
      const estTime = dayjs
        .tz(
          `${this.waypoint.routeDate} ${values.endOfTimeWindow}`,
          this.timeZoneId || 'America/Toronto'
        )
        .tz('America/Toronto')
        .format('HH:mm');

      console.log(values);
      await aroundLoadingMessage('Saving...', async () => {
        await apiService.admin.routes.changePickupTime(
          this.waypoint.routeId,
          this.waypoint.id,
          estTime,
          values.applyToAllPickups
        );
        this.showPickupTimeModel = false;
        this.$emit('reloadRoute');
      });
    },
    async changePickupAddress({ address }, { setFieldError }) {
      if (!address.address || address.address !== address.formattedAddress) {
        setFieldError(
          `address.address`,
          'Please select the address from the autocomplete dropdown.'
        );
        return;
      }
      await aroundLoadingMessage('Saving...', async () => {
        await apiService.admin.routes.changePickupAddress(
          this.waypoint.routeId,
          this.waypoint.id,
          address
        );
        this.showAddressModal = false;
        this.$emit('reloadRoute');
      });
    },
    async reset() {
      await apiService.admin.routes.resetWaypoint(this.routeId, this.waypoint.id);
      this.$emit('reloadRoute');
    },
    async resetPickup() {
      const confirmed = await showConfirmationModal({
        title: 'Confirm Pickup Reset',
        message: 'Are you sure you want to reset this pickup, this action cannot be undone?',
        confirmButtonText: 'Confirm',
        color: 'danger',
      });

      if (!confirmed) return;
      await apiService.admin.routes.resetWaypoint(this.routeId, this.waypoint.id);
      this.$emit('reloadRoute');
    },
    async updateStatus(values) {
      await apiService.admin.routes.updateWaypointStatus(this.routeId, this.waypoint.id, values);
      this.showUpdateStatusModal = false;
      if (values.image) {
        // eslint-disable-next-line prefer-destructuring
        this.image = this.uploadPhotoImage;
      }
      this.$emit('reloadRoute');
    },
    async downloadLabels(fileType: 'pdf' | 'zpl') {
      const { merchant } = await apiService.admin.merchants.get(this.waypoint.associatedEmail);
      let blob = new Blob([this.createZplLabel(merchant)], { type: 'x-application/zpl' });
      if (fileType === 'pdf') {
        const pdfData = await convertZplToPdf(blob);
        blob = new Blob([pdfData], { type: 'application/pdf' });
      }
      downloadBlob(
        blob,
        `${this.route.routeDate}-${this.route.id}-${this.waypoint.id}.${fileType}`
      );
    },
    createZplLabel(merchant: Merchant) {
      return waypointZpl({
        route: this.route,
        waypoint: this.waypoint,
        merchant,
        toChangeTimezone: this.timeZoneId,
      });
    },
  },
});
</script>
