<template>
  <main class="flex-1 overflow-y-auto focus:outline-none" tabindex="0">
    <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
      <div class="pt-10 pb-8">
        <div class="px-4 sm:px-6 md:px-0">
          <GoHeader :level="1">
            <span v-if="!currentReport">Reports</span>
            <span v-else>{{ currentReport.name }} Report</span>
          </GoHeader>
        </div>
        <div class="px-4 sm:px-6 md:px-0 pt-4 mt-8">
          <div class="mb-4" v-if="!currentReport">
            <div class="bg-white dark:bg-gray-800 shadow overflow-hidden sm:rounded-md">
              <ul class="divide-y divide-gray-200">
                <li v-for="(report, key) in reports" :key="key">
                  <router-link
                    :to="{
                      name: 'merchant.report',
                      params: { report: key },
                    }"
                    class="whitespace-nowrap block hover:bg-gray-50"
                    aria-current="page"
                  >
                    <div class="px-4 py-4 flex items-center sm:px-6">
                      <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                        <div class="truncate">
                          <div class="flex text-lg">
                            <p class="font-medium text-indigo-600 truncate">{{ report.name }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="ml-5 flex-shrink-0">
                        <!-- Heroicon name: solid/chevron-right -->
                        <svg
                          class="h-5 w-5 text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="pt-10">
            <component v-if="currentReport" :is="currentReport.component" />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent, markRaw } from 'vue';
import MerchantReportPastCutoff from '../../components/MerchantReportPastCutoff.vue';

const reports = {
  pastExpectedCutoff: {
    name: 'Estimated Past Cutoff Report',
    component: markRaw(MerchantReportPastCutoff),
  },
};

export default defineComponent({
  data() {
    return {
      reports,
      currentReport: null,
    };
  },
  mounted() {
    const { report } = this.$route.params;
    if (report) {
      this.currentReport = reports[report];
    }
  },
  watch: {
    $route(to, _from) {
      if (to.name === 'merchant.report') {
        const { report } = this.$route.params;
        this.currentReport = reports[report];
      }
      if (to.name === 'merchant.reports') {
        this.currentReport = null;
      }
    },
  },
});
</script>
