import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc'; // dependent on utc plugin
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

export function findDeliveryDate(deliveryDay: string, today?: dayjs.Dayjs) {
  if (!today) {
    // eslint-disable-next-line no-param-reassign
    today = dayjs();
  }

  let count = 0;
  let date = '';

  while (!date) {
    const loopDate = today.add(count, 'day');
    if (loopDate.format('dddd').toLowerCase() === deliveryDay.toLowerCase()) {
      date = loopDate.format('YYYY-MM-DD');
    }
    count += 1;
  }
  return date;
}

export const readableDuration = (duration: number) => {
  const hours = Math.floor(duration / 60);
  const minutes = Math.floor(duration % 60);
  const results = [];

  if (hours) {
    results.push(`${hours}h`);
  }

  if (minutes) {
    results.push(`${minutes}m`);
  }

  return results.join(' ');
};

export const time = (date: string, timezoneID = 'America/Toronto'): string => {
  const timeZonetime = dayjs(date)
    .tz(timezoneID || 'America/Toronto')
    .format('h:mm A z');

  if (timeZonetime === 'Invalid Date') return '';

  return timeZonetime;
};

type Measure = 'minutes' | 'hours';

export const diff = (startTime: string, endTime: string, measure: Measure = 'minutes'): number => {
  return dayjs(endTime).diff(startTime, measure);
};

export const fromNow = (timeString: string): string => {
  return dayjs(timeString).fromNow();
};

export const readableDateTime = (timeString: string): string => {
  return dayjs(timeString).format('YYYY-MM-DD h:mm A z');
};
