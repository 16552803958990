<template>
  <main class="flex-1 overflow-y-auto focus:outline-none" tabindex="0">
    <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
      <!-- Delivery Service Disruption Alert Banner -->
      <!-- <div
        class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 mt-4 rounded relative"
        role="alert"
      >
        <strong class="font-bold text-xl">Delivery Service Disruption Alert</strong>
        <div class="pt-4">
          Due to Environment Canada upgrading the status to a "Warning", we have suspended delivery
          operations in BC and ON starting December 22nd evening. Service will resume on:
        </div>
        <div class="pt-4">Same-Day Service (Ontario): December 25th</div>
        <div>Same-Day Service (British Columbia): December 25th</div>
        <div>Next-Day Service (Ontario): December 27th</div>
        <div>Next-Day Service (British Columbia): December 27th</div>
      </div> -->

      <div class="pt-10 pb-16">
        <div
          class="px-4 sm:px-6 md:px-0 flex flex-col lg:flex-row lg:justify-between lg:items-center"
        >
          <GoHeader :level="1" class="mb-4 lg:mb-0">Batches</GoHeader>
          <div id="zendeskChatBox"></div>
          <div
            class="flex flex-row-reverse lg:flex-row justify-end items-center text-xs lg:space-x-2"
          >
            <div class="space-x-4 flex items-center">
              <IconLoader class="animate-spin mx-2 h-5 w-5 text-primary-800" v-show="loading" />
              <GoButton
                as="router-link"
                variant="link"
                :to="{ name: 'merchant.batches', query: { ...$route.query, day: changeDay(-1) } }"
                class="text-blue-800"
              >
                Previous Day
              </GoButton>
              <div class="text-xl">{{ currentDayOfWeek }} {{ currentDay }}</div>
              <GoButton
                variant="link"
                as="router-link"
                :to="{ name: 'merchant.batches', query: { ...$route.query, day: changeDay(1) } }"
                class="text-blue-800"
              >
                Next Day
              </GoButton>
            </div>
          </div>
        </div>
        <div class="px-4 sm:px-6 md:px-0">
          <div class="py-6" v-if="routes">
            <div class="flex justify-between items-center">
              <nav class="flex space-x-4 mb-4 items-center" aria-label="Tabs">
                <router-link
                  :to="{
                    path: `/merchant/batches`,
                    query: { ...$route.query, state: null },
                  }"
                  class="text-gray-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md"
                  :class="!$route.query.state && 'bg-gray-200 dark:text-gray-700'"
                >
                  All ({{ routes?.length }})
                </router-link>
                <router-link
                  v-for="state in routeStates"
                  :key="state"
                  :to="{
                    path: `/merchant/batches`,
                    query: { ...$route.query, state },
                  }"
                  class="text-gray-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md"
                  :class="$route.query.state === state && 'bg-gray-200 dark:text-gray-700'"
                >
                  {{ startCase(state) }} ({{ groupedRoutes[state]?.length || 0 }})
                </router-link>
              </nav>
              <div class="flex space-x-2 items-center mb-4" v-if="sortedRoutes.length">
                <GoCheckbox name="showTable" label="Show Table" v-model="showTable" value="true" />
                <GoButton
                  variant="outline"
                  class="text-xs py-1 flex flex-row justify-center items-center space-x-2"
                  @click="downloadLabels('zpl')"
                >
                  <IconDownload class="w-3" />
                  <span>ZPL</span>
                </GoButton>
                <GoButton
                  variant="outline"
                  class="text-xs py-1 flex flex-row justify-center items-center space-x-2"
                  @click="downloadLabels('pdf')"
                >
                  <IconDownload class="w-3" />
                  <span>PDF</span>
                </GoButton>
                <GoButton
                  variant="outline"
                  class="text-xs py-1 flex flex-row justify-center items-center space-x-2"
                  @click="downloadCsv"
                >
                  <IconDownload class="w-3" />
                  <span>CSV</span>
                </GoButton>
              </div>
            </div>
            <div>
              <template v-if="displayRoutes.length === 0">
                <div
                  class="bg-white dark:bg-gray-800 shadow rounded-lg mb-4 flex flex-col p-8 text-lg"
                >
                  There are no batches for {{ currentDay }}
                </div>
              </template>
              <template v-else>
                <div v-for="route in sortedRoutes" :key="route.id">
                  <BatchCard :route="route" />
                  <BatchTable :route="route" v-if="showTable" />
                  <div class="border-b-2 border-gray-300 mb-6 mt-2" v-if="showTable"></div>
                </div>
              </template>
            </div>
          </div>
          <div>
            <div v-if="unroutedOrders && unroutedOrders.orders.length">
              <GoHeader :level="1" class="mb-4">
                {{ unroutedOrders.orders.length }} Unrouted Orders
              </GoHeader>
              <div
                v-if="!deliveryDay"
                class="bg-white dark:bg-gray-800 shadow rounded-lg mb-4 flex flex-col p-4 text-base"
              >
                There is no delivery day configured for {{ currentDayOfWeek }} {{ currentDay }}.
              </div>
              <MerchantUnroutedOrders
                :unroutedOrders="unroutedOrders.orders"
                @updateUnroutedOrders="getRoutes"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import sortBy from 'lodash/sortBy';
import postscribe from 'postscribe';
import groupBy from 'lodash/groupBy';
import startCase from 'lodash/startCase';
import dayjs from 'dayjs';
import {
  apiService,
  convertZplToPdf,
  dropoffsForRoute,
  Merchant,
  waypointZpl,
  rawOrderZpl,
  Route,
} from '@tyltgo/shared';
import { unparse } from 'papaparse';
import { chunk, flatten } from 'lodash';
import { PDFDocument } from 'pdf-lib';
import { currentUser } from '@/services/auth-service';
import GoButton from '../../components/GoButton.vue';
import IconLoader from '../../components/IconLoader.vue';
import IconDownload from '../../components/IconDownload.vue';
import BatchCard from '../../components/BatchCard.vue';
import BatchTable from '../../components/BatchTable.vue';
import GoCheckbox from '../../components/GoCheckbox.vue';
import { routeToCsvHash, rawOrderToCsvHash } from '../../services/csv-service';
import { downloadBlob } from '../../services/browser-service';
import { timezoneConversionRouteInput } from '../../services/date-service';
import { aroundLoadingMessage } from '../../services/ui-message-service';

export default defineComponent({
  setup() {
    return { startCase, currentUser };
  },
  components: {
    BatchCard,
    BatchTable,
    GoButton,
    IconLoader,
    IconDownload,
    GoCheckbox,
  },
  async mounted() {
    console.log(this.$route.query);
    const { day, showTable } = this.$route.query;
    this.currentDay = day ?? dayjs().format('YYYY-MM-DD');
    this.showTable = showTable;
    postscribe(
      '#zendeskChatBox',
      `<script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=fc9bf77d-8e98-469e-b79e-d8af082cb1a7"><\/script>`
    );
  },
  data() {
    return {
      showTable: false,
      loading: false,
      routes: null,
      unroutedOrders: null,
      currentDay: null,
    };
  },
  watch: {
    $route(to, _from) {
      if (to.name === 'merchant.batches') {
        this.currentDay = to.query.day ?? dayjs().format('YYYY-MM-DD');
      }
    },
    showTable() {
      this.$router.push({
        name: 'merchant.batches',
        query: { ...this.$route.query, showTable: this.showTable },
      });
    },
    currentDay() {
      this.getRoutes();
    },
  },
  computed: {
    currentDayOfWeek() {
      if (!this.currentDay) return '';
      return dayjs(this.currentDay).format('dddd');
    },
    deliveryDay() {
      if (!this.currentUser?.deliveryDays) return undefined;
      if (!this.currentDayOfWeek) return undefined;
      return this.currentUser.deliveryDays.find(d => d.day === this.currentDayOfWeek.toLowerCase());
    },
    routeStates() {
      return ['new', 'inProgress', 'completed'];
    },
    displayRoutes() {
      return this.routes.map(route => {
        return {
          ...route,
          state: route.state === 'offer' ? 'new' : route.state,
        };
      });
    },
    sortedRoutes(): any[] {
      return sortBy(this.filteredRoutes, 'cutoffTime', 'routeNumber');
    },
    sortedRoutesWaypoints() {
      return this.sortedRoutes
        .map(route => route.waypoints)
        .reduce((list, waypoints) => [...list, ...waypoints], []);
    },
    filteredRoutes() {
      const { state } = this.$route.query;

      if (state) {
        return this.displayRoutes.filter(route => route.state === state);
      }

      return this.displayRoutes;
    },
    groupedRoutes() {
      return groupBy(this.displayRoutes, 'state');
    },
  },
  methods: {
    changeDay(diff: number) {
      return dayjs(this.currentDay).add(diff, 'days').format('YYYY-MM-DD');
    },
    async getRoutes() {
      this.loading = true;
      this.routes = await apiService.merchants.batches.all({ day: this.currentDay || '' });
      this.unroutedOrders = await apiService.merchants.batches.unroutedOrders({
        day: this.currentDay || '',
      });
      this.loading = false;
    },
    async downloadCsv() {
      const rows = this.sortedRoutes
        .map((route: Route) => routeToCsvHash(route, 'merchant', this.currentUser))
        .flat();
      const blob = new Blob([unparse(rows)], { type: 'text/csv' });
      downloadBlob(blob, `batches-${this.sortedRoutes[0].routeDate}.csv`);
    },
    async downloadUnroutedCsv() {
      const rows = this.unroutedOrders.orders.map(order => rawOrderToCsvHash(order)).flat();
      const blob = new Blob([unparse(rows)], { type: 'text/csv' });
      downloadBlob(blob, `orders-${this.unroutedOrders.orders[0].date}.csv`);
    },
    async downloadLabels(fileType: 'pdf' | 'zpl', type?: 'routed' | 'unrouted') {
      const labelsFunction = type === 'unrouted' ? 'createUnroutedZplLabels' : 'createZplLabels';
      aroundLoadingMessage('Generating Labels...', async () => {
        const merchant = this.currentUser;
        const labels: string[] = await this[labelsFunction](merchant);
        console.log({ labels });
        let blob = new Blob(labels, { type: 'x-application/zpl' });
        if (fileType === 'pdf') {
          const labelGroups = chunk(labels, 1);
          const pdfs = [];
          for (const labelGroup of labelGroups) {
            const groupBlob = new Blob(labelGroup, { type: 'x-application/zpl' });
            // eslint-disable-next-line no-await-in-loop
            const pdfLabel = await convertZplToPdf(groupBlob, 'arraybuffer');
            // eslint-disable-next-line no-await-in-loop
            await new Promise(r => setTimeout(r, 1000));
            pdfs.push(pdfLabel);
          }

          const pdfDoc = await PDFDocument.create();
          const pdfDocs = await Promise.all(pdfs.map(async doc => PDFDocument.load(doc)));
          await Promise.all(
            pdfDocs.map(async doc => {
              (await pdfDoc.copyPages(doc, [...Array(doc.getPageCount()).keys()])).forEach(page => {
                pdfDoc.addPage(page);
              });
            })
          );
          const pdfBytes = await pdfDoc.save();
          blob = new Blob([pdfBytes], { type: 'application/pdf' });
        }
        const fileName =
          type === 'unrouted'
            ? `orders-${this.unroutedOrders.orders[0].date}.${fileType}`
            : `batches-${this.sortedRoutes[0].routeDate}.${fileType}`;
        downloadBlob(blob, fileName);
      });
    },
    async createZplLabels(merchant: Merchant) {
      const promises = this.sortedRoutes.map(async route => {
        const zone = await timezoneConversionRouteInput(route, 'merchant');
        return {
          routeId: route.id,
          zone: zone.timeZoneId,
        };
      });
      const timezones: { routeId: string; zone: string }[] = await Promise.all(promises);
      return flatten<string>(
        this.sortedRoutes.map(route => {
          const { zone } = timezones.find(z => z.routeId === route.id);
          return dropoffsForRoute(route).map(dropoff =>
            waypointZpl({ route, waypoint: dropoff, merchant, toChangeTimezone: zone })
          );
        })
      );
    },
    async createUnroutedZplLabels(merchant: Merchant) {
      return flatten<string>(
        this.unroutedOrders.orders.map(order => rawOrderZpl({ rawOrder: order, merchant }))
      );
    },
  },
});
</script>
