<template>
  <div class="min-h-screen flex flex-col justify-center py-12 px-2 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h1 class="mx-auto text-6xl h-12 text-center font-logo">Tyltgo</h1>
      <GoHeader :level="2" class="mt-6 h-12 text-center font-logo">
        {{ startCase(loginType) }} Portal
      </GoHeader>
      <GoHeader :level="1" class="mt-8 text-center">Login to your account</GoHeader>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white dark:bg-gray-800 py-8 px-4 shadow sm:rounded-lg sm:px-10 space-y-2">
        <GoForm class="space-y-4 mb-4" @submit="login">
          <GoTextField
            name="email"
            label="Email"
            placeholder="you@example.com"
            v-model="inputEmail"
          />
          <GoTextField name="password" label="Password" type="password" />
          <GoButton class="w-full">Login</GoButton>
        </GoForm>
        <router-link
          :to="{
            name: routeForReset(loginType),
            query: { inputEmail: inputEmail },
          }"
          class="nav-link"
        >
          <h3 class="text-sm font-medium cursor-pointer">Forgot Password?</h3>
        </router-link>

        <div v-if="failed" class="rounded-md bg-red-50 p-4">
          <h3 class="text-sm font-medium text-red-800">
            Login Failed. Please check your email and password.
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
import { apiService, authService } from '@tyltgo/shared';
import startCase from 'lodash/startCase';
import GoTextField from '../components/GoTextField.vue';
import GoButton from '../components/GoButton.vue';
import { redirectUrl } from '../services/auth-service';

const loginFunctions = {
  merchant: apiService.merchants.auth.login,
  admin: apiService.admin.auth.login,
  courier: apiService.couriers.auth.login,
};

const successRoutes = {
  merchant: 'merchant.batches',
  admin: 'admin.home',
  courier: 'courier.profile',
};

const resetPasswordRoutes = {
  merchant: 'merchant.passwordReset',
  admin: 'admin.passwordReset',
  courier: 'courier.passwordReset',
};

export default defineComponent({
  setup() {
    return {
      startCase,
    };
  },
  components: {
    Form,
    GoTextField,
    GoButton,
  },
  props: {
    loginType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      user: {},
      failed: false,
      inputEmail: '',
    };
  },
  beforeMount() {
    const { token } = this.$route.query;
    if (token) {
      this.successfulLogin(token);
    }
  },
  methods: {
    routeForReset(loginType: string) {
      return resetPasswordRoutes[loginType];
    },
    async successfulLogin(token: string) {
      await authService.loginSuccess(token);
      if (redirectUrl.value) {
        this.$router.push(redirectUrl.value);
        redirectUrl.value = null;
      } else {
        this.$router.push({ name: successRoutes[this.loginType] });
      }
    },
    async login(user: { email: string; password: string }) {
      this.failed = false;
      const token = await loginFunctions[this.loginType](user.email, user.password);
      if (token) {
        this.successfulLogin(token);
      } else {
        this.failed = true;
      }
    },
  },
});
</script>
