<template>
  <main class="flex-1 focus:outline-none" tabindex="0">
    <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
      <div class="pt-10 pb-16">
        <div v-if="currentUser.canDeleteInvoices" class="mb-6">
          <div class="px-4 sm:px-6 md:px-0">
            <GoHeader :level="1">Generate Invoice</GoHeader>
          </div>
          <div class="px-4 sm:px-6 md:px-0 pt-10">
            <div class="flex flex-col">
              <div class="-my-2 sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <Form
                    class="gap-3 grid grid-cols-4 w-full pr-4 mb-4"
                    @submit="generateInvoice"
                    :validation-schema="schema"
                  >
                    <GoTextField class="col-span-1" name="start" label="Start Date" type="date" />
                    <GoTextField class="col-span-1" name="end" label="End Date" type="date" />
                    <div class="col-span-1 flex items-end">
                      <GoButton>Generate</GoButton>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="px-4 sm:px-6 md:px-0">
          <GoHeader :level="1">Invoices</GoHeader>
        </div>
        <div class="px-4 sm:px-6 md:px-0 pt-10">
          <div class="flex flex-col">
            <div class="-my-2 sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <InvoicesTable
                  v-if="charges?.length"
                  :invoices="charges"
                  @loadInvoices="loadInvoices"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { apiService } from '@tyltgo/shared';
import sortBy from 'lodash/sortBy';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { currentUser } from '../services/auth-service';
import { addNotification, aroundLoadingMessage } from '../services/ui-message-service';

const schema = yup.object().shape({
  start: yup.string().required().label('Start Date'),
  end: yup.string().required().label('End Date'),
});

export default defineComponent({
  emits: ['loadMerchant'],
  components: {
    Form,
  },
  props: {
    merchant: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      schema,
      currentUser,
    };
  },
  async mounted() {
    await this.loadInvoices();
  },
  data() {
    return {
      loading: false,
      charges: null,
    };
  },
  methods: {
    async generateInvoice(values) {
      console.log(values);
      try {
        await aroundLoadingMessage('Generating...', async () => {
          await apiService.admin.merchants.generateInvoice(this.merchant.id, values);
          await this.loadInvoices();
          addNotification({ color: 'success', message: 'Settings Updated.' });
        });
      } catch (e) {
        addNotification({ color: 'danger', message: 'Error while updating.' });
      }
    },
    async loadInvoices() {
      this.loading = true;
      this.charges = sortBy(
        await apiService.admin.merchants.invoices(this.merchant.id),
        'created'
      ).reverse();
      this.loading = false;
    },
  },
});
</script>
