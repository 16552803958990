/* eslint-disable import/prefer-default-export */
/* eslint-disable max-classes-per-file */
import axios from 'axios';
import {
  CreateBatchOptions,
  GenerateBatchOptions,
  GlobalSettings,
  Location,
  WaypointStatusOptions,
} from '../types';

let apiHost = '';
let apiUrl = '';
let authToken = '';
let globalHeaders: Record<string, unknown> = {};

let errorHandler = (_e: Error, _extra: unknown) => {};
let unconnectedHandler = () => {};
let connectionChecker = () => false;

class NetworkConnectionError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'NetworkConnectionError';
  }
}

const checkForConnection = (options = { silentUnconnected: false }) => {
  const connected = connectionChecker();
  console.log('CONNECTED API', connected);
  if (!connected) {
    console.log('UNCONNECTED HANDLER');
    if (!options.silentUnconnected) unconnectedHandler();
    throw new NetworkConnectionError('No connection');
  }
};

interface GetOptions {
  silentUnconnected?: boolean;
  params?: unknown;
}

const get = async (url: string, options: GetOptions = {}) => {
  console.log('HTTP GET', url);
  console.log('AUTH', authToken);

  checkForConnection({ silentUnconnected: options.silentUnconnected || false });
  try {
    const result = await axios.get(url, {
      params: options.params,
      headers: { ...globalHeaders, Authorization: authToken },
    });

    // this is mostly for the nativescript app
    if (!result.status) {
      const error = new Error('no status from request');
      (error as Error & { response: unknown }).response = result;
      throw error;
    }

    return result;
  } catch (e) {
    console.log(e);
    console.log(e.response);
    console.log(e.response?.data);
    errorHandler(e, { method: 'GET', url: url.replace(/^https?/, ''), status: e.response?.status });
    throw e;
  }
};

const post = async (
  url: string,
  data: unknown = {},
  options?: { silentUnconnected?: boolean; headers?: Record<string, unknown> }
) => {
  console.log('HTTP POST', url, data);
  console.log('AUTH', authToken);
  const { silentUnconnected = false, headers = {} } = { ...options };

  checkForConnection({ silentUnconnected });
  try {
    const result = await axios.post(url, data, {
      headers: { ...globalHeaders, Authorization: authToken, ...headers },
    });
    return result;
  } catch (e) {
    console.log(e);
    console.log(e.response);
    console.log(e.response?.data);
    errorHandler(e, {
      method: 'POST',
      url: url.replace(/^https?/, ''),
      data,
      status: e.response?.status,
    });
    throw e;
  }
};

const httpDelete = async (
  url: string,
  data: unknown = {},
  options = { silentUnconnected: false }
) => {
  console.log('HTTP DELETE', url, data);
  console.log('AUTH', authToken);

  checkForConnection(options);
  try {
    const result = await axios.delete(url, {
      headers: { Authorization: authToken },
    });
    return result;
  } catch (e) {
    console.log(e);
    console.log(e.response);
    console.log(e.response?.data);
    errorHandler(e, {
      method: 'DELETE',
      url: url.replace(/^https?/, ''),
      data,
      status: e.response?.status,
    });
    throw e;
  }
};

class ApiService {
  setApiHost(host: string) {
    apiHost = host;
    apiUrl = `${apiHost}/api`;
  }

  get apiHost() {
    return apiHost;
  }

  setErrorHandler(handler: typeof errorHandler) {
    errorHandler = handler;
  }

  setAuthorizationToken(token: string) {
    authToken = token;
  }

  get authorizationToken() {
    return authToken;
  }

  setUnconnectedHandler(handler: typeof unconnectedHandler) {
    unconnectedHandler = handler;
  }

  setConnectionChecker(checker: typeof connectionChecker) {
    connectionChecker = checker;
  }

  setGlobalHeaders(headers: typeof globalHeaders) {
    globalHeaders = headers;
  }

  tracking = {
    async search(search: string, trackingSecret: string, trackingSlug = undefined) {
      const url = `${apiUrl}/v0/elastic/waypoint/tracking?key=abcdefkey&search=${search}`;
      const { data } = await get(url, {
        params: { trackingSlug, trackingSecret },
      });
      return data;
    },
    async isOutsourced(routeId: string) {
      const url = `${apiUrl}/v0/tracking/isOutsourced/${routeId}`;
      const { data } = await get(url);

      return data;
    },
    async config(slug: string) {
      const url = `${apiUrl}/v0/tracking/config/${slug}`;
      const { data } = await get(url);

      return data;
    },
    async updateDeliveryNote(
      routeId: string,
      waypointId: string,
      trackingSecret: string,
      deliveryNote: string
    ) {
      const url = `${apiUrl}/v0/tracking/${routeId}/waypoint/${waypointId}/updateDeliveryNote`;
      const { data } = await post(url, { trackingSecret, deliveryNote });
      return data;
    },
    async waypointImage(id: string, waypointId: string, trackingSecret: string) {
      const url = `${apiUrl}/v0/tracking/${id}/waypoint/${encodeURIComponent(waypointId)}/image`;
      const result = await get(url, { params: { trackingSecret } });
      const { image } = result.data;

      return image;
    },
    async validateTrackingSecret(
      waypointId: string,
      trackingSecret: string,
      trackingSlug = undefined
    ) {
      const url = `${apiUrl}/v0/tracking/validateTrackingSecret`;
      const { data } = await post(url, { waypointId, trackingSecret, trackingSlug });
      return data;
    },
    async reportWrongAddress(routeId: string, waypointId: string, trackingSecret: string) {
      const url = `${apiUrl}/v0/tracking/${routeId}/waypoint/${waypointId}/reportWrongAddress`;
      const { data } = await post(url, { trackingSecret });
      return data;
    },
    async unsubscribeEmail(routeId: string, waypointId: string, trackingSecret: string) {
      const url = `${apiUrl}/v0/tracking/${routeId}/waypoint/${waypointId}/unsubscribeEmail`;
      const { data } = await post(url, { trackingSecret });
      return data;
    },
    async resubscribeEmail(routeId: string, waypointId: string, trackingSecret: string) {
      const url = `${apiUrl}/v0/tracking/${routeId}/waypoint/${waypointId}/resubscribeEmail`;
      const { data } = await post(url, { trackingSecret });
      return data;
    },
    async reportIncorrectDelivery(
      routeId: string,
      waypointId: string,
      trackingSecret: string,
      incorrectDeliveryNote: string
    ) {
      const url = `${apiUrl}/v0/tracking/${routeId}/waypoint/${waypointId}/reportIncorrectDelivery`;
      const { data } = await post(url, { trackingSecret, incorrectDeliveryNote });
      return data;
    },
    async tipCourier(routeId: string, waypointId: string, trackingSecret: string) {
      const url = `${apiUrl}/v0/tracking/${routeId}/waypoint/${encodeURIComponent(
        waypointId
      )}/tipCourier`;
      const { data } = await post(url, { trackingSecret });
      return data;
    },
  };

  couriers = {
    auth: {
      async sendSupportMessage(payload: {
        routeId?: string;
        waypointId?: string;
        message: string;
        screen: string;
      }) {
        const url = `${apiUrl}/v0/couriers/auth/sendSupportMessage`;
        const { data } = await post(url, payload);
        return data;
      },
      async login(email: string, password: string) {
        try {
          const url = `${apiUrl}/v0/couriers/auth/login`;
          const result = await post(url, { user: { email, password } });

          if (result.status) {
            return result.data.token;
          }

          return false;
        } catch (e) {
          if (e.name === 'NetworkConnectionError') throw e;
          return false;
        }
      },

      async forgotPassword(user: unknown) {
        const url = `${apiUrl}/v0/couriers/auth/forgotPassword`;
        const { data } = await post(url, { user });
        return data;
      },

      async signup(user: unknown) {
        const url = `${apiUrl}/v0/couriers/auth/signup`;
        const { data } = await post(url, { user });
        return data;
      },

      async otp(phoneNumber: string) {
        const url = `${apiUrl}/v0/couriers/auth/otp`;
        const { data } = await post(url, { phoneNumber });
        return data;
      },

      async checkOtp(phoneNumber: string, code: string) {
        const url = `${apiUrl}/v0/couriers/auth/checkOtp`;
        const { data } = await get(url, { params: { phoneNumber, code } });
        return data;
      },

      async setFcmToken(fcmToken: string) {
        const url = `${apiUrl}/v0/couriers/auth/setFcmToken`;
        const { data } = await post(url, { fcmToken }, { silentUnconnected: true });
        return data.fcmToken;
      },

      async location(location: Location, routeId: string) {
        const url = `${apiUrl}/v0/couriers/auth/location`;
        const { data } = await post(url, { location, routeId }, { silentUnconnected: true });
        return data.location;
      },

      async currentUser() {
        const url = `${apiUrl}/v0/couriers/auth/me`;
        const result = await get(url);
        const { user } = result.data;

        return user;
      },
    },
    scanner: {
      async loadData(params?: { merchantId: string }) {
        const url = `${apiUrl}/v0/couriers/scanner/loadData`;
        const { data } = await get(url, { params });
        return data;
      },
      async scanned(orderId: string, message: string, method: string, merchantId?: string) {
        const url = `${apiUrl}/v0/couriers/scanner/scanned`;
        const { data } = await post(url, { orderId, message, method, merchantId });
        return data;
      },
      async audit() {
        const url = `${apiUrl}/v0/couriers/scanner/audit`;
        const { data } = await post(url);
        return data;
      },
      async getFlowerCoOrder(payload: unknown) {
        const url = `${apiUrl}/v0/couriers/scanner/flowerCoOrder`;
        const { data } = await post(url, payload);
        return data;
      },
      async createFlowerCoRawOrder(payload: unknown) {
        const url = `${apiUrl}/v0/couriers/scanner/createFlowerCoRawOrder`;
        const { data } = await post(url, payload);
        return data;
      },
      async createFlowerCoRoute(payload: unknown) {
        const url = `${apiUrl}/v0/couriers/scanner/createFlowerCoRoute`;
        const { data } = await post(url, payload);
        return data;
      },
    },
    profile: {
      async steps({ grouped }: { grouped: boolean }) {
        const url = `${apiUrl}/v0/couriers/profile/steps`;
        const { data } = await get(url, { params: { grouped } });
        return data;
      },
      async agreements() {
        const url = `${apiUrl}/v0/couriers/profile/agreements`;
        const { data } = await get(url);
        return data;
      },
      async acceptAgreement(name: string) {
        const url = `${apiUrl}/v0/couriers/profile/acceptAgreement/${name}`;
        const { data } = await post(url);
        return data;
      },
      async confirmTraining(name: string, payload: unknown) {
        const url = `${apiUrl}/v0/couriers/profile/confirmTraining/${name}`;
        const { data } = await post(url, payload);
        return data;
      },
      async confirmLastStep(name: string) {
        const url = `${apiUrl}/v0/couriers/profile/confirmLastStep/${name}`;
        const { data } = await post(url);
        return data;
      },
      async updateCourierDetails(values: unknown) {
        const url = `${apiUrl}/v0/couriers/profile/updateCourierDetails`;
        const { data } = await post(url, { values });
        return data;
      },
      async documentImage(documentName: string) {
        const url = `${apiUrl}/v0/couriers/profile/image/${documentName}`;
        const { data } = await get(url);
        return data;
      },
      async updateDocument(payload: unknown) {
        const url = `${apiUrl}/v0/couriers/profile/updateDocument`;
        const { data } = await post(url, payload);
        return data;
      },
      async updateAvailability(payload: unknown) {
        const url = `${apiUrl}/v0/couriers/profile/updateAvailability`;
        const { data } = await post(url, payload);
        return data;
      },
      async pauseAvailability() {
        const url = `${apiUrl}/v0/couriers/profile/pauseAvailability`;
        const { data } = await post(url);
        return data;
      },
      async resumeAvailability() {
        const url = `${apiUrl}/v0/couriers/profile/resumeAvailability`;
        const { data } = await post(url);
        return data;
      },
    },
  };

  merchants = {
    billing: {
      async all() {
        const url = `${apiUrl}/v0/merchants/billing`;
        const result = await get(url);
        const { charges } = result.data;

        return charges;
      },
      async pdf(id: string) {
        const url = `${apiUrl}/v0/merchants/billing/${id}/pdf`;
        const result = await get(url);
        return result.data.url;
      },
      async charge(id: string) {
        const url = `${apiUrl}/v0/merchants/billing/${id}`;
        const { data } = await get(url);
        return data;
      },
      async padSetup(id: string) {
        const url = `${apiUrl}/v0/merchants/billing/${id}/padSetup`;
        const { data } = await post(url);
        return data;
      },
      async completePadSetup(id: string, setupIntent: any) {
        const url = `${apiUrl}/v0/merchants/billing/${id}/completePadSetup`;
        const { data } = await post(url, setupIntent);
        return data;
      },
    },
    shopify: {
      async install(query: Record<string, string>, merchantId: string) {
        const url = `${apiUrl}/v0/integrations/shopify/install`;
        const { data } = await post(
          url,
          { query },
          {
            headers: merchantId ? { 'X-TYLTGO-MERCHANTID': merchantId } : {},
          }
        );
        return data;
      },
      async integration(merchantId: string, shop: string, sessionToken: string) {
        const url = `${apiUrl}/v0/integrations/shopify/integration`;
        const { data } = await get(url, { params: { sessionToken, shop, merchantId } });
        return data;
      },
      async apiKey(shop: string) {
        const url = `${apiUrl}/v0/integrations/shopify/apiKey`;
        const { data } = await get(url, { params: { shop } });
        return data;
      },
      async login(user: unknown, sessionToken: string) {
        const url = `${apiUrl}/v0/integrations/shopify/login?sessionToken=${sessionToken}`;
        const { data } = await post(url, { user });
        return data;
      },
    },
    pricing: {
      async all() {
        const url = `${apiUrl}/v0/merchants/pricing`;
        const { data } = await get(url);
        return data;
      },
    },
    batches: {
      async all(params: unknown) {
        const url = `${apiUrl}/v0/merchants/batches`;
        const result = await get(url, { params });
        const { batches } = result.data;

        return batches;
      },
      async get(id: string) {
        const url = `${apiUrl}/v0/merchants/batches/${id}`;
        const result = await get(url);
        const { batch } = result.data;

        return batch;
      },
      async getComponentBatch(merchantId: string, batchId: string) {
        const url = `${apiUrl}/v0/merchants/batches/getComponentBatch`;
        const { data } = await get(url, { params: { merchantId, batchId } });

        return data;
      },
      async shopifyOrders(shopName: string) {
        const url = `${apiUrl}/v0/merchants/batches/shopifyOrders/${shopName}`;
        const { data } = await get(url);

        return data;
      },
      async searchShopifyOrder(shopName: string, params: unknown) {
        const url = `${apiUrl}/v0/merchants/batches/searchShopifyOrder/${shopName}`;
        const { data } = await get(url, { params });

        return data;
      },
      async submitShopifyOrders(shopName: string, params: unknown) {
        const url = `${apiUrl}/v0/merchants/batches/submitShopifyOrders/${shopName}`;
        const { data } = await post(url, params);
        return data;
      },
      async markScannedForRelabel(params: unknown) {
        const url = `${apiUrl}/v0/merchants/batches/markScannedForRelabel`;
        const { data } = await post(url, params);

        return data;
      },
      async unroutedOrders(params: { day: string }) {
        const url = `${apiUrl}/v0/merchants/batches/unroutedOrders`;
        const { data } = await get(url, { params });
        return data;
      },
      async updateRawOrder(id: string, params: unknown) {
        const url = `${apiUrl}/v0/merchants/batches/updateRawOrder/${id}`;
        const { data } = await post(url, params);
        return data;
      },
      async removeRawOrder(id: string) {
        const url = `${apiUrl}/v0/merchants/batches/removeRawOrder/${id}`;
        const { data } = await post(url);
        return data;
      },
      async geolocation({ address, addressParts }: { address?: string; addressParts?: unknown }) {
        const url = `${apiUrl}/v0/merchants/batches/geolocation`;
        const result = await post(url, { address, addressParts });
        const { geolocation } = result.data;

        return geolocation;
      },
      async storeCsv(payload: unknown) {
        const url = `${apiUrl}/v0/merchants/batches/storeCsv`;
        const { data } = await post(url, payload);
        return data;
      },
      async getTimezone(location: string, timestamp: number, id: string) {
        const url = `${apiUrl}/v0/merchants/batches/${id}/getTimezone?location=${location}&timestamp=${timestamp}`;
        const { data } = await get(url);
        return data;
      },
      async generate(options: GenerateBatchOptions) {
        const url = `${apiUrl}/v0/merchants/batches/generate`;
        const result = await post(url, options);
        const { data } = result;

        return data;
      },
      async createRawOrders(options: GenerateBatchOptions) {
        const url = `${apiUrl}/v0/merchants/batches/createRawOrders`;
        const result = await post(url, options);
        const { data } = result;

        return data;
      },
      async routingRequestState(id: string) {
        const url = `${apiUrl}/v0/merchants/batches/routingRequestState/${id}`;
        const result = await get(url);
        const { data } = result;

        return data;
      },
      async routingRequestResult(id: string) {
        const url = `${apiUrl}/v0/merchants/batches/routingRequestResult/${id}`;
        const result = await get(url);
        const { data } = result;

        return data;
      },
      async create(options: CreateBatchOptions) {
        const url = `${apiUrl}/v0/merchants/batches/`;
        const result = await post(url, options);
        const { batch } = result.data;

        return batch;
      },
      async orderImage(id: string, orderId: string, imageType?: string) {
        const url = `${apiUrl}/v0/merchants/batches/${id}/orders/${orderId}/image`;
        const result = await get(url, { params: { imageType } });
        const { image } = result.data;

        return image;
      },
      async cancelOrder(id: string, orderId: string) {
        const url = `${apiUrl}/v0/merchants/batches/${id}/orders/${orderId}/cancelOrder`;
        const { data } = await post(url);
        return data;
      },
      async changeQuantity(id: string, orderId: string, payload: unknown) {
        const url = `${apiUrl}/v0/merchants/batches/${id}/orders/${orderId}/changeQuantity`;
        const { data } = await post(url, payload);
        return data;
      },
      async searchForRelabel(orderId: string, itemNumber: string) {
        const url = `${apiUrl}/v0/merchants/batches/searchForRelabel`;
        const { data } = await get(url, { params: { orderId, itemNumber } });

        return data;
      },
      async search(searchText: string) {
        const url = `${apiUrl}/v0/merchants/batches/search?searchText=${searchText}`;
        const result = await get(url);
        const { results } = result.data;

        return results;
      },
      async searchWaypoints(searchText: string) {
        const url = `${apiUrl}/v0/merchants/batches/searchWaypoints?searchText=${searchText}`;
        const result = await get(url);
        const { results } = result.data;

        return results;
      },
    },
    auth: {
      async login(email: string, password: string) {
        try {
          const url = `${apiUrl}/v0/merchants/auth/login`;
          const result = await post(url, { user: { email, password } });

          if (result.status) {
            return result.data.token;
          }

          return false;
        } catch (e) {
          if (e.name === 'NetworkConnectionError') throw e;
          return false;
        }
      },

      async forgotPassword(user: unknown) {
        const url = `${apiUrl}/v0/merchants/auth/forgotPassword`;
        const { data } = await post(url, { user });
        return data;
      },

      async currentUser() {
        const url = `${apiUrl}/v0/merchants/auth/me`;
        const result = await get(url);
        const { user } = result.data;

        return user;
      },
      async signupMerchantInfo(key: string) {
        const url = `${apiUrl}/v0/merchants/auth/signupMerchantInfo`;
        const { data } = await get(url, { params: { key } });
        return data;
      },
      async completeSignup(merchant: unknown) {
        const url = `${apiUrl}/v0/merchants/auth/completeSignup`;
        const { data } = await post(url, { merchant });
        return data;
      },
    },
  };

  admin = {
    settings: {
      async get() {
        const url = `${apiUrl}/v0/admin/settings`;
        const { data } = await get(url);
        return data;
      },
      async updateSetting(settings: GlobalSettings, agreements: Record<string, string>) {
        const url = `${apiUrl}/v0/admin/settings`;
        const { data } = await post(url, { settings, agreements });
        return data;
      },
      async lockViewer() {
        const url = `${apiUrl}/v0/admin/settings/lockViewer`;
        const { data } = await get(url);
        return data;
      },
      async deleteLock(collection: string) {
        const url = `${apiUrl}/v0/admin/settings/deleteLock?collection=${collection}`;
        const { data } = await httpDelete(url);
        return data;
      },
    },
    scheduling: {
      async schedule(payload: { courierIds: string[]; routeIds: string[] }) {
        const url = `${apiUrl}/v0/admin/scheduling/schedule`;
        const { data } = await post(url, payload);
        return data;
      },
      async checkLock() {
        const url = `${apiUrl}/v0/admin/scheduling/checkLock`;
        const { data } = await get(url);
        return data;
      },
    },
    pricing: {
      async getCities(payload: unknown) {
        const url = `${apiUrl}/v0/admin/pricing/getCities`;
        const { data } = await post(url, payload);
        return data;
      },
      async getAddresses(payload: unknown) {
        const url = `${apiUrl}/v0/admin/pricing/getAddresses`;
        const { data } = await post(url, payload);
        return data;
      },
      async updateFsas(payload: unknown) {
        const url = `${apiUrl}/v0/admin/pricing/updateFsas`;
        const { data } = await post(url, payload);
        return data;
      },
      async fsas() {
        const url = `${apiUrl}/v0/admin/pricing/fsas`;
        const { data } = await get(url);
        return data;
      },
      async priceAddresses(payload: unknown) {
        const url = `${apiUrl}/v0/admin/pricing`;
        const { data } = await post(url, payload);
        return data;
      },
    },
    couriers: {
      async all() {
        const url = `${apiUrl}/v0/admin/couriers`;
        const result = await get(url);
        const { couriers } = result.data;

        return couriers;
      },
      async get(id: string) {
        const url = `${apiUrl}/v0/admin/couriers/${id}`;
        const { data } = await get(url);
        return data;
      },
      async allCourierAvailability(day: string) {
        const url = `${apiUrl}/v0/admin/couriers/courierAvailabilityDay?day=${day}`;
        const { data } = await get(url);
        return data;
      },
      async approveShift(id: string) {
        const url = `${apiUrl}/v0/admin/couriers/approveShift?id=${id}`;
        const { data } = await post(url);
        return data;
      },
      async routes(id: string, { size }: { size: number }) {
        const url = `${apiUrl}/v0/admin/couriers/${id}/routes`;
        const { data } = await get(url, { params: { size } });
        return data;
      },
      async offers(id: string) {
        const url = `${apiUrl}/v0/admin/couriers/${id}/offers`;
        const { data } = await get(url);
        return data;
      },
      async offerHistory(id: string) {
        const url = `${apiUrl}/v0/admin/couriers/${id}/offerHistory`;
        const { data } = await get(url);
        return data;
      },
      async search(
        searchText: string,
        options: {
          fixedSearchText?: string;
          size?: number;
          sortBy?: string;
          sortOrder?: string;
          radius?: number;
          geoPin?: string;
        } = {}
      ) {
        const url = `${apiUrl}/v0/admin/couriers/search`;
        const { data } = await post(url, {
          ...options,
          searchText,
        });
        return data;
      },
      async documentImage(id: string, documentName: string) {
        const url = `${apiUrl}/v0/admin/couriers/${id}/image/${documentName}`;
        const { data } = await get(url);
        return data;
      },
      async updateDocument(id: string, payload: unknown) {
        const url = `${apiUrl}/v0/admin/couriers/${id}/updateDocument`;
        const { data } = await post(url, payload);
        return data;
      },
      async updateSettings(id: string, settingOptions: unknown) {
        const url = `${apiUrl}/v0/admin/couriers/${id}/updateSettings`;
        const { data } = await post(url, { settingOptions });
        return data;
      },
      async updateReferrals(id: string, referralOptions: unknown) {
        const url = `${apiUrl}/v0/admin/couriers/${id}/updateReferrals`;
        const { data } = await post(url, { referralOptions });
        return data;
      },
      async updateAvailability(id: string, payload: unknown) {
        const url = `${apiUrl}/v0/admin/couriers/${id}/updateAvailability`;
        const { data } = await post(url, payload);
        return data;
      },
      async loginAs(email: string) {
        const url = `${apiUrl}/v0/admin/couriers/loginAs`;
        const { data } = await post(url, { email });
        return data;
      },
      async updateSuspended(email: string, state: boolean) {
        const url = `${apiUrl}/v0/admin/couriers/updateSuspended`;
        const { data } = await post(url, { email, state });
        return data;
      },
      async approve(email: string) {
        const url = `${apiUrl}/v0/admin/couriers/approve`;
        const { data } = await post(url, { email });
        return data;
      },
      async moveToTraining(email: string) {
        const url = `${apiUrl}/v0/admin/couriers/moveToTraining`;
        const { data } = await post(url, { email });
        return data;
      },
      async moveToOnHold(email: string) {
        const url = `${apiUrl}/v0/admin/couriers/moveToOnHold`;
        const { data } = await post(url, { email });
        return data;
      },
      async reject(email: string) {
        const url = `${apiUrl}/v0/admin/couriers/reject`;
        const { data } = await post(url, { email });
        return data;
      },
      async previewPayouts(values: unknown) {
        const url = `${apiUrl}/v0/admin/couriers/previewPayouts`;
        const { data } = await post(url, values);
        return data;
      },
      async quizResponse(email: string) {
        const url = `${apiUrl}/v0/admin/couriers/${email}/quizResponse`;
        const { data } = await get(url);
        return data;
      },
      async payouts(email: string) {
        const url = `${apiUrl}/v0/admin/couriers/${email}/payouts`;
        const { data } = await get(url);
        return data;
      },
      async allPayouts() {
        const url = `${apiUrl}/v0/admin/couriers/allPayouts`;
        const { data } = await get(url);
        return data;
      },
      async referredCouriers(email: string) {
        const url = `${apiUrl}/v0/admin/couriers/${email}/referredCouriers`;
        const { data } = await get(url);
        return data;
      },
      async duplicatePhoneNumberCheck(email: string, phoneNumber: string) {
        const url = `${apiUrl}/v0/admin/couriers/${email}/${phoneNumber}/duplicatePhoneNumberCheck`;
        const { data } = await get(url);
        return data;
      },
    },
    billing: {
      async invoices(params: unknown) {
        const url = `${apiUrl}/v0/admin/billing/invoices`;
        const { data } = await get(url, { params });
        return data;
      },
      async chargeInvoice(id: string) {
        const url = `${apiUrl}/v0/admin/billing/invoices/${id}/charge`;
        const { data } = await post(url);
        return data;
      },
      async padInvoice(id: string) {
        const url = `${apiUrl}/v0/admin/billing/invoices/${id}/pad`;
        const { data } = await post(url);
        return data;
      },
      async markInvoicePaid(id: string) {
        const url = `${apiUrl}/v0/admin/billing/invoices/${id}/markPaid`;
        const { data } = await post(url);
        return data;
      },
    },
    merchants: {
      async all() {
        const url = `${apiUrl}/v0/admin/merchants`;
        const { data } = await get(url);
        return data;
      },
      async addMerchant(merchant: unknown) {
        const url = `${apiUrl}/v0/admin/merchants/addMerchant`;
        const { data } = await post(url, { merchant });
        return data;
      },
      async get(id: string) {
        const url = `${apiUrl}/v0/admin/merchants/${id}`;
        const { data } = await get(url);
        return data;
      },
      async checkSurgeLock(id: string) {
        const url = `${apiUrl}/v0/admin/merchants/${id}/checkSurgeLock`;
        const { data } = await get(url);
        return data;
      },
      async getSchedulingNotes(id: string) {
        const url = `${apiUrl}/v0/admin/merchants/${id}/schedulingNotes`;
        const { data } = await get(url);
        return data;
      },
      async getExcludedCouriers(id: string) {
        const url = `${apiUrl}/v0/admin/merchants/${id}/excludedCouriers`;
        const { data } = await get(url);
        return data;
      },
      async batches(id: string, { size }: { size: number }) {
        const url = `${apiUrl}/v0/admin/merchants/${id}/batches`;
        const { data } = await get(url, { params: { size } });
        return data;
      },
      async search(searchText: string, options?: { size?: number }) {
        const url = `${apiUrl}/v0/admin/merchants/search`;
        const { data } = await post(url, { searchText, ...(options || {}) });
        return data;
      },
      async loginAs(email: string) {
        const url = `${apiUrl}/v0/admin/merchants/loginAs`;
        const { data } = await post(url, { email });
        return data;
      },
      async pricing(id: string, pricingData: unknown) {
        const url = `${apiUrl}/v0/admin/merchants/${id}/pricing`;
        const { data } = await post(url, pricingData);
        return data;
      },
      async updateSettings(id: string, settingOptions: unknown) {
        const url = `${apiUrl}/v0/admin/merchants/${id}/updateSettings`;
        const { data } = await post(url, { settingOptions });
        return data;
      },
      async routeDeliveryDay(id: string, deliveryDayIndex: number, routingGroupName: string) {
        const url = `${apiUrl}/v0/admin/merchants/${id}/routeDeliveryDay`;
        const { data } = await post(url, { deliveryDayIndex, routingGroupName });
        return data;
      },
      async updateLocations(id: string, addresses: unknown) {
        const url = `${apiUrl}/v0/admin/merchants/${id}/updateLocations`;
        const { data } = await post(url, { addresses });
        return data;
      },
      async getImage(id: string, imageType: string, imageFileName: string) {
        const url = `${apiUrl}/v0/admin/merchants/${id}/getImage`;
        const result = await get(url, { params: { imageType, imageFileName } });
        const { image } = result.data;

        return image;
      },
      async generateRoutes(id: string, payload: unknown) {
        const url = `${apiUrl}/v0/admin/merchants/${id}/generateRoutes`;
        const { data } = await post(url, payload);
        return data;
      },
      async generateRoutesBackground(id: string, payload: unknown) {
        const url = `${apiUrl}/v0/admin/merchants/${id}/generateRoutesBackground`;
        const { data } = await post(url, payload);
        return data;
      },
      async previewRoutes(id: string, payload: unknown) {
        const url = `${apiUrl}/v0/admin/merchants/${id}/previewRoutes`;
        const { data } = await post(url, payload);
        return data;
      },
      async rawOrders(id: string, payload: unknown) {
        const url = `${apiUrl}/v0/admin/merchants/${id}/rawOrders`;
        const { data } = await post(url, payload);
        return data;
      },
      async unrouteRawOrder(id: string) {
        const url = `${apiUrl}/v0/admin/merchants/${id}/unrouteRawOrder`;
        const { data } = await post(url);
        return data;
      },
      async apiKeys(id: string) {
        const url = `${apiUrl}/v0/admin/merchants/${id}/apiKeys`;
        const { data } = await get(url);
        return data;
      },
      async shopifyIntegrations(id: string) {
        const url = `${apiUrl}/v0/admin/merchants/${id}/shopifyIntegrations`;
        const { data } = await get(url);
        return data;
      },
      async updateShopifyIntegration(id: string, shopifyIntegration: unknown) {
        const url = `${apiUrl}/v0/admin/merchants/${id}/updateShopifyIntegration`;
        const { data } = await post(url, { shopifyIntegration });
        return data;
      },
      async updateWebhooks(id: string, settings: unknown) {
        const url = `${apiUrl}/v0/admin/merchants/${id}/updateWebhooks`;
        const { data } = await post(url, { settings });
        return data;
      },
      async createShopifyIntegration(id: string, shopifyIntegration: unknown) {
        const url = `${apiUrl}/v0/admin/merchants/${id}/createShopifyIntegration`;
        const { data } = await post(url, { shopifyIntegration });
        return data;
      },
      async createApiKey(id: string) {
        const url = `${apiUrl}/v0/admin/merchants/${id}/createApiKey`;
        const { data } = await post(url);
        return data;
      },
      async rawOrderGeocode(id: string, orderId: string) {
        const url = `${apiUrl}/v0/admin/merchants/${id}/rawOrders/${orderId}/geocode`;
        const { data } = await post(url);
        return data;
      },
      async getTextMessages(id: string) {
        const url = `${apiUrl}/v0/admin/merchants/${id}/getTextMessages`;
        const { data } = await get(url);
        return data;
      },

      async updateTextMessages(
        id: string,
        completedTexts: unknown,
        initialTexts: unknown,
        nightBeforeTexts: unknown,
        dayBeforeTexts: unknown
      ) {
        const url = `${apiUrl}/v0/admin/merchants/${id}/updateTextMessages`;
        const { data } = await post(url, {
          completedTexts,
          initialTexts,
          nightBeforeTexts,
          dayBeforeTexts,
        });
        return data;
      },
      async deleteTextMessage(id: string, template: string) {
        const url = `${apiUrl}/v0/admin/merchants/${id}/deleteTextMessage`;
        const { data } = await post(url, { template });
        return data;
      },

      async invoices(id: string) {
        const url = `${apiUrl}/v0/admin/merchants/${id}/invoices`;
        const result = await get(url);
        const { charges } = result.data;

        return charges;
      },
      async shopifyOrders(id: string, shopName: string) {
        const url = `${apiUrl}/v0/admin/merchants/${id}/shopifyOrders/${shopName}`;
        const { data } = await get(url);
        return data;
      },
      async submitShopifyOrders(id: string, shopName: string, params: unknown) {
        const url = `${apiUrl}/v0/admin/merchants/${id}/submitShopifyOrders/${shopName}`;
        const { data } = await post(url, params);
        return data;
      },
      async invoice(id: string, invoiceId: string) {
        const url = `${apiUrl}/v0/admin/merchants/${id}/invoice/${invoiceId}`;
        const { data } = await get(url);
        return data;
      },
      async generateInvoice(id: string, payload: unknown) {
        const url = `${apiUrl}/v0/admin/merchants/${id}/generateInvoice`;
        const { data } = await post(url, payload);
        return data;
      },

      async invoicePdf(invoiceId: string, id: string) {
        const url = `${apiUrl}/v0/admin/merchants/${invoiceId}/${id}/invoicePdf`;
        const result = await get(url);
        return result.data.url;
      },
      async invoiceSummaryCsv(invoiceId: string, id: string) {
        const url = `${apiUrl}/v0/admin/merchants/${invoiceId}/${id}/invoiceSummaryCsv`;
        const { data } = await get(url);
        return data;
      },

      async emailInvoice(invoiceId: string, id: string) {
        const url = `${apiUrl}/v0/admin/merchants/${invoiceId}/${id}/emailInvoice`;
        const { data } = await post(url);
        return data;
      },

      async invoiceDetailsCsv(invoiceId: string, id: string) {
        const url = `${apiUrl}/v0/admin/merchants/${invoiceId}/${id}/invoiceDetailsCsv`;
        const { data } = await get(url);
        return data;
      },

      async deleteInvoice(invoiceId: string, id: string) {
        const url = `${apiUrl}/v0/admin/merchants/${invoiceId}/${id}/deleteInvoice`;
        const { data } = await httpDelete(url);
        return data;
      },

      async createCredit(
        id: string,
        credit: { amount: number; reason: string; orderId: string; province: string }
      ) {
        const url = `${apiUrl}/v0/admin/merchants/${id}/credits`;
        const { data } = await post(url, { credit });
        return data;
      },
      async credits(id: string) {
        const url = `${apiUrl}/v0/admin/merchants/${id}/credits`;
        const { data } = await get(url);
        return data;
      },
      async createDebit(
        id: string,
        debit: { amount: number; reason: string; description: string; province: string }
      ) {
        const url = `${apiUrl}/v0/admin/merchants/${id}/debits`;
        const { data } = await post(url, { debit });
        return data;
      },
      async debits(id: string) {
        const url = `${apiUrl}/v0/admin/merchants/${id}/debits`;
        const { data } = await get(url);
        return data;
      },

      async getDetailedPriceList(id: string) {
        const url = `${apiUrl}/v0/admin/merchants/${id}/getDetailedPriceList`;
        const { data } = await get(url);
        return data;
      },
    },
    routes: {
      async courierLocation(id: string) {
        const url = `${apiUrl}/v0/admin/routes/${id}/courierLocation`;
        const result = await get(url);
        const { location } = result.data;

        return location;
      },
      async fixAcceptedState(id: string) {
        const url = `${apiUrl}/v0/admin/routes/${id}/fixAcceptedState`;
        const { data } = await post(url);
        return data;
      },
      async offerHistory(id: string) {
        const url = `${apiUrl}/v0/admin/routes/${id}/offerHistory`;
        const { data } = await get(url);
        return data;
      },
      async day(day = '') {
        const url = `${apiUrl}/v0/admin/routes/day?day=${day}`;
        const result = await get(url);
        const { routes } = result.data;

        return routes;
      },
      async get(id: string) {
        const url = `${apiUrl}/v0/admin/routes/${id}`;
        const result = await get(url);
        const { route } = result.data;

        return route;
      },
      async problemPhoneNumbers(id: string) {
        const url = `${apiUrl}/v0/admin/routes/${id}/problemPhoneNumbers`;
        const { data } = await get(url);

        return data;
      },
      async problemEmails(id: string) {
        const url = `${apiUrl}/v0/admin/routes/${id}/problemEmails`;
        const { data } = await get(url);

        return data;
      },
      async delete(id: string) {
        const url = `${apiUrl}/v0/admin/routes/${id}`;
        const { data } = await httpDelete(url);
        return data;
      },
      async undelete(id: string) {
        const url = `${apiUrl}/v0/admin/routes/${id}/undelete`;
        const { data } = await post(url);
        return data;
      },
      async setAutoSchedule(id: string, setting: boolean) {
        const url = `${apiUrl}/v0/admin/routes/${id}/setAutoSchedule`;
        const { data } = await post(url, { setting });
        return data;
      },
      async updateWaypointStatus(id: string, waypointId: string, payload: WaypointStatusOptions) {
        const url = `${apiUrl}/v0/admin/routes/${id}/waypoints/${encodeURIComponent(
          waypointId
        )}/updateStatus`;
        const { data } = await post(url, payload);
        return data;
      },
      async waypointImage(id: string, waypointId: string, imageType?: string, itemNumber?: number) {
        const url = `${apiUrl}/v0/admin/routes/${id}/waypoints/${encodeURIComponent(
          waypointId
        )}/image`;
        const result = await get(url, { params: { imageType, itemNumber } });
        const { image } = result.data;

        return image;
      },
      async waypointTextMessageLogs(id: string, waypointId: string) {
        const url = `${apiUrl}/v0/admin/routes/${id}/waypoints/${encodeURIComponent(
          waypointId
        )}/textMessageLogs`;
        const { data } = await get(url);

        return data;
      },
      async waypointEmailLogs(id: string, waypointId: string) {
        const url = `${apiUrl}/v0/admin/routes/${id}/waypoints/${encodeURIComponent(
          waypointId
        )}/emailLogs`;
        const { data } = await get(url);

        return data;
      },
      async sendOffer(
        id: string,
        courierId: string,
        { toPickup, afterRoute }: Record<string, unknown>
      ) {
        const url = `${apiUrl}/v0/admin/routes/${id}/sendOffer`;
        const result = await post(url, { courierId, toPickup, afterRoute });
        const { route } = result.data;

        return route;
      },
      async sendOfferBundle(courierId: string, bundle: Record<string, unknown>) {
        const url = `${apiUrl}/v0/admin/routes/sendOfferBundle`;
        const { data } = await post(url, { courierId, bundle });
        return data;
      },
      async sendOfferCourierBundle(routeId: string, bundle: Record<string, unknown>) {
        const url = `${apiUrl}/v0/admin/routes/sendOfferCourierBundle`;
        const { data } = await post(url, { routeId, bundle });
        return data;
      },
      async v2Pay(id: string, values: unknown) {
        const url = `${apiUrl}/v0/admin/routes/${id}/v2Pay`;
        const { data } = await post(url, values);

        return data;
      },
      async unassignCourierOffer(id: string, courierId: string) {
        const url = `${apiUrl}/v0/admin/routes/${id}/unassignCourierOffer/${courierId}`;
        const { data } = await post(url);

        return data;
      },
      async cancelRouteOffer(id: string) {
        const url = `${apiUrl}/v0/admin/routes/${id}/cancelRouteOffer`;
        const { data } = await post(url);

        return data;
      },
      async markRoutePicked(id: string) {
        const url = `${apiUrl}/v0/admin/routes/${id}/markRoutePicked`;
        const { data } = await post(url);

        return data;
      },
      async unassignAllOffers(id: string) {
        const url = `${apiUrl}/v0/admin/routes/${id}/unassignAllOffers`;
        const { data } = await post(url);

        return data;
      },
      async getAdjustmentsParameters(id: string) {
        const url = `${apiUrl}/v0/admin/routes/${id}/getAdjustmentsParameters`;
        const { data } = await get(url);
        return data;
      },
      async assignError(id: string, options: { type: string; description: string }) {
        const url = `${apiUrl}/v0/admin/routes/${id}/assignError`;
        const { data } = await post(url, options);

        return data;
      },
      async updateCapabilities(id: string, options: unknown) {
        const url = `${apiUrl}/v0/admin/routes/${id}/updateCapabilities`;
        const { data } = await post(url, options);

        return data;
      },
      async changePickupTime(
        id: string,
        waypointId: string,
        endOfTimeWindow: string,
        applyToAllPickups?: boolean
      ) {
        const url = `${apiUrl}/v0/admin/routes/${id}/waypoints/${encodeURIComponent(
          waypointId
        )}/changePickupTime`;
        const { data } = await post(url, { endOfTimeWindow, applyToAllPickups });

        return data;
      },
      async changePickupAddress(id: string, waypointId: string, address: unknown) {
        const url = `${apiUrl}/v0/admin/routes/${id}/waypoints/${encodeURIComponent(
          waypointId
        )}/changePickupAddress`;
        const { data } = await post(url, { address });

        return data;
      },
      async resolveError(
        id: string,
        errorId: string,
        options: { reason: string; bumpupAmount: number; internal: boolean }
      ) {
        const url = `${apiUrl}/v0/admin/routes/${id}/resolveError/${errorId}`;
        const { data } = await post(url, options);

        return data;
      },
      async deleteError(id: string, errorId: string) {
        const url = `${apiUrl}/v0/admin/routes/${id}/deleteError/${errorId}`;
        const { data } = await post(url);

        return data;
      },
      async calculateLegs(id: string) {
        const url = `${apiUrl}/v0/admin/routes/${id}/calculateLegs`;
        const { data } = await post(url);

        return data;
      },
      async reindexRoute(id: string) {
        const url = `${apiUrl}/v0/admin/routes/${id}/reindexRoute`;
        const { data } = await post(url);

        return data;
      },
      async assignComponents(id: string) {
        const url = `${apiUrl}/v0/admin/routes/${id}/assignComponents`;
        const { data } = await post(url);

        return data;
      },
      async takeSnapshot(id: string) {
        const url = `${apiUrl}/v0/admin/routes/${id}/takeSnapshot`;
        const result = await post(url);
        const { routeId } = result.data;

        return routeId;
      },
      async restoreSnapshot(id: string) {
        const url = `${apiUrl}/v0/admin/routes/${id}/restoreSnapshot`;
        const result = await post(url);
        const { routeId } = result.data;

        return routeId;
      },
      async courierLocations(id: string) {
        const url = `${apiUrl}/v0/admin/routes/${id}/courierLocations`;
        const { data } = await get(url);

        return data;
      },
      async resetWaypoint(id: string, waypointId: string) {
        const url = `${apiUrl}/v0/admin/routes/${id}/waypoints/${encodeURIComponent(
          waypointId
        )}/reset`;
        const result = await post(url);
        const { route } = result.data;

        return route;
      },
      async updateWaypoints(id: string, payload: unknown) {
        const url = `${apiUrl}/v0/admin/routes/${id}/waypoints/update`;
        const { data } = await post(url, payload);

        return data;
      },
      async updateWaypointsPreview(id: string, payload: unknown) {
        const url = `${apiUrl}/v0/admin/routes/${id}/waypoints/updatePreview`;
        const { data } = await post(url, payload);

        return data;
      },
      async mergePreview(batchIds: string[]) {
        const url = `${apiUrl}/v0/admin/routes/mergePreview`;
        const { data } = await post(url, { batchIds });
        return data;
      },
      async merge(batchIds: string[]) {
        const url = `${apiUrl}/v0/admin/routes/merge`;
        const { data } = await post(url, { batchIds });
        return data;
      },
      async search(searchText: string) {
        const url = `${apiUrl}/v0/admin/routes/search?searchText=${searchText}`;
        const { data } = await get(url);
        return data;
      },
      async deletedSearch(searchText: string) {
        const url = `${apiUrl}/v0/admin/routes/deletedSearch?searchText=${searchText}`;
        const { data } = await get(url);
        return data;
      },
      async searchRoutes(searchText: string, size?: number) {
        const url = `${apiUrl}/v0/admin/routes/searchRoutes?size=${size}&searchText=${searchText}`;
        const { data } = await get(url);
        return data;
      },
      async searchWaypoints(searchText: string, size?: number) {
        const url = `${apiUrl}/v0/admin/routes/searchWaypoints?size=${size}&searchText=${searchText}`;
        const { data } = await get(url);
        return data;
      },

      async getTimezone(location: string, timestamp: number, fsa: string) {
        const url = `${apiUrl}/v0/admin/routes/getTimezone?location=${location}&timestamp=${timestamp}&fsa=${fsa}`;
        const { data } = await get(url);
        return data;
      },
    },
    auth: {
      async login(email: string, password: string) {
        try {
          const url = `${apiUrl}/v0/admin/auth/login`;
          const result = await post(url, { user: { email, password } });

          if (result.status) {
            return result.data.token;
          }

          return false;
        } catch (e) {
          if (e.name === 'NetworkConnectionError') throw e;
          return false;
        }
      },

      async forgotPassword(user: unknown) {
        const url = `${apiUrl}/v0/admin/auth/forgotPassword`;
        const { data } = await post(url, { user });
        return data;
      },

      async currentUser() {
        const url = `${apiUrl}/v0/admin/auth/me`;
        const result = await get(url);
        const { user } = result.data;

        return user;
      },
    },
    incidents: {
      async createIncident(incident: unknown) {
        const url = `${apiUrl}/v0/admin/incidents/createIncident`;
        const { data } = await post(url, { incident });
        return data;
      },
      async deleteIncident(id: number) {
        const url = `${apiUrl}/v0/admin/incidents/${id}/deleteIncident`;
        const { data } = await post(url);
        return data;
      },
      async takeAction(id: number, action: unknown) {
        const url = `${apiUrl}/v0/admin/incidents/${id}/takeAction`;
        const { data } = await post(url, { action });
        return data;
      },
      async closeIncident(id: number, reason: unknown) {
        const url = `${apiUrl}/v0/admin/incidents/${id}/closeIncident`;
        const { data } = await post(url, { reason });
        return data;
      },
      async getIncidentsParameters() {
        const url = `${apiUrl}/v0/admin/incidents/getIncidentsParameters`;
        const { data } = await get(url);
        return data;
      },
      async getRouteIncidents(routeId: string) {
        const url = `${apiUrl}/v0/admin/incidents/routes/${routeId}/routeIncidents`;
        const { data } = await get(url);
        return data;
      },
      async getCourierIncidents(courierId: string) {
        const url = `${apiUrl}/v0/admin/incidents/couriers/${courierId}/courierIncidents`;
        const { data } = await get(url);
        return data;
      },
      async searchIncidents(values: unknown) {
        const url = `${apiUrl}/v0/admin/incidents/searchIncidents`;
        const { data } = await post(url, values);
        return data;
      },
    },
  };

  offers = {
    async get(id: string) {
      const url = `${apiUrl}/v0/routes/${id}/offer`;
      const { data } = await get(url);
      return data;
    },
    async accept(id: string, payload: unknown) {
      const url = `${apiUrl}/v0/routes/${id}/offer/accept`;
      const { data } = await post(url, payload);
      return data;
    },
    async decline(id: string, payload?: unknown) {
      const url = `${apiUrl}/v0/routes/${id}/offer/decline`;
      const { data } = await post(url, payload);
      return data;
    },
  };

  routes = {
    async all() {
      const url = `${apiUrl}/v0/routes/`;
      const { data } = await get(url);
      return data;
    },
    async get(id: string) {
      const url = `${apiUrl}/v0/routes/${id}`;
      const { data } = await get(url);
      return data;
    },
    async missedOffers() {
      const url = `${apiUrl}/v0/routes/missedOffers`;
      const { data } = await get(url);
      return data;
    },
    async cancelRouteOffer(id: string, payload: unknown) {
      const url = `${apiUrl}/v0/routes/${id}/cancelRouteOffer`;
      const { data } = await post(url, payload);
      return data;
    },
    async start(id: string, payload: unknown) {
      const url = `${apiUrl}/v0/routes/${id}/start`;
      const { data } = await post(url, payload);
      return data;
    },
    async markWaypointArrived(id: string, waypointId: string, payload: unknown) {
      const url = `${apiUrl}/v0/routes/${id}/waypoints/${encodeURIComponent(
        waypointId
      )}/markArrived`;
      const { data } = await post(url, payload);
      return data;
    },
    async endAt(id: string, address: string, currentLocation: Location) {
      const url = `${apiUrl}/v0/routes/${id}/endAt`;
      const { data } = await post(url, { address, currentLocation });
      return data;
    },
    async endAtOffer(id: string, address: string, currentLocation: Location) {
      const url = `${apiUrl}/v0/routes/${id}/endAtOffer`;
      const { data } = await post(url, { address, currentLocation });
      return data;
    },
    async markWaypointPicked(id: string, waypointId: string, payload: unknown) {
      const url = `${apiUrl}/v0/routes/${id}/waypoints/${encodeURIComponent(
        waypointId
      )}/markPicked`;
      const { data } = await post(url, payload);
      return data;
    },
    async updateWaypointStatus(id: string, waypointId: string, payload: WaypointStatusOptions) {
      const url = `${apiUrl}/v0/routes/${id}/waypoints/${encodeURIComponent(
        waypointId
      )}/updateStatus`;
      const { data } = await post(url, payload);
      return data;
    },
    async updatePhoto(
      id: string,
      waypointId: string,
      payload: { image: string; itemNumber?: number; imageType?: string }
    ) {
      const url = `${apiUrl}/v0/routes/${id}/waypoints/${encodeURIComponent(
        waypointId
      )}/updatePhoto`;
      const { data } = await post(url, payload);
      return data;
    },
    async uploadPhotoUrl(id: string, waypointId: string) {
      const url = `${apiUrl}/v0/routes/${id}/waypoints/${encodeURIComponent(
        waypointId
      )}/uploadPhoto`;
      return url;
    },
    async waypointContacted(id: string, waypointId: string, type: 'message' | 'call') {
      const url = `${apiUrl}/v0/routes/${id}/waypoints/${encodeURIComponent(waypointId)}/contacted`;
      const { data } = await post(url, { type });
      return data;
    },
    async waypointSendSms(id: string, waypointId: string) {
      const url = `${apiUrl}/v0/routes/${id}/waypoints/${encodeURIComponent(waypointId)}/sendSms`;
      const { data } = await post(url);
      return data;
    },
    async getPickupAddressImage(id: string, waypointId: string) {
      const url = `${apiUrl}/v0/routes/${id}/waypoints/${encodeURIComponent(
        waypointId
      )}/pickupAddressImage`;
      const { data } = await get(url);
      return data;
    },
  };

  income = {
    async all(start: unknown, end: unknown) {
      const url = `${apiUrl}/v0/income`;
      const { data } = await get(url, {
        params: { start, end },
      });
      return data;
    },

    async expeditedPayoutRequest(requestedAmount: number) {
      const url = `${apiUrl}/v0/income/expeditedPayoutRequest`;
      const { data } = await post(url, {
        requestedAmount,
      });
      return data;
    },

    async currentBalance() {
      const url = `${apiUrl}/v0/income/currentBalance`;
      const { data } = await get(url);
      return data;
    },

    async veemStatus(weekCounter: number) {
      const url = `${apiUrl}/v0/income/veemStatus?weekCounter=${weekCounter}`;
      const { data } = await get(url);
      return data;
    },
  };

  availability = {
    async all(day: unknown) {
      const url = `${apiUrl}/v0/availability?day=${day}`;
      const { data } = await get(url);
      return data;
    },

    async setShift(day: unknown, start: unknown, end: unknown) {
      const url = `${apiUrl}/v0/availability/set?day=${day}&start=${start}&end=${end}`;
      const { data } = await post(url);
      return data;
    },

    async deleteShift(id: unknown) {
      const url = `${apiUrl}/v0/availability/delete?id=${id}`;
      const { data } = await httpDelete(url);
      return data;
    },
  };
}

const apiService = new ApiService();

export { apiService };
