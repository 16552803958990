<template>
  <div class="px-4 sm:px-6 md:px-0">
    <div class="mb-4 flex space-x-4">
      <GoTextField
        :label="false"
        type="search"
        name="search"
        v-model="filters.searchText"
        placeholder="Search"
        class="w-10/12"
      />
      <GoTextField :label="false" type="number" name="size" v-model="filters.size" class="w-1/12" />
    </div>

    <div class="grid grid-cols-4 gap-2 mb-2">
      <nav
        v-for="[field, states] of Object.entries(filterFields)"
        :key="field"
        class="mb-2 col-span-1"
        :aria-label="field"
      >
        <div class="flex space-x-4">
          <GoMultiselect
            placeholder="All"
            :options="states"
            :mode="multiFilters.includes(field) ? 'multiple' : 'single'"
            :name="`filters.${field}`"
            v-model="filters[field]"
            :label="startCase(field)"
          />
        </div>
      </nav>
    </div>
    <nav class="mb-2 flex items-center" aria-label="Sort">
      <div class="text-gray-400 px-3 py-2 font-medium text-sm rounded-md w-20">Sort</div>
      <div class="flex flex-wrap gap-4">
        <router-link
          v-for="sortField in [
            'signedUpAt',
            'documentUpdatedAt',
            'lastRouteDate',
            'totalRoutes',
            'tier',
          ]"
          :key="sortField"
          :to="{
            query: {
              ...$route.query,
              cs: {
                by: sortField,
                order:
                  sortField === 'tier'
                    ? sort.by === sortField && sort.order === 'asc'
                      ? 'desc'
                      : 'asc'
                    : sort.by === sortField && sort.order === 'desc'
                    ? 'asc'
                    : 'desc',
              },
            },
          }"
          class="text-gray-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md"
          :class="sort.by === sortField && 'bg-gray-200 dark:text-gray-800'"
        >
          {{ startCase(sortField) }}
        </router-link>
      </div>
    </nav>
    <div class="mb-4 flex flex-wrap gap-4">
      <GoTextField
        label="false"
        name="address"
        placeholder="Filter by address"
        class="w-10/12"
        google-places
        @place-selected="placeSelected"
        type="search"
        v-model="address"
      />
      <GoTextField
        :label="false"
        type="number"
        name="radius"
        placeholder="km"
        v-model="filters.radius"
        class="w-1/12"
      />
    </div>
    <nav class="flex flex-wrap gap-4 mb-2" aria-label="Filter Options">
      <span class="text-gray-400 px-3 py-2 font-medium text-sm rounded-md w-16">Fake Filters</span>
      <GoCheckbox name="hideAccepted" label="Hide Accepted" v-model="hideAccepted" :value="true" />
      <GoCheckbox name="hideOffer" label="Hide Offer" v-model="hideOffer" :value="true" />
      <GoCheckbox
        name="hideInProgress"
        label="Hide In Progress"
        v-model="hideInProgress"
        :value="true"
      />
      <GoCheckbox name="hideDeclined" label="Hide Declined" v-model="hideDeclined" :value="true" />
      <GoCheckbox
        name="hideCompleted"
        label="Hide Completed"
        v-model="hideCompleted"
        :value="true"
      />
    </nav>
    <div v-if="dateForDay[currentDay]" class="mb-4 flex flex-col space-y-4">
      <div class="flex flex-wrap gap-4 items-end p-4 bg-white dark:bg-gray-800 rounded-md">
        <GoTextField
          label="For Route"
          type="search"
          placeholder="Select route on route side"
          name="schedulingRouteId"
          v-model="schedulingRouteId"
          :disabled="!schedulingRouteId"
          class="w-2/3"
        />
        <GoTextField
          label="Radius"
          type="number"
          name="forRouteRadius"
          placeholder="km"
          v-model="forRouteRadius"
          class="w-1/6"
        />
        <GoCheckbox
          name="forRouteSortByDistance"
          label="Sort By Distance"
          v-model="forRouteSortByDistance"
          :value="true"
          class="w-1/6 mb-2"
        />
        <GoCheckbox
          name="forRouteFilterByPickup"
          label="Filter by Pickup"
          v-model="forRouteFilterByPickup"
          :value="true"
          class="w-1/6 mb-2"
        />
        <GoCheckbox
          name="forRouteFilterByAcceptedOverlap"
          label="Filter by Accepted Overlap"
          v-model="forRouteFilterByAcceptedOverlap"
          :value="true"
          class="w-1/6 mb-2"
        />
        <GoCheckbox
          name="forRouteHideDeclined"
          label="Hide Declined"
          v-model="forRouteHideDeclined"
          :value="true"
          class="w-1/6 mb-2"
        />
        <GoCheckbox
          name="forRouteHideOffer"
          label="Hide Offer"
          v-model="forRouteHideOffer"
          :value="true"
          class="w-1/6 mb-2"
        />
      </div>
    </div>
    <div class="mt-4">
      <div class="w-full mb-4" style="height: 60vh" v-if="showMap">
        <l-map ref="map" @ready="setMap(couriersList, 'map')" :zoom="zoom">
          <MapTiles />
          <DrawMapRoute v-if="forRoute" :route="forRoute" link="admin.route" />
          <l-marker
            v-for="courier of couriersList"
            :key="courier.id"
            :lat-lng="[courier.lat, courier.lng]"
          >
            <l-icon>
              <component
                :is="courierIcon(courier).icon"
                class="opacity-60"
                :class="courierIcon(courier).styles"
              />
            </l-icon>
            <l-popup :options="{ maxWidth: 650, maxHeight: 400 }">
              <div class="overflow-y-scroll h-full w-full max-h-96">
                <CourierCard :courier="courier" :routeDate="dateForDay[currentDay]">
                  <template #bottom>
                    <div class="flex flex-col space-y-1 pt-2">
                      <div
                        v-for="routeGroup of [
                          {
                            routes: assignedRoutes[courier.id],
                            type: 'assigned',
                            badge: 'accepted',
                            getRoute: r => r,
                          },
                          {
                            routes: declinedRouteIdsForCourier(courier.id),
                            type: 'declined',
                            badge: 'declined',
                            getRoute: r => routesById[r],
                          },
                          {
                            routes: offeredRouteIdsForCourier(courier.id),
                            type: 'offer',
                            badge: 'offer',
                            getRoute: r => routesById[r],
                          },
                        ]"
                        :key="routeGroup.type"
                      >
                        <div
                          v-if="routeGroup.routes?.length"
                          class="flex flex-row items-center space-x-2 w-full mb-2"
                        >
                          <GoButton
                            :class="routeBadgeStateStyle(routeGroup.badge)"
                            @click="
                              showRoutes[routeGroup.type + courier.id] = !showRoutes[
                                routeGroup.type + courier.id
                              ]
                            "
                            variant="badge"
                          >
                            {{ showRoutes[routeGroup.type] ? 'Hide' : 'Show' }}
                            {{ routeGroup.routes.length }} {{ routeGroup.type
                            }}{{ routeGroup.routes.length === 1 ? '' : 's' }}
                          </GoButton>
                          <GoBadge
                            v-if="forRoute && routeGroup.routes.includes(forRoute.id)"
                            class="bg-brand-2 text-gray-50"
                          >
                            Selected
                          </GoBadge>
                        </div>
                        <template v-if="showRoutes[routeGroup.type + courier.id]">
                          <div
                            v-for="route of routeGroup.routes"
                            :key="routeGroup.getRoute(route).id"
                          >
                            <RouteCardCondensed
                              :route="routeGroup.getRoute(route)"
                              :state="
                                routeGroup.type === 'assigned'
                                  ? routeGroup.getRoute(route).state
                                  : routeGroup.type
                              "
                              :courier="courier"
                            />
                          </div>
                        </template>
                      </div>
                    </div>
                    <slot name="bottom" :courier="courier" :route="forRoute"></slot>
                  </template>
                </CourierCard>
              </div>
            </l-popup>
          </l-marker>
          <l-marker v-if="filters.geoPin" :lat-lng="filters.geoPin.split(',')">
            <l-icon>
              <IconStar class="w-7 text-green-500" />
            </l-icon>
          </l-marker>
          <l-marker v-if="forRouteCenter" :lat-lng="forRouteCenter.geometry.coordinates">
            <l-icon>
              <IconStar class="w-7 text-blue-500" />
            </l-icon>
          </l-marker>
        </l-map>
      </div>
      <GoButton variant="link" class="text-xs" @click="showMap = !showMap">
        {{ showMap ? 'Hide' : 'Show' }} Map
      </GoButton>
    </div>
    <div class="flex items-center justify-between mt-4">
      <GoButton v-if="couriers" variant="link" class="text-xs" @click="downloadCsv">
        Download CSV ({{ couriersList.length }})
      </GoButton>
      <div class="flex items-center">
        <IconLoader v-show="searchLoading" class="w-5 mr-4 animate-spin" />
        <div v-if="couriers" class="text-sm">
          Showing {{ couriersList.length }} of {{ couriersTotal }}
        </div>
      </div>
    </div>

    <div class="py-4">
      <CourierCard
        :courier="courier"
        v-for="courier of couriersList.slice(0, couriersToShow)"
        :key="courier.id"
        @loadCourier="loadCourier"
        :routeDate="dateForDay[currentDay]"
      >
        <template #bottom>
          <div class="flex flex-col space-y-1 pt-2">
            <div
              v-for="routeGroup of [
                {
                  routes: assignedRoutes[courier.id],
                  type: 'assigned',
                  badge: 'accepted',
                  getRoute: r => r,
                },
                {
                  routes: declinedRouteIdsForCourier(courier.id),
                  type: 'declined',
                  badge: 'declined',
                  getRoute: r => routesById[r],
                },
                {
                  routes: offeredRouteIdsForCourier(courier.id),
                  type: 'offer',
                  badge: 'offer',
                  getRoute: r => routesById[r],
                },
              ]"
              :key="routeGroup.type"
            >
              <div
                v-if="routeGroup.routes?.length"
                class="flex flex-row items-center space-x-2 w-full mb-2"
              >
                <GoButton
                  :class="routeBadgeStateStyle(routeGroup.badge)"
                  @click="
                    showRoutes[routeGroup.type + courier.id] = !showRoutes[
                      routeGroup.type + courier.id
                    ]
                  "
                  variant="badge"
                >
                  {{ showRoutes[routeGroup.type] ? 'Hide' : 'Show' }}
                  {{ routeGroup.routes.length }} {{ routeGroup.type
                  }}{{ routeGroup.routes.length === 1 ? '' : 's' }}
                </GoButton>
                <GoBadge
                  v-if="forRoute && routeGroup.routes.includes(forRoute.id)"
                  class="bg-brand-2 text-gray-50"
                >
                  Selected
                </GoBadge>
              </div>
              <template v-if="showRoutes[routeGroup.type + courier.id]">
                <div v-for="route of routeGroup.routes" :key="routeGroup.getRoute(route).id">
                  <RouteCardCondensed
                    :route="routeGroup.getRoute(route)"
                    :state="
                      routeGroup.type === 'assigned'
                        ? routeGroup.getRoute(route).state
                        : routeGroup.type
                    "
                    :courier="courier"
                  />
                </div>
              </template>
            </div>
          </div>
          <slot name="bottom" :courier="courier" :route="forRoute"></slot>
        </template>
      </CourierCard>
      <div
        v-if="couriersToShow < couriersList.length"
        class="flex items-center justify-center gap-4"
      >
        <GoButton variant="outline" class="text-sm" @click="numberOfCouriersToShow += 20">
          Show more couriers
        </GoButton>
        <GoButton
          variant="outline"
          class="text-sm"
          @click="numberOfCouriersToShow = couriersList.length"
        >
          Show all {{ couriersList.length }}
        </GoButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { LPolyline, LCircle, LMap, LIcon, LMarker, LPopup } from '@vue-leaflet/vue-leaflet';
import {
  apiService,
  CourierAgreementStates,
  CourierDocumentStates,
  CourierSignupStates,
  CourierStates,
  SchedulingGroups,
  AvailabilityStatuses,
  TypeOfCar,
  routeBadgeStateStyle,
  calculateDistance,
  centerOfPoints,
  isCourierAvailableForRoute,
  courierHasOverlappingRoute,
  Route,
} from '@tyltgo/shared';
import debounce from 'lodash/debounce';
import startCase from 'lodash/startCase';
import pick from 'lodash/pick';
import intersection from 'lodash/intersection';
import io from 'socket.io-client';
import dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import { useDocumentVisibility } from '@vueuse/core';
import { schedulingRoute, schedulingRouteId } from '../store/scheduling-store';
import MapTiles from './MapTiles.vue';
import DrawMapRoute from './DrawMapRoute.vue';
import Card from './CourierCard.vue';
import GoTextField from './GoTextField.vue';
import GoButton from './GoButton.vue';
import GoBadge from './GoBadge.vue';
import IconLoader from './IconLoader.vue';
import IconCar from './IconCar.vue';
import IconHatchback from './IconHatchback.vue';
import IconMinivan from './IconMinivan.vue';
import IconCargovan from './IconCargovan.vue';
import IconSuv from './IconSuv.vue';
import IconTruckPickup from './IconTruckPickup.vue';
import IconStar from './IconStar.vue';
import GoMultiselect from './GoMultiselect.vue';
import RouteCardCondensed from './RouteCardCondensed.vue';
import RouteCard from './RouteCard.vue';
import { loadCouriers, couriers, couriersTotal } from '../store/couriers-store';
import {
  assignedRoutesMap,
  declinedRoutes,
  loadRoutes,
  offeredRoutes,
  routes,
  routesById,
} from '../store/routes-store';
import { downloadBlob } from '../services/browser-service';
import { getRouteExcludedCouriers } from '../services/route-service';
import GoCheckbox from './GoCheckbox.vue';

const startingNumberOfCouriers = 20;

export default defineComponent({
  emits: ['couriersListChanged'],
  setup() {
    const visibilityState = useDocumentVisibility();
    return {
      visibilityState,
      routesById,
      couriers,
      couriersTotal,
      startCase,
      routes,
      schedulingRouteId,
      routeBadgeStateStyle,
    };
  },
  props: {
    days: {
      type: Array,
    },
    path: {
      type: String,
    },
    currentDay: {
      type: String,
    },
  },
  components: {
    RouteCard,
    IconCar,
    IconHatchback,
    IconCargovan,
    IconMinivan,
    IconSuv,
    IconTruckPickup,
    IconStar,
    LMap,
    LCircle,
    LPolyline,
    LIcon,
    LMarker,
    LPopup,
    MapTiles,
    DrawMapRoute,
    CourierCard: Card,
    GoTextField,
    IconLoader,
    GoMultiselect,
    GoButton,
    GoBadge,
    RouteCardCondensed,
    GoCheckbox,
  },
  async beforeMount() {
    this.setFilters();
    this.search();

    this.address = this.filters.address;

    this.socket = io(`${apiService.apiHost}?room=admin/couriers`, {
      transports: ['websocket', 'polling'],
    });
    this.routesSocket = io(`${apiService.apiHost}?room=admin/routes`, {
      transports: ['websocket', 'polling'],
    });
    this.socket.on('courier', debounce(this.search, 1000));
    this.routesSocket.on('route', debounce(this.updateRoutes, 1000));

    this.today = dayjs().format('dddd').toLowerCase();
    this.days.forEach((_, count) => {
      const datejs = dayjs().add(count, 'days');
      this.dateForDay[datejs.format('dddd').toLowerCase()] = datejs.format('YYYY-MM-DD');
    });
  },
  unmounted() {
    this.disconnectSockets();
  },
  data() {
    return {
      numberOfCouriersToShow: startingNumberOfCouriers,
      showRoutes: {},
      forRouteSortByDistance: true,
      forRouteFilterByPickup: true,
      forRouteFilterByAcceptedOverlap: true,
      forRouteHideDeclined: false,
      forRouteHideOffer: false,
      forRouteRadius: 100,
      showMap: false,
      zoom: 6,
      hideAccepted: false,
      hideOffer: false,
      hideInProgress: false,
      hideCompleted: false,
      hideDeclined: false,
      dateForDay: {},
      today: null,
      socket: null,
      routesSocket: null,
      address: '',
      searchLoading: false,
      filters: {} as Record<string, unknown>,
      sort: {} as Record<string, string>,
    };
  },
  watch: {
    couriersList(value) {
      this.$emit('couriersListChanged', value);
    },
    async visibilityState(state) {
      if (state === 'visible') {
        this.disconnectSockets();
        await this.search({ setMap: false });
        await this.updateRoutes();
        this.connectSockets();
      } else {
        this.disconnectSockets();
      }
    },
    $route(to, from) {
      let changed = false;
      if (!isEqual(to.query.cs, from.query.cs)) {
        changed = true;
      }
      if (changed || (to.name === this.path && from?.name !== this.path)) {
        this.setFilters();
      }
    },
    currentDay() {
      this.numberOfCouriersToShow = startingNumberOfCouriers;
      this.search({ setMap: true });
    },
    schedulingRouteId() {
      setTimeout(() => {
        this.numberOfCouriersToShow = startingNumberOfCouriers;
        if (this.schedulingRouteId) {
          this.setMap(this.couriersList, 'map');
        }
      }, 100);
    },
    forRouteRadius() {
      setTimeout(() => {
        if (this.schedulingRouteId) {
          this.setMap(this.couriersList, 'map');
        }
      }, 100);
    },
    filters: {
      handler: debounce(function filtersHandler(filters, _old) {
        this.numberOfCouriersToShow = startingNumberOfCouriers;
        this.$router.push({
          query: { ...this.$route.query, c: this.cleanQuery(filters) },
        });
        this.search({ setMap: true });
      }, 200),
      deep: true,
    },
    address(address: string) {
      if (address) return;
      this.filters.address = '';
      this.filters.geoPin = '';
    },
  },
  computed: {
    couriersToShow() {
      if (this.path === 'admin.couriers') return this.couriersList.length;
      return this.numberOfCouriersToShow;
    },
    offeredRoutesByCourier() {
      return this.offeredRoutes.reduce((acc, r) => {
        const courierIds = [...new Set(r.activeOffers.map(offer => offer.courierId))] as string[];
        courierIds.forEach(courierId => {
          acc[courierId] = acc[courierId] ? [...acc[courierId], r.id] : [r.id];
        });
        return acc;
      }, {});
    },
    declinedRoutesByCourier() {
      return this.declinedRoutes.reduce((acc, r) => {
        const courierIds = [...new Set(r.declinedOffers.map(offer => offer.courierId))] as string[];
        courierIds.forEach(courierId => {
          acc[courierId] = acc[courierId] ? [...acc[courierId], r.id] : [r.id];
        });
        return acc;
      }, {});
    },
    forRoute() {
      return schedulingRoute.value;
    },
    forRouteCenter() {
      if (!this.forRoute) return null;
      const box = centerOfPoints(this.forRoute.waypoints.map(w => [w.lat, w.lng]));
      return box;
    },
    assignedRoutes(): Record<string, Route[]> {
      if (this.currentDay === 'all') return {};
      return assignedRoutesMap.value;
    },
    declinedRoutes() {
      if (this.currentDay === 'all') return [];
      return declinedRoutes.value;
    },
    offeredRoutes() {
      if (this.currentDay === 'all') return [];
      return offeredRoutes.value;
    },
    couriersList() {
      if (!this.couriers) return [];
      let list = this.couriers.filter(courier => {
        if (this.hideAccepted && this.hasAssignedRoute(courier.id, 'accepted')) return false;
        if (this.hideInProgress && this.hasAssignedRoute(courier.id, 'inProgress')) return false;
        if (this.hideCompleted && this.hasAssignedRoute(courier.id, 'completed')) return false;
        if (this.forRoute) {
          const courierCapabilities = courier.settings.capabilities || [];
          if (courier.typeOfCar) {
            courierCapabilities.push(courier.typeOfCar);
          }
          if (courier.tier < 3) {
            courierCapabilities.push('watchlisted', 'newMerchant');
          }
          if (courier.tier < 2) {
            courierCapabilities.push('tierOne');
          }
          if (this.forRoute.capabilitiesNeeded?.length) {
            const capabilities = intersection(
              this.forRoute.capabilitiesNeeded,
              courierCapabilities
            );
            if (this.forRoute.capabilitiesNeeded.length !== capabilities.length) return false;
          }
          if (this.forRouteFilterByPickup) {
            const available = isCourierAvailableForRoute(courier, this.forRoute, this.currentDay);
            if (!available) return false;
          }

          if (this.forRouteFilterByAcceptedOverlap) {
            const courierAssignedRoutes = this.assignedRoutes[courier.id] || [];
            const overlappingRoute = courierHasOverlappingRoute(
              this.forRoute,
              courierAssignedRoutes
            );
            if (overlappingRoute) return false;
          }

          if (this.forRouteHideDeclined) {
            const declined = this.forRoute.declinedOffers.find(
              offer => offer.courierId === courier.id
            );
            if (declined) return false;
          }

          if (this.forRouteHideOffer) {
            const offer = this.forRoute.activeOffers.find(o => o.courierId === courier.id);
            if (offer) return false;
          }

          const excludedCouriers = getRouteExcludedCouriers(this.forRoute);
          if (excludedCouriers.includes(courier.id)) return false;

          const distance = calculateDistance(
            [courier.lat, courier.lng],
            this.forRouteCenter.geometry.coordinates,
            'kilometers'
          );
          if (distance > this.forRouteRadius) return false;
        }
        if (this.hideOffer) {
          const show = !this.offeredRoutes.find(r =>
            r.activeOffers.find(offer => offer.courierId === courier.id)
          );
          if (!show) return false;
        }
        if (this.hideDeclined) {
          const show = !this.declinedRoutes.find(r =>
            r.declinedOffers.find(offer => offer.courierId === courier.id)
          );
          if (!show) return false;
        }
        return true;
      });

      if (this.forRoute && this.forRouteSortByDistance) {
        list = sortBy(list, courier => {
          const toPickup = calculateDistance(
            [courier.lat, courier.lng],
            this.forRoute.waypoints[0],
            'kilometers'
          );
          const afterRoute = calculateDistance(
            [courier.lat, courier.lng],
            this.forRoute.waypoints[this.forRoute.waypoints.length - 1],
            'kilometers'
          );
          return toPickup + afterRoute;
        });
      }
      return list;
    },
    multiFilters() {
      return ['state', 'typeOfCar', 'tier'];
    },
    filterFields() {
      return {
        state: CourierStates,
        signupState: CourierSignupStates,
        agreementsState: CourierAgreementStates,
        documentsState: CourierDocumentStates,
        typeOfCar: TypeOfCar,
        schedulingGroup: SchedulingGroups,
        availabilityStatus: AvailabilityStatuses,
        tier: {
          '1': 1,
          '2': 2,
          '3': 3,
          '4': 4,
          '5': 5,
          '6': 6,
          '7': 7,
        },
      };
    },
  },
  methods: {
    declinedRouteIdsForCourier(courierId: string) {
      return this.declinedRoutesByCourier[courierId] || [];
    },
    offeredRouteIdsForCourier(courierId: string) {
      return this.offeredRoutesByCourier[courierId] || [];
    },
    placeSelected(place, _name) {
      this.filters.address = place.formatted_address;
      this.filters.geoPin = `${place.geometry.location.lat()},${place.geometry.location.lng()}`;
    },
    cleanQuery(query) {
      return Object.entries(query).reduce((clean, [key, value]) => {
        if (!value) return clean;
        return {
          ...clean,
          [key]: value,
        };
      }, {});
    },
    setFilters() {
      this.sort = {
        by: 'signedUpAt',
        order: 'desc',
        ...(this.$route.query.cs || {}),
      };
      this.filters = {
        radius: 50,
        size: this.$route.name === 'admin.scheduling' ? 1500 : 400,
        ...(this.$route.query.c || {}),
      };
    },
    newFilters(diff) {
      const newFilters = Object.keys(diff).reduce(
        (filters, key) => {
          return {
            ...filters,
            [key]: diff[key],
          };
        },
        { ...this.filters }
      );
      return newFilters;
    },
    search: debounce(async function search({ setMap }: { setMap?: boolean }) {
      this.searchLoading = true;
      const filters = Object.entries(pick(this.filters, Object.keys(this.filterFields))).reduce(
        (line, [key, value]) => {
          if (!value) return line;
          if (Array.isArray(value) && !value.length) return line;
          const values = Array.isArray(value) ? value : [value];
          const newLine = values.map(v => `${key}:"${v}"`).join(' OR ');
          return `${line} (${newLine})`;
        },
        ''
      );
      await loadCouriers(`${this.filters.searchText || '*'}`, {
        fixedSearchText: filters,
        currentDay: this.currentDay,
        sortBy: this.sort.by,
        sortOrder: this.sort.order,
        radius: this.filters.radius,
        geoPin: this.filters.geoPin,
        size: this.filters.size,
      });
      await this.updateRoutes();
      if (setMap && this.showMap) {
        this.setMap(this.couriersList, 'map');
      }

      this.searchLoading = false;
    }, 400),
    async updateRoutes() {
      if (this.currentDay === 'all') return;
      if (this.path === 'admin.couriers') {
        const date = this.dateForDay[this.currentDay];
        loadRoutes(date);
      }
    },
    loadCourier(_email: string) {
      this.search(this.filters.searchText);
    },
    hasAssignedRoute(courierId: string, state: string) {
      const assigned: Route[] = this.assignedRoutes[courierId] || [];
      const has = assigned.find(route => route.state === state);
      return !!has;
    },
    downloadCsv() {
      const header = [
        'First Name',
        'Last Name',
        'Email',
        'Phone Number',
        'Address',
        'State',
        'Documents State',
        'Agreements State',
        'Signup Date',
        'Last Route Date',
        'Total Routes',
        'Car Type',
        'Tier',
      ].join(',');
      const lines = this.couriersList.map(courier => {
        const line = [
          courier.firstName,
          courier.lastName,
          courier.email,
          courier.phoneNumber,
          courier.address,
          courier.state,
          courier.documentsState,
          courier.agreementsState,
          courier.signedUpAt,
          courier.lastRouteDate,
          courier.totalRoutes,
          courier.typeOfCar,
          courier.tier,
        ]
          .map(item => (item === null || item === undefined ? '' : item))
          .map(item => `${item}`)
          .map(item => `"${item.replace(/"/g, '')}"`)
          .join(',');
        return line;
      });
      const csv = [header].concat(lines).join('\n');
      const blob = new Blob([csv], { type: 'text/csv' });
      downloadBlob(blob, `couriers-list-${new Date()}.csv`);
    },
    setMap(list, refName) {
      const map = this.$refs[refName];
      if (!map) return;
      const showList = list.filter(l => l.lat && l.lng);
      if (showList.length) {
        map.leafletObject.fitBounds(showList, { maxZoom: 12 });
      }
      map.leafletObject.scrollWheelZoom.disable();
    },
    courierIcon(courier) {
      const assigned = (this.assignedRoutes[courier.id] || []).filter(r => r.state !== 'completed')
        .length;
      const color = assigned ? 'text-indigo-600' : '';
      if (['Pick-Up Truck'].includes(courier.typeOfCar)) {
        return { icon: 'IconTruckPickup', styles: `w-8 ${color}` };
      }
      if (['SUV'].includes(courier.typeOfCar)) {
        return { icon: 'IconSuv', styles: `w-8 ${color}` };
      }
      if (['Hatchback'].includes(courier.typeOfCar)) {
        return { icon: 'IconHatchback', styles: `w-8 ${color}` };
      }
      if (['Minivan'].includes(courier.typeOfCar)) {
        return { icon: 'IconMinivan', styles: `w-8 ${color}` };
      }
      if (['Cargo Van'].includes(courier.typeOfCar)) {
        return { icon: 'IconCargovan', styles: `w-8 ${color}` };
      }
      return { icon: 'IconCar', styles: `w-4 ${color}` };
    },
    connectSockets() {
      if (this.socket) this.socket.connect();
      if (this.routesSocket) this.routesSocket.connect();
    },
    disconnectSockets() {
      if (this.socket) this.socket.disconnect();
      if (this.routesSocket) this.routesSocket.disconnect();
    },
  },
});
</script>
