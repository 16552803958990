/* eslint-disable import/prefer-default-export */
const baseClasses =
  'leading-normal text-center font-bold uppercase focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-primary-500 button hover:opacity-80';

const stylesFunction = (options: { padding: string }): Record<string, string[]> => {
  return {
    default: ['text-white', options.padding, baseClasses],
    'default-primary': ['bg-brand-2', 'text-white'],
    'default-primary-soft': ['bg-brand-3', 'text-brand-2'],
    'default-secondary': ['bg-brand-1', 'text-white'],
    'default-danger': ['bg-red-600', 'text-white'],
    'default-danger-light': ['bg-red-100', 'text-red-600'],
    'default-white': ['bg-white', 'text-white'],
    'default-warning': ['bg-yellow-600'],
    'default-success': ['bg-green-600'],
    'default-disabled': ['bg-gray-300', 'cursor-not-allowed'],
    outline: ['bg-white', 'border-2', options.padding, baseClasses],
    'outline-primary': ['border-brand-1', 'text-brand-1'],
    'outline-danger': ['border-red-600', 'text-red-600'],
    'outline-warning': ['border-yellow-600', 'text-yellow-600'],
    'outline-success': ['border-green-600', 'text-green-600'],
    'outline-disabled': ['border-gray-600', 'text-gray-600', 'cursor-not-allowed'],
    link: ['bg-transparent', 'border-0', baseClasses],
    'link-disabled': ['border-gray-600', 'text-gray-600', 'cursor-not-allowed'],
    'link-danger': ['text-red-700'],
    'link-warning': ['text-yellow-700'],
    'link-success': ['text-green-700'],
    badge: [
      'flex-none text-xs text-center px-2 py-1 rounded-sm inline-block uppercase font-light border hover:border-gray-400 dark:text-gray-300 dark:border-gray-300 dark:bg-gray-800',
    ],
    'badge-disabled': ['border-gray-600', 'text-gray-600', 'cursor-not-allowed'],
  };
};

interface ButtonStyleOptions {
  disabled: boolean;
  color:
    | 'primary'
    | 'primary-soft'
    | 'secondary'
    | 'danger'
    | 'danger-light'
    | 'warning'
    | 'success'
    | 'white';
  variant: 'default' | 'outline' | 'link' | 'badge';
  rounded: string;
  padding?: string;
}

export const buttonStyles = ({
  disabled,
  color,
  variant,
  rounded,
  padding,
}: ButtonStyleOptions) => {
  let list: string[] = [];
  const buttonColor = disabled ? 'disabled' : color;

  const colors = stylesFunction({ padding: padding || 'px-3 py-3' });
  list = list.concat(colors[variant] || []);
  list = list.concat(colors[`${variant}-${buttonColor}`] || []);
  list = list.concat(rounded || 'rounded-md');

  return list;
};
