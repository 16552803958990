<template>
  <div class="home">
    <div v-if="isDevelopment" class="flex flex-col max-w-xl mx-auto space-y-4 mt-12">
      <GoButton as="router-link" :to="{ name: 'admin.login' }" variant="outline">
        Admin Login
      </GoButton>
      <GoButton as="router-link" :to="{ name: 'merchant.login' }" variant="outline">
        Merchant Login
      </GoButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import GoButton from '../components/GoButton.vue';

export default defineComponent({
  components: {
    GoButton,
  },
  computed: {
    isDevelopment() {
      return import.meta.env.MODE === 'development';
    },
  },
  mounted() {
    if (!this.isDevelopment) {
      window.location.href = 'https://tyltgo.com';
    }
  },
});
</script>
