<template>
  <main class="flex-1 overflow-y-auto focus:outline-none" tabindex="0">
    <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
      <div class="pt-10 pb-8">
        <div class="px-4 sm:px-6 md:px-0 mb-8">
          <GoHeader :level="2">Pending Orders</GoHeader>
        </div>
        <nav class="flex flex-wrap gap-4 mb-4" aria-label="Filter Options">
          <span
            class="text-gray-400 dark:text-gray-300 px-3 py-2 font-medium text-sm rounded-md w-16"
          >
            Filter
          </span>
          <GoMultiselect
            :style="`max-width: 200px`"
            placeholder="All"
            label="Submitted Date"
            name="filters.submittedDate"
            v-model="filters.submittedDate"
            :options="submittedDates"
            :searchable="true"
          />
        </nav>
        <GoHeader :level="5" class="mb-4">
          {{ filteredOrders?.length ? filteredOrders?.length : '' }} Pending Order{{
            filteredOrders?.length === 1 ? '' : 's'
          }}
        </GoHeader>
        <div class="px-4 sm:px-6 md:px-0">
          <template v-if="unroutedOrders && unroutedOrders.orders.length">
            <MerchantUnroutedOrders
              :unroutedOrders="filteredOrders"
              @updateUnroutedOrders="loadOrders"
            />
          </template>
          <template v-else>
            <div class="bg-white dark:bg-gray-800 shadow rounded-lg mb-4 flex flex-col p-4">
              No Pending Orders
            </div>
          </template>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { apiService } from '@tyltgo/shared';
import dayjs from 'dayjs';
import { currentUser } from '../../services/auth-service';

export default defineComponent({
  setup(props) {
    const route = useRoute();
    const storeName = props.storeName || route.params.storeName;
    const store = currentUser.value.shopifyStores.find(s => s.credentials.shopName === storeName);
    return {
      store,
      currentUser,
    };
  },
  props: {
    storeName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: true,
      unroutedOrders: null,
      filters: {
        submittedDate: null,
      },
    };
  },
  mounted() {
    this.loadOrders();
  },
  watch: {},
  computed: {
    filteredOrders() {
      if (!this.unroutedOrders?.orders) return null;
      return this.unroutedOrders.orders.filter(order => {
        if (!this.filters.submittedDate) {
          return true;
        }

        const date = dayjs(order.createdAt * 1000).format('YYYY-MM-DD');
        return this.filters.submittedDate === date;
      });
    },
    submittedDates() {
      if (!this.unroutedOrders?.orders) return [];
      const dates = this.unroutedOrders?.orders?.map(o => {
        const date = dayjs(o.createdAt * 1000).format('YYYY-MM-DD');
        return date;
      });

      return [...new Set(dates)].map(date => {
        return { label: date, value: date };
      });
    },
  },
  methods: {
    async loadOrders() {
      this.loading = true;
      this.unroutedOrders = await apiService.merchants.batches.unroutedOrders({ day: '' });
      this.loading = false;
    },
  },
});
</script>
