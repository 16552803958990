<template>
  <component :is="as" class="font-extrabold" :class="classes">
    <slot></slot>
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    level: {
      type: Number,
      default: 1,
    },
    size: {
      type: String,
      required: false,
    },
    color: {
      type: String,
      required: false,
    },
  },
  computed: {
    as() {
      return `h${this.level}`;
    },
    classes() {
      return [this.sizeClass, this.colorClass];
    },
    sizeClass() {
      if (this.size) return this.size;
      if (this.level === 1) return 'text-3xl';
      if (this.level === 2) return 'text-2xl';
      return '';
    },
    colorClass() {
      if (this.color) return this.color;
      if (this.level === 1) return 'text-gray-900 dark:text-gray-100';
      if (this.level === 2) return 'text-gray-800 dark:text-gray-200';
      return '';
    },
  },
});
</script>
