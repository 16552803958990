<template>
  <main class="flex-1 focus:outline-none" tabindex="0">
    <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0 print:px-0">
      <div class="pb-16">
        <div class="px-4 sm:px-6 md:px-0 print:px-0">
          <MerchantInvoiceBlock v-if="charge && merchant" :invoice="charge" :merchant="merchant" />
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { apiService } from '@tyltgo/shared';
import MerchantInvoiceBlock from '../../components/MerchantInvoiceBlock.vue';

export default defineComponent({
  components: { MerchantInvoiceBlock },
  async mounted() {
    await this.loadMerchant();
    await this.loadCharge();
  },
  data() {
    return {
      loading: false,
      charge: null,
      merchant: null,
    };
  },
  methods: {
    async loadCharge() {
      this.loading = true;
      const { charge } = await apiService.admin.merchants.invoice(
        this.$route.params.id,
        this.$route.params.invoiceId
      );
      this.charge = charge;
      this.loading = false;
    },
    async loadMerchant() {
      const { id } = this.$route.params;
      if (!id) return;
      this.loading = true;
      const { merchant } = await apiService.admin.merchants.get(id);
      this.merchant = merchant;
      this.loading = false;
    },
  },
});
</script>
