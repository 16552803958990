<template>
  <p :id="id" class="form-field-help mt-1 text-sm text-gray-500">
    {{ help }}
  </p>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    id: {
      type: String,
    },
    help: {
      type: String,
    },
  },
});
</script>
