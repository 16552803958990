import {
  createRouter,
  createWebHistory,
  parseQuery,
  RouteLocationNormalized,
  RouteRecordRaw,
} from 'vue-router';
import { authService } from '@tyltgo/shared';
// TODO: programatically load these.
import { stopLoadingMessage } from '@/services/ui-message-service';
import qs from 'qs';
import HomePage from '../views/HomePage.vue';
import ApiDocs from '../views/ApiDocs.vue';
import TrackingPage from '../views/TrackingPage.vue';
import UnsubscribePage from '../views/UnsubscribePage.vue';
import ResubscribePage from '../views/ResubscribePage.vue';
import CourierSignup from '../views/courier/CourierSignup.vue';
import CourierSignupSteps from '../views/courier/CourierSignupSteps.vue';
import SharedLogin from '../views/SharedLogin.vue';
import SafariNotSupported from '../views/SafariNotSupported.vue';
import SharedPasswordReset from '../views/SharedPasswordReset.vue';
import CourierPortal from '../views/CourierPortal.vue';
import CourierProfile from '../views/courier/CourierProfile.vue';
import AdminPortal from '../views/AdminPortal.vue';
import AdminHome from '../views/admin/AdminHome.vue';
import AdminSearch from '../views/admin/AdminSearch.vue';
import AdminPhoneSearch from '../views/admin/AdminPhoneSearch.vue';
import AdminRoute from '../views/admin/AdminRoute.vue';
import AdminRoutes from '../views/admin/AdminRoutes.vue';
import AdminScheduling from '../views/admin/AdminScheduling.vue';
import AdminCouriers from '../views/admin/AdminCouriers.vue';
import AdminCouriersPayouts from '../views/admin/AdminCouriersPayouts.vue';
import AdminCouriersGeneratePayouts from '../views/admin/AdminCouriersGeneratePayouts.vue';
import AdminCourier from '../views/admin/AdminCourier.vue';
import AdminCourierAvailability from '../views/admin/AdminCourierAvailability.vue';
import AdminReports from '../views/admin/AdminReports.vue';
import AdminIncidents from '../views/admin/AdminIncidents.vue';
import AdminSettings from '../views/admin/AdminSettings.vue';
import AdminPricing from '../views/admin/AdminPricing.vue';
import AdminMerchants from '../views/admin/AdminMerchants.vue';
import AdminMerchant from '../views/admin/AdminMerchant.vue';
import AdminMerchantInvoice from '../views/admin/AdminMerchantInvoice.vue';
import AdminMerchantShopify from '../views/admin/AdminMerchantShopify.vue';
import AdminMerchantInvoices from '../views/admin/AdminMerchantInvoices.vue';
import MerchantPortal from '../views/MerchantPortal.vue';
import MerchantShopifyPortal from '../views/MerchantShopifyPortal.vue';
import MerchantSignup from '../views/merchant/MerchantSignup.vue';
import MerchantHome from '../views/merchant/MerchantHome.vue';
import MerchantShopifyHome from '../views/merchant/MerchantShopifyHome.vue';
import MerchantBatches from '../views/merchant/MerchantBatches.vue';
import MerchantBatch from '../views/merchant/MerchantBatch.vue';
import MerchantNewBatch from '../views/merchant/MerchantNewBatch.vue';
import MerchantBilling from '../views/merchant/MerchantBilling.vue';
import MerchantLabelPrinter from '../views/merchant/MerchantLabelPrinter.vue';
import MerchantSettingsOverview from '../views/merchant/MerchantSettingsOverview.vue';
import MerchantInvoice from '../views/merchant/MerchantInvoice.vue';
import MerchantReports from '../views/merchant/MerchantReports.vue';
import MerchantPADSetup from '../views/merchant/MerchantPADSetup.vue';
import MerchantShopifyIntegrations from '../views/merchant/MerchantShopifyIntegrations.vue';
import MerchantShopifyIntegration from '../views/merchant/MerchantShopifyIntegration.vue';
import MerchantShopifyIntegrationAddOrders from '../views/merchant/MerchantShopifyIntegrationAddOrders.vue';
import MerchantShopifyIntegrationSubmittedOrders from '../views/merchant/MerchantShopifyIntegrationSubmittedOrders.vue';
import MerchantShopifyIntegrationHome from '../views/merchant/MerchantShopifyIntegrationHome.vue';
import { startupAuth, currentUser, redirectUrl } from '../services/auth-service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;
const isSafari = !!window.safari;

function handleSafari(to: RouteLocationNormalized) {
  redirectUrl.value = { path: to.path, query: to.query };
  return { path: '/safari' };
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
  },
  { path: '/api-docs', name: 'api-docs', component: ApiDocs },
  { path: '/tracking', name: 'tracking', component: TrackingPage },
  { path: '/tracking/:id', name: 'tracking.id', component: TrackingPage },
  {
    path: '/tracking/:id/:trackingSecret',
    name: 'tracking.id.trackingsecret',
    component: TrackingPage,
  },
  { path: '/track/:trackingSlug', name: 'track', component: TrackingPage },
  { path: '/track/:trackingSlug/:id', name: 'track.id', component: TrackingPage },
  {
    path: '/track/:trackingSlug/:id/:trackingSecret',
    name: 'track.id.trackingsecret',
    component: TrackingPage,
  },
  { path: '/tracking/tipCourier', name: 'tracking.tipCourier', component: TrackingPage },
  { path: '/tracking/:id/tipCourier', name: 'tracking.tipCourier', component: TrackingPage },
  {
    path: '/tracking/:id/:trackingSecret/tipCourier',
    name: 'tracking.tipCourier',
    component: TrackingPage,
  },
  { path: '/track/:trackingSlug/tipCourier', name: 'track.tipCourier', component: TrackingPage },
  {
    path: '/track/:trackingSlug/:id/tipCourier',
    name: 'track.tipCourier',
    component: TrackingPage,
  },
  {
    path: '/track/:trackingSlug/:id/:trackingSecret/tipCourier',
    name: 'track.tipCourier',
    component: TrackingPage,
  },
  {
    path: '/unsubscribed/:id/:trackingSecret',
    name: 'unsubscribed.id.trackingsecret',
    component: UnsubscribePage,
  },
  {
    path: '/unsubscribe/:trackingSlug/:id/:trackingSecret',
    name: 'unsubscribe.id.trackingsecret',
    component: UnsubscribePage,
  },
  {
    path: '/resubscribe/:trackingSlug/:id/:trackingSecret',
    name: 'resubscribe.id.trackingsecret',
    component: ResubscribePage,
  },
  {
    path: '/resubscribed/:id/:trackingSecret',
    name: 'resubscribed.id.trackingsecret',
    component: ResubscribePage,
  },
  { path: '/safari', name: 'safari', component: SafariNotSupported },
  {
    path: '/merchant/signup/complete/:key',
    name: 'merchant.signup',
    meta: { title: 'Merchant Signup' },
    component: MerchantSignup,
    beforeEnter: async (_to, _from) => {
      await startupAuth('merchant');
      if (currentUser.value?.email && authService.isLoggedIn) {
        return { name: 'merchant.batches' };
      }

      return true;
    },
  },
  {
    path: '/merchant/padSetup',
    name: 'merchant.padSetup',
    meta: { title: 'Merchant Signup' },
    component: MerchantPADSetup,
    beforeEnter: async (_to, _from) => {
      await startupAuth('merchant');
      if (currentUser.value?.email && authService.isLoggedIn) return true;
      return { name: 'merchant.login' };
    },
  },
  {
    path: '/courier/signup',
    name: 'courier.signup',
    meta: { title: 'Courier Signup' },
    component: CourierSignup,
    beforeEnter: async (to, _from) => {
      await startupAuth('courier');

      if (to.query.referredBy) {
        return true;
      }

      if (currentUser.value?.email && authService.isLoggedIn) {
        const incomplete = currentUser.value.state === 'incomplete';
        if (!incomplete) return { name: 'courier.profile' };
        return { name: 'courier.signup.steps' };
      }

      return true;
    },
  },
  {
    meta: { title: 'Courier Signup' },
    path: '/courier/signup/steps',
    name: 'courier.signup.steps',
    component: CourierSignupSteps,
  },
  {
    meta: { title: 'Courier Signup' },
    path: '/courier/signup/steps/:section/:name',
    name: 'courier.signup.step',
    component: CourierSignupSteps,
  },
  {
    path: '/courier/login',
    name: 'courier.login',
    component: SharedLogin,
    props: {
      loginType: 'courier',
    },
    beforeEnter: async (_to, _from) => {
      await startupAuth('courier');
      return true;
    },
    meta: {
      title: 'Courier Login',
    },
  },
  {
    path: '/courier/password-reset',
    name: 'courier.passwordReset',
    props: {
      loginType: 'courier',
    },
    component: SharedPasswordReset,
  },
  {
    path: '/merchant/login',
    name: 'merchant.login',
    props: {
      loginType: 'merchant',
    },
    component: SharedLogin,
    beforeEnter: async (_to, _from) => {
      await startupAuth('merchant');
      return true;
    },
    meta: {
      title: 'Merchant Login',
    },
  },
  {
    path: '/merchant/password-reset',
    name: 'merchant.passwordReset',
    props: {
      loginType: 'merchant',
    },
    component: SharedPasswordReset,
  },
  {
    path: '/admin/login',
    name: 'admin.login',
    component: SharedLogin,
    props: {
      loginType: 'admin',
    },
    meta: { title: 'Admin Portal' },
    beforeEnter: async (_to, _from) => {
      await startupAuth('admin');
      return true;
    },
  },
  {
    path: '/admin/password-reset',
    name: 'admin.passwordReset',
    props: {
      loginType: 'admin',
    },
    component: SharedPasswordReset,
  },
  {
    path: '/courier',
    name: 'courier',
    component: CourierPortal,
    meta: {
      title: 'Courier Portal',
    },
    beforeEnter: async (to, _from) => {
      await startupAuth('courier');
      if (!currentUser.value?.email || !authService.isLoggedIn) {
        redirectUrl.value = { path: to.path, query: to.query };
        return { path: '/courier/login' };
      }
      const incomplete = currentUser.value.state === 'incomplete';
      if (incomplete) {
        return { name: 'courier.signup.steps' };
      }

      return true;
    },
    children: [
      {
        path: 'profile',
        name: 'courier.profile',
        component: CourierProfile,
        meta: { title: 'Courier Profile' },
      },
      {
        path: 'profile/:section/:name',
        name: 'courier.profile.step',
        component: CourierProfile,
        meta: { title: 'Courier Profile' },
      },
    ],
  },
  {
    path: '/merchant/invoice/:id',
    name: 'merchant.invoice',
    component: MerchantInvoice,
    meta: { title: 'Invoice' },
    beforeEnter: async (to, _from) => {
      await startupAuth('merchant');

      if (!currentUser.value?.email || !authService.isLoggedIn) {
        redirectUrl.value = { path: to.path, query: to.query };
        return { path: '/merchant/login' };
      }

      return true;
    },
  },
  {
    path: '/merchant/shopify',
    name: 'merchant.shopify',
    component: MerchantShopifyPortal,
    meta: {
      title: 'Merchant Portal',
    },
    beforeEnter: async (to, _from) => {
      await startupAuth('merchant');
      if (isSafari) {
        return handleSafari(to);
      }

      // if (!currentUser.value?.email || !authService.isLoggedIn) {
      //   redirectUrl.value = { path: to.path, query: to.query };
      //   return { path: '/merchant/login' };
      // }

      return true;
    },
    children: [
      { path: 'home', name: 'merchant.shopify.home', component: MerchantShopifyIntegrationHome },
      {
        path: 'add',
        name: 'merchant.shopify.addOrders',
        component: MerchantShopifyIntegrationAddOrders,
        meta: { title: 'Shopify Integration - Add Orders' },
      },
      {
        path: 'pendingOrders',
        name: 'merchant.shopify.pendingOrders',
        component: MerchantShopifyIntegrationSubmittedOrders,
        meta: { title: 'Shopify Integration - Pending Orders' },
      },
    ],
  },
  {
    path: '/merchant',
    name: 'merchant',
    component: MerchantPortal,
    meta: {
      title: 'Merchant Portal',
    },
    beforeEnter: async (to, _from) => {
      await startupAuth('merchant');

      if (isSafari) {
        return handleSafari(to);
      }

      if (!currentUser.value?.email || !authService.isLoggedIn) {
        redirectUrl.value = { path: to.path, query: to.query };
        return { path: '/merchant/login' };
      }

      return true;
    },
    children: [
      { path: 'home', name: 'merchant.home', component: MerchantHome },
      {
        path: 'billing',
        name: 'merchant.billing',
        component: MerchantBilling,
        meta: { title: 'Billing' },
      },
      {
        path: 'labelPrinter',
        name: 'merchant.labelPrinter',
        component: MerchantLabelPrinter,
        meta: { title: 'Label Printer' },
      },
      {
        path: 'settings',
        name: 'merchant.settings',
        component: MerchantSettingsOverview,
        meta: { title: 'Settings' },
      },
      {
        path: 'settings/:tab',
        name: 'merchant.settings.tab',
        component: MerchantSettingsOverview,
        meta: { title: 'Settings' },
      },
      {
        path: 'reports',
        name: 'merchant.reports',
        component: MerchantReports,
        meta: { title: 'Reports' },
      },
      {
        path: 'reports/:report',
        name: 'merchant.report',
        component: MerchantReports,
        meta: { title: 'Reports' },
      },
      {
        path: 'shopifyIntegrations',
        name: 'merchant.shopifyIntegrations',
        component: MerchantShopifyIntegrations,
        meta: { title: 'Shopify Integrations' },
      },
      {
        path: 'shopifyIntegrations/:storeName',
        name: 'merchant.shopifyIntegration',
        component: MerchantShopifyIntegration,
        meta: { title: 'Shopify Integration' },
        children: [
          {
            path: '',
            name: 'merchant.shopifyIntegration.home',
            component: MerchantShopifyIntegrationHome,
            meta: { title: 'Shopify Integration' },
          },
          {
            path: 'add',
            name: 'merchant.shopifyIntegration.addOrders',
            component: MerchantShopifyIntegrationAddOrders,
            meta: { title: 'Shopify Integration - Add Orders' },
          },
          {
            path: 'pendingOrders',
            name: 'merchant.shopifyIntegration.pendingOrders',
            component: MerchantShopifyIntegrationSubmittedOrders,
            meta: { title: 'Shopify Integration - Pending Orders' },
          },
        ],
      },
      {
        path: 'batches',
        name: 'merchant.batches',
        component: MerchantBatches,
        meta: { title: 'Orders' },
      },
      {
        path: 'batches/new',
        name: 'merchant.new',
        component: MerchantNewBatch,
        meta: { title: 'New Order Batch' },
      },
      {
        path: 'batches/:id',
        name: 'merchant.batch',
        component: MerchantBatch,
        meta: { title: 'Batch' },
      },
    ],
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminPortal,
    beforeEnter: async (to, _from) => {
      await startupAuth('admin');

      if (isSafari) {
        return handleSafari(to);
      }

      if (!currentUser.value?.isAdmin || !authService.isLoggedIn) {
        redirectUrl.value = { path: to.path, query: to.query };
        return { path: '/admin/login' };
      }

      return true;
    },
    children: [
      { path: 'home', name: 'admin.home', component: AdminHome },
      { path: 'search', name: 'admin.search', component: AdminSearch },
      { path: 'searchPhone', name: 'admin.searchPhone', component: AdminPhoneSearch },
      { path: 'routes', name: 'admin.routes', component: AdminRoutes, meta: { title: 'Routes' } },
      { path: 'routes/:id', name: 'admin.route', component: AdminRoute, meta: { title: 'Route' } },
      {
        path: 'routes/:id/:tab',
        name: 'admin.route.tab',
        component: AdminRoute,
        meta: { title: 'Route' },
      },
      {
        path: 'scheduling',
        name: 'admin.scheduling',
        component: AdminScheduling,
        meta: { title: 'Scheduling' },
      },
      {
        path: 'couriers',
        name: 'admin.couriers',
        component: AdminCouriers,
        meta: { title: 'Couriers' },
      },
      {
        path: 'couriers/payouts',
        name: 'admin.couriers.payouts',
        component: AdminCouriersPayouts,
        meta: { title: 'Courier Payouts' },
      },
      {
        path: 'couriers/previewPayouts',
        name: 'admin.couriers.previewPayouts',
        component: AdminCouriersGeneratePayouts,
        meta: { title: 'Preview Courier Payouts' },
      },
      {
        path: 'couriers/:id/',
        name: 'admin.courier',
        component: AdminCourier,
        meta: { title: 'Courier' },
      },
      {
        path: 'couriers/:id/:tab',
        name: 'admin.courier.tab',
        component: AdminCourier,
        meta: { title: 'Courier' },
      },
      {
        path: 'availability',
        name: 'admin.availability',
        component: AdminCourierAvailability,
        meta: { title: 'Availability' },
      },
      {
        path: 'merchants',
        name: 'admin.merchants',
        component: AdminMerchants,
        meta: { title: 'Merchants' },
      },
      {
        path: 'merchants/invoices',
        name: 'admin.merchants.invoices',
        component: AdminMerchantInvoices,
        meta: { title: 'Merchant Invoices' },
      },
      {
        path: 'merchants/:id/',
        name: 'admin.merchant',
        component: AdminMerchant,
        meta: { title: 'Merchant' },
      },
      {
        path: 'merchants/:id/:tab',
        name: 'admin.merchant.tab',
        component: AdminMerchant,
        meta: { title: 'Merchant' },
      },
      {
        path: 'merchants/:id/invoice/:invoiceId',
        name: 'admin.merchant.invoice',
        component: AdminMerchantInvoice,
        meta: { title: 'Merchant Invoice' },
      },
      {
        path: 'merchants/:id/shopify/:shopName/',
        name: 'admin.merchant.shopify',
        component: AdminMerchantShopify,
        meta: { title: 'Merchant Shopify' },
      },
      {
        path: 'reports',
        name: 'admin.reports',
        component: AdminReports,
        meta: { title: 'Reports' },
      },
      {
        path: 'reports/:report',
        name: 'admin.report',
        component: AdminReports,
        meta: { title: 'Reports' },
      },
      {
        path: 'incidents',
        name: 'admin.incidents',
        component: AdminIncidents,
        meta: { title: 'Incidents' },
      },
      {
        path: 'settings',
        name: 'admin.settings',
        component: AdminSettings,
        meta: { title: 'Settings' },
      },
      {
        path: 'pricing',
        name: 'admin.pricing',
        component: AdminPricing,
        meta: { title: 'Pricing' },
      },
      {
        path: 'pricing/:tab',
        name: 'admin.pricing.tab',
        component: AdminPricing,
        meta: { title: 'Pricing' },
      },
    ],
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutPage.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    if (to.path === from.path) return {};
    return { top: 0, left: 0 };
  },
  parseQuery: qs.parse as typeof parseQuery,
  stringifyQuery(query) {
    const result = qs.stringify(query);
    return result || '';
  },
});

router.beforeEach((to, _from, next) => {
  console.log({ to });
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);

  const title = nearestWithTitle?.meta?.title
    ? `${nearestWithTitle.meta.title} - Tyltgo`
    : 'Tyltgo';
  document.title = title;

  stopLoadingMessage();
  next();
});

export default router;
