<template>
  <div
    v-if="notifications.length"
    aria-live="assertive"
    class="fixed inset-0 flex flex-col space-y-4 items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-start md:ml-64"
    style="z-index: 1005"
  >
    <div
      v-for="notification of notifications"
      :key="notification.id"
      class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 transition transform"
      :class="notification.show ? 'opacity-100' : 'opacity-0 -translate-x-4'"
    >
      <div class="p-4" v-if="notification.color">
        <div class="flex items-start">
          <div class="flex-shrink-0">
            <div class="h-6 w-6" :class="colors(notification.color).icon">
              <CheckCircleIcon v-if="notification.color === 'success'" aria-hidden="true" />
              <XCircleIcon v-else-if="notification.color === 'danger'" aria-hidden="true" />
              <ExclamationIcon v-else-if="notification.color === 'warning'" aria-hidden="true" />
              <InformationCircleIcon v-else aria-hidden="true" />
            </div>
          </div>
          <div class="ml-3 w-0 flex-1 pt-0.5 space-y-1">
            <p v-if="notification.title" class="text-sm font-medium text-gray-900">
              {{ notification.title }}
            </p>
            <p v-if="notification.message" class="text-sm text-gray-500">
              {{ notification.message }}
            </p>
          </div>
          <div class="ml-4 flex-shrink-0 flex">
            <button
              @click="show = false"
              class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span class="sr-only">Close</span>
              <XIcon
                class="h-5 w-5"
                aria-hidden="true"
                @click="removeNotification(notification.id)"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {
  CheckCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
  ExclamationIcon,
} from '@heroicons/vue/outline';
import { XIcon } from '@heroicons/vue/solid';
import { notifications, removeNotification } from '../services/ui-message-service';

const styles = {
  primary: {
    icon: 'text-primary-400',
  },
  success: {
    icon: 'text-green-400',
  },
  information: {
    icon: 'text-blue-400',
  },
  danger: {
    icon: 'text-red-400',
  },
  warning: {
    icon: 'text-yellow-400',
  },
};

export default defineComponent({
  setup() {
    return {
      notifications,
      removeNotification,
    };
  },
  components: {
    XIcon,
    CheckCircleIcon,
    InformationCircleIcon,
    XCircleIcon,
    ExclamationIcon,
  },
  methods: {
    colors(color) {
      return styles[color];
    },
  },
});
</script>
