/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */
import { apiService } from './api-service';

type StoreTokenFunction = (token: string) => void;

class AuthService {
  jwt = '';

  storeToken!: StoreTokenFunction;

  postJwt!: () => Promise<void>;

  // set storeToken(func: StoreTokenFunction) {
  //   this.storeToken = func;
  // }

  async logout() {
    await this.setJwt('');
  }

  async loginSuccess(token: string) {
    await this.setJwt(token);
  }

  get isLoggedIn() {
    return !!this.jwt;
  }

  async setJwt(token: string) {
    if (!this.storeToken) throw new Error('storeToken not set in auth service.');

    this.storeToken(token);
    apiService.setAuthorizationToken(token);
    this.jwt = token;

    if (!this.postJwt) throw new Error('postJwt not set in auth service.');
    await this.postJwt();
  }
}

const authService = new AuthService();
export { authService };
