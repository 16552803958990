<template>
  <main class="flex-1 focus:outline-none" tabindex="0">
    <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
      <div class="pt-10 pb-16">
        <div class="px-4 sm:px-6 md:px-0">
          <GoHeader :level="1">Invoices</GoHeader>
        </div>
        <div class="px-4 sm:px-6 md:px-0 pt-10">
          <div class="flex flex-col">
            <div class="-my-2 sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <Form class="gap-3 grid grid-cols-4 w-full pr-4 mb-4" @submit="loadInvoices">
                  <GoLabel label="Created Between" class="col-span-4" />
                  <GoTextField
                    class="col-span-1"
                    name="start"
                    v-model="filter.start"
                    label="Start Date"
                    type="date"
                  />
                  <GoTextField
                    class="col-span-1"
                    name="end"
                    v-model="filter.end"
                    label="End Date"
                    type="date"
                  />
                  <GoSelect
                    class="col-span-1"
                    name="state"
                    placeholder="All"
                    v-model="filter.state"
                    :options="{
                      PAID: 'Paid',
                      NOT_PAID: 'Not Paid',
                    }"
                  />
                  <div class="col-span-1 flex items-end">
                    <GoButton>Load</GoButton>
                  </div>
                </Form>
                <InvoicesTable
                  v-if="invoices?.length"
                  :invoices="invoices"
                  @loadInvoices="loadInvoices"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { apiService } from '@tyltgo/shared';
import sortBy from 'lodash/sortBy';
import { Form } from 'vee-validate';
import dayjs from 'dayjs';
import { currentUser } from '../../services/auth-service';

export default defineComponent({
  emits: ['loadMerchant'],
  components: {
    Form,
  },
  setup() {
    return {
      currentUser,
    };
  },
  data() {
    return {
      loading: false,
      invoices: null,
      filter: {
        start: dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
        end: dayjs().format('YYYY-MM-DD'),
        state: null,
      },
    };
  },
  methods: {
    async loadInvoices() {
      this.loading = true;
      const { invoices } = await apiService.admin.billing.invoices(this.filter);
      console.log(invoices);
      if (invoices?.length) {
        this.invoices = sortBy(invoices, 'created').reverse();
      }
      this.loading = false;
    },
  },
});
</script>
