<template>
  <main class="flex-1 overflow-y-auto focus:outline-none" tabindex="0">
    <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
      <div class="pt-10 pb-16">
        <div class="px-4 sm:px-6 md:px-0">
          <div class="py-6" v-if="route">
            <div>
              <BatchCard :route="route" :viewDetails="false" />
              <div class="w-full mb-4 shadow rounded-md" style="height: 60vh">
                <l-map
                  ref="map"
                  @ready="setMap"
                  @update:zoom="zoomUpdated"
                  :zoom="zoom"
                  class="z-0 rounded-md"
                >
                  <MapTiles />
                  <l-control-layers />

                  <DrawMapRoute :route="route" :polyline="false" link="merchant.batch" />
                </l-map>
              </div>
              <OrderCard
                v-for="waypoint in route.waypoints"
                :route="route"
                :key="waypoint.id"
                :waypoint="waypoint"
                :routeId="route.id"
                :routeState="route.state"
                :ref="waypoint.id"
                @reloadBatch="loadBatch"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { LMap, LControlLayers } from '@vue-leaflet/vue-leaflet';
import { apiService } from '@tyltgo/shared';
import debounce from 'lodash/debounce';
import io from 'socket.io-client';
import BatchCard from '../../components/BatchCard.vue';
import OrderCard from '../../components/OrderCard.vue';
import DrawMapRoute from '../../components/DrawMapRoute.vue';
import MapTiles from '../../components/MapTiles.vue';

export default defineComponent({
  components: {
    BatchCard,
    OrderCard,
    DrawMapRoute,
    LMap,
    MapTiles,
    LControlLayers,
  },
  watch: {
    async $route(_to, _from) {
      await this.loadBatch();
    },
  },
  async mounted() {
    await this.loadBatch();
    const { id } = this.$route.params;
    this.socket = io(`${apiService.apiHost}?room=merchant/batches/${id}`, {
      transports: ['websocket', 'polling'],
    });
    this.socket.on('batch', debounce(this.updateBatch, 4000));
  },
  async updated() {
    const { hash } = this.$route;
    document.title = `Batch ${this.$route.params.id} - Tyltgo`;
    if (hash && hash !== this.hash) {
      this.$refs[this.$route.hash.replace('#', '')]?.$el?.scrollIntoView();
    }
    this.hash = hash;
  },
  data() {
    return {
      hash: null,
      loading: false,
      route: null,
      zoom: 6,
    };
  },
  computed: {
    bounds() {
      return this.route.waypoints.map(point => [point.lat, point.lng]);
    },
  },
  methods: {
    async loadBatch() {
      console.log(this.$route.params);
      this.loading = true;
      const { id } = this.$route.params;
      this.route = await apiService.merchants.batches.get(id);
      this.loading = false;
    },
    setMap() {
      const { map } = this.$refs;
      map.leafletObject.fitBounds(this.bounds);
      map.leafletObject.scrollWheelZoom.disable();
    },
    zoomUpdated(zoom: number) {
      this.zoom = zoom;
    },
    updateBatch({ batchId }) {
      console.log('in updated', { batchId });
      this.loadBatch();
    },
  },
});
</script>
