<template>
  <div v-if="offers">
    <p v-if="!offers.length" class="p-4">No Offers.</p>
    <p v-if="offers.length" class="py-4 text-lg font-bold">Offer History (last 30)</p>
    <div
      v-for="offer in offers"
      :key="offer.id"
      class="bg-white dark:bg-gray-800 border-b-2 dark:border-gray-500 px-4 py-2"
    >
      <div class="mb-1">
        <router-link :to="`/admin/routes/${offer.route.id}`">
          Route {{ offer.route.id }}
        </router-link>
      </div>
      <RouteOfferLine :offer="offer" :route="offer.route" />
    </div>
  </div>
</template>

<script lang="ts">
import { apiService } from '@tyltgo/shared';
import { defineComponent } from 'vue';
import { aroundLoadingMessage } from '../services/ui-message-service';
import RouteOfferLine from './RouteOfferLine.vue';

export default defineComponent({
  components: {
    RouteOfferLine,
  },
  props: {
    courier: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      offers: null,
    };
  },
  async mounted() {
    await aroundLoadingMessage('Sending...', async () => {
      const results = await apiService.admin.couriers.offerHistory(this.courier.email);
      this.offers = results.offers || [];
    });
  },
});
</script>
