<template>
  <div class="form-checkbox-group">
    <GoCheckbox
      v-for="item in items"
      :key="valueValue(item)"
      :name="name"
      :label="labelValue(item)"
      :value="valueValue(item)"
      :help="helpValue(item)"
      :show-error="false"
    />
    <ErrorMessage :name="name" v-slot="{ message }">
      <GoErrorMessage :message="message" />
    </ErrorMessage>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ErrorMessage } from 'vee-validate';
import GoCheckbox from './GoCheckbox.vue';
import GoErrorMessage from './GoErrorMessage.vue';

interface Options {
  [key: string]: string;
}

export default defineComponent({
  inheritAttrs: false,
  components: {
    GoCheckbox,
    ErrorMessage,
    GoErrorMessage,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    options: {
      type: [Array, Object],
      required: true,
    },
    valueField: {
      type: String,
      default: 'value',
    },
    labelField: {
      type: String,
      default: 'label',
    },
    helpField: {
      type: String,
      default: 'help',
    },
  },
  computed: {
    isArray(): boolean {
      return Array.isArray(this.options);
    },
    items(): any[] {
      if (this.isArray) {
        return this.options;
      }
      return this.objectToArray(this.options as Options);
    },
  },
  methods: {
    labelValue(item: Options) {
      return item[this.labelField];
    },
    valueValue(item: Options) {
      return item[this.valueField];
    },
    helpValue(item: Options) {
      return item[this.helpField];
    },
    objectToArray(object: Options): any[] {
      const result = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const value in object) {
        if (value) {
          const label = object[value];
          result.push({
            value,
            label,
          });
        }
      }
      return result;
    },
  },
});
</script>
