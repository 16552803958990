<template>
  <main class="flex-1 focus:outline-none" tabindex="0">
    <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
      <div class="pt-10 pb-16">
        <div class="px-4 sm:px-6 md:px-0">
          <GoHeader :level="1">Payouts</GoHeader>
        </div>
        <div class="px-4 sm:px-6 md:px-0 pt-10">
          <div class="flex flex-col">
            <div class="-my-2 sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow border-b border-gray-200 dark:border-gray-500 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-500">
                    <thead class="bg-gray-50 dark:bg-gray-700">
                      <tr>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
                        >
                          Payout ID
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
                        >
                          Pay Period
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
                        >
                          # Routes
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
                        >
                          Total Pay
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
                        >
                          Expedited Pay
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
                        >
                          Current Balance
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
                        >
                          Bumpup Pay
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
                        >
                          Veem ID
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
                        >
                          Created
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      class="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-500"
                    >
                      <tr v-for="payout of payouts" :key="payout.id">
                        <td
                          class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-50"
                        >
                          {{ payout.payoutId }}
                        </td>
                        <td
                          class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-50"
                        >
                          {{ payout.payPeriod }}
                        </td>
                        <td
                          class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300"
                        >
                          {{ payout.orders.length }}
                        </td>
                        <td
                          class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300"
                        >
                          ${{ parseFloat(payout.totalPay).toFixed(2) }}
                        </td>
                        <td
                          class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300"
                        >
                          ${{ parseFloat(payout.expeditedPayoutTotal || 0).toFixed(2) }}
                        </td>
                        <td
                          class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300"
                        >
                          ${{ parseFloat(payout.currentBalance || 0).toFixed(2) }}
                        </td>
                        <td
                          class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300"
                        >
                          ${{ parseFloat(payout.bumpUpPay).toFixed(2) }}
                        </td>
                        <td
                          class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300"
                        >
                          <GoTooltip :text="paymentStatus(payout.veem?.status).message">
                            {{ paymentStatus(payout.veem?.status).status }}
                          </GoTooltip>
                        </td>
                        <td
                          class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300"
                        >
                          {{ payout.transferId }}
                        </td>
                        <td
                          class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300"
                        >
                          {{
                            payout.veem?.timeCreated
                              ? veemDateTime(payout.veem.timeCreated)
                              : 'Pending Creation'
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { apiService, paymentStatus, veemDateTime } from '@tyltgo/shared';
import sortBy from 'lodash/sortBy';
import GoTooltip from './GoTooltip.vue';

export default defineComponent({
  emits: ['loadMerchant'],

  components: {
    GoTooltip,
  },
  props: {
    courier: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      paymentStatus,
      veemDateTime,
    };
  },
  async mounted() {
    await this.loadPayouts();
  },
  data() {
    return {
      loading: false,
      payouts: null,
    };
  },
  methods: {
    async loadPayouts() {
      this.loading = true;
      const { payouts } = await apiService.admin.couriers.payouts(this.courier.email);
      this.payouts = sortBy(payouts, 'created').reverse();
      this.loading = false;
    },
  },
});
</script>
