<template>
  <div
    class="px-4 mb-4 sm:px-6 md:px-0 flex flex-col lg:flex-row lg:justify-between lg:items-center"
  >
    <div
      v-if="currentUser.parentIds?.length || currentUser.settings.showUnroutedLabels"
      class="flex space-x-2 items-center mb-4"
    >
      <GoButton
        variant="outline"
        class="text-xs py-1 flex flex-row justify-center items-center space-x-2"
        @click="downloadLabels('zpl', 'unrouted')"
      >
        <IconDownload class="w-3" />
        <span>ZPL</span>
      </GoButton>
      <GoButton
        variant="outline"
        class="text-xs py-1 flex flex-row justify-center items-center space-x-2"
        @click="downloadLabels('pdf', 'unrouted')"
      >
        <IconDownload class="w-3" />
        <span>PDF</span>
      </GoButton>
      <GoButton
        variant="outline"
        class="text-xs py-1 flex flex-row justify-center items-center space-x-2"
        @click="downloadUnroutedCsv"
      >
        <IconDownload class="w-3" />
        <span>CSV</span>
      </GoButton>
    </div>
  </div>
  <div>
    <div v-for="order in unroutedOrders" :key="order.id">
      <UnroutedOrderCard :order="order" @updateUnroutedOrders="$emit('updateUnroutedOrders')" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import startCase from 'lodash/startCase';
import { convertZplToPdf, Merchant, RawOrder, rawOrderZpl } from '@tyltgo/shared';
import { unparse } from 'papaparse';
import { chunk, flatten } from 'lodash';
import { PDFDocument } from 'pdf-lib';
import { currentUser } from '@/services/auth-service';
import GoButton from '../../components/GoButton.vue';
import IconDownload from '../../components/IconDownload.vue';
import { rawOrderToCsvHash } from '../../services/csv-service';
import { downloadBlob } from '../../services/browser-service';
import { aroundLoadingMessage } from '../../services/ui-message-service';

export default defineComponent({
  emits: ['updateUnroutedOrders'],
  props: {
    unroutedOrders: Array,
    default: [],
  },
  setup() {
    return { startCase, currentUser };
  },
  components: {
    GoButton,
    IconDownload,
  },
  methods: {
    async downloadUnroutedCsv() {
      const rows = this.unroutedOrders.map((order: RawOrder) => rawOrderToCsvHash(order)).flat();
      const blob = new Blob([unparse(rows)], { type: 'text/csv' });
      downloadBlob(blob, `orders-${this.unroutedOrders[0].date}.csv`);
    },
    async downloadLabels(fileType: 'pdf' | 'zpl', type?: 'routed' | 'unrouted') {
      const labelsFunction = type === 'unrouted' ? 'createUnroutedZplLabels' : 'createZplLabels';
      aroundLoadingMessage('Generating Labels...', async () => {
        const merchant = this.currentUser;
        const labels: string[] = await this[labelsFunction](merchant);
        console.log({ labels });
        let blob = new Blob(labels, { type: 'x-application/zpl' });
        if (fileType === 'pdf') {
          const labelGroups = chunk(labels, 1);
          const pdfs = [];
          for (const labelGroup of labelGroups) {
            const groupBlob = new Blob(labelGroup, { type: 'x-application/zpl' });
            // eslint-disable-next-line no-await-in-loop
            const pdfLabel = await convertZplToPdf(groupBlob, 'arraybuffer');
            // eslint-disable-next-line no-await-in-loop
            await new Promise(r => setTimeout(r, 1000));
            pdfs.push(pdfLabel);
          }

          const pdfDoc = await PDFDocument.create();
          const pdfDocs = await Promise.all(pdfs.map(async doc => PDFDocument.load(doc)));
          await Promise.all(
            pdfDocs.map(async doc => {
              (await pdfDoc.copyPages(doc, [...Array(doc.getPageCount()).keys()])).forEach(page => {
                pdfDoc.addPage(page);
              });
            })
          );
          const pdfBytes = await pdfDoc.save();
          blob = new Blob([pdfBytes], { type: 'application/pdf' });
        }
        const fileName =
          type === 'unrouted'
            ? `orders-${this.unroutedOrders[0].date}.${fileType}`
            : `batches-${this.sortedRoutes[0].routeDate}.${fileType}`;
        downloadBlob(blob, fileName);
      });
    },
    async createUnroutedZplLabels(merchant: Merchant) {
      return flatten<string>(
        this.unroutedOrders.map((order: RawOrder) => rawOrderZpl({ rawOrder: order, merchant }))
      );
    },
  },
});
</script>
