<template>
  <div class="inline-block">
    <input
      type="file"
      :id="id"
      ref="file"
      class="hidden"
      :accept="accept"
      @change="filesSelected($event.target.files)"
    />
    <GoButton v-bind="$attrs" as="label" :for="id" @click.stop="onClick">
      <slot></slot>
    </GoButton>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import GoButton from './GoButton.vue';

export default defineComponent({
  inheritAttrs: false,
  emits: ['filesSelected', 'click'],
  components: {
    GoButton,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    accept: {
      type: String,
    },
  },
  methods: {
    filesSelected(files) {
      this.$emit('filesSelected', Object.values({ ...files }));
      this.$refs.file.value = null;
    },
    onClick(event) {
      this.$emit('click', event);
    },
    trigger() {
      this.$refs.file.click();
    },
  },
});
</script>
