<template>
  <GoTextField
    v-bind="$attrs"
    :name="`${name}.${addressFieldName}`"
    autocomplete="off"
    google-places
    @place-selected="placeSelected"
    type="search"
    v-model="address"
  />
  <GoHiddenField :name="`${name}.${formattedAddressFieldName}`" v-model="formattedAddress" />
  <GoHiddenField :name="`${name}.${latFieldName}`" v-model="lat" />
  <GoHiddenField :name="`${name}.${lngFieldName}`" v-model="lng" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import GoTextField from './GoTextField.vue';
import GoHiddenField from './GoHiddenField.vue';

export default defineComponent({
  emits: ['place-selected'],
  inheritAttrs: false,
  components: {
    GoTextField,
    GoHiddenField,
  },
  props: {
    name: {
      type: String,
    },
    addressFieldName: {
      type: String,
      default: 'address',
    },
    latFieldName: {
      type: String,
      default: 'lat',
    },
    lngFieldName: {
      type: String,
      default: 'lng',
    },
    formattedAddressFieldName: {
      type: String,
      default: 'formattedAddress',
    },
  },
  data() {
    return {
      address: '',
      formattedAddress: '',
      lat: null,
      lng: null,
    };
  },
  methods: {
    placeSelected(place) {
      this.$emit('place-selected', place);
      this.address = place.formatted_address;
      this.formattedAddress = place.formatted_address;
      this.lat = place.geometry.location.lat();
      this.lng = place.geometry.location.lng();
    },
  },
});
</script>
