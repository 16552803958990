<template>
  <main class="flex-1 focus:outline-none" tabindex="0" v-if="currentUser?.email">
    <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
      <div class="pt-10 pb-16">
        <div class="px-4 sm:px-6 md:px-0">
          <GoHeader :level="1">Profile</GoHeader>
        </div>
        <div class="px-4 sm:px-6 md:px-0">
          <div class="pb-6"></div>
          <GoAlert v-if="profileMessage" color="information" class="mb-4">
            {{ profileMessage }}
          </GoAlert>
          <!-- <div
            class="bg-white py-2 shadow rounded-sm mb-4 flex border-l-4 flex-col"
            v-if="courier && !courier?.settings?.disableAddressChange"
          > -->
          <div class="bg-white py-2 shadow rounded-sm mb-4 flex border-l-4 flex-col" v-if="courier">
            <Form @submit="saveChanges" :initial-values="initialValues">
              <div class="px-4 pt-4">
                <div class="flex justify-between space-x-8">
                  <div>
                    <h2 class="text-xl">{{ fullName }}</h2>
                  </div>
                  <div>
                    <p class="text-gray-700">{{ courier.email }}</p>
                  </div>
                </div>

                <div class="flex pt-4">
                  <div>
                    <p>{{ courier.address }}</p>
                    <h2>{{ courier.unitNumber }}</h2>
                  </div>
                </div>
                <div class="py-3">
                  <div class="grid grid-cols-1 sm:grid-cols-3 gap-6">
                    <GoTextField class="col-span-1" label="Phone Number" name="phoneNumber" />
                  </div>
                </div>
              </div>
              <div class="flex justify-start px-4 py-2">
                <GoButton class="text-xs">Save Changes</GoButton>
              </div>
            </Form>
          </div>
          <CourierSteps :steps="steps" stepRouteName="courier.profile.step">
            <div class="flex flex-col items-center justify-center sm:block sm:p-0 h-full">
              <div class="h-1/3">&nbsp;</div>
              <div class="flex flex-col items-center justify-center w-3/4 mx-auto">
                <h2 class="font-bold font-header text-2xl mb-4">Your profile is up to date</h2>
              </div>
              <div class="h-1/3">&nbsp;</div>
            </div>
          </CourierSteps>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import {
  courierSteps,
  courierProfileMessage,
  findRemainingSteps,
  apiService,
} from '@tyltgo/shared';
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
import { cloneDeep, pick } from 'lodash';
import GoTextField from '../../components/GoTextField.vue';
import GoButton from '../../components/GoButton.vue';
import GoAlert from '../../components/GoAlert.vue';
import CourierSteps from '../../components/CourierSteps.vue';
import { currentUser } from '../../services/auth-service';
import { addNotification, aroundLoadingMessage } from '../../services/ui-message-service';

export default defineComponent({
  setup() {
    return {
      currentUser,
      courier: currentUser,
      courierProfileMessage,
    };
  },
  components: {
    Form,
    GoTextField,
    GoButton,
    GoAlert,
    CourierSteps,
  },
  data() {
    return {
      step: null,
      courierAddress: '',
      geo: {},
    };
  },
  created() {
    this.geo[this.courier.address] = {
      lat: this.courier.lat,
      lng: this.courier.lng,
    };
  },
  mounted() {
    const { name } = this.$route.params;
    if (!name) {
      const remainingSteps = findRemainingSteps(this.currentUser, this.steps);
      const step = remainingSteps[0];
      this.$router.replace({
        name: 'courier.profile.step',
        params: { section: step?.section || 'completed', name: step?.name || 'done' },
      });
    }
  },
  computed: {
    initialValues() {
      const clone = cloneDeep(this.courier);
      return {
        ...pick(clone, 'address', 'phoneNumber', 'unitNumber'),
      };
    },
    steps() {
      return courierSteps.filter(step => !step.signup);
    },
    fullName() {
      return [this.currentUser.firstName, this.currentUser.lastName]
        .filter(name => !!name)
        .join(' ');
    },
    profileMessage() {
      return this.courierProfileMessage(this.currentUser);
    },
  },
  methods: {
    placeSelected(place, _name) {
      this.geo = {};
      this.geo[place.formatted_address] = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      this.courierAddress = place.formatted_address;
    },
    async saveChanges(values, { setFieldError }) {
      if (Object.keys(this.geo)[0] !== values.address) {
        setFieldError('address', 'Please select the address from the autocomplete dropdown.');
        return;
      }

      await aroundLoadingMessage('Saving...', async () => {
        try {
          await apiService.couriers.profile.updateCourierDetails({
            ...values,
            // eslint-disable-next-line @typescript-eslint/ban-types
            ...(Object.values(this.geo)[0] as {}),
          });
          addNotification({ color: 'success', message: 'Details Updated' });
          this.$emit('loadCourier');
        } catch (e) {
          addNotification({
            color: 'danger',
            message: e.response?.data?.message || 'Error while updating details',
          });
        }
      });
    },
  },
});
</script>
