<template>
  <div class="min-h-screen flex flex-col justify-center py-12 px-2 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h1 class="mx-auto text-6xl h-12 text-center font-logo">Tyltgo</h1>
      <h2 class="mt-4 mx-auto text-3xl h-12 text-center font-logo text-gray-800">
        {{ startCase(loginType) }} Password Reset
      </h2>
    </div>
    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 space-y-2">
        <Form class="space-y-4 mb-4" @submit="reset" :validation-schema="schema">
          <GoTextField
            name="email"
            label="Email"
            placeholder="you@example.com"
            v-model="inputEmail"
          />
          <GoButton class="w-full">Reset</GoButton>
        </Form>
        <GoAlert v-if="resetStatus.result === 'success'" color="success" class="mb-4">
          Please check your email for reset instructions.
        </GoAlert>
        <GoAlert v-if="resetStatus.result == 'error'" color="danger" class="mb-4">
          Incorrect Email. Please check your email and try again.
        </GoAlert>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { apiService } from '@tyltgo/shared';
import startCase from 'lodash/startCase';
import GoTextField from '../components/GoTextField.vue';
import GoButton from '../components/GoButton.vue';
import GoAlert from '../components/GoAlert.vue';

const schema = yup.object().shape({
  email: yup.string().email().required().label('Email'),
});

export default defineComponent({
  setup() {
    return {
      startCase,
      schema,
    };
  },
  components: {
    Form,
    GoTextField,
    GoButton,
    GoAlert,
  },
  props: {
    loginType: {
      type: String,
      required: true,
    },
  },
  beforeMount() {
    this.providedEmail();
  },
  data() {
    return {
      inputEmail: '',
      user: {},
      resetStatus: '',
    };
  },
  methods: {
    providedEmail() {
      this.inputEmail = this.$route.query.inputEmail;
    },
    async reset(user) {
      this.resetStatus = await apiService.couriers.auth.forgotPassword(user.email);
    },
  },
});
</script>
