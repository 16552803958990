<template>
  <TrackingPageLayout v-slot="{ config }">
    <main>
      <div v-if="!initialLoading && matchedOrder" class="flex flex-col">
        <p class="text-xl leading-7 font-normal mb-4" :class="config.grayColor">
          Order Status for {{ matchedOrder.waypointId }}
        </p>
        <nav aria-label="Progress" class="py-4 mb-4" v-if="currentStep.name !== 'Not Delivered'">
          <ol class="flex items-center">
            <li
              v-for="(step, stepIdx) in steps"
              :key="step.name"
              :class="[stepIdx !== steps.length - 1 ? 'pr-8 sm:pr-20' : '', 'relative']"
            >
              <template v-if="step.status === 'complete'">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="h-0.5 w-full" :style="`background-color: ${config.brandColor};`" />
                </div>
                <a
                  href="#"
                  class="relative w-8 h-8 flex items-center justify-center rounded-full hover:bg-indigo-900"
                  :style="`background-color: ${config.brandColor};`"
                >
                  <CheckIcon
                    class="w-5 h-5"
                    aria-hidden="true"
                    :style="`color: ${config.buttonTextColor}`"
                  />
                  <span class="sr-only">{{ step.name }}</span>
                </a>
              </template>
              <template v-else-if="step.status === 'current'">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="h-0.5 w-full" :style="`background-color: ${config.brandColor};`" />
                </div>
                <a
                  href="#"
                  class="relative w-8 h-8 flex items-center justify-center border-2 rounded-full"
                  :style="`border-color: ${config.brandColor}; background-color: ${config.buttonTextColor}`"
                  aria-current="step"
                >
                  <span
                    class="h-2.5 w-2.5 rounded-full"
                    aria-hidden="true"
                    :style="`background-color: ${config.brandColor};`"
                  />
                  <span class="sr-only">{{ step.name }}</span>
                </a>
              </template>
              <template v-else>
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="h-0.5 w-full" :style="`background-color: ${config.brandColor};`" />
                </div>
                <a
                  href="#"
                  class="group relative w-8 h-8 flex items-center justify-center border-2 border-gray-300 rounded-full hover:border-gray-400"
                  :style="`border-color: ${config.brandColor}; background-color: ${config.buttonTextColor}`"
                >
                  <span
                    class="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300"
                    aria-hidden="true"
                  />
                  <span class="sr-only">{{ step.name }}</span>
                </a>
              </template>
            </li>
          </ol>
        </nav>
        <p
          class="text-6xl leading-none font-extrabold tracking-tight mb-4 text-left"
          :style="`color: ${config.textColor}`"
        >
          {{ currentStep.name }}
        </p>
        <template v-if="currentStep.name === 'Delivered' && !moreFeatures">
          <div :style="`color: ${config.textColor}`">
            <p class="text-xl leading-7 font-normal mb-1">
              {{ currentStep.etaMessage }}
            </p>
            <p class="text-2xl leading-7 font-bold mb-6">
              {{ matchedOrder.etaWindow }}
            </p>
            <p class="text-xl leading-7 mb-4">
              {{ currentStep.message }}
            </p>
          </div>
        </template>
        <div v-if="currentStep.name !== 'Delivered' && !this.isOutsourced">
          <template v-if="matchedOrder.etaWindow && currentStep.name !== 'Not Delivered'">
            <p class="text-xl leading-7 font-normal mb-1" :class="config.grayColor">
              {{ currentStep.etaMessage }}
            </p>
            <p class="text-2xl leading-7 font-bold mb-4" :style="`color: ${config.textColor}`">
              {{ matchedOrder.etaWindow }}
            </p>
          </template>
          <p
            class="text-xl leading-7 font-normal mb-6"
            :class="config.grayColor"
            :style="`color: ${config.textColor}`"
          >
            {{ currentStep.message }}
          </p>
          <p
            v-if="matchedOrder.etaWindow && hasEstimate"
            class="text-sm leading-7 font-normal mb-2"
            :class="config.grayColor"
            :style="`color: ${config.textColor}`"
          >
            * Your delivery time window is subject to change with traffic & road conditions. Refresh
            this page for an updated estimate throughout the day.
          </p>
        </div>
        <div class="py-4 sm:pb-10" v-if="matchedOrder.routeId && !config.disableTip">
          <div :style="`color: ${config.textColor}`" class="mb-5">
            Want to leave a tip for your hardworking courier?
          </div>
          <div class="flex items-center mr-2">
            <GoButton
              class="w-full sm:w-1/2 text-sm"
              rounded="rounded-md"
              color="secondary"
              :style="`background-color: ${config.brandColor}; color: ${config.buttonTextColor}`"
              @click="tipCourier()"
            >
              Tip Courier
            </GoButton>
            <div
              v-if="tipCourierLoading && !showCourierTipModal"
              class="flex flex-col items-center justify-center"
            >
              <IconLoader
                class="animate-spin mx-2 h-8 w-8 text-primary-800"
                v-show="tipCourierLoading"
              />
            </div>
          </div>
        </div>
        <GoModal
          v-if="showCourierTipModal && !config.disableTip"
          @close="showIncorrectDeliveryModal = false"
        >
          <div class="p-4">
            <p class="text-lg">Would you like to tip your hardworking courier?</p>
          </div>
          <div class="p-4">
            <div class="flex flex-row justify-between">
              <div class="flex justify-start">
                <GoButton
                  class="text-xs"
                  variant="link"
                  rounded="rounded-md"
                  @click="showCourierTipModal = false"
                >
                  Close
                </GoButton>
              </div>
              <div class="flex justify-end">
                <GoButton
                  class="text-sm"
                  variant="outline"
                  rounded="rounded-md"
                  color="secondary"
                  :style="`background-color: ${config.brandColor}; color: ${config.buttonTextColor}`"
                  @click="tipCourier()"
                >
                  Tip Courier
                </GoButton>
                <div
                  v-if="tipCourierLoading && showCourierTipModal"
                  class="flex flex-col items-center justify-center"
                >
                  <IconLoader
                    class="animate-spin mx-2 h-8 w-8 text-primary-800"
                    v-show="tipCourierLoading"
                  />
                </div>
              </div>
            </div>
          </div>
        </GoModal>
        <div
          v-if="moreFeatures && matchedOrder"
          class="sm:flex sm:justify-center lg:justify-start mb-10 flex flex-col"
        >
          <div
            v-if="currentStep.name === 'Delivered'"
            class="pt-2 shadow rounded-lg mb-4 flex flex-col p-4"
            :class="config.cardBackgroundColor"
          >
            <div class="flex flex-row" :style="`color: ${config.textColor}`">
              <IconFileImage v-if="image" class="w-10 h-10" />
              <div class="flex flex-row justify-between">
                <div class="pt-2 pl-4 text-left">
                  <div class="text-lg">
                    Your delivery was
                    {{
                      matchedOrder.status.toLowerCase() === 'delivered to customer'
                        ? 'left with you'
                        : matchedOrder.status.toLowerCase()
                    }}.
                  </div>
                  <div :class="config.grayColor">
                    {{ matchedOrder.etaWindow }},
                    {{ relativeTime(matchedOrder.statusUpdatedAt) }}
                  </div>
                </div>
                <img
                  v-if="image"
                  @click="showImageModal = true"
                  class="w-1/4 rounded-lg mt-2"
                  :src="image"
                />
                <GoModal v-if="showImageModal" @close="showImageModal = false">
                  <div class="flex justify-center">
                    <img :src="image" />
                  </div>
                </GoModal>
              </div>
            </div>
            <div class="flex flex-col items-end mt-4">
              <div @click="showIncorrectDeliveryModal = true">
                <a href="#" class="no-underline text-xs text-teal-700">
                  Something wrong with the delivery? Contact Us ->
                </a>
              </div>
            </div>
            <GoModal v-if="showIncorrectDeliveryModal" @close="showIncorrectDeliveryModal = false">
              <div class="p-4">
                <p class="text-lg">Please tell us what's wrong with the delivery?</p>
              </div>
              <div class="flex flex-row p-4" :style="`color: ${config.textColor}`">
                <GoTextArea
                  class="pb-4 pt-2 mr-2 w-full"
                  label="false"
                  :name="`incorrectDeliveryNote`"
                  v-model="incorrectDeliveryNote"
                />
              </div>
              <div class="p-4">
                <div class="flex flex-row justify-between">
                  <div class="flex justify-start">
                    <GoButton
                      class="text-xs"
                      variant="link"
                      rounded="rounded-md"
                      @click="showIncorrectDeliveryModal = false"
                    >
                      Close
                    </GoButton>
                  </div>
                  <div class="flex justify-end">
                    <GoButton
                      class="text-sm"
                      variant="outline"
                      rounded="rounded-md"
                      color="secondary"
                      :style="`background-color: ${config.brandColor}; color: ${config.buttonTextColor}`"
                      @click="reportIncorrectDelivery"
                    >
                      Report
                    </GoButton>
                  </div>
                </div>
              </div>
            </GoModal>
            <GoModal
              v-if="showIncorrectDeliverySuccessModal"
              @close="showIncorrectDeliverySuccessModal = false"
            >
              <div class="p-4">
                <p class="text-lg">Someone will be in touch with you soon!</p>
              </div>
              <div class="p-4">
                <div class="flex flex-row justify-end">
                  <GoButton
                    class="text-xs"
                    variant="outline"
                    rounded="rounded-md"
                    color="secondary"
                    :style="`background-color: ${config.brandColor}; color: ${config.buttonTextColor}`"
                    @click="showIncorrectDeliverySuccessModal = false"
                  >
                    Okay
                  </GoButton>
                </div>
              </div>
            </GoModal>
          </div>
          <div
            v-if="false && currentStep.name !== 'Delivered'"
            class="pt-2 shadow rounded-lg mb-4 flex flex-col p-4 mr-2"
            :class="config.cardBackgroundColor"
          >
            <div class="flex flex-row" :style="`color: ${config.textColor}`">
              <IconUser class="w-4 h-4 mt-3" />
              <div class="flex flex-row justify-between">
                <div class="pt-2 pl-4 text-left">
                  <div class="text-lg">
                    {{ matchedOrder.name }}
                  </div>
                  <div class="text-gray-500 text-l">
                    {{ matchedOrder.address }}
                  </div>
                  <div class="text-gray-500 text-l">
                    {{ matchedOrder.addressLine2 }}
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col items-end">
              <div @click="showWrongAddressModal = true">
                <a href="#" class="no-underline text-sm text-teal-700">
                  Wrong Address? Contact Us ->
                </a>
              </div>
            </div>
            <GoModal v-if="showWrongAddressModal" @close="showWrongAddressModal = false">
              <div class="p-4">
                <p class="text-lg">Are you sure that the following address is wrong?</p>
              </div>
              <div class="flex flex-row p-4" :style="`color: ${config.textColor}`">
                <IconUser class="w-4 h-4 mt-3" />
                <div class="flex flex-row justify-between">
                  <div class="pt-2 pl-4 text-left">
                    <div class="text-lg">
                      {{ matchedOrder.name }}
                    </div>
                    <div class="text-gray-500 text-l">
                      {{ matchedOrder.address }}
                    </div>
                    <div class="text-gray-500 text-l">
                      {{ matchedOrder.addressLine2 }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-4">
                <div class="flex flex-row justify-between">
                  <div class="flex justify-start">
                    <GoButton
                      class="text-xs"
                      variant="outline"
                      rounded="rounded-md"
                      @click="showWrongAddressModal = false"
                    >
                      NO
                    </GoButton>
                  </div>
                  <div class="flex justify-end">
                    <GoButton
                      class="text-xs"
                      variant="outline"
                      rounded="rounded-md"
                      color="secondary"
                      :style="`background-color: ${config.brandColor}; color: ${config.buttonTextColor}`"
                      @click="reportWrongAddress"
                    >
                      YES
                    </GoButton>
                  </div>
                </div>
              </div>
            </GoModal>
            <GoModal
              v-if="showWrongAddressSuccessModal"
              @close="showWrongAddressSuccessModal = false"
            >
              <div class="p-4">
                <p class="text-lg">Someone will be in touch to correct your address!</p>
              </div>
              <div class="p-4">
                <div class="flex flex-row justify-end">
                  <GoButton
                    class="text-xs"
                    variant="outline"
                    rounded="rounded-md"
                    color="secondary"
                    :style="`background-color: ${config.brandColor}; color: ${config.buttonTextColor}`"
                    @click="showWrongAddressSuccessModal = false"
                  >
                    Okay
                  </GoButton>
                </div>
              </div>
            </GoModal>
          </div>
          <Form
            class="pt-2 sm:pb-10"
            v-if="currentStep.name !== 'Delivered' && matchedOrder.routeId"
            @submit="updateDeliveryNote"
          >
            <div :style="`color: ${config.textColor}`">Delivery Instructions:</div>
            <GoTextArea
              class="pb-4 pt-2 mr-2"
              label="false"
              :name="`deliveryNote`"
              v-model="deliveryNote"
            />
            <div class="flex items-center mr-2">
              <GoButton
                class="w-full sm:w-1/2 text-sm"
                rounded="rounded-md"
                color="secondary"
                :style="`background-color: ${config.brandColor}; color: ${config.buttonTextColor}`"
              >
                Update Instructions
              </GoButton>
              <div v-if="loading">
                <IconLoader class="animate-spin mx-2 h-8 w-8 text-primary-800" v-show="loading" />
              </div>
              <GoModal
                v-if="showInstructionsUpdatedModal"
                @close="showInstructionsUpdatedModal = false"
              >
                <div class="p-4">
                  <p class="text-lg">Delivery instructions updated!</p>
                </div>
                <div class="p-4">
                  <div class="flex flex-row justify-end">
                    <GoButton
                      class="text-xs"
                      variant="outline"
                      rounded="rounded-md"
                      color="secondary"
                      :style="`background-color: ${config.brandColor}; color: ${config.buttonTextColor}`"
                      @click="showInstructionsUpdatedModal = false"
                    >
                      Okay
                    </GoButton>
                  </div>
                </div>
              </GoModal>
            </div>
          </Form>
          <div
            v-if="this.matchedOrder?.trackingStatus === 'Missing' && config.supportEmail"
            class="mt-4 flex flex-col justify-center w-full space-y-2"
          >
            <p class="text-3xl">Please reach out to {{ config.supportEmail }}.</p>
          </div>
        </div>
      </div>
      <div v-else-if="!initialLoading" class="text-center lg:text-left">
        <h1
          class="text-4xl leading-none tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl"
        >
          <span class="block xl:inline" :style="`color: ${config.textColor}`">
            {{ config.title }}
          </span>
        </h1>
        <p
          v-if="config.blurb"
          class="mt-3 text-base sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0"
          :class="config.grayColor"
        >
          {{ config.blurb }}
        </p>
        <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
          <Form
            class="space-y-3 sm:space-x-3 sm:space-y-0 flex flex-col sm:flex-row sm:items-center w-full pr-4"
            @submit="track"
          >
            <GoTextField
              class="w-full sm:w-2/3 mt-0"
              name="orderId"
              v-model="orderId"
              label="false"
              placeholder="Enter your tracking number"
            />
            <GoButton
              class="w-full sm:w-1/3 text-sm"
              rounded="rounded-md"
              color="secondary"
              :style="`background-color: ${config.brandColor}; color: ${config.buttonTextColor}`"
            >
              Track
            </GoButton>
          </Form>
        </div>
        <div
          v-if="loading"
          class="mt-4 flex flex-col items-center justify-center py-8 w-full space-y-2"
        >
          <IconLoader class="animate-spin mx-2 h-8 w-8 text-primary-800" v-show="loading" />
        </div>
        <div v-if="notFound" class="mt-4 flex flex-col justify-center py-8 w-full space-y-2">
          <p class="text-2xl">No order was found with that ID.</p>
          <p class="text-2xl">Please double check if it was entered correctly.</p>
          <p v-if="config.supportEmail" class="text-3xl pt-2">
            If any issues, please reach out to {{ config.supportEmail }}.
          </p>
        </div>
      </div>
      <div v-else class="mt-4 flex flex-col items-center justify-center py-8 w-full space-y-2">
        <IconLoader class="animate-spin mx-2 h-8 w-8 text-primary-800" />
      </div>
    </main>
  </TrackingPageLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
import { apiService } from '@tyltgo/shared';
import dayjs from 'dayjs';
import { CheckIcon } from '@heroicons/vue/solid';
import relativeTime from 'dayjs/plugin/relativeTime';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isYesterday from 'dayjs/plugin/isYesterday';
import IconFileImage from '../components/IconFileImage.vue';
import IconUser from '../components/IconUser.vue';
import GoTextField from '../components/GoTextField.vue';
import GoTextArea from '../components/GoTextArea.vue';
import GoModal from '../components/GoModal.vue';
import GoButton from '../components/GoButton.vue';
import IconLoader from '../components/IconLoader.vue';
import { time as formattedTime } from '../services/date-service';

dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(isYesterday);

export default defineComponent({
  components: {
    GoButton,
    GoTextField,
    GoTextArea,
    GoModal,
    Form,
    CheckIcon,
    IconLoader,
    IconFileImage,
    IconUser,
  },
  async beforeMount() {
    this.initialLoading = true;
    const { id } = this.$route.params;
    const { trackingSecret, trackingSlug } = this.$route.params;
    this.trackingSecret = trackingSecret?.replace(/[^0-9_a-z]/, '');
    this.trackingSlug = trackingSlug;
    if (id) {
      this.orderId = id.replace(/[.$!]$/, '');
      await this.validateTrackingSecret();
      await this.track({ orderId: this.orderId });
      if (this.$route.name.includes('tipCourier')) {
        await this.tipCourier();
      }
      if (this.moreFeatures) {
        this.image = await this.getImage();
      }
    }
    this.initialLoading = false;
  },
  mounted() {
    setTimeout(() => {
      this.showCourierTipModal = true;
    }, 5000);
  },
  data() {
    return {
      matchedOrder: null,
      notFound: null,
      initialLoading: null,
      loading: null,
      tipCourierLoading: null,
      orderId: null,
      trackingSecret: '',
      deliveryNote: '',
      image: null,
      showImageModal: false,
      moreFeatures: false,
      trackingSlug: '',
      showWrongAddressModal: false,
      showWrongAddressSuccessModal: false,
      showInstructionsUpdatedModal: false,
      showIncorrectDeliveryModal: false,
      showCourierTipModal: false,
      showIncorrectDeliverySuccessModal: false,
      incorrectDeliveryNote: '',
      isOutsourced: false,
    };
  },
  computed: {
    currentStep() {
      const notDelivered = {
        'Bring Back': {
          message: this.matchedOrder.trackingStatus,
        },
        Missing: {
          message: '',
        },
      };
      if (notDelivered[this.matchedOrder.status]) {
        return {
          name: 'Not Delivered',
          href: '#',
          status: 'complete',
          message: notDelivered[this.matchedOrder.status].message,
          etaMessage: 'Estimated time of arrival:',
        };
      }
      return this.steps.find(step => step.name === this.matchedOrder.trackingStatus);
    },
    steps() {
      if (!this.matchedOrder) return [];

      const steps = [
        {
          name: 'Delivery Scheduled',
          href: '#',
          status: 'complete',
          message: 'Your delivery is scheduled.',
          etaMessage: 'Estimated Window of Arrival *',
        },
        {
          name: 'On Route',
          href: '#',
          status: ['On Route', 'Delivered'].includes(this.matchedOrder.trackingStatus)
            ? 'complete'
            : 'current',
          message: 'Your order is on the way to you.',
          etaMessage: 'Estimated Window of Arrival *',
        },
        {
          name: 'Delivered',
          href: '#',
          status: ['Delivered'].includes(this.matchedOrder.trackingStatus) ? 'complete' : 'current',
          message: 'Your order has been delivered.',
          etaMessage: 'Time of delivery:',
        },
      ];

      return steps;
    },
    hasEstimate() {
      return ['Delivery Scheduled', 'On Route'].includes(this.currentStep.name);
    },
  },
  methods: {
    async validateTrackingSecret() {
      const { valid } = await apiService.tracking.validateTrackingSecret(
        this.orderId,
        this.trackingSecret,
        this.trackingSlug
      );
      this.moreFeatures = valid;
    },
    relativeTime(time: string) {
      if (dayjs(time).isYesterday()) return 'yesterday';
      if (dayjs(time).isToday()) return 'today';
      if (dayjs(time).isTomorrow()) return 'tomorrow';

      return dayjs(time).fromNow();
    },
    async getImage() {
      if (this.matchedOrder?.state !== 'completed' || !this.moreFeatures) {
        return null;
      }
      try {
        // eslint-disable-next-line @typescript-eslint/return-await
        return await apiService.tracking.waypointImage(
          this.matchedOrder.routeId,
          this.matchedOrder.waypointId,
          this.trackingSecret
        );
      } catch (e) {
        return null;
      }
    },
    async updateDeliveryNote({ deliveryNote }) {
      if (!this.moreFeatures) return;
      this.loading = true;
      await apiService.tracking.updateDeliveryNote(
        this.matchedOrder.routeId,
        this.orderId,
        this.trackingSecret,
        deliveryNote
      );
      this.loading = false;
      this.showInstructionsUpdatedModal = true;
    },
    async tipCourier() {
      this.tipCourierLoading = true;
      const { paymentLink } = await apiService.tracking.tipCourier(
        this.matchedOrder.routeId,
        this.orderId,
        this.trackingSecret
      );
      if (this.$route.name.includes('tipCourier')) {
        window.open(paymentLink.url, '_self');
      } else {
        window.open(paymentLink.url, '_blank');
      }
      this.showCourierTipModal = false;
      this.tipCourierLoading = false;
    },
    async reportWrongAddress() {
      if (!this.moreFeatures) return;

      await apiService.tracking.reportWrongAddress(
        this.matchedOrder.routeId,
        this.orderId,
        this.trackingSecret
      );
      this.showWrongAddressModal = false;
      this.showWrongAddressSuccessModal = true;
    },
    async reportIncorrectDelivery() {
      if (!this.moreFeatures) return;

      await apiService.tracking.reportIncorrectDelivery(
        this.matchedOrder.routeId,
        this.orderId,
        this.trackingSecret,
        this.incorrectDeliveryNote
      );
      this.showIncorrectDeliveryModal = false;
      this.showIncorrectDeliverySuccessModal = true;
    },
    async track({ orderId }) {
      this.loading = true;
      if (orderId) {
        this.matchedOrder = await this.trackOrder(orderId);
      }
      this.loading = false;
      if (orderId) {
        this.notFound = !this.matchedOrder;
      }
      if (this.matchedOrder) {
        this.deliveryNote = this.matchedOrder.deliveryNote || '';
        this.isOutsourced = await apiService.tracking.isOutsourced(this.matchedOrder.routeId);
      }
    },
    async trackOrder(orderId: string) {
      const searchText = orderId?.trim();

      if (!searchText) return null;

      const searchQuery = `id:${searchText}`;

      const results = await apiService.tracking.search(
        encodeURIComponent(searchQuery),
        this.trackingSecret,
        this.trackingSlug
      );

      let result = null;
      if (results.length > 1) {
        result = results.find((item: { merged: boolean }) => item.merged) || results[0];
      } else if (results.length === 1) {
        // eslint-disable-next-line prefer-destructuring
        result = results[0];
      }

      if (!result) return null;
      if (result.status === 'Cancelled') return null;

      if (result.estimatedArrivalTimeStart) {
        result.etaWindow = `${dayjs(result.routeDate, 'YYYY-MM-DD').format(
          'MMMM D, YYYY'
        )} ${formattedTime(result.estimatedArrivalTimeStart, result.timeZoneId)} - ${formattedTime(
          result.estimatedArrivalTimeEnd,
          result.timeZoneId
        )}`;
      }

      if (result.trackingStatus.includes('Arranging')) {
        result.trackingStatus = 'Delivery Scheduled';
      } else if (
        result.trackingStatus.includes('Deliver') ||
        result.trackingStatus.includes('Door')
      ) {
        result.trackingStatus = 'Delivered';
        result.etaWindow = formattedTime(
          dayjs(result.statusUpdatedAt).format('MMMM D, YYYY h:mm A'),
          result.timeZoneId
        );
      } else if (result.trackingStatus.includes('Bring Back')) {
        result.trackingStatus = `Sorry we missed you - please contact ${
          result.associatedBusiness || 'the merchant'
        } for help.`;
      }
      return result;
    },
    formatTime(time: string) {
      return dayjs(time).format('h:mm A');
    },
  },
});
</script>
