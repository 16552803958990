<template>
  <p :id="id" class="form-field-hint text-xs text-gray-500">{{ hint }}</p>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    id: {
      type: String,
    },
    hint: {
      type: String,
    },
  },
});
</script>
