<template>
  <div v-if="showAssignedRoutes" class="flex flex-col justify-between space-x-4">
    <DataBit title="Assigned Routes" :leftAligned="true">
      <div v-for="route of assignedRoutes[courier.id]" :key="route.id">
        <RouteCardCondensed :route="route" :state="route.state" :courier="courier" />
      </div>
      <div
        v-for="route of declinedRoutes.filter(r =>
          r.declinedOffers.find(o => o.courierId === courier.id)
        )"
        :key="route.id"
      >
        <RouteCardCondensed :route="route" state="declined" :courier="courier" />
      </div>
      <div
        v-for="route of offeredRoutes.filter(r =>
          r.activeOffers.find(o => o.courierId === courier.id)
        )"
        :key="route.id"
      >
        <RouteCardCondensed :route="route" state="offer" :courier="courier" />
      </div>
    </DataBit>
  </div>
  <div class="flex flex-col justify-between">
    <div class="text-xs uppercase font-medium text-gray-500 font-header">Calculate Pay</div>
    <div class="">
      <GoForm
        class="space-y-4"
        @submit="calculatePay"
        :initial-values="{
          toPickupAddress: courier.address,
          afterRouteAddress: courier.address,
          courierId: courier.id,
        }"
      >
        <GoHiddenField name="courierId" />
        <GoTextField label="To Pickup Address" name="toPickupAddress" google-places />
        <GoTextField label="After Route Address" name="afterRouteAddress" google-places />
        <div class="flex justify-end">
          <GoButton class="text-xs" variant="outline" data-button="calculatePay">
            Calculate V2 Pay
          </GoButton>
        </div>
      </GoForm>
    </div>
    <div class="flex flex-col justify-between mt-6" v-if="calculatedPay[courier.id]">
      <div class="space-y-2 mb-4">
        <div class="flex flex-row justify-between">
          <div class="flex flex-row space-x-2">
            <DataBit title="Base Pay" :value="calculatedPay[courier.id].v2.basePay?.toFixed(2)" />
            <DataBit
              title="Personal Pay"
              :value="calculatedPay[courier.id].v2.personalPay?.toFixed(2)"
            />
            <DataBit
              title="Adjustment Pay"
              :value="calculatedPay[courier.id].v2.bumpupPay?.toFixed(2)"
            />
          </div>
        </div>
        <div class="flex flex-row justify-between">
          <div class="flex flex-row space-x-2">
            <DataBit
              title="Duration"
              :value="`${readableDuration(totalDuration(calculatedPay[courier.id].v2))} `"
            />
            <DataBit
              title="Distance"
              :value="`${(totalDistance(calculatedPay[courier.id].v2) / 1000).toFixed(2)} kms`"
            />
            <DataBit
              v-if="calculatedPay[courier.id].v2.pay"
              title="Pay / hr"
              :value="
                (
                  calculatedPay[courier.id].v2.pay /
                  (totalDuration(calculatedPay[courier.id].v2) / 60)
                ).toFixed(2)
              "
            />
            <DataBit title="Total Pay" :value="calculatedPay[courier.id].v2.pay?.toFixed(2)" />
            <DataBit
              title="Margins"
              :value="
                (
                  ((route.price -
                    calculatedPay[courier.id].v2.pay +
                    Number(route?.payDetails?.courierTips || 0)) /
                    route.price) *
                  100
                ).toFixed(2) + ' %'
              "
            />
          </div>
          <div class="flex flex-row space-x-2">
            <DataBit title="Duration" :value="`${readableDuration(route.duration)}`" />
            <DataBit title="Distance" :value="`${route.distanceKm?.toFixed(2)} kms`" />
            <DataBit title="Route Price" :value="route.price?.toFixed(2)" />
          </div>
        </div>
        <GoButton
          class="text-xs"
          variant="link"
          @click="showCalculatedPayDetails = !showCalculatedPayDetails"
        >
          {{ showCalculatedPayDetails ? 'Hide' : 'Show' }} Full Pay Breakdown
        </GoButton>
        <GoTable
          v-if="showCalculatedPayDetails"
          class="my-4 overflow-x-auto"
          :tableData="{
            headers: [
              { title: 'Change', key: 'change' },
              { title: 'Details', key: 'details', wrap: true },
            ],
            data: calculatedPay[courier.id].v2.details.map(d => ({
              ...d,
              change: d.change?.toFixed(2),
            })),
          }"
        ></GoTable>
      </div>
      <div class="flex flex-1 items-end justify-between space-x-2">
        <GoAlert title="Offer Details">
          <div>
            <div>
              Total pay of offer to courier: ${{ calculatedPay[courier.id].v2.pay?.toFixed(2) }}
            </div>
            <div>An automated text/push notification will be sent to the courier on offer.</div>
          </div>
        </GoAlert>
        <template v-if="canSendOffer(courier)">
          <template v-if="schedulingCourierId">
            <GoButton
              v-if="!schedulingRouteBundle[route.id]"
              class="text-xs"
              variant="outline"
              @click="schedulingAddRouteToBundle(route.id, calculatedPay[courier.id].v2)"
            >
              Select for bundle offer
            </GoButton>
            <GoButton v-else class="text-xs" @click="schedulingRemoveRouteFromBundle(route.id)">
              Remove from bundle offer
            </GoButton>
          </template>
          <GoForm v-else @submit="sendOffer(courier.id, calculatedPay[courier.id].v2)">
            <GoButton class="text-xs" variant="outline">
              Send Offer for ${{ calculatedPay[courier.id].v2.pay?.toFixed(2) }}
            </GoButton>
          </GoForm>
        </template>
        <div v-else>
          Cannot send offer.
          {{ cantSendOfferReasons(courier) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Form } from 'vee-validate';
import { apiService, Courier, courierUnofferableReasons, readableDuration } from '@tyltgo/shared';
import { assignedRoutesMap, declinedRoutes, offeredRoutes } from '../store/routes-store';
import { addNotification, aroundLoadingMessage } from '../services/ui-message-service';
import RouteCardCondensed from './RouteCardCondensed.vue';
import GoTable from './GoTable.vue';
import GoHiddenField from './GoHiddenField.vue';
import GoAlert from './GoAlert.vue';
import DataBit from './DataBit.vue';
import GoButton from './GoButton.vue';
import GoTextField from './GoTextField.vue';
import {
  schedulingCourierId,
  schedulingRouteBundle,
  schedulingAddRouteToBundle,
  schedulingRemoveRouteFromBundle,
} from '../store/scheduling-store';

export default defineComponent({
  emits: ['offerSent'],
  setup() {
    return {
      readableDuration,
      schedulingCourierId,
      schedulingRouteBundle,
      schedulingAddRouteToBundle,
      schedulingRemoveRouteFromBundle,
    };
  },
  components: {
    Form,
    RouteCardCondensed,
    GoHiddenField,
    GoTable,
    GoAlert,
    DataBit,
    GoButton,
    GoTextField,
  },
  props: {
    route: {
      type: Object,
      required: true,
    },
    courier: {
      type: Object as PropType<Courier>,
      required: true,
    },
    showAssignedRoutes: {
      type: Boolean,
      default: true,
    },
    isExcludedCourier: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      calculatedPay: {},
      showCalculatedPayDetails: false,
    };
  },
  computed: {
    assignedRoutes() {
      return assignedRoutesMap.value;
    },
    declinedRoutes() {
      return declinedRoutes.value;
    },
    offeredRoutes() {
      return offeredRoutes.value;
    },
  },
  watch: {
    route(newRoute, oldRoute) {
      if (newRoute?.id !== oldRoute.id) {
        this.calculatedPay = {};
      }
    },
    courier(newCourier, oldCourier) {
      if (newCourier?.id !== oldCourier.id) {
        this.calculatedPay = {};
      }
    },
  },
  methods: {
    async calculatePay(values) {
      const result = await apiService.admin.routes.v2Pay(this.route.id, values);
      this.calculatedPay = { [values.courierId]: result };
    },
    async sendOffer(courierId: string, { toPickup, afterRoute }) {
      try {
        await aroundLoadingMessage('Sending...', async () => {
          await apiService.admin.routes.sendOffer(this.route.id, courierId, {
            toPickup,
            afterRoute,
          });
          this.$emit('offerSent');
        });
      } catch (e) {
        addNotification({
          color: 'danger',
          message: `Error sending offer: ${e.response?.data?.message || e.message}`,
        });
      }
    },
    cantSendOfferReasons(courier: Courier) {
      return courierUnofferableReasons(courier, this.isExcludedCourier);
    },
    canSendOffer(courier: Courier) {
      if (this.cantSendOfferReasons(courier)) return false;
      return true;
    },
    totalDuration({ toPickup, afterRoute }) {
      return Math.round(Number(this.route.duration + toPickup?.duration + afterRoute?.duration));
    },
    totalDistance({ toPickup, afterRoute }) {
      return Math.round(Number(this.route.distance + toPickup?.distance + afterRoute?.distance));
    },
  },
});
</script>
