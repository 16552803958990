<template>
  <div v-if="batches">
    <p v-if="!batches.length" class="p-4">No Batches.</p>
    <p v-if="batches.length" class="py-4 text-lg font-bold">Last {{ size }} Batches</p>
    <RouteCard v-for="batch in batches" :key="batch.id" :route="batch" />
  </div>
</template>

<script lang="ts">
import { apiService } from '@tyltgo/shared';
import { defineComponent } from 'vue';
import RouteCard from './RouteCard.vue';

export default defineComponent({
  components: {
    RouteCard,
  },
  props: {
    merchant: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      batches: null,
      size: 50,
    };
  },
  async mounted() {
    const results = await apiService.admin.merchants.batches(this.merchant.email, {
      size: this.size,
    });
    this.batches = results.batches || [];
  },
});
</script>
