<template>
  <div class="px-4 sm:px-6 md:px-0 space-y-4 w-1/3">
    <div class="flex space-x-4 items-center">
      <GoUploadButton
        variant="link"
        class="text-xs"
        id="upload"
        accept=".csv"
        @filesSelected="fileSelected"
      >
        Upload
      </GoUploadButton>
      <div v-if="addresses">{{ addresses.length }} addresses loaded.</div>
    </div>
    <Form
      class="space-y-4"
      @submit="getPricing"
      :initial-values="initialValues"
      :validation-schema="errorSchema"
    >
      <GoSelect
        class="w-full"
        label="Routing Engine"
        name="routingEngine"
        :options="RoutingEngineNames"
      />
      <GoTextField type="number" label="Batch Capacity" name="batchCapacity" :value="27" />
      <GoTextField type="number" label="Time Constraint (hours)" name="timeConstraint" :value="8" />
      <GoTextField
        type="number"
        label="Volume Capacity (cubic feet)"
        name="volumeCapacity"
        :value="0"
      />
      <GoTextField
        type="number"
        label="Hourly On Route Wage"
        name="minimumHourlyWage"
        :value="15"
      />
      <GoTextField
        type="number"
        label="Hourly Personal Wage"
        name="minimumHourlyPersonalWage"
        :value="7.5"
      />
      <GoTextField label="Name" name="name" />
      <GoTextField
        type="number"
        label="Routing Accuracy"
        help="Higher is more accurate, but takes longer to run"
        name="maxGenerations"
        :value="5"
      />
      <GoCheckbox label="Multiple Pickups" name="multiplePickups" :value="true" />
      <GoCheckbox label="Bag Return" name="bagReturn" :value="true" />
      <div class="flex items-center space-x-6">
        <GoButton>Generate Batches</GoButton>
      </div>
    </Form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { apiService } from '@tyltgo/shared/services/api-service';
import { RoutingEngineNames } from '@tyltgo/shared';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { currentUser } from '../../services/auth-service';
import GoUploadButton from '../../components/GoUploadButton.vue';
import GoSelect from '../../components/GoSelect.vue';
import GoButton from '../../components/GoButton.vue';
import GoTextField from '../../components/GoTextField.vue';
import {
  showConfirmationModal,
  showLoadingMessage,
  stopLoadingMessage,
} from '../../services/ui-message-service';
import { parseCsv } from '../../services/csv-service';

const errorSchema = yup.object().shape({
  name: yup.string().required().label('Name'),
  routingEngine: yup.string().required().label('Routing Engine'),
});

export default defineComponent({
  setup() {
    return {
      RoutingEngineNames,
    };
  },
  components: {
    GoUploadButton,
    GoSelect,
    GoButton,
    GoTextField,
    Form,
  },
  data() {
    return {
      addresses: null,
      initialValues: { routingEngine: 'vrpcli' },
      errorSchema,
      pickupAddress: '',
    };
  },
  computed: {
    apiHost() {
      return apiService.apiHost;
    },
  },
  methods: {
    async fileSelected(files: any[]) {
      const reader = new FileReader();
      reader.addEventListener('load', e => {
        const data = <string>e.target.result;
        setTimeout(async () => {
          const csv = parseCsv(data, { header: true });
          this.addresses = csv.data;
        }, 100);
      });

      const file = files[0];
      if (file) {
        reader.readAsText(file);
      }
    },
    async getPricing(values) {
      if (!this.addresses) return;

      showLoadingMessage('Traveling through the internets...');
      try {
        await apiService.admin.pricing.priceAddresses({
          ...values,
          email: currentUser.value.email,
          addresses: this.addresses,
        });
        showConfirmationModal({
          title: 'Running in the background',
          message: "You'll get an email with the results, this might take some time.",
          color: 'success',
        });
      } catch (e) {
        console.log('error', e);
      }
      stopLoadingMessage();
    },
    placeSelected(place, _name) {
      this.pickupAddress = place.formatted_address;
    },
  },
});
</script>
