<template>
  <div class="bg-white shadow rounded-lg mb-4 flex border-l-4 flex-col" :class="stateBorder">
    <div class="w-full pb-2 px-4">
      <div class="flex flex-col sm:justify-between sm:items-center sm:flex-row sm:flex-row-reverse">
        <div class="flex space-x-3">
          <span class="text-gray-500 text-sm">{{ route.createdAtDisplay }}</span>
        </div>
        <div class="flex flex-col sm:flex-row sm:space-x-4 mb-1">
          <div class="space-x-2 flex flex-row items-center py-1">
            <GoBadge :class="stateStyle">
              {{ startCase(state) }}
            </GoBadge>
            <GoBadge v-if="isNewCourier" class="bg-red-200 text-red-700">New Courier</GoBadge>
            <GoBadge v-if="route.deleted" class="bg-purple-200 text-purple-600">Deleted</GoBadge>
            <GoBadge class="bg-indigo-200 text-indigo-600" v-if="isMerged">merged</GoBadge>
            <GoBadge class="bg-brand-2 text-gray-50" v-if="schedulingRouteId === route.id">
              Selected
            </GoBadge>
          </div>
        </div>
      </div>
      <div class="pb-1 flex flex-row justify-between">
        <span class="text-lg">
          <router-link :to="`/admin/routes/${route.id}`">Route {{ route.id }}</router-link>
        </span>
        <div class="flex flex-col sm:flex-row sm:justify-between">
          <div class="flex space-x-4">
            <GoTooltip :text="readableDay(route.routeDate)">
              <DataBit title="Date" :value="route.routeDate" />
            </GoTooltip>
            <DataBit title="Duration" :value="`${route.duration} mins`" />
            <DataBit title="Distance" :value="`${route.distanceKm.toFixed(2)} km`" />
          </div>
        </div>
      </div>
    </div>
    <div v-for="offer of offersToShow" :key="offer.id" class="w-full py-2 px-4 border-t-2">
      <RouteOfferLine :offer="offer" :route="route" :showMenu="false" />
    </div>
    <div v-for="pickup in pickupPoints" :key="pickup.id" class="w-full py-2 px-4 border-t-2">
      <div class="flex flex-wrap gap-2 sm:justify-between">
        <div class="flex flex-col">
          <div class="space-x-2 flex items-center">
            <span>
              <router-link :to="`/admin/merchants/${pickup.associatedEmail}`">
                {{ pickup.name }}
              </router-link>
            </span>
            <span class="text-sm text-gray-500">
              {{ pickup.associatedOrder }}
            </span>
            <span class="text-sm text-gray-500" v-if="pickup.routeNumber">
              #{{ pickup.routeNumber }}
            </span>
          </div>
          <span class="text-xs text-gray-500">
            {{ pickup.address }}
          </span>
          <div class="flex space-x-2 items-center">
            <GoTooltip text="Dropoffs" class="flex space-x-2">
              <IconBoxOpen class="w-4 text-gray-600" />
              <div>
                <span class="text-sm" v-if="['completed', 'inProgress'].includes(route.state)">
                  {{ completedWaypointsFor(pickup).length }} /
                </span>
                <span class="text-sm">
                  {{ dropoffsFor(pickup).length }}
                </span>
              </div>
            </GoTooltip>
            <GoTooltip
              text="Unable to Delivers"
              class="flex space-x-2"
              v-if="groupedDropoffsFor(pickup)['Unable To Deliver']?.length"
            >
              <IconBackspace class="w-4 text-red-600" />
              <div>
                <span class="text-sm">
                  {{ groupedDropoffsFor(pickup)['Unable To Deliver'].length }}
                </span>
              </div>
            </GoTooltip>
            <GoTooltip
              text="Cancelled"
              class="flex space-x-2"
              v-if="groupedDropoffsFor(pickup)['Cancelled']?.length"
            >
              <IconTimesCircle class="w-4 text-red-600" />
              <div>
                <span class="text-sm">
                  {{ groupedDropoffsFor(pickup)['Cancelled'].length }}
                </span>
              </div>
            </GoTooltip>
            <GoTooltip
              text="Left At Door"
              class="flex space-x-2"
              v-if="groupedDropoffsFor(pickup)['Door']?.length"
            >
              <IconDoor class="w-4 text-gray-600" />
              <div>
                <span class="text-sm">
                  {{ groupedDropoffsFor(pickup)['Door'].length }}
                </span>
              </div>
            </GoTooltip>
          </div>
        </div>
        <div class="flex space-x-4">
          <DataBit
            title="Pickup Time"
            :value="time(pickup.cutoffTime, timeZoneId)"
            v-if="pickup.cutoffTime"
          />
          <DataBit
            title="Arrived At"
            :value="time(pickup.arrivedAt, timeZoneId)"
            v-if="pickup.arrivedAt"
          />
          <DataBit
            title="Picked At"
            :value="time(pickup.statusUpdatedAt, timeZoneId)"
            v-if="pickup.statusUpdatedAt"
          />
          <DataBit title="Price" :value="`$${pickup.price.toFixed(2)}`" v-if="pickup.price" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import groupBy from 'lodash/groupBy';
import { waypointDisplayState } from '@tyltgo/shared';
import startCase from 'lodash/startCase';
import IconTimesCircle from './IconTimesCircle.vue';
import IconBoxOpen from './IconBoxOpen.vue';
import IconDoor from './IconDoor.vue';
import IconBackspace from './IconBackspace.vue';
import GoBadge from './GoBadge.vue';
import GoTooltip from './GoTooltip.vue';
import DataBit from './DataBit.vue';
import RouteOfferLine from './RouteOfferLine.vue';
import { time, readableDay, timezoneConversionRouteInput } from '../services/date-service';
import { schedulingRouteId } from '../store/scheduling-store';

export default defineComponent({
  setup() {
    return {
      time,
      readableDay,
      startCase,
      schedulingRouteId,
    };
  },
  components: {
    IconBoxOpen,
    IconDoor,
    IconBackspace,
    IconTimesCircle,
    GoBadge,
    DataBit,
    RouteOfferLine,
    GoTooltip,
  },
  props: {
    route: {
      type: Object,
      required: true,
    },
    state: {
      type: String,
      required: true,
    },
    courier: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      timeZoneId: this.route.timeZoneId,
    };
  },
  async created() {
    if (!this.route.timeZoneId) {
      const zone = await timezoneConversionRouteInput(this.route, 'admin');
      this.timeZoneId = zone.timeZoneId;
    }
  },
  computed: {
    offersToShow() {
      const activeOffers =
        this.state !== 'declined'
          ? this.route.activeOffers.filter(offer => offer.courierId === this.courier.id)
          : [];
      const declinedOffers =
        this.state !== 'offer'
          ? this.route.declinedOffers.filter(offer => offer.courierId === this.courier.id)
          : [];
      return [...activeOffers, ...declinedOffers];
    },
    isNewCourier() {
      return this.courier?.totalRoutes < 4;
    },
    isMerged() {
      return this.route.mergedOrderIds.length;
    },
    pickupPoints(): any[] {
      if (!this.route) {
        return [];
      }

      return this.route.waypoints?.filter(x => x.type === 'pickup') || [];
    },
    completedWaypoints() {
      return this.route.waypoints?.filter(route => route.state === 'completed') || [];
    },
    stateStyle() {
      const colors = {
        completed: 'text-green-800 bg-green-100',
        offer: 'text-yellow-800 bg-yellow-100',
        inProgress: 'text-blue-900 bg-blue-200',
        new: 'text-gray-800 bg-gray-200',
        declined: 'text-red-800 bg-red-100',
        accepted: 'text-blue-700 bg-blue-50',
      };
      return colors[this.state];
    },
    errorStyle() {
      const colors = {
        resolved: 'text-green-800 bg-green-200',
        unresolved: 'text-red-800 bg-red-200',
      };
      return colors[this.unresolvedErrors.length > 0 ? 'unresolved' : 'resolved'];
    },
    stateBorder() {
      const colors = {
        completed: 'border-green-100',
        offer: 'border-yellow-100',
        inProgress: 'border-blue-200',
        new: 'border-gray-200',
        declined: 'border-red-100',
        accepted: 'border-blue-50',
      };
      return colors[this.state];
    },
    waypoints() {
      return this.route.waypoints;
    },
  },
  methods: {
    groupedDropoffsFor(waypoint: any) {
      return groupBy(this.dropoffsFor(waypoint), waypointDisplayState);
    },
    completedWaypointsFor(waypoint: any) {
      return this.completedWaypoints.filter(
        point => point.associatedOrder === waypoint.associatedOrder && point.type === 'dropoff'
      );
    },
    dropoffsFor(waypoint: any): any[] {
      return this.route.waypoints?.filter(
        x => x.associatedOrder === waypoint.associatedOrder && x.type === 'dropoff'
      );
    },
  },
});
</script>
