<template>
  <Field :name="name" type="hidden" :validateOnBlur="true" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Field } from 'vee-validate';

export default defineComponent({
  components: {
    Field,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
  },
});
</script>
