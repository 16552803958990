/* eslint-disable import/prefer-default-export */
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import startCase from 'lodash/startCase';
import {
  CourierAgreements,
  Courier,
  CourierDocuments,
  CourierDocument,
  CourierAgreement,
  CourierTrainings,
  CourierLastSteps,
  CourierSettingsSteps,
  Route,
  DayOfWeek,
} from '../types';
import { isOverlapping, timeToNumber } from './availability-service';
import { routeTimeNumbers } from './route-service';

dayjs.extend(isSameOrAfter);
dayjs.extend(customParseFormat);

const toDate = (date: string | null) => {
  if (!date) return undefined;

  const result = dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
  if (result === 'Invalid Date') return undefined;

  return result;
};

export const courierCalculateSignupCompleteness = (courier: Courier) => {
  return (
    Object.values(courier.training).every(details => details.completed) &&
    Object.values(courier.settingsSteps).every(details => details.completed) &&
    Object.values(courier.lastSteps).every(details => details.completed)
  );
};

export const courierCalculateCompleteness = (courier: Courier) => {
  return (
    Object.values(courier.documents).every(details => details.completed) &&
    Object.values(courier.training).every(details => details.completed) &&
    Object.values(courier.lastSteps).every(details => details.completed) &&
    Object.values(courier.settingsSteps).every(details => details.completed) &&
    Object.values(courier.agreements).every(details => details.completed)
  );
};

interface CourierTrainingOptions {
  field: string;
  timeField: string;
}
export const courierTraining: Record<string, CourierTrainingOptions> = {
  opportunity: {
    field: 'opportunityRead',
    timeField: 'opportunityReadAt',
  },
  // review: {
  //   field: 'responsibilitiesRead',
  //   timeField: 'reviewReadAt',
  // },
  quiz: {
    field: 'quizRead',
    timeField: 'quizReadAt',
  },
};

export const courierCalculateTraining = (driver: any) => {
  return Object.entries(courierTraining).reduce((result, [training, options]) => {
    const completed = !!driver[options.field];
    const state = completed ? 'approved' : 'incomplete';
    return {
      ...result,
      [training]: {
        completed,
        state,
      },
    };
  }, {} as CourierTrainings);
};

interface CourierAgreementOptions {
  field: string;
  timeField: string;
}
export const courierAgreements: Record<string, CourierAgreementOptions> = {
  contract: {
    field: 'contractRead',
    timeField: 'contractReadAt',
  },
  socialCode: {
    field: 'socialCodeRead',
    timeField: 'socialCodeReadAt',
  },
  courierGuide: {
    field: 'driverGuideRead',
    timeField: 'driverGuideReadAt',
  },
};

export const courierCalculateAgreements = (driver: any) => {
  return Object.entries(courierAgreements).reduce((result, [agreement, options]) => {
    const completed = !!driver[options.field];
    const state = completed ? 'approved' : 'incomplete';
    return {
      ...result,
      [agreement]: {
        completed,
        state,
      },
    };
  }, {} as CourierAgreements);
};

interface CourierStepsOptions {
  field: string;
  timeField: string;
}
export const courierLastSteps: Record<string, CourierStepsOptions> = {
  nextStep: {
    field: 'nextStepRead',
    timeField: 'nextStepReadAt',
  },
};

export const courierCalculateLastSteps = (driver: any) => {
  return Object.entries(courierLastSteps).reduce((result, [step, options]) => {
    const completed = !!driver[options.field];
    const state = completed ? 'approved' : 'incomplete';
    return {
      ...result,
      [step]: {
        completed,
        state,
      },
    };
  }, {} as CourierLastSteps);
};

export const courierSettingsSteps: Record<string, CourierStepsOptions> = {
  availability: {
    field: 'availabilityUpdated',
    timeField: 'availabilityUpdatedAt',
  },
};

export const courierCalculateSettingsSteps = (driver: any) => {
  return Object.entries(courierSettingsSteps).reduce((result, [step, options]) => {
    const completed = !!driver[options.field];
    const state = completed ? 'approved' : 'incomplete';
    return {
      ...result,
      [step]: {
        completed,
        state,
      },
    };
  }, {} as CourierSettingsSteps);
};

interface CourierDocumentOptions {
  expiry: boolean;
  storageName: string;
  baseField: string;
  documentTypes?: {
    name: string;
    expiry: boolean;
  }[];
}
export const courierDocuments: Record<string, CourierDocumentOptions> = {
  license: {
    expiry: true,
    storageName: "Driver's License",
    baseField: 'license',
  },
  insurance: {
    expiry: true,
    storageName: 'Vehicle Insurance',
    baseField: 'insurance',
  },
  ownership: {
    expiry: false,
    storageName: 'Vehicle Ownership',
    baseField: 'ownership',
  },
  eligibility: {
    expiry: false,
    storageName: 'Work Eligibility',
    baseField: 'eligibility',
    documentTypes: [
      { name: 'Permanent Residency Card', expiry: true },
      { name: 'Work Or Study Permit', expiry: true },
      { name: 'Canadian Birth Certificate', expiry: false },
      { name: 'Canadian Passport', expiry: false },
    ],
  },
  photo: {
    expiry: false,
    storageName: 'Driver Selfie',
    baseField: 'selfie',
  },
};

export const courierDocumentState = (document: CourierDocument) => {
  if (document.expired) return 'expired';
  if (document.approved) return 'approved';
  if (document.completed) return 'awaitingApproval';
  return 'incomplete';
};

export const courierCalculateDocuments = (driver: any): CourierDocuments => {
  return Object.entries(courierDocuments).reduce((result, [document, options]) => {
    const uploaded = !!driver[`${options.baseField}Uploaded`];
    const approved = !!driver[`${options.baseField}Approved`];
    let expiryDate: string | undefined;
    let documentType: string | undefined;
    let documentExpiry: boolean | undefined;
    let expired: boolean | undefined;
    let expiringSoon: boolean | undefined;
    let completed = uploaded;

    if (options.documentTypes) {
      documentType = driver[`${options.baseField}Type`];
      documentExpiry = options.documentTypes.find(type => type.name === documentType)?.expiry;
    }

    if (options.expiry || documentExpiry) {
      expiryDate = toDate(driver[`${options.baseField}Expiry`]);
      completed = completed && !!expiryDate;

      if (completed) {
        const date = dayjs(expiryDate);
        const now = dayjs();
        expired = now.isSameOrAfter(date);
        expiringSoon = expired ? false : now.isSameOrAfter(date.subtract(2, 'weeks'));
      }
    }

    const calculation = {
      approved,
      uploaded,
      completed,
      expiryDate,
      expired,
      expiringSoon,
      documentType,
    };

    const state = courierDocumentState(calculation);

    return {
      ...result,
      [document]: {
        ...calculation,
        state,
      },
    };
  }, {} as CourierDocuments);
};

export const courierAgreementsState = (courier: Courier) => {
  const completed = Object.values(courier.agreements).every(details => details.completed);
  const state = completed ? 'approved' : 'incomplete';
  return state;
};

export const courierDocumentsState = (courier: Courier) => {
  const completed = Object.values(courier.documents).every(document => document.completed);
  const expired = Object.values(courier.documents).find(document => document.expired);
  const approved = Object.values(courier.documents).every(document => document.approved);
  const state = completed ? 'awaitingApproval' : 'incomplete';
  if (state === 'awaitingApproval' && expired) return 'expired';
  if (approved) return 'approved';
  return state;
};

export const courierState = (courier: Courier) => {
  if (courier.rejected) return 'rejected';
  if (courier.suspended?.state) return 'suspended';
  if (courier.status === 'OnHold') return 'onHold';
  if (courier.status === 'PendingTraining') return 'pendingTraining';

  const state = courierCalculateCompleteness(courier) ? 'awaitingApproval' : 'incomplete';
  const documentsState = courierDocumentsState(courier);
  if (['Approved', 'PendingReapproval'].includes(courier.status) && documentsState === 'expired') {
    return 'expired';
  }
  if (courier.status === 'PendingReapproval') return 'awaitingReapproval';
  if (courier.approved) return 'approved';
  return state;
};

interface ColorSet {
  border: string;
  background: string;
  text: string;
  marker: string;
}
const colors: Record<string, ColorSet> = {
  Expired: {
    border: 'border-red-200',
    background: 'bg-red-200',
    text: 'text-red-800',
    marker: 'text-red-500',
  },
  Suspended: {
    border: 'border-pink-200',
    background: 'bg-pink-200',
    text: 'text-pink-800',
    marker: 'text-pink-500',
  },
  Incomplete: {
    border: 'border-blue-200',
    background: 'bg-blue-200',
    text: 'text-blue-800',
    marker: 'text-blue-500',
  },
  'Awaiting Approval': {
    border: 'border-yellow-200',
    background: 'bg-yellow-200',
    text: 'text-yellow-600',
    marker: 'text-yellow-500',
  },
  'Awaiting Reapproval': {
    border: 'border-yellow-200',
    background: 'bg-yellow-200',
    text: 'text-yellow-600',
    marker: 'text-yellow-500',
  },
  'Pending Training': {
    border: 'border-indigo-200',
    background: 'bg-indigo-200',
    text: 'text-indigo-600',
    marker: 'text-indigo-500',
  },
  Approved: {
    border: 'border-green-200',
    background: 'bg-green-200',
    text: 'text-green-800',
    marker: 'text-green-500',
  },
  Rejected: {
    border: 'border-red-200',
    background: 'bg-red-200',
    text: 'text-red-800',
    marker: 'text-red-500',
  },
  default: {
    border: 'border-gray-200',
    background: 'bg-gray-200',
    text: 'text-gray-800',
    marker: 'text-gray-500',
  },
};

const tierColors: Record<string, ColorSet> = {
  1: {
    border: 'border-purple-900',
    background: 'bg-purple-900',
    text: 'text-purple-200',
    marker: 'text-purple-500',
  },
  2: {
    border: 'border-purple-700',
    background: 'bg-purple-700',
    text: 'text-purple-200',
    marker: 'text-purple-500',
  },
  3: {
    border: 'border-purple-500',
    background: 'bg-purple-500',
    text: 'text-purple-200',
    marker: 'text-purple-500',
  },
  4: {
    border: 'border-purple-300',
    background: 'bg-purple-300',
    text: 'text-purple-500',
    marker: 'text-purple-500',
  },
  5: {
    border: 'border-purple-100',
    background: 'bg-purple-100',
    text: 'text-purple-800',
    marker: 'text-purple-500',
  },
  6: {
    border: 'border-pink-50',
    background: 'bg-pink-50',
    text: 'text-pink-800',
    marker: 'text-pink-500',
  },
  default: {
    border: 'border-gray-200',
    background: 'bg-gray-200',
    text: 'text-gray-800',
    marker: 'text-gray-500',
  },
};

const schedulingColors: Record<string, ColorSet> = {
  sameDay: {
    border: 'border-indigo-100',
    background: 'bg-indigo-100',
    text: 'text-indigo-900',
    marker: 'text-indigo-500',
  },
  nextDay: {
    border: 'border-indigo-200',
    background: 'bg-indigo-200',
    text: 'text-indigo-900',
    marker: 'text-indigo-500',
  },
};

const getColorSet = (state: string): ColorSet => {
  return colors[state] || colors.default;
};

const getTierColorSet = (tier: number): ColorSet => {
  return tierColors[tier] || tierColors.default;
};

const getSchedulingColorSet = (status: string): ColorSet => {
  return schedulingColors[status] || schedulingColors.default;
};

export const courierDisplayState = (courier: Courier): string => {
  return startCase(courierState(courier));
};

export const courierTextStyle = (courier: Courier): string => {
  return getColorSet(courierDisplayState(courier)).text;
};

export const courierBackgroundStyle = (courier: Courier): string => {
  return getColorSet(courierDisplayState(courier)).background;
};

export const courierStyle = (courier: Courier): string => {
  return `${courierBackgroundStyle(courier)} ${courierTextStyle(courier)}`;
};

export const courierTierStyle = (courier: Courier): string => {
  return `${getTierColorSet(courier.tier).background} ${getTierColorSet(courier.tier).text}`;
};

export const courierSchedulingStyle = (courier: Courier): string => {
  return `${getSchedulingColorSet(courier.schedulingGroup).background} ${
    getSchedulingColorSet(courier.schedulingGroup).text
  }`;
};

export const courierDocumentDisplayState = (document: CourierDocument): string => {
  return startCase(courierDocumentState(document));
};

export const courierDocumentStyle = (document: CourierDocument): string => {
  const { background, text } = getColorSet(courierDocumentDisplayState(document));
  return `${background} ${text}`;
};

export const courierStepTextStyle = (step: CourierDocument | CourierAgreement): string => {
  return getColorSet(startCase(step.state || undefined)).text;
};

const eligibilityMarkdownText = `Accepted documents include:
- Permanent Residency Card
- Canadian Birth Certificate
- Canadian Passport
- Work or Study Permit`;

interface CourierStep {
  name: string;
  section: string;
  title?: string;
  signup?: boolean;
  options: {
    title?: string;
    subtitle?: string;
    markdownText?: string;
    youtubeVideo?: string;
    typeform?: string;
  };
}

export const courierSteps: CourierStep[] = [
  {
    name: 'opportunity',
    section: 'training',
    signup: true,
    options: {
      title: 'The Opportunity',
      markdownText: `Tyltgo is the leading crowdsourced delivery service in Ontario, providing high-quality logistics solutions for small businesses and enterprises. We deliver for a variety of retailers - for example: meal kits, flower shops, prescription medicine, gift baskets, and other products.`,
      youtubeVideo: 'blqb_xMCR4o',
    },
  },
  // {
  //   name: 'review',
  //   section: 'training',
  //   signup: true,
  //   options: {
  //     title: 'Review',
  //     youtubeVideo: 'LYWOTCOaydA',
  //   },
  // },
  {
    name: 'quiz',
    section: 'training',
    title: 'Basic Information',
    signup: true,
    options: {
      title: 'Basic Information',
      typeform: 'Rmb46we6',
    },
  },
  {
    name: 'contract',
    section: 'agreements',
    options: {},
  },
  {
    name: 'socialCode',
    section: 'agreements',
    options: {},
  },
  {
    name: 'courierGuide',
    section: 'agreements',
    options: {},
  },
  {
    name: 'availability',
    section: 'settingsSteps',
    options: {
      title: 'Availability',
      subtitle: 'Tell us your availability so we know when to send you offers',
    },
  },
  {
    name: 'license',
    section: 'documents',
    options: {
      title: "Upload Driver's License",
      subtitle: 'Ensure that the image is clear and the expiration date is visible',
    },
  },
  {
    name: 'insurance',
    section: 'documents',
    options: {
      title: 'Upload Vehicle Insurance',
      subtitle: 'Ensure that the image is clear and the expiration date is visible',
      markdownText:
        'If you’re not the primary driver of the vehicle, please upload the insurance document that lists you as the secondary driver, alongside the vehicle and insurance details.',
    },
  },
  {
    name: 'ownership',
    section: 'documents',
    options: {
      title: 'Upload Vehicle Ownership',
      subtitle: 'Ensure that the image is clear',
    },
  },
  {
    name: 'eligibility',
    section: 'documents',
    options: {
      title: 'Upload Proof of Work Eligibility',
      subtitle: 'Ensure that the image is clear ',
      markdownText: eligibilityMarkdownText,
    },
  },
  {
    name: 'photo',
    section: 'documents',
    options: {
      title: 'Upload Photo',
      subtitle: 'Upload a clear photo of yourself',
    },
  },
  {
    name: 'nextStep',
    section: 'lastSteps',
    signup: true,
    options: {
      title: 'Next Step & Support',
      youtubeVideo: '5NHjicNdpWc',
    },
  },
];

export const courierStepsGrouped = (steps: CourierStep[] | undefined = undefined) => {
  return (steps || courierSteps).reduce((groups, step) => {
    return {
      ...groups,
      [step.section]: [...(groups[step.section] || []), step],
    };
  }, {} as Record<string, CourierStep[]>);
};

export const findCourierStep = (name: string, section = '') => {
  const steps = courierSteps.filter(step => step.name === name);
  if (section) {
    return steps.filter(step => section === step.section)[0];
  }
  return steps[0];
};

export const findRemainingSteps = (
  courier: Courier,
  steps: CourierStep[] | undefined = undefined
) => {
  return (steps || courierSteps).filter(step => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const state = (courier as any)[step.section]?.[step.name]?.state;
    if (['approved', 'awaitingApproval'].includes(state)) {
      return false;
    }
    return true;
  });
};

export const courierProfileMessage = (courier: Courier) => {
  if ([courier.documentsState, courier.agreementsState, courier.state].includes('incomplete')) {
    return 'Finish the remaining steps to complete your profile';
  }
  if ([courier.documentsState, courier.state].includes('expired')) {
    return 'Update the expired documents in your profile';
  }
  if (['pendingTraining'].includes(courier.state || '')) {
    return 'Your account is pending training.';
  }
  if (['onHold'].includes(courier.state || '')) {
    return 'Your account is on hold. Please contact support@tyltgo.com.';
  }
  if (['awaitingApproval', 'awaitingReapproval'].includes(courier.state || '')) {
    return 'Your account is awaiting approval';
  }
  return '';
};

// TODO: move veem stuff to different file later.
export const veemStatuses = {
  Drafted: {
    status: 'Scheduled',
    message: 'Please expect your pay to be processed every Monday and deposited by every Friday.',
  },
  Sent: {
    status: 'Submitted',
    message:
      'The payment is being processed. To receive your pay, please check your inbox for an email from Veem, and complete next steps outlined.',
  },
  PendingAuth: {
    status: 'Pending Approval by Veem',
    message:
      'The payment is being processed. Please make sure your banking information on Veem is accurate.',
  },
  Authorized: {
    status: 'Submitted (waiting on Veem)',
    message:
      'The payment is being processed. Please expect it to be deposited within 2-3 business days.',
  },
  InProgress: {
    status: 'In Progress',
    message:
      'The payment is being processed. Please expect it to be deposited within 2-3 business days.',
  },
  Complete: {
    status: 'Deposited',
    message: 'The payment is complete and has been deposited to your bank account.',
  },
  Cancelled: {
    status: 'Cancelled',
    message: 'The payment has been cancelled by Tyltgo.',
  },
  Closed: {
    status: 'Closed',
    message: 'The payment has been closed by Veem.',
  },
  Unknown: {
    status: 'Unknown',
    message:
      'We encountered an error processing your pay. Please reach out to Tyltgo Support to resolve the issue.',
  },
};

export const veemDateTime = (time: string) => {
  if (!time) {
    return 'Pending Creation';
  }
  const times = time.split('T').join(' ');
  return dayjs(times.replace('Z', '-12:00'), 'YYYY-DD-MM HH:mm:ss.SSSZ').format(
    'YYYY-MM-DD h:mm A'
  );
};

export const paymentStatus = (status: keyof typeof veemStatuses) => {
  if (veemStatuses[status]) {
    return veemStatuses[status];
  }

  return veemStatuses.Unknown;
};

export const courierUnofferableReasons = (courier: Courier, isExcludedCourier?: boolean) => {
  if (!courier.state) return 'Courier has no state.';
  if (
    [
      'incomplete',
      'awaitingApproval',
      'rejected',
      'suspended',
      'onHold',
      'pendingTraining',
    ].includes(courier.state)
  )
    return `Status is ${courier.state}`;
  if (courier.availabilityStatus === 'paused') return `Availability is paused.`;

  if (isExcludedCourier || false) return 'Excluded from merchant(s).';

  return '';
};

export function isCourierAvailableForRoute(courier: Courier, route: Route, currentDay: DayOfWeek) {
  const availability = courier.availability[currentDay];
  const pickupNumber = timeToNumber(dayjs(route.cutoffTime).format('HH:mm'));
  const availabilityBufferInHours = 1;
  const available = availability?.find(a => {
    return (
      pickupNumber >= a.startNumber - availabilityBufferInHours &&
      pickupNumber <= a.endNumber + availabilityBufferInHours
    );
  });
  return available;
}

export function courierHasOverlappingRoute(route: Route, courierAssignedRoutes: Route[]) {
  const forRouteTimeNumbers = routeTimeNumbers(route);
  const overlappingRoute = courierAssignedRoutes.find(a => {
    return isOverlapping(forRouteTimeNumbers, routeTimeNumbers(a));
  });
  return !!overlappingRoute;
}
