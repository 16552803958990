<template>
  <main class="flex-1 overflow-y-auto focus:outline-none" tabindex="0">
    <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
      <div class="sm:px-6 md:px-0 print:px-0">
        <MerchantInvoiceBlock v-if="charge" :invoice="charge" :merchant="currentUser" />
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { apiService } from '@tyltgo/shared';
import { currentUser } from '../../services/auth-service';
import MerchantInvoiceBlock from '../../components/MerchantInvoiceBlock.vue';

export default defineComponent({
  setup() {
    return {
      currentUser,
    };
  },
  components: {
    MerchantInvoiceBlock,
  },
  async mounted() {
    this.loading = true;
    const { charge } = await apiService.merchants.billing.charge(this.$route.params.id);
    this.charge = charge;
    this.loading = false;
  },
  data() {
    return {
      loading: false,
      charge: null,
    };
  },
});
</script>
