<template>
  <div class="py-6 space-x-2">
    <Form class="space-y-4 mb-8" @submit="getCities">
      <GoAddressField label="Pickup Address" name="pickupAddress" />
      <GoTextField type="number" label="Radius (km)" name="radius" :value="75" class="w-1/3" />
      <div class="flex items-center space-x-6">
        <GoButton>Get Cities</GoButton>
      </div>
    </Form>
    <Form v-if="cities.length" class="space-y-4" @submit="getAddresses">
      <GoTextArea label="Cities" name="cities" :value="citiesString" class="w-1/3 h-96 mb-12" />
      <GoTextField
        type="number"
        label="# of Simulations"
        name="numberOfSimulations"
        :value="100"
        class="w-1/3"
      />
      <GoTextField
        type="number"
        label="Order Per Day"
        name="ordersPerDay"
        :value="125"
        class="w-1/3"
      />
      <GoHiddenField name="pickupAddress" v-model="pickupAddress" />
      <div class="flex items-center space-x-6 pt-8">
        <GoButton>Get Addresses</GoButton>
      </div>
    </Form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
import { apiService } from '@tyltgo/shared';
import { downloadBlob } from '@/services/browser-service';
import { aroundLoadingMessage } from '@/services/ui-message-service';

export default defineComponent({
  components: {
    Form,
  },
  data() {
    return {
      cities: [],
      pickupAddress: null,
    };
  },
  computed: {
    citiesString() {
      return this.cities.map(city => `${city.serviceCity} ${city.percentage}`).join('\n');
    },
  },
  methods: {
    async getCities(values) {
      this.pickupAddress = values.pickupAddress;
      aroundLoadingMessage('Getting Cities...', async () => {
        const result = await apiService.admin.pricing.getCities(values);
        console.log(result);
        this.cities = result.cities;
      });
    },
    async getAddresses(values) {
      aroundLoadingMessage('Generating Csv', async () => {
        const csv = await apiService.admin.pricing.getAddresses(values);
        console.log(csv);
        const blob = new Blob([csv], { type: 'text/csv' });
        downloadBlob(blob, `pricing-sample-${new Date().toISOString()}.csv`);
      });
    },
  },
});
</script>
