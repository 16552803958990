<template>
  <main class="min-h-screen flex-1 focus:outline-none" tabindex="0" v-if="currentUser?.email">
    <div class="bg-brand-1 px-4">
      <div class="flex max-w-7xl mx-auto py-4 items-center justify-between">
        <div class="flex items-center">
          <a href="https://www.tyltgo.com" class="text-4xl font-logo text-gray-100">Tyltgo</a>
          <p class="ml-12 text-white font-header font-bold text-2xl">Courier Signup</p>
        </div>
        <div class="hidden md:flex text-white flex-col" v-if="currentUser">
          <p class="text-xs">{{ currentUser.email }}</p>
          <p class="text-sm">{{ currentUser.firstName }} {{ currentUser.lastName }}</p>
        </div>
      </div>
    </div>
    <div class="relative max-w-5xl mx-auto md:px-8 xl:px-0 md:py-12">
      <GoAlert v-if="!completed" color="information" class="mb-4">
        Complete all the steps below to finish the signup process.
      </GoAlert>
      <CourierSteps :steps="steps" stepRouteName="courier.signup.step">
        <div class="flex flex-col items-center justify-center sm:block sm:p-0 h-full">
          <div class="h-1/3">&nbsp;</div>
          <div class="flex flex-col items-center justify-center md:w-2/3 mx-auto">
            <IconCheckCircle class="h-12 flex-shrink-0 text-green-300 mb-8" />
            <h2 class="font-bold font-header text-2xl mb-4">Signup Application Completed</h2>
            <p class="text-lg text-center">Thank you for completing your application!</p>
            <p class="text-lg text-center">
              Your application is under review and you will only be contacted if it is approved. You
              can log back into your account to view the status of your application at any time.
            </p>
          </div>
          <div class="h-1/3">&nbsp;</div>
        </div>
      </CourierSteps>
    </div>
    <GoMessages />
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { courierSteps, findRemainingSteps } from '@tyltgo/shared';
import CourierSteps from '../../components/CourierSteps.vue';
import GoAlert from '../../components/GoAlert.vue';
import GoMessages from '../../components/GoMessages.vue';
import IconCheckCircle from '../../components/IconCheckCircle.vue';
import { currentUser, startupAuth } from '../../services/auth-service';

export default defineComponent({
  setup() {
    return {
      currentUser,
    };
  },
  components: {
    CourierSteps,
    GoAlert,
    GoMessages,
    IconCheckCircle,
  },
  data() {
    return {};
  },
  async beforeCreate() {
    await startupAuth('courier');
    if (!currentUser.value?.email) {
      this.$router.push({ path: '/courier/signup' });
    }
  },
  mounted() {
    const { name } = this.$route.params;
    if (!name) {
      const remainingSteps = findRemainingSteps(this.currentUser, this.steps);
      const step = remainingSteps[0];
      this.$router.replace({
        name: 'courier.signup.step',
        params: { section: step?.section || 'completed', name: step?.name || 'done' },
      });
    }
  },
  computed: {
    completed() {
      return findRemainingSteps(this.currentUser, this.steps).length === 0;
    },
    steps() {
      return courierSteps;
    },
  },
  methods: {},
});
</script>
