<template>
  <Form @submit="saveChanges" :initial-values="initialValues" :validation-schema="schema">
    <div class="space-y-3">
      <div class="bg-white dark:bg-gray-800 pt-2 shadow rounded-lg mb-4 flex flex-col p-4">
        <h3 class="text-sm font-bold uppercase my-4">Business Information</h3>
        <div class="mt-3 grid grid-cols-1 sm:grid-cols-3 gap-6">
          <GoTextField class="col-span-1" label="Business Name" name="businessName" />
          <GoTextField class="col-span-1" label="Contact First Name" name="firstName" />
          <GoTextField class="col-span-1" label="Contact Last Name" name="lastName" />
        </div>
        <h3 class="text-sm font-bold uppercase my-4">"Unable to Deliver" Emails</h3>
        <div class="mt-3 grid grid-cols-1 sm:grid-cols-3 gap-3">
          <FieldArray name="bringBackEmails" v-slot="{ fields, push, remove }">
            <div
              v-for="(entry, index) in fields"
              :key="entry.key"
              class="col-start-1 col-end-1 flex items-start"
            >
              <GoTextField
                type="email"
                placeholder="you@example.com"
                label="false"
                :name="`bringBackEmails[${index}]`"
                class="w-full"
              />
              <GoButton
                @click="remove(index)"
                class="text-xs px-3 pt-3"
                variant="link"
                type="button"
              >
                <IconTimes class="w-3" />
              </GoButton>
            </div>
            <div class="col-start-1 col-end-1">
              <GoButton type="button" class="bg-gray-100 mt-5" @click="push('')" variant="badge">
                <div class="flex">
                  <IconPlus class="w-2 mr-2" />
                  Add Email
                </div>
              </GoButton>
            </div>
          </FieldArray>
        </div>
      </div>

      <div class="bg-white dark:bg-gray-800 pt-2 shadow rounded-lg mb-4 flex flex-col p-4">
        <h3 class="text-sm font-bold uppercase my-4">Routing Settings</h3>
        <div class="mt-3 grid grid-cols-1 sm:grid-cols-3 gap-6">
          <GoHiddenField name="settings.additionalQuantityPrice" />
          <GoTextField
            class="col-span-1"
            label="Batch Capacity"
            name="settings.batchCapacity"
            type="number"
          />
          <GoTextField
            class="col-span-1"
            label="Volume Capacity (cubic feet)"
            name="settings.volumeCapacity"
            type="number"
          />
          <GoTextField
            class="col-span-1"
            label="Time Constraint (hours)"
            name="settings.timeConstraint"
            type="number"
          />
        </div>
      </div>

      <div class="bg-white dark:bg-gray-800 pt-2 shadow rounded-lg mb-4 flex flex-col p-4">
        <h3 class="text-sm font-bold uppercase my-4">Scheduling Settings</h3>
        <div class="mt-3 grid grid-cols-1 sm:grid-cols-3 gap-6">
          <GoTextField
            class="col-span-1"
            label="Do not auto/mass schedule until"
            name="settings.doNotScheduleUntil"
            type="time"
          />
          <GoMultiselect
            class="col-span-2"
            :name="`settings.capabilitiesNeeded`"
            label="Capabilities Needed"
            mode="tags"
            :options="{
              smartServe: 'Smart Serve',
              newMerchant: 'New Merchant',
              watchlisted: 'Watchlisted',
            }"
            v-model="capabilitiesNeeded"
          />
          <GoCheckbox
            :value="true"
            class="col-span-1"
            label="Exclude from Mass Scheduling"
            name="settings.excludeFromMassScheduling"
          />
          <GoCheckbox
            :value="true"
            class="col-span-1"
            label="Exclude from Auto Scheduling"
            name="settings.excludeFromAutoScheduling"
          />
        </div>
      </div>

      <div class="bg-white dark:bg-gray-800 pt-2 shadow rounded-lg mb-4 flex flex-col p-4">
        <h3 class="text-sm font-bold uppercase my-4">Label Settings</h3>
        <div class="mt-3 grid grid-cols-1 sm:grid-cols-3 gap-6">
          <GoCheckbox
            :value="true"
            class="col-span-1"
            label="Remove Dropoff Number on Label"
            name="settings.removeDropoffNumberLabel"
          />
          <GoCheckbox
            :value="true"
            class="col-span-1"
            label="Show Unrouted Labels"
            name="settings.showUnroutedLabels"
          />
          <GoTextField
            class="col-span-3"
            label="Label Logo Image Url"
            name="settings.labelLogoImageUrl"
            help="Image should be png or jpg"
          />
        </div>
      </div>

      <div class="bg-white dark:bg-gray-800 pt-2 shadow rounded-lg mb-4 flex flex-col p-4">
        <h3 class="text-sm font-bold uppercase my-4">Notifications Settings</h3>
        <div class="mt-3 grid grid-cols-1 sm:grid-cols-3 gap-6">
          <GoTextField
            class="col-span-1"
            label="Delivery Cutoff Time"
            name="settings.deliveryCutoffTime"
            type="time"
          />
          <GoTextField
            class="col-span-1"
            label="Merchant Slack Channel"
            name="settings.merchantSlackChannel"
          />
          <GoTextField
            class="col-span-1"
            label="Merchant Text Phone Number"
            name="settings.recipientFromPhoneNumber"
          />
          <GoCheckbox
            :value="true"
            class="col-span-1"
            label="Send Recipient Notification Emails"
            name="settings.sendRecipientNotificationEmails"
          />
        </div>
      </div>

      <div class="bg-white dark:bg-gray-800 pt-2 shadow rounded-lg mb-4 flex flex-col p-4">
        <h3 class="text-sm font-bold uppercase my-4">Reverse Logistics Settings</h3>
        <div class="mt-3 grid grid-cols-1 sm:grid-cols-3 gap-6">
          <GoCheckbox
            :value="true"
            class="col-span-1"
            label="Enable Reverse Logistics"
            name="settings.reverseLogisticsEnabled"
          />
          <GoTextField
            class="col-span-1"
            label="Item Name (plural)"
            name="settings.reverseLogisticsItem"
          />
        </div>
        <div class="mt-3 grid grid-cols-1 sm:grid-cols-3 gap-6">
          <GoCheckbox
            :value="true"
            class="col-span-1"
            label="Automate Return Dropoff"
            name="settings.reverseLogisticsAutomateReturn"
          />
          <GoAddressField
            label="Return Address (if different from pickup)"
            name="settings.reverseLogisticsReturnAddress"
            class="col-span-1"
          />
        </div>
        <div class="mt-6">
          <GoTextArea
            class="col-span-1 sm:col-span-3"
            label="Return Dropoff Message"
            name="settings.reverseLogisticsDropoffMessage"
          />
        </div>
      </div>

      <div class="bg-white dark:bg-gray-800 pt-2 shadow rounded-lg mb-4 flex flex-col p-4">
        <h3 class="text-sm font-bold uppercase my-4">Tracking Page</h3>
        <div class="py-2 text-sm">
          <a :href="trackingPageLink" target="_blank">Test Tracking Link</a>
        </div>
        <div class="mt-3 grid grid-cols-1 sm:grid-cols-3 gap-6">
          <GoTextField class="col-span-1" label="Tracking Key" name="trackingKey" />
          <GoTextField class="col-span-1" label="Tracking Slug" name="trackingSlug" />
          <GoTextField
            class="col-span-1"
            label="Title"
            placeholder="Track your ..."
            name="settings.trackingPage.title"
          />
          <GoSelect
            class="col-span-1"
            placeholder="Select Background Type"
            label="Background Type"
            name="settings.trackingPage.background"
            :options="{
              light: 'Light',
              dark: 'Dark',
            }"
          />
          <GoTextField
            class="col-span-1"
            type="color"
            label="Brand Color"
            name="settings.trackingPage.brandColor"
          />
          <GoCheckbox
            :value="true"
            class="col-span-1"
            label="Disable Tips"
            name="settings.trackingPage.disableTip"
          />
          <GoTextField
            class="sm:col-span-1 col-span-1"
            label="Support Email To Display"
            name="settings.trackingPage.supportEmail"
          />
          <GoTextField
            class="sm:col-span-3 col-span-1"
            label="Logo Image Url"
            name="settings.trackingPage.logoImageUrl"
          />
          <GoTextField
            class="sm:col-span-3 col-span-1"
            label="Large Image Url"
            name="settings.trackingPage.largeImageUrl"
          />
          <GoTextArea class="col-span-3" label="Blurb" name="settings.trackingPage.blurb" />
        </div>
      </div>

      <div class="bg-white dark:bg-gray-800 pt-2 shadow rounded-lg mb-4 flex flex-col p-4">
        <h3 class="text-sm font-bold uppercase my-4">Payment Settings</h3>
        <div class="py-2 text-sm">
          <a href="https://app.qbo.intuit.com/app/customers?addNew=true" target="_blank">
            Quickbooks
          </a>
        </div>
        <div class="mt-3 grid grid-cols-1 sm:grid-cols-3 gap-6">
          <GoSelect
            class="col-span-1"
            placeholder="Select Receipt Type"
            label="Receipt Type"
            name="receiptType"
            :options="{
              csv: 'CSV',
              pdf: 'PDF',
            }"
          />
          <GoSelect
            class="col-span-1"
            placeholder="Select Charge Method"
            label="Charge Method"
            name="chargeMethod"
            :options="{
              'credit-card': 'Credit Card',
              quickbooks: 'Quickbooks',
              pad: 'Pre-authorized Debit',
            }"
          />
          <GoTextField
            class="col-span-1"
            label="Quickbooks Customer Id"
            name="quickbooksCustomerId"
          />
          <div class="col-span-1 sm:col-span-2">
            <GoHiddenField name="invoiceAddress.address1" v-model="invoiceAddress.address1" />
            <GoHiddenField name="invoiceAddress.city" v-model="invoiceAddress.city" />
            <GoHiddenField name="invoiceAddress.zip" v-model="invoiceAddress.zip" />
            <GoHiddenField name="invoiceAddress.state" v-model="invoiceAddress.state" />
            <GoAddressField
              label="Invoice Address"
              name="invoiceAddressField"
              placeholder="Update Invoice Address"
              @place-selected="placeSelected"
            />
            <div v-if="invoiceAddress.address1 || merchant.invoiceAddress.address1" class="mt-2">
              {{ invoiceAddress.address1 || merchant.invoiceAddress.address1 }},
              {{ invoiceAddress.city || merchant.invoiceAddress.city }},
              {{ invoiceAddress.state || merchant.invoiceAddress.state }}
              {{ invoiceAddress.zip || merchant.invoiceAddress.zip }}
            </div>
          </div>
          <GoTextArea
            class="col-span-1 sm:col-span-3"
            label="Invoice Notes"
            name="settings.invoiceNotes"
          />
          <GoSelect
            class="col-span-1"
            placeholder="Select Billing Cadence"
            label="Billing Cadence"
            name="settings.billingCadence"
            :options="{
              none: 'None',
              weekly: 'Weekly',
              biweekly: 'Bi-weekly',
              monthly: 'Monthly',
            }"
          />
        </div>

        <h3 class="text-sm font-bold uppercase my-4">Invoice Emails</h3>
        <div class="mt-3 grid grid-cols-1 sm:grid-cols-3 gap-3">
          <FieldArray name="invoiceEmails" v-slot="{ fields, push, remove }">
            <div
              v-for="(entry, index) in fields"
              :key="entry.key"
              class="col-start-1 col-end-1 flex items-start"
            >
              <GoTextField
                type="email"
                placeholder="you@example.com"
                label="false"
                :name="`invoiceEmails[${index}]`"
                class="w-full"
              />
              <GoButton
                @click="remove(index)"
                class="text-xs px-3 pt-3"
                variant="link"
                type="button"
              >
                <IconTimes class="w-3" />
              </GoButton>
            </div>
            <div class="col-start-1 col-end-1">
              <GoButton type="button" class="bg-gray-100 mt-5" @click="push('')" variant="badge">
                <div class="flex">
                  <IconPlus class="w-2 mr-2" />
                  Add Email
                </div>
              </GoButton>
            </div>
          </FieldArray>
        </div>
      </div>
      <div class="bg-white dark:bg-gray-800 pt-2 shadow rounded-lg mb-4 flex flex-col p-4">
        <h3 class="text-sm font-bold uppercase my-4">Courier App Guided Messaging</h3>
        <div class="mt-3 grid grid-cols-1 sm:grid-cols-3 gap-6">
          <GoTextArea
            class="col-span-1 sm:col-span-3"
            label="Pickup Message"
            name="settings.pickupMessage"
          />
          <GoTextArea
            class="col-span-1 sm:col-span-3"
            label="Drop-off Message"
            name="settings.dropoffMessage"
          />
          <GoTextArea
            class="col-span-1 sm:col-span-3"
            label="Pre-route Message"
            name="settings.preRouteMessage"
          />
        </div>
      </div>
      <div class="bg-white dark:bg-gray-800 pt-2 shadow rounded-lg mb-4 flex flex-col p-4">
        <h3 class="text-sm font-bold uppercase my-4">Courier App Delivery Options</h3>
        <div class="mt-3 grid grid-cols-1 sm:grid-cols-3 gap-6">
          <GoCheckboxGroup
            class="col-span-1 sm:col-span-3"
            label="Delivery Options"
            name="settings.deliveryOptions"
            :options="deliveryOptions.map(x => ({ label: x, value: x }))"
          />
        </div>
      </div>
      <div class="bg-white dark:bg-gray-800 pt-2 shadow rounded-lg mb-4 flex flex-col p-4">
        <h3 class="text-sm font-bold uppercase my-4">Notes</h3>
        <div class="mt-3 grid grid-cols-1 sm:grid-cols-3 gap-6">
          <GoTextArea
            class="col-span-1 sm:col-span-3"
            label="Scheduling Notes"
            name="settings.schedulingNotes"
          />
        </div>
      </div>
      <div class="bg-white dark:bg-gray-800 pt-2 shadow rounded-lg mb-4 flex flex-col p-4">
        <h3 class="text-sm font-bold uppercase my-4">Excluded Couriers</h3>
        <div class="mt-3 grid grid-cols-1 sm:grid-cols-3 gap-3">
          <FieldArray name="excludedCouriers" v-slot="{ fields, push, remove }">
            <div
              v-for="(entry, index) in fields"
              :key="entry.key"
              class="col-start-1 col-end-1 flex items-start"
            >
              <GoTextField
                type="email"
                placeholder="you@example.com"
                label="false"
                :name="`excludedCouriers[${index}]`"
                class="w-full"
              />
              <GoButton
                @click="remove(index)"
                class="text-xs px-3 pt-3"
                variant="link"
                type="button"
              >
                <IconTimes class="w-3" />
              </GoButton>
            </div>
            <div class="col-start-1 col-end-1">
              <GoButton type="button" class="bg-gray-100 mt-5" @click="push('')" variant="badge">
                <div class="flex">
                  <IconPlus class="w-2 mr-2" />
                  Add Courier Email
                </div>
              </GoButton>
            </div>
          </FieldArray>
        </div>
      </div>

      <div class="bg-white dark:bg-gray-800 pt-2 shadow rounded-lg mb-4 flex flex-col p-4">
        <h3 class="text-sm font-bold uppercase my-4">Heirarchy Settings</h3>
        <div class="mt-3 grid grid-cols-1 sm:grid-cols-3 gap-y-1">
          <GoCheckbox
            :value="true"
            class="col-span-1 sm:col-span-3"
            label="Parent"
            name="settings.parent"
          />

          <GoLabel class="mt-3 col-span-3">Routing Parent Businesses</GoLabel>
          <FieldArray name="parentIds" v-slot="{ fields, push, remove }">
            <div
              v-for="(entry, index) in fields"
              :key="entry.key"
              class="col-start-1 col-end-1 flex items-start"
            >
              <GoTextField
                type="email"
                placeholder="you@example.com"
                label="false"
                :name="`parentIds[${index}]`"
                class="w-full"
              />
              <GoButton
                @click="remove(index)"
                class="text-xs px-3 pt-3"
                variant="link"
                type="button"
              >
                <IconTimes class="w-3" />
              </GoButton>
            </div>
            <div class="col-start-1 col-end-1">
              <GoButton type="button" class="bg-gray-100 mt-5" @click="push('')" variant="badge">
                <div class="flex">
                  <IconPlus class="w-2 mr-2" />
                  Add Routing Parent
                </div>
              </GoButton>
            </div>
          </FieldArray>

          <GoLabel class="mt-3 col-span-3">Fulfillment Parent Businesses</GoLabel>
          <FieldArray name="fulfillmentParentIds" v-slot="{ fields, push, remove }">
            <div
              v-for="(entry, index) in fields"
              :key="entry.key"
              class="col-start-1 col-end-1 flex items-start"
            >
              <GoTextField
                type="email"
                placeholder="you@example.com"
                label="false"
                :name="`fulfillmentParentIds[${index}]`"
                class="w-full"
              />
              <GoButton
                @click="remove(index)"
                class="text-xs px-3 pt-3"
                variant="link"
                type="button"
              >
                <IconTimes class="w-3" />
              </GoButton>
            </div>
            <div class="col-start-1 col-end-1">
              <GoButton type="button" class="bg-gray-100 mt-5" @click="push('')" variant="badge">
                <div class="flex">
                  <IconPlus class="w-2 mr-2" />
                  Add Fulfillment Parent
                </div>
              </GoButton>
            </div>
          </FieldArray>
        </div>
      </div>

      <div class="bg-white dark:bg-gray-800 pt-2 shadow rounded-lg mb-4 flex flex-col p-4">
        <h3 class="text-sm font-bold uppercase my-4">Routing Groups</h3>
        <GoTextField
          type="email"
          placeholder="you@example.com"
          label="Routing Info Email"
          :name="`settings.routingInfoEmail`"
          class="w-1/3 py-3"
        />
        <div class="mt-3 grid grid-cols-1 sm:grid-cols-3 gap-y-1">
          <FieldArray name="settings.routingGroups" v-slot="{ fields, push, remove }">
            <div
              v-for="(entry, index) in fields"
              :key="entry.key"
              class="col-span-3 space-y-1 border-b pb-2 grid grid-cols-3 gap-3 mb-3"
            >
              <div class="flex space-x-2 items-center justify-between col-span-3">
                <pre>#{{ index + 1 }}</pre>
                <GoButton @click="remove(index)" class="text-xs" variant="link" type="button">
                  <IconTimes class="w-3" />
                </GoButton>
              </div>
              <GoTextField
                :name="`settings.routingGroups[${index}].name`"
                label="Name"
                class="w-full"
              />
              <GoAddressField
                label="Pickup Address"
                :name="`settings.routingGroups[${index}].pickUpLocation`"
                class="col-span-2"
              />
              <GoTextField
                type="time"
                :name="`settings.routingGroups[${index}].pickupTime`"
                label="Pickup Time (Pickup Address Timezone)"
                class="col-span-1"
              />
              <GoTextField
                :name="`settings.routingGroups[${index}].timeConstraint`"
                label="Time Constraint (hours)"
                class="col-span-1"
              />
              <div class="col-span-1"></div>
              <GoTextField
                type="number"
                :name="`settings.routingGroups[${index}].pickupTimeStagger`"
                label="Pickup Time Stagger (minutes)"
                class="col-span-1"
              />
              <GoTextField
                type="number"
                :name="`settings.routingGroups[${index}].pickupTimeStaggerGroup`"
                label="Pickup Time Stagger Group"
                class="col-span-1"
              />
              <div class="col-span-1"></div>
              <GoTextArea
                label="Postal Codes (one per line)"
                :name="`settings.routingGroups[${index}].postalCodes`"
              />
              <GoCheckbox
                :name="`settings.routingGroups[${index}].isLineHaul`"
                label="Is Linehaul?"
                :value="true"
              />
              <GoCheckbox
                :name="`settings.routingGroups[${index}].isMiddleMile`"
                label="Is Middle Mile?"
                :value="true"
              />
              <template v-if="entry.value.isMiddleMile">
                <h3 class="col-span-3 text-sm font-bold uppercase my-4">
                  Middle Mile for #{{ index + 1 }} {{ entry.value.name }}
                </h3>
                <GoTextField
                  :name="`settings.routingGroups[${index}].middleMileGroup`"
                  label="Middle Mile Group"
                  class="w-full"
                />
                <GoAddressField
                  label="Middle Mile Address"
                  :name="`settings.routingGroups[${index}].middleMileAddress`"
                  class="col-span-2"
                />
              </template>
              <FieldArray
                :name="`settings.routingGroups[${index}].deliveryDays`"
                v-slot="{
                  fields: deliveryDayFields,
                  push: pushDeliveryDay,
                  remove: removeDeliveryDay,
                }"
              >
                <h3 class="col-span-3 text-sm font-bold uppercase my-4">
                  Delivery Days for #{{ index + 1 }} {{ entry.value.name }}
                </h3>
                <div class="col-span-3 flex flex-row">
                  <div class="w-3"></div>
                  <div>
                    <div
                      v-for="(deliveryDayEntry, deliveryDayIndex) in deliveryDayFields"
                      :key="deliveryDayEntry.key"
                      class="col-span-3 pb-2 flex flex-row gap-3 mb-3 items-start"
                    >
                      <GoSelect
                        placeholder="Select Day"
                        :options="DayOfWeekList"
                        :name="`settings.routingGroups[${index}].deliveryDays[${deliveryDayIndex}].day`"
                        label="Delivery Day"
                        class="w-full"
                      />
                      <GoSelect
                        placeholder="Select Day"
                        :options="DayOfWeekList"
                        :name="`settings.routingGroups[${index}].deliveryDays[${deliveryDayIndex}].routingDay`"
                        label="Routing Day"
                        class="w-full"
                      />
                      <GoTextField
                        type="time"
                        :name="`settings.routingGroups[${index}].deliveryDays[${deliveryDayIndex}].routingTime`"
                        label="Routing Time (EST)"
                        class="w-full"
                      />
                      <GoTextField
                        type="time"
                        :name="`settings.routingGroups[${index}].deliveryDays[${deliveryDayIndex}].integrationPullTime`"
                        label="Integration Pull Time (EST)"
                        class="w-full"
                      />
                      <GoButton
                        @click="removeDeliveryDay(deliveryDayIndex)"
                        class="text-xs"
                        variant="link"
                        type="button"
                      >
                        <IconTimes class="w-3" />
                      </GoButton>

                      <GoButton
                        v-if="canRouteNow"
                        @click="routeDeliveryDay(deliveryDayIndex, entry.value.name)"
                        class="text-xs"
                        type="button"
                      >
                        Route Now
                      </GoButton>
                    </div>
                    <div class="col-span-3 flex flex-row">
                      <GoButton
                        type="button"
                        class="bg-gray-100 mt-5"
                        @click="pushDeliveryDay({})"
                        variant="badge"
                      >
                        <div class="flex">
                          <IconPlus class="w-2 mr-2" />
                          Add Delivery Day to {{ entry.value.name }}
                        </div>
                      </GoButton>
                    </div>
                  </div>
                </div>
              </FieldArray>

              <FieldArray
                :name="`settings.routingGroups[${index}].vehicleTypes`"
                v-slot="{
                  fields: vehicleTypeFields,
                  push: pushVehicleType,
                  remove: removeVehicleType,
                }"
              >
                <h3 class="col-span-3 text-sm font-bold uppercase my-4">
                  Vehicle Types for #{{ index + 1 }} {{ entry.value.name }}
                </h3>
                <div class="col-span-3 flex flex-row">
                  <div class="w-3"></div>
                  <div class="w-full">
                    <div
                      v-for="(vehicleTypeEntry, vehicleTypeIndex) in vehicleTypeFields"
                      :key="vehicleTypeEntry.key"
                      class="grid grid-cols-4 pb-2 gap-3 mb-3 items-center w-full"
                    >
                      <GoTextField
                        :name="`settings.routingGroups[${index}].vehicleTypes[${vehicleTypeIndex}].name`"
                        label="Name"
                        class="col-span-1"
                      />
                      <GoCheckbox
                        :value="true"
                        class="col-span-1"
                        label="Name Capability"
                        :name="`settings.routingGroups[${index}].vehicleTypes[${vehicleTypeIndex}].capability`"
                      />
                      <GoSelect
                        placeholder="Select Vehicle Type"
                        :options="{
                          'Sprinter Van': 'Sprinter Van',
                          'Cargo Van': 'Cargo Van',
                          Minivan: 'Minivan',
                        }"
                        :name="`settings.routingGroups[${index}].vehicleTypes[${vehicleTypeIndex}].type`"
                        label="Type"
                        class="col-span-1"
                      />
                      <GoTextField
                        type="number"
                        :name="`settings.routingGroups[${index}].vehicleTypes[${vehicleTypeIndex}].count`"
                        label="Count of vehicles"
                        class="col-span-1"
                      />
                      <GoTextField
                        type="number"
                        :name="`settings.routingGroups[${index}].vehicleTypes[${vehicleTypeIndex}].costFactor`"
                        label="Cost Factor"
                        class="col-span-1"
                      />
                      <GoTextField
                        type="number"
                        :name="`settings.routingGroups[${index}].vehicleTypes[${vehicleTypeIndex}].maxDistance`"
                        label="Max Distance (km)"
                        class="col-span-1"
                      />
                      <GoAddressField
                        label="End Address"
                        :name="`settings.routingGroups[${index}].vehicleTypes[${vehicleTypeIndex}].endLocation`"
                        class="col-span-1"
                      />
                      <GoButton
                        @click="removeVehicleType(vehicleTypeIndex)"
                        class="text-xs"
                        variant="link"
                        type="button"
                      >
                        <IconTimes class="w-3" />
                      </GoButton>
                    </div>
                    <div class="col-span-3 flex flex-row">
                      <GoButton
                        type="button"
                        class="bg-gray-100 mt-5"
                        @click="pushVehicleType({})"
                        variant="badge"
                      >
                        <div class="flex">
                          <IconPlus class="w-2 mr-2" />
                          Add Vehicle Type to {{ entry.value.name }}
                        </div>
                      </GoButton>
                    </div>
                  </div>
                </div>
              </FieldArray>
            </div>
            <div class="col-start-1 col-end-1">
              <GoButton type="button" class="bg-gray-100 mt-5" @click="push({})" variant="badge">
                <div class="flex">
                  <IconPlus class="w-2 mr-2" />
                  Add Routing Group
                </div>
              </GoButton>
            </div>
          </FieldArray>
        </div>
      </div>

      <div class="bg-white dark:bg-gray-800 pt-2 shadow rounded-lg mb-4 flex flex-col p-4">
        <h3 class="text-sm font-bold uppercase my-4">Misc Settings</h3>
        <div class="mt-3 grid grid-cols-1 sm:grid-cols-3 gap-y-1">
          <GoCheckbox
            :value="true"
            class="col-span-1 sm:col-span-3"
            label="Can Change Quantity"
            name="settings.canChangeQuantity"
          />
        </div>
      </div>
    </div>
    <GoButton class="text-xs mt-4">Save Changes</GoButton>
  </Form>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Form, FieldArray } from 'vee-validate';
import { addressDetails, apiService, DayOfWeekList } from '@tyltgo/shared';
import { cloneDeep } from 'lodash';
import * as yup from 'yup';
import GoTextField from './GoTextField.vue';
import GoTextArea from './GoTextArea.vue';
import GoAddressField from './GoAddressField.vue';
import GoHiddenField from './GoHiddenField.vue';
import GoSelect from './GoSelect.vue';
import GoButton from './GoButton.vue';
import GoCheckboxGroup from './GoCheckboxGroup.vue';
import GoCheckbox from './GoCheckbox.vue';
import { addNotification, aroundLoadingMessage } from '../services/ui-message-service';
import IconPlus from './IconPlus.vue';
import IconTimes from './IconTimes.vue';
import { currentUser } from '../services/auth-service';

const schema = yup.object().shape({
  bringBackEmails: yup
    .array()
    .of(yup.string().email('Must be a valid email.').required('Enter valid email.')),

  invoiceEmails: yup
    .array()
    .of(yup.string().email('Must be a valid email.').required('Enter valid email.')),

  excludedCouriers: yup
    .array()
    .of(yup.string().email('Must be a valid email.').required('Enter valid email.')),
});

const deliveryOptions = [
  'Delivered To Customer',
  'Left At Door',
  'Delivered To Concierge',
  'Delivered To Neighbour',
];

export default defineComponent({
  emits: ['loadMerchant'],
  components: {
    GoButton,
    GoTextField,
    GoTextArea,
    GoAddressField,
    GoHiddenField,
    GoSelect,
    Form,
    FieldArray,
    GoCheckboxGroup,
    GoCheckbox,
    IconPlus,
    IconTimes,
  },
  props: {
    merchant: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      deliveryOptions,
      schema,
      DayOfWeekList,
      currentUser,
    };
  },
  created() {
    this.capabilitiesNeeded = this.merchant.settings.capabilitiesNeeded;
  },
  mounted() {
    if (this.merchant.invoiceAddress?.address1) {
      this.invoiceAddress = {
        ...this.merchant.invoiceAddress,
      };
    }
  },
  data() {
    return {
      capabilitiesNeeded: [],
      invoiceAddress: {
        address1: '',
        city: '',
        state: '',
        zip: '',
      },
    };
  },
  computed: {
    trackingPageLink() {
      const resolved = this.$router.resolve({
        name: this.merchant.trackingSlug ? 'track' : 'tracking',
        params: { trackingSlug: this.merchant.trackingSlug },
      });
      const { protocol, host } = window.location;
      return `${protocol}//${host}${resolved.fullPath}`;
    },
    initialValues() {
      return {
        ...cloneDeep(this.merchant),
      };
    },
    isDevelopment() {
      return import.meta.env.MODE === 'development';
    },
    canRouteNow() {
      return false;
    },
  },
  methods: {
    async saveChanges(values) {
      await aroundLoadingMessage('Saving...', async () => {
        try {
          await apiService.admin.merchants.updateSettings(this.merchant.id, values);
          addNotification({ color: 'success', message: 'Settings Updated' });
          this.$emit('loadMerchant');
        } catch (e) {
          addNotification({
            color: 'danger',
            message: e.response?.data?.message || 'Error while updating settings',
          });
        }
      });
    },
    placeSelected(place) {
      const details = addressDetails(place.formatted_address);
      this.invoiceAddress = {
        address1: details.street,
        city: details.city,
        state: details.province,
        zip: details.postalCode,
      };
    },
    async routeDeliveryDay(deliveryDayIndex, routingGroupName) {
      await apiService.admin.merchants.routeDeliveryDay(
        this.merchant.id,
        deliveryDayIndex,
        routingGroupName
      );
    },
  },
});
</script>
