import { createApp } from 'vue';
import { createGtm } from '@gtm-support/vue-gtm';
import { apiService } from '@tyltgo/shared';
import VueClickAway from 'vue3-click-away';
// import { init as initSentry } from '@sentry/vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@vueform/multiselect/themes/default.css';
import './main.css';
import { loadDarkModeSetting } from './services/browser-service';

const isProduction = import.meta.env.MODE === 'production';

const apiHost = isProduction ? 'https://api.tyltgoapp.com' : import.meta.env.VITE_API_HOST;
apiService.setApiHost(apiHost);
apiService.setConnectionChecker(() => true);

const app = createApp(App)
  .use(VueClickAway)
  .use(store)
  .use(router)
  .use(
    createGtm({
      id: 'GTM-PH6FHBB',
      compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
      nonce: '2726c7f26c', // Will add `nonce` to the script tag
      debug: true, // Whether or not display console logs debugs (optional)
      loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
      vueRouter: router, // Pass the router instance to automatically sync with router (optional)
      trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
    })
  );

// if (isProduction) {
//   initSentry({
//     app,
//     dsn: 'https://5d61e4bd89964d519c540c6461f6fe0a@o498243.ingest.sentry.io/6010798',

//     integrations: [],
//     tracesSampleRate: 0,
//     logErrors: true,
//   });
// }

app.mount('#app');

loadDarkModeSetting();
