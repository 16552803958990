<template>
  <div class="flex-1 h-screen focus:outline-none overflow-y-hidden min-h-0" tabindex="0">
    <div class="relative md:px-8 xl:px-1 min-h-0 h-full w-full">
      <div class="pt-px pb-16 min-h-0 h-full w-full">
        <div class="flex flex-row justify-between px-4 sm:px-6 md:px-0 mb-4">
          <GoHeader :level="1">Scheduling</GoHeader>
          <DaySelection
            :days="days"
            :dateForDay="dateForDay"
            :today="today"
            :currentDay="currentDay"
            path="/admin/scheduling"
          />
          <div></div>
        </div>
        <div class="flex flex-row space-x-2 px-2 min-h-0 h-full w-full">
          <div class="w-1/2 h-full overflow-y-scroll pb-96" ref="courierSection">
            <AdminCourierSearchPage
              :days="days"
              :dateForDay="dateForDay"
              :today="today"
              :currentDay="currentDay"
              path="admin.scheduling"
              @couriersListChanged="courierIds = $event.map(e => e.id)"
            >
              <template #bottom="{ courier, route }">
                <template
                  v-if="
                    route &&
                    ['new', 'declined', 'offer'].includes(route.state) &&
                    !route.activeOffers.find(offer => offer.courierId === courier.id)
                  "
                >
                  <SendOfferPanel
                    :route="route"
                    :courier="courier"
                    :showAssignedRoutes="false"
                    :isExcludedCourier="isExcluded(courier)"
                  />
                </template>
                <GoButton
                  v-if="schedulingCourierId === courier.id"
                  class="text-xs"
                  @click="schedulingCourierId = ''"
                >
                  Unselect
                </GoButton>
                <GoButton
                  v-else-if="!schedulingRouteId"
                  variant="link"
                  class="text-xs"
                  @click="selectCourier(courier)"
                >
                  Find Routes
                </GoButton>
              </template>
            </AdminCourierSearchPage>
          </div>
          <div class="w-1/2 overflow-y-scroll pb-96" ref="routeSection">
            <AdminRoutesSearchPage
              v-if="dateForDay[currentDay]"
              @sortedRoutesChanged="routeIds = $event.map(e => e.id)"
              path="admin.scheduling"
              :currentDate="dateForDay[currentDay]"
            >
              <template #bottom="{ route }">
                <div
                  v-if="schedulingCourier"
                  class="space-y-1 py-2 px-4 border-t-2 border-gray-200"
                >
                  <RouteOfferLine
                    v-for="offer of [...route.declinedOffers, ...route.activeOffers].filter(
                      o => o.courierId === schedulingCourier.id
                    )"
                    :key="offer.id"
                    :offer="offer"
                    :route="route"
                    :showMenu="false"
                  />
                </div>
                <div class="space-y-1 py-2 px-4 border-t-2 border-gray-200">
                  <GoButton
                    v-if="schedulingRouteId === route.id"
                    class="text-xs"
                    @click="schedulingRouteId = ''"
                  >
                    Unselect
                  </GoButton>
                  <GoButton
                    v-else-if="!schedulingCourierId"
                    variant="link"
                    class="text-xs"
                    @click="selectRoute(route)"
                  >
                    Find Couriers
                  </GoButton>
                  <GoAlert
                    v-if="schedulingRouteId === route.id && merchantSchedulingNotes.length"
                    :title="'Merchant(s) Scheduling Notes'"
                    :color="'warning'"
                  >
                    <div>
                      <div v-for="note of merchantSchedulingNotes" :key="note.ownerId">
                        <span>
                          <router-link :to="`/admin/merchants/${note.ownerId}`">
                            {{ note.ownerName }}
                          </router-link>
                        </span>
                        <pre class="text-sm whitespace-pre-line font-body">{{ note.content }}</pre>
                        <br />
                      </div>
                    </div>
                  </GoAlert>
                  <template
                    v-if="
                      schedulingCourier &&
                      ['new', 'declined', 'offer'].includes(route.state) &&
                      !route.activeOffers.find(offer => offer.courierId === schedulingCourier.id)
                    "
                  >
                    <SendOfferPanel
                      :route="route"
                      :courier="schedulingCourier"
                      :showAssignedRoutes="false"
                      :isExcludedCourier="isExcluded(schedulingCourier)"
                    />
                  </template>
                </div>
              </template>
            </AdminRoutesSearchPage>
            <div
              v-if="currentUser.canAutoSchedule"
              class="flex flex-wrap gap-4 items-end p-4 bg-white dark:bg-gray-800 rounded-md"
            >
              <GoTextField
                label="Radius"
                name="schedulingOptions.forRouteRadius"
                v-model="schedulingOptions.forRouteRadius"
              />
              <GoTextField
                label="Offers Per Route"
                name="schedulingOptions.maxOffersPerRoute"
                v-model="schedulingOptions.maxOffersPerRoute"
              />
              <GoTextField
                label="Offers Per Courier"
                name="schedulingOptions.maxOffersPerCourier"
                v-model="schedulingOptions.maxOffersPerCourier"
              />
              <GoTextField
                label="Max Pay Percentage"
                name="schedulingOptions.payThresholdPercentage"
                v-model="schedulingOptions.payThresholdPercentage"
              />
              <div class="space-y-2">
                <GoButton v-if="!schedulingLocked" class="text-xs" @click="scheduleRoutes">
                  Schedule {{ routeIds.length }} Routes with {{ courierIds.length }} Couriers
                </GoButton>
                <GoButton v-else class="text-xs" :disabled="true">Can't touch this.</GoButton>
                <div class="py-2 px-4 rounded-md border text-xs text-white bg-gray-800">
                  With great power comes at least some responsibility.
                </div>

                <div
                  v-if="logs.length"
                  class="py-2 px-4 rounded-md border text-xs text-white bg-gray-800"
                >
                  <p v-for="(log, index) of logs.slice(-5)" :key="index" class="mb-1">
                    {{ log.time }} - {{ log.message }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import dayjs from 'dayjs';
import { apiService } from '@tyltgo/shared';
import io from 'socket.io-client';
import DaySelection from '../../components/DaySelection.vue';
import AdminCourierSearchPage from '../../components/AdminCourierSearchPage.vue';
import AdminRoutesSearchPage from '../../components/AdminRoutesSearchPage.vue';
import GoButton from '../../components/GoButton.vue';
import SendOfferPanel from '../../components/SendOfferPanel.vue';
import { setShowMenu } from '../../store/admin-store';
import RouteOfferLine from '../../components/RouteOfferLine.vue';
import {
  schedulingRouteId,
  schedulingCourierId,
  schedulingCourier,
} from '../../store/scheduling-store';
import GoAlert from '../../components/GoAlert.vue';
import GoTextField from '../../components/GoTextField.vue';
import { aroundLoadingMessage, showConfirmationModal } from '../../services/ui-message-service';
import { currentUser } from '../../services/auth-service';

const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export default defineComponent({
  setup() {
    return {
      days,
      schedulingRouteId,
      schedulingCourierId,
      schedulingCourier,
      currentUser,
    };
  },
  components: {
    GoTextField,
    RouteOfferLine,
    DaySelection,
    AdminCourierSearchPage,
    AdminRoutesSearchPage,
    GoAlert,
    GoButton,
    SendOfferPanel,
  },
  watch: {
    schedulingLocked(value) {
      if (value) {
        this.schedulingInterval = setInterval(async () => {
          const { lock } = await apiService.admin.scheduling.checkLock();
          this.schedulingLocked = lock;
        }, 10000);
      } else if (this.schedulingInterval) clearInterval(this.schedulingInterval);
    },
    $route(to) {
      if (to.name === 'admin.scheduling') {
        this.currentDay = this.$route.query.currentDay || this.today;
      }
    },
    currentDay() {
      this.schedulingRouteId = '';
      this.schedulingCourierId = '';
    },
    schedulingRouteId() {
      const element = this.$refs?.courierSection;
      if (element) {
        element.scrollTop = 0;
      }
    },
    schedulingCourierId() {
      const element = this.$refs?.routeSection;
      if (element) {
        element.scrollTop = 0;
      }
    },
  },
  async beforeMount() {
    this.today = dayjs().format('dddd').toLowerCase();
    this.currentDay = this.$route.query.currentDay || this.today;
    this.days.forEach((_, count) => {
      const datejs = dayjs().add(count, 'days');
      this.dateForDay[datejs.format('dddd').toLowerCase()] = datejs.format('YYYY-MM-DD');
    });
    const { lock } = await apiService.admin.scheduling.checkLock();
    this.schedulingLocked = lock;
  },
  mounted() {
    setShowMenu(false);
    this.socket = io(`${apiService.apiHost}?room=admin/scheduling`, {
      transports: ['websocket', 'polling'],
    });
    this.socket.on('log', this.updateLogs);
  },
  unmounted() {
    setShowMenu(true);
    if (this.schedulingInterval) clearInterval(this.schedulingInterval);
    this.disconnectSockets();
  },
  data() {
    return {
      logs: [],
      socket: null,
      schedulingLocked: false,
      schedulingInterval: null,
      schedulingOptions: {
        forRouteRadius: 100,
        maxOffersPerRoute: 30,
        maxOffersPerCourier: 12,
        payThresholdPercentage: 5,
        filterByPickupTime: true,
      },
      routeIds: [],
      courierIds: [],
      currentDay: 'all',
      today: null,
      dateForDay: {},
      merchantSchedulingNotes: [],
      excludedCouriers: [],
    };
  },
  methods: {
    async getMerchantSchedulingNotes(id: string) {
      const { note } = await apiService.admin.merchants.getSchedulingNotes(id);
      return note;
    },
    async getMerchantExcludedCouriers(id: string) {
      const { excludedCouriers } = await apiService.admin.merchants.getExcludedCouriers(id);
      return excludedCouriers;
    },
    isExcluded(courier) {
      if (!courier) return false;
      return this.excludedCouriers ? this.excludedCouriers.includes(courier.id) : false;
    },
    async selectRoute(route) {
      this.schedulingRouteId = route.id;
      await this.loadMerchantParameters(route);
    },
    selectCourier(courier) {
      this.schedulingCourierId = courier.id;
    },
    unselectRoute() {
      this.schedulingRouteId = '';
      this.merchantSchedulingNotes = [];
      this.excludedCouriers = [];
    },
    async loadMerchantParameters(route) {
      const merchantIds = route.waypoints.map(wp => wp.associatedEmail);
      const distinctMerchantIds = [...new Set(merchantIds)];

      await this.loadMerchantSchedulingNotes(distinctMerchantIds);
      await this.loadMerchantExcludedCouriers(distinctMerchantIds);
    },
    async loadMerchantSchedulingNotes(distinctMerchantIds: string[]) {
      const notesPromises = distinctMerchantIds.map(this.getMerchantSchedulingNotes);
      const notes = await Promise.all(notesPromises);
      this.merchantSchedulingNotes = notes.filter((note: any) => !!note.content);
    },
    async loadMerchantExcludedCouriers(distinctMerchantIds: string[]) {
      const excludedCouriersPromises = distinctMerchantIds.map(
        async (id: string): Promise<string[]> => this.getMerchantExcludedCouriers(id)
      );
      const excludedCouriers = await Promise.all(excludedCouriersPromises);
      this.excludedCouriers = excludedCouriers.reduce(
        (acc, excluded) => acc.concat(excluded || []),
        []
      );
    },
    disconnectSockets() {
      if (!this.socket) return;
      this.socket.disconnect();
    },
    updateLogs(log) {
      this.logs.push(log);
    },
    async scheduleRoutes() {
      try {
        await aroundLoadingMessage('Creating Scheduling Job...', async () => {
          await apiService.admin.scheduling.schedule({
            ...this.schedulingOptions,
            routeIds: this.routeIds,
            courierIds: this.courierIds,
          });
          this.schedulingLocked = true;
          showConfirmationModal({
            color: 'success',
            message: `Scheduling started in the background.`,
          });
        });
      } catch (e) {
        this.schedulingLocked = false;
        showConfirmationModal({
          color: 'danger',
          message: `Error scheduling: ${e.response?.data?.message || e.message}`,
        });
      }
    },
  },
});
</script>
