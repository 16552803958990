<template>
  <div v-if="route.payDetails">
    <div class="uppercase">Pay Breakdown ({{ route.payDetails.totalPay.toFixed(2) }})</div>
    <GoTable
      class="my-4 overflow-x-auto"
      :tableData="{
        headers: [
          { title: 'Change', key: 'change' },
          { title: 'Details', key: 'details' },
        ],
        data: route.payDetails.details.map(d => ({
          ...d,
          change: d.change.toFixed(2),
        })),
      }"
    ></GoTable>
  </div>
  <div v-else>No Courier Assigned</div>
</template>

<script lang="ts">
import { Route } from '@tyltgo/shared';
import { defineComponent, PropType } from 'vue';
import GoTable from './GoTable.vue';

export default defineComponent({
  emits: ['loadMerchant'],
  components: {
    GoTable,
  },
  props: {
    route: {
      type: Object as PropType<Route>,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
});
</script>
