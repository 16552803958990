<template>
  <main class="flex-1 overflow-y-auto focus:outline-none" tabindex="0">
    <div class="relative max-w-6xl mx-auto md:px-8 xl:px-0">
      <div class="pt-10 pb-8">
        <div class="px-4 sm:px-6 md:px-0">
          <GoHeader :level="2">Add Orders</GoHeader>
        </div>
        <div class="px-4 sm:px-6 md:px-0">
          <div class="pb-16">
            <div v-if="!currentUser" class="p-8">
              <IconLoader class="animate-spin mx-2 h-8 w-8 text-primary-800" />
            </div>

            <div v-else class="px-4 sm:px-6 md:px-0 print:px-0">
              <GoHeader :level="3" class="pt-4">{{ $route.params.id }}</GoHeader>
              <GoHeader :level="1" class="mb-8">{{ $route.params.shopName }}</GoHeader>

              <template v-if="!isDateOptionSelected">
                <div class="flex flex-row space-x-4 justify-center items-center">
                  <GoTextField
                    label="Choose Delivery Day"
                    name="date"
                    type="date"
                    v-model="date"
                    class="w-full md:w-1/4 mb-3"
                  />
                  <span>OR</span>
                  <GoButton variant="outline" @click="shouldAutoDate = true">
                    Automatically Assign Date
                  </GoButton>
                </div>
              </template>
              <template v-else>
                <GoHeader :level="2" class="mb-8 flex items-center space-x-8">
                  <span>Delivery Day: {{ shouldAutoDate ? 'Automatic' : date }}</span>
                  <GoButton
                    variant="outline"
                    class="text-xs"
                    @click="
                      shouldAutoDate = false;
                      date = '';
                    "
                  >
                    change
                  </GoButton>
                </GoHeader>
                <div v-if="loading" class="py-3">
                  <IconLoader class="animate-spin mx-2 h-8 w-8 text-primary-800" />
                </div>
                <template v-if="rawOrders">
                  <div class="flex space-x-4 mb-4">
                    <GoTextField
                      label="Tags (comma separated)"
                      v-model="filters.tags"
                      class="w-1/4"
                    />
                    <GoTextField
                      label="Postal Code (comma separated)"
                      v-model="filters.postalCodes"
                      class="w-1/4"
                    />
                    <GoSelect
                      label="Group"
                      v-model="filters.group"
                      class="w-1/4"
                      placeholder="Any"
                      :options="[
                        ...routingGroups.map(g => ({ label: g.name, value: g.name })),
                        { label: 'No Group', value: 'No Group' },
                      ]"
                    />
                    <GoSelect
                      label="Eligibility"
                      v-model="filters.eligibility"
                      class="w-1/4"
                      placeholder="Any"
                      :options="{
                        eligible: 'Eligible',
                        notEligible: 'Not Eligible',
                      }"
                    />
                    <GoSelect
                      label="Status"
                      v-model="filters.status"
                      class="w-1/5"
                      placeholder="Any"
                      :options="{
                        routed: 'Routed',
                        unrouted: 'Unrouted',
                      }"
                    />
                  </div>
                  <div class="flex items-center justify-between">
                    <div class="text-base">{{ selectedRows.length }} orders selected</div>
                    <div class="text-base">{{ filteredRawOrders.length }} orders total</div>
                  </div>
                  <GoTable
                    class="mt-4 overflow-x-auto"
                    :selectable="true"
                    :selectableValue="row => row.orderId"
                    @selectedRows="rows => (selectedRows = rows)"
                    :rowClass="rowClass"
                    :selectableRow="
                      row => !row.exists && row.status === 'unrouted' && row.isEligible
                    "
                    :tableData="{
                      headers: [
                        { title: 'Order Number', key: 'orderId' },
                        {
                          title: 'Customer',
                          valueFunction: row => `${row.name}\n${row.phoneNumber}\n${row.email}`,
                          pre: true,
                        },
                        {
                          title: 'Address',
                          valueFunction: row =>
                            `${row.addressLine1}\n${row.city}\n${row.postalCode} ${row.province}\n${row.addressLine2}\n\n${row.notes}`,
                          pre: true,
                        },
                        { title: 'Tags', key: 'tags' },
                        {
                          title: 'Group',
                          valueFunction: deliveryGroupString,
                          wrap: false,
                        },
                        {
                          title: 'Delivery Date',
                          valueFunction: deliveryDateString,
                          wrap: false,
                        },
                        {
                          title: 'Status',
                          valueFunction: rowStatus,
                        },
                      ],
                      data: filteredRawOrders,
                    }"
                  ></GoTable>

                  <div class="mt-6">
                    <div class="text-base mb-4">{{ selectedRows.length }} orders selected</div>
                    <GoForm @submit="submitOrders" class="space-y-4">
                      <GoButton class="text-base" :disabled="!selectedRows.length">
                        Submit Orders
                      </GoButton>
                    </GoForm>
                  </div>
                </template>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { apiService, findDeliveryDate } from '@tyltgo/shared';
import keyBy from 'lodash/keyBy';
import intersection from 'lodash/intersection';
import capitalize from 'lodash/capitalize';
import sortBy from 'lodash/sortBy';
import flatten from 'lodash/flatten';
import dayjs from 'dayjs';
import { showConfirmationModal } from '@/services/ui-message-service';
import { currentUser } from '../../services/auth-service';

export default defineComponent({
  setup(props) {
    const route = useRoute();
    const storeName = props.storeName || route.params.storeName;
    const store = currentUser.value.shopifyStores.find(s => s.credentials.shopName === storeName);
    return {
      store,
      currentUser,
      merchant: currentUser,
    };
  },
  props: {
    storeName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      filters: {
        tags: '',
        postalCodes: '',
        eligibility: '',
        status: '',
        group: '',
      },
      loading: false,
      loadingMerchant: false,
      shopifyOrders: null,
      shopifyStore: null,
      rawOrders: null,
      merchant: null,
      selectedRows: [],
      date: '',
      shouldAutoDate: false,
    };
  },
  async mounted() {
    await this.loadShopifyOrders();
  },
  watch: {},
  computed: {
    isDateOptionSelected() {
      if (this.shouldAutoDate) return true;
      return !!this.date;
    },
    routingGroups() {
      const groups = [
        ...(this.merchant.settings.routingGroups || []),
        ...(this.merchant.parentRoutingGroups || []),
      ];
      return groups.map(group => {
        return {
          ...group,
          deliveryDays: sortBy(
            group.deliveryDays?.map(day => {
              let date = findDeliveryDate(day.day);
              const routingDate = findDeliveryDate(day.routingDay);
              const routingTime = dayjs.tz(
                `${routingDate} ${day.routingTime}`,
                'YYYY-MM-DD HH:mm',
                'America/Toronto'
              );
              if (routingTime.isBefore(dayjs())) {
                date = findDeliveryDate(day.day, dayjs(date, 'YYYY-MM-DD').add(1, 'day'));
              }
              if (this.date) {
                const dayForDate = dayjs(this.date, 'YYYY-MM-DD').format('dddd').toLowerCase();
                date = dayForDate === day.day ? this.date : null;
              }
              return {
                ...day,
                date,
              };
            }),
            'date'
          ),
        };
      });
    },
    filteredRawOrders() {
      return this.rawOrders
        .map(order => ({
          ...order,
          day: this.nextDeliveryDayForOrder(order),
          isEligible: this.isEligible(order),
          eligibilityError: this.eligibilityError(order),
        }))
        .filter(order => {
          if (!this.filters.group) {
            return true;
          }

          if (this.filters.group === 'No Group') {
            return !order.day;
          }

          return order.day?.name === this.filters.group;
        })
        .filter(order => {
          if (!this.filters.eligibility) {
            return true;
          }

          const eligible = order.isEligible;

          let result = false;
          if (this.filters.eligibility === 'eligible') {
            result = eligible;
          } else if (this.filters.eligibility === 'notEligible') {
            result = !eligible;
          }

          return result;
        })
        .filter(order => {
          if (!this.filters.status) {
            return true;
          }

          return order.status === this.filters.status;
        })
        .filter(order => {
          if (!this.filters.tags) {
            return true;
          }

          const searchTags = this.filters.tags.toLowerCase().split(/,\s*/);
          const tags = order.tags.toLowerCase().split(/,\s*/);

          return searchTags.every(searchTag => {
            return tags.some(tag => tag.startsWith(searchTag.toLowerCase()));
          });
        })
        .filter(order => {
          if (!this.filters.postalCodes) {
            return true;
          }

          const postalCode = order.postalCode.toLowerCase();
          const searchPostalCodes = this.filters.postalCodes.toLowerCase().split(/,\s*/);
          return searchPostalCodes.some(code => postalCode.startsWith(code));
        });
    },
  },
  methods: {
    rowStatus(row) {
      if (row.status === 'routed') return 'Routed';
      if (row.exists) return 'Submitted';
      return row.eligibilityError || 'Not submitted';
    },
    rowClass(row) {
      if (row.status === 'routed') return 'bg-green-50';
      if (row.exists) return 'bg-blue-50';
      return !row.isEligible ? 'bg-red-50' : '';
    },
    async submitOrders() {
      const ordersById = keyBy(this.rawOrders, 'orderId');
      const filteredOrdersById = keyBy(this.filteredRawOrders, 'orderId');
      const orders = this.selectedRows.map(id => ({
        ...ordersById[id],
        date: filteredOrdersById[id].day.date,
      }));
      try {
        await apiService.merchants.batches.submitShopifyOrders(this.$route.params.storeName, {
          orders: orders.map(o => ({ orderId: o.orderId, date: o.date })),
        });
        const confirmed = await showConfirmationModal({
          title: 'Orders Submitted',
          message: `Orders will appear in Pending Orders in a few minutes.`,
          confirmButtonText: 'Okay',
        });

        if (confirmed) {
          await this.loadShopifyOrders();
          this.selectedRows = [];
        }
      } catch (e) {
        console.log('there was an error', e);
        await showConfirmationModal({
          color: 'danger',
          title: 'Error submitted orders',
          message: `There was an error submitting orders, please contact support.`,
          confirmButtonText: 'Okay',
        });
      }
    },
    isEligible(row) {
      return !this.eligibilityError(row);
    },
    fsaForOrder(row) {
      const fsa = row.postalCode.slice(0, 3).toUpperCase();
      return fsa;
    },
    deliveryDaysForOrder(row) {
      const fsa = this.fsaForOrder(row);
      const routingGroups = this.routingGroups.filter(group => {
        return group.postalCodeMatch.some(code => {
          if (!code.length) return true;
          return fsa.startsWith(code.toUpperCase());
        });
      });
      const deliveryDays = sortBy(
        flatten(
          routingGroups.map(group => {
            return group.deliveryDays.map(day => {
              return {
                ...day,
                name: group.name,
              };
            });
          })
        ),
        'date'
      );
      return deliveryDays.filter(day => {
        if (!day.date) return true;
        const routingTime = dayjs.tz(
          `${day.date} ${day.routingTime}`,
          'YYYY-MM-DD HH:mm',
          'America/Toronto'
        );
        return routingTime.isAfter(dayjs());
      });
    },
    nextDeliveryDayForOrder(row) {
      const tags = row.tags.toLowerCase().split(/,\s*/);

      const { date } = this;
      const deliveryDays = this.deliveryDaysForOrder(row);
      if (date) {
        return deliveryDays.find(day => day.date === date);
      }
      return (
        deliveryDays.find(day => {
          const dateTag = dayjs(day.date, 'YYYY-MM-DD')
            .format(this.shopifyStore.credentials.dateFormat)
            .toLowerCase();
          return tags.find(tag => tag === dateTag);
        }) || deliveryDays[0]
      );
    },
    deliveryDateString(row) {
      if (!row.isEligible) return '';
      if (!row.day) return '';
      return `${capitalize(row.day.day)} ${row.day.date}`;
    },
    deliveryGroupString(row) {
      if (!row.isEligible) return '';
      if (!row.day) return '';
      return `${row.day.name}`;
    },
    eligibilityError(row) {
      const tags = row.tags.toLowerCase().split(/,\s*/);

      // if (tags.includes('tyltgo-status-delivered')) {
      //   return 'Order already delivered';
      // }

      const tagsToSkip = (this.shopifyStore.tagsToSkip || '')
        .split(',')
        .map(t => t.trim().toLowerCase());
      const foundToSkip = intersection(tags, tagsToSkip).length;
      if (tags.includes('tyltgo-skip') || foundToSkip) {
        return 'Order has a skip tag';
      }

      const fsa = this.fsaForOrder(row);
      if (!fsa) {
        return `Order does not have an FSA.`;
      }

      if (this.merchant.priceList) {
        const priceListCheck = Object.keys(this.merchant.priceList).find(
          key => key.toUpperCase() === fsa
        );
        if (!priceListCheck) {
          return `${fsa} is not in the price list.`;
        }
      }

      const deliveryDaysForOrder = this.deliveryDaysForOrder(row);
      if (!deliveryDaysForOrder.length) {
        return `${fsa} is not in routing group.`;
      }

      if (this.date && !deliveryDaysForOrder.find(d => d.date === this.date)) {
        return `${fsa} is not delivered on this date.`;
      }

      // TODO: check for time cutoff
      return '';
    },
    async loadShopifyOrders() {
      this.loading = true;
      const { orders, rawOrders, shopifyStore } = await apiService.merchants.batches.shopifyOrders(
        this.$route.params.storeName
      );
      this.shopifyOrders = orders;
      this.shopifyStore = shopifyStore;
      this.rawOrders = rawOrders;
      this.loading = false;
    },
  },
});
</script>
