<template>
  <div
    class="bg-white dark:bg-gray-800 py-2 shadow rounded-sm mb-4 flex border-l-4 dark:border-gray-500 flex-col"
    :id="merchant.id"
  >
    <div class="flex flex-col sm:flex-row justify-between px-4">
      <p class="text-gray-500 dark:text-gray-400 text-sm">{{ merchant.email }}</p>
      <div class="flex flex-row space-x-2 items-center">
        <GoBadge v-if="merchant.settings.parent" class="bg-indigo-200 text-indigo-700">
          Parent
        </GoBadge>
        <GoBadge v-if="merchant.parentIds?.length" class="bg-blue-200 text-blue-700">Child</GoBadge>
        <GoDropdown>
          <template v-slot:button>
            <IconEllipsis class="flex-none w-3 text-gray-600 dark:text-gray-400" />
          </template>
          <template v-slot:items>
            <GoDropdownItem
              @click="$router.push({ name: 'admin.merchant', params: { id: merchant.email } })"
            >
              View Details
            </GoDropdownItem>
            <GoDropdownItem @click="loginAs">Login As</GoDropdownItem>
          </template>
        </GoDropdown>
      </div>
    </div>
    <div class="px-4 pb-2">
      <div class="flex flex-col sm:flex-row justify-between">
        <router-link :to="{ name: 'admin.merchant', params: { id: merchant.id } }">
          {{ merchant.name }}
        </router-link>
      </div>
      <div class="flex flex-col sm:flex-row justify-between text-sm">
        {{ merchant.phoneNumber }}
      </div>
    </div>
    <div class="flex flex-wrap gap-2 justify-between px-4">
      <div class="w-1/2">
        <DataBit
          v-if="merchant.settings.capabilitiesNeeded?.length"
          class="mt-3"
          title="Capabilities"
          :left-align="true"
        >
          <GoBadge
            v-for="capability of merchant.settings.capabilitiesNeeded"
            :key="capability"
            class="bg-blue-100 text-blue-800 mr-2"
          >
            {{ startCase(capability) }}
          </GoBadge>
        </DataBit>
      </div>
    </div>
    <div class="flex flex-col sm:flex-row border-t-2 pt-2 px-4 justify-end" v-if="$slots.bottom">
      <slot name="bottom"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { apiService } from '@tyltgo/shared';
import startCase from 'lodash/startCase';
import GoBadge from './GoBadge.vue';
import GoDropdownItem from './GoDropdownItem.vue';
import GoDropdown from './GoDropdown.vue';
import IconEllipsis from './IconEllipsis.vue';
import { authTokenKey } from '../services/auth-service';

export default defineComponent({
  setup() {
    return {
      startCase,
    };
  },
  components: {
    GoBadge,
    GoDropdown,
    GoDropdownItem,
    IconEllipsis,
  },
  props: {
    merchant: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async loginAs() {
      const { token } = await apiService.admin.merchants.loginAs(this.merchant.email);
      const key = authTokenKey('merchant');
      localStorage.setItem(key, token);

      window.open('/merchant/batches');
    },
  },
});
</script>
