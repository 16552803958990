<template>
  <p class="mt-2 text-sm text-red-600" :id="id">
    {{ message }}
  </p>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    id: {
      type: String,
      required: false,
    },
    message: {
      type: String,
    },
  },
});
</script>
