<template>
  <div>
    <div class="mt-3 text-center sm:mt-5">
      <h3 class="text-2xl leading-6 font-medium text-gray-900 mb-6" id="modal-headline">
        Map Fields
      </h3>
      <div class="mt-2">
        <div v-for="field of internalMappingFields" :key="field.name">
          <div class="flex space-x-4 mb-2">
            <div class="text-right w-1/2 flex align-middle justify-end items-center">
              <GoLabel>
                {{ field.name }}
                <span v-if="field.required">*</span>
              </GoLabel>
            </div>
            <div class="text-left w-1/2">
              <Multiselect
                autocomplete="do-not-complete"
                :mode="field.multiple ? 'tags' : 'single'"
                v-model="csvMappings[field.name]"
                :options="csvHeaders.map(x => ({ label: x, value: x }))"
                v-if="parsedCsv"
                :searchable="true"
              />
            </div>
          </div>
          <div class="flex space-x-4 mb-2 justify-end" v-if="mappingErrors[field.name]">
            <GoErrorMessage :message="mappingErrors[field.name]" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-5 sm:mt-6 flex justify-between space-x-4">
    <GoButton @click="$emit('selected', false)" variant="outline" class="w-full">Cancel</GoButton>
    <GoButton @click="process" class="w-full">Map</GoButton>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Multiselect from '@vueform/multiselect';
import pluralize from 'pluralize';
import { apiService, fixAddressLines, parsePhone } from '@tyltgo/shared';
import groupBy from 'lodash/groupBy';
import flatten from 'lodash/flatten';
import GoButton from './GoButton.vue';
import GoLabel from './GoLabel.vue';
import GoErrorMessage from './GoErrorMessage.vue';
import {
  showLoadingMessage,
  stopLoadingMessage,
  showConfirmationModal,
} from '../services/ui-message-service';
import { parseCsv } from '../services/csv-service';

const shopifyFields = {
  'Recipient Full Name': 'Shipping Name',
  'Recipient Phone Number': 'Shipping Phone',
  'Address Line 1': 'Shipping Address1',
  'Address Line 2': 'Shipping Address2',
  'Address City': 'Shipping City',
  'Address Province': 'Shipping Province',
  'Address Postal Code': 'Shipping Zip',
  'Order Notes': ['Notes'],
  Quantity: 'Lineitem quantity',
  // requiresShipping: 'Lineitem requires shipping',
  'Order Number': 'Name',
  Email: 'Email',
};

const aslFields = {
  'Recipient Full Name': 'Consignee Name',
  'Recipient Phone Number': 'Consignee Phone Number',
  'Address Line 1': 'Consignee Address Line 1',
  'Address Line 2': 'Consignee Address Line 2',
  'Address City': 'Consignee City',
  'Address Province': 'Consignee Province',
  'Address Postal Code': 'Consignee Postal Code',
  'Order Notes': [],
  // requiresShipping: 'Lineitem requires shipping',
  'Order Number': 'Unique Barcode on Package',
};

export default defineComponent({
  components: {
    GoButton,
    GoLabel,
    GoErrorMessage,
    Multiselect,
  },
  emits: ['selected'],
  props: {
    files: {
      type: Object,
      required: true,
    },
    merchant: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      csvFileString: '',
      mappedResults: [],
      mappingErrors: {},
      invalidGeocode: [],
      parsedCsv: null,
      csvMappings: {},
      internalMappingFields: [
        { name: 'Recipient Full Name', required: true },
        { name: 'Recipient Phone Number', required: true },

        { name: 'Address Line 1', required: true },
        { name: 'Address Line 2' },
        { name: 'Address City', required: true },
        { name: 'Address Province', required: true },
        { name: 'Address Postal Code', required: true },

        { name: 'Order Notes', multiple: true },
        { name: 'Quantity', required: false },
        this.merchant.settings.volumeCapacity
          ? { name: 'Volume', required: false, hint: 'cubic feet' }
          : undefined,
        { name: 'Order Number', required: false },
        { name: 'Email', required: false },
      ].filter(x => !!x),
    };
  },
  mounted() {
    const reader = new FileReader();
    reader.addEventListener('load', e => {
      this.parsedCsv = undefined;
      const data = <string>e.target.result;
      this.csvFileString = data;
      setTimeout(() => {
        this.parsedCsv = parseCsv(data, { header: true });
        this.parsedCsv.data = this.parsedCsv.data.filter(
          row => Object.values(row).filter(x => !!x).length
        );

        if (this.isShopifyCsv) {
          this.csvMappings = shopifyFields;
          this.processShopifyCsv();
        } else if (this.isAslCsv) {
          this.csvMappings = aslFields;
          this.processAslCsv();
        } else {
          const csvMappingsByHeaders = this.getLocalCsvMappingsByHeaders();
          this.csvMappings = csvMappingsByHeaders[this.csvHeaders.join('-')] || {};
        }
      }, 100);
    });

    const file = this.files[0];
    if (file) {
      reader.readAsText(file);
    }
  },
  computed: {
    csvHeaders() {
      return this.parsedCsv.meta.fields;
    },
    isShopifyCsv() {
      return Object.values(shopifyFields).every(value => {
        if (Array.isArray(value)) {
          return true;
        }
        return this.csvHeaders.includes(value);
      });
    },
    isAslCsv() {
      return Object.values(aslFields).every(value => {
        if (Array.isArray(value)) {
          return true;
        }
        return this.csvHeaders.includes(value);
      });
    },
  },
  methods: {
    processShopifyCsv() {
      const group = groupBy(this.parsedCsv.data, 'Name');
      const fields = flatten(Object.values(shopifyFields));
      const data = Object.values(group).map(rows => {
        const rowsRequiresShippings = rows.filter(r => r['Lineitem requires shipping'] === 'TRUE');

        const row = {};

        fields.forEach(field => {
          if ((rowsRequiresShippings[0] || {})[field]) {
            row[field] = rowsRequiresShippings[0][field];
          } else {
            const valueRow = rows.find(r => !!r[field]);
            row[field] = valueRow ? valueRow[field] : '';
          }
        });

        return row;
      });
      this.parsedCsv.data = data;

      this.process();
    },
    processAslCsv() {
      this.process();
    },
    showLoadingMessage() {
      showLoadingMessage(
        `Verifying Addresses... (${this.mappedResults.length} / ${this.parsedCsv.data.length})`
      );
    },

    showErrorMessage() {
      const titleMessage = `${this.invalidGeocode.length} Unverified ${pluralize(
        'Address',
        this.invalidGeocode.length
      )}`;

      const mainMessage = `Please modify highlighted ${pluralize(
        'order',
        this.invalidGeocode.length
      )}.`;

      showConfirmationModal({
        title: titleMessage,
        message: mainMessage,
        color: 'danger',
      });
    },

    getLocalCsvMappingsByHeaders() {
      return JSON.parse(localStorage.getItem('csvMappingsByHeaders') || '{}');
    },
    validateCsvMappings() {
      this.mappingErrors = {};
      const errors = {};
      for (const { name, required } of this.internalMappingFields) {
        const input = this.csvMappings[name];
        const value = Array.isArray(input) ? input.join('') : input;
        if (required && !value) {
          errors[name] = `${name} is a required field.`;
        }
      }

      this.mappingErrors = errors;
    },
    mappedValue(
      data: Record<string, string>,
      name: string,
      options: { separator: string; includeFieldNames: boolean } = {
        separator: ' ',
        includeFieldNames: false,
      }
    ) {
      const input = this.csvMappings[name];
      if (!input) return null;

      const value = Array.isArray(input) ? input : [input];
      if (options.includeFieldNames) {
        return value
          .filter(field => !!(data[field] || '').trim())
          .map(field => `${field}: ${data[field]}`)
          .join(options.separator);
      }
      return value
        .filter(field => !!(data[field] || '').trim())
        .map(field => data[field])
        .join(options.separator);
    },
    async process() {
      this.showLoadingMessage();
      this.mappedResults = [];

      this.validateCsvMappings();

      if (!this.mappingErrors.length) {
        const csvMappingsByHeaders = this.getLocalCsvMappingsByHeaders();
        csvMappingsByHeaders[this.csvHeaders.join('-')] = this.csvMappings;
        localStorage.setItem('csvMappingsByHeaders', JSON.stringify(csvMappingsByHeaders));

        const adminAuthToken = localStorage.getItem('authToken-admin');
        try {
          // store the csv file and the mappings
          await apiService.merchants.batches.storeCsv({
            csvFile: this.csvFileString,
            csvMappings: this.csvMappings,
            adminAuthToken,
          });
        } catch (e) {
          // do nothing
        }

        for (const data of this.parsedCsv.data) {
          let rawAddress: {
            addressLine1: string;
            addressLine2: string;
            city?: string;
            province?: string;
            postalCode?: string;
          } = {
            addressLine1: this.mappedValue(data, 'Address Line 1'),
            addressLine2: this.mappedValue(data, 'Address Line 2'),
            city: this.mappedValue(data, 'Address City'),
            province: this.mappedValue(data, 'Address Province'),
            postalCode: this.mappedValue(data, 'Address Postal Code'),
          };
          if (
            rawAddress.addressLine1 === rawAddress.city &&
            rawAddress.addressLine1 === rawAddress.province &&
            rawAddress.addressLine1 === rawAddress.postalCode
          ) {
            rawAddress = {
              addressLine1: this.mappedValue(data, 'Address Line 1'),
              addressLine2: this.mappedValue(data, 'Address Line 2'),
            };
          }
          console.log(rawAddress);
          const line1 = this.mappedValue(data, 'Address Line 1');
          const [addressLine1, addressLine2] = fixAddressLines(
            line1,
            this.mappedValue(data, 'Address Line 2')
          );

          const quantity = this.mappedValue(data, 'Quantity');
          const volume = this.mappedValue(data, 'Volume');
          const orderNumber = this.mappedValue(data, 'Order Number');
          const email = this.mappedValue(data, 'Email');
          const payload = {
            rawAddress,
            phoneNumber: parsePhone(this.mappedValue(data, 'Recipient Phone Number'), '1'),
            name: this.mappedValue(data, 'Recipient Full Name'),
            addressLine1: this.mappedValue(data, 'Address Line 1'),
            droppedAddress: true,
            latitude: undefined,
            longitude: undefined,
            addressLine2: this.mappedValue(data, 'Address Line 2'),
            notes:
              this.mappedValue(data, 'Order Notes', {
                separator: ' -- ',
                includeFieldNames: true,
              }) || '',
            quantity: quantity ? Number(quantity) : 1,
            volume: volume ? Number(volume) : 0,
            orderNumber: orderNumber ? orderNumber.trim() : undefined,
            email: email ? email.trim() : undefined,
          };

          try {
            const city = this.mappedValue(data, 'Address City');
            const province = this.mappedValue(data, 'Address Province');
            const postalCode = this.mappedValue(data, 'Address Postal Code');

            let geolocation: any;
            if (city === line1 && province === line1 && postalCode === line1) {
              // eslint-disable-next-line no-await-in-loop
              geolocation = await apiService.merchants.batches.geolocation({
                address: addressLine1,
              });
            } else {
              const addressParts = {
                addressLine1,
                city,
                province,
                postalCode,
              };
              // eslint-disable-next-line no-await-in-loop
              geolocation = await apiService.merchants.batches.geolocation({
                addressParts,
              });
            }

            // eslint-disable-next-line no-await-in-loop
            await new Promise(r => setTimeout(r, 50));

            // When we send an address like "123 Some Road Unit 33" to google
            // it will (sometimes) return "33-123 Some Road"
            // So let's fix this.
            const [address1, address2] = fixAddressLines(geolocation.address, addressLine2);

            payload.latitude = geolocation.lat;
            payload.longitude = geolocation.lng;
            payload.addressLine1 = address1;
            payload.addressLine2 = address2;
            payload.droppedAddress = false;

            this.showLoadingMessage();
          } catch (e) {
            console.log('error with mapping field', data, e);
            // eslint-disable-next-line prefer-template
            this.invalidGeocode.push('error');
          } finally {
            this.mappedResults.push(payload);
          }
        }

        await new Promise(r => setTimeout(r, 50));
        this.$emit('selected', this.mappedResults);
      }
      stopLoadingMessage();
      await new Promise(r => setTimeout(r, 100));

      if (this.invalidGeocode.length) {
        this.showErrorMessage();
      }
    },
  },
});
</script>
