<template>
  <div>
    <redoc :spec-url="`${apiHost}/api/v0/integrations/openapi/openapi.yaml`"></redoc>
  </div>
</template>

<script lang="ts">
import { apiService } from '@tyltgo/shared';
import { defineComponent } from 'vue';

export default defineComponent({
  async mounted() {
    this.$nextTick(() => {
      const script = document.createElement('script');
      script.setAttribute(
        'src',
        'https://cdn.jsdelivr.net/npm/redoc@next/bundles/redoc.standalone.js'
      );
      document.body.appendChild(script);
    });
  },
  computed: {
    apiHost() {
      return apiService.apiHost;
    },
  },
});
</script>
