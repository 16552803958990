<template>
  <div class="inline-block">
    <GoUploadButton
      variant="outline"
      class="text-xs"
      id="csv_upload"
      @filesSelected="parseAslFile"
      accept=".csv"
    >
      Upload ASL CSV
    </GoUploadButton>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { unparse } from 'papaparse';
import GoUploadButton from './GoUploadButton.vue';
import { parseCsv } from '../services/csv-service';
import { downloadBlob } from '../services/browser-service';

export default defineComponent({
  components: {
    GoUploadButton,
  },
  methods: {
    parseAslFile(files) {
      const reader = new FileReader();
      reader.addEventListener('load', e => {
        const file = <string>e.target.result;
        const csvFile = [
          [
            'Shipper Account Number',
            'Shipper Name',
            'Shipper Address Line 1',
            'Shipper Address Line 2',
            'Shipper Address Line 3',
            'Shipper City',
            'Shipper Province',
            'Shipper Postal Code',
            'Consignee Name',
            'Consignee Address Line 1',
            'Consignee Address Line 2',
            'Consignee Address Line 3',
            'Consignee City',
            'Consignee Province',
            'Consignee Postal Code',
            'Country Code Abbreviation',
            'Unique Barcode on Package',
            'Shipper Reference Number',
            'Consignee Reference Number',
            'Piece Number',
            'Piece Weight',
            'Unit of Measurement',
            'Special Instructions',
            'Service Level',
            'Consignee Phone Number',
            'Consignee Email Address',
            'Shipper Country Abbreviation',
            'Item Description',
            'Route Code',
            'Shipper Phone',
            'Reference 3',
            'Reference 4',
            'Labor Code',
            'Job Option',
            'Email Notification',
            'Containerization Barcode',
            'Additional Emails',
            'Requested Date Time',
            'Special Mobile',
            'Pickup Ready Time',
            'Pickup CloseTime',
            'Pickup Location',
            'Delivery Type Code',
            'Diversion Code',
            'Handling Class Code',
            'Source',
            'Pickup Name',
            'Pickup Phone Ext',
            'Job Type',
            'Mode',
            'Sub Status',
            'Pickup Location',
            'Scheduled Date Time',
            'Total Weight',
            'Number Of Pieces',
            'Weight Unit',
            'Job Description',
            'Cut Off Time',
            'Status',
            'Sub Status Comment',
            'Timezone Offset',
            'ShpVers',
            'Reference 2',
            'Scheduled Job ID',
            'Alternate Route Code',
            'Length',
            'Width',
            'Height',
            'Unit Of Measure Length',
            'Sales Order Number',
            'ParcelType',
            'Option',
            'UserDefinedField',
            'SortOrder',
            'SortCode',
            'Pickup Latitude',
            'Pickup Longitude',
            'Delivery Latitiude',
            'Delivery Longitude',
            'Alternate Barcode',
            'AssignmentMode',
          ].join('|'),
          file.toString(),
        ].join('\n');
        setTimeout(() => {
          const parsedCsv = parseCsv(csvFile, { header: true });
          parsedCsv.data = parsedCsv.data
            .filter(row => Object.values(row).filter(x => !!x).length)
            .filter(row => !!row['Shipper Account Number']);
          const backToCsv = unparse(parsedCsv.data as any[]);
          const blob = new Blob([backToCsv], { type: 'text/csv' });
          downloadBlob(blob, `asl-${new Date().toISOString()}.csv`);
        }, 100);
      });
      const file = files[0];
      if (file) {
        reader.readAsText(file);
      }
    },
  },
});
</script>
