<template>
  <main class="flex-1 overflow-y-auto focus:outline-none" tabindex="0">
    <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
      <div class="pt-10 pb-16">
        <div class="px-4 sm:px-6 md:px-0 mb-4 flex justify-between">
          <GoHeader :level="1">Incidents</GoHeader>
        </div>
        <div class="px-4 sm:px-6 md:px-0">
          <Form class="gap-3 grid grid-cols-4 w-full pr-4 mb-4" @submit="searchIncidents">
            <GoTextField
              class="col-span-1"
              name="start"
              v-model="start"
              label="Start Date"
              type="date"
            />
            <GoTextField class="col-span-1" name="end" v-model="end" label="End Date" type="date" />

            <GoMultiselect
              placeholder="All"
              :options="states"
              :name="`state`"
              v-model="state"
              :label="'State'"
              class="col-span-1"
            />

            <div class="col-span-1 flex items-end">
              <GoButton>Search</GoButton>
            </div>
          </Form>
          <div class="flex items-center justify-end">
            <IconLoader v-show="searchLoading" class="w-5 mr-4" />
            <div v-if="incidents" class="text-sm">Showing {{ incidents.length }}</div>
          </div>
          <div class="py-6">
            <div
              v-for="incident of incidents"
              :key="incident.id"
              class="w-full py-2 px-4 border-t-2 dark:border-gray-500 bg-white dark:bg-gray-800"
            >
              <IncidentLine
                :incident="incident"
                :showSource="true"
                @reloadIncident="loadIncidents"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
import { apiService } from '@tyltgo/shared';
import dayjs from 'dayjs';
import GoTextField from '../../components/GoTextField.vue';
import GoButton from '../../components/GoButton.vue';
import IncidentLine from '../../components/IncidentLine.vue';
import { aroundLoadingMessage } from '../../services/ui-message-service';

export default defineComponent({
  components: {
    GoButton,
    GoTextField,
    Form,
    IncidentLine,
  },
  data() {
    return {
      start: '',
      end: '',
      state: '',
      incidents: [],
      searchLoading: false,
    };
  },
  mounted() {
    const end = dayjs();
    this.end = end.format('YYYY-MM-DD');
    this.start = end.subtract(5, 'day').format('YYYY-MM-DD');
  },
  computed: {
    states() {
      return [
        { label: 'New', value: 'new' },
        { label: 'Pending Action', value: 'pendingAction' },
        { label: 'Closed', value: 'closed' },
        { label: 'Deleted', value: 'deleted' },
      ];
    },
  },
  methods: {
    async searchIncidents(values) {
      this.searchLoading = true;
      await aroundLoadingMessage('Searching...', async () => {
        const { incidents } = await apiService.admin.incidents.searchIncidents(values);
        this.incidents = incidents;
      });
      this.searchLoading = false;
    },
    async loadIncidents() {
      const { incidents } = await apiService.admin.incidents.searchIncidents({
        start: this.start,
        end: this.end,
        state: this.state,
      });
      this.incidents = incidents;
    },
  },
});
</script>
