<template>
  <l-marker :lat-lng="[waypoint.lat, waypoint.lng]">
    <l-icon>
      <IconStore
        class="w-8 opacity-80"
        :class="waypointMarkerStyle(waypoint)"
        v-if="waypoint.type === 'pickup'"
      />
      <div class="relative" v-else>
        <span
          class="absolute text-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-2/3"
          style="font-size: 0.6rem"
        >
          {{ waypointNumber }}
        </span>
        <IconMapMarker :class="markerColor" />
      </div>
    </l-icon>
    <l-popup :options="{ maxWidth: 750 }">
      <div v-if="link.includes('merchant')" class="flex flex-col">
        <div class="mb-1">
          <GoBadge :class="waypointStyle(waypoint)">
            <span>{{ waypointDisplayState(waypoint) }}</span>
          </GoBadge>
        </div>
        <router-link :to="{ name: link, params: { id: waypoint.routeId } }">
          Route {{ waypoint.routeId }}
        </router-link>
        <div class="space-x-1">
          <router-link
            class="uppercase inline"
            :to="{ name: link, params: { id: waypoint.routeId }, hash: `#${waypoint.id}` }"
          >
            {{ waypoint.type }}
            <span v-if="waypoint.type === 'dropoff' && waypointNumber">#{{ waypointNumber }}</span>
          </router-link>
          <span v-if="waypoint.type === 'dropoff'">- {{ waypoint.id }}</span>
        </div>
        <span class="text-gray-500">
          {{ waypoint.name }}
        </span>
        <span class="text-gray-700">
          {{ waypoint.address }}
        </span>
      </div>
      <div v-else class="flex flex-col">
        <WaypointCard
          :waypoint="waypoint"
          :routeId="route.id"
          :ref="waypoint.id"
          :waypointNumber="waypointNumber"
          :route="route"
        />
      </div>
    </l-popup>
  </l-marker>
</template>

<script lang="ts">
import { LIcon, LMarker, LPopup } from '@vue-leaflet/vue-leaflet';
import { defineComponent } from 'vue';
import { waypointMarkerStyle, waypointStyle, waypointDisplayState } from '@tyltgo/shared';
import IconMapMarker from './IconMapMarker.vue';
import WaypointCard from './WaypointCard.vue';
import IconStore from './IconStore.vue';
import GoBadge from './GoBadge.vue';

export default defineComponent({
  setup() {
    return { waypointMarkerStyle, waypointDisplayState, waypointStyle };
  },
  components: {
    LIcon,
    LPopup,
    LMarker,
    IconMapMarker,
    IconStore,
    GoBadge,
    WaypointCard,
  },
  props: {
    waypoint: {
      type: Object,
      required: true,
    },
    route: {
      type: Object,
      required: true,
    },
    waypointNumber: {
      type: Number,
      required: false,
    },
    zoom: {
      type: Number,
    },
    color: {
      type: String,
      required: false,
    },
    link: {
      type: String,
      required: true,
    },
  },
  computed: {
    markerColor() {
      if (this.color) {
        return this.color;
      }
      if (this.waypoint.state !== 'completed' && this.waypoint.relativePriorityTime) {
        return 'text-yellow-400 w-4';
      }
      return waypointMarkerStyle(this.waypoint, this.zoom);
    },
  },
});
</script>
