<template>
  <div class="h-screen flex">
    <div class="hidden lg:flex lg:flex-shrink-0">
      <div class="w-64 fixed h-screen flex flex-col">
        <nav
          class="bg-brand-1 border-r border-gray-200 pt-5 pb-4 flex flex-col flex-grow overflow-y-auto text-white"
        >
          <div class="flex-shrink-0 px-4 flex flex-col items-start">
            <h1 class="text-5xl font-logo text-gray-100">Tyltgo</h1>
            <h2 class="mt-2 text-base font-header text-gray-100">Courier Portal</h2>
          </div>
          <div class="flex-grow mt-5 flex flex-col">
            <div class="flex-1 space-y-1">
              <router-link :to="{ name: 'courier.profile' }" class="nav-link">
                <UserCircleIcon />
                Profile
              </router-link>
            </div>
          </div>
          <div class="flex-shrink-0 block w-full">
            <div class="px-4 text-gray-200">{{ currentUser.email }}</div>
            <div class="pb-2 px-4 text-gray-200">
              {{ currentUser.firstName }} {{ currentUser.lastName }}
            </div>

            <a
              href="#"
              @click="logout"
              class="group py-2 px-4 flex items-center text-sm font-medium text-gray-200 hover:text-gray-900 hover:bg-gray-50"
            >
              <IconSignout class="text-gray-300 group-hover:text-gray-500 mr-3 h-6 w-6" />
              Logout
            </a>
          </div>
        </nav>
      </div>
    </div>

    <!-- Content area -->
    <div class="relative flex-1 flex flex-col lg:ml-64">
      <main class="flex-1 focus:outline-none" tabindex="0">
        <router-view />
      </main>
    </div>
    <GoMessages />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { authService } from '@tyltgo/shared';
import { UserCircleIcon } from '@heroicons/vue/outline';
import IconSignout from '../components/IconSignout.vue';
import { currentUser } from '../services/auth-service';
import GoMessages from '../components/GoMessages.vue';

export default defineComponent({
  setup() {
    return {
      currentUser,
    };
  },
  components: {
    UserCircleIcon,
    IconSignout,
    GoMessages,
  },
  methods: {
    async logout() {
      await authService.logout();
      this.$router.push({ name: 'courier.login' });
    },
  },
});
</script>

<style lang="scss" scoped>
.nav-link {
  @apply border-transparent text-gray-200 border-l-4 py-2 px-3 flex items-center text-sm font-medium group;

  &:hover {
    @apply text-gray-900 bg-gray-50;
  }

  &.router-link-active {
    @apply bg-primary-50 border-primary-600 text-primary-800;
    svg {
      @apply text-primary-700;
    }
  }

  svg {
    @apply text-gray-300 h-6 w-6 mr-3;
  }
}
</style>
