<template>
  <main class="flex-1 overflow-y-auto focus:outline-none" tabindex="0">
    <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
      <div class="pt-10 pb-16" v-if="merchant">
        <div>
          <div class="">
            <div class="border-b border-gray-200 mb-4">
              <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                <router-link
                  v-for="(tab, key) in tabs"
                  :key="key"
                  :to="{
                    name: 'merchant.settings.tab',
                    params: { id: merchant.email, tab: key },
                  }"
                  class="whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                  :class="
                    currentTab && currentTab.name === tab.name
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                  "
                  aria-current="page"
                >
                  {{ tab.name }}
                </router-link>
              </nav>
            </div>
            <div>
              <component
                v-if="currentTab"
                :is="currentTab.component"
                :merchant="merchant"
                @loadMerchant="loadMerchant"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent, markRaw } from 'vue';
import { apiService } from '@tyltgo/shared';
import MerchantServiceArea from './MerchantServiceArea.vue';

const tabs = {
  serviceArea: {
    name: 'Service Area',
    component: markRaw(MerchantServiceArea),
  },
};

export default defineComponent({
  components: {},
  data() {
    return {
      merchant: null,
      loading: false,
      tabs,
      currentTab: null,
    };
  },
  async created() {
    this.merchant = await apiService.merchants.auth.currentUser();
  },
  async mounted() {
    const { tab } = this.$route.params;
    this.currentTab = tabs[tab] || tabs.serviceArea;
    await this.loadMerchant();
  },
  watch: {
    async $route(to, from) {
      const { tab } = this.$route.params;
      if (to.name === 'merchant.settings.tab') {
        this.currentTab = tabs[tab] || tabs.serviceArea;
      }
      if (to.name.startsWith('merchant.settings') && to.params.id !== from.params.id) {
        await this.loadMerchant();
        this.currentTab = null;
        setTimeout(() => {
          this.currentTab = tabs[tab] || tabs.serviceArea;
        }, 10);
      }
    },
  },
  methods: {
    async loadMerchant() {
      this.loading = true;
      this.merchant = await apiService.merchants.auth.currentUser();
      this.loading = false;
    },
  },
});
</script>
