<template>
  <div>
    <table class="min-w-full border-collapse">
      <tr class="bg-gray-50 dark:bg-gray-700 border-b-2 dark:border-gray-500">
        <th v-if="selectable" scope="col" class="relative w-12 px-6 sm:w-16 sm:px-8">
          <input
            type="checkbox"
            class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
            :checked="indeterminate || selectedRows.length === tableData.data.length"
            :indeterminate="indeterminate"
            @change="
              selectedRows = $event.target.checked
                ? tableData.data.filter(selectableRow).map(selectableValue)
                : []
            "
          />
        </th>
        <th
          scope="col"
          class="px-6 py-3 text-left text-xs font-medium text-gray-800 dark:text-gray-100 uppercase tracking-wider"
          v-for="header of headers"
          :key="header"
        >
          {{ header }}
        </th>
      </tr>
      <tbody class="bg-white dark:bg-gray-800">
        <template v-for="row in rows" :key="row">
          <tr
            class="border-b-2 dark:border-gray-500"
            :class="[rowClass ? rowClass(tableData.data[row[0].rowIndex]) : '']"
          >
            <td v-if="selectable" class="relative w-12 px-6 sm:w-16 sm:px-8">
              <input
                v-if="selectableRow(tableData.data[row[0].rowIndex], row[0].rowIndex)"
                type="checkbox"
                class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                :value="selectableValue(tableData.data[row[0].rowIndex], row[0].rowIndex)"
                v-model="selectedRows"
              />
            </td>

            <td
              v-for="(column, index) in row"
              :key="index"
              class="px-6 py-3 text-sm font-medium text-gray-900 dark:text-gray-100"
              :class="[{ 'whitespace-nowrap': !column.wrap, 'text-right': column.right }]"
            >
              <GoTooltip v-if="column.tooltip" :text="column.tooltip">
                {{ column.value }}
              </GoTooltip>
              <pre v-else-if="column.pre" class="text-xs whitespace-pre-line">{{
                column.value
              }}</pre>
              <span v-else>
                {{ column.value }}
              </span>
            </td>
          </tr>
          <tr class="page-break"></tr>
        </template>
      </tbody>
    </table>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import GoTooltip from './GoTooltip.vue';

export default defineComponent({
  components: {
    GoTooltip,
  },
  emits: ['selectedRows'],
  props: {
    rowClass: {
      type: Function,
      required: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    selectableRow: {
      type: Function,
      default: (_row, _index) => true,
    },
    selectableValue: {
      type: Function,
      default: (row, index) => index,
    },
    tableData: {
      type: Object,
      required: true,
    },
  },
  watch: {
    selectedRows(value) {
      this.$emit('selectedRows', value);
    },
  },
  data() {
    return {
      selectedRows: [],
    };
  },
  computed: {
    indeterminate() {
      return (
        this.selectedRows.length > 0 &&
        this.selectedRows.length <
          this.tableData.data.filter(this.selectableRow).map(this.selectableValue)
      );
    },
    headers() {
      return this.tableData.headers.map(header => header.title);
    },
    rows() {
      return this.tableData.data.map((row, index) => {
        return this.tableData.headers.map(header => {
          return {
            rowIndex: index,
            right: !!header.right,
            pre: !!header.pre,
            wrap: header.wrap === undefined ? true : header.wrap,
            tooltip: header.tooltip ? header.tooltip(row[header.key]) : '',
            value: header.valueFunction ? header.valueFunction(row) : row[header.key],
          };
        });
      });
    },
  },
});
</script>

<style>
@media print {
  tr.page-break {
    display: block !important;
    page-break-inside: avoid;
    page-break-after: auto;
  }
  tr {
    page-break-inside: avoid;
  }
  td {
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }
  @page {
    size: auto;
    margin: 1cm;
  }
}
</style>
