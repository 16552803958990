<template>
  <div class="w-full pb-2 px-1 dark:bg-gray-800">
    <div>
      <div class="flex flex-wrap sm:justify-between sm:items-center sm:flex-row-reverse py-2">
        <div class="flex space-x-2">
          <GoBadge :class="typeStyle">
            {{ startCase(incident.type) }}
          </GoBadge>
          <GoBadge class="text-gray-800 bg-gray-200">
            {{ startCase(incident.subType) }}
          </GoBadge>
          <GoDropdown v-if="incident.status !== 'deleted'">
            <template v-slot:button>
              <IconEllipsis class="flex-none w-3 text-gray-600 dark:text-gray-300" />
            </template>
            <template v-slot:items>
              <GoDropdownItem @click="showTakeActionIncidentModal = true">
                Take Action
              </GoDropdownItem>
              <GoDropdownItem @click="showCloseIncidentModal = true">Close</GoDropdownItem>
              <GoDropdownItem @click="deleteIncident()">Delete</GoDropdownItem>
            </template>
          </GoDropdown>
          <div v-if="incident.status === 'deleted'" class="flex px-2"></div>
        </div>
        <div class="flex space-x-2">
          <GoBadge :class="statusStyle">{{ startCase(incident.status) }}</GoBadge>
          <GoBadge v-if="showSource" class="text-gray-800 bg-gray-200">
            {{ startCase(incident.source) }}
          </GoBadge>
          <GoBadge class="text-gray-800 bg-gray-200">
            {{ startCase(incident.category) }}
          </GoBadge>
        </div>
      </div>
      <div v-if="incident.routeId" class="flex space-x-2">
        <p class="text-base">
          <router-link :to="`/admin/routes/${incident.routeId}`">
            Route {{ incident.routeId }}
          </router-link>
        </p>
      </div>
      <div v-if="incident.merchantId" class="flex space-x-2">
        <IconStore class="flex-none w-3 text-gray-600 dark:text-gray-300" />
        <p class="text-sm">
          <router-link :to="`/admin/merchants/${incident.merchantId}`">
            Merchant {{ incident.merchantId }}
          </router-link>
        </p>
      </div>
      <div class="flex space-x-2">
        <IconCar v-if="incident.courierId" class="flex-none w-3 text-gray-600 dark:text-gray-300" />
        <router-link
          v-if="incident.courierId"
          class="text-sm"
          :to="`/admin/couriers/${incident.courierId}`"
        >
          {{ incident.courierId }}
        </router-link>
      </div>
      <div class="flex space-x-2">
        <IconMapMarker
          v-if="incident.waypointId"
          class="flex-none w-3 text-gray-600 dark:text-gray-300"
        />
        <span class="text-xs">{{ incident.waypointId }}</span>
      </div>
      <div class="flex space-x-2">
        <div class="flex-none w-3 text-gray-600 dark:text-gray-300"></div>
        <span class="text-xs">
          Reported:
          <b>{{ fromNow(incident.createdAt) }}</b>
        </span>
      </div>
      <div class="flex space-x-2 mb-2">
        <div class="flex-none w-3 text-gray-600 dark:text-gray-300"></div>
        <span v-if="incident.reportedBy" class="text-xs">
          By:
          <b>{{ incident.reportedBy }}</b>
        </span>
      </div>
      <div v-if="incident.description" class="flex space-x-2">
        <div class="flex-none w-3 text-gray-600 dark:text-gray-300"></div>
        <span class="text-sm">
          <u>Description:</u>
          {{ incident.description }}
        </span>
      </div>
      <div v-if="incident.pendingAction" class="flex space-x-2">
        <div class="flex-none w-3 text-gray-600 dark:text-gray-300"></div>
        <span class="text-sm">
          <u>Pending Action:</u>
          {{ incident.pendingAction }}
        </span>
      </div>
      <div v-if="incident.closedReason" class="flex space-x-2">
        <div class="flex-none w-3 text-gray-600 dark:text-gray-300"></div>
        <span class="text-sm">
          <u>Closed Reason:</u>
          {{ incident.closedReason }}
        </span>
      </div>
    </div>
    <GoModal v-if="showCloseIncidentModal" @close="showCloseIncidentModal = false">
      <div class="p-4">
        <p class="text-lg">Close Incident</p>
      </div>
      <div class="p-4">
        <Form class="space-y-4" @submit="closeIncident">
          <GoTextArea :name="`closedReason`" label="Reason" :hint="'optional'" />
          <div class="flex justify-end">
            <GoButton class="text-xs" variant="outline">Close</GoButton>
          </div>
        </Form>
      </div>
    </GoModal>
    <GoModal v-if="showTakeActionIncidentModal" @close="showTakeActionIncidentModal = false">
      <div class="p-4">
        <p class="text-lg">Take Action</p>
      </div>
      <div class="p-4">
        <Form class="space-y-4" @submit="takeAction">
          <GoTextArea :name="`pendingAction`" label="Action" :hint="'optional'" />
          <div class="flex justify-end">
            <GoButton class="text-xs" variant="outline">Proceed</GoButton>
          </div>
        </Form>
      </div>
    </GoModal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
import { apiService, fromNow, readableDateTime, readableDuration } from '@tyltgo/shared';
import startCase from 'lodash/startCase';
import IconCar from './IconCar.vue';
import IconEllipsis from './IconEllipsis.vue';
import GoDropdown from './GoDropdown.vue';
import GoDropdownItem from './GoDropdownItem.vue';
import GoBadge from './GoBadge.vue';
import GoButton from './GoButton.vue';
import { time } from '../services/date-service';
import { showConfirmationModal } from '../services/ui-message-service';

export default defineComponent({
  setup() {
    return {
      readableDateTime,
      time,
      fromNow,
      readableDuration,
      startCase,
    };
  },
  components: {
    IconCar,
    IconEllipsis,
    GoDropdown,
    GoDropdownItem,
    GoBadge,
    GoButton,
    Form,
  },
  props: {
    incident: {
      type: Object,
      required: true,
    },
    showSource: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showCloseIncidentModal: false,
      showTakeActionIncidentModal: false,
    };
  },
  computed: {
    statusStyle() {
      const colors = {
        new: 'text-yellow-800 bg-yellow-100',
        pendingAction: 'text-blue-800 bg-blue-100',
        deleted: 'text-red-800 bg-red-100',
        closed: 'text-gray-800 bg-gray-200',
      };
      return colors[this.incident.status] || 'text-gray-800 bg-gray-200';
    },
    typeStyle() {
      const colors = {
        strike: 'text-red-800 bg-red-100',
      };
      return colors[this.incident.type] || 'text-gray-800 bg-gray-200';
    },
  },
  methods: {
    async takeAction(values) {
      await apiService.admin.incidents.takeAction(this.incident.id, values);
      this.showTakeActionIncidentModal = false;
      this.$emit('reloadIncident');
    },
    async deleteIncident() {
      const confirmed = await showConfirmationModal({
        color: 'danger',
        title: 'Confirm Incident Deletion',
        message: `Are you sure you want to delete this incident?`,
        confirmButtonText: 'Delete Incident',
      });
      if (confirmed) {
        await apiService.admin.incidents.deleteIncident(this.incident.id);
        this.$emit('reloadIncident');
      }
    },
    async closeIncident(values) {
      await apiService.admin.incidents.closeIncident(this.incident.id, values);
      this.showCloseIncidentModal = false;
      this.$emit('reloadIncident');
    },
  },
});
</script>
