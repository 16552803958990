<template>
  <div class="min-h-screen flex flex-col">
    <div class="bg-brand-1 px-4 md:mb-8">
      <div class="flex max-w-7xl mx-auto py-4 items-center">
        <a href="https://www.tyltgo.com" class="text-4xl font-logo text-gray-100">Tyltgo</a>
        <p class="ml-12 text-white font-header font-bold text-2xl">Merchant Signup</p>
      </div>
    </div>
    <div v-if="merchant" class="sm:mx-auto sm:w-full sm:max-w-md">
      <GoHeader :level="2" class="px-4 mt-8 text-center">
        We're excited to partner with {{ merchant.businessName }}!
      </GoHeader>
    </div>

    <div v-if="merchant" class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 space-y-2">
        <Form
          class="space-y-4 mb-4"
          @submit="completeSignup"
          :validation-schema="schema"
          autocomplete="off"
        >
          <input autocomplete="false" name="hidden" type="text" style="display: none" />

          <GoTextField name="firstName" type="text" />
          <GoTextField name="lastName" type="text" />
          <GoTextField name="phoneNumber" type="text" />
          <GoTextField
            name="email"
            type="email"
            :value="merchant.email"
            placeholder="you@example.com"
            :disabled="true"
          />
          <GoTextField name="password" type="password" autocomplete="new-password" />
          <div>
            <GoLabel label="Credit Card" />
            <div
              class="shadow-sm block w-full sm:text-sm rounded-md disabled:opacity-80 focus:ring-indigo-500 focus:border-indigo-500 text-gray-800 px-2 py-3 mt-1"
              :class="creditCardErrorMessage ? 'border-red-400 border-2' : 'border-gray-300 border'"
            >
              <div ref="card"></div>
            </div>
            <GoErrorMessage v-if="creditCardErrorMessage" :message="creditCardErrorMessage" />
          </div>
          <GoAddressField label="Invoice Address" name="invoiceAddressFull" />
          <GoCheckbox name="agreement" value="true" position="start">
            <template #label>
              By clicking this box, you agree that you have read TyltGO's
              <a href="https://www.tyltgo.com/company/terms-conditions" target="_blank">
                Terms of Service
              </a>
              and
              <a href="https://www.tyltgo.com/company/privacy-policy" target="_blank">
                Privacy Policy
              </a>
              and agree to the terms and conditions within them.
            </template>
          </GoCheckbox>
          <GoHiddenField name="signupAccessKey" :value="merchant.signupAccessKey" />
          <GoButton class="w-full">Complete Signup</GoButton>
          <div v-if="failureMessage" class="rounded-md bg-red-50 p-4">
            <h3 class="text-sm font-medium text-red-800">
              {{ failureMessage }}
            </h3>
          </div>
        </Form>
      </div>
    </div>
    <GoMessages />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
import { apiService, authService } from '@tyltgo/shared';
import startCase from 'lodash/startCase';
import * as yup from 'yup';
import GoTextField from '../../components/GoTextField.vue';
import GoLabel from '../../components/GoLabel.vue';
import GoHiddenField from '../../components/GoHiddenField.vue';
import GoButton from '../../components/GoButton.vue';
import GoCheckbox from '../../components/GoCheckbox.vue';
import GoMessages from '../../components/GoMessages.vue';
import GoErrorMessage from '../../components/GoErrorMessage.vue';
import { aroundLoadingMessage } from '../../services/ui-message-service';

const schema = yup.object().shape({
  password: yup.string().required().label('Password'),
  firstName: yup.string().required().label('First Name'),
  lastName: yup.string().required().label('Last Name'),
  phoneNumber: yup.string().required().label('Phone Number'),
  agreement: yup.boolean().required().label('Agreement'),
  invoiceAddressFull: yup.object({
    address: yup.string().required().label('Invoice Address'),
  }),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let stripe: any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let card: any;

export default defineComponent({
  setup() {
    return {
      startCase,
      schema,
    };
  },
  components: {
    Form,
    GoTextField,
    GoHiddenField,
    GoButton,
    GoCheckbox,
    GoMessages,
    GoLabel,
    GoErrorMessage,
  },
  data() {
    return {
      merchant: null,
      failureMessage: null,
      creditCardErrorMessage: null,
      stripe: null,
    };
  },
  async mounted() {
    await this.getMerchantInfo();
    this.$nextTick(() => {
      const stripeScript = document.createElement('script');
      stripeScript.onload = () => {
        this.setupStripeCard();
      };
      stripeScript.setAttribute('src', 'https://js.stripe.com/v3/');
      document.body.appendChild(stripeScript);
    });
  },
  methods: {
    setupStripeCard() {
      const keys = {
        development:
          'pk_test_51Gu81eDfXLMpF6ZZN9UTWOaNXItyXs7ivmZHQEpoLypgH7PCbTn9HnTL7H0YS2vqd54qgSCU0yHcc5BTD31iQIEU00NqqZroWI',
        staging:
          'pk_test_51Gu81eDfXLMpF6ZZN9UTWOaNXItyXs7ivmZHQEpoLypgH7PCbTn9HnTL7H0YS2vqd54qgSCU0yHcc5BTD31iQIEU00NqqZroWI',
        production: 'pk_live_xLy2qulRepTnpi4TCzwRyh5k00frMMZsF5',
      };

      stripe = window.Stripe(keys[import.meta.env.MODE]);
      card = stripe.elements().create('card', { hidePostalCode: true });
      card.mount(this.$refs.card);
    },
    async getMerchantInfo() {
      const { key } = this.$route.params;
      const { merchant } = await apiService.merchants.auth.signupMerchantInfo(key);
      this.merchant = merchant;
    },
    async completeSignup(values) {
      this.failureMessage = null;
      this.creditCardErrorMessage = null;

      await aroundLoadingMessage('Loading ...', async () => {
        const result = await stripe.createToken(card);
        if (result.error?.message) {
          this.creditCardErrorMessage = result.error.message;
          this.$forceUpdate();
          return;
        }

        const { token } = await apiService.merchants.auth.completeSignup({
          ...values,
          stripeToken: result.token.id,
        });

        await authService.loginSuccess(token);
        this.$router.push({ name: 'merchant.batches' });
      });
    },
  },
});
</script>
