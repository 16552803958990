<template>
  <GoModal v-slot="{ close }" width="small">
    <Form @submit="confirm" :validation-schema="errorSchema">
      <div class="sm:flex sm:items-start">
        <div
          class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10"
          :class="colors.bg"
        >
          <XCircleIcon v-if="color === 'danger'" class="h-6 w-6 text-red-600" />
          <ExclamationIcon v-else-if="color === 'warning'" class="h-6 w-6 text-yellow-600" />
          <CheckCircleIcon v-else-if="color === 'success'" class="h-6 w-6 text-green-600" />
          <InformationCircleIcon v-else class="h-6 w-6 text-primary-600" />
        </div>
        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 v-if="title" class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
            {{ title }}
          </h3>
          <div class="mt-2">
            <p class="text-base text-gray-500">
              {{ message }}
            </p>
            <div v-if="matchText" class="pt-8">
              <GoTextField
                name="typedMatchText"
                label="false"
                v-model="typedMatchText"
                :help="matchTextMessage"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5 sm:mt-6 sm:flex sm:justify-between">
        <GoButton class="text-xs" type="button" variant="link" @click="close">
          {{ closeButtonText }}
        </GoButton>
        <GoButton class="text-xs" :color="colors.button" variant="outline">
          {{ confirmButtonText }}
        </GoButton>
      </div>
    </Form>
  </GoModal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {
  ExclamationIcon,
  InformationCircleIcon,
  XCircleIcon,
  CheckCircleIcon,
} from '@heroicons/vue/outline';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import GoModal from './GoModal.vue';
import GoButton from './GoButton.vue';
import GoTextField from './GoTextField.vue';

const styles = {
  primary: {
    bg: 'bg-primary-50',
    button: 'primary',
  },
  success: {
    bg: 'bg-green-50',
    button: 'success',
  },
  information: {
    bg: 'bg-blue-50',
    button: 'primary',
  },
  danger: {
    bg: 'bg-red-50',
    button: 'danger',
  },
  warning: {
    bg: 'bg-yellow-50',
    button: 'warning',
  },
};

export default defineComponent({
  emits: ['confirm'],
  components: {
    GoModal,
    GoButton,
    GoTextField,
    ExclamationIcon,
    InformationCircleIcon,
    CheckCircleIcon,
    XCircleIcon,
    Form,
  },
  props: {
    message: {
      type: String,
    },
    title: {
      type: String,
    },
    color: {
      type: String,
      default: 'primary',
    },
    confirmButtonText: {
      type: String,
      default: 'Ok',
    },
    closeButtonText: {
      type: String,
      default: 'Close',
    },
    matchText: {
      type: String,
      default: '',
    },
    matchTextMessage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      typedMatchText: '',
    };
  },
  computed: {
    errorSchema() {
      if (this.matchText) {
        return yup.object().shape({
          typedMatchText: yup
            .string()
            .required()
            .test('match', this.matchTextMessage, value => this.matchText === value)
            .label('This'),
        });
      }
      return undefined;
    },
    colors() {
      return styles[this.color];
    },
  },
  methods: {
    confirm() {
      if (this.matchText && this.matchText !== this.typedMatchText) {
        return;
      }

      this.$emit('confirm');
    },
  },
});
</script>
