<template>
  <div>
    <div v-if="startDateTime">
      <Form
        @submit="loadRawOrders"
        class="space-y-4 mb-4"
        :initial-values="{
          status: 'unrouted',
          dateTime: startDateTime,
          fsaFilterList,
          fsaIncludeList,
        }"
      >
        <GoTextField
          class="w-full mb-2"
          label="FSAs Include List (comma delimited)"
          name="fsaIncludeList"
        />
        <GoTextField
          class="w-full mb-2"
          label="FSAs Exclude List (comma delimited)"
          name="fsaFilterList"
        />
        <GoSelect
          class="w-full md:w-1/4 mb-4"
          label="Status"
          name="status"
          :options="{ both: 'Both', routed: 'Routed', unrouted: 'Unrouted' }"
        />
        <GoTextField
          type="datetime-local"
          class="w-full md:w-1/4"
          label="Earliest Scan Time"
          name="dateTime"
        />
        <GoButton ref="loadFormButton">Load Raw Orders</GoButton>
      </Form>
    </div>
    <div v-if="orders?.length">
      <pre class="py-2"
        >{{ orders.length }} Total Orders - {{ routedOrders.length }} Routed - {{
          orders.length - routedOrders.length
        }} Unrouted</pre
      >
      <Form
        ref="form"
        @submit="previewRoutes"
        class="space-y-4"
        :initial-values="{ orders, batchCapacity, timeConstraint }"
        v-slot="{ handleSubmit }"
        :validation-schema="schema"
      >
        <div
          v-for="(order, index) of orders"
          :key="order.id"
          class="bg-white dark:bg-gray-800 p-4 shadow rounded-sm mb-4 flex justify-between"
          :class="
            {
              include: 'bg-blue-50 dark:bg-blue-700 border-2 border-blue-500',
              routed: 'bg-green-50 dark:bg-green-700 border-2 border-green-500',
            }[ordersStatus[order.id] || order.status]
          "
        >
          <div class="flex flex-col">
            <p class="mb-1">
              {{ order.name }}
              <span class="inline-block ml-2 text-xs">{{ order.orderId }}</span>
              <span class="text-xs ml-2">{{ order.date }}</span>
              <span v-if="isToday(order.date)" class="text-xs ml-2 uppercase">Today</span>
              <span v-if="!isToday(order.date)" class="text-xs ml-2 uppercase text-red-400">
                Not Today
              </span>
            </p>
            <div class="grid grid-cols-2 gap-y-1 gap-x-4">
              <div class="col-span-1">
                <p class="text-xs">{{ order.phoneNumber }}</p>
                <p class="text-xs">{{ order.email }}</p>
                <p class="text-sm">
                  <span class="text-xs uppercase block text-gray-400">Formatted Address</span>
                  <span class="block">{{ order.geolocation?.formattedAddress }}</span>
                  <span class="block">
                    {{ order.geolocation?.latitude }}, {{ order.geolocation?.longitude }}
                  </span>
                  <span
                    v-if="isOutside(order.geolocation)"
                    @click="geocode(order.id)"
                    class="text-xs uppercase block text-red-600 py-1 border border-red-300 rounded-md px-1"
                  >
                    Outside
                  </span>
                </p>
              </div>
              <div class="col-span-1 text-sm">
                <p>
                  {{ order.addressLine1 }}, {{ order.city }}, {{ order.province }}
                  {{ order.postalCode }}
                </p>
                <p>
                  {{ order.addressLine2 }}
                  <span class="text-xs to-gray-400">{{ order.addressType }}</span>
                </p>
              </div>
              <p class="col-span-2 text-sm">{{ order.notes }}</p>
            </div>
            <GoTooltip text="Quantity" class="flex space-x-2">
              <IconBoxOpen class="w-4 text-gray-600 dark:text-gray-400" />
              <div class="text-sm font-body align-middle">{{ order.quantity }}</div>
            </GoTooltip>
            <div v-if="order.status !== 'routed'">
              <GoHiddenField :name="`orders[${index}].id`" :value="order.id" />
              <GoTextField
                type="number"
                class="w-full md:w-1/4"
                label="Priority (hours)"
                :name="`orders[${index}].relativePriorityTime`"
                :value="priorityTime(order)"
              />
            </div>
          </div>
          <div>
            <p class="text-gray-400 text-sm">{{ displayTime(order.createdAt) }}</p>
            <template v-if="order.status === 'routed'">
              <p class="text-green-500 text-base pb-2">Routed</p>
              <GoButton
                rounded="rounded-md"
                color="warning"
                class="text-xs"
                @click.prevent.stop="unroute(order.id)"
              >
                Un-Route
              </GoButton>
            </template>
            <template v-else>
              <GoButton
                @click.prevent.stop="includeAllAfter(index)"
                rounded="rounded-md"
                color="secondary"
                class="text-xs"
              >
                Include & All After
              </GoButton>
              <GoCheckbox
                label="Include"
                value="include"
                :name="`orders[${index}].status`"
                v-model="ordersStatus[order.id]"
              />
            </template>
          </div>
        </div>
        <GoSelect
          class="w-full md:w-2/4 mb-4"
          label="Pickup Address"
          name="businessAddress"
          :options="allBusinessAddresses"
          label-field="businessAddress"
          value-field="businessAddress"
          v-model="businessAddress"
        />
        <GoTextField
          type="number"
          class="w-full md:w-1/4"
          label="Batch Capacity"
          name="batchCapacity"
        />
        <GoTextField
          type="number"
          class="w-full md:w-1/4"
          label="Time Constraint (hours)"
          name="timeConstraint"
        />
        <GoTextField type="date" class="w-full md:w-1/4" label="Date" name="date" />
        <div class="flex flex-row">
          <GoTextField type="time" class="w-full md:w-1/4" label="Pickup Time" name="endWindow" />
          <div class="flex-col">
            <GoLabel class="pl-4" label="Timezone" />
            <div class="pt-2 pl-4 text-gray-700 text-center">{{ timeZoneId || 'N/A' }}</div>
          </div>
        </div>
        <pre class="py-2">{{ selectedOrders.length }} Selected Orders</pre>
        <GoCheckbox
          v-if="routeNow"
          :name="`routeNow`"
          label="Route Immediately"
          :value="true"
          v-model="routeNow"
          class="col-span-4 sm:col-span-1"
        />
        <GoButton :disabled="!selectedOrders.length">
          <span v-if="routeNow">Route Without Preview</span>
          <span v-else>Preview Routes</span>
        </GoButton>
        <div
          v-if="previewResults.unassignedDropoffs && previewResults.unassignedDropoffs.length"
          class="p-4 bg-red-50"
        >
          <p class="py-2 text-red-600">
            {{ previewResults.unassignedDropoffs.length }} Unassigned Dropoffs
          </p>
          <GoTable
            :tableData="{
              headers: [
                { title: 'ID', key: 'id' },
                { title: 'Name', key: 'name' },
                { title: 'Address', key: 'address' },
              ],
              data: previewResults.unassignedDropoffs,
            }"
          ></GoTable>
        </div>
        <div v-if="previewResults.routes" class="py-4">
          <div class="w-full mb-4" style="height: 60vh" v-if="showPreviewMap">
            <l-map ref="map" @ready="setMap(previewResults.routes, 'map')" :zoom="6">
              <MapTiles />

              <div :key="previewResults.routes.length">
                <DrawMapRoute
                  v-for="route in previewResults.routes"
                  :key="route.id"
                  :route="route"
                  :markers="false"
                  :color="`random`"
                  link="admin.route"
                />
              </div>
            </l-map>
          </div>
          <GoButton
            variant="link"
            class="text-xs"
            @click.prevent.stop="showPreviewMap = !showPreviewMap"
          >
            {{ showPreviewMap ? 'Hide' : 'Show' }} Map
          </GoButton>
          <p class="py-2 text-2xl font-bold">
            {{ pluralize('Route', previewResults.routes.length, true) }}
            -
            {{ pluralize('Dropoff', previewRoutesDropoffCount, true) }}
          </p>
          <div v-for="route in previewResults.routes" :key="route.id">
            <RouteCard :route="route" :mergedRouteResult="true" />
          </div>
          <GoButton
            v-if="canCreateRoutes"
            @click.prevent.stop="handleSubmit($event, generateRoutes)"
          >
            Create Routes
          </GoButton>
        </div>
      </Form>
    </div>
    <div v-if="createdRoutes" class="py-8">
      <p class="py-2">{{ createdRoutes.length }} Routes Created</p>
      <div v-for="route in createdRoutes" :key="route.id">
        <RouteCard :route="route" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { apiService, calculateDistance } from '@tyltgo/shared';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { LMap } from '@vue-leaflet/vue-leaflet';
import pluralize from 'pluralize';
import io from 'socket.io-client';
import GoLabel from './GoLabel.vue';
import GoTable from './GoTable.vue';
import GoButton from './GoButton.vue';
import GoSelect from './GoSelect.vue';
import GoCheckbox from './GoCheckbox.vue';
import GoTextField from './GoTextField.vue';
import GoHiddenField from './GoHiddenField.vue';
import RouteCard from './RouteCard.vue';
import DrawMapRoute from './DrawMapRoute.vue';
import {
  aroundLoadingMessage,
  showConfirmationModal,
  showLoadingMessage,
  stopLoadingMessage,
} from '../services/ui-message-service';
import { timezoneConversionCurrentUserInput } from '../services/date-service';
import MapTiles from './MapTiles.vue';

dayjs.extend(advancedFormat);

const schema = yup.object().shape({
  businessAddress: yup.string().required().label('Pickup Address'),
  batchCapacity: yup.number().required().label('Batch Capacity'),
  endWindow: yup.string().required().label('Pickup Time'),
  date: yup.string().required().label('Date'),
});

export default defineComponent({
  setup() {
    return {
      schema,
      timezoneConversionCurrentUserInput,
      pluralize,
    };
  },
  components: {
    MapTiles,
    LMap,
    DrawMapRoute,
    GoTable,
    GoButton,
    GoSelect,
    GoCheckbox,
    GoTextField,
    GoHiddenField,
    RouteCard,
    Form,
    GoLabel,
  },
  props: {
    merchant: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      routeNow: false,
      showPreviewMap: false,
      businessAddress: '',
      orders: null,
      ordersStatus: {},
      previewResults: {
        routes: null,
        unassignedDropoffs: null,
      },
      createdRoutes: null,
      timeZoneId: null,
      batchCapacity: null,
      timeConstraint: null,
      startDateTime: '',
      fsaIncludeList: '',
      fsaFilterList: '',
      socket: null,
      someKey: null,
    };
  },
  async mounted() {
    this.batchCapacity = this.merchant.settings.batchCapacity;
    this.timeConstraint = this.merchant.settings.timeConstraint;
    this.startDateTime = dayjs().subtract(1, 'day').startOf('day').format('YYYY-MM-DDTHH:mm');

    this.socket = io(`${apiService.apiHost}?room=admin/merchant/raworders`, {
      transports: ['websocket', 'polling'],
    });
    this.socket.on('success', this.returnSuccess);
    this.socket.on('error', this.returnError);
  },
  unmounted() {
    this.disconnectSockets();
  },
  watch: {
    async businessAddress() {
      const additionalRouteInfo = {
        businessAddress: this.businessAddress,
        day: dayjs().format('YYYY-MM-DD'),
      };

      const zone = await timezoneConversionCurrentUserInput(
        this.allBusinessAddresses,
        'admin',
        additionalRouteInfo
      );
      this.timeZoneId = zone.timeZoneId;
    },
  },
  computed: {
    allBusinessAddresses() {
      return [
        ...(this.merchant.businessAddresses || []),
        ...(this.merchant.parentBusinessAddresses || []),
      ];
    },
    previewRoutesDropoffCount() {
      return this.previewResults.routes.reduce((acc, route) => {
        const dropoffCount = route.waypoints
          .filter(p => p.type === 'dropoff')
          .filter(p => !p.bagReturnDropoff).length;
        return acc + dropoffCount;
      }, 0);
    },
    canCreateRoutes() {
      // if (this.previewResults?.unassignedDropoffs?.length) {
      //   return false;
      // }
      // if (this.selectedOrders.length !== this.previewRoutesDropoffCount) {
      //   return false;
      // }
      return true;
    },
    selectedBusinessAddress() {
      return this.allBusinessAddresses.find(a => a.businessAddress === this.businessAddress);
    },
    selectedOrders() {
      return Object.entries(this.ordersStatus)
        .filter(([, value]) => value === 'include')
        .map(([id]) => id);
    },
    routedOrders() {
      return this.orders.filter(order => order.status === 'routed');
    },
  },
  methods: {
    async unroute(id: string) {
      const confirmed = await showConfirmationModal({
        title: 'Confirm Raw Order Un-Route',
        message: `Are you sure you want to un-route this raw order?`,
        confirmButtonText: 'Un-Route Raw Order',
      });
      if (confirmed) {
        await aroundLoadingMessage('Loading ...', async () => {
          await apiService.admin.merchants.unrouteRawOrder(id);
          console.log(this.$refs.loadFormButton);
          this.$refs.loadFormButton.$el.click();
        });
      }
    },
    isToday(date) {
      return dayjs().format('YYYY-MM-DD') === date;
    },
    priorityTime(order) {
      if (order.relativePriorityTime) return order.relativePriorityTime;
      const addressType = order.addressType || '';
      if (addressType.toLowerCase().includes('business')) {
        return 4;
      }
      if (addressType.toLowerCase().includes('funeral')) {
        return 1;
      }
      return '';
    },
    displayTime(timestamp) {
      return dayjs.unix(timestamp).format('MMMM Do, h:mm A');
    },
    includeAllAfter(index) {
      const ids = this.orders
        .slice(index)
        .filter(order => order.status !== 'routed')
        .map(order => order.id);
      for (const id of ids) {
        this.ordersStatus[id] = 'include';
      }
    },
    async loadRawOrders(values) {
      await aroundLoadingMessage('Loading ...', async () => {
        const { orders } = await apiService.admin.merchants.rawOrders(this.merchant.email, values);
        this.orders = orders;
        this.previewResults = {};
        this.ordersStatus = {};
        this.createdRoutes = null;
      });
    },
    getPayloadFromValues(values) {
      return {
        ...values,
        orders: values.orders
          .filter(order => this.selectedOrders.includes(order.id))
          .map(order => {
            return {
              id: order.id,
              relativePriorityTime: order.relativePriorityTime,
            };
          }),
      };
    },
    async previewRoutes(values) {
      if (values.routeNow) {
        return this.generateRoutesBackground(values);
      }
      // eslint-disable-next-line no-param-reassign
      values.endWindow = dayjs
        .tz(`${dayjs().format('YYYY-MM-DD')} ${values.endWindow}`, `${this.timeZoneId}`)
        .tz('America/Toronto')
        .format('HH:mm');

      const payload = this.getPayloadFromValues(values);
      this.createdRoutes = null;
      await aroundLoadingMessage('Loading ...', async () => {
        const results = await apiService.admin.merchants.previewRoutes(
          this.merchant.email,
          payload
        );
        this.previewResults = results;
      });

      return null;
    },
    async generateRoutes(values) {
      // eslint-disable-next-line no-param-reassign
      values.endWindow = dayjs
        .tz(`${dayjs().format('YYYY-MM-DD')} ${values.endWindow}`, `${this.timeZoneId}`)
        .tz('America/Toronto')
        .format('HH:mm');

      const payload = this.getPayloadFromValues(values);
      await aroundLoadingMessage('Loading ...', async () => {
        const { routes } = await apiService.admin.merchants.generateRoutes(
          this.merchant.email,
          payload
        );
        this.orders = null;
        await new Promise(r => setTimeout(r, 10));
        this.$refs.loadFormButton.$el.click();
        this.previewResults = {};
        this.ordersStatus = {};
        this.createdRoutes = routes;
      });
    },
    async generateRoutesBackground(values) {
      // eslint-disable-next-line no-param-reassign
      values.endWindow = dayjs
        .tz(`${dayjs().format('YYYY-MM-DD')} ${values.endWindow}`, `${this.timeZoneId}`)
        .tz('America/Toronto')
        .format('HH:mm');

      const payload = this.getPayloadFromValues(values);
      showLoadingMessage('Generating ...');
      const { someKey } = await apiService.admin.merchants.generateRoutesBackground(
        this.merchant.email,
        payload
      );
      this.someKey = someKey;
    },
    async returnSuccess(info) {
      console.log({ info });
      this.orders = null;
      await new Promise(r => setTimeout(r, 10));
      this.$refs.loadFormButton.$el.click();
      this.previewResults = {};
      this.ordersStatus = {};
      this.createdRoutes = null;
      stopLoadingMessage();
    },
    async returnError(info) {
      console.log({ info });
      await new Promise(r => setTimeout(r, 10));
      stopLoadingMessage();
      await showConfirmationModal({
        title: 'Routing Error',
        message: info,
        confirmButtonText: 'Ok',
      });
    },
    isOutside(address) {
      if (!address) return true;
      if (
        !address.formattedAddress.includes(', Canada')
        // !address.formattedAddress.includes(', ON')
      ) {
        return true;
      }
      if (this.selectedBusinessAddress) {
        // if point is over 300km away from merchant location
        return (
          calculateDistance(
            {
              latitude: this.selectedBusinessAddress.businessLat,
              longitude: this.selectedBusinessAddress.businessLng,
            },
            address
          ) > 300000
        );
      }
      return false;
    },
    async geocode(orderId) {
      await apiService.admin.merchants.rawOrderGeocode(this.merchant.email, orderId);
    },
    setMap(mapRoutes, refName) {
      const map = this.$refs[refName];
      if (!map) return;
      if (mapRoutes.length) {
        const waypoints = mapRoutes
          .map(route => route.waypoints)
          .reduce((list, p) => [...list, ...p], []);
        map.leafletObject.fitBounds(waypoints);
      }
      map.leafletObject.scrollWheelZoom.disable();
    },
    connectSockets() {
      if (!this.socket) return;
      this.socket.connect();
    },
    disconnectSockets() {
      if (!this.socket) return;
      this.socket.disconnect();
    },
  },
});
</script>
