<template>
  <main class="flex-1 overflow-y-auto focus:outline-none" tabindex="0">
    <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
      <div class="pt-10 pb-16">
        <div class="px-4 sm:px-6 md:px-0 mb-8">
          <GoHeader :level="1">Label Printer</GoHeader>
        </div>
        <div class="flex flex-col space-y-8">
          <GoAlert
            v-if="printerStatus === 'searching'"
            color="information"
            class="border border-gray-300"
          >
            Searching for printer...
          </GoAlert>
          <GoAlert
            v-else-if="printerStatus === 'found' && printer"
            color="success"
            class="border border-gray-300"
          >
            Printer Found: {{ printer.name }}
          </GoAlert>
          <GoAlert
            v-else-if="printerStatus !== 'searching'"
            color="danger"
            class="border border-gray-300"
          >
            Error finding printer: {{ printerStatus }}
          </GoAlert>
          <div class="flex space-x-8">
            <GoForm class="space-y-4 mb-4" @submit="searchOnly">
              <GoTextField name="orderId" label="Search And Receive Only" autocomplete="off" />
              <GoButton class="w-full">Receive</GoButton>
            </GoForm>
            <GoForm class="space-y-4 mb-4" @submit="searchAndPrint">
              <GoTextField
                :focus="true"
                name="orderId"
                label="Search and Print"
                autocomplete="off"
              />
              <GoButton class="w-full" :disabled="!printer">Print</GoButton>
            </GoForm>
          </div>
          <div class="">
            <GoAlert color="danger" class="border border-gray-500" v-if="error">
              <div class="text-xl">Order '{{ error }}' not found.</div>
            </GoAlert>
            <div v-else-if="matchedWaypoint && route">
              <GoHeader v-if="!sameOrderScanned" :level="3" class="mb-4">Order Found</GoHeader>
              <div v-else class="flex items-center space-x-3 mb-4">
                <GoHeader :level="3">Same Label Scanned Again</GoHeader>
                <GoButton
                  type="button"
                  class="text-xs"
                  variant="outline"
                  @click="
                    lastSearchString = null;
                    sameOrderScanned = null;
                    match = null;
                    error = null;
                  "
                >
                  Clear
                </GoButton>
              </div>
              <OrderCard
                :route="route"
                :key="matchedWaypoint.id"
                :waypoint="matchedWaypoint"
                :routeId="matchedWaypoint.routeId"
                :forSearch="true"
              />
            </div>
          </div>
          <div
            class="flex flex-row-reverse lg:flex-row justify-end items-center text-xs lg:space-x-2"
          >
            <div class="space-x-4 flex items-center">
              <IconLoader class="animate-spin mx-2 h-5 w-5 text-primary-800" v-show="loading" />
              <GoButton
                as="router-link"
                variant="link"
                :to="{ query: { ...$route.query, day: changeDay(-1) } }"
                class="text-blue-800"
              >
                Previous Day
              </GoButton>
              <div class="text-xl">{{ currentDayOfWeek }} {{ currentDay }}</div>
              <GoButton
                variant="link"
                as="router-link"
                :to="{ query: { ...$route.query, day: changeDay(1) } }"
                class="text-blue-800"
              >
                Next Day
              </GoButton>
            </div>
          </div>
          <div class="bg-white shadow overflow-hidden sm:rounded-md">
            <ul role="list" class="divide-y divide-gray-200">
              <li
                v-for="[merchantId, dropoffs] of Object.entries(dropoffsByMerchant)"
                :key="merchantId"
              >
                <div class="px-4 py-4 sm:px-6">
                  <div class="flex items-center justify-between">
                    <p class="text-lg font-medium text-gray-600 truncate">
                      {{ dropoffs[0].associatedBusiness }}
                    </p>
                  </div>
                  <div class="mt-2 sm:flex sm:justify-between">
                    <div class="sm:flex justify-between w-full">
                      <p class="flex items-center text-sm text-gray-500">
                        {{ totalQuantity(dropoffs) }} boxes
                      </p>
                      <p class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                        <button
                          @click="showRecieved[merchantId] = !showRecieved[merchantId]"
                          class="text-indigo-600"
                        >
                          {{ totalScanned(dropoffs) }} recieved
                        </button>
                      </p>
                      <p class="mt-2 flex items-center text-sm sm:mt-0 sm:ml-6">
                        <button
                          @click="showUnrecieved[merchantId] = !showUnrecieved[merchantId]"
                          class="text-indigo-600"
                        >
                          {{ totalQuantity(dropoffs) - totalScanned(dropoffs) }} unrecieved
                        </button>
                      </p>
                    </div>
                  </div>
                  <div v-if="showUnrecieved[merchantId]" class="items-center mt-3">
                    <p class="text-base font-medium mb-2">
                      {{ totalQuantity(dropoffs) - totalScanned(dropoffs) }} unrecieved
                    </p>
                    <template v-for="dropoff of unrecievedDropoffs(merchantId)" :key="dropoff.id">
                      <template v-for="index in dropoff.quantity" :key="index">
                        <p v-if="!dropoff.scannedForRelabel.includes(index.toString())">
                          <span class="text-base font-bold mr-4">{{ dropoff.id }}</span>
                          <span class="text-sm font-bold">
                            Box {{ index }} of {{ dropoff.quantity }}
                          </span>
                        </p>
                      </template>
                    </template>
                  </div>
                  <div v-if="showRecieved[merchantId]" class="items-center mt-3">
                    <p class="text-base font-medium mb-2">{{ totalScanned(dropoffs) }} recieved</p>
                    <template v-for="dropoff of recievedDropoffs(merchantId)" :key="dropoff.id">
                      <template v-for="index in dropoff.quantity" :key="index">
                        <p v-if="dropoff.scannedForRelabel.includes(index.toString())">
                          <span class="text-base font-bold mr-4">{{ dropoff.id }}</span>
                          <span class="text-sm font-bold">
                            Box {{ index }} of {{ dropoff.quantity }}
                          </span>
                        </p>
                      </template>
                    </template>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <template v-if="routes?.length">
            <GoHeader :level="2">Routes</GoHeader>
            <div class="bg-white shadow overflow-hidden sm:rounded-md">
              <ul role="list" class="divide-y divide-gray-200">
                <li
                  v-for="[routingGroup, routeNumbers] of Object.entries(routesByRoutingGroup)"
                  :key="routingGroup"
                >
                  <div class="px-4 py-4 sm:px-6">
                    <div class="flex items-center justify-between">
                      <p class="text-lg font-medium text-gray-600 truncate">
                        {{ routingGroup }}
                        <span class="ml-4 text-sm">({{ routeNumbers.length }} routes)</span>
                      </p>
                    </div>
                    <div class="mt-2 sm:flex sm:justify-between">
                      <div class="w-full">
                        <p
                          v-for="routeNumber of routeNumbers"
                          :key="routeNumber"
                          class="flex items-center text-base text-gray-700"
                        >
                          #{{ routeNumber }}
                        </p>
                      </div>
                    </div>
                    <div class="mt-2 sm:flex sm:justify-between">
                      <div class="sm:flex justify-between w-full">
                        <p class="flex items-center text-sm text-gray-500">
                          {{ totalQuantity(dropoffsByRoutingGroup[routingGroup]) }} boxes
                        </p>
                        <p class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                          <button
                            @click="showRecieved[routingGroup] = !showRecieved[routingGroup]"
                            class="text-indigo-600"
                          >
                            {{ totalScanned(dropoffsByRoutingGroup[routingGroup]) }} recieved
                          </button>
                        </p>
                        <p class="mt-2 flex items-center text-sm sm:mt-0 sm:ml-6">
                          <button
                            @click="showUnrecieved[routingGroup] = !showUnrecieved[routingGroup]"
                            class="text-indigo-600"
                          >
                            {{
                              totalQuantity(dropoffsByRoutingGroup[routingGroup]) -
                              totalScanned(dropoffsByRoutingGroup[routingGroup])
                            }}
                            unrecieved
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="showUnrecieved[routingGroup]"
                    class="items-center mt-3 px-4 py-4 sm:px-6"
                  >
                    <p class="text-base font-medium mb-2">
                      {{
                        totalQuantity(dropoffsByRoutingGroup[routingGroup]) -
                        totalScanned(dropoffsByRoutingGroup[routingGroup])
                      }}
                      unrecieved
                    </p>
                    <template
                      v-for="dropoff of unrecievedDropoffsByRoutingGroup(routingGroup)"
                      :key="dropoff.id"
                    >
                      <template v-for="index in dropoff.quantity" :key="index">
                        <p v-if="!dropoff.scannedForRelabel.includes(index.toString())">
                          <span class="text-base font-bold mr-4">{{ dropoff.id }}</span>
                          <span class="text-sm font-bold">
                            Box {{ index }} of {{ dropoff.quantity }}
                          </span>
                        </p>
                      </template>
                    </template>
                  </div>
                  <div
                    v-if="showRecieved[routingGroup]"
                    class="items-center mt-3 px-4 py-4 sm:px-6"
                  >
                    <p class="text-base font-medium mb-2">
                      {{ totalScanned(dropoffsByRoutingGroup[routingGroup]) }}
                      recieved
                    </p>
                    <template
                      v-for="dropoff of recievedDropoffsByRoutingGroup(routingGroup)"
                      :key="dropoff.id"
                    >
                      <template v-for="index in dropoff.quantity" :key="index">
                        <p v-if="dropoff.scannedForRelabel.includes(index.toString())">
                          <span class="text-base font-bold mr-4">{{ dropoff.id }}</span>
                          <span class="text-sm font-bold">
                            Box {{ index }} of {{ dropoff.quantity }}
                          </span>
                        </p>
                      </template>
                    </template>
                  </div>
                </li>
              </ul>
            </div>
          </template>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { apiService, waypointZpl } from '@tyltgo/shared';
import dayjs from 'dayjs';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { currentUser } from '../../services/auth-service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let BrowserPrint: any;

export default defineComponent({
  setup() {
    return {
      currentUser,
    };
  },
  data() {
    return {
      lastSearchString: null,
      sameOrderScanned: null,
      matchedWaypoint: null,
      waypointMerchant: null,
      itemNumber: null,
      route: null,
      error: false,
      printer: null,
      printerStatus: 'searching',
      loading: false,
      routes: null,
      currentDay: null,
      showUnrecieved: {},
      showRecieved: {},
    };
  },
  async mounted() {
    const { day } = this.$route.query;
    this.currentDay = day ?? dayjs().add(1, 'day').format('YYYY-MM-DD');
    try {
      const printer = await this.findZebraPrinter();
      this.printer = printer;
      this.printerStatus = 'found';
    } catch (e) {
      this.printerStatus = e.message;
      throw e;
    }
  },
  watch: {
    $route(to, _from) {
      if (to.name === 'merchant.labelPrinter') {
        this.currentDay = to.query.day ?? dayjs().add(1, 'day').format('YYYY-MM-DD');
      }
    },
    currentDay() {
      this.getRoutes();
    },
  },
  computed: {
    currentDayOfWeek() {
      if (!this.currentDay) return '';
      return dayjs(this.currentDay).format('dddd');
    },
    dropoffsByMerchant() {
      if (!this.routes) return {};
      const dropoffs = flatten(
        this.routes.map(r =>
          r.waypoints
            .filter(p => p.type === 'dropoff')
            .filter(p => !['cancelled'].includes(p.subState))
        )
      );
      return groupBy(dropoffs, 'associatedEmail');
    },
    dropoffsByRoutingGroup() {
      if (!this.routes) return {};
      const dropoffs = flatten(
        this.routes.map(r =>
          r.waypoints
            .filter(p => p.type === 'dropoff')
            .filter(p => !['cancelled'].includes(p.subState))
            .map(p => ({ ...p, routingGroup: r.routingGroup }))
        )
      );
      return groupBy(dropoffs, 'routingGroup');
    },
    routesByRoutingGroup() {
      if (!this.routes) return {};
      return this.routes.reduce((acc, route) => {
        const waypoints = route.waypoints.filter(p => p.type === 'pickup');

        const routingGroup = route.routingGroup || 'no group';
        return {
          ...acc,
          [routingGroup]: sortBy(
            [...new Set([...(acc[routingGroup] || []), ...waypoints.map(p => p.routeNumber)])],
            r => Number(r)
          ),
        };
      }, {});
    },
  },
  methods: {
    totalQuantity(dropoffs) {
      return dropoffs.reduce((acc, dropoff) => acc + dropoff.quantity, 0);
    },
    totalScanned(dropoffs) {
      return dropoffs.reduce((acc, dropoff) => acc + dropoff.scannedForRelabel.length, 0);
    },
    unrecievedDropoffs(merchantId) {
      const dropoffs = this.dropoffsByMerchant[merchantId] || [];
      return dropoffs.filter(d => d.scannedForRelabel.length < d.quantity);
    },
    recievedDropoffs(merchantId) {
      const dropoffs = this.dropoffsByMerchant[merchantId] || [];
      return dropoffs.filter(d => d.scannedForRelabel.length);
    },
    unrecievedDropoffsByRoutingGroup(routingGroup) {
      const dropoffs = this.dropoffsByRoutingGroup[routingGroup] || [];
      return dropoffs.filter(d => d.scannedForRelabel.length < d.quantity);
    },
    recievedDropoffsByRoutingGroup(routingGroup) {
      const dropoffs = this.dropoffsByRoutingGroup[routingGroup] || [];
      return dropoffs.filter(d => d.scannedForRelabel.length);
    },
    async getRoutes() {
      this.loading = true;
      this.routes = await apiService.merchants.batches.all({ day: this.currentDay || '' });
      this.loading = false;
    },
    changeDay(diff: number) {
      return dayjs(this.currentDay).add(diff, 'days').format('YYYY-MM-DD');
    },
    async getLocalDevices() {
      return new Promise((resolve, reject) => {
        BrowserPrint.getLocalDevices(resolve, reject);
      });
    },
    async findZebraPrinter() {
      const devices = await this.getLocalDevices();
      console.log('DEVICES', devices);
      if (!devices.printer) {
        throw new Error('No Printers Connected');
      }
      return devices.printer.find(d => d.manufacturer.includes('Zebra'));
    },
    async findOrder(values) {
      if (this.lastSearchString === values.orderId) {
        this.sameOrderScanned = true;
        return null;
      }

      const [orderId, itemNumber] = values.orderId.split(/\s+/);

      this.sameOrderScanned = null;
      this.error = false;
      this.matchedWaypoint = null;
      this.waypointMerchant = null;
      this.route = null;
      try {
        const results = await apiService.merchants.batches.searchForRelabel(orderId, itemNumber);
        this.matchedWaypoint = results.matchedWaypoint;

        if (!this.matchedWaypoint) {
          throw new Error('order not found');
        }

        this.lastSearchString = values.orderId;
        this.itemNumber = itemNumber;

        this.route = results.route;
        this.waypointMerchant = results.merchant;

        return this.matchedWaypoint;
      } catch (e) {
        this.error = orderId;
        this.matchedWaypoint = null;
        this.waypointMerchant = null;
      }
      return null;
    },

    async searchOnly(values, { resetForm }) {
      await this.findOrder(values);
      resetForm();
    },
    async writeToPrinter(label: string) {
      if (!this.printer) {
        throw new Error('Cannot print without printer');
      }
      return new Promise((resolve, reject) => {
        this.printer.send(label, resolve, reject);
      });
    },
    async searchAndPrint(values, { resetForm }) {
      const waypoint = await this.findOrder(values);
      if (waypoint && this.route) {
        const routeWaypoint = this.route.waypoints.find(p => p.id === waypoint.id);
        console.log({ route: this.route, waypoint, routeWaypoint });
        const itemNumber = Number(this.itemNumber || 1);
        const labelString = waypointZpl({
          route: this.route,
          waypoint: routeWaypoint,
          merchant: this.waypointMerchant || this.currentUser,
          toChangeTimezone: this.route.timeZoneId,
        });
        const labels = labelString
          .split('\n^XA')
          .filter(x => !!x)
          .map(s => `\n^XA${s}`);
        const labelToPrint = labels[itemNumber - 1];
        console.log(labelToPrint);
        await this.writeToPrinter(labelToPrint);
      }
      resetForm();
    },
  },
});
</script>
