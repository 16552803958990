<template>
  <div>
    <div class="flex flex-row justify-between items-center">
      <div
        class="pb-4"
        v-if="
          merchant.settings.additionalQuantityPrice && !merchant.settings.removePricelistDisplay
        "
      >
        Additional Quantity Price: ${{ merchant.settings.additionalQuantityPrice.toFixed(2) }}
      </div>
      <GoButton variant="link" class="text-xs" @click="downloadCsv">Download Pricing CSV</GoButton>
    </div>
    <div v-if="merchant.priceList">
      <GoTable :tableData="tableData"></GoTable>
    </div>
    <div class="pt-2" v-if="!merchant.priceList">Dynamic Service Area.</div>
  </div>
</template>
<script lang="ts">
import { apiService } from '@tyltgo/shared';
import { sortBy } from 'lodash';
import dayjs from 'dayjs';
import { defineComponent } from 'vue';
import { unparse } from 'papaparse';
import GoTable from '../../components/GoTable.vue';
import { downloadBlob } from '../../services/browser-service';
import { currentUser } from '../../services/auth-service';

export default defineComponent({
  setup() {
    return {
      currentUser,
    };
  },
  components: {
    GoTable,
  },
  data() {
    return {
      tableData: {
        headers: [],
        data: [],
      },
    };
  },
  async created() {
    this.tableData.data = sortBy(
      (await apiService.merchants.pricing.all()).detailedList,
      'principality'
    );

    const principality = this.tableData.data.map(element => element.principality).some(Boolean);
    const price = this.tableData.data.map(element => element.price).some(Boolean);
    const city = this.tableData.data.map(element => element.city).some(Boolean);

    if (principality) {
      this.tableData.headers.push({ title: 'Principality', key: 'principality' });
    }
    if (price) {
      this.tableData.headers.push({ title: 'Price', key: 'price' });
    }
    if (city) {
      this.tableData.headers.push({ title: 'City', key: 'city' });
    }
  },

  props: {
    merchant: {
      type: Object,
      required: true,
    },
  },

  methods: {
    async downloadCsv() {
      const blob = new Blob([unparse(this.tableData.data)], { type: 'text/csv' });
      downloadBlob(blob, `pricing-${dayjs().format()}-${this.currentUser.id}.csv`);
    },
  },
});
</script>
