<template>
  <div v-if="routes">
    <p v-if="!routes.length" class="p-4">No Offers.</p>
    <p v-if="routes.length" class="py-4 text-lg font-bold">Offers</p>
    <RouteCard v-for="route in routes" :key="route.id" :route="route" />
  </div>
</template>

<script lang="ts">
import { apiService } from '@tyltgo/shared';
import { defineComponent } from 'vue';
import { aroundLoadingMessage } from '../services/ui-message-service';
import RouteCard from './RouteCard.vue';

export default defineComponent({
  components: {
    RouteCard,
  },
  props: {
    courier: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      routes: null,
    };
  },
  async mounted() {
    await aroundLoadingMessage('Sending...', async () => {
      const results = await apiService.admin.couriers.offers(this.courier.email);
      this.routes = results.routes || [];
    });
  },
});
</script>
