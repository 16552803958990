<template>
  <div class="h-screen flex">
    <!-- Content area -->
    <div v-if="shopifySession?.token" class="relative flex-1 flex flex-col">
      <main class="flex-1 focus:outline-none" tabindex="0">
        <nav class="bg-white shadow">
          <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="flex justify-between h-16">
              <div class="flex">
                <div class="flex-shrink-0 flex items-center">
                  <router-link
                    :to="{ name: routeName('home'), params: { storeName: shopifySession.shop } }"
                  >
                    <h1 class="text-3xl font-logo text-gray-800">Tyltgo</h1>
                  </router-link>
                </div>
                <div class="md:ml-6 md:flex md:space-x-8">
                  <router-link
                    :to="{
                      name: routeName('pendingOrders'),
                      params: { storeName: shopifySession.shop },
                    }"
                    class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                  >
                    Pending Orders
                  </router-link>
                  <div class="inline-flex items-center flex-shrink-0">
                    <GoButton
                      as="router-link"
                      :to="{
                        name: routeName('addOrders'),
                        params: { storeName: shopifySession.shop },
                      }"
                      class="text-sm inline-flex justify-between items-center text-white"
                    >
                      <svg
                        class="-ml-1 mr-2 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <span class="text-white">Add Orders</span>
                    </GoButton>
                  </div>
                </div>
              </div>
              <div class="flex items-center"></div>
            </div>
          </div>
        </nav>
        <router-view />
      </main>
    </div>

    <GoMessages />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { apiService, authService } from '@tyltgo/shared';
import { createApp } from '@shopify/app-bridge';
import { getSessionToken } from '@shopify/app-bridge-utils';
import { currentUser, shopifySession, loadCurrentUser } from '../services/auth-service';
import GoMessages from '../components/GoMessages.vue';

export default defineComponent({
  setup() {
    return {
      currentUser,
      shopifySession,
    };
  },
  components: {
    GoMessages,
  },
  data() {
    return {
      session: null,
    };
  },
  watch: {
    $route(_to, _from) {
      // this is to remove the search panel when the route changes.
      this.searchText = '';
    },
  },
  async beforeMount() {
    const { session, host, shop } = this.$route.query;
    if (session) {
      const { apiKey } = await apiService.merchants.shopify.apiKey(shop);
      this.session = session;
      const app = createApp({
        apiKey,
        host,
      });
      const sessionToken = await getSessionToken(app);
      shopifySession.value.token = sessionToken;
      shopifySession.value.shop = shop;
      const { integration, token } = await apiService.merchants.shopify.integration(
        currentUser.value?.email,
        shop,
        shopifySession.value.token
      );
      shopifySession.value.integration = integration;
      if (token) {
        await authService.loginSuccess(token);
        await loadCurrentUser('merchant');
      }
    } else {
      console.log('no session!');
      const { redirectUrl } = await apiService.merchants.shopify.install(
        this.$route.query,
        currentUser.value?.email
      );
      window.location = redirectUrl;
    }
  },
  methods: {
    async logout() {
      await authService.logout();
      this.$router.push({ name: 'merchant.login' });
    },
    routeName(name) {
      const parts = this.$route.name.split('.');
      parts.splice(-1, 1);
      parts.push(name);
      return parts.join('.');
    },
  },
});
</script>

<style lang="scss" scoped>
.nav-link {
  @apply border-transparent text-gray-200 border-l-4 py-2 px-3 flex items-center text-sm font-medium group;

  &:hover {
    @apply text-gray-900 bg-gray-50;
  }

  &.router-link-active {
    @apply bg-primary-50 border-primary-600 text-primary-800;
    svg {
      @apply text-primary-700;
    }
  }

  svg {
    @apply text-gray-300 h-6 w-6 mr-3;
  }
}
</style>
