<template>
  <div class="py-4 bg-white align-middle min-w-full sm:px-6 lg:px-8 print:px-4 merchant-invoice">
    <div class="flex justify-between py-4 mb-8 border-b-2 border-gray-300">
      <div class="col-span-1">
        <h1 class="text-5xl font-logo text-gray-900">Tyltgo</h1>
      </div>
      <div class="col-span-1 text-right">
        <div>Tylt Technologies Inc.</div>
        <div>30 Duke Street West</div>
        <div>Waterloo, ON N2H 3W5</div>
        <div>HST Account Number: 732921275</div>
      </div>
    </div>
    <div class="flex justify-between py-4 mb-8">
      <div class="col-span-1">
        <div class="text-lg text-gray-600">{{ invoiceDate }} Invoice</div>
        <div class="text-xl text-gray-800 mb-3">
          <span class="font-bold">{{ invoice.id }}</span>
        </div>
        <div class="text-lg text-gray-600 mb-3">{{ invoice.billingPeriod }}</div>
        <div class="text-2xl">{{ formatCurrency(invoice.priceWithTax) }}</div>
      </div>
      <div class="col-span-1 text-right">
        <div class="font-bold">{{ merchant.businessName }}</div>
        <div class="font-bold">{{ merchant.firstName }} {{ merchant.lastName }}</div>
        <div>{{ merchant.invoiceAddress.address1 }}</div>
        <div>{{ merchant.invoiceAddress.city }}, {{ merchant.invoiceAddress.state }}</div>
      </div>
    </div>
    <GoTable
      class="mt-4 mb-12 overflow-x-auto"
      :tableData="{
        headers: [
          { title: 'Batch ID', key: 'orderId' },
          { title: 'Date', key: 'day', wrap: false },
          { title: 'Drop Offs', key: 'dropOffs', right: true },
          {
            title: 'Fuel Surcharge',
            valueFunction: row => getFuelSurcharge(row),
            right: true,
          },
          {
            title: 'Extra Costs',
            valueFunction: row =>
              row.adjustments
                .filter(a => !a.isInternal)
                .map(a => `${formatCurrency(a.amount)} ${a.reason}`)
                .join('\n') || '',
            pre: true,
          },
          {
            title: 'Price',
            valueFunction: row => formatCurrency(row.price),
            right: true,
          },
        ],
        data: tableDataCompiled,
      }"
    ></GoTable>

    <GoTable
      v-if="invoice.debits?.length"
      class="mt-4 mb-12 overflow-x-auto"
      :tableData="{
        headers: [
          { title: 'Additional Charge', key: 'reason' },
          { title: 'Date', key: 'day', valueFunction: row => dateFromUnix(row.created) },
          { title: 'Description', key: 'description' },
          {
            title: 'Price',
            valueFunction: row => formatCurrency(row.amount),
            right: true,
          },
        ],
        data: sortedDebits(invoice),
      }"
    ></GoTable>

    <GoTable
      v-if="invoice.credits?.length"
      class="mt-4 mb-12 overflow-x-auto"
      :tableData="{
        headers: [
          { title: 'Reason', key: 'reason' },
          { title: 'Province', key: 'province' },
          { title: 'Order ID (Optional)', key: 'orderId' },

          {
            title: 'Credit Amount',
            valueFunction: row => formatCurrency(row.amount),
            right: true,
          },
        ],
        data: invoice.credits || [],
      }"
    ></GoTable>

    <div class="page-break"></div>
    <div class="page-break">
      <table class="ml-auto border-separate text-right">
        <tbody class="bg-white">
          <tr>
            <td class="px-6 py-1 whitespace-nowrap text-sm font-medium text-gray-900">Credits</td>
            <td class="px-6 py-1 whitespace-nowrap text-sm text-gray-500">
              ({{ formatCurrency(invoice.creditAmount) }})
            </td>
          </tr>
          <tr class="page-break"></tr>
          <tr v-if="totalDropoffCount">
            <td class="px-6 py-1 whitespace-nowrap text-sm font-medium text-gray-900">
              Average Price / Drop Off
            </td>
            <td class="px-6 py-1 whitespace-nowrap text-sm text-gray-500">
              {{ formatCurrency(pricePerDropoff) }}
            </td>
          </tr>
          <template v-if="invoice.processingFee">
            <tr class="page-break"></tr>
            <tr>
              <td class="px-6 py-1 whitespace-nowrap text-sm font-medium text-gray-900">
                Processing Fee
              </td>
              <td class="px-6 py-1 whitespace-nowrap text-sm text-gray-500">
                {{ formatCurrency(invoice.processingFee) }}
              </td>
            </tr>
          </template>
          <tr class="page-break"></tr>
          <tr>
            <td class="px-6 py-1 whitespace-nowrap text-sm font-medium text-gray-900">Subtotal</td>
            <td class="px-6 py-1 whitespace-nowrap text-sm text-gray-500">
              {{ formatCurrency(invoice.price) }}
            </td>
          </tr>
          <tr class="page-break"></tr>
          <tr>
            <td class="px-6 py-1 whitespace-nowrap text-sm font-medium text-gray-900">Tax</td>
            <td class="px-6 py-1 whitespace-nowrap text-sm text-gray-500">
              {{ formatCurrency(invoice.priceWithTax - invoice.price) }}
            </td>
          </tr>
          <tr class="page-break"></tr>
          <tr>
            <td class="px-6 py-1 whitespace-nowrap text-sm font-medium text-gray-900">
              Total (Tax Included)
            </td>
            <td class="px-6 py-1 whitespace-nowrap text-sm text-gray-500">
              {{ formatCurrency(invoice.priceWithTax + (invoice.processingFee || 0)) }}
            </td>
          </tr>
          <tr class="page-break"></tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import dayjs from 'dayjs';
import sortBy from 'lodash/sortBy';
import GoTable from './GoTable.vue';

export default defineComponent({
  components: {
    GoTable,
  },
  props: {
    invoice: {
      type: Object,
      required: true,
    },
    merchant: {
      type: Object,
      required: true,
    },
  },
  computed: {
    invoiceDate() {
      return dayjs.unix(this.invoice.created).format('MMMM Do, YYYY');
    },
    pricePerDropoff() {
      if (!this.invoice.orders.length) return 0;
      return this.invoice.price / this.totalDropoffCount;
    },
    totalDropoffCount() {
      return this.invoice.orders.reduce((acc, order) => acc + order.dropOffs, 0);
    },
    totalPrice() {
      return this.invoice.orders.reduce((acc, order) => acc + order.price, 0);
    },
    tableDataCompiled() {
      const newTotal = {
        orderId: 'Total',
        dropOffs: this.formatNumber(this.totalDropoffCount),
        day: '',
        adjustments: [],
        price: this.totalPrice,
      };
      return [...this.invoice.orders, newTotal];
    },
  },
  methods: {
    dateFromUnix(date: number) {
      return dayjs.unix(date).format('YYYY-MM-DD');
    },
    formatCurrency(amount: number) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });

      return formatter.format(amount);
    },
    formatNumber(number: number) {
      return number.toLocaleString('en-US');
    },
    getFuelSurcharge(row) {
      const detail = row.priceDetails?.details?.find(d => d.category === 'fuelSurcharge');
      if (!detail) return undefined;

      const fuelSurcharge = detail.custom?.fuelSurcharge;
      if (!fuelSurcharge) return undefined;

      return `${fuelSurcharge}%`;
    },
    sortedDebits(invoice) {
      return sortBy(invoice.debits || [], item => this.dateFromUnix(item.created));
    },
  },
});
</script>
