<template>
  <main class="flex-1 overflow-y-auto focus:outline-none" tabindex="0">
    <nav class="bg-white shadow">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between h-16">
          <div class="flex">
            <div class="flex-shrink-0 flex items-center">
              <router-link :to="{ name: routeName('home') }">
                <h1 class="text-3xl font-logo text-gray-800">Tyltgo</h1>
              </router-link>
            </div>
            <div class="md:ml-6 md:flex md:space-x-8">
              <router-link
                :to="{ name: 'merchant.shopifyIntegration.pendingOrders' }"
                class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
              >
                Pending Orders
              </router-link>
              <div class="inline-flex items-center flex-shrink-0">
                <GoButton
                  as="router-link"
                  :to="{ name: routeName('addOrders') }"
                  class="text-sm inline-flex justify-between items-center text-white"
                >
                  <svg
                    class="-ml-1 mr-2 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span class="text-white">Add Orders</span>
                </GoButton>
              </div>
            </div>
          </div>
          <div class="flex items-center"></div>
        </div>
      </div>
    </nav>

    <router-view />
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { currentUser } from '../../services/auth-service';

export default defineComponent({
  setup(props) {
    const route = useRoute();
    const storeName = props.storeName || route.params.storeName;
    const store = currentUser.value.shopifyStores.find(s => s.credentials.shopName === storeName);
    return {
      store,
      currentUser,
    };
  },
  props: {
    storeName: {
      type: String,
      default: '',
    },
  },
  methods: {
    routeName(name) {
      const parts = this.$route.name.split('.');
      parts.splice(-1, 1);
      parts.push(name);
      return parts.join('.');
    },
  },
});
</script>
