<template>
  <div>
    <div v-if="waypoints">
      <GoButton variant="link" class="text-xs py-5" @click="downloadCsv">Download CSV</GoButton>
      <div class="py-4">
        <OrderCard
          v-for="waypoint in waypoints"
          :route="getRoute(waypoint.routeId)"
          :key="waypoint.id"
          :waypoint="waypoint"
          :routeId="waypoint.routeId"
          :ref="waypoint.id"
          :forSearch="true"
        />
        <div v-if="!waypoints.length">No orders estimated past cutoff time.</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {
  addressDetails,
  apiService,
  Route,
  Waypoint,
  waypointsWithAlerts,
  waypointsWithDeliveryCutoffs,
} from '@tyltgo/shared';
import dayjs from 'dayjs';
import OrderCard from './OrderCard.vue';
import GoButton from './GoButton.vue';
import { currentUser } from '../services/auth-service';
import { downloadBlob } from '../services/browser-service';
import { showLoadingMessage, stopLoadingMessage } from '../services/ui-message-service';

export default defineComponent({
  components: {
    OrderCard,
    GoButton,
  },
  data() {
    return {
      waypoints: null,
      fullRoutes: null,
    };
  },
  async mounted() {
    showLoadingMessage('Loading ...');
    await this.updateRoutes();
    console.log(this.fullRoutes);
    stopLoadingMessage();
  },
  methods: {
    getRoute(id) {
      const route = this.fullRoutes.filter(element => element.id === id);

      return route[0];
    },
    async updateRoutes() {
      const { day } = this.$route.query;
      const routes = await apiService.merchants.batches.all({ day });
      this.fullRoutes = routes;

      const allWaypoints: Waypoint[] = (routes as Route[]).reduce((points, route) => {
        const pointsWith = waypointsWithAlerts(
          waypointsWithDeliveryCutoffs(route.waypoints, [currentUser.value]),
          { estimate: false }
        );
        return [...points, ...pointsWith];
      }, []);

      this.waypoints = allWaypoints.filter(point =>
        point.alerts?.find(alert => alert.type === 'estimatedPastCutoff')
      );
    },
    downloadCsv() {
      const header = `Date,Batch Id,Order Id,Name,Phone Number,ETA,Street,City,Province,Postal Code\n`;
      const lines = this.waypoints
        .map(point => {
          const address = addressDetails(point.address);
          const eta = dayjs(point.estimatedArrivalTime).format('h:mm A');
          const line = [
            point.routeDate,
            point.routeId,
            point.id,
            point.name,
            point.phoneNumber,
            eta,
            address.street,
            address.city,
            address.province,
            address.postalCode,
          ]
            .map(item => (item === null || item === undefined ? '' : item))
            .map(item => `"${item.replace(/"/g, '')}"`)
            .join(',');
          return line;
        })
        .join('\n');
      const csv = header + lines;
      const blob = new Blob([csv], { type: 'text/csv' });
      downloadBlob(blob, `batches-${new Date().toISOString()}.csv`);
    },
  },
});
</script>
