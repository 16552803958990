<template>
  <div
    class="bg-white dark:bg-gray-800 p-4 shadow rounded-sm mb-4 flex border-l-4 flex-col"
    :class="stateBorder"
    :id="waypoint.id"
  >
    <div class="flex flex-col sm:flex-row justify-between">
      <div class="flex flex-col w-full sm:w-3/5 mb-1">
        <div class="flex items-center">
          <router-link
            :to="`/merchant/batches/${waypoint.routeId}#${waypoint.id}`"
            class="uppercase font-header text-xs text-gray-500"
          >
            {{ waypoint.type }}
          </router-link>
          <span
            class="font-header font-bold text-xs text-gray-500 dark:text-gray-400 ml-2"
            v-if="waypoint.type === 'dropoff'"
          >
            {{ waypoint.id }}
          </span>
        </div>
        <div class="flex flex-col">
          <span>
            {{ waypoint.name }}
          </span>
          <span class="text-sm text-gray-500 dark:text-gray-400">
            {{ waypoint.address }}
          </span>
          <span class="text-sm text-gray-500 dark:text-gray-400" v-if="waypoint.phoneNumber">
            {{ waypoint.phoneNumber }}
          </span>
          <span class="text-sm text-gray-500 dark:text-gray-400" v-if="waypoint.email">
            {{ waypoint.email }}
          </span>
          <div
            class="flex space-x-2 items-start"
            v-if="waypoint.quantity && !waypoint.bagReturnDropoff"
          >
            <GoTooltip text="Quantity" class="flex space-x-2">
              <IconBoxOpen class="w-4 text-gray-600 dark:text-gray-300" />
              <div class="text-sm font-body align-middle">{{ waypoint.quantity }}</div>
            </GoTooltip>
          </div>
          <GoTooltip text="Order Note" class="flex space-x-2 items-start" v-if="waypoint.note">
            <IconClipboard class="flex-none w-3 mt-px text-gray-600 dark:text-gray-300" />
            <p class="text-sm font-body align-middle">{{ waypoint.note }}</p>
          </GoTooltip>
        </div>
      </div>
      <div class="flex flex-col w-full sm:w-2/5">
        <div class="flex sm:justify-end mb-2 space-x-2 items-center">
          <GoBadge v-if="waypoint.unpicked" class="bg-pink-200 text-pink-700">
            <span>Unpicked</span>
          </GoBadge>
          <div v-if="verifyStreetImage">
            <GoButton variant="badge" @click="showVerifyStreetImageModal = true">
              <span class="flex space-x-2 items-center">
                <IconCamera class="w-3" />
                <span>Street</span>
              </span>
            </GoButton>
            <GoModal v-if="showVerifyStreetImageModal" @close="showVerifyStreetImageModal = false">
              <div class="flex justify-center">
                <img :src="verifyStreetImage" />
              </div>
            </GoModal>
          </div>

          <div v-if="verifyUnitImage">
            <GoButton variant="badge" @click="showVerifyUnitImageModal = true">
              <span class="flex space-x-2 items-center">
                <IconCamera class="w-3" />
                <span>Unit</span>
              </span>
            </GoButton>
            <GoModal v-if="showVerifyUnitImageModal" @close="showVerifyUnitImageModal = false">
              <div class="flex justify-center">
                <img :src="verifyUnitImage" />
              </div>
            </GoModal>
          </div>
          <GoButton v-if="image" variant="badge" @click="showImageModal = true">
            <span class="flex space-x-2 items-center">
              <IconFileImage class="w-3" />
              <span>Photo</span>
            </span>
          </GoButton>
          <GoModal v-if="showImageModal" @close="showImageModal = false">
            <div class="flex justify-center">
              <img :src="image" />
            </div>
          </GoModal>
          <GoTooltip v-if="waypoint.noCharge" :text="`You will not be charged for this dropoff`">
            <GoBadge class="border border-gray-400">
              <span>Not Charged</span>
            </GoBadge>
          </GoTooltip>
          <GoBadge :class="stateStyle">
            <span>{{ waypointDisplayState(waypoint) }}</span>
          </GoBadge>
          <GoDropdown>
            <template v-slot:button>
              <IconEllipsis class="flex-none w-3 text-gray-600 dark:text-gray-300" />
            </template>
            <template v-slot:items>
              <GoDropdownItem v-if="canChangeQuantity" @click="showChangeQuantityModal = true">
                Change Quantity
              </GoDropdownItem>
              <GoDropdownItem v-if="canCancel" @click="cancelOrder">Cancel</GoDropdownItem>
              <GoDropdownItem
                v-if="trackingPageParams && waypoint.type === 'dropoff'"
                :to="trackingPageParams"
                target="_blank"
                as="router-link"
              >
                Tracking Link
              </GoDropdownItem>
            </template>
          </GoDropdown>
          <GoModal v-if="showChangeQuantityModal" @close="showChangeQuantityModal = false">
            <div class="p-4">
              <p class="text-lg">Change Quantity</p>
            </div>
            <div class="p-4">
              <Form
                class="space-y-4"
                @submit="changeQuantity"
                :validation-schema="quantitySchema"
                :initial-values="{ quantity: waypoint.quantity }"
              >
                <div class="flex space-x-6 justify-center mb-4 pt-4">
                  <GoTextField label="Quantity" type="number" :name="`quantity`" />
                </div>
                <div class="flex justify-end">
                  <GoButton class="text-xs" variant="outline">Save</GoButton>
                </div>
              </Form>
            </div>
          </GoModal>
        </div>
        <div class="flex space-x-4 sm:justify-end mb-1">
          <DataBit
            title="Est. Arrival"
            :value="time(waypoint.estimatedArrivalTime, timeZoneId)"
            v-if="waypoint.estimatedArrivalTime"
          />
          <DataBit
            title="Arrived At"
            :value="time(waypoint.arrivedAt, timeZoneId)"
            v-if="waypoint.arrivedAt"
          />
          <DataBit
            title="Updated At"
            :value="time(waypoint.statusUpdatedAt, timeZoneId)"
            v-if="waypoint.statusUpdatedAt"
          />
        </div>
        <div>
          <div
            v-if="
              waypoint.state === 'completed' &&
              (waypoint.reverseLogisticsItemsCollected || waypoint.bagReturnDropoff)
            "
          >
            <p class="text-sm font-body align-middle">
              {{ getReverseLogisticsItemsTitle }}
            </p>
          </div>
          <GoTooltip
            text="Courier Note"
            class="flex space-x-2 items-start"
            v-if="waypoint.courierNote"
          >
            <IconCar class="flex-none w-3 mt-1 text-gray-600 dark:text-gray-300" />
            <p class="text-sm font-body align-middle">{{ waypoint.courierNote }}</p>
          </GoTooltip>
          <div
            class="flex space-x-2 items-start border-2 bg-pink-100 border-pink-400 text-pink-700 px-2 py-1 rounded-md"
            v-if="waypoint.unpicked"
          >
            <IconCar class="flex-none w-3 mt-1 text-gray-600 dark:text-gray-300" />
            <p class="text-sm font-body align-middle">{{ waypoint.unpickedReason }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2 pt-2 border-t-2" v-if="forSearch">
      <div class="flex justify-between items-center">
        <p class="text-lg">
          <router-link :to="`/merchant/batches/${waypoint.routeId}#${waypoint.id}`">
            Batch {{ waypoint.routeId }}
          </router-link>
        </p>
        <div class="flex space-x-4">
          <DataBit title="Date" :value="waypoint.routeDate" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {
  apiService,
  waypointBorder,
  waypointState,
  waypointStyle,
  waypointDisplayState,
  calculateDistance,
} from '@tyltgo/shared';
import * as yup from 'yup';
import { Form } from 'vee-validate';
import IconCar from './IconCar.vue';
import IconEllipsis from './IconEllipsis.vue';
import GoBadge from './GoBadge.vue';
import GoButton from './GoButton.vue';
import DataBit from './DataBit.vue';
import { time, timezoneConversionRouteInput } from '../services/date-service';
import IconClipboard from './IconClipboard.vue';
import IconFileImage from './IconFileImage.vue';
import GoModal from './GoModal.vue';
import GoTooltip from './GoTooltip.vue';
import GoDropdown from './GoDropdown.vue';
import GoDropdownItem from './GoDropdownItem.vue';
import { aroundLoadingMessage, showConfirmationModal } from '../services/ui-message-service';
import { currentUser } from '../services/auth-service';

const quantitySchema = yup.object().shape({
  quantity: yup.number().required().label('Quantity'),
});

export default defineComponent({
  emits: ['reloadBatch'],
  setup() {
    return {
      time,
      waypointDisplayState,
      timezoneConversionRouteInput,
      currentUser,
      quantitySchema,
    };
  },
  components: {
    Form,
    IconCar,
    IconEllipsis,
    GoBadge,
    IconClipboard,
    IconFileImage,
    GoModal,
    DataBit,
    GoTooltip,
    GoDropdown,
    GoDropdownItem,
    GoButton,
  },
  props: {
    route: {
      type: Object,
      required: true,
    },
    waypoint: {
      type: Object,
      required: true,
    },
    routeId: {
      type: String,
      required: true,
    },
    routeState: {
      type: String,
      required: false,
    },
    forSearch: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      image: null,
      verifyStreetImage: null,
      verifyStreetImageLinks: [],
      verifyUnitImage: null,
      verifyUnitImageLinks: [],
      showVerifyStreetImageModal: false,
      showVerifyUnitImageModal: false,
      showImageModal: false,
      timeZoneId: this.route.timeZoneId || this.waypoint.timeZoneId,
      showChangeQuantityModal: false,
    };
  },
  async created() {
    if (!this.route.timeZoneId && !this.waypoint.timeZoneId) {
      const zone = await timezoneConversionRouteInput(this.route, 'merchant');
      this.timeZoneId = zone.timeZoneId;
    }
    if (this.waypoint.state === 'completed') {
      this.image = await this.getImage();
      this.verifyStreetImage = await this.getVerifyStreetImage();
      this.verifyUnitImage = await this.getVerifyUnitImage();
    }
  },
  // async updated() {
  //   this.image = await this.getImage();
  // },
  computed: {
    trackingPageParams() {
      if (!this.currentUser) return undefined;
      return {
        name: this.currentUser.trackingSlug
          ? 'track.id.trackingsecret'
          : 'tracking.id.trackingsecret',
        params: {
          trackingSlug: this.currentUser.trackingSlug,
          id: this.waypoint.id,
          trackingSecret: this.waypoint.trackingSecret || 't',
        },
      };
    },
    state() {
      return waypointState(this.waypoint);
    },
    stateStyle() {
      return waypointStyle(this.waypoint);
    },
    stateBorder() {
      return waypointBorder(this.waypoint);
    },
    showImage() {
      return this.waypoint.type !== 'pickup' && this.waypoint.state === 'completed';
    },
    canCancel() {
      if (this.routeState === 'completed') return false;
      if (this.waypoint.bagReturnDropoff) return false;
      return this.waypoint.state !== 'completed' && this.waypoint.type === 'dropoff';
    },
    canChangeQuantity() {
      if (!this.currentUser.settings?.canChangeQuantity) return false;

      if (this.waypoint.type === 'pickup') return false;
      if (this.waypoint.bagReturnDropoff) return false;
      if (this.routeState === 'completed' || this.routeState === 'inProgress') return false;
      return true;
    },
    getReverseLogisticsItemsTitle() {
      if (this.waypoint.bagReturnDropoff) {
        const totalReturned = this.route.waypoints
          .filter(waypoint => !waypoint.bagReturnDropoff && waypoint.type === 'dropoff')
          .map(waypoint => waypoint.reverseLogisticsItemsCollected)
          .reduce((acc, itemCount) => acc + itemCount);
        return `${totalReturned} ${this.currentUser.settings.reverseLogisticsItem} returned.`;
      }
      return `${this.waypoint.reverseLogisticsItemsCollected} ${this.currentUser.settings.reverseLogisticsItem} picked.`;
    },
  },
  methods: {
    calculateDistance(location: any) {
      if (!location) return null;
      return `${calculateDistance(this.waypoint, location).toFixed(2)}m`;
    },
    async getImage() {
      if (!this.showImage) return null;
      return apiService.merchants.batches.orderImage(this.routeId, this.waypoint.id);
    },
    async getVerifyStreetImage() {
      try {
        return await apiService.merchants.batches.orderImage(
          this.routeId,
          this.waypoint.id,
          'streetVerification'
        );
      } catch (e) {
        return null;
      }
    },
    async getVerifyUnitImage() {
      try {
        return await apiService.merchants.batches.orderImage(
          this.routeId,
          this.waypoint.id,
          'unitVerification'
        );
      } catch (e) {
        return null;
      }
    },
    async changeQuantity(values) {
      console.log(values);
      await aroundLoadingMessage('Updating...', async () => {
        await apiService.merchants.batches.changeQuantity(this.routeId, this.waypoint.id, values);
        this.showChangeQuantityModal = false;
        this.$emit('reloadBatch');
      });
    },
    async cancelOrder() {
      let chargeInfo = 'Delivery charge will be removed for this order';
      if (this.route.courierId) {
        chargeInfo = 'This route is assigned therefore your delivery charge will still apply';
      }

      const confirmed = await showConfirmationModal({
        color: 'danger',
        title: 'Confirm Order Cancellation',
        message: `${chargeInfo}. Are you sure you want to cancel this order?`,
        confirmButtonText: 'Cancel Order',
      });
      if (confirmed) {
        await aroundLoadingMessage('Cancelling...', async () => {
          await apiService.merchants.batches.cancelOrder(this.routeId, this.waypoint.id);
          this.$emit('reloadBatch');
        });
      }
    },
  },
});
</script>
