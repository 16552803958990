<template>
  <main
    class="relative flex-1 overflow-y-auto focus:outline-none pb-12"
    tabindex="0"
    :class="edit ? 'mb-96' : ''"
  >
    <div class="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8" v-if="route">
      <RouteCard
        :route="routeWithAlerts(route)"
        :courier="courier"
        class="my-4"
        @reloadRoute="loadRoute"
      />
      <div class="border-b border-gray-200 mb-4">
        <nav class="-mb-px flex space-x-8" aria-label="Tabs">
          <router-link
            v-for="(tab, key) in tabs"
            :key="key"
            :to="{
              name: 'admin.route.tab',
              params: { id: route.id, tab: key },
            }"
            class="whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
            :class="
              currentTab && currentTab.name === tab.name
                ? 'border-indigo-500 dark:border-indigo-400 text-indigo-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            "
            aria-current="page"
          >
            {{ tab.name }}
          </router-link>
        </nav>
      </div>
      <div v-if="currentTab.name === 'Waypoints'">
        <div class="w-full mb-4 relative" style="height: 60vh">
          <l-map ref="map" @ready="setMap(route, 'map')" :zoom="zoom">
            <MapTiles />
            <l-control-layers />

            <DrawMapRoute :route="routeWithAlerts(route)" :zoom="zoom" link="admin.route" />
            <l-polyline
              v-if="courierLocations.length"
              :lat-lngs="courierLocations.map(location => [location.lat, location.lng])"
              :color="`red`"
              :opacity="0.4"
              :weight="3"
            />
            <template v-if="showCourierLocations && courierLocations.length">
              <l-marker
                v-for="(location, index) of courierLocations"
                :key="index"
                :lat-lng="[location.lat, location.lng]"
              >
                <l-icon>
                  <IconCircle class="w-1 text-red-500" />
                </l-icon>
                <l-popup>
                  <div class="flex flex-col">
                    <div>{{ time(location.location.timestamp, timeZoneId) }}</div>
                    <div>{{ diff(location.location.timestamp, undefined) }} minutes ago</div>
                  </div>
                </l-popup>
              </l-marker>
            </template>
            <l-marker
              v-if="courierLocation && courierLocation.latitude"
              :lat-lng="[courierLocation.latitude, courierLocation.longitude]"
            >
              <l-icon>
                <IconCar class="w-6 text-brand-1" />
              </l-icon>
              <l-popup>
                <div class="flex flex-col">
                  <div>{{ time(courierLocation.timestamp, timeZoneId) }}</div>
                  <div>{{ diff(courierLocation.timestamp, undefined) }} minutes ago</div>
                </div>
              </l-popup>
            </l-marker>
            <l-marker v-if="courier?.lat && courier?.lng" :lat-lng="[courier.lat, courier.lng]">
              <l-icon>
                <IconHome class="w-8 text-pink-500" />
              </l-icon>
              <l-popup>
                <div class="flex flex-col">
                  <div>Courier's Address</div>
                  <div>{{ courier.address }}</div>
                </div>
              </l-popup>
            </l-marker>
            <l-marker
              v-if="route.startedLocation?.latitude"
              :lat-lng="[route.startedLocation.latitude, route.startedLocation.longitude]"
            >
              <l-icon>
                <IconPlay class="w-5 text-green-500" />
              </l-icon>
              <l-popup>
                <div class="flex flex-col">
                  <div>Started {{ fromNow(route.startedAt) }}</div>
                  <div>{{ readableDateTime(route.startedAt) }}</div>
                </div>
              </l-popup>
            </l-marker>
            <template v-if="showArrviveUpdateLocation">
              <template v-for="waypoint of route.waypoints" :key="waypoint.id">
                <l-marker
                  v-if="waypoint.statusUpdatedLocation && waypoint.type === 'dropoff'"
                  :lat-lng="[
                    waypoint.statusUpdatedLocation.latitude,
                    waypoint.statusUpdatedLocation.longitude,
                  ]"
                >
                  <l-icon>
                    <div class="relative">
                      <span
                        class="absolute text-white font-bold left-1/2 transform -translate-x-1/2 -translate-y-2/3"
                        style="font-size: 0.6rem; margin-top: 65%"
                      >
                        {{ waypointNumber(route, waypoint) }}
                      </span>
                      <IconMapPin :class="waypointMarkerStyle(waypoint, zoom)" />
                    </div>
                  </l-icon>
                </l-marker>
                <l-marker
                  v-if="waypoint.arrivedLocation && waypoint.type === 'dropoff'"
                  :lat-lng="[waypoint.arrivedLocation.latitude, waypoint.arrivedLocation.longitude]"
                >
                  <l-icon>
                    <div class="relative">
                      <span
                        class="absolute text-black font-bold left-1/2 transform -translate-x-1/2 -translate-y-2/3"
                        style="font-size: 0.6rem; margin-top: 65%"
                      >
                        {{ waypointNumber(route, waypoint) }}
                      </span>
                      <IconMapPin class="text-yellow-400 w-4" />
                    </div>
                  </l-icon>
                </l-marker>
              </template>
            </template>
            <l-marker
              v-if="searchedAddressText && searchedAddress?.geometry"
              :lat-lng="searchedAddress.geometry.location"
            >
              <l-icon>
                <IconMapPin class="w-5 text-blue-500" />
              </l-icon>
            </l-marker>
          </l-map>
          <div
            class="flex space-x-2 items-center my-2 absolute bottom-0 w-full"
            style="z-index: 800"
          >
            <GoButton
              class="text-xs ml-2"
              variant="outline"
              @click="
                showCourierLocations = !showCourierLocations;
                loadCourierLocations();
              "
              type="button"
            >
              <div class="flex">
                <IconCar class="w-4" />
              </div>
            </GoButton>
            <GoButton
              class="text-xs ml-2"
              variant="outline"
              @click="showArrviveUpdateLocation = !showArrviveUpdateLocation"
              type="button"
            >
              <div class="flex">
                <IconMapPin class="w-4 h-4" />
              </div>
            </GoButton>
            <div class="mx-2 w-1/2">
              <GoAddressField
                label="false"
                name="address"
                v-model="searchedAddressText"
                @place-selected="a => (searchedAddress = JSON.parse(JSON.stringify(a)))"
                class="w-full"
              />
            </div>
          </div>
        </div>
        <GoForm
          @submit="update"
          v-slot="{ handleSubmit, values }"
          :validation-schema="schema"
          :initial-values="{ waypoints: {} }"
        >
          <WaypointCard
            v-for="waypoint in routeWithAlerts(route).waypoints"
            :key="waypoint.id"
            :waypoint="waypoint"
            :routeId="route.id"
            :ref="waypoint.id"
            :waypointNumber="waypointNumber(route, waypoint)"
            :edit="edit"
            :route="route"
            :merchant="merchantsByBatch[waypoint.associatedOrder]"
            :problemPhoneNumber="problemPhoneNumbersByNumber[waypoint.phoneNumber]?.[0]"
            :problemEmail="problemEmailsByEmail[waypoint.email]?.[0]"
            :problemNotification="problemNotificationsByNumber[waypoint.phoneNumber]?.[0]"
            @reloadRoute="loadRoute"
          />

          <div v-if="edit">
            <FieldArray name="orders" v-slot="{ fields, push, remove }">
              <div class="flex flex-col pt-2">
                <NewOrderForm
                  v-for="(entry, index) of fields"
                  :key="entry.key"
                  :prefix="`orders[${index}].`"
                  :orderNumber="`# ${index + 1}`"
                  :batchList="batchList"
                  :merchantsByBatch="merchantsByBatch"
                  @removeOrder="remove(index)"
                />
              </div>
              <div class="flex justify-start py-2 mb-2">
                <GoButton class="text-xs" variant="outline" @click="push({})" type="button">
                  <div class="flex">
                    <IconPlus class="w-2 mr-2" />
                    Add Order
                  </div>
                </GoButton>
              </div>
            </FieldArray>
          </div>

          <div
            v-if="route.endAtAddress || edit"
            class="bg-white dark:bg-gray-800 p-4 shadow rounded-sm mb-4 flex border-l-4 flex-col"
          >
            <span class="uppercase font-header text-xs text-gray-500 dark:text-gray-300 block">
              End Address
            </span>
            <GoTextField
              :label="false"
              type="search"
              name="endAtAddress"
              v-model="endAtAddress"
              @place-selected="placeSelected"
              google-places
              placeholder="Search for address"
              v-if="edit"
            />
            <span v-else>
              {{ route.endAtAddress }}
            </span>
          </div>

          <div
            v-if="edit && route.mergedOrderIds.length"
            class="bg-white dark:bg-gray-800 p-4 shadow rounded-sm mb-4 flex border-l-4 flex-col"
          >
            <span class="uppercase font-header text-xs text-gray-500 block">Add Batches</span>
            <div class="flex my-2 py-2">
              <GoTextField
                :label="false"
                name="newBatchId"
                v-model="newBatchId"
                placeholder="Batch Id"
                class="mr-4"
              />
              <GoButton class="text-xs" variant="outline" @click="addBatch" type="button">
                <div class="flex">
                  <IconPlus class="w-2 mr-2" />
                  Add Batch
                </div>
              </GoButton>
            </div>
            <div>
              <RouteCard v-for="batch of newBatches" :key="batch.id" :route="batch" class="mb-4" />
            </div>
          </div>

          <div
            v-if="edit && route.mergedOrderIds.length"
            class="bg-white dark:bg-gray-800 p-4 shadow rounded-sm mb-4 flex border-l-4 flex-col"
          >
            <span class="uppercase font-header text-xs text-gray-500 block mb-2">
              Remove Batches
            </span>
            <div>
              <div
                v-for="pickup of route.waypoints?.filter(
                  p => p.type === 'pickup' && route.mergedOrderIds.includes(p.associatedOrder)
                )"
                :key="pickup.id"
                class="pb-2 mb-2 border-b-2"
              >
                <div class="flex flex-col sm:flex-row sm:justify-between">
                  <div class="flex flex-col">
                    <p class="space-x-2 flex items-center">
                      <span>
                        <router-link :to="`/admin/merchants/${pickup.associatedEmail}`">
                          {{ pickup.name }}
                        </router-link>
                      </span>
                      <span class="text-sm text-gray-500 dark:text-gray-300">
                        {{ pickup.associatedOrder }}
                      </span>
                      <span
                        class="text-sm text-gray-500 dark:text-gray-300"
                        v-if="pickup.routeNumber"
                      >
                        #{{ pickup.routeNumber }}
                      </span>
                    </p>
                    <p class="text-xs text-gray-500 dark:text-gray-300">
                      {{ pickup.address }}
                    </p>
                  </div>
                  <div class="flex space-x-4">
                    <GoCheckbox
                      label="Remove"
                      name="removeBatchIds"
                      :value="pickup.associatedOrder"
                    />
                  </div>
                </div>
              </div>
              <div
                v-if="
                  (values.removeBatchIds && !Array.isArray(values.removeBatchIds)) ||
                  values.removeBatchIds?.length === route.mergedOrderIds.length
                "
              >
                <GoAlert color="danger" class="mb-4">
                  You can't remove all the component batches, geez.
                </GoAlert>
              </div>
            </div>
          </div>

          <GoBottomPanel :resetHeight="!updatePreviewResults" :showResize="!!updatePreviewResults">
            <div v-if="previewResultsError" class="py-4 w-1/3">
              <div class="rounded-md bg-red-50 p-4 text-red-800">
                There was an error generating this route. Please check priority times and other
                route details and try again.
              </div>
            </div>
            <div
              v-if="!previewResultsError && updatePreviewResults"
              class="h-full flex flex-row space-x-4 overflow-y-auto"
            >
              <div class="w-1/2">
                <div
                  v-if="updatePreviewResults.rerouteReasons.length"
                  class="bg-pink-200 border border-pink-500 p-2 rounded-md mb-2"
                >
                  <p class="font-bold mb-2">Route might be rearranged due to:</p>
                  <div v-for="reason in updatePreviewResults.rerouteReasons" :key="reason">
                    {{ reason }}
                  </div>
                </div>
                <p class="text-sm text-gray-600 dark:text-gray-400 font-header uppercase bold mb-1">
                  Changes
                  <span v-if="route.courierId" class="mr-2 font-body">
                    (NOTE: pay will not decrease when route has a courier assigned)
                  </span>
                </p>
                <div class="flex space-x-2 items-center mb-3">
                  <DataBit
                    title="Duration"
                    :value="`${updatePreviewResults.route.duration - route.duration} mins`"
                  />
                  <DataBit
                    title="Distance"
                    :value="`${(updatePreviewResults.route.distanceKm - route.distanceKm).toFixed(
                      2
                    )} km`"
                  />
                  <DataBit
                    title="Driver Pay"
                    :value="`${(updatePreviewResults.route.pay - route.pay).toFixed(2)}`"
                  />
                  <DataBit
                    title="Price"
                    :value="`${(updatePreviewResults.route.price - route.price).toFixed(2)}`"
                  />
                </div>
                <RouteCard
                  :route="routeWithAlerts(updatePreviewResults.route)"
                  @reloadRoute="loadRoute"
                />
                <div
                  v-if="updatePreviewResults.unassignedDropoffs.length"
                  class="bg-pink-200 border border-pink-500 p-2 rounded-md"
                >
                  <p class="font-bold mb-2">Unassigned Dropoffs</p>
                  <div
                    v-for="waypoint in updatePreviewResults.unassignedDropoffs"
                    :key="waypoint.id"
                  >
                    {{ waypoint.name }} - {{ waypoint.address }}
                  </div>
                </div>

                <div class="uppercase">
                  Pay Breakdown ({{ updatePreviewResults.route.pay.toFixed(2) }})
                </div>
                <GoTable
                  class="my-4 overflow-x-auto"
                  :tableData="{
                    headers: [
                      { title: 'Change', key: 'change' },
                      { title: 'Details', key: 'details' },
                    ],
                    data: updatePreviewResults.details.map(d => ({
                      ...d,
                      change: d.change.toFixed(2),
                    })),
                  }"
                ></GoTable>
              </div>
              <div class="w-1/2">
                <div class="flex flex-col">
                  <div class="h-72">
                    <l-map
                      ref="previewMap"
                      @ready="setMap(updatePreviewResults.route, 'previewMap')"
                      :zoom="zoom"
                    >
                      <MapTiles />
                      <l-control-layers />
                      <DrawMapRoute
                        :route="updatePreviewResults.route"
                        :zoom="zoom"
                        link="admin.route"
                      />
                      <WaypointMapMarker
                        v-for="waypoint in updatePreviewResults.unassignedDropoffs"
                        :key="waypoint.id"
                        :waypoint="waypoint"
                        :route="updatePreviewResults.route"
                        color="text-pink-500 w-6"
                        :zoom="zoom"
                        link="admin.route"
                      />
                    </l-map>
                  </div>
                  <div class="py-4">
                    <WaypointCard
                      v-for="waypoint in routeWithAlerts(updatePreviewResults.route).waypoints"
                      :key="waypoint.id"
                      :waypoint="waypoint"
                      :routeId="updatePreviewResults.route.id"
                      :waypointNumber="waypointNumber(updatePreviewResults.route, waypoint)"
                      :ref="waypoint.id"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="fixed bottom-0 py-2 w-full pr-64 bg-white dark:bg-gray-800">
              <GoButton variant="link" @click="edit = true" v-if="!edit">Edit</GoButton>
              <div class="flex justify-between space-x-4 pr-8" v-if="edit">
                <div class="space-x-4 flex items-center">
                  <GoButton
                    variant="link"
                    type="button"
                    @click="
                      edit = false;
                      updatePreviewResults = null;
                      previewResultsError = null;
                    "
                  >
                    Cancel
                  </GoButton>
                  <GoButton variant="link" @click="handleSubmit($event, updateWaypointsPreview)">
                    Preview
                  </GoButton>
                  <GoCheckbox
                    v-if="currentUser.featureFlags?.allowPayDecrease"
                    label="Allow Pay Decrease"
                    name="allowPayDecrease"
                    :value="true"
                  />
                </div>
                <GoButton
                  v-if="updatePreviewResults && !updatePreviewResults.unassignedDropoffs.length"
                  variant="link"
                  @click="handleSubmit($event, updateWaypoints)"
                >
                  Save
                </GoButton>
              </div>
            </div>
          </GoBottomPanel>
        </GoForm>
        <div class="py-4" v-if="route.removedWaypoints.length">
          <h3 class="font-bold mb-2">Removed Waypoints</h3>
          <WaypointCard
            v-for="waypoint in routeWithAlerts(route).removedWaypoints"
            :key="waypoint.id"
            :showActions="false"
            :removed="true"
            :waypoint="waypoint"
            :routeId="route.id"
            :ref="waypoint.id"
          />
        </div>
      </div>
      <div v-else-if="currentTab.component">
        <component :is="currentTab.component" :route="route" @loadRoute="loadRoute" :key="tabKey" />
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import 'leaflet/dist/leaflet.css';
import { LMap, LControlLayers, LMarker, LPopup, LIcon, LPolyline } from '@vue-leaflet/vue-leaflet';
import { defineComponent, markRaw } from 'vue';
import {
  apiService,
  waypointsWithAlerts,
  waypointNumber,
  merchantIdsForRoute,
  waypointsWithDeliveryCutoffs,
  fromNow,
  readableDateTime,
  waypointMarkerStyle,
} from '@tyltgo/shared';
import io from 'socket.io-client';
import debounce from 'lodash/debounce';
import groupBy from 'lodash/groupBy';
import { Form, FieldArray } from 'vee-validate';
import * as yup from 'yup';
import orderBy from 'lodash/orderBy';
import dayjs from 'dayjs';
import { useDocumentVisibility } from '@vueuse/core';
import RouteCard from '../../components/RouteCard.vue';
import DrawMapRoute from '../../components/DrawMapRoute.vue';
import WaypointCard from '../../components/WaypointCard.vue';
import GoButton from '../../components/GoButton.vue';
import GoCheckbox from '../../components/GoCheckbox.vue';
import GoBottomPanel from '../../components/GoBottomPanel.vue';
import IconCar from '../../components/IconCar.vue';
import IconHome from '../../components/IconHome.vue';
import IconPlay from '../../components/IconPlay.vue';
import IconCircle from '../../components/IconCircle.vue';
import IconPlus from '../../components/IconPlus.vue';
import WaypointMapMarker from '../../components/WaypointMapMarker.vue';
import GoTextField from '../../components/GoTextField.vue';
import GoTable from '../../components/GoTable.vue';
import NewOrderForm from '../../components/NewOrderForm.vue';
import DataBit from '../../components/DataBit.vue';
import MapTiles from '../../components/MapTiles.vue';
import AdminRoutePayBreakdown from '../../components/AdminRoutePayBreakdown.vue';
import AdminRoutePriceDetails from '../../components/AdminRoutePriceDetails.vue';
import AdminRouteOfferHistory from '../../components/AdminRouteOfferHistory.vue';
import AdminRouteIncidents from '../../components/AdminRouteIncidents.vue';
import {
  showLoadingMessage,
  stopLoadingMessage,
  showConfirmationModal,
} from '../../services/ui-message-service';
import { diff, time, timezoneConversionRouteInput } from '../../services/date-service';
import { currentUser } from '../../services/auth-service';
import IconBox from '../../components/IconBox.vue';
import IconMapPin from '../../components/IconMapPin.vue';
import GoAddressField from '../../components/GoAddressField.vue';

const tabs = {
  waypoints: {
    name: 'Waypoints',
    // component: markRaw(AdminMerchantBatches),
  },
  payBreakdown: {
    name: 'Pay Breakdown',
    component: markRaw(AdminRoutePayBreakdown),
  },
  priceDetails: {
    name: 'Price Details',
    component: markRaw(AdminRoutePriceDetails),
  },
  offerHistory: {
    name: 'Offer History',
    component: markRaw(AdminRouteOfferHistory),
  },
  incidents: {
    name: 'Incidents',
    component: markRaw(AdminRouteIncidents),
  },
};

const schema = yup.object().shape({
  orders: yup.array().of(
    yup.object().shape({
      id: yup
        .string()
        .required()
        .matches(
          /^[A-Za-z0-9_-]+$/,
          'Order Id allows only letters, numbers, dashes and underscores'
        )
        .label('Order Id'),
      name: yup.string().required().label('Name'),
      address: yup.string().required().label('Address'),
      phoneNumber: yup.string().required().label('Phone Number'),
      quantity: yup.number().required().label('Quantity'),
      associatedOrder: yup.string().required().label('Merchant'),
    })
  ),
});

export default defineComponent({
  components: {
    GoAddressField,
    IconBox,
    IconMapPin,
    GoTable,
    RouteCard,
    DrawMapRoute,
    WaypointCard,
    LMap,
    MapTiles,
    LControlLayers,
    LMarker,
    LPopup,
    LIcon,
    LPolyline,
    IconCar,
    IconHome,
    IconPlay,
    IconCircle,
    IconPlus,
    GoButton,
    GoCheckbox,
    GoBottomPanel,
    Form,
    FieldArray,
    GoTextField,
    WaypointMapMarker,
    NewOrderForm,
    DataBit,
  },
  setup() {
    const visibilityState = useDocumentVisibility();
    return {
      waypointMarkerStyle,
      visibilityState,
      readableDateTime,
      fromNow,
      waypointsWithAlerts,
      waypointNumber,
      schema,
      currentUser,
      time,
      diff,
      tabs,
    };
  },
  watch: {
    async visibilityState(state) {
      if (state === 'visible') {
        this.disconnectSockets();
        await this.updateRoute();
        this.connectSockets();
      } else {
        this.disconnectSockets();
      }
    },
    async $route(to, from) {
      const { tab } = this.$route.params;
      if (to.name === 'admin.route.tab') {
        this.currentTab = tabs[tab] || tabs.waypoints;
      }
      if (from && from.params.id !== to.params.id && to.name.startsWith('admin.route')) {
        await this.loadRoute();
        this.currentTab = null;
        setTimeout(() => {
          this.currentTab = tabs[tab] || tabs.waypoints;
        }, 10);
      }
    },
  },
  async mounted() {
    const { tab } = this.$route.params;
    this.currentTab = tabs[tab] || tabs.waypoints;

    await this.loadRoute();

    const { id } = this.$route.params;
    this.courierLocation = await apiService.admin.routes.courierLocation(id);
    this.socket = io(`${apiService.apiHost}?room=admin/routes/${id}`, {
      transports: ['websocket', 'polling'],
    });
    this.socket.on('courierLocation', ({ location }) => {
      this.courierLocation = location;
      this.loadCourierLocations();
    });
    this.socket.on('route', debounce(this.updateRoute, 4000));
  },
  unmounted() {
    if (this.socket) this.socket.disconnect();
  },
  async updated() {
    const { hash } = this.$route;
    document.title = `Route ${this.$route.params.id} - Tyltgo`;
    if (hash && hash !== this.hash) {
      this.$refs[this.$route.hash.replace('#', '')]?.$el?.scrollIntoView();
    }
    this.hash = hash;
  },
  data() {
    return {
      problemPhoneNumbersByNumber: {},
      problemNotificationsByNumber: {},
      problemEmailsByEmail: {},
      searchedAddressText: '',
      searchedAddress: {},
      currentTab: null,
      socket: null,
      courierLocation: null,
      courierLocations: [],
      showCourierLocations: false,
      showArrviveUpdateLocation: false,
      route: null,
      merchants: null,
      courier: null,
      zoom: 6,
      edit: false,
      updatePreviewResults: null,
      previewResultsError: null,
      endAtAddress: null,
      newBatchId: '',
      newBatches: [],
      timeZoneId: 'America/Toronto',
      tabKey: 0,
    };
  },
  computed: {
    isDevelopment() {
      return import.meta.env.MODE === 'development';
    },
    batchList() {
      const set = new Set<string>(this.route.waypoints.map(x => x.associatedOrder));
      const merchantNames = Array.from(set).reduce((names, id) => {
        const merchant = this.route.waypoints.find(
          x => id === x.associatedOrder && x.associatedBusiness
        ).associatedBusiness;
        return {
          ...names,
          [id]: merchant,
        };
      }, {});
      return Array.from(set).map(x => ({ label: `${x} - ${merchantNames[x]}`, value: x }));
    },
    merchantsById() {
      return this.merchants.reduce((hash, merchant) => {
        return {
          ...hash,
          [merchant.email]: merchant,
        };
      }, {});
    },
    merchantsByBatch() {
      return this.route.waypoints.reduce((hash, point) => {
        if (hash[point.associatedOrder]) return hash;

        const merchant = this.merchantsById[point.associatedEmail];
        return {
          ...hash,
          [point.associatedOrder]: merchant,
        };
      }, {});
    },
  },
  methods: {
    placeSelected(place, _name) {
      this.endAtAddress = place.formatted_address;
    },
    async loadProblemEmails() {
      const { id } = this.$route.params;
      if (!id) return;

      const { problemEmails } = await apiService.admin.routes.problemEmails(id);
      this.problemEmailsByEmail = groupBy(problemEmails, 'email');
    },
    async loadProblemPhoneNumbers() {
      const { id } = this.$route.params;
      if (!id) return;

      const {
        problemPhoneNumbers,
        problemNotifications,
      } = await apiService.admin.routes.problemPhoneNumbers(id);
      this.problemPhoneNumbersByNumber = groupBy(problemPhoneNumbers, 'phoneNumber');
      this.problemNotificationsByNumber = groupBy(problemNotifications, 'toId');
    },
    async loadRoute() {
      this.loadProblemPhoneNumbers();
      this.loadProblemEmails();

      const { id } = this.$route.params;
      if (!id) return;
      this.loading = true;
      const route = await apiService.admin.routes.get(id);
      this.timeZoneId = route.timeZoneId;
      if (!route.timeZoneId) {
        const zone = await timezoneConversionRouteInput(route, 'admin');
        this.timeZoneId = zone.timeZoneId;
      }
      this.endAtAddress = route.endAtAddress;
      const merchantIds: string[] = merchantIdsForRoute(route);
      this.merchants = (
        await Promise.all(
          merchantIds.map(async merchantId => {
            try {
              const { merchant } = await apiService.admin.merchants.get(merchantId);
              return merchant;
            } catch (e) {
              return null;
            }
          })
        )
      ).filter(x => !!x);

      if (route.assignedDriver) {
        const { courier } = await apiService.admin.couriers.get(route.assignedDriver);
        this.courier = courier;
      }

      this.route = route;
      this.loading = false;

      if (this.currentTab?.name === 'Incidents') {
        this.tabKey += 1;
      }
    },
    async loadCourierLocations() {
      if (!this.showCourierLocations) {
        this.courierLocations = [];
        return;
      }

      const { id } = this.$route.params;
      if (!id) return;
      const { courierLocations } = await apiService.admin.routes.courierLocations(id);
      const startOfDay = dayjs(this.route.routeDate).tz(this.timeZoneId).startOf('day');
      console.log({ startOfDay });

      this.courierLocations = orderBy(
        courierLocations.filter(l => dayjs(l.location.timestamp).isAfter(startOfDay)),
        'location.timestamp'
      );
    },
    setMap(route, mapRef) {
      const map = this.$refs[mapRef];
      map.leafletObject.fitBounds(this.bounds(route));
      map.leafletObject.scrollWheelZoom.disable();
    },
    async updateRoute() {
      await this.loadRoute();
      this.courierLocation = await apiService.admin.routes.courierLocation(this.route.id);
    },
    setErrors(setFieldError, { orders, waypoints }) {
      let errors = false;
      const ids = this.route.waypoints.map(point => point.id.toLowerCase());
      for (const [index, order] of (orders || []).entries()) {
        if (!order.location) {
          errors = true;
          setFieldError(
            `orders[${index}].address`,
            'Please select the address from the autocomplete dropdown.'
          );
        }
        if (ids.includes(order.id.toLowerCase())) {
          errors = true;
          setFieldError(`orders[${index}].id`, 'Order Id is already being used in this route.');
        }
      }

      for (const [waypointId, wp] of Object.entries(waypoints || {})) {
        const waypoint = wp as any;
        const point = this.route.waypoints.find(p => p.id === waypointId);
        if (
          point.address !== waypoint.customerAddress &&
          point.lat === waypoint.customerLat &&
          point.lng === waypoint.customerLong
        ) {
          errors = true;
          setFieldError(
            `waypoints.${waypointId}.customerAddress`,
            'Please select the address from the autocomplete dropdown.'
          );
        }
      }

      return errors;
    },
    processValues(values) {
      const orders =
        values.orders?.map(order => ({
          ...order,
          geo: undefined,
          location: order.geo[order.address],
        })) || [];
      // ensure waypoints is always a hash.
      const waypoints = Object.entries(values.waypoints || {}).reduce((hash, [id, point]) => {
        if (!point) return hash;
        return {
          ...hash,
          [id]: point,
        };
      }, {});
      const remove = Object.entries(waypoints || {}).reduce((list, [id, point]) => {
        if (!(point as any).remove) return list;
        return [...list, id];
      }, []);
      return {
        ...values,
        waypoints,
        orders,
        remove,
        removeBatchIds: values.removeBatchIds || [],
        newBatchIds: this.newBatches.map(batch => batch.id),
      };
    },
    async updateWaypoints(values, { setFieldError }) {
      // eslint-disable-next-line no-param-reassign
      values = this.processValues(values);
      const errors = this.setErrors(setFieldError, values);
      if (errors) return;

      showLoadingMessage('Updating Route');
      await apiService.admin.routes.updateWaypoints(this.route.id, values);
      this.edit = false;
      this.newBatches = [];
      stopLoadingMessage();
      this.updatePreviewResults = null;
      this.loadRoute();
    },
    async updateWaypointsPreview(values, { setFieldError }) {
      // eslint-disable-next-line no-param-reassign
      values = this.processValues(values);
      const errors = this.setErrors(setFieldError, values);
      if (errors) return;

      showLoadingMessage('Generating Preview');
      try {
        const data = await apiService.admin.routes.updateWaypointsPreview(this.route.id, values);
        this.previewResultsError = false;
        this.updatePreviewResults = data;
      } catch (e) {
        this.previewResultsError = true;
        console.log(e);
      }
      stopLoadingMessage();
    },
    update() {
      // no-op
    },
    async addBatch() {
      if (
        this.newBatches.map(batch => batch.id).includes(this.newBatchId) ||
        this.route.mergedOrderIds.includes(this.newBatchId)
      ) {
        this.newBatchId = '';
      }

      if (this.newBatchId) {
        const batch = await apiService.admin.routes.get(this.newBatchId);
        if (batch.mergedToId) {
          showConfirmationModal({
            title: `Route ${batch.id} is already a component`,
            message: `You cannot add a component batch to a merged route. It is already in another merged route: ${batch.mergedToId}`,
            color: 'danger',
          });
        } else if (batch.mergedOrderIds.length && !batch.deleted) {
          showConfirmationModal({
            title: `Route ${batch.id} is a merged route`,
            message: `You cannot add an active merged route to another merged route. Get your head in the game.`,
            color: 'danger',
          });
        } else if (batch.mergedOrderIds.length && batch.deleted) {
          for (const mergedOrderId of batch.mergedOrderIds) {
            // eslint-disable-next-line no-await-in-loop
            const mergedBatch = await apiService.admin.routes.get(mergedOrderId);
            this.newBatches.push(mergedBatch);
          }
        } else {
          this.newBatches.push(batch);
        }
      }
      this.newBatchId = '';
    },
    bounds(route) {
      const bounds = route.waypoints.map(point => [point.lat, point.lng]);
      if (this.courierLocation && this.courierLocation.latitude) {
        return [...bounds, [this.courierLocation.latitude, this.courierLocation.longitude]];
      }
      return bounds;
    },
    routeWithAlerts(route) {
      return {
        ...route,
        waypoints: waypointsWithAlerts(
          waypointsWithDeliveryCutoffs(route.waypoints, this.merchants)
        ),
      };
    },
    connectSockets() {
      if (!this.socket) return;
      this.socket.connect();
    },
    disconnectSockets() {
      console.log('NO SOCKS');
      if (!this.socket) return;
      this.socket.disconnect();
    },
  },
});
</script>
