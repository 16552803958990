<template>
  <GoField :name="name" v-bind="$attrs" v-slot="{ field, id, errors }" ref="field">
    <input
      @focus="onFocus"
      :autocomplete="$attrs.autocomplete || id"
      v-bind="field"
      class="form-element shadow-sm block w-full sm:text-sm rounded-md disabled:opacity-80 dark:bg-gray-700"
      :class="elementClasses(errors)"
      :placeholder="placeholder"
      :id="id"
      :type="type"
      :disabled="disabled"
      ref="input"
    />
    <div
      class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
      v-if="errors.length"
    >
      <IconExclamationCircle class="h-5 w-5 text-red-500" />
    </div>
  </GoField>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import GoField from './GoField.vue';
import IconExclamationCircle from './IconExclamationCircle.vue';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let google: any;

export default defineComponent({
  emits: ['place-selected'],
  inheritAttrs: false,
  components: {
    GoField,
    IconExclamationCircle,
  },
  props: {
    name: {
      type: String,
    },
    placeholder: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    focus: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    googlePlaces: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.focus) {
      this.$refs.input.focus();
    }
    if (this.googlePlaces) {
      const autocomplete = new google.maps.places.Autocomplete(this.$refs.input, {
        componentRestrictions: { country: 'CA' },
        types: ['address'],
        fields: ['formatted_address', 'geometry'],
      });

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();

        // this will trigger the change in the v-model
        // of the text field.
        const event = new Event('change');
        this.$refs.input.dispatchEvent(event);

        this.$emit('place-selected', place, this.name);
      });
    }
  },
  methods: {
    elementClasses(errors: string[]) {
      if (errors.length) {
        return 'border-2 border-red-400 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 pr-10';
      }
      return 'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 text-gray-800 dark:text-gray-100 dark:border-gray-500 dark:placeholder-gray-400';
    },
    onFocus() {
      if (this.googlePlaces) {
        this.$refs.input.attributes.autocomplete.value = this.$refs.field.generatedId;
        this.$refs.input.attributes.name.value = this.$refs.field.generatedId;
      }
    },
  },
});
</script>
