<template>
  <div>
    <dt class="text-xs uppercase font-medium text-gray-500 dark:text-gray-400 font-header">
      {{ title }}
    </dt>
    <dd class="mt-1 text-sm text-gray-900 dark:text-gray-300" :class="align">
      <span v-if="value">{{ value }}</span>
      <slot></slot>
    </dd>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Boolean],
      required: false,
    },
    leftAlign: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    align() {
      return this.leftAlign ? 'text-left' : 'text-right';
    },
  },
});
</script>
