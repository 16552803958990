<template>
  <div
    class="bg-white dark:bg-gray-800 py-2 shadow rounded-sm mb-4 flex border-l-4 flex-col"
    :id="error.id"
    :class="errorBorder"
  >
    <div class="flex flex-col sm:flex-row justify-between px-4">
      <p class="text-gray-400 text-sm">{{ error.type }}</p>
      <div class="flex flex-col sm:flex-row justify-end">
        <GoBadge :class="errorStyle">{{ error.state }}</GoBadge>
        <GoButton @click="deleteError" class="px-3" variant="link" type="button">
          <IconTimes class="w-3 text-gray-400" />
        </GoButton>
      </div>
    </div>
    <div class="px-4 py-2 space-y-2">
      <div v-if="error.parameters?.length" class="flex space-x-4">
        <DataBit
          v-for="parameter of error.parameters"
          :key="parameter.name"
          :title="parameter.displayName"
          :value="parameter.displayValue"
          :left-align="true"
        />
      </div>
      <div class="flex space-x-4">
        <DataBit title="Reported By" :value="error.reportedBy" />
        <DataBit title="Reported At" :value="readableDateTime(error.createdAt)" />
      </div>
      <div class="flex space-x-2 items-start" v-if="error.description">
        <IconClipboard class="flex-none w-3 mt-px text-gray-400" />
        <p class="text-sm font-body align-middle">{{ error.description }}</p>
      </div>
      <div class="flex space-x-4" v-if="error.state === 'resolved'">
        <DataBit title="Resolved By" :value="error.resolvedBy" />
        <DataBit
          title="Bump Up"
          :value="`$${resolutionBumpup.toFixed(2)}`"
          v-if="resolutionBumpup"
        />
        <DataBit title="Internal" :left-align="true" :value="resolutionInternal ? 'Yes' : 'No'" />
      </div>
      <div class="flex space-x-2 items-start" v-if="error.state === 'resolved'">
        <IconClipboard class="flex-none w-3 mt-px text-gray-400" />
        <p class="text-sm font-body align-middle">{{ error.resolution.notes }}</p>
      </div>
      <Form
        v-if="error.state === 'unresolved'"
        class="space-y-4"
        @submit="resolve"
        :validation-schema="errorSchema"
      >
        <div class="flex justify-around">
          <DataBit title="Price" :value="`$${price.toFixed(2)}`" />
          <DataBit title="Courier Pay" :value="`$${pay.toFixed(2)}`" />
          <DataBit title="Margin" :value="`${margin.toFixed(2)}%`" />
        </div>
        <GoTextField name="reason" label="false" placeholder="reason" />
        <div class="flex justify-between">
          <GoCheckbox :value="true" name="internal" label="Internal" v-model="internal" />
          <GoTextField
            name="bumpupAmount"
            v-model="bumpupAmount"
            type="number"
            label="false"
            placeholder="bump up amount"
          />
          <GoButton variant="outline" class="text-xs">Resolve</GoButton>
        </div>
      </Form>
    </div>
    <div
      class="flex flex-col sm:flex-row border-t-2 pt-2 px-4 justify-end"
      v-if="$slots.bottom"
    ></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import * as yup from 'yup';
import { Form } from 'vee-validate';
import { apiService, readableDateTime } from '@tyltgo/shared';
import GoBadge from './GoBadge.vue';
import GoButton from './GoButton.vue';
import GoTextField from './GoTextField.vue';
import GoCheckbox from './GoCheckbox.vue';
import DataBit from './DataBit.vue';
import IconClipboard from './IconClipboard.vue';
import IconTimes from './IconTimes.vue';
import { aroundLoadingMessage, showConfirmationModal } from '../services/ui-message-service';

export default defineComponent({
  setup() {
    return {
      readableDateTime,
    };
  },
  components: {
    Form,
    GoBadge,
    GoButton,
    GoCheckbox,
    GoTextField,
    DataBit,
    IconClipboard,
    IconTimes,
  },
  emits: ['resolved', 'deleted'],
  props: {
    error: {
      type: Object,
      required: true,
    },
    route: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      bumpupAmount: null,
      internal: false,
    };
  },
  computed: {
    errorSchema() {
      return yup.object().shape({
        reason: yup.string().required().label('Reason'),
        bumpupAmount: yup.number().required().label('Bump Up Amount'),
      });
    },
    price() {
      const change = this.internal ? 0 : Number(this.bumpupAmount || 0);
      return Number(this.route.price) + change;
    },
    pay() {
      return Number(this.route.pay) + Number(this.bumpupAmount || 0);
    },
    margin() {
      return ((this.price - this.pay) / this.price) * 100;
    },
    resolutionBumpup() {
      return this.error.resolution?.bumpUp;
    },
    resolutionInternal() {
      return this.error.resolution?.isInternal;
    },
    errorStyle() {
      const colors = {
        resolved: 'text-green-800 bg-green-200',
        unresolved: 'text-red-800 bg-red-200',
      };
      return colors[this.error.state];
    },
    errorBorder() {
      const colors = {
        resolved: 'border-green-200',
        unresolved: 'border-red-200',
      };
      return colors[this.error.state];
    },
  },
  methods: {
    async deleteError() {
      const confirmed = await showConfirmationModal({
        title: 'Confirm Adjustment Deletion',
        message: 'Are you sure you want to delete this adjustment?',
        confirmButtonText: 'Confirm',
        color: 'danger',
      });

      if (confirmed) {
        await aroundLoadingMessage('Deleting...', async () => {
          await apiService.admin.routes.deleteError(this.route.id, this.error.id);
          this.$emit('deleted');
        });
      }
    },
    async resolve(values) {
      await aroundLoadingMessage('Resolving...', async () => {
        await apiService.admin.routes.resolveError(this.route.id, this.error.id, values);
        this.$emit('resolved');
      });
    },
  },
});
</script>
