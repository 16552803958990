/* eslint-disable import/prefer-default-export */
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc'; // dependent on utc plugin
import { Merchant, Route } from '../types';
import { timeToNumber } from './availability-service';
import { diff } from './date-service';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);

export const unacceptedRoute = (
  route: Route,
  { bufferMinutes }: { bufferMinutes?: number } = {}
) => {
  if (route.state === 'completed') return null;
  if (route.state === 'accepted') return null;
  if (route.state === 'inProgress') return null;

  const options = {
    bufferMinutes: bufferMinutes || 60,
  };

  const now = dayjs();
  const pickupTime = dayjs(route.cutoffTime || undefined);
  const bufferTime = pickupTime.subtract(options.bufferMinutes, 'minute');
  if (!now.isSameOrAfter(bufferTime)) return null;

  const minutesToPickup = Math.abs(now.diff(pickupTime, 'minutes'));
  const message = `Unaccepted Route. Pickup in ${minutesToPickup} minutes. (Pickup Time: ${pickupTime.format(
    'h:mm A'
  )} Buffer: ${options.bufferMinutes} minutes)`;
  return message;
};

export const unstartedRoute = (
  route: Route,
  { bufferMinutes }: { bufferMinutes?: number } = {}
) => {
  if (route.state === 'completed') return null;
  if (route.state === 'inProgress') return null;

  const options = {
    bufferMinutes: bufferMinutes || 25,
  };

  const now = dayjs();
  const pickupTime = dayjs(route.cutoffTime || undefined);
  const bufferTime = pickupTime.subtract(options.bufferMinutes, 'minute');
  if (!now.isSameOrAfter(bufferTime)) return null;

  const minutesToPickup = Math.abs(now.diff(pickupTime, 'minutes'));
  const message = `Route not started. Pickup in ${minutesToPickup} minutes. (Pickup Time: ${pickupTime.format(
    'h:mm A'
  )} Buffer: ${options.bufferMinutes} minutes)`;
  return message;
};

export const timeTaken = (route: Route) => {
  const { waypoints } = route;
  const startedAt = waypoints?.find(point => !!point.arrivedAt)?.arrivedAt;
  if (!startedAt) return 0;

  const endedAt = (waypoints || [])
    .filter(point => point.subState !== 'cancelled')
    .slice()
    .reverse()
    .find(point => !!point.statusUpdatedAt)?.statusUpdatedAt;
  if (!endedAt) return 0;

  return diff(startedAt, endedAt);
};

export const routeBadgeStateStyle = (state: string): string => {
  const colors: Record<string, string> = {
    new: 'text-gray-800 bg-gray-200',
    offer: 'text-yellow-800 bg-yellow-100',
    declined: 'text-red-800 bg-red-100',
    accepted: 'text-blue-700 bg-blue-50',
    inProgress: 'text-blue-900 bg-blue-200',
    completed: 'text-green-800 bg-green-100',
  };
  return colors[state] || 'text-gray-700 bg-gray-100';
};

export const routeTimeNumbers = (route: Route): { startNumber: number; endNumber: number } => {
  if (!route.cutoffTime) {
    return {
      startNumber: 0,
      endNumber: 0,
    };
  }
  const startNumber = timeToNumber(dayjs(route.cutoffTime).format('HH:mm'));
  const endNumber = Number((startNumber + route.duration / 60).toFixed(2));
  return { startNumber, endNumber };
};

export const getRouteExcludedCouriersFromMerchant = (
  route: Route,
  merchantsById: Record<string, Merchant>
): string[] => {
  if (!route) return [];
  const merchantIds = route.waypoints?.map(wp => wp.associatedEmail) || [];
  const distinctMerchantIds = [...new Set(merchantIds)];

  const excludedCouriers = (distinctMerchantIds || []).map(
    id => merchantsById[id]?.excludedCouriers || []
  );
  return excludedCouriers.reduce((acc, excluded) => acc.concat(excluded || []), []);
};

export const orderIdForMerchant = (merchant: Merchant, orderId: string) => {
  // shopify order numbers start with #
  // we don't want those.
  const id = orderId
    .replace(/^#/, '')
    .replace(/\s+/g, '')
    .replace(/\//g, '')
    .replace(/\\/g, '')
    .replace(/\./g, '-');
  if (merchant.trackingKey && !id.startsWith(`${merchant.trackingKey}-`)) {
    return `${merchant.trackingKey}-${id}`;
  }
  return id;
};

export const modifyRecipientText = (type: string, text: string) => {
  if (type !== 'completed') return text;
  if (text.includes('{trackingLink}')) return text;

  return `${text} Delivery Details: \${trackingLink}`;
};
