<template>
  <div class="flex flex-col justify-center">
    <div class="bg-brand-1 px-4 md:mb-8">
      <div class="flex max-w-7xl mx-auto py-4 items-center">
        <a href="https://www.tyltgo.com" class="text-4xl font-logo text-gray-100">Tyltgo</a>
        <p class="ml-12 text-white font-header font-bold text-2xl">Courier Signup</p>
      </div>
    </div>
    <div class="sm:mx-auto sm:w-full sm:max-w-md px-4">
      <div
        v-if="$route.query.referredBy"
        class="mt-2 bg-teal-600 text-center text-white mb-4 rounded-md text-base py-3 px-2"
      >
        <h2>
          You were referred by {{ $route.query.name || 'a friend' }}! You will receive $50 each when
          you complete your first 5 routes!
        </h2>
      </div>
      <GoHeader :level="2" class="px-4 mt-8 text-center mb-4">
        Deliver with Tyltgo and earn on your own terms.
      </GoHeader>
      <div v-html="htmlText" class="prose prose-lg"></div>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 space-y-2">
        <Form @submit="signup" :validation-schema="schema">
          <div class="space-y-4 mb-4">
            <GoTextField name="email" type="email" placeholder="you@example.com" />
            <GoTextField name="password" type="password" />
            <GoTextField name="firstName" type="text" />
            <GoTextField name="lastName" type="text" />
            <GoTextField name="phoneNumber" type="text" v-model="courierPhoneNumber" />
            <GoTextField
              name="address"
              type="text"
              google-places
              @place-selected="placeSelected"
              v-model="address"
            />
            <GoTextField name="unitNumber" type="text" hint="if applicable" />
            <GoSelect name="typeOfCar" :options="carTypes" placeholder="Select Car Type" />
            <GoTextField
              :disabled="true"
              v-if="$route.query.referredBy"
              name="referredBy"
              v-model="referredBy"
            />
            <GoHiddenField v-if="$route.query.s" name="referredSource" v-model="referredSource" />
            <GoCheckbox name="agreement" value="true" position="start">
              <template #label>
                By clicking this box, you agree that you have read TyltGO's
                <a href="https://www.tyltgo.com/company/terms-conditions" target="_blank">
                  Terms of Service
                </a>
                and
                <a href="https://www.tyltgo.com/company/privacy-policy" target="_blank">
                  Privacy Policy
                </a>
                and agree to the terms and conditions within them.
              </template>
            </GoCheckbox>
          </div>
          <GoButton class="w-full">Signup</GoButton>
          <div v-if="failureMessage" class="rounded-md bg-red-50 p-4">
            <h3 class="text-sm font-medium text-red-800">
              {{ failureMessage }}
            </h3>
          </div>
        </Form>
      </div>
    </div>
    <GoMessages />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
import { apiService, authService, TypeOfCar, parsePhone } from '@tyltgo/shared';
import startCase from 'lodash/startCase';
import * as yup from 'yup';
import { marked } from 'marked';
import GoTextField from '../../components/GoTextField.vue';
import GoHiddenField from '../../components/GoHiddenField.vue';
import GoButton from '../../components/GoButton.vue';
import GoSelect from '../../components/GoSelect.vue';
import GoCheckbox from '../../components/GoCheckbox.vue';
import GoMessages from '../../components/GoMessages.vue';
import {
  aroundLoadingMessage,
  showLoadingMessage,
  stopLoadingMessage,
} from '../../services/ui-message-service';

const schema = yup.object().shape({
  email: yup.string().email().required().label('Email'),
  password: yup.string().required().label('Password'),
  firstName: yup.string().required().label('First Name'),
  lastName: yup.string().required().label('Last Name'),
  phoneNumber: yup.string().required().label('Phone Number'),
  address: yup.string().required().label('Address'),
  typeOfCar: yup.string().required().label('Type of Car'),
  agreement: yup.boolean().required().label('Agreement'),
});

export default defineComponent({
  setup() {
    return {
      startCase,
      schema,
      parsePhone,
    };
  },
  components: {
    Form,
    GoTextField,
    GoHiddenField,
    GoButton,
    GoSelect,
    GoCheckbox,
    GoMessages,
  },
  mounted() {
    this.referredBy = this.$route.query.referredBy;
    this.referredSource = this.$route.query.s;
  },
  data() {
    return {
      referredBy: null,
      referredSource: null,
      user: {},
      address: '',
      courierPhoneNumber: '',
      geolocation: {},
      failureMessage: null,
    };
  },
  computed: {
    htmlText() {
      return marked(`Thank you for your interest in signing up! Before you get started, you'll need the following documents to complete your application:
  - A valid driver's license
  - Your vehicle insurance
  - Your vehicle ownership
  - Proof of work eligibility (PR Card, Canadian Birth Certificate, Canadian Passport, or Work/Study Permit)`);
    },
    carTypes() {
      return Object.values(TypeOfCar).map(type => ({
        label: type,
        value: type,
      }));
    },
  },
  methods: {
    placeSelected(place, _name) {
      this.geolocation[place.formatted_address] = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      this.address = place.formatted_address;
    },
    async signup(values, { setFieldError }) {
      let errors = false;
      this.failureMessage = null;
      const geo = this.geolocation[values.address];
      if (!geo) {
        errors = true;
        setFieldError('address', 'Please select the address from the autocomplete dropdown.');
      }

      if (errors) return;

      try {
        showLoadingMessage('Loading ...');
        const { token } = await apiService.couriers.auth.signup({
          ...values,
          lat: geo.lat,
          lng: geo.lng,
        });
        await authService.loginSuccess(token);
        this.$router.push({ name: 'courier.signup.steps' });
      } catch (error) {
        stopLoadingMessage();
        this.failureMessage = error.response?.data?.message;
      }
    },
  },
});
</script>
