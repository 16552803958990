<template>
  <div
    v-if="configLoaded"
    class="relative overflow-auto min-h-screen font-inter w-screen"
    :class="config.backgroundColor"
  >
    <div class="max-w-7xl mx-auto lg:h-screen">
      <div
        class="relative z-10 pb-8 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32 h-full"
        :class="config.backgroundColor"
      >
        <svg
          class="hidden lg:block absolute right-0 inset-y-0 h-full w-48 transform translate-x-1/2"
          :style="`color: ${config.brandColor};`"
          fill="currentColor"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          aria-hidden="true"
        >
          <polygon points="50,0 100,0 50,100 0,100" />
        </svg>
        <div
          class="w-full py-2 bg-white sm:fixed sm:bottom-0 sm:left-0 sm:mt-12 sm:px-6 md:mt-16 lg:mt-16 lg:px-8 lg:pr-20 xl:mt-16 flex justify-between items-center sm:justify-around"
        >
          <a href="https://www.tyltgo.com" class="flex flex-row text-primary-900 px-4">
            <div class="flex flex-col justify-center text-xs w-1/3 sm:max-w-max mr-2">
              This Delivery Is Powered By
            </div>
            <div class="text-2xl font-bold italic">Tyltgo</div>
            <div class="flex flex-col justify-center text-xs italic ml-1">.com</div>
          </a>
          <a
            href="https://tyltgo.typeform.com/to/sp6kNM6T#source=tracking"
            class="flex flex-row text-primary-900 items-center justify-end text-xs px-4"
          >
            <IconBullhorn class="mr-3 h-4 w-4 flex-none flex-grow-0" />
            <span class="w-min sm:w-max">Refer a business!</span>
          </a>
        </div>
        <div>
          <div class="relative pt-6 sm:pt-12 px-4 sm:px-6 lg:px-8">
            <nav
              class="relative flex items-center justify-between sm:h-10 lg:justify-start"
              aria-label="Global"
            >
              <div class="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0 max-w-full">
                <div class="flex items-center justify-center w-full lg:w-auto">
                  <a
                    :href="config.businessLink"
                    class="text-5xl"
                    :class="config.logoFont"
                    :style="`color: ${config.brandColor};`"
                  >
                    <img
                      v-if="config.logoImageUrl"
                      class="max-w-full max-h-32 pt-4"
                      :src="config.logoImageUrl"
                      alt=""
                    />
                    <span v-else>
                      {{ config.businessName }}
                    </span>
                  </a>
                </div>
              </div>
            </nav>
          </div>
        </div>

        <div
          class="flex flex-col justify-between h-full mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-16 lg:px-8 lg:pr-20 xl:mt-16"
        >
          <slot :config="config"></slot>
        </div>
      </div>
    </div>
    <div
      class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2"
      :style="`background-color: ${config.brandColor};`"
    >
      <img
        v-if="config.largeImageUrl"
        class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
        :src="config.largeImageUrl"
        alt=""
      />
    </div>
  </div>
  <div v-else class="flex justify-center items-center min-w-full min-h-screen">
    <IconLoader class="animate-spin mx-2 h-44 w-44 text-primary-800" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { apiService } from '@tyltgo/shared';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isYesterday from 'dayjs/plugin/isYesterday';
import IconLoader from '../components/IconLoader.vue';

dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(isYesterday);

export default defineComponent({
  components: {
    IconLoader,
  },
  async beforeMount() {
    this.configLoaded = false;

    const { trackingSlug } = this.$route.params;
    if (trackingSlug) {
      const data = await apiService.tracking.config(<string>trackingSlug);
      console.log({ data });
      if (!data.config) {
        return;
      }

      this.config = {
        ...data.config,
        logoFont: 'font-inter',
      };
    } else {
      this.config = {
        background: 'light',
        businessName: 'Tyltgo',
        businessLink: 'https://tyltgo.com',
        logoImageUrl: '',
        largeImageUrl:
          'https://global-uploads.webflow.com/5ff49c50e9e1bb1d223efb8a/5ff4f9fe3cacc13964104e71_edit_victoria-kubiaki-t0Aio60jD4Q-unsplash.png',
        title: 'Track your order!',
        blurb: `Tyltgo couriers deliver to cities all across Southwestern Ontario, and British Columbia's Lower Mainland`,
        brandColor: 'rgba(32, 39, 70)',

        textColor: 'black',
        backgroundColor: 'bg-gray-100',
        grayColor: 'text-gray-500',
        logoFont: 'font-logo',
      };
    }

    if (this.config.background === 'dark') {
      this.config = {
        ...this.config,
        textColor: 'white',
        buttonTextColor: 'black',
        grayColor: 'text-gray-300',
        backgroundColor: 'bg-black',
        cardBackgroundColor: 'bg-gray-800',
      };
    } else {
      this.config = {
        ...this.config,
        textColor: 'black',
        buttonTextColor: 'white',
        grayColor: 'text-gray-500',
        backgroundColor: 'bg-gray-100',
        cardBackgroundColor: 'bg-white',
      };
    }

    this.configLoaded = !this.$route.name.includes('tipCourier');
  },
  data() {
    return {
      loading: null,
      config: null,
      configLoaded: false,
    };
  },
});
</script>
