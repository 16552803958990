<template>
  <main class="flex-1 overflow-y-auto focus:outline-none" tabindex="0">
    <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
      <div class="pt-10 pb-16" v-if="merchant">
        <MerchantCard :merchant="merchant" />

        <GoAlert
          v-if="!infoComplete"
          class="my-4"
          color="information"
          title="Complete Merchant Details"
          message="Set the merchant pricing, location(s), batch capacity for the next step."
        />

        <GoAlert
          v-if="showSignupLink"
          class="my-4"
          color="information"
          title="Merchant Signup Link"
        >
          <div class="text-sm text-blue-700">
            <p>Send the merchant this link so they can complete their signup:</p>
            <p>
              {{ signupLink }}
            </p>
          </div>
        </GoAlert>

        <div>
          <div class="">
            <div class="border-b border-gray-200 mb-4">
              <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                <router-link
                  v-for="(tab, key) in tabs"
                  :key="key"
                  :to="{
                    name: 'admin.merchant.tab',
                    params: { id: merchant.email, tab: key },
                  }"
                  class="whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                  :class="
                    currentTab && currentTab.name === tab.name
                      ? 'border-indigo-500 dark:border-indigo-400 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                  "
                  aria-current="page"
                >
                  {{ tab.name }}
                </router-link>
              </nav>
            </div>
            <div>
              <component
                v-if="currentTab"
                :is="currentTab.component"
                :merchant="merchant"
                @loadMerchant="loadMerchant"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent, markRaw } from 'vue';
import { apiService } from '@tyltgo/shared';
import Card from '../../components/MerchantCard.vue';
import AdminMerchantBatches from '../../components/AdminMerchantBatches.vue';
import AdminMerchantSummary from '../../components/AdminMerchantSummary.vue';
import AdminMerchantLocations from '../../components/AdminMerchantLocations.vue';
import AdminMerchantSettings from '../../components/AdminMerchantSettings.vue';
import AdminMerchantPricing from '../../components/AdminMerchantPricing.vue';
import AdminMerchantInvoicing from '../../components/AdminMerchantInvoicing.vue';
import AdminMerchantCredits from '../../components/AdminMerchantCredits.vue';
import AdminMerchantDebits from '../../components/AdminMerchantDebits.vue';
import AdminMerchantRawOrders from '../../components/AdminMerchantRawOrders.vue';
import AdminMerchantTextMessages from '../../components/AdminMerchantTextMessages.vue';
import AdminMerchantIntegrations from '../../components/AdminMerchantIntegrations.vue';
import GoAlert from '../../components/GoAlert.vue';

const tabs = {
  batches: {
    name: 'Batches',
    component: markRaw(AdminMerchantBatches),
  },
  summary: {
    name: 'Summary',
    component: markRaw(AdminMerchantSummary),
  },
  locations: {
    name: 'Locations',
    component: markRaw(AdminMerchantLocations),
  },
  settings: {
    name: 'Settings',
    component: markRaw(AdminMerchantSettings),
  },
  pricing: {
    name: 'Pricing',
    component: markRaw(AdminMerchantPricing),
  },
  invoicing: {
    name: 'Invoicing',
    component: markRaw(AdminMerchantInvoicing),
  },
  credits: {
    name: 'Credits',
    component: markRaw(AdminMerchantCredits),
  },
  debits: {
    name: 'Debits',
    component: markRaw(AdminMerchantDebits),
  },
  textMessages: {
    name: 'Text Messages',
    component: markRaw(AdminMerchantTextMessages),
  },
  integrations: {
    name: 'Integrations',
    component: markRaw(AdminMerchantIntegrations),
  },
  rawOrders: {
    name: 'Raw Orders',
    component: markRaw(AdminMerchantRawOrders),
  },
};

export default defineComponent({
  components: {
    MerchantCard: Card,
    GoAlert,
  },
  data() {
    return {
      merchant: null,
      loading: false,
      tabs,
      currentTab: null,
    };
  },
  async mounted() {
    const { tab } = this.$route.params;
    this.currentTab = tabs[tab] || tabs.batches;
    await this.loadMerchant();
  },
  watch: {
    async $route(to, from) {
      const { tab } = this.$route.params;
      if (to.name === 'admin.merchant.tab') {
        this.currentTab = tabs[tab] || tabs.batches;
      }
      if (to.name.startsWith('admin.merchant') && to.params.id !== from.params.id) {
        await this.loadMerchant();
        this.currentTab = null;
        setTimeout(() => {
          this.currentTab = tabs[tab] || tabs.batches;
        }, 10);
      }
    },
  },
  computed: {
    infoComplete() {
      // TODO: replace with shared addresses function
      if (!(this.merchant.businessAddresses.length + this.merchant.parentBusinessAddresses.length))
        return false;
      if (!this.merchant.settings.batchCapacity) return false;
      return true;
    },
    showSignupLink() {
      return this.infoComplete && this.merchant.signupAccessKey;
    },
    signupLink() {
      const resolved = this.$router.resolve({
        name: 'merchant.signup',
        params: { key: this.merchant.signupAccessKey },
      });
      const { protocol, host } = window.location;
      return `${protocol}//${host}${resolved.fullPath}`;
    },
  },
  methods: {
    async loadMerchant() {
      const { id } = this.$route.params;
      if (!id) return;
      this.loading = true;
      const { merchant } = await apiService.admin.merchants.get(id);
      this.merchant = merchant;
      this.loading = false;
    },
  },
});
</script>
