<template>
  <main class="flex-1 overflow-y-auto focus:outline-none" tabindex="0">
    <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
      <div class="pt-10 pb-16">
        <div class="px-4 sm:px-6 md:px-0 mb-8">
          <GoHeader :level="1">Admin Search</GoHeader>
        </div>
        <div class="px-4 sm:px-6 md:px-0">
          <div class="px-4 sm:px-6 md:px-0">
            <GoTextField
              :label="false"
              type="search"
              name="search"
              v-model="searchText"
              placeholder="Search"
              class="mb-4"
            />
          </div>
          <SearchPanel
            v-if="searchText"
            :searchResults="searchResults"
            :searchText="searchText"
            :searchLoading="searchLoading"
            :searchSet="searchSet"
            v-slot="{ hit }"
            startingTab="routes"
          >
            <WaypointCard
              :waypoint="hit._source"
              :routeId="hit._source.routeId"
              :forSearch="true"
              v-if="hit._index.includes('waypoint')"
            />

            <CourierCard
              :courier="hit._source"
              :forSearch="true"
              v-if="hit._index.includes('courier')"
            />

            <RouteCard :route="hit._source" v-if="hit._index.includes('routes')" />
            <MerchantCard :merchant="hit._source" v-if="hit._index.includes('merchants')" />
          </SearchPanel>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import debounce from 'lodash/debounce';
import { defineComponent } from 'vue';
import { apiService } from '@tyltgo/shared';
import GoTextField from '../../components/GoTextField.vue';
import SearchPanel from '../../components/SearchPanel.vue';
import WaypointCard from '../../components/WaypointCard.vue';
import CourierCard from '../../components/CourierCard.vue';
import RouteCard from '../../components/RouteCard.vue';
import MerchantCard from '../../components/MerchantCard.vue';

export default defineComponent({
  components: {
    GoTextField,
    SearchPanel,
    WaypointCard,
    CourierCard,
    RouteCard,
    MerchantCard,
  },
  data() {
    return {
      searchText: '',
      searchResults: {},
      searchLoading: false,
      searchSet: false,
    };
  },
  watch: {
    $route(to) {
      if (to.name === 'admin.search') {
        this.setFilters();
        this.search();
      }
    },
    searchText(searchText: string) {
      this.$router.push({ query: { ...this.$route.query, searchText } });
    },
  },
  async beforeMount() {
    this.setFilters();
    this.search();
  },
  methods: {
    setFilters() {
      this.searchText = this.$route.query.searchText;
    },
    search: debounce(async function search() {
      if (this.searchText.length < 2) return;
      const api = {
        routes: apiService.admin.routes.search,
        merchants: apiService.admin.merchants.search,
        couriers: apiService.admin.couriers.search,
      };

      this.searchLoading = true;
      const results = {};
      for (const [name, call] of Object.entries(api)) {
        // eslint-disable-next-line no-await-in-loop
        results[name] = await call(this.searchText);
      }
      this.searchLoading = false;
      this.searchSet = true;
      this.searchResults = results;
    }, 400),
  },
});
</script>
