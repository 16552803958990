<template>
  <div class="sm:p-4">
    <div class="text-center py-2">
      <h2 class="text-2xl mb-1">{{ options.title }}</h2>
      <h4 class="text-lg text-gray-600">{{ options.subtitle }}</h4>
    </div>
    <Form
      @submit="saveChanges"
      :initial-values="{
        availability: courier.availability,
      }"
      :validation-schema="schema"
    >
      <div class="space-y-3">
        <div class="bg-white pt-2 rounded-lg mb-4 flex flex-col p-4">
          <div class="mt-3 flex flex-col space-y-4">
            <CourierAvailabilityForm :courier="currentUser" />
          </div>
        </div>
      </div>
      <div class="p-8 text-center">
        <GoButton class="mt-4">Review & Submit</GoButton>
      </div>
    </Form>
  </div>
</template>

<script lang="ts">
import { apiService, availabilitySchema as schema } from '@tyltgo/shared/services';
import { defineComponent } from 'vue';
import startCase from 'lodash/startCase';
import { Form } from 'vee-validate';
import GoButton from './GoButton.vue';
import {
  addNotification,
  aroundLoadingMessage,
  showLoadingMessage,
  stopLoadingMessage,
} from '../services/ui-message-service';
import { currentUser } from '../services/auth-service';
import CourierAvailabilityForm from './CourierAvailabilityForm.vue';

export default defineComponent({
  emits: ['completed'],
  setup() {
    return {
      schema,
      currentUser,
      startCase,
    };
  },
  components: {
    GoButton,
    CourierAvailabilityForm,
    Form,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    courier: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      videoWatched: null,
    };
  },
  computed: {
    completed() {
      return this.courier.settingsSteps[this.name]?.completed || false;
    },
  },
  methods: {
    async confirmLastStep() {
      showLoadingMessage('Submitting ...');
      await apiService.couriers.profile.confirmLastStep(this.name);
      stopLoadingMessage();
      this.$emit('completed', this.name);
    },
    async saveChanges(values) {
      await aroundLoadingMessage('Saving...', async () => {
        try {
          await apiService.couriers.profile.updateAvailability(values);
          this.$emit('completed', this.name);
        } catch (e) {
          addNotification({
            color: 'danger',
            message: e.response?.data?.message || 'Error while updating availability.',
          });
        }
      });
    },
  },
});
</script>
