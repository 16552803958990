<template>
  <div class="max-w-4xl mx-auto px-1 pt-2 sm:px-6 lg:px-8" style="margin-bottom: 30rem">
    <div class="px-1 sm:px-6 md:px-0 mb-4 pt-8">
      <GoHeader :level="1">Routes</GoHeader>
    </div>
    <AdminRoutesSearchPage path="admin.routes" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AdminRoutesSearchPage from '../../components/AdminRoutesSearchPage.vue';

export default defineComponent({
  components: {
    AdminRoutesSearchPage,
  },
});
</script>
