/* eslint-disable import/prefer-default-export */

export const addressDetails = (address: string) => {
  // TODO: fix duplication. quick hack.
  const split = address.split(',');
  if (split.length === 4) {
    const [street, city, provincePostalCode, country] = address.split(', ');
    const [province, ...postalParts] = provincePostalCode.split(' ');
    return {
      street,
      city,
      province,
      postalCode: postalParts.join(' '),
      country,
      fsa: postalParts.join(' ').slice(0, 3),
    };
  }

  if (split.length === 5) {
    const [street, , city, provincePostalCode, country] = address.split(', ');
    const [province, ...postalParts] = provincePostalCode.split(' ');
    return {
      street,
      city,
      province,
      postalCode: postalParts.join(' '),
      country,
      fsa: postalParts.join(' ').slice(0, 3),
    };
  }

  if (split.length === 3) {
    const [city, provincePostalCode, country] = address.split(', ');
    const [province, ...postalParts] = provincePostalCode.split(' ');
    return {
      street: '',
      city,
      province,
      postalCode: postalParts.join(' '),
      country,
      fsa: postalParts.join(' ').slice(0, 3),
    };
  }

  throw new Error(`Improper Address: ${address}`);
};

const unitPrefixRegex = /(apt|suite|unit)\.?\s+#?(\s+)?\d+/i;

const cleanUnitNumber = (number: string) => {
  // eslint-disable-next-line no-param-reassign
  number = number.replace(/#/g, '').trim();
  if (/^\d+$/.test(number) || /^(u|l)?\.?p\.?h\.?\s*\d+$/i.test(number)) {
    return number;
  }
  return '';
};

export const extractUnitNumber = (address: string): { unitNumber?: string; address: string } => {
  const unitPrefixMatch = address.match(unitPrefixRegex);
  if (unitPrefixMatch) {
    const number = unitPrefixMatch[0];
    return {
      unitNumber: number,
      address: address.replace(number, '').replace(/^-/, ''),
    };
  }

  if (address.match(/-\s*\d+/)) {
    const [beforeDash, rest] = address.split('-');
    const cleanNumber = cleanUnitNumber(beforeDash);
    return {
      unitNumber: cleanNumber,
      address: cleanNumber ? rest.trim() : address,
    };
  }

  return { unitNumber: '', address };
};

export const fixAddressLines = (addressLine: string, addressLine2 = '') => {
  const { unitNumber, address } = extractUnitNumber(addressLine);

  // eslint-disable-next-line no-param-reassign
  addressLine = address;

  if (unitNumber) {
    // eslint-disable-next-line no-param-reassign
    addressLine2 = [`Unit: ${unitNumber}`, addressLine2].filter(x => !!x).join('-');
  }

  return [addressLine, addressLine2];
};

export const ottawaLinehaulFSAs = [
  'K0A',
  'K4M',
  'K4P',
  'K4R',
  'K7S',
  'K4K',
  'K6H',
  'K6J',
  'K6K',
  'K0C',
  'K1B',
  'K1C',
  'K1G',
  'K1J',
  'K1T',
  'K1W',
  'K1X',
  'K0B',
  'K6A',
  'K2K',
  'K2L',
  'K2M',
  'K2T',
  'K2V',
  'K2W',
  'K0G',
  'K7H',
  'K2C',
  'K2E',
  'K2G',
  'K2H',
  'K2J',
  'K2R',
  'K1E',
  'K4A',
  'K4B',
  'K4C',
  'K1A',
  'K1N',
  'K1P',
  'K1R',
  'K1S',
  'K2P',
  'K1K',
  'K1L',
  'K1M',
  'K1H',
  'K1V',
  'K1Y',
  'K1Z',
  'K2A',
  'K2B',
  'K7V',
  'K7A',
  'K7C',
  'K2S',
  'K6V',
  'K6T',
  'K0E',
  'K7N',
  'K7L',
  'K7G',
  'K7K',
  'K7M',
  'K7P',
  'K7R',
  'K0H',
  'J8T',
  'J8Y',
  'J8X',
  'J8Z',
  'J8R',
  'J8P',
  'J8V',
  'J9A',
  'J9J',
  'J9H',
  'J9B',
];

export const cambridgeLinehaulFSAs = [
  'L3M',
  'L8B',
  'L8E',
  'L8G',
  'L8H',
  'L8J',
  'L8K',
  'L8L',
  'L8M',
  'L8N',
  'L8P',
  'L8R',
  'L8S',
  'L8T',
  'L8V',
  'L8W',
  'L9A',
  'L9B',
  'L9C',
  'L9G',
  'L9H',
  'L9K',
  'L2A',
  'L2E',
  'L2G',
  'L2H',
  'L2J',
  'L2M',
  'L2N',
  'L2P',
  'L2R',
  'L2S',
  'L2T',
  'L2V',
  'L2W',
  'L0R',
  'L0S',
  'L3B',
  'L3C',
  'L3K',
  'N9V',
  'N3L',
  'N3P',
  'N3R',
  'N3S',
  'N3T',
  'N3V',
  'N0A',
  'N3W',
  'N3Y',
  'N4B',
  'N1A',
  'N1P',
  'N1R',
  'N1S',
  'N1T',
  'N3C',
  'N3E',
  'N3H',
  'N0P',
  'N7L',
  'N7M',
  'N0R',
  'N8M',
  'N0M',
  'N7G',
  'N1M',
  'N1C',
  'N1E',
  'N1G',
  'N1H',
  'N1K',
  'N1L',
  'N9Y',
  'N2A',
  'N2B',
  'N2C',
  'N2E',
  'N2G',
  'N2H',
  'N2K',
  'N2M',
  'N2N',
  'N2P',
  'N2R',
  'N9H',
  'N9J',
  'N8H',
  'N5V',
  'N5W',
  'N5X',
  'N5Y',
  'N5Z',
  'N6A',
  'N6B',
  'N6C',
  'N6E',
  'N6G',
  'N6H',
  'N6J',
  'N6K',
  'N6L',
  'N6M',
  'N6N',
  'N6P',
  'N0N',
  'N0E',
  'N7W',
  'N7S',
  'N7T',
  'N0L',
  'N5H',
  'N5L',
  'N5P',
  'N5R',
  'N7V',
  'N0K',
  'N4X',
  'N4Z',
  'N5A',
  'N2J',
  'N2L',
  'N2T',
  'N2V',
  'N0B',
  'N3A',
  'N3B',
  'N8N',
  'N8P',
  'N8R',
  'N8S',
  'N8T',
  'N8V',
  'N8W',
  'N8X',
  'N8Y',
  'N9A',
  'N9B',
  'N9C',
  'N9E',
  'N9G',
  'N9K',
  'N4T',
  'N4V',
  'N5C',
  'N0J',
  'N4G',
  'N4S',
  'N7X',
  'N8A',
];
