<template>
  <GoField
    v-bind="$attrs"
    type="checkbox"
    :value="value"
    :hint="false"
    label-location="after"
    class="flex"
    :class="positionValue"
    field-wrapper-class="relative flex items-start"
    element-wrapper-class="flex items-center h-5"
    after-wrapper-class="ml-3 mt-1"
    :show-error="showError"
    :uncheckedValue="uncheckedValue"
  >
    <template v-slot:default="{ field, id, errors }">
      <input
        v-bind="field"
        class="form-element h-4 w-4 text-indigo-600 rounded"
        :class="elementClasses(errors)"
        :id="id"
        type="checkbox"
        :value="value"
      />
    </template>
    <template v-if="$slots.label" #label>
      <slot name="label"></slot>
    </template>
  </GoField>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import GoField from './GoField.vue';

export default defineComponent({
  inheritAttrs: false,
  components: {
    GoField,
  },
  props: {
    value: {
      type: [String, Object, Boolean],
      required: true,
    },
    showError: {
      type: Boolean,
      default: true,
    },
    position: {
      type: String,
      default: 'center',
    },
    uncheckedValue: {
      type: [String, Object, Boolean],
      required: false,
    },
  },
  computed: {
    positionValue() {
      if (this.position === 'start') {
        return 'items-start';
      }
      return 'items-center';
    },
  },
  methods: {
    elementClasses(errors: string[]) {
      if (this.showError && errors.length) {
        return 'border-2 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500';
      }
      return 'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300';
    },
  },
});
</script>
