<template>
  <SearchPanel
    v-if="type === 'full' && searchText"
    :searchResults="searchResults"
    :searchText="searchText"
    :searchLoading="searchLoading"
    :searchSet="searchSet"
    v-slot="{ hit }"
    startingTab="routes"
  >
    <WaypointCard
      :waypoint="hit._source"
      :routeId="hit._source.routeId"
      :forSearch="true"
      v-if="hit._index.startsWith('waypoints-')"
    />

    <WaypointCard
      :waypoint="hit._source"
      :routeId="hit._source.routeId"
      :forSearch="true"
      :removed="true"
      v-if="hit._index.startsWith('removedwaypoints-')"
    />

    <CourierCard :courier="hit._source" :forSearch="true" v-if="hit._index.includes('courier')" />

    <RouteCard :route="hit._source" v-if="hit._index.includes('routes')" />
    <MerchantCard :merchant="hit._source" v-if="hit._index.includes('merchants')" />
  </SearchPanel>
  <div
    class="fixed h-screen flex flex-col"
    :class="{
      'w-64': type === 'full',
      'w-screen z-50': type === 'mobile',
    }"
  >
    <nav
      class="bg-brand-1 border-r border-gray-200 dark:border-gray-700 pt-5 pb-4 flex flex-col flex-grow overflow-y-auto text-white"
    >
      <div class="flex flex-row justify-between">
        <div class="flex-shrink-0 px-4 flex flex-col items-start">
          <h1 class="text-5xl font-logo text-gray-100">Tyltgo</h1>
          <h2 class="mt-2 text-base font-header text-gray-100">Admin Portal</h2>
        </div>
        <div class="px-4" v-if="type === 'mobile'">
          <IconTimes class="w-6 text-white" @click="$emit('close')" />
        </div>
      </div>
      <div class="pt-4 px-4 space-y-1">
        <div class="flex justify-between">
          <div>Vancouver ({{ timePacific.split(' ')[2] }})</div>
          <div>{{ timePacific.split(' ')[0] }} {{ timePacific.split(' ')[1] }}</div>
        </div>
        <div class="flex justify-between">
          <div>Calgary ({{ timeMountain.split(' ')[2] }})</div>
          <div>{{ timeMountain.split(' ')[0] }} {{ timeMountain.split(' ')[1] }}</div>
        </div>
        <div class="flex justify-between">
          <div>Toronto ({{ timeEastern.split(' ')[2] }})</div>
          <div>{{ timeEastern.split(' ')[0] }} {{ timeEastern.split(' ')[1] }}</div>
        </div>
      </div>
      <div class="flex-grow mt-5 flex flex-col">
        <div class="flex-1 space-y-1">
          <div class="p-4">
            <GoTextField
              :label="false"
              type="search"
              name="search"
              v-model="searchText"
              placeholder="Search"
            />
          </div>
          <router-link :to="{ name: 'admin.home' }" exact class="nav-link">
            <IconHome />
            Home
          </router-link>

          <router-link :to="{ name: 'admin.routes' }" class="nav-link">
            <svg
              class="mr-3 h-6 w-6 text-gray-300 group-hover:text-gray-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z"
              />
            </svg>
            Routes
          </router-link>

          <div>
            <div class="nav-link outer-nav-link">
              <IconCar />
              Couriers
            </div>
            <div>
              <router-link :to="{ name: 'admin.couriers' }" class="nav-link inner-nav-link">
                List
              </router-link>
              <router-link :to="{ name: 'admin.couriers.payouts' }" class="nav-link inner-nav-link">
                View Payouts
              </router-link>
              <router-link
                :to="{ name: 'admin.couriers.previewPayouts' }"
                class="nav-link inner-nav-link"
              >
                Preview Payouts
              </router-link>
            </div>
          </div>

          <div>
            <div class="nav-link outer-nav-link">
              <IconStore />
              Merchants
            </div>
            <div>
              <router-link :to="{ name: 'admin.merchants' }" class="nav-link inner-nav-link">
                List
              </router-link>
              <router-link
                :to="{ name: 'admin.merchants.invoices' }"
                class="nav-link inner-nav-link"
              >
                Invoices
              </router-link>
            </div>
          </div>

          <router-link
            :to="{
              name: 'admin.scheduling',
              query: {
                t: 1,
                c: {
                  state: ['approved', 'awaitingReapproval'],
                  availabilityStatus: 'active',
                },
                r: {
                  stateFilter: ['new', 'offer', 'declined'],
                },
              },
            }"
            class="nav-link"
          >
            <IconCalendar />
            Scheduling
          </router-link>
          <router-link :to="{ name: 'admin.reports' }" class="nav-link">
            <IconFile />
            Reports
          </router-link>
          <router-link :to="{ name: 'admin.incidents' }" class="nav-link">
            <IconIncident />
            Incidents
          </router-link>
        </div>
      </div>
      <div class="flex-shrink-0 block w-full">
        <router-link :to="{ name: 'admin.pricing' }" class="nav-link">
          <IconSearchDollar class="text-gray-300 group-hover:text-gray-500 mr-3 h-6 w-6" />
          Pricing
        </router-link>
        <router-link :to="{ name: 'admin.settings' }" class="nav-link">
          <IconCog class="text-gray-300 group-hover:text-gray-500 mr-3 h-6 w-6" />
          Settings
        </router-link>

        <a
          href="#"
          @click="logout"
          class="group py-2 px-4 flex items-center text-sm font-medium text-gray-200 hover:text-gray-900 hover:bg-gray-50"
        >
          <IconSignout class="text-gray-300 group-hover:text-gray-500 mr-3 h-6 w-6" />
          Logout
        </a>
      </div>
    </nav>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { apiService, authService } from '@tyltgo/shared';
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import { time } from '../services/date-service';
import IconHome from './IconHome.vue';
import IconStore from './IconStore.vue';
import IconTimes from './IconTimes.vue';
import IconFile from './IconFile.vue';
import IconCar from './IconCar.vue';
import IconSignout from './IconSignout.vue';
import IconCog from './IconCog.vue';
import IconSearchDollar from './IconSearchDollar.vue';
import IconCalendar from './IconCalendar.vue';
import GoTextField from './GoTextField.vue';
import SearchPanel from './SearchPanel.vue';
import RouteCard from './RouteCard.vue';
import WaypointCard from './WaypointCard.vue';
import MerchantCard from './MerchantCard.vue';
import CourierCard from './CourierCard.vue';

export default defineComponent({
  components: {
    IconHome,
    IconCar,
    IconStore,
    IconTimes,
    IconFile,
    IconSignout,
    IconCog,
    IconSearchDollar,
    IconCalendar,
    GoTextField,
    SearchPanel,
    RouteCard,
    WaypointCard,
    MerchantCard,
    CourierCard,
  },
  props: {
    type: {
      type: String,
      default: 'full',
    },
  },
  data() {
    return {
      searchText: '',
      searchResults: {},
      searchLoading: false,
      searchSet: false,
      interval: null,
      timeEastern: null,
      timePacific: null,
      timeMountain: null,
    };
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  created() {
    const clock = () => {
      const timeStamp = dayjs().toString();
      this.timeEastern = time(timeStamp, 'America/Toronto');
      this.timeMountain = time(timeStamp, 'America/Edmonton');
      this.timePacific = time(timeStamp, 'America/Vancouver');
    };
    clock();

    this.interval = setInterval(() => {
      clock();
    }, 1000);
  },
  watch: {
    $route(_to, _from) {
      // this is to remove the search panel when the route changes.
      this.searchText = '';
    },
    searchText(value: string) {
      if (value) {
        this.search(value);
      } else {
        this.searchResults = {};
        this.searchSet = false;
      }
    },
  },
  methods: {
    async logout() {
      await authService.logout();
      this.$router.push({ name: 'admin.login' });
    },
    search: debounce(async function search(searchText: string) {
      if (searchText.length < 2) return;
      const api = {
        routes: apiService.admin.routes.search,
        merchants: apiService.admin.merchants.search,
        couriers: apiService.admin.couriers.search,
        deletedRoutes: apiService.admin.routes.deletedSearch,
      };

      this.searchLoading = true;
      const results = {};
      for (const [name, call] of Object.entries(api)) {
        // eslint-disable-next-line no-await-in-loop
        results[name] = await call(searchText);
      }
      this.searchLoading = false;
      this.searchSet = true;
      this.searchResults = results;
    }, 400),
  },
});
</script>

<style lang="scss" scoped>
.nav-link {
  @apply border-transparent text-gray-200 border-l-4 py-2 px-3 flex items-center text-sm font-medium group;

  &.outer-nav-link {
    &:hover {
      @apply text-gray-200;
    }
  }

  &.inner-nav-link {
    @apply pl-12;
  }

  &:hover:not(.outer-nav-link) {
    @apply text-gray-900 bg-gray-50;
  }

  &.router-link-active {
    @apply bg-primary-50 border-primary-600 text-primary-800;
    svg {
      @apply text-primary-700;
    }
  }

  svg {
    @apply text-gray-300 h-6 w-6 mr-3;
  }
}
</style>
