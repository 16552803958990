<template>
  <main class="flex-1 overflow-y-auto focus:outline-none" tabindex="0">
    <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
      <div class="pt-10 pb-16">
        <div class="px-4 sm:px-6 md:px-0">
          <GoHeader :level="1">Billing</GoHeader>
        </div>
        <div class="px-4 sm:px-6 md:px-0 pt-10">
          <div class="flex flex-col">
            <div class="-my-2 sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50 dark:bg-gray-700">
                      <tr>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
                        >
                          Billing Period
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
                        >
                          Price
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
                        >
                          Taxes (HST)
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
                        >
                          Total
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
                        >
                          # Orders
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
                        >
                          Receipt
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      class="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-500"
                    >
                      <tr v-for="charge of charges" :key="charge.id">
                        <td
                          class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-200"
                        >
                          {{ charge.billingPeriod }}
                        </td>
                        <td
                          class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300"
                        >
                          ${{ parseFloat(charge.price).toFixed(2) }}
                        </td>
                        <td
                          class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300"
                        >
                          ${{
                            (parseFloat(charge.priceWithTax) - parseFloat(charge.price)).toFixed(2)
                          }}
                        </td>
                        <td
                          class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300"
                        >
                          ${{ parseFloat(charge.priceWithTax).toFixed(2) }}
                        </td>
                        <td
                          class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300"
                        >
                          {{ charge.numberOfOrders }}
                        </td>
                        <td
                          class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-2"
                        >
                          <GoButton
                            as="router-link"
                            variant="outline"
                            class="text-xs dark:text-gray-700"
                            :to="{ name: 'merchant.invoice', params: { id: charge.id } }"
                            target="_blank"
                          >
                            View
                          </GoButton>
                          <GoButton @click="showPdf(charge)" variant="outline" class="text-xs">
                            Download
                          </GoButton>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import sortBy from 'lodash/sortBy';
import { apiService } from '@tyltgo/shared';
import GoButton from '../../components/GoButton.vue';
import { showConfirmationModal } from '../../services/ui-message-service';

export default defineComponent({
  components: {
    GoButton,
  },
  async mounted() {
    this.loading = true;
    this.charges = sortBy(await apiService.merchants.billing.all(), 'created');
    this.loading = false;
  },
  data() {
    return {
      loading: false,
      charges: null,
    };
  },
  methods: {
    async showPdf(charge: any) {
      const url = await apiService.merchants.billing.pdf(charge.id);
      if (url) {
        window.open(url);
      } else {
        showConfirmationModal({
          title: `PDF Not Found`,
          message: `Please contact the Tyltgo team for assistance.`,
          color: 'danger',
        });
      }
    },
    async showInvoice(charge: any) {
      this.$router.push();
    },
  },
});
</script>
