<template>
  <div class="flex flex-col items-center space-y-4" v-if="documentOptions">
    <div class="text-center py-2">
      <h2 class="text-3xl mb-1">{{ options.title }}</h2>
      <h4 class="text-lg text-gray-600">{{ options.subtitle }}</h4>
    </div>
    <div v-if="htmlText" v-html="htmlText" class="prose" />
    <div
      v-if="uploaded"
      class="h-64 flex flex-col items-center justify-end p-4 space-y-4"
      @click="clickUpload"
    >
      <DocumentImage v-if="imageData" :imageData="imageData" :alt="name" class="h-3/4" />
      <GoUploadButton
        id="image_upload"
        ref="uploadButton"
        accept=".png,.jpeg,.jpg,.pdf"
        variant="link"
        @filesSelected="fileSelected"
        @click="buttonClicked = true"
      >
        Change file
      </GoUploadButton>
    </div>
    <div
      v-if="!uploaded"
      class="flex flex-col items-center justify-center space-y-8 rounded-md w-96 h-96 border-2 border-dashed border-gray-400"
      @click="clickUpload"
    >
      <DocumentImage
        v-if="imageToUpload"
        :imageData="imageToUpload"
        :alt="name"
        class="p-4 h-3/4"
      />

      <GoUploadButton
        id="image_upload"
        accept=".png,.jpeg,.jpg,.pdf"
        ref="uploadButton"
        variant="link"
        @filesSelected="fileSelected"
        @click="buttonClicked = true"
      >
        {{ imageToUpload ? 'Change' : 'Select' }} file
      </GoUploadButton>
    </div>

    <div class="p-4">
      <Form ref="form" @submit="submit" class="space-y-4" :initial-values="initialValues">
        <GoSelect
          v-if="documentTypeRequired"
          label="Document Type"
          :name="documentTypeField"
          v-model="documentType"
          :options="documentOptions.documentTypes"
          label-field="name"
          value-field="name"
          placeholder="Select a document"
        />
        <GoTextField
          v-if="expiryRequired"
          label="Expiry Date"
          :name="expiryField"
          v-model="expiryDate"
          type="date"
        />
        <GoButton :disabled="!canSubmit">Review & Submit</GoButton>
      </Form>
    </div>
  </div>
</template>

<script lang="ts">
import { apiService } from '@tyltgo/shared/services/api-service';
import { defineComponent } from 'vue';
import { marked } from 'marked';
import { Form } from 'vee-validate';
import { courierDocuments } from '@tyltgo/shared';
import GoButton from './GoButton.vue';
import GoSelect from './GoSelect.vue';
import GoTextField from './GoTextField.vue';
import GoUploadButton from './GoUploadButton.vue';
import DocumentImage from './DocumentImage.vue';
import { showLoadingMessage, stopLoadingMessage } from '../services/ui-message-service';

export default defineComponent({
  emits: ['completed'],
  components: {
    DocumentImage,
    GoButton,
    GoSelect,
    GoUploadButton,
    GoTextField,
    Form,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
    courier: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      buttonClicked: null,
      imageData: null,
      imageToUpload: null,
      expiryDate: null,
      documentOptions: null,
      documentType: null,
    };
  },
  watch: {
    options() {
      this.$refs.form.setValues(this.initialValues);
      this.imageData = null;
      this.imageToUpload = null;
      this.setupDocument();
    },
  },
  mounted() {
    this.setupDocument();
  },
  computed: {
    initialValues() {
      return {
        [this.expiryField]: this.courier.documents[this.name].expiryDate,
        [this.documentTypeField]: this.courier.documents[this.name].documentType,
      };
    },
    expiryRequired() {
      if (this.documentTypeRequired) {
        return this.documentOptions.documentTypes.find(type => type.name === this.documentType)
          ?.expiry;
      }
      return this.documentOptions.expiry;
    },
    expiryField() {
      return `${this.documentOptions.baseField}Expiry`;
    },
    documentTypeRequired() {
      return !!this.documentOptions.documentTypes;
    },
    documentTypeField() {
      return `${this.documentOptions.baseField}Type`;
    },
    uploaded() {
      return !this.imageToUpload && this.courier.documents[this.name].uploaded;
    },
    base64Src() {
      if (!this.imageData?.base64Image) return undefined;

      return `data:${this.imageData.contentType};base64,${this.imageData.base64Image}`;
    },
    htmlText() {
      if (!this.options.markdownText) return null;

      return marked(this.options.markdownText);
    },
    canSubmit() {
      if (!(this.imageToUpload || this.imageData)) return false;
      if (this.documentTypeRequired && !this.documentType) return false;
      if (this.expiryRequired && !this.expiryDate) return false;
      return true;
    },
  },
  methods: {
    setupDocument() {
      this.expiryDate = this.courier.documents[this.name].expiryDate;
      this.documentType = this.courier.documents[this.name].documentType;
      this.documentOptions = courierDocuments[this.name];
      this.getImageData();
    },
    async getImageData() {
      if (!this.uploaded) return;
      const data = await apiService.couriers.profile.documentImage(this.name);
      this.imageData = data;
    },
    fileSelected([file]) {
      const base64 = URL.createObjectURL(file);
      this.imageToUpload = {
        file,
        imageUrl: base64,
        base64Image: '',
        contentType: file.type,
      };
    },
    async submit(values) {
      const formData = new FormData();
      if (this.imageToUpload) {
        formData.append('file', this.imageToUpload.file);
      }
      formData.append('name', this.name);
      formData.append('values', JSON.stringify(values));
      showLoadingMessage('Uploading ...');
      await apiService.couriers.profile.updateDocument(formData as any);
      stopLoadingMessage();
      this.$emit('completed', this.name);
    },
    clickUpload() {
      if (!this.buttonClicked) {
        this.$refs.uploadButton.trigger();
      }
      // NOTE: this convolution is done
      // to prevent a double click on the
      // file uploaded button (it opens the file browser twice)
      // and still trigger it when you click on the larger div or image.
      setTimeout(() => {
        this.buttonClicked = null;
      }, 100);
    },
  },
});
</script>
