<template>
  <object
    v-bind="$attrs"
    v-if="imageData.contentType === 'application/pdf'"
    :data="imageData.imageUrl"
    :type="imageData.contentType"
    style="width: inherit; height: inherit"
  >
    <embed :src="imageData.imageUrl" :type="imageData.contentType" />
  </object>
  <img v-else :src="base64Src || imageData.imageUrl" v-bind="$attrs" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  inheritAttrs: false,
  props: {
    imageData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    base64Src() {
      if (!this.imageData?.base64Image) return undefined;

      return `data:${this.imageData.contentType};base64,${this.imageData.base64Image}`;
    },
  },
});
</script>
