<template>
  <div
    class="bg-white dark:bg-gray-800 pt-2 shadow rounded-lg mb-4 flex border-l-4 flex-col"
    :class="stateBorder"
  >
    <div class="w-full pb-2 px-4">
      <div class="flex flex-col lg:justify-between lg:items-center lg:flex-row-reverse">
        <div class="flex items-center space-x-2">
          <p class="text-gray-500 dark:text-gray-400 text-sm">{{ route.createdAtDisplay }}</p>
          <GoBadge :class="stateStyle">
            {{ state }}
          </GoBadge>
          <GoDropdown>
            <template v-slot:button>
              <IconEllipsis class="flex-none w-3 text-gray-600 dark:text-gray-300" />
            </template>
            <template v-slot:items>
              <GoDropdownItem
                v-if="viewDetails"
                @click="$router.push({ name: 'merchant.batch', params: { id: route.id } })"
              >
                View Details
              </GoDropdownItem>
              <GoDropdownItem @click="downloadLabels('zpl')">ZPL Labels</GoDropdownItem>
              <GoDropdownItem @click="downloadLabels('pdf')">PDF Labels</GoDropdownItem>
              <GoDropdownItem @click="downloadCsv">Download CSV</GoDropdownItem>
            </template>
          </GoDropdown>
        </div>
        <div class="flex flex-col lg:flex-row lg:space-x-4 mb-1">
          <p class="text-lg">
            <router-link :to="`/merchant/batches/${route.id}`">Batch {{ route.id }}</router-link>
          </p>
          <div class="space-x-2" v-if="viewDetails">
            <GoButton as="router-link" :to="`/merchant/batches/${route.id}`" variant="badge">
              View details
            </GoButton>
          </div>
        </div>
      </div>
      <div class="flex flex-col lg:flex-row lg:justify-between">
        <div class="w-1/2">
          <div class="flex space-x-2 text-sm" v-if="route.routeNumber">
            Batch Number #{{ route.routeNumber }}
          </div>
          <div class="flex flex-row space-x-3">
            <GoTooltip text="Dropoffs" class="flex space-x-2">
              <IconBoxOpen class="w-4 text-gray-600 dark:text-gray-300" />
              <div v-if="route.waypoints">
                <span class="text-sm" v-if="['completed', 'inProgress'].includes(route.state)">
                  {{ completedWaypointsFor(pickup).length }} /
                </span>
                <span class="text-sm">
                  {{ dropoffsFor(pickup).length }}
                </span>
              </div>
              <div v-else>
                <span class="text-sm">
                  {{ route.dropoffCount }}
                </span>
              </div>
            </GoTooltip>
            <GoTooltip text="Total Quantity" class="flex space-x-2" v-if="route.waypoints">
              <IconBoxesStacked class="w-4 text-gray-600 dark:text-gray-300" />
              <div>
                <span class="text-sm">
                  {{ dropoffsFor(pickup).reduce((acc, dropoff) => acc + dropoff.quantity, 0) }}
                </span>
              </div>
            </GoTooltip>
            <GoTooltip
              v-if="groupedDropoffsFor(pickup)['Unable To Deliver']?.length"
              text="Unable To Deliver"
              class="flex space-x-2"
            >
              <IconBackspace class="w-4 text-red-600 dark:text-gray-300" />
              <div>
                <span class="text-sm">
                  {{ groupedDropoffsFor(pickup)['Unable To Deliver'].length }}
                </span>
              </div>
            </GoTooltip>
            <GoTooltip
              text="Cancelled"
              class="flex space-x-2"
              v-if="groupedDropoffsFor(pickup)['Cancelled']?.length"
            >
              <IconTimesCircle class="w-4 text-red-600" />
              <div>
                <span class="text-sm">
                  {{ groupedDropoffsFor(pickup)['Cancelled'].length }}
                </span>
              </div>
            </GoTooltip>
            <GoTooltip
              v-if="groupedDropoffsFor(pickup)['Missing']?.length"
              text="Missing"
              class="flex space-x-2"
            >
              <IconBan class="w-4 text-red-600 dark:text-gray-300" />
              <div>
                <span class="text-sm">
                  {{ groupedDropoffsFor(pickup)['Missing'].length }}
                </span>
              </div>
            </GoTooltip>
            <GoTooltip
              text="Left At Door"
              class="flex space-x-2"
              v-if="groupedDropoffsFor(pickup)['Door']?.length"
            >
              <IconDoor class="w-4 text-gray-600 dark:text-gray-300" />
              <div>
                <span class="text-sm">
                  {{ groupedDropoffsFor(pickup)['Door'].length }}
                </span>
              </div>
            </GoTooltip>
          </div>
        </div>
        <div class="flex space-x-4">
          <DataBit title="Date" :value="route.routeDate" />
          <DataBit title="Distance" :value="`${route.distanceKm.toFixed(2)} km`" />
          <DataBit title="Price" :value="`$${price.toFixed(2)}`" />
          <DataBit
            v-if="additionalCharge > 0"
            title="Additional Charge"
            :value="`$${additionalCharge.toFixed(2)}`"
          />
        </div>
      </div>
    </div>
    <div class="w-full py-2 px-4 border-t-2">
      <div class="flex flex-col lg:flex-row lg:justify-between">
        <div class="flex flex-col">
          <p class="space-x-2 flex items-center text-gray-500 dark:text-gray-300 text-sm">
            {{ route.pickupAddress }}
          </p>
          <div class="flex space-x-2 items-center"></div>
        </div>
        <div class="flex space-x-4" v-if="pickup">
          <DataBit title="Batch Number" :value="route.routeNumber" v-if="route.routeNumber" />
          <DataBit
            title="Pickup Time"
            :value="pickupRange(pickup.cutoffTime, timeZoneId)"
            v-if="pickup.cutoffTime"
          />
          <DataBit
            title="Arrived At"
            :value="time(pickup.arrivedAt, timeZoneId)"
            v-if="pickup.arrivedAt"
          />
          <DataBit
            title="Picked At"
            :value="time(pickup.statusUpdatedAt, timeZoneId)"
            v-if="pickup.statusUpdatedAt"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  apiService,
  convertZplToPdf,
  dropoffsForRoute,
  Merchant,
  Waypoint,
  DropoffWaypoint,
  waypointDisplayState,
  waypointZpl,
} from '@tyltgo/shared';
import { defineComponent } from 'vue';
import groupBy from 'lodash/groupBy';
import { unparse } from 'papaparse';
import dayjs from 'dayjs';
import { currentUser } from '@/services/auth-service';
import IconBoxOpen from './IconBoxOpen.vue';
import IconDoor from './IconDoor.vue';
import IconBackspace from './IconBackspace.vue';
import IconEllipsis from './IconEllipsis.vue';
import GoBadge from './GoBadge.vue';
import GoButton from './GoButton.vue';
import DataBit from './DataBit.vue';
import GoDropdown from './GoDropdown.vue';
import GoDropdownItem from './GoDropdownItem.vue';
import { pickupRange, time, timezoneConversionRouteInput } from '../services/date-service';
import { downloadBlob } from '../services/browser-service';
import { routeToCsvHash } from '../services/csv-service';

export default defineComponent({
  setup() {
    return {
      dayjs,
      time,
      timezoneConversionRouteInput,
      pickupRange,
      currentUser,
    };
  },
  components: {
    IconBoxOpen,
    IconDoor,
    IconBackspace,
    IconEllipsis,
    GoBadge,
    GoButton,
    DataBit,
    GoDropdown,
    GoDropdownItem,
  },
  props: {
    route: {
      type: Object,
      required: true,
    },
    viewDetails: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      timeZoneId: this.route.timeZoneId,
    };
  },
  async created() {
    if (this.route.timeZoneId === undefined) {
      const zone = await timezoneConversionRouteInput(this.route, 'merchant');
      this.timeZoneId = zone.timeZoneId;
    }
  },
  computed: {
    additionalCharge() {
      const resolvedErrorsWithExternalBump =
        this.route.errors?.filter(
          x => x.isResolved && !x.resolution.isInternal && Number(x.resolution.bumpUp || 0) > 0
        ) || [];
      return resolvedErrorsWithExternalBump.reduce(
        (total: number, resolvedError) => total + Number(resolvedError.resolution.bumpUp || 0),
        0
      );
    },
    price() {
      return (this.route.price ?? 0) - this.additionalCharge;
    },
    pickup() {
      return this.pickupPoints[0];
    },
    pickupPoints(): Waypoint[] {
      if (!this.route) {
        return [];
      }

      return this.route.waypoints?.filter(x => x.type === 'pickup') || [];
    },
    completedWaypoints(): Waypoint[] {
      return this.route.waypoints?.filter(route => route.state === 'completed') || [];
    },
    stateStyle() {
      const colors = {
        completed: 'text-green-800 bg-green-200',
        'in progress': 'text-blue-800 bg-blue-200',
        new: 'text-gray-800 bg-gray-200',
        offer: 'text-gray-800 bg-gray-200',
        'courier assigned': 'text-blue-700 bg-blue-50',
      };
      return colors[this.state];
    },
    stateBorder() {
      const colors = {
        completed: 'border-green-200',
        'in progress': 'border-blue-200',
        new: 'border-gray-200',
        offer: 'border-gray-200',
        'courier assigned': 'border-blue-50',
      };
      return colors[this.state];
    },
    state() {
      const { state } = this.route;
      if (state === 'inProgress') {
        return 'in progress';
      }
      if (state === 'completed') {
        return 'completed';
      }
      if (state === 'accepted') {
        return 'courier assigned';
      }
      return 'new';
    },
  },
  methods: {
    groupedDropoffsFor(waypoint: Waypoint) {
      return groupBy(this.dropoffsFor(waypoint), waypointDisplayState);
    },
    completedWaypointsFor(waypoint: Waypoint) {
      return this.completedWaypoints.filter(
        point => point.associatedOrder === waypoint.associatedOrder && point.type === 'dropoff'
      );
    },
    dropoffsFor(waypoint: Waypoint): DropoffWaypoint[] {
      return (
        this.route.waypoints?.filter(
          x => x.associatedOrder === waypoint.associatedOrder && x.type === 'dropoff'
        ) || []
      );
    },
    bringBacksFor(waypoint: Waypoint): DropoffWaypoint[] {
      return (
        this.route.waypoints?.filter(
          x => x.associatedOrder === waypoint.associatedOrder && x.subState === 'bringBack'
        ) || []
      );
    },
    async downloadCsv() {
      const rows = routeToCsvHash(this.route, 'merchant', this.currentUser);
      const blob = new Blob([unparse(rows)], { type: 'text/csv' });
      downloadBlob(blob, `batch-${this.route.id}.csv`);
    },
    async downloadLabels(fileType: 'pdf' | 'zpl') {
      const merchant = this.currentUser;
      let blob = new Blob([this.createZplLabel(merchant)], { type: 'x-application/zpl' });

      if (fileType === 'pdf') {
        const pdfData = await convertZplToPdf(blob);
        blob = new Blob([pdfData], { type: 'application/pdf' });
      }
      downloadBlob(blob, `${this.route.routeDate}-${this.route.id}.${fileType}`);
    },
    createZplLabel(merchant: Merchant) {
      return dropoffsForRoute(this.route)
        .map(dropoff =>
          waypointZpl({
            route: this.route,
            waypoint: dropoff,
            merchant,
            toChangeTimezone: this.timeZoneId,
          })
        )
        .join('');
    },
  },
});
</script>
