<template>
  <div
    class="bg-white dark:bg-gray-800 py-2 shadow rounded-sm mb-4 flex border-l-4 dark:border-gray-500 flex-col space-y-2"
    :id="courier.id"
  >
    <div class="flex flex-col sm:flex-row justify-between px-4">
      <router-link :to="{ name: 'admin.courier', params: { id: courier.email } }">
        {{ courier.firstName }} {{ courier.lastName }}
      </router-link>
      <div class="flex items-center space-x-4">
        <GoBadge v-if="isNewCourier" class="bg-red-200 text-red-700">New Courier</GoBadge>
        <GoTooltip v-if="courier.tier" :text="`${tierReason}`">
          <GoBadge :class="courierTierStyle(courier)">Tier {{ courier.tier }}</GoBadge>
        </GoTooltip>
        <GoBadge v-if="courier.schedulingGroup" :class="courierSchedulingStyle(courier)">
          {{ startCase(courier.schedulingGroup) }}
        </GoBadge>
        <GoBadge :class="courierStyle(courier)">
          {{ courierDisplayState(courier) }}
        </GoBadge>
        <GoDropdown>
          <template v-slot:button>
            <IconEllipsis class="flex-none w-3 text-gray-600 dark:text-gray-300" />
          </template>
          <template v-slot:items>
            <GoDropdownItem
              @click="$router.push({ name: 'admin.courier', params: { id: courier.id } })"
            >
              View Details
            </GoDropdownItem>
            <GoDropdownItem @click="loginAs">Login As</GoDropdownItem>
            <GoDropdownItem @click="updateSuspended(courier.state !== 'suspended')">
              {{ startCase(suspendAction) }}
            </GoDropdownItem>
            <GoDropdownItem v-if="!['onHold'].includes(courier.state)" @click="moveToOnHold">
              Move to Hold
            </GoDropdownItem>
            <GoDropdownItem
              v-if="!['pendingTraining', 'suspended'].includes(courier.state)"
              @click="moveToTraining"
            >
              Move to Training
            </GoDropdownItem>
            <GoDropdownItem
              v-if="!['approved', 'suspended'].includes(courier.state)"
              @click="approve"
            >
              Approve
            </GoDropdownItem>
            <GoDropdownItem v-if="['awaitingApproval'].includes(courier.state)" @click="reject">
              Reject
            </GoDropdownItem>
          </template>
        </GoDropdown>
      </div>
    </div>
    <div class="flex flex-wrap gap-2 justify-between px-4">
      <div class="w-1/2">
        <div class="flex flex-col sm:flex-row space-x-4">
          <p class="text-sm">
            {{ courier.email }}
          </p>
          <div
            class="flex flex-col sm:flex-row sm:space-x-2 sm:items-center justify-between text-sm text-gray-700 dark:text-gray-300"
          >
            <span>
              {{ courier.phoneNumber }}
            </span>
            <a target="__blank" :href="`https://my.openphone.co/?search=${courier.phoneNumber}`">
              <IconPhone class="w-3" />
            </a>
          </div>
        </div>
        <div class="flex flex-col sm:flex-row justify-between">
          {{ courier.address }}
        </div>
        <DataBit class="mt-3" title="Availability" :left-align="true">
          <div class="flex flex-col space-y-2">
            <div v-if="courier.availabilityStatus === 'paused'" class="flex flex-row space-x-2">
              <IconTimesCircle class="w-3 text-red-500" />
              <div>Paused</div>
            </div>
            <div v-if="routeDate">
              <div
                v-for="[available, blocks] of Object.entries(availabilitiesForDate(routeDate))"
                :key="available"
                class="flex flex-row space-x-2"
              >
                <IconCheckCircle v-if="available === 'true'" class="w-3 text-green-500" />
                <IconTimesCircle v-else class="w-3 text-red-500" />

                <div>{{ startCase(dateToDay(routeDate)) }}s</div>
                <div>{{ blockToString(blocks) }}</div>
              </div>
            </div>
            <div v-else>
              <div v-for="[available, days] of Object.entries(daysAvailable())" :key="available">
                <div v-if="days.length" class="flex flex-row space-x-2 items-start">
                  <IconCheckCircle v-if="available === 'true'" class="w-3 text-green-500 mt-1" />
                  <IconTimesCircle v-else class="w-3 text-red-500 mt-1" />

                  <div>{{ days.join(', ') }}</div>
                </div>
              </div>
            </div>
          </div>
        </DataBit>
        <DataBit
          v-if="courier.settings.capabilities?.length"
          class="mt-3"
          title="Capabilities"
          :left-align="true"
        >
          <GoBadge
            v-for="capability of courier.settings.capabilities"
            :key="capability"
            class="bg-blue-100 text-blue-800"
          >
            {{ startCase(capability) }}
          </GoBadge>
        </DataBit>
      </div>
      <div>
        <div class="flex space-x-4 flex-wrap justify-end mb-4">
          <DataBit title="Signup" :left-align="false">
            <div class="flex space-x-2 justify-end">
              <div
                v-for="[name, item] of Object.entries(courier.training)
                  .concat(Object.entries(courier.settingsSteps))
                  .concat(Object.entries(courier.lastSteps))"
                :key="name"
              >
                <GoTooltip
                  :text="`${startCase(name)} - ${item.completed ? 'Completed' : 'Incomplete'}`"
                >
                  <IconCheckCircle v-if="item.completed" class="w-3 text-green-500" />
                  <IconTimesCircle v-if="!item.completed" class="w-3 text-red-500" />
                </GoTooltip>
              </div>
            </div>
          </DataBit>
          <DataBit title="Agreements" :left-align="false">
            <div class="flex space-x-2 justify-end">
              <div v-for="[name, item] of Object.entries(courier.agreements)" :key="name">
                <GoTooltip
                  :text="`${startCase(name)} - ${item.completed ? 'Completed' : 'Incomplete'}`"
                >
                  <IconCheckCircle v-if="item.completed" class="w-3 text-green-500" />
                  <IconTimesCircle v-if="!item.completed" class="w-3 text-red-500" />
                </GoTooltip>
              </div>
            </div>
          </DataBit>
          <DataBit title="Documents">
            <div class="flex space-x-2">
              <div
                v-for="[name, document] of Object.entries(courier.documents)"
                :key="name"
                :class="courierStepTextStyle(document)"
              >
                <GoTooltip :text="`${startCase(name)} - ${courierDocumentDisplayState(document)}`">
                  <IconFileImage class="w-3" />
                </GoTooltip>
              </div>
            </div>
          </DataBit>
        </div>
        <div class="flex space-x-4 justify-end">
          <DataBit title="Vehicle Type">
            {{ courier.typeOfCar }}
          </DataBit>
          <DataBit title="Signup Date">
            {{ toDate(courier.signedUpAt) }}
          </DataBit>
          <DataBit title="Last Route Date">
            {{ toDate(courier.lastRouteDate) }}
          </DataBit>
          <DataBit title="Total Routes">
            {{ courier.totalRoutes }}
          </DataBit>
        </div>
      </div>
    </div>
    <div class="border-t-2 dark:border-gray-500 pt-2 px-4" v-if="courier.availabilityNote">
      <DataBit title="Availability Note" :leftAlign="true">
        <pre class="text-xs whitespace-pre-line">{{ courier.availabilityNote }}</pre>
      </DataBit>
    </div>
    <div class="border-t-2 dark:border-gray-500 pt-2 px-4" v-if="$slots.bottom">
      <slot name="bottom"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import {
  courierDisplayState,
  courierStyle,
  courierTierStyle,
  courierSchedulingStyle,
  courierStepTextStyle,
  courierDocumentDisplayState,
  apiService,
  fullDayAvailabilityBlocks,
  availabilityDays,
} from '@tyltgo/shared';
import { defineComponent } from 'vue';
import startCase from 'lodash/startCase';
import dayjs from 'dayjs';
import groupBy from 'lodash/groupBy';
import GoBadge from './GoBadge.vue';
import GoTooltip from './GoTooltip.vue';
import DataBit from './DataBit.vue';
import IconFileImage from './IconFileImage.vue';
import IconPhone from './IconPhone.vue';
import IconCheckCircle from './IconCheckCircle.vue';
import IconTimesCircle from './IconTimesCircle.vue';
import GoDropdownItem from './GoDropdownItem.vue';
import GoDropdown from './GoDropdown.vue';
import GoConfirmationModal from './GoConfirmationModal.vue';
import GoButton from './GoButton.vue';
import IconEllipsis from './IconEllipsis.vue';
import { authTokenKey } from '../services/auth-service';
import {
  showConfirmationModal,
  showLoadingMessage,
  stopLoadingMessage,
} from '../services/ui-message-service';

export default defineComponent({
  emits: ['loadCourier'],
  setup() {
    return {
      startCase,
      courierDisplayState,
      courierStyle,
      courierTierStyle,
      courierSchedulingStyle,
      courierStepTextStyle,
      courierDocumentDisplayState,
    };
  },
  components: {
    GoBadge,
    DataBit,
    GoTooltip,
    IconFileImage,
    IconCheckCircle,
    IconTimesCircle,
    IconEllipsis,
    GoDropdown,
    GoDropdownItem,
    GoConfirmationModal,
    GoButton,
    IconPhone,
  },
  props: {
    courier: {
      type: Object,
      required: true,
    },
    routeDate: {
      type: String,
      required: false,
    },
  },
  computed: {
    suspendAction() {
      return this.courier.state === 'suspended' ? 'unsuspend' : 'suspend';
    },
    isNewCourier() {
      return this.courier.totalRoutes < 4;
    },
    tierReason() {
      const reasons = {
        1: 'GOAT',
        2: 'route: >=20, Availability updated',
        3: 'route: 5-19, Availability updated',
        4: 'route: 0-4, Availability updated',
        5: 'route: >=5, Availability not updated',
        6: 'route: 0-4, Availability not updated',
        7: 'SURGE MASTER',
      };
      return reasons[this.courier.tier] || 'error';
    },
  },
  methods: {
    toDate(date) {
      if (!date) return undefined;
      return dayjs(date).format('YYYY-MM-DD');
    },
    async loginAs() {
      const { token } = await apiService.admin.couriers.loginAs(this.courier.email);
      const key = authTokenKey('courier');
      localStorage.setItem(key, token);

      window.open('/courier/profile');
    },
    async updateSuspended(state: boolean) {
      const confirmed = await showConfirmationModal({
        color: this.suspendAction === 'suspend' ? 'danger' : 'primary',
        title: 'Confirm Suspension Update',
        message: `Are you sure you want to ${this.suspendAction} ${this.courier.firstName} ${this.courier.lastName}?`,
        confirmButtonText: startCase(this.suspendAction),
      });
      if (confirmed) {
        showLoadingMessage('Processing...');
        await apiService.admin.couriers.updateSuspended(this.courier.email, state);
        this.$emit('loadCourier', this.courier.email);
        stopLoadingMessage();
      }
    },
    async approve() {
      const confirmed = await showConfirmationModal({
        title: 'Confirm Approval',
        message: `Are you sure you want to approve ${this.courier.firstName} ${this.courier.lastName}?`,
        confirmButtonText: 'Approve',
      });
      if (confirmed) {
        showLoadingMessage('Processing...');
        await apiService.admin.couriers.approve(this.courier.email);
        this.$emit('loadCourier', this.courier.email);
        stopLoadingMessage();
      }
    },
    async moveToTraining() {
      const confirmed = await showConfirmationModal({
        title: 'Confirm Move to Training',
        message: `Are you sure you want to move ${this.courier.firstName} ${this.courier.lastName} into training?`,
        confirmButtonText: 'Move to Training',
      });
      if (confirmed) {
        showLoadingMessage('Processing...');
        await apiService.admin.couriers.moveToTraining(this.courier.email);
        this.$emit('loadCourier', this.courier.email);
        stopLoadingMessage();
      }
    },
    async moveToOnHold() {
      const confirmed = await showConfirmationModal({
        title: 'Confirm Move to Hold',
        message: `Are you sure you want to put ${this.courier.firstName} ${this.courier.lastName} on hold?`,
        confirmButtonText: 'Move to Hold',
      });
      if (confirmed) {
        showLoadingMessage('Processing...');
        await apiService.admin.couriers.moveToOnHold(this.courier.email);
        this.$emit('loadCourier', this.courier.email);
        stopLoadingMessage();
      }
    },
    async reject() {
      const confirmed = await showConfirmationModal({
        title: 'Confirm Rejection',
        message: `Are you sure you want to reject ${this.courier.firstName} ${this.courier.lastName}?`,
        confirmButtonText: 'Reject',
        color: 'danger',
      });
      if (confirmed) {
        showLoadingMessage('Processing...');
        await apiService.admin.couriers.reject(this.courier.email);
        this.$emit('loadCourier', this.courier.email);
        stopLoadingMessage();
      }
    },
    toTime(time: string) {
      const timeDayjs = dayjs(`2020-01-01 ${time}`);
      return timeDayjs.format('h:mm A');
    },
    blockToString(blocks) {
      return blocks
        .map(block => `${this.toTime(block.startTime)} - ${this.toTime(block.endTime)}`)
        .join(', ');
    },
    dateToDay(date) {
      return dayjs(date).format('dddd').toLowerCase();
    },
    availabilitiesForDate(date) {
      const day = this.dateToDay(date);
      const blocks = fullDayAvailabilityBlocks(this.courier.availability[day]);

      const grouped = groupBy(blocks, 'available');
      return grouped;
    },
    daysAvailable() {
      const availability = this.courier.availability || {};

      const result = {
        true: [],
        false: [],
      };

      for (const day of availabilityDays) {
        const blocks = availability[day];
        const hasBlocks = !!blocks?.length;
        result[hasBlocks.toString()].push(`${startCase(day)}s`);
      }

      return result;
    },
  },
});
</script>
