/* eslint-disable import/prefer-default-export */
import { computed, Ref, ref } from 'vue';

const showMenuRef: Ref<boolean> = ref(true);

export const showMenu = computed(() => {
  return showMenuRef.value;
});

export const setShowMenu = (value: boolean): void => {
  showMenuRef.value = value;
};
