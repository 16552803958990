<template>
  <div v-if="ready" class="mb-10">
    <div class="space-y-3">
      <div class="bg-white dark:bg-gray-800 pt-2 shadow rounded-lg mb-4 flex flex-col p-4">
        <h3 class="text-sm font-bold uppercase my-4">Tyltgo API Key</h3>
        <div v-if="apiKeys.length">
          <pre v-for="apiKey of apiKeys" :key="apiKey"
            >{{ apiKey.apiKey }} {{ apiKey.merchantEmail }}</pre
          >
        </div>
        <div v-else>
          <p class="mb-4">No API key.</p>
          <GoButton @click="createApiKey">Create API key</GoButton>
        </div>
      </div>
      <div class="bg-white dark:bg-gray-800 pt-2 shadow rounded-lg mb-4 flex flex-col p-4">
        <h3 class="text-sm font-bold uppercase my-4">Tyltgo API Key</h3>
        <GoForm @submit="updateWebhooks" :initial-values="initialValues">
          <GoTextField
            class="col-span-1 col-start-1"
            label="Routing Webhook"
            name="settings.routingWebhook"
          />
          <div class="py-3 flex justify-end">
            <GoButton>Save</GoButton>
          </div>
        </GoForm>
      </div>
      <div class="bg-white dark:bg-gray-800 pt-2 shadow rounded-lg mb-4 flex flex-col p-4">
        <h3 class="text-sm font-bold uppercase my-4">Shopify Integrations</h3>
        <div v-if="shopifyIntegrations.length">
          <div v-for="shopify of shopifyIntegrations" :key="shopify.credentials.shopName">
            <GoForm @submit="updateShopifyIntegration" :initial-values="{ shopify }">
              <router-link
                :to="{
                  name: 'admin.merchant.shopify',
                  params: { id: merchant.id, shopName: shopify.credentials.shopName },
                }"
              >
                <span class="text-sm font-bold my-4">{{ shopify.credentials.shopName }}</span>
              </router-link>
              <div class="mt-3 grid grid-cols-1 sm:grid-cols-3 gap-6">
                <GoSelect
                  class="col-span-1"
                  :options="TagDateFormats"
                  name="shopify.credentials.dateFormat"
                  label="Date Format"
                />
                <GoSelect
                  class="col-span-1"
                  :options="ShopifyPullTypes"
                  name="shopify.pullType"
                  label="Pull Type"
                />
                <GoSelect
                  class="col-span-1"
                  :options="ShopifyQuantityTypes"
                  name="shopify.quantityType"
                  label="Quantity Type"
                />
                <GoSelect
                  class="col-span-1"
                  name="shopify.markFulfilledOptions"
                  placeholder="No"
                  label="Mark Fulfilled"
                  :options="ShopifyMarkFulfilledTypes"
                />
                <GoCheckbox
                  class="col-span-1"
                  name="shopify.updateStatusTag"
                  label="Update Status Tag"
                  :value="true"
                />
                <GoTextField
                  class="col-span-1 col-start-1"
                  label="Tags to Route (comma seperated)"
                  name="shopify.tagsToRoute"
                />
                <GoTextField
                  class="col-span-1"
                  label="Tags to Skip (comma seperated)"
                  name="shopify.tagsToSkip"
                />
                <GoTextField
                  class="col-span-1"
                  label="Tags on Routed (comma seperated)"
                  name="shopify.tagsOnRouted"
                />
                <GoTextField
                  class="col-span-1"
                  label="Tags on Picked (comma seperated)"
                  name="shopify.tagsOnPicked"
                />
                <GoTextField
                  class="col-span-1"
                  label="Tags on Delivered (comma seperated)"
                  name="shopify.tagsOnDelivered"
                />
                <GoTextField
                  class="col-span-1"
                  label="Tags on Undelivered (comma seperated)"
                  name="shopify.tagsOnUndelivered"
                />
              </div>
              <div class="flex justify-end py-4">
                <GoButton class="">Update</GoButton>
              </div>
            </GoForm>
          </div>
        </div>
        <div v-else>
          <p class="mb-4">No Shopify Integrations.</p>
        </div>
        <div>
          <GoButton
            v-if="!showCreateShopifyForm"
            @click="showCreateShopifyForm = true"
            variant="outline"
            class="text-xs"
          >
            Add Shopify Integration
          </GoButton>
        </div>
        <Form v-if="showCreateShopifyForm" @submit="createShopifyIntegration">
          <div class="mt-3 grid grid-cols-1 sm:grid-cols-3 gap-6">
            <GoTextField
              class="col-span-3"
              label="Store Name"
              name="shopify.credentials.shopName"
              value="[customerstore].myshopify.com"
            />
            <GoTextField
              class="col-span-3"
              label="Dashed App Name"
              value="tyltgo-delivery-[customername]"
              name="shopify.credentials.appName"
            />
            <GoTextField class="col-span-3" label="API Key" name="shopify.credentials.apiKey" />
            <GoTextField
              class="col-span-3"
              label="API Secret"
              name="shopify.credentials.password"
            />
          </div>
          <div class="flex justify-between py-4">
            <GoButton @click="showCreateShopifyForm = false" variant="outline" class="text-xs">
              Cancel
            </GoButton>
            <GoButton>Create</GoButton>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import {
  apiService,
  TagDateFormats,
  ShopifyPullTypes,
  ShopifyQuantityTypes,
  ShopifyMarkFulfilledTypes,
} from '@tyltgo/shared';
import { Form } from 'vee-validate';
import { cloneDeep } from 'lodash';
import { aroundLoadingMessage } from '../services/ui-message-service';

export default defineComponent({
  components: {
    Form,
  },
  setup() {
    return {
      TagDateFormats,
      ShopifyPullTypes,
      ShopifyQuantityTypes,
      ShopifyMarkFulfilledTypes,
    };
  },
  data() {
    return {
      showCreateShopifyForm: false,
      ready: false,
      apiKeys: null,
      shopifyIntegrations: null,
      files: null,
      priceList: null,
      uploadedTableData: null,
      pricingType: '',
      subPricingType: '',
      tableData: {
        headers: [
          { title: 'Principality', key: 'principality' },
          { title: 'Price', key: 'price' },
        ],
        data: [],
      },
    };
  },
  props: {
    merchant: {
      type: Object,
      required: true,
    },
  },
  async beforeMount() {
    await this.getApiKeys();
    await this.getShopifyIntegrations();
    this.ready = true;
  },
  computed: {
    initialValues() {
      return {
        ...cloneDeep(this.merchant),
      };
    },
  },
  methods: {
    async createApiKey() {
      await apiService.admin.merchants.createApiKey(this.merchant.email);
      await this.getApiKeys();
    },
    async updateShopifyIntegration(values) {
      await aroundLoadingMessage('Updating ...', async () => {
        console.log(values);
        await apiService.admin.merchants.updateShopifyIntegration(
          this.merchant.email,
          values.shopify
        );
      });
    },
    async updateWebhooks(values) {
      await aroundLoadingMessage('Updating ...', async () => {
        console.log(values);
        await apiService.admin.merchants.updateWebhooks(this.merchant.email, values.settings);
      });
    },
    async createShopifyIntegration(values) {
      await aroundLoadingMessage('Creating ...', async () => {
        console.log(values);
        await apiService.admin.merchants.createShopifyIntegration(
          this.merchant.email,
          values.shopify
        );
        await this.getShopifyIntegrations();
        this.showCreateShopifyForm = false;
      });
    },
    async getApiKeys() {
      const { apiKeys } = await apiService.admin.merchants.apiKeys(this.merchant.email);
      this.apiKeys = apiKeys;
    },
    async getShopifyIntegrations() {
      const { shopifyIntegrations } = await apiService.admin.merchants.shopifyIntegrations(
        this.merchant.email
      );
      this.shopifyIntegrations = shopifyIntegrations;
    },
  },
});
</script>
