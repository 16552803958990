/* eslint-disable import/prefer-default-export */
import { apiService, Merchant, Route, Courier } from '@tyltgo/shared';
import { computed, Ref, ref } from 'vue';
import groupBy from 'lodash/groupBy';
import { useTask } from 'vue-concurrency';

const routesRef: Ref<Route[]> = ref(null);
const merchantsRef: Ref<Merchant[]> = ref(null);
const couriersRef: Ref<Courier[]> = ref(null);

export const routes = computed(() => {
  return routesRef.value;
});

export const routesById = computed(() => {
  if (!routesRef.value) return {};
  return routesRef.value.reduce((acc, r) => {
    return {
      ...acc,
      [r.id]: r,
    };
  }, {});
});

export const merchantsById = computed(() => {
  if (!merchantsRef.value) return {};
  return merchantsRef.value.reduce((acc, m) => {
    return {
      ...acc,
      [m.id]: m,
    };
  }, {});
});

export const assignedRoutesMap = computed(() => {
  if (!routesRef.value) return {};
  return groupBy(
    routesRef.value.filter(route => route.courierId),
    'courierId'
  );
});

export const declinedRoutes = computed(() => {
  if (!routesRef.value) return [];
  return routesRef.value.filter(route => route.declinedOffers.length);
});

export const offeredRoutes = computed(() => {
  if (!routesRef.value) return [];
  return routesRef.value.filter(route => route.activeOffers.length);
});

export const merchants = computed(() => {
  return merchantsRef.value;
});

export const couriers = computed(() => {
  return couriersRef.value;
});

export const loadRoutes = async (day: string | undefined): Promise<void> => {
  const results: Route[] = await apiService.admin.routes.day(day);
  const merchantIds: string[] = Array.from(
    new Set(
      results.reduce((ids, route) => {
        return [...ids, ...route.waypoints.map(point => point.associatedEmail), route.merchantId];
      }, [])
    )
  );
  const { data: merchantData } = await apiService.admin.merchants.search(
    `elastic ${merchantIds.map(id => `email:"${id}"`).join(' OR ')}`,
    {
      size: 300,
    }
  );

  const courierEmails: string[] = results.filter(r => r.assignedDriver).map(r => r.assignedDriver);
  const { data: courierData } = await apiService.admin.couriers.search(
    `elastic ${courierEmails.map(email => `email:"${email}"`).join(' OR ')}`,
    { size: courierEmails.length }
  );

  couriersRef.value = courierData;
  merchantsRef.value = merchantData;
  routesRef.value = results;
};

export const loadRoutesTask = useTask(function* loadRouteGenerator(
  _signal,
  day: string | undefined
) {
  yield loadRoutes(day);
}).keepLatest();
