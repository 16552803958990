<template>
  <main class="flex-1 focus:outline-none" tabindex="0">
    <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
      <div class="pt-10 pb-16">
        <GoHeader :level="1">Pricing</GoHeader>
      </div>
      <nav class="-mb-px flex space-x-8" aria-label="Tabs">
        <router-link
          v-for="(tab, key) in tabs"
          :key="key"
          :to="{
            name: 'admin.pricing.tab',
            params: { tab: key },
          }"
          class="whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
          :class="
            currentTab && currentTab.name === tab.name
              ? 'border-indigo-500 dark:border-indigo-400 text-indigo-600'
              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
          "
          aria-current="page"
        >
          {{ tab.name }}
        </router-link>
      </nav>
      <div class="px-4 sm:px-6 md:px-0 space-y-4 py-8">
        <div>
          <component v-if="currentTab" :is="currentTab.component" />
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent, markRaw } from 'vue';
import AdminPricingGenerate from './AdminPricingGenerate.vue';
import AdminPricingPrepare from './AdminPricingPrepare.vue';
import AdminPricingFsas from './AdminPricingFsas.vue';

const tabs = {
  generate: {
    name: 'Generate',
    component: markRaw(AdminPricingGenerate),
  },
  prepare: {
    name: 'Prepare',
    component: markRaw(AdminPricingPrepare),
  },
  fsas: {
    name: 'FSAs',
    component: markRaw(AdminPricingFsas),
  },
};

export default defineComponent({
  data() {
    return {
      tabs,
      currentTab: null,
    };
  },
  async mounted() {
    const { tab } = this.$route.params;
    this.currentTab = tabs[tab] || tabs.generate;
  },
  watch: {
    async $route(to, from) {
      const { tab } = this.$route.params;
      if (to.name === 'admin.pricing.tab') {
        this.currentTab = tabs[tab] || tabs.generate;
      } else if (to.name.startsWith('admin.pricing')) {
        this.currentTab = null;
        setTimeout(() => {
          this.currentTab = tabs[tab] || tabs.generate;
        }, 10);
      }
    },
  },
});
</script>
