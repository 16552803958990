<template>
  <component
    :is="as"
    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-200 focus:bg-gray-100 cursor-pointer"
    role="menuitem"
    @click="onClick"
  >
    <slot></slot>
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['click'],
  props: {
    as: {
      type: String,
      default: 'a',
    },
  },
  inject: ['hideDropdown'],
  methods: {
    onClick(event: Event) {
      this.hideDropdown();
      if (this.as !== 'router-link') {
        event.preventDefault();
        this.$emit('click');
      }
    },
  },
});
</script>
