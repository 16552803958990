<template>
  <div class="md:ml-64 fixed inset-0 overflow-hidden" v-if="show" style="z-index: 1001">
    <div class="absolute inset-0 overflow-hidden">
      <section
        class="absolute inset-y-0 left-0 max-w-full flex"
        aria-labelledby="slide-over-heading"
      >
        <div class="w-screen max-w-2xl">
          <div
            class="h-full flex flex-col py-6 bg-white dark:bg-gray-900 shadow-xl overflow-y-scroll"
          >
            <div class="px-4 sm:px-6">
              <div class="flex items-start justify-between">
                <div class="flex items-center">
                  <h2 id="slide-over-heading" class="text-lg font-medium text-gray-900">
                    Search: {{ searchText }}
                  </h2>
                  <IconLoader
                    class="animate-spin mx-2 h-5 w-5 text-primary-800"
                    v-show="searchLoading"
                  />
                </div>
                <div class="h-7 flex items-center">
                  <button
                    @click="close"
                    class="bg-white dark:bg-gray-700 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span class="sr-only">Close panel</span>
                    <!-- Heroicon name: outline/x -->
                    <svg
                      class="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="mt-6 relative flex-1 px-4 sm:px-6">
              <div class="absolute inset-0 px-4 sm:px-6" v-if="searchResults">
                <nav class="flex space-x-4 mb-4" aria-label="Tabs" v-if="showTabs">
                  <a
                    v-for="[setName, results] of resultSets"
                    :key="setName"
                    href="#"
                    :class="[
                      setName === currentTab
                        ? 'bg-gray-100 text-gray-700 dark:text-gray-900'
                        : 'text-gray-500 hover:text-gray-700',
                      'px-3 py-2 font-medium text-sm rounded-md',
                    ]"
                    :aria-current="setName === currentTab ? 'page' : undefined"
                    @click.prevent="currentTab = setName"
                  >
                    {{ startCase(setName) }} ({{ results.total }})
                  </a>
                </nav>

                <div class="h-full" aria-hidden="true" v-if="currentResults">
                  <p class="mb-2" v-show="searchSet">
                    Showing {{ currentResults.hits.length }} of {{ currentResults.total }}
                  </p>
                  <div v-for="hit of currentResults.hits" :key="hit._id">
                    <slot :hit="hit"></slot>
                  </div>
                </div>
              </div>
              <!-- /End replace -->
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import startCase from 'lodash/startCase';
import IconLoader from './IconLoader.vue';

export default defineComponent({
  setup() {
    return {
      startCase,
    };
  },
  components: {
    IconLoader,
  },
  props: {
    searchResults: {
      type: Object,
      required: true,
    },
    searchText: {
      type: String,
      required: true,
    },
    searchLoading: {
      type: Boolean,
      required: true,
    },
    searchSet: {
      type: Boolean,
      required: true,
    },
    startingTab: {
      type: String,
    },
  },
  emits: ['close'],
  watch: {
    searchText() {
      this.show = true;
    },
  },
  data() {
    return {
      show: true,
      currentTab: 'default',
    };
  },
  mounted() {
    if (this.startingTab) {
      this.currentTab = this.startingTab;
    }
  },
  computed: {
    showTabs() {
      if (!this.searchResults) return false;

      return !this.searchResults.hits;
    },
    resultSets() {
      if (!this.searchResults) return [];

      if (this.showTabs) {
        return Object.entries(this.searchResults);
      }

      return [['default', this.searchResults]];
    },
    currentResults() {
      if (!this.searchResults) return null;
      if (!this.showTabs) return this.searchResults;

      return this.searchResults[this.currentTab];
    },
  },
  methods: {
    close(args) {
      this.show = false;
      this.$emit('close', args);
    },
  },
});
</script>
