<template>
  <div>
    <div v-if="waypoints">
      <div class="py-4">
        <WaypointCard
          v-for="waypoint in waypoints"
          :route="getRoute(waypoint.routeId)"
          :key="waypoint.id"
          :waypoint="waypoint"
          :routeId="waypoint.routeId"
          :ref="waypoint.id"
          :forSearch="true"
        />
        <div v-if="!waypoints.length">No waypoints estimated past cutoff time.</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {
  apiService,
  Route,
  Waypoint,
  waypointsWithAlerts,
  waypointsWithDeliveryCutoffs,
} from '@tyltgo/shared';
import debounce from 'lodash/debounce';
import io from 'socket.io-client';
import { loadRoutes, routes, merchants } from '../store/routes-store';
import WaypointCard from './WaypointCard.vue';
import { showLoadingMessage, stopLoadingMessage } from '../services/ui-message-service';

export default defineComponent({
  components: {
    WaypointCard,
  },
  setup() {
    return { loadRoutes, routes, merchants };
  },
  data() {
    return {
      socket: null,
      waypoints: null,
    };
  },
  async mounted() {
    if (!this.routes) {
      showLoadingMessage('Loading ...');
    }
    await this.updateRoutes();
    stopLoadingMessage();

    this.socket = io(`${apiService.apiHost}?room=admin/routes`, {
      transports: ['websocket', 'polling'],
    });
    this.socket.on('route', debounce(this.updateRoutes, 4000));
    this.socket.on('route', ({ routeId }) => {
      console.log('in socket route update:', { routeId });
    });
  },
  unmounted() {
    if (this.socket) this.socket.disconnect();
  },
  methods: {
    getRoute(id) {
      const route = this.routes.filter(element => element.id === id);

      return route[0];
    },
    async updateRoutes() {
      const { day } = this.$route.query;
      await this.loadRoutes(day);

      const allWaypoints: Waypoint[] = (this.routes as Route[]).reduce((points, route) => {
        const pointsWith = waypointsWithAlerts(
          waypointsWithDeliveryCutoffs(route.waypoints, this.merchants)
        );
        return [...points, ...pointsWith];
      }, []);

      this.waypoints = allWaypoints.filter(point =>
        point.alerts?.find(alert => alert.type === 'estimatedPastCutoff')
      );
    },
  },
});
</script>
