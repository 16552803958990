<template>
  <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
</template>

<script lang="ts">
import { LTileLayer } from '@vue-leaflet/vue-leaflet';
import { defineComponent } from 'vue';

const tiles = {
  default: {
    url: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
  },
  esri: {
    url:
      'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
    attribution:
      'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community',
  },
  osm: {
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution:
      '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
  },
};

export default defineComponent({
  components: {
    LTileLayer,
  },
  props: {
    style: {
      type: String,
      default: 'default',
    },
  },
  computed: {
    url() {
      return tiles[this.style].url;
    },
    attribution() {
      return tiles[this.style].attribution;
    },
  },
});
</script>
