<template>
  <GoConfirmationModal
    v-if="confirmationModal.show"
    @close="confirmationModal.close"
    @confirm="confirmationModal.confirm"
    :color="confirmationModal.color"
    :title="confirmationModal.title"
    :message="confirmationModal.message"
    :confirmButtonText="confirmationModal.confirmButtonText"
    :closeButtonText="confirmationModal.closeButtonText"
    :matchText="confirmationModal.matchText"
    :matchTextMessage="confirmationModal.matchTextMessage"
  ></GoConfirmationModal>
  <GoOverlay v-if="loadingMessage">
    <GoMessagePanel>
      <IconLoader class="w-6 mr-4 animate-spin" />
      <span>{{ loadingMessage }}</span>
    </GoMessagePanel>
  </GoOverlay>
  <GoNotifications />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import GoNotifications from './GoNotifications.vue';
import GoConfirmationModal from './GoConfirmationModal.vue';
import GoOverlay from './GoOverlay.vue';
import GoMessagePanel from './GoMessagePanel.vue';
import IconLoader from './IconLoader.vue';
import { confirmationModal, loadingMessage } from '../services/ui-message-service';

export default defineComponent({
  setup() {
    return {
      confirmationModal,
      loadingMessage,
    };
  },
  components: {
    GoConfirmationModal,
    GoOverlay,
    GoMessagePanel,
    IconLoader,
    GoNotifications,
  },
});
</script>
