<template>
  <main class="flex-1 focus:outline-none" tabindex="0">
    <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
      <div class="pt-10 pb-16">
        <div class="px-4 sm:px-6 md:px-0">
          <GoHeader :level="1">Referrals</GoHeader>
        </div>
        <div class="px-4 sm:px-6 md:px-0 pt-10">
          <div v-if="showForm" class="bg-white dark:bg-gray-800 p-4 mb-4">
            <Form @submit="saveChanges" :initial-values="initialValues">
              <div class="grid grid-cols-1 sm:grid-cols-3 gap-6">
                <GoTextField class="col-span-1" label="Referred By" name="referredBy" />
                <GoTextField class="col-span-1" label="Referred Source" name="referredSource" />
              </div>
              <GoButton class="text-xs mt-4">Save Changes</GoButton>
            </Form>
          </div>
          <div v-else class="text-lg bg-white dark:bg-gray-800 rounded-md px-4 py-4 mb-8">
            <div v-if="courier.referredBy" class="text-lg">
              Referred by:
              <router-link :to="`/admin/couriers/${courier.referredBy}`">
                {{ courier.referredBy }}
              </router-link>
            </div>
            <div v-else>This courier was not referred.</div>
            <div v-if="courier.referredSource" class="text-lg">
              Referred Source: {{ courier.referredSource }}
            </div>
            <GoButton variant="outline" class="text-xs mt-4" @click="showForm = true">
              Edit
            </GoButton>
          </div>
          <h3 class="text-xl font-extrabold text-gray-900">Referred Couriers</h3>
          <GoAlert class="my-4" color="information" title="Courier Referral Link">
            <pre class="text-sm whitespace-pre-line"
              >{{ referralLink }}
            </pre>
          </GoAlert>
          <div v-if="referredCouriers && referredCouriers.length" class="pt-4">
            <CourierCard
              class="mb-4"
              v-for="courier of referredCouriers"
              :key="courier.id"
              :courier="courier"
            />
          </div>
          <div v-else class="pt-6">
            <div class="text-lg bg-white dark:bg-gray-800 rounded-md px-4 py-4">
              No referred couriers.
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { apiService } from '@tyltgo/shared';
import { Form } from 'vee-validate';
import { cloneDeep, pick } from 'lodash';
import CourierCard from './CourierCard.vue';
import GoAlert from './GoAlert.vue';
import { addNotification, aroundLoadingMessage } from '../services/ui-message-service';
import GoTextField from './GoTextField.vue';
import GoButton from './GoButton.vue';

export default defineComponent({
  components: {
    CourierCard,
    GoAlert,
    Form,
    GoTextField,
    GoButton,
  },
  props: {
    courier: {
      type: Object,
      required: true,
    },
  },
  async mounted() {
    await this.loadReferredCouriers();
  },
  data() {
    return {
      showForm: false,
      loading: false,
      referredCouriers: null,
    };
  },
  computed: {
    initialValues() {
      const clone = cloneDeep(this.courier);
      return {
        ...pick(clone, 'referredBy', 'referredSource'),
      };
    },
    referralLink() {
      const resolved = this.$router.resolve({
        name: 'courier.signup',
        query: {
          referredBy: this.courier.email,
          name: this.courier.firstName,
        },
      });
      const { protocol, host } = window.location;
      return `${protocol}//${host}${resolved.fullPath}`;
    },
  },
  methods: {
    async loadReferredCouriers() {
      this.loading = true;
      const { referredCouriers } = await apiService.admin.couriers.referredCouriers(
        this.courier.email
      );
      this.referredCouriers = referredCouriers;
      this.loading = false;
    },
    async saveChanges(values) {
      console.log(values);
      await aroundLoadingMessage('Saving...', async () => {
        try {
          await apiService.admin.couriers.updateReferrals(this.courier.id, {
            ...values,
          });
          addNotification({ color: 'success', message: 'Details Updated' });
          this.showForm = false;
          this.$emit('loadCourier');
        } catch (e) {
          addNotification({
            color: 'danger',
            message: e.response?.data?.message || 'Error while updating details',
          });
        }
      });
    },
  },
});
</script>
