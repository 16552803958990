<template>
  <div class="flex flex-row space-x-2 items-start">
    <div
      class="flex flex-col justify-center items-center space-y-2"
      v-for="day of [placeholder, ...daysList].filter(x => !!x)"
      :key="day"
    >
      <router-link
        class="flex justify-center items-center uppercase rounded-full h-12 w-12 text-xs font-mono"
        :class="{
          'bg-brand-1 text-white dark:bg-primary-400 dark:text-gray-700': currentDay === day,
          'bg-gray-200 text-black dark:bg-gray-700': currentDay !== day,
        }"
        :to="{
          query: {
            ...$route.query,
            currentDay: day,
          },
        }"
      >
        {{ day.slice(0, 3) }}
      </router-link>
      <div v-if="dateForDay[day]" class="text-center">
        {{ dateForDay[day].split('-')[2] }}
      </div>
      <div v-if="today === day" class="text-center w-3 h-3 bg-brand-2 rounded-full"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    days: {
      type: Array,
    },
    dateForDay: {
      type: Object,
    },
    today: {
      type: String,
    },
    currentDay: {
      type: String,
    },
    placeholder: {
      type: String,
      required: false,
    },
  },

  computed: {
    daysList(): string[] {
      return this.days || [];
    },
  },
});
</script>
