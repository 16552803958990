<template>
  <main class="flex-1 overflow-y-auto focus:outline-none" tabindex="0">
    <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
      <div class="pt-10 pb-16">
        <div class="flex flex-row justify-between px-4 sm:px-6 md:px-0 mb-4">
          <GoHeader :level="1">Couriers</GoHeader>
          <DaySelection
            :days="days"
            :dateForDay="dateForDay"
            :today="today"
            :currentDay="currentDay"
            path="/admin/couriers"
            placeholder="all"
          />
        </div>
        <AdminCourierSearchPage
          :days="days"
          :dateForDay="dateForDay"
          :today="today"
          :currentDay="currentDay"
          path="admin.couriers"
        />
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import dayjs from 'dayjs';
import DaySelection from '../../components/DaySelection.vue';
import AdminCourierSearchPage from '../../components/AdminCourierSearchPage.vue';

const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export default defineComponent({
  setup() {
    return {
      days,
    };
  },
  components: {
    DaySelection,
    AdminCourierSearchPage,
  },
  watch: {
    $route(to) {
      if (to.name === 'admin.couriers') {
        this.currentDay = this.$route.query.currentDay || 'all';
      }
    },
  },
  async beforeMount() {
    this.today = dayjs().format('dddd').toLowerCase();
    this.days.forEach((_, count) => {
      const datejs = dayjs().add(count, 'days');
      this.dateForDay[datejs.format('dddd').toLowerCase()] = datejs.format('YYYY-MM-DD');
    });
  },
  data() {
    return {
      currentDay: 'all',
      today: null,
      dateForDay: {},
    };
  },
});
</script>
