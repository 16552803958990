<template>
  <div
    class="flex flex-col sm:flex-row sm:items-stretch space-y-2 sm:space-x-4 sm:items-center"
    v-for="day of days"
    :key="day"
  >
    <div class="flex lg:items-start h-full">
      <span class="w-24 mt-2 md:mt-3">{{ startCase(day) }}s</span>
    </div>
    <FieldArray :name="`availability.${day}`" v-slot="{ fields, push, remove }">
      <div v-if="fields.length">
        <div class="flex flex-col space-y-2">
          <div
            class="flex flex-col space-y-2 items-start justify-center"
            v-for="(entry, index) of fields"
            :key="entry.key"
          >
            <div class="flex flex-row items-center space-x-2">
              <div class="flex flex-row space-x-2">
                <GoTextField
                  label="false"
                  type="time"
                  :name="`availability.${day}[${index}].startTime`"
                />
                <span class="mt-2">to</span>
                <GoTextField
                  label="false"
                  type="time"
                  :name="`availability.${day}[${index}].endTime`"
                />
              </div>
              <div class="sm:pl-2 lg:pl-4 flex flex-col sm:flex-row items-center space-x-2">
                <GoButton
                  type="button"
                  class="bg-gray-100 text-xs"
                  @click="remove(index)"
                  color="danger"
                >
                  <span>remove</span>
                </GoButton>
                <GoButton
                  v-if="entry.isLast"
                  type="button"
                  class="bg-gray-100 text-xs hidden lg:inline-flex items-center"
                  @click="push({})"
                  color="primary-soft"
                >
                  <IconPlus class="w-2 mr-2" />
                  <span>Add Another</span>
                </GoButton>
              </div>
            </div>
            <div class="flex items-start block lg:hidden">
              <GoButton
                v-if="entry.isLast"
                type="button"
                class="bg-gray-100 text-xs inline-flex items-center"
                @click="push({})"
                color="primary-soft"
              >
                <IconPlus class="w-2 mr-2" />
                <span>Add Another</span>
              </GoButton>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="flex flex-row items-center space-x-4 mt-1">
        <GoButton
          type="button"
          class="bg-gray-100 text-xs inline-flex items-center"
          @click="push({})"
          color="primary-soft"
        >
          <IconPlus class="w-2 mr-2" />
          <span>Add Availability</span>
        </GoButton>
      </div>
    </FieldArray>
  </div>
</template>

<script lang="ts">
import startCase from 'lodash/startCase';
import { defineComponent } from 'vue';
import { FieldArray } from 'vee-validate';
import { availabilitySchema as schema, availabilityDays as days } from '@tyltgo/shared';
import GoButton from './GoButton.vue';
import IconPlus from './IconPlus.vue';
import GoTextField from './GoTextField.vue';

export default defineComponent({
  setup() {
    return {
      days,
      schema,
      startCase,
    };
  },
  components: {
    GoButton,
    GoTextField,
    FieldArray,
    IconPlus,
  },
  props: {
    courier: {
      type: Object,
      required: true,
    },
  },
});
</script>
