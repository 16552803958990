<template>
  <BatchCard :route="confirmedBatch" v-if="confirmedBatch" />
  <div
    class="bg-white dark:bg-gray-800 pt-2 shadow rounded-lg mb-4 flex border-l-4 flex-col"
    v-if="!confirmedBatch"
  >
    <div class="w-full pb-2 px-4">
      <div class="flex justify-between mb-4">
        <p class="text-lg">Batch #{{ index + 1 }}</p>
        <div class="flex space-x-4">
          <p class="text-xl">CA${{ batch.totalPrice.toFixed(2) }}</p>
          <p class="text-xl">{{ batch.routeInformation.duration }} mins</p>
          <p class="text-xl" v-if="currentUser.featureFlags?.newBatchesToCsv">
            {{ (batch.routeInformation.distance / 1000).toFixed(2) }} km
          </p>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="px-4 sm:px-6 md:px-0">
          <div class="flex flex-col">
            <div class="-my-2 sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-200">
                    <tbody class="bg-white dark:bg-gray-800 divide-y divide-gray-200">
                      <tr v-for="(point, pointIndex) in batch.dropOffPoints" :key="pointIndex">
                        <td
                          class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-100 text-right"
                        >
                          {{ point.name }}
                        </td>
                        <td
                          class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300"
                        >
                          {{ point.address }}
                          <span
                            v-if="point.outsidePriceList"
                            class="pl-2 text-sm uppercase text-red-600"
                          >
                            Premium Charge
                          </span>
                        </td>
                        <td
                          class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300"
                        >
                          {{ point.id }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center pt-4 pb-2" v-if="timeLoaded">
        <Form
          @submit="confirm"
          :validation-schema="schema"
          :initial-values="{ endWindow: savedPickupTime }"
        >
          <div class="flex space-x-4 justify-center mb-4 pt-4">
            <div class="text-left">
              <GoLabel label="Delivery Day" />
              <div class="pt-2">{{ batch.day }}</div>
            </div>
            <GoTextField label="Pickup Time" type="time" :name="`endWindow`" v-model="pickupTime" />
            <div class="text-left">
              <GoLabel label="Timezone" />
              <div class="pt-2">{{ timeZoneId }}</div>
            </div>
          </div>
          <GoButton>Confirm Batch</GoButton>
          <p class="pt-4 text-sm">
            Total Batch Price: ${{ batch.totalPrice.toFixed(2) }}. Excludes taxes and any other
            applicable fees.
          </p>
          <p v-if="isOutsideArea()" class="pt-1 text-sm">
            {{ `Includes Outside Service Area premium charge ($25 x ${isOutsideArea()}).` }}
          </p>
          <p v-if="pickupTime" class="pt-1 text-sm">
            Pickup time will be approximately between {{ pickupRange(pickupTime) }}
          </p>
        </Form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { apiService } from '@tyltgo/shared';
import { defineComponent } from 'vue';
import * as yup from 'yup';
import { Form } from 'vee-validate';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import GoButton from './GoButton.vue';
import BatchCard from './BatchCard.vue';
import GoLabel from './GoLabel.vue';
import GoTextField from './GoTextField.vue';
import { currentUser } from '../services/auth-service';
import { showLoadingMessage, stopLoadingMessage } from '../services/ui-message-service';
import { pickupRange, timezoneConversionCurrentUserInput } from '../services/date-service';

dayjs.extend(utc);
dayjs.extend(timezone);

const schema = yup.object().shape({
  endWindow: yup.string().required().label('Pickup Time'),
});

export default defineComponent({
  setup() {
    return {
      currentUser,
      schema,
      timezoneConversionCurrentUserInput,
    };
  },
  components: {
    Form,
    GoButton,
    GoTextField,
    BatchCard,
    GoLabel,
  },
  props: {
    batch: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      pickupTime: null,
      confirmedBatch: null,
      timeZoneId: '',
      timeLoaded: false,
      savedPickupTime: '',
    };
  },
  async created() {
    this.savedPickupTime = localStorage.getItem('savedPickupTime') || '';
    this.pickupTime = this.savedPickupTime;
    const zone = await timezoneConversionCurrentUserInput(
      this.currentUser.businessAddresses,
      'merchant',
      this.batch
    );
    this.timeZoneId = zone.timeZoneId;
    this.timeLoaded = true;
  },
  methods: {
    isOutsideArea() {
      return this.batch.dropOffPoints.filter(item => item.outsidePriceList).length;
    },
    pickupRange(time: string) {
      return pickupRange(this.timeDayjs(time).format(), this.timeZoneId);
    },
    timeDayjs(time: string) {
      return dayjs.tz(`${this.batch.day} ${time}`, `${this.timeZoneId}`);
    },
    async confirm(values) {
      localStorage.setItem('savedPickupTime', values.endWindow);
      const estTime = this.timeDayjs(values.endWindow).tz('America/Toronto').format('HH:mm');

      showLoadingMessage('Confirming Batch ...');
      const batch = await apiService.merchants.batches.create({
        startWindow: values.startWindow,
        endWindow: estTime,
        businessAddress: this.batch.businessAddress,
        day: this.batch.day,
        orders: this.batch.dropOffPoints,
        carType: this.batch.carType,
        totalPrice: this.batch.totalPrice,
        routeInformation: this.batch.routeInformation,
      });
      console.log(batch);
      this.confirmedBatch = batch;
      stopLoadingMessage();
    },
  },
});
</script>
