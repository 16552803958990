<template>
  <div class="bg-white dark:bg-gray-800 p-4" v-if="quizResults">
    <div v-for="item of items" :key="item.question" class="mb-6">
      <p class="mb-1 font-bold text-gray-500 dark:text-gray-400">{{ item.question }}</p>
      <p>{{ item.answer?.label || item.answer }}</p>
    </div>
    <div class="mb-6">
      <p class="mb-1 font-bold text-gray-500 dark:text-gray-400">Score</p>
      <p>{{ quizResults.calculated?.score }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { apiService } from '@tyltgo/shared/services/api-service';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    courier: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      quizResults: null,
    };
  },
  async mounted() {
    this.quizResults = await apiService.admin.couriers.quizResponse(this.courier.email);
  },
  computed: {
    items() {
      return this.quizResults.items.filter(item => item.answer);
    },
  },
});
</script>
