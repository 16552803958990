/* eslint-disable import/prefer-default-export */

import { ref, Ref, watchEffect } from 'vue';

export const downloadBlob = (blob: Blob, filename: string): void => {
  const a = document.createElement('a');
  const url = URL.createObjectURL(blob);
  a.setAttribute('href', url);
  a.setAttribute('download', filename);
  a.click();
};

export const darkMode: Ref<boolean | null> = ref(null);
export const setDarkMode = (value: boolean): void => {
  const element = document.getElementsByTagName('html')[0];
  if (value) {
    element.classList.add('dark');
    localStorage.setItem('darkMode', 'true');
  } else {
    element.classList.remove('dark');
    localStorage.removeItem('darkMode');
  }
};

export const loadDarkModeSetting = (): void => {
  const dark = localStorage.getItem('darkMode');
  if (dark) {
    darkMode.value = true;
  }
};

watchEffect(() => {
  if (darkMode.value !== null) {
    setDarkMode(darkMode.value);
  }
});
