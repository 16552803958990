<template>
  <div class="py-6 space-x-2">
    <GoButton variant="outline" class="text-xs" @click="downloadFsas">Download FSAs</GoButton>
    <GoUploadButton
      variant="outline"
      class="text-xs"
      id="upload_fsas"
      accept=".csv"
      @filesSelected="uploadFsas"
    >
      Upload FSAs
    </GoUploadButton>
  </div>
</template>

<script lang="ts">
import { downloadBlob } from '@/services/browser-service';
import { parseCsv } from '@/services/csv-service';
import {
  addNotification,
  showLoadingMessage,
  stopLoadingMessage,
} from '@/services/ui-message-service';
import { apiService } from '@tyltgo/shared';
import { defineComponent } from 'vue';

export default defineComponent({
  methods: {
    async uploadFsas(files: any[]) {
      const reader = new FileReader();
      reader.addEventListener('load', event => {
        const data = <string>event.target.result;
        setTimeout(async () => {
          const csv = parseCsv(data, { header: true });
          console.log(csv);
          showLoadingMessage('Updating FSAs');
          try {
            await apiService.admin.pricing.updateFsas({
              data: csv.data,
            });
            addNotification({
              color: 'success',
              message: `FSAs updated.`,
            });
          } catch (e) {
            console.log('error', e);
            addNotification({
              color: 'danger',
              message: `Error: ${e.response?.data?.message || e.message}`,
            });
          }
          stopLoadingMessage();
        }, 100);
      });

      const file = files[0];
      if (file) {
        reader.readAsText(file);
      }
    },
    async downloadFsas() {
      const csv = await apiService.admin.pricing.fsas();
      const blob = new Blob([csv], { type: 'text/csv' });
      downloadBlob(blob, `fsas-${new Date().toISOString()}.csv`);
    },
  },
});
</script>
