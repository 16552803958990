<template>
  <main class="flex-1 overflow-y-auto focus:outline-none" tabindex="0">
    <div class="relative max-w-7xl mx-auto md:px-8 xl:px-0">
      <div class="pt-10 pb-16">
        <div class="px-4 sm:px-6 md:px-0">
          <GoHeader :level="1">Preview Courier Payouts</GoHeader>
        </div>
        <div class="px-4 sm:px-6 md:px-0 py-4">
          <Form class="gap-3 grid grid-cols-4 w-full pr-4 mb-4" @submit="previewPayouts">
            <GoTextField
              class="col-span-1"
              name="start"
              v-model="start"
              label="Start Date"
              type="date"
            />
            <GoTextField class="col-span-1" name="end" v-model="end" label="End Date" type="date" />
            <div class="col-span-1 flex items-end">
              <GoButton>Preview</GoButton>
            </div>
          </Form>

          <div v-if="payouts.length">
            <div class="flex mb-4 space-x-4">
              <div class="shadow-md rounded-md py-4 px-8 bg-white text-center">
                <span class="font-bold block mb-2">Couriers</span>
                <span>{{ payouts.length }}</span>
              </div>
              <div class="shadow-md rounded-md py-4 px-8 bg-white text-center">
                <span class="font-bold block mb-2">Routes</span>
                <span>{{ payouts.reduce((total, p) => p.orders.length + total, 0) }}</span>
              </div>
              <div class="shadow-md rounded-md py-4 px-8 bg-white text-center">
                <span class="font-bold block mb-2">Total Pay</span>
                <span>
                  {{ parseFloat(payouts.reduce((total, p) => p.totalPay + total, 0)).toFixed(2) }}
                </span>
              </div>
            </div>
            <GoTable
              :tableData="{
                headers: [
                  { title: 'Payout ID', key: 'payoutId' },
                  { title: 'Courier', key: 'email' },
                  { title: 'Pay Period', key: 'payPeriod' },
                  { title: '# Routes', key: 'numberOfRoutes' },
                  { title: 'Total Pay', key: 'totalPay' },
                  { title: 'Bumpup Pay', key: 'bumpUpPay' },
                  {
                    title: 'Status',
                    key: 'veemStatus',
                    valueFunction: row => paymentStatus(row.veemStatus).status,
                    tooltip: status => paymentStatus(status).message,
                  },
                  { title: 'Veem ID', key: 'veemId' },
                  { title: 'Created', key: 'veemCreated' },
                ],
                data: payouts.map(p => ({
                  ...p,
                  numberOfRoutes: p.orders.length,
                  totalPay: parseFloat(p.totalPay).toFixed(2),
                  bumpUpPay: parseFloat(p.bumpUpPay).toFixed(2),
                  veemStatus: p.veem?.status,
                  veemId: p.transferId,
                  veemCreated: veemDateTime(p.veem?.timeCreated),
                })),
              }"
            ></GoTable>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
import { apiService, veemDateTime, paymentStatus } from '@tyltgo/shared';
import dayjs from 'dayjs';
import sortBy from 'lodash/sortBy';
import GoTextField from '../../components/GoTextField.vue';
import GoButton from '../../components/GoButton.vue';
import GoTable from '../../components/GoTable.vue';
import { aroundLoadingMessage } from '../../services/ui-message-service';

export default defineComponent({
  components: {
    GoButton,
    GoTextField,
    Form,
    GoTable,
  },
  setup() {
    return {
      veemDateTime,
      paymentStatus,
    };
  },
  data() {
    return {
      start: '',
      end: '',
      payouts: [],
    };
  },
  mounted() {
    const end = dayjs().startOf('week');
    this.end = end.format('YYYY-MM-DD');
    this.start = end.subtract(6, 'day').format('YYYY-MM-DD');
  },
  methods: {
    async previewPayouts(values) {
      await aroundLoadingMessage('Generating...', async () => {
        const { payouts } = await apiService.admin.couriers.previewPayouts(values);
        this.payouts = sortBy(payouts, 'email').reverse();
      });
    },
  },
});
</script>
