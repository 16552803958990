<template>
  <div class="align-middle inline-block min-w-full mb-4">
    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <tr>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            ></th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Order ID
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Name
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Address
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Status
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="(point, index) of dropoffs" :key="point.id">
            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              {{ index + 1 }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              <router-link :to="`/merchant/batches/${route.id}#${point.id}`">
                {{ point.id }}
              </router-link>
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ point.name }}
            </td>
            <td class="px-6 py-4 text-sm text-gray-500">
              {{ point.address }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              <GoBadge :class="waypointStyle(point)">
                <span>{{ waypointDisplayState(point) }}</span>
              </GoBadge>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { waypointDisplayState, waypointStyle } from '@tyltgo/shared';
import GoBadge from './GoBadge.vue';

export default defineComponent({
  setup() {
    return {
      waypointDisplayState,
      waypointStyle,
    };
  },
  components: {
    GoBadge,
  },
  props: {
    route: {
      type: Object,
      required: true,
    },
  },
  computed: {
    dropoffs(): any[] {
      return this.route.waypoints?.filter(x => x.type === 'dropoff') || [];
    },
  },
});
</script>
