<template>
  <Form @submit="saveChanges" :initial-values="initialValues">
    <div class="bg-white dark:bg-gray-800 pt-2 shadow rounded-lg mb-4 flex flex-col p-4">
      <h3 class="text-sm font-bold uppercase my-4">Courier Details</h3>
      <div class="grid grid-cols-1 sm:grid-cols-3 gap-6">
        <GoTextField
          label="Address"
          name="address"
          placeholder="Address"
          class="col-span-4 sm:col-span-2"
          google-places
          @place-selected="placeSelected"
          type="search"
          v-model="courierAddress"
        />
        <GoTextField class="col-span-1" label="Phone Number" name="phoneNumber" />
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-3 gap-6 pt-2">
        <GoTextField class="col-span-1" label="Unit Number (if applicable)" name="unitNumber" />
        <GoSelect name="typeOfCar" :options="carTypes" placeholder="Select Car Type" />
        <!-- <GoCheckbox
          :value="true"
          class="col-span-1"
          label="Disable Address Change for Courier"
          name="settings.disableAddressChange"
        /> -->
      </div>
      <pre v-if="duplicatePhoneNumber.length" class="pt-2 text-red-600">
The phone number is associated with the following accounts: {{ duplicatePhoneNumber }}</pre
      >
    </div>
    <div class="bg-white dark:bg-gray-800 pt-2 shadow rounded-lg mb-4 flex flex-col p-4">
      <h3 class="text-sm font-bold uppercase my-4">Courier Scheduling</h3>
      <div class="mt-3 grid grid-cols-1 sm:grid-cols-3 gap-6">
        <div class="col-span-1 sm:col-span-3">
          <GoCheckbox
            v-for="key in schedulingOptions"
            :key="key"
            :label="key"
            :name="`settings.${key}`"
            :value="true"
            :uncheckedValue="false"
          />
        </div>
      </div>
    </div>
    <div class="space-y-3">
      <div class="bg-white dark:bg-gray-800 pt-2 shadow rounded-lg mb-4 flex flex-col p-4">
        <h3 class="text-sm font-bold uppercase my-4">Feature Flags</h3>
        <div class="mt-3 grid grid-cols-1 sm:grid-cols-3 gap-6">
          <div class="col-span-1 sm:col-span-3">
            <GoCheckbox
              v-for="key in featureFlagOptions"
              :key="key"
              :label="key"
              :name="`featureFlags.${key}`"
              :value="true"
              :uncheckedValue="false"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="space-y-3">
      <div class="bg-white dark:bg-gray-800 pt-2 shadow rounded-lg mb-4 flex flex-col p-4">
        <h3 class="text-sm font-bold uppercase my-4">Capabilities</h3>
        <div class="mt-3 grid grid-cols-1 sm:grid-cols-3 gap-6">
          <div class="col-span-1 sm:col-span-3">
            <GoMultiselect
              :name="`settings.capabilities`"
              label="Capabilities"
              mode="tags"
              :options="{
                smartServe: 'Smart Serve',
              }"
              v-model="capabilities"
            />
          </div>
        </div>
      </div>
    </div>
    <GoButton class="text-xs mt-4">Save Changes</GoButton>
  </Form>
</template>

<script lang="ts">
import { apiService, TypeOfCar } from '@tyltgo/shared';
import { cloneDeep, pick } from 'lodash';
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
import GoTextField from './GoTextField.vue';
import GoSelect from './GoSelect.vue';
import { addNotification, aroundLoadingMessage } from '../services/ui-message-service';
import GoButton from './GoButton.vue';
import GoCheckbox from './GoCheckbox.vue';

export default defineComponent({
  components: {
    GoButton,
    GoCheckbox,
    Form,
    GoTextField,
    GoSelect,
  },
  props: {
    courier: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      geo: {},
      courierAddress: '',
      capabilities: [],
      duplicatePhoneNumber: [],
    };
  },
  async created() {
    this.geo[this.courier.address] = {
      lat: this.courier.lat,
      lng: this.courier.lng,
    };
    this.capabilities = this.courier.settings.capabilities;

    const checkDuplicatePhoneNumbers = await apiService.admin.couriers.duplicatePhoneNumberCheck(
      this.courier.email,
      this.courier.phoneNumber
    );

    this.duplicatePhoneNumber = checkDuplicatePhoneNumbers.couriers;
  },
  computed: {
    carTypes() {
      return Object.values(TypeOfCar).map(type => ({
        label: type,
        value: type,
      }));
    },
    initialValues() {
      const clone = cloneDeep(this.courier);
      return {
        ...pick(
          clone,
          'featureFlags',
          'address',
          'phoneNumber',
          'unitNumber',
          'settings',
          'typeOfCar'
        ),
      };
    },
    featureFlagOptions() {
      return ['verifyAddress', 'showZendesk'];
    },
    schedulingOptions() {
      return ['goat', 'sameDay', 'surge'];
    },
  },
  methods: {
    placeSelected(place, _name) {
      this.geo = {};
      this.geo[place.formatted_address] = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      this.courierAddress = place.formatted_address;
    },
    async saveChanges(values, { setFieldError }) {
      if (Object.keys(this.geo)[0] !== values.address) {
        setFieldError('address', 'Please select the address from the autocomplete dropdown.');
        return;
      }

      await aroundLoadingMessage('Saving...', async () => {
        try {
          await apiService.admin.couriers.updateSettings(this.courier.id, {
            ...values,
            // eslint-disable-next-line @typescript-eslint/ban-types
            ...(Object.values(this.geo)[0] as {}),
          });
          addNotification({ color: 'success', message: 'Details Updated' });

          const checkDuplicatePhoneNumbers = await apiService.admin.couriers.duplicatePhoneNumberCheck(
            this.courier.email,
            values.phoneNumber
          );
          this.duplicatePhoneNumber = checkDuplicatePhoneNumbers.couriers;

          this.$emit('loadCourier');
        } catch (e) {
          addNotification({
            color: 'danger',
            message: e.response?.data?.message || 'Error while updating details',
          });
        }
      });
    },
  },
});
</script>
