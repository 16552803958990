<template>
  <div>
    <div class="mb-10">
      <Form
        @submit="saveChanges"
        :initial-values="{
          subPricingType,
          pricingType,
          additionalQuantityPrice,
          fuelSurchargeAnchor,
          chargeFuelSurcharge,
          removePricelistDisplay,
        }"
      >
        <div class="grid grid-cols-3 gap-3 items-center">
          <div class="col-span-4 sm:col-span-1">
            <GoSelect
              placeholder="Select Pricing Type"
              label="Pricing Type"
              name="pricingType"
              v-model="pricingType"
              :options="[
                { label: 'Dynamic', value: 'dynamic' },
                { label: 'Fixed', value: 'fixed' },
              ]"
            />
          </div>
          <div class="col-span-4 sm:col-span-1" v-if="pricingType === 'fixed'">
            <GoSelect
              label="Sub Pricing Type"
              name="subPricingType"
              v-model="subPricingType"
              :options="[
                { label: 'City', value: 'city' },
                { label: 'FSA', value: 'fsa' },
              ]"
            />
          </div>
          <div class="col-span-4 sm:col-span-1">
            <GoTextField
              label="Additional Quantity Price"
              name="additionalQuantityPrice"
              v-model="additionalQuantityPrice"
              type="number"
            />
          </div>
        </div>
        <div class="grid grid-cols-8 gap-3 items-center py-3">
          <div class="col-span-4 sm:col-span-1">
            <GoCheckbox
              label="Charge fuel surcharge"
              name="chargeFuelSurcharge"
              v-model="chargeFuelSurcharge"
              :value="true"
            />
          </div>
          <div v-for="key in ['ON', 'BC']" :key="key" class="col-span-4 sm:col-span-1">
            <GoTextField
              :label="`${key}`"
              :name="`fuelSurchargeAnchor.${key}`"
              v-model="fuelSurchargeAnchor[key]"
              type="number"
            />
          </div>
        </div>
        <div class="py-3">
          <GoCheckbox
            :value="true"
            class="col-span-1"
            label="Remove Prices in Merchant Portal"
            name="removePricelistDisplay"
            v-model="removePricelistDisplay"
          />
        </div>
        <GoButton class="text-xs mx-3 mt-4">Save Changes</GoButton>
      </Form>
    </div>
    <div class="my-2 mx-3">
      <div v-if="pricingType === 'fixed'">
        <GoAlert
          class="mb-4"
          color="information"
          title="Instructions"
          message="The csv upload file must have exactly the following headers: 'price' and 'principality'"
        />
        <GoUploadButton
          id="csv_upload"
          variant="outline"
          class="text-xs"
          accept=".csv"
          @filesSelected="fileSelected"
        >
          Upload CSV
        </GoUploadButton>
        <GoButton
          v-if="merchant.priceList"
          v-on:click="downloadCurrentPriceList"
          class="text-xs mx-3"
          as="label"
        >
          Download Current Price List
        </GoButton>

        <GoModal v-if="showModal" @close="modalClosed">
          <div>
            <h1 class="text-3xl">Preview</h1>
          </div>
          <div class="py-4">
            <GoButton class="text-xs mx-3" as="label" @click="confirmUploadedList">
              Confirm
            </GoButton>
            <GoButton variant="outline" class="text-xs" @click="modalClosed">Cancel</GoButton>
          </div>

          <GoTable :tableData="uploadedTableData"></GoTable>
        </GoModal>
      </div>
    </div>
    <GoTable
      class="my-5"
      v-if="priceList && pricingType === 'fixed'"
      :tableData="tableData"
    ></GoTable>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { apiService } from '@tyltgo/shared';
import { Form } from 'vee-validate';
import dayjs from 'dayjs';
import sortBy from 'lodash/sortBy';
import GoButton from './GoButton.vue';
import GoUploadButton from './GoUploadButton.vue';
import GoModal from './GoModal.vue';
import GoTable from './GoTable.vue';
import GoSelect from './GoSelect.vue';
import GoTextField from './GoTextField.vue';
import GoAlert from './GoAlert.vue';
import { downloadBlob } from '../services/browser-service';
import { addNotification } from '../services/ui-message-service';
import { parseCsv } from '../services/csv-service';

export default defineComponent({
  emits: ['loadMerchant'],
  components: {
    GoButton,
    Form,
    GoModal,
    GoTable,
    GoSelect,
    GoAlert,
    GoUploadButton,
    GoTextField,
  },
  data() {
    return {
      showModal: false,
      files: null,
      priceList: null,
      uploadedTableData: null,
      pricingType: '',
      subPricingType: '',
      additionalQuantityPrice: null,
      fuelSurchargeAnchor: {},
      chargeFuelSurcharge: null,
      removePricelistDisplay: null,
      tableData: {
        headers: [
          { title: 'Principality', key: 'principality' },
          { title: 'Price', key: 'price' },
        ],
        data: [],
      },
    };
  },
  props: {
    merchant: {
      type: Object,
      required: true,
    },
  },
  async beforeMount() {
    this.pricingType = this.merchant.pricingType;
    this.priceList = this.merchant.priceList;
    this.removePricelistDisplay = this.merchant.settings.removePricelistDisplay;
    this.additionalQuantityPrice = this.merchant.settings.additionalQuantityPrice;
    this.fuelSurchargeAnchor = this.merchant.settings.fuelSurchargeAnchor || {};
    this.chargeFuelSurcharge = this.merchant.settings.chargeFuelSurcharge;
    if (this.priceList) {
      this.tableData.data = sortBy(
        (await apiService.admin.merchants.getDetailedPriceList(this.merchant.email)).detailedList,
        'principality'
      );
      const city = this.tableData.data.map(element => element.city).some(Boolean);
      if (city) {
        this.tableData.headers.push({ title: 'City', key: 'city' });
      }
    }
    if (this.pricingType === 'fixed') {
      this.subPricingType = this.merchant.subPricingType;
    }
  },
  methods: {
    mapPriceListToTableData(priceList) {
      const tableDataRows = sortBy(
        Object.keys(priceList).map(principality => {
          return {
            principality,
            price: priceList[principality],
          };
        }),
        'principality'
      );
      return tableDataRows;
    },
    mapTableDataToPriceList(tableData) {
      const priceList = {};
      tableData.data
        .filter(row => row.principality)
        .forEach(row => {
          const parsedPrice = row.price as string;
          const formattedPrice = parsedPrice.replace(/[^0-9.]/g, '');
          const formattedPrincipality = row.principality.trim();
          priceList[formattedPrincipality] = parseFloat(formattedPrice);
        });
      return priceList;
    },
    confirmUploadedList() {
      this.priceList = this.mapTableDataToPriceList(this.uploadedTableData);
      this.saveChanges({
        pricingType: 'fixed',
        subPricingType: this.subPricingType || this.merchant.subPricingType,
        removePricelistDisplay:
          this.removePricelistDisplay || this.merchant.settings.removePricelistDisplay,
        additionalQuantityPrice:
          this.additionalQuantityPrice || this.merchant.settings.additionalQuantityPrice,
        fuelSurchargeAnchor: Object.keys(this.fuelSurchargeAnchor).length
          ? this.fuelSurchargeAnchor
          : this.merchant.settings.fuelSurchargeAnchor || {},
        chargeFuelSurcharge: this.chargeFuelSurcharge || this.merchant.settings.chargeFuelSurcharge,
        priceList: this.priceList,
      });
      this.modalClosed();
    },
    fileSelected([file]) {
      const reader = new FileReader();
      reader.addEventListener('load', e => {
        this.parsedCsv = undefined;
        const data = <string>e.target.result;
        setTimeout(() => {
          this.parsedCsv = parseCsv(data, { header: true });
          this.uploadedTableData = {
            headers: [
              { title: 'Principality', key: 'principality' },
              { title: 'Price', key: 'price' },
            ],
            data: sortBy(this.parsedCsv.data, 'principality'),
          };
          this.showModal = true;
        }, 100);
      });

      if (file) {
        reader.readAsText(file);
      }
    },
    modalClosed() {
      this.showModal = false;
    },
    downloadCurrentPriceList() {
      const rows = [];
      const city = this.tableData.data.map(element => element.city).some(Boolean);
      this.tableData.data.forEach(principality => {
        const csvRowData = city
          ? [principality.principality, principality.price, principality.city]
          : [principality.principality, principality.price];
        rows.push(csvRowData);
      });

      const csvHeaders = city ? ['principality', 'price', 'city'] : ['principality', 'price'];
      const csv = [csvHeaders]
        .concat(rows)
        .map(x => x.join(','))
        .join('\n');

      const contentType = 'text/csv';
      const csvFile = new Blob([csv], { type: contentType });
      downloadBlob(
        csvFile,
        `${this.merchant.email} - Price List - ${dayjs().format('YYYY-MM-DD')}.csv`
      );
    },
    async saveChanges(values) {
      if (values.pricingType === 'dynamic') {
        this.priceList = null;
        this.subPricingType = '';
      }
      const data = {
        priceList: this.priceList,
        pricingType: values.pricingType,
        subPricingType: values.subPricingType || '',
        removePricelistDisplay: values.removePricelistDisplay,
        additionalQuantityPrice: values.additionalQuantityPrice || null,
        fuelSurchargeAnchor: values.fuelSurchargeAnchor || {},
        chargeFuelSurcharge: values.chargeFuelSurcharge || null,
      };
      const response = await apiService.admin.merchants.pricing(this.merchant.email, data);
      this.tableData.data = sortBy(
        (await apiService.admin.merchants.getDetailedPriceList(this.merchant.email)).detailedList,
        'principality'
      );
      if (response) {
        addNotification({ color: 'success', message: 'Pricing changes saved' });
        this.$emit('loadMerchant');
      } else {
        addNotification({ color: 'danger', message: 'Error while saving pricing changes' });
      }
    },
  },
});
</script>
