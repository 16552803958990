<template>
  <Form
    @submit="saveChanges"
    :initial-values="{
      availability: courier.availability,
      availabilityNote: courier.availabilityNote,
      settings: courier.settings,
    }"
    :validation-schema="schema"
  >
    <div class="space-y-3">
      <div class="bg-white dark:bg-gray-800 pt-2 shadow rounded-lg mb-4 flex flex-col p-4">
        <h3 class="text-sm font-bold uppercase my-4">Availability Status</h3>
        <div>
          <div class="flex flex-row space-x-4 w-3/5">
            <div class="text-sm">
              Pausing a courier’s availability means they will not be receiving any route offers
              until they turn it back on.
            </div>
            <GoCheckbox
              class="w-full"
              label="Paused"
              :name="`settings.paused`"
              :value="true"
              :uncheckedValue="false"
            />
          </div>
        </div>
        <div class="mt-3 flex flex-col space-y-4">
          <CourierAvailabilityForm :courier="courier" />
          <div>
            <GoTextArea type="text" :name="`availabilityNote`" />
          </div>
        </div>
      </div>
    </div>
    <GoButton class="text-xs mt-4">Save Changes</GoButton>
  </Form>
</template>

<script lang="ts">
import { apiService, availabilitySchema as schema } from '@tyltgo/shared';
import { cloneDeep, pick } from 'lodash';
import startCase from 'lodash/startCase';
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
import { addNotification, aroundLoadingMessage } from '../services/ui-message-service';
import GoButton from './GoButton.vue';
import GoTextArea from './GoTextArea.vue';
import GoCheckbox from './GoCheckbox.vue';
import CourierAvailabilityForm from './CourierAvailabilityForm.vue';

export default defineComponent({
  setup() {
    return {
      schema,
      startCase,
    };
  },
  components: {
    GoButton,
    GoTextArea,
    GoCheckbox,
    Form,
    CourierAvailabilityForm,
  },
  props: {
    courier: {
      type: Object,
      required: true,
    },
  },
  computed: {
    initialValues() {
      const clone = cloneDeep(this.courier);
      return {
        ...pick(clone, 'featureFlags'),
      };
    },
  },
  methods: {
    async saveChanges(values, { resetForm }) {
      await aroundLoadingMessage('Saving...', async () => {
        try {
          const {
            availability,
            availabilityNote,
            settings,
          } = await apiService.admin.couriers.updateAvailability(this.courier.id, values);
          addNotification({ color: 'success', message: 'Availability Updated.' });

          resetForm({
            values: { availability, availabilityNote, settings },
          });

          this.$emit('loadCourier');
        } catch (e) {
          addNotification({
            color: 'danger',
            message: e.response?.data?.message || 'Error while updating availability.',
          });
        }
      });
    },
  },
});
</script>
