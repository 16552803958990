<template>
  <div v-if="agreementHtml" class="p-4">
    <div v-if="!completed" class="mb-8 rounded-md bg-blue-50 p-4 text-blue-700">
      <p>Please read the {{ startCase(name) }} below carefully.</p>
      <p>Confirm that you have read the guide by pressing the button below.</p>
    </div>
    <div v-html="agreementHtml" class="prose"></div>
    <div v-if="!completed" class="p-8 text-center">
      <GoButton @click="acceptAgreement">Confirm</GoButton>
    </div>
  </div>
</template>

<script lang="ts">
import { apiService } from '@tyltgo/shared/services';
import { defineComponent } from 'vue';
import { marked } from 'marked';
import startCase from 'lodash/startCase';
import GoButton from './GoButton.vue';
import { showLoadingMessage, stopLoadingMessage } from '../services/ui-message-service';

export default defineComponent({
  emits: ['completed'],
  setup() {
    return {
      startCase,
    };
  },
  components: {
    GoButton,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    courier: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      agreements: {},
      agreementHtml: null,
    };
  },
  computed: {
    completed() {
      return this.courier.agreements[this.name].completed;
    },
  },
  updated() {
    this.setAgreement();
  },
  async mounted() {
    const { agreements } = await apiService.couriers.profile.agreements();
    this.agreements = agreements;
    this.setAgreement();
  },
  methods: {
    setAgreement() {
      this.agreementHtml = marked(this.agreements[this.name]);
    },
    async acceptAgreement() {
      showLoadingMessage('Submitting ...');
      await apiService.couriers.profile.acceptAgreement(this.name);
      stopLoadingMessage();
      this.$emit('completed', this.name);
    },
  },
});
</script>
