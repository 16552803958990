<template>
  <div class="h-screen flex">
    <div class="hidden md:flex md:flex-shrink-0 print:hidden">
      <div class="w-64 fixed h-screen flex flex-col">
        <nav
          class="bg-brand-1 border-r border-gray-200 pt-5 pb-4 flex flex-col flex-grow overflow-y-auto text-white"
        >
          <div class="flex-shrink-0 px-4 flex flex-col items-start">
            <h1 class="text-5xl font-logo text-gray-100">Tyltgo</h1>
            <h2 class="mt-2 text-base font-header text-gray-100">Merchant Portal</h2>
          </div>
          <div class="flex-grow mt-5 flex flex-col">
            <div class="flex-1 space-y-1">
              <div class="p-4">
                <GoTextField
                  :label="false"
                  type="search"
                  name="search"
                  v-model="searchText"
                  placeholder="Search"
                />
              </div>
              <router-link :to="{ name: 'merchant.batches' }" class="nav-link">
                <!-- Heroicon name: outline/document-search -->
                <svg
                  class="mr-3 h-6 w-6 text-gray-300 group-hover:text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z"
                  />
                </svg>
                Orders
              </router-link>

              <router-link :to="{ name: 'merchant.billing' }" class="nav-link">
                <!-- Heroicon name: outline/chat -->
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="file-invoice-dollar"
                  class="svg-inline--fa fa-file-invoice-dollar fa-w-12"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                >
                  <path
                    fill="currentColor"
                    d="M377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-153 31V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zM64 72c0-4.42 3.58-8 8-8h80c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8H72c-4.42 0-8-3.58-8-8V72zm0 80v-16c0-4.42 3.58-8 8-8h80c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8H72c-4.42 0-8-3.58-8-8zm144 263.88V440c0 4.42-3.58 8-8 8h-16c-4.42 0-8-3.58-8-8v-24.29c-11.29-.58-22.27-4.52-31.37-11.35-3.9-2.93-4.1-8.77-.57-12.14l11.75-11.21c2.77-2.64 6.89-2.76 10.13-.73 3.87 2.42 8.26 3.72 12.82 3.72h28.11c6.5 0 11.8-5.92 11.8-13.19 0-5.95-3.61-11.19-8.77-12.73l-45-13.5c-18.59-5.58-31.58-23.42-31.58-43.39 0-24.52 19.05-44.44 42.67-45.07V232c0-4.42 3.58-8 8-8h16c4.42 0 8 3.58 8 8v24.29c11.29.58 22.27 4.51 31.37 11.35 3.9 2.93 4.1 8.77.57 12.14l-11.75 11.21c-2.77 2.64-6.89 2.76-10.13.73-3.87-2.43-8.26-3.72-12.82-3.72h-28.11c-6.5 0-11.8 5.92-11.8 13.19 0 5.95 3.61 11.19 8.77 12.73l45 13.5c18.59 5.58 31.58 23.42 31.58 43.39 0 24.53-19.05 44.44-42.67 45.07z"
                  ></path>
                </svg>
                Billing
              </router-link>
              <router-link
                :to="{ name: 'merchant.reports' }"
                class="nav-link"
                v-if="currentUser.settings.deliveryCutoffTime"
              >
                <IconFile />
                Reports
              </router-link>
              <router-link
                v-if="adminAuthToken && currentUser.shopifyStores?.length"
                :to="{ name: 'merchant.shopifyIntegrations' }"
                class="nav-link"
              >
                <IconShopify />
                Shopify Integrations
              </router-link>
              <router-link :to="{ name: 'merchant.settings' }" class="nav-link">
                <IconCog />

                Settings
              </router-link>
              <router-link
                v-if="currentUser.settings.parent"
                :to="{ name: 'merchant.labelPrinter' }"
                class="nav-link"
              >
                <IconPrint />

                Label Printer
              </router-link>
              <a
                href="https://tyltgo.zendesk.com/hc/en-us/categories/360006282791-Merchants"
                target="_blank"
                class="nav-link"
              >
                <svg
                  class="mr-3 h-6 w-6 text-gray-300 group-hover:text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                  />
                </svg>
                FAQ
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="external-link-alt"
                  class="py-1 ml-32 svg-inline--fa fa-external-link-alt fa-w-16"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"
                  ></path>
                </svg>
              </a>
              <a
                href="https://tyltgo.typeform.com/to/sp6kNM6T#source=portal"
                target="_blank"
                class="nav-link flex items-center justify-between bg-primary-800"
              >
                <div class="flex items-center">
                  <IconBullhorn
                    class="mr-3 h-6 w-6 text-gray-300 group-hover:text-gray-500 flex-none flex-grow-0"
                  />
                  Refer a Business!
                </div>
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="external-link-alt"
                  class="py-1 svg-inline--fa fa-external-link-alt fa-w-16"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"
                  ></path>
                </svg>
              </a>
              <div class="pt-8 text-center">
                <GoButton as="router-link" :to="{ name: 'merchant.new' }" class="mt-6 text-sm">
                  New Order Batch
                </GoButton>
              </div>
            </div>
          </div>
          <div class="flex-shrink-0 block w-full">
            <div class="py-2 px-4 text-gray-200">{{ currentUser.name }}</div>

            <a
              href="#"
              @click="logout"
              class="group py-2 px-4 flex items-center text-sm font-medium text-gray-200 hover:text-gray-900 hover:bg-gray-50"
            >
              <IconSignout class="text-gray-300 group-hover:text-gray-500 mr-3 h-6 w-6" />
              Logout
            </a>
          </div>
        </nav>
      </div>
    </div>

    <!-- Content area -->
    <div class="relative flex-1 flex flex-col md:ml-64">
      <SearchPanel
        v-if="searchText"
        :searchResults="searchResults"
        :searchText="searchText"
        :searchLoading="searchLoading"
        :searchSet="searchSet"
        v-slot="{ hit }"
      >
        <OrderCard
          :route="hit._source"
          :waypoint="hit._source"
          :routeId="hit._source.routeId"
          :forSearch="true"
          v-if="hit._index.includes('waypoint')"
        />

        <BatchCard :route="hit._source" v-if="hit._index.includes('routes')" />
      </SearchPanel>
      <main class="flex-1 focus:outline-none" tabindex="0">
        <router-view />
      </main>
    </div>
    <GoMessages />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { apiService, authService } from '@tyltgo/shared';
import debounce from 'lodash/debounce';
import SearchPanel from '../components/SearchPanel.vue';
import OrderCard from '../components/OrderCard.vue';
import BatchCard from '../components/BatchCard.vue';
import GoButton from '../components/GoButton.vue';
import GoTextField from '../components/GoTextField.vue';
import IconFile from '../components/IconFile.vue';
import IconCog from '../components/IconCog.vue';
import IconSignout from '../components/IconSignout.vue';
import { currentUser } from '../services/auth-service';
import GoMessages from '../components/GoMessages.vue';

export default defineComponent({
  setup() {
    const adminAuthToken = localStorage.getItem('authToken-admin');
    return {
      currentUser,
      adminAuthToken,
    };
  },
  components: {
    GoButton,
    GoTextField,
    IconFile,
    IconCog,
    IconSignout,
    SearchPanel,
    OrderCard,
    BatchCard,
    GoMessages,
  },
  data() {
    return {
      searchText: '',
      searchResults: {},
      searchLoading: false,
      searchSet: false,
    };
  },
  watch: {
    $route(_to, _from) {
      // this is to remove the search panel when the route changes.
      this.searchText = '';
    },
    searchText(value: string) {
      if (value) {
        this.search(value);
      } else {
        this.searchResults = {};
        this.searchSet = false;
      }
    },
  },
  methods: {
    async logout() {
      await authService.logout();
      this.$router.push({ name: 'merchant.login' });
    },
    search: debounce(async function search(searchText: string) {
      if (searchText.length < 2) return;

      this.searchLoading = true;
      const results = await apiService.merchants.batches.search(searchText);
      this.searchLoading = false;
      this.searchSet = true;
      this.searchResults = results;
    }, 400),
  },
});
</script>

<style lang="scss" scoped>
.nav-link {
  @apply border-transparent text-gray-200 border-l-4 py-2 px-3 flex items-center text-sm font-medium group;

  &:hover {
    @apply text-gray-900 bg-gray-50;
  }

  &.router-link-active {
    @apply bg-primary-50 border-primary-600 text-primary-800;
    svg {
      @apply text-primary-700;
    }
  }

  svg {
    @apply text-gray-300 h-6 w-6 mr-3;
  }
}
</style>
