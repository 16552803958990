<template>
  <main class="flex-1 overflow-y-auto focus:outline-none" tabindex="0">
    <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
      <div class="pt-10 pb-8">
        <div class="bg-white rounded-md px-4 sm:px-6 py-4 sm:py-6 mt-8">
          <div class="relative">
            <dl class="space-y-8">
              <div class="relative">
                <dt>
                  <router-link
                    :to="{
                      name: $route.name.replace('home', 'addOrders'),
                      params: { storeName: shopName },
                    }"
                  >
                    <div
                      class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-600 text-white"
                    >
                      <svg
                        class="h-6 w-6 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Add Orders</p>
                  </router-link>
                </dt>
                <dd class="mt-2 ml-16 text-base text-gray-500">
                  Filter and add orders to Tyltgo's delivery system.
                </dd>
              </div>

              <div class="relative">
                <dt>
                  <router-link
                    :to="{
                      name: $route.name.replace('home', 'pendingOrders'),
                      params: { storeName: shopName },
                    }"
                  >
                    <div
                      class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-600 text-white"
                    >
                      <svg
                        class="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z"
                        />
                      </svg>
                    </div>
                    <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Pending Orders</p>
                  </router-link>
                </dt>
                <dd class="mt-2 ml-16 text-base text-gray-500">
                  View/Edit pending orders and
                  <strong>download labels</strong>
                  .
                </dd>
              </div>

              <div v-if="adminAuthToken" class="relative">
                <dt>
                  <router-link
                    :to="{
                      name: $route.name.replace('home', 'pendingOrders'),
                      params: { storeName: shopName },
                    }"
                  >
                    <div
                      class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-600 text-white"
                    >
                      <svg
                        class="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z"
                        />
                      </svg>
                    </div>
                    <p class="ml-16 text-lg leading-6 font-medium text-gray-900">
                      Search Shopify (Visible to admins only)
                    </p>
                  </router-link>
                </dt>
                <dd class="mt-2 ml-16 text-base text-gray-500">
                  <GoForm @submit="searchShopify" class="space-y-3">
                    <GoTextField name="search" label="Order ID" />
                    <GoButton>Search</GoButton>
                  </GoForm>
                  <pre class="p-3">{{ shopifySearchResult }}</pre>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { apiService } from '@tyltgo/shared';
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { currentUser, shopifySession } from '../../services/auth-service';

export default defineComponent({
  setup(props) {
    const adminAuthToken = localStorage.getItem('authToken-admin');
    const route = useRoute();
    const storeName = props.storeName || route.params.storeName || shopifySession.value.shop;
    const store = currentUser.value.shopifyStores.find(s => s.credentials.shopName === storeName);
    return {
      store,
      shopName: storeName,
      currentUser,
      adminAuthToken,
    };
  },
  props: {
    storeName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      shopifySearchResult: null,
    };
  },
  mounted() {},
  methods: {
    async searchShopify(values: unknown) {
      this.shopifySearchResult = null;
      console.log('hello', this.storeName);
      this.shopifySearchResult = await apiService.merchants.batches.searchShopifyOrder(
        this.shopName,
        values
      );
    },
  },
});
</script>
