<template>
  <div
    class="flex flex-col ml-64 pr-64 bg-white dark:bg-gray-800 fixed bottom-0 left-0 w-full px-4 pt-2 pb-10 max-h-96 space-y-2"
    :style="{
      zIndex: 1020,
      height: panelHeight,
      maxHeight: startHeight ? '100%' : '50%',
    }"
    ref="bottomPanel"
  >
    <div class="flex justify-center" v-if="showResize">
      <div
        class="h-1 w-10 bg-black rounded-full"
        style="cursor: ns-resize"
        @mousedown="startResize"
      ></div>
    </div>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    resetHeight: {
      type: Boolean,
      default: false,
    },
    showResize: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      startY: 0,
      startHeight: null,
      originalHeight: null,
      panelSize: null,
      resize: false,
    };
  },
  mounted() {
    this.originalHeight = this.$refs.bottomPanel.offsetHeight;
  },
  computed: {
    panelHeight() {
      if (this.resetHeight) {
        if (this.originalHeight) return `${this.originalHeight}px`;
        return undefined;
      }
      if (this.startHeight) {
        const height = this.startHeight + this.panelSize;
        if (height < this.originalHeight) return undefined;
        return `${height}px`;
      }
      return undefined;
    },
  },
  methods: {
    startResize(event) {
      this.resize = true;
      this.startHeight = this.$refs.bottomPanel.offsetHeight;
      this.panelSize = 0;
      this.startY = event.screenY;

      document.addEventListener('mousemove', this.mouseMove);
      document.addEventListener('mouseup', this.stopResize);
    },
    stopResize(_event) {
      this.resize = false;
      document.removeEventListener('mousemove', this.mouseMove);
      document.removeEventListener('mouseup', this.stopResize);
    },
    mouseMove(event) {
      if (this.resize) {
        if (event.stopPropagation) event.stopPropagation();
        if (event.preventDefault) event.preventDefault();
        // eslint-disable-next-line no-param-reassign
        event.cancelBubble = true;
        // eslint-disable-next-line no-param-reassign
        event.returnValue = false;

        this.panelSize = this.startY - event.screenY;

        return false;
      }

      return true;
    },
  },
});
</script>
