/* eslint-disable import/prefer-default-export */
import axios from 'axios';

// for now we want the zplLabel to be FormData or Blob passed from a browser
// or a string if passed from a server
// we don't want to add the form-data lib since it messes with streams.
export const convertZplToPdf = async (
  zplLabel: unknown,
  responseType: 'blob' | 'arraybuffer' = 'blob'
) => {
  let formData = zplLabel;
  if (typeof zplLabel === 'string' || zplLabel instanceof String) {
    formData = zplLabel;
    // eslint-disable-next-line no-param-reassign
    responseType = 'arraybuffer';
  }
  const labelaryResponse = await axios.post(
    'https://api.labelary.com/v1/printers/8dpmm/labels/4x6/',
    formData,
    {
      headers: { Accept: 'application/pdf' },
      responseType,
    }
  );

  return labelaryResponse.data;
};
