<template>
  <main class="flex-1 overflow-y-auto focus:outline-none" tabindex="0">
    <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
      <div class="pt-10 pb-16">
        <div class="px-4 sm:px-6 md:px-0 mb-4 flex justify-between">
          <GoHeader :level="1">Merchants</GoHeader>
          <GoButton
            class="text-xs"
            variant="outline"
            @click="showAddMerchantModal = true"
            type="button"
          >
            <div class="flex">
              <IconPlus class="w-2 mr-2" />
              Add Merchant
            </div>
          </GoButton>
          <GoModal v-if="showAddMerchantModal" @close="showAddMerchantModal = false">
            <Form @submit="addMerchant" class="space-y-3" :validation-schema="schema">
              <GoTextField name="email" type="email" placeholder="you@example.com" />
              <GoTextField name="businessName" type="text" />
              <GoSelect
                name="businessType"
                :options="businessTypes"
                placeholder="Select Business Type"
              />
              <div class="text-center py-3">
                <GoButton class="w-full">Signup</GoButton>
              </div>
            </Form>
          </GoModal>
        </div>
        <div class="px-4 sm:px-6 md:px-0">
          <GoTextField
            :label="false"
            type="search"
            name="search"
            v-model="searchText"
            placeholder="Search"
            class="mb-4"
          />
          <div class="flex items-center justify-end">
            <IconLoader v-show="searchLoading" class="w-5 mr-4" />
            <div v-if="merchants" class="text-sm">
              Showing {{ merchants.length }} of {{ searchCount }}
            </div>
          </div>
          <div class="py-6">
            <MerchantCard :merchant="merchant" v-for="merchant of merchants" :key="merchant.id" />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { apiService } from '@tyltgo/shared';
import debounce from 'lodash/debounce';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import Card from '../../components/MerchantCard.vue';
import GoTextField from '../../components/GoTextField.vue';
import GoButton from '../../components/GoButton.vue';
import GoSelect from '../../components/GoSelect.vue';
import GoModal from '../../components/GoModal.vue';
import IconLoader from '../../components/IconLoader.vue';
import IconPlus from '../../components/IconPlus.vue';
import { addNotification, aroundLoadingMessage } from '../../services/ui-message-service';

const schema = yup.object().shape({
  email: yup.string().email().required().label('Email'),
  businessName: yup.string().required().label('Business Name'),
  businessType: yup.string().required().label('Business Type'),
});

export default defineComponent({
  setup() {
    return { schema };
  },
  components: {
    MerchantCard: Card,
    GoTextField,
    GoButton,
    GoSelect,
    GoModal,
    IconLoader,
    IconPlus,
    Form,
  },
  data() {
    return {
      merchants: null,
      searchText: '',
      searchLoading: false,
      searchCount: null,
      showAddMerchantModal: false,
      addMerchantErrorMessage: null,
    };
  },
  watch: {
    searchText(value: string) {
      this.search(value || '*');
    },
  },
  async mounted() {
    this.search('*');
  },
  computed: {
    businessTypes() {
      return [
        'Florist',
        'Pharmacy',
        'Electronics',
        'Groceries',
        'Restaurant',
        'Dry Cleaner',
        'Other',
      ].map(type => ({
        label: type,
        value: type,
      }));
    },
  },
  methods: {
    search: debounce(async function search(searchText: string) {
      this.searchLoading = true;
      const results = await apiService.admin.merchants.search(searchText);
      this.searchLoading = false;
      this.merchants = results.data;
      this.searchCount = results.total;
    }, 400),
    async addMerchant(values) {
      await aroundLoadingMessage('Creating...', async () => {
        try {
          await apiService.admin.merchants.addMerchant(values);
          this.$router.push({ name: 'admin.merchant', params: { id: values.email } });
        } catch (e) {
          addNotification({
            color: 'danger',
            message: e.response?.data?.message || 'An error occured.',
          });
        }
      });
    },
  },
});
</script>
