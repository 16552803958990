/* eslint-disable import/prefer-default-export */
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc'; // dependent on utc plugin
import timezone from 'dayjs/plugin/timezone';
import { apiService, addressDetails, Route, Merchant, Waypoint } from '@tyltgo/shared';

export { time, diff } from '@tyltgo/shared';

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

export const readableDay = (date: string): string => {
  const result = dayjs(date).format('dddd');
  if (result === 'Invalid Date') return '';
  return result;
};

export const timezoneConversionRouteInput = async (
  routeOrWaypoint: Route | Waypoint,
  portal: 'admin' | 'merchant'
): Promise<TimezoneObject> => {
  let waypoint: Waypoint;
  if ('waypoints' in routeOrWaypoint) {
    const pickupWaypoint = routeOrWaypoint.waypoints.find(
      element => element.address === routeOrWaypoint.pickupAddress
    );
    waypoint = pickupWaypoint;
  } else {
    waypoint = routeOrWaypoint;
  }

  if (!waypoint?.address) {
    return { timeZoneId: 'America/Toronto' };
  }

  const fullPostalCode = addressDetails(waypoint.address);
  if (fullPostalCode.postalCode === '') {
    return { timeZoneId: 'America/Toronto' };
  }
  const FSA = fullPostalCode.fsa;

  const portalToPath = {
    admin: apiService.admin.routes.getTimezone,
    merchant: apiService.merchants.batches.getTimezone,
  };

  const locationTimezone: TimezoneResponse = await portalToPath[portal](
    `${waypoint.lat},${waypoint.lng}`,
    dayjs(waypoint.routeDate).unix(),
    FSA
  );

  return locationTimezone.timezone;
};

export const timezoneConversionCurrentUserInput = async (
  businessAddresses: Merchant['businessAddresses'],
  portal: 'admin' | 'merchant',
  additionalRouteInfo: { businessAddress: string; day: string }
): Promise<TimezoneObject> => {
  const businessAddress = businessAddresses.filter(
    element => element.businessAddress === additionalRouteInfo.businessAddress
  );

  const fullPostalCode = addressDetails(additionalRouteInfo.businessAddress);
  if (fullPostalCode.postalCode === '') {
    return { timeZoneId: 'America/Toronto' };
  }
  const FSA = fullPostalCode.fsa;

  const portalToPath = {
    admin: apiService.admin.routes.getTimezone,
    merchant: apiService.merchants.batches.getTimezone,
  };

  const locationTimezone: TimezoneResponse = await portalToPath[portal](
    `${businessAddress[0].businessLat},${businessAddress[0].businessLng}`,
    dayjs(additionalRouteInfo.day).unix(),
    FSA
  );

  return locationTimezone.timezone;
};

export const pickupRange = (time: string, timeZoneId: string) => {
  const pickupTime = dayjs(time).tz(timeZoneId || 'America/Toronto');
  const startTime = pickupTime.format('h:mm A');
  const endTime = pickupTime.add(30, 'minutes').format('h:mm A');
  return `${startTime} - ${endTime} ${pickupTime.format('z')}`;
};

interface TimezoneResponse {
  timezone: TimezoneObject;
}

interface TimezoneObject {
  timeZoneId: string;
}
