<template>
  <div>
    <GoModal v-if="showImageModal" @close="showImageModal = false">
      <DocumentImage :imageData="modalImage" class="w-full min-h-64" />
    </GoModal>
    <div class="grid grid-cols-2 gap-y-2 gap-x-4">
      <div
        class="rounded-md p-4 bg-white dark:bg-gray-800 mb-4 space-y-4 col-span-3 sm:col-span-1 flex flex-col justify-between"
        v-for="[name, document] of Object.entries(courier.documents)"
        :key="name"
      >
        <div class="space-y-4">
          <div class="flex justify-between items-center">
            <div class="flex space-x-4">
              <span class="font-bold">
                {{ startCase(name) }}
              </span>
              <GoBadge :class="courierDocumentStyle(document)">
                {{ courierDocumentDisplayState(document) }}
              </GoBadge>
            </div>
            <div
              class="flex items-center space-x-2 text-gray-700 dark:text-gray-400"
              v-if="images[name]"
            >
              <ZoomInIcon class="w-5" @click="showImageModalFor(name)" />
              <ExternalLinkIcon class="w-5" @click="newTabFor(name)" />
            </div>
          </div>
          <div class="h-48 flex justify-center items-center">
            <IconLoader
              class="animate-spin mx-2 h-5 w-5 text-primary-800"
              v-if="!images[name] && !imagesLoaded"
            />
            <DocumentImage
              v-if="images[name]"
              :imageData="images[name]"
              :alt="name"
              class="h-full"
              @click="showImageModalFor(name)"
            />
          </div>
          <div class="flex justify-between">
            <DataBit v-if="documentTypeRequired(name)" title="Document Type">
              {{ document.documentType }}
            </DataBit>
            <DataBit v-if="expiryRequired(name)" title="Expiry Date">
              {{ document.expiryDate }}
            </DataBit>
          </div>
        </div>
        <div class="flex justify-between">
          <GoUploadButton
            variant="link"
            class="text-xs"
            :id="`upload_${name}`"
            accept=".pdf,.png,.jpeg,.jpg"
            @filesSelected="files => fileSelected(files, name)"
          >
            Upload
          </GoUploadButton>
          <GoButton
            variant="link"
            color="danger"
            class="text-xs"
            @click="rejectDocument(name)"
            v-if="document.state !== 'incomplete'"
          >
            Reject
          </GoButton>
          <GoButton
            variant="link"
            class="text-xs"
            @click="approveDocument(name)"
            v-if="document.state === 'awaitingApproval'"
          >
            Approve
          </GoButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  apiService,
  courierDocumentDisplayState,
  courierDocuments,
  courierDocumentStyle,
} from '@tyltgo/shared';
import { defineComponent } from 'vue';
import { ZoomInIcon, ExternalLinkIcon } from '@heroicons/vue/outline';
import startCase from 'lodash/startCase';
import GoBadge from './GoBadge.vue';
import GoUploadButton from './GoUploadButton.vue';
import GoButton from './GoButton.vue';
import GoModal from './GoModal.vue';
import DataBit from './DataBit.vue';
import IconLoader from './IconLoader.vue';
import DocumentImage from './DocumentImage.vue';
import {
  showConfirmationModal,
  showLoadingMessage,
  stopLoadingMessage,
} from '../services/ui-message-service';

export default defineComponent({
  emits: ['loadCourier'],
  setup() {
    return {
      startCase,
      courierDocumentDisplayState,
      courierDocumentStyle,
    };
  },
  components: {
    GoBadge,
    GoUploadButton,
    GoButton,
    GoModal,
    DataBit,
    IconLoader,
    DocumentImage,
    ZoomInIcon,
    ExternalLinkIcon,
  },
  props: {
    courier: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      images: {},
      imagesLoaded: false,
      modalImage: null,
      showImageModal: false,
    };
  },
  mounted() {
    this.loadImages();
  },
  methods: {
    documentTypeRequired(name) {
      const documentOptions = courierDocuments[name];
      return !!documentOptions.documentTypes;
    },
    expiryRequired(name) {
      const documentOptions = courierDocuments[name];
      if (this.documentTypeRequired(name)) {
        const { documentType } = this.courier.documents[name];
        return documentOptions.documentTypes.find(type => type.name === documentType)?.expiry;
      }
      return documentOptions.expiry;
    },
    showImageModalFor(documentName: string) {
      this.showImageModal = true;
      this.modalImage = this.images[documentName];
    },
    async loadImage(name) {
      const data = await this.getImageData(name);
      this.images[name] = data;
    },
    async loadImages() {
      await Promise.all(Object.keys(this.courier.documents).map(this.loadImage));
      this.imagesLoaded = true;
    },
    async getImageData(documentName: string) {
      const data = await apiService.admin.couriers.documentImage(this.courier.email, documentName);
      return data;
    },
    b64toBlob(b64Data, contentType = '', sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    dataSrc(documentName: string) {
      const image = this.images[documentName];
      if (!image) return undefined;

      return `data:${image.contentType};base64, ${image.base64Image}`;
    },
    async fileSelected(files, name) {
      const formData = new FormData();
      formData.append('file', files[0]);
      formData.append('name', name);
      showLoadingMessage('Uploading ...');
      await apiService.admin.couriers.updateDocument(this.courier.id, formData as any);
      await this.loadImage(name);
      stopLoadingMessage();
    },
    async approveDocument(name) {
      const document = courierDocuments[name];
      showLoadingMessage('Approving ...');
      await apiService.admin.couriers.updateDocument(this.courier.id, {
        name,
        values: {
          [`${document.baseField}Approved`]: true,
        },
      });
      this.$emit('loadCourier');
      stopLoadingMessage();
    },
    async rejectDocument(name) {
      const document = courierDocuments[name];
      const confirmed = await showConfirmationModal({
        title: 'Reject Document',
        message: 'Are you sure?',
        confirmButtonText: 'Reject',
        color: 'danger',
      });

      if (!confirmed) return;

      const approvalStatus = ['Approved', 'PendingReapproval'].includes(this.courier.status)
        ? 'PendingReapproval'
        : 'Pending';
      showLoadingMessage('Rejecting ...');
      await apiService.admin.couriers.updateDocument(this.courier.id, {
        name,
        values: {
          [`${document.baseField}Approved`]: false,
          [`${document.baseField}Rejected`]: true,
          [`${document.baseField}Uploaded`]: false,
          approvalStatus,
        },
      });
      this.$emit('loadCourier');
      stopLoadingMessage();
    },
    newTabFor(name) {
      window.open(this.images[name].imageUrl);
    },
  },
});
</script>
