<template>
  <div>
    <GoButton variant="outline" class="mb-4 text-xs" @click="downloadCsv">Download CSV</GoButton>
    <div class="shadow border-b border-gray-200 dark:border-gray-500 sm:rounded-lg">
      <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-500">
        <thead class="bg-gray-50 dark:bg-gray-700">
          <tr>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
            >
              Merchant
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
            >
              Note
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
            >
              Invoice ID
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
            >
              Billing Period
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
            >
              Price
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
            >
              Taxes (HST)
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
            >
              Debits
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
            >
              Credits
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
            >
              Total
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
            >
              # Orders
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
            >
              State
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody class="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-500">
          <tr v-for="invoice of invoices" :key="invoice.id">
            <td
              class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-50"
            >
              <router-link :to="{ name: 'admin.merchant', params: { id: invoice.email } }">
                {{ invoice.email }}
              </router-link>
            </td>
            <td
              class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-50"
            >
              <pre>{{ invoice.note }}</pre>
            </td>
            <td
              class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-50"
            >
              {{ invoice.id }}
            </td>
            <td
              class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-50"
            >
              {{ invoice.billingPeriod }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
              ${{ parseFloat(invoice.price.toString()).toFixed(2) }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
              ${{
                (
                  parseFloat(invoice.priceWithTax.toString()) - parseFloat(invoice.price.toString())
                ).toFixed(2)
              }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
              ${{ parseFloat((invoice.debitAmount || 0).toString()).toFixed(2) }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
              ${{ parseFloat(invoice.creditAmount.toString()).toFixed(2) }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
              ${{ parseFloat(invoice.priceWithTax.toString()).toFixed(2) }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
              {{ invoice.numberOfOrders }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
              {{ invoice.state }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
              <GoDropdown>
                <template v-slot:button>
                  <IconEllipsis class="flex-none w-3 text-gray-600 dark:text-gray-300" />
                </template>
                <template v-slot:items>
                  <div>
                    <GoDropdownItem
                      v-if="invoice.state === 'NOT_PAID'"
                      @click="chargeInvoice(invoice)"
                    >
                      Charge (Credit Card)
                    </GoDropdownItem>
                    <GoDropdownItem
                      v-if="invoice.state === 'NOT_PAID'"
                      @click="padInvoice(invoice)"
                    >
                      Charge (PAD)
                    </GoDropdownItem>
                    <GoDropdownItem
                      v-if="invoice.state === 'NOT_PAID'"
                      @click="markInvoicePaid(invoice)"
                    >
                      Mark as Paid
                    </GoDropdownItem>
                    <GoDropdownItem
                      as="router-link"
                      :to="{
                        name: 'admin.merchant.invoice',
                        params: { id: invoice.email, invoiceId: invoice.id },
                      }"
                      variant="link"
                      target="_blank"
                    >
                      View
                    </GoDropdownItem>
                    <GoDropdownItem
                      v-if="invoice.attachmentUrls.length"
                      @click="showPdf(invoice)"
                      variant="link"
                    >
                      Download PDF
                    </GoDropdownItem>
                    <GoDropdownItem @click="downloadSummaryCsv(invoice)" variant="link">
                      Download Summary CSV
                    </GoDropdownItem>
                    <GoDropdownItem @click="downloadDetailsCsv(invoice)" variant="link">
                      Download Details CSV
                    </GoDropdownItem>
                    <GoDropdownItem @click="emailInvoice(invoice)" variant="link">
                      Email Invoice
                    </GoDropdownItem>
                    <GoDropdownItem
                      v-if="invoice.state !== 'PAID'"
                      @click="deleteInvoice(invoice)"
                      class="text-red-700"
                    >
                      Delete
                    </GoDropdownItem>
                  </div>
                </template>
              </GoDropdown>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script lang="ts">
import { apiService, Invoice } from '@tyltgo/shared';
import { defineComponent, PropType } from 'vue';
import { unparse } from 'papaparse';
import {
  addNotification,
  aroundLoadingMessage,
  showConfirmationModal,
} from '../services/ui-message-service';
import { currentUser } from '../services/auth-service';
import { downloadBlob } from '../services/browser-service';

export default defineComponent({
  emits: ['loadInvoices'],
  setup() {
    return {
      currentUser,
    };
  },
  props: {
    invoices: {
      type: Array as PropType<Invoice[]>,
      required: true,
    },
  },
  methods: {
    async downloadCsv() {
      const rows = this.invoices.map(invoice => {
        return {
          'Billing Period': invoice.billingPeriod,
          'Merchant ID': invoice.email,
          'Invoice Price (with Credit)': invoice.price,
          'Credit Amount': invoice.creditAmount,
          Tax: invoice.tax,
          Total: invoice.priceWithTax,
        };
      });
      const blob = new Blob([unparse(rows)], { type: 'text/csv' });
      downloadBlob(blob, `invoices.csv`);
    },
    async downloadSummaryCsv(invoice) {
      aroundLoadingMessage('Downloading ...', async () => {
        const { csv } = await apiService.admin.merchants.invoiceSummaryCsv(
          invoice.id,
          invoice.email
        );
        const blob = new Blob([csv], { type: 'text/csv' });
        downloadBlob(blob, `${invoice.id}-summary.csv`);
      });
    },

    async emailInvoice(invoice) {
      if (!this.currentUser.canEmailInvoice) {
        showConfirmationModal({
          title: `Permission denied.`,
          message: `You do not have permission to generate attachments and email invoice. Good day.`,
          color: 'danger',
        });
        return;
      }

      const confirmed = await showConfirmationModal({
        title: 'Confirm to Generate Attachments and Email Invoice',
        message: 'Are you sure you want to generate attachments and email invoice?',
        confirmButtonText: 'Confirm',
        color: 'warning',
      });

      if (confirmed) {
        await aroundLoadingMessage('Generating Attachments and Emailing...', async () => {
          try {
            await apiService.admin.merchants.emailInvoice(invoice.id, invoice.email);
            this.$emit('loadInvoices');
          } catch (e) {
            addNotification({
              color: 'danger',
              message: e.response?.data?.message || 'An error occured.',
            });
          }
        });
      }
    },

    async downloadDetailsCsv(invoice) {
      aroundLoadingMessage('Downloading ...', async () => {
        const { csv } = await apiService.admin.merchants.invoiceDetailsCsv(
          invoice.id,
          invoice.email
        );
        const blob = new Blob([csv], { type: 'text/csv' });
        downloadBlob(blob, `${invoice.id}-details.csv`);
      });
    },
    async showPdf(invoice: Invoice) {
      const url = await apiService.admin.merchants.invoicePdf(invoice.id, invoice.email);
      if (url) {
        window.open(url);
      } else {
        showConfirmationModal({
          title: `PDF Not Found`,
          message: `Please contact ENG team for assistance.`,
          color: 'danger',
        });
      }
    },
    async padInvoice(invoice: Invoice) {
      if (!this.currentUser.canChargeInvoices) {
        showConfirmationModal({
          title: `Permission denied.`,
          message: `You do not have permission to charge invoices. Good day.`,
          color: 'danger',
        });
        return;
      }

      const confirmed = await showConfirmationModal({
        title: `PAD Charge Invoice`,
        message: `Are you sure you wish to charge this invoice via PAD?`,
        color: 'warning',
      });
      if (!confirmed) return;

      await aroundLoadingMessage('Charging...', async () => {
        try {
          await apiService.admin.billing.padInvoice(invoice.id);
          addNotification({
            color: 'success',
            message: 'PAD Successfully Initiated',
          });
          this.$emit('loadInvoices');
        } catch (e) {
          addNotification({
            color: 'danger',
            message: e.response?.data?.message || 'An error occured.',
          });
        }
      });
    },
    async chargeInvoice(invoice: Invoice) {
      if (!this.currentUser.canChargeInvoices) {
        showConfirmationModal({
          title: `Permission denied.`,
          message: `You do not have permission to charge invoices. Good day.`,
          color: 'danger',
        });
        return;
      }

      const confirmed = await showConfirmationModal({
        title: `Credit Card Charge Invoice`,
        message: `Are you sure you wish to charge this invoice?`,
        color: 'warning',
      });
      if (!confirmed) return;

      await aroundLoadingMessage('Charging...', async () => {
        try {
          await apiService.admin.billing.chargeInvoice(invoice.id);
          this.$emit('loadInvoices');
        } catch (e) {
          addNotification({
            color: 'danger',
            message: e.response?.data?.message || 'An error occured.',
          });
        }
      });
    },
    async markInvoicePaid(invoice: Invoice) {
      if (!this.currentUser.canChargeInvoices) {
        showConfirmationModal({
          title: `Permission denied.`,
          message: `You do not have permission to mark invoices paid. Good day.`,
          color: 'danger',
        });
        return;
      }

      const confirmed = await showConfirmationModal({
        title: `Mark Invoice as Paid`,
        message: `Are you sure you wish to mark this invoice as paid?`,
        color: 'warning',
      });
      if (!confirmed) return;

      await aroundLoadingMessage('Marking...', async () => {
        try {
          await apiService.admin.billing.markInvoicePaid(invoice.id);
          this.$emit('loadInvoices');
        } catch (e) {
          addNotification({
            color: 'danger',
            message: e.response?.data?.message || 'An error occured.',
          });
        }
      });
    },

    async deleteInvoice(invoice: Invoice) {
      if (!this.currentUser.canDeleteInvoices) {
        showConfirmationModal({
          title: `Permission denied.`,
          message: `You do not have permission to delete invoices. Good day.`,
          color: 'danger',
        });
        return;
      }

      const confirmed = await showConfirmationModal({
        title: 'Confirm Deletion of Invoice',
        message: 'Are you sure you want to delete this invoice?',
        matchText: invoice.id,
        matchTextMessage: 'Please type in the invoice ID.',
        confirmButtonText: 'Confirm',
        color: 'danger',
      });

      if (confirmed) {
        await aroundLoadingMessage('Deleting...', async () => {
          try {
            await apiService.admin.merchants.deleteInvoice(invoice.id, invoice.email);
            this.$emit('loadInvoices');
          } catch (e) {
            addNotification({
              color: 'danger',
              message: e.response?.data?.message || 'An error occured.',
            });
          }
        });
      }
    },
  },
});
</script>
